import { Box } from '@mantine/core';
import React from 'react';
import RelationshipManager from '../RelationshipManager';

export type TableConfigurationForm = {
    name: string;
    label: string;
    description: string | undefined;
    userId: string;
    primaryKey: string;
};

const SchemaTableRelationships: React.FC = () => {
    return (
        <Box className="mt-4">
            <RelationshipManager />
        </Box>
    );
};

export default React.memo(SchemaTableRelationships);
