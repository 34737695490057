import FilterRuleForm from '@components/Audience/Filters/FilterRuleForm';
import FilterRuleFormPropertySelect from '@components/Audience/Filters/FilterRuleFormPropertySelect';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import {
    addFilterRule,
    DimensionType,
    isField,
    isFilterableField,
    RelationTableType,
    type FilterableField,
    type FilterRule,
    type Filters,
} from '@lightdash/common';
import { Stack } from '@mantine/core';
import React, { useCallback } from 'react';

interface TriggerFilterGroupFormProps {
    fields: FilterableField[];
    filterRules: FilterRule[];
    isEditMode: boolean;
    onChange: (value: FilterRule[]) => void;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    activeField: FilterableField;
    handleEventChange: (eventName: string, eventSource: string) => void;
}

const TriggerFilterGroupForm: React.FC<TriggerFilterGroupFormProps> = ({
    isEditMode,
    fields,
    filterRules,
    onChange,
    filters,
    setFilters,
    activeField,
    handleEventChange,
}) => {
    const onDeleteItem = useCallback(
        (index: number) => {
            onChange([
                ...filterRules.slice(0, index),
                ...filterRules.slice(index + 1),
            ]);
        },
        [filterRules, onChange],
    );

    const onChangeItem = useCallback(
        (index: number, item: FilterRule) => {
            onChange([
                ...filterRules.slice(0, index),
                item,
                ...filterRules.slice(index + 1),
            ]);
        },
        [filterRules, onChange],
    );

    const addFieldRule = useCallback(
        (field: FieldWithSuggestions) => {
            const value =
                field.tableType === RelationTableType.EVENT &&
                field.type === DimensionType.EVENT
                    ? field.fieldReference
                    : undefined;
            if (isField(field) && isFilterableField(field)) {
                setFilters(
                    addFilterRule({
                        filters,
                        field,
                        ...(Boolean(value) && { value }),
                    }),
                    false,
                    0,
                );
            }
        },
        [filters, setFilters],
    );

    const handleTriggerEventChange = useCallback(
        (field: FieldWithSuggestions) => {
            const eventName = field.name;
            const eventSource = field.table ?? '';
            handleEventChange(eventName, eventSource);
        },
        [handleEventChange],
    );

    return (
        <Stack spacing="sm">
            {filterRules.length ? (
                filterRules.map((item, index) => (
                    <FilterRuleForm
                        isEditMode={isEditMode}
                        key={item.id}
                        filterRule={item}
                        fields={fields}
                        onChange={(value) => onChangeItem(0, value)}
                        onDelete={() => onDeleteItem(index)}
                        onConvertToGroup={undefined}
                        filters={filters}
                        setFilters={setFilters}
                        groupIndex={0}
                    />
                ))
            ) : (
                <FilterRuleFormPropertySelect
                    fields={fields}
                    onSubmit={(items) => {
                        addFieldRule(items[0]);
                        handleTriggerEventChange(items[0]);
                    }}
                    isEditMode={isEditMode}
                    activeField={activeField}
                    withinPortal={true}
                    minTargetButtonWidth={'10rem'}
                    relation={undefined}
                />
            )}
        </Stack>
    );
};

export default TriggerFilterGroupForm;
