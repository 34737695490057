import { Button, Popover, type MantineSize } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';
import { memo, type FC } from 'react';
import { ButtonVariant } from '../../mantineTheme';
import MantineIcon from '../common/MantineIcon';
import LimitForm from './LimitForm';

export type Props = {
    size?: MantineSize;
    disabled?: boolean;
    limit: number;
    onLimitChange: (value: number) => void;
};

const LimitButton: FC<Props> = memo(({ disabled, limit, onLimitChange }) => {
    const [opened, { open, close }] = useDisclosure(false);
    const ref = useClickOutside(
        () => setTimeout(() => close(), 0),
        ['mouseup', 'touchend'],
    );

    const handleLimitChange = (value: number) => {
        onLimitChange(value);
        close();
    };

    return (
        <Popover
            withinPortal
            disabled={disabled}
            opened={opened}
            position="bottom-end"
            withArrow
            shadow="md"
            offset={2}
            arrowOffset={10}
        >
            <Popover.Target>
                <Button
                    p="xs"
                    disabled={disabled}
                    onClick={opened ? undefined : open}
                    variant={ButtonVariant.OUTLINED}
                >
                    <MantineIcon icon={IconChevronDown} size="sm" />
                </Button>
            </Popover.Target>

            <Popover.Dropdown>
                <LimitForm
                    ref={ref}
                    limit={limit}
                    onLimitChange={handleLimitChange}
                />
            </Popover.Dropdown>
        </Popover>
    );
});

export default LimitButton;
