import { useLocale } from '@hooks/useLocale';
import { Box, Button, Group } from '@mantine/core';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import React, { useCallback } from 'react';
import { ButtonVariant } from '../../../../mantineTheme';
import { type BuilderFooterProps } from '../type';

const BuilderFooter = <T,>({
    currentStep,
    builderSteps,
    currentStepCallback,
    previousStepCallback,
    showFooterButtons,
    setShowFooterButtons,
    setCurrentStep,
}: BuilderFooterProps<T>) => {
    const { t } = useLocale();
    const getNextStep = useCallback(() => {
        const currentStepIndex = builderSteps.findIndex(
            (step) => step.key === currentStep,
        );
        return builderSteps[currentStepIndex + 1]?.key;
    }, [currentStep, builderSteps]);

    const getPreviousStep = useCallback(() => {
        const currentStepIndex = builderSteps.findIndex(
            (step) => step.key === currentStep,
        );
        return builderSteps[currentStepIndex - 1]?.key;
    }, [currentStep, builderSteps]);

    const handleBack = useCallback(() => {
        const previousStep = getPreviousStep();
        if (previousStepCallback) {
            const skip = previousStepCallback();
            if (skip) return;
        }
        if (previousStep) {
            setShowFooterButtons({
                next: true,
                disableNext: false,
                back: true,
            });
            setCurrentStep(previousStep);
        }
    }, [
        getPreviousStep,
        previousStepCallback,
        setCurrentStep,
        setShowFooterButtons,
    ]);

    const handleNext = useCallback(() => {
        const nextStep = getNextStep();
        if (currentStepCallback) {
            const skipExecution = currentStepCallback();
            if (skipExecution) return;
        }
        if (nextStep) {
            setShowFooterButtons({
                next: true,
                disableNext: false,
                back: true,
            });
            setCurrentStep(nextStep);
        }
    }, [
        getNextStep,
        currentStepCallback,
        setShowFooterButtons,
        setCurrentStep,
    ]);

    return (
        <Group className="justify-between w-full">
            {showFooterButtons.back ? (
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={handleBack}
                    leftIcon={<CaretLeft />}
                >
                    {t('builder_footer.go_back_button_text')}
                </Button>
            ) : (
                <Box className="flex-1"></Box>
            )}
            {showFooterButtons.next && (
                <Button
                    variant={ButtonVariant.PRIMARY}
                    onClick={handleNext}
                    rightIcon={
                        <CaretRight
                            color={
                                !showFooterButtons.disableNext
                                    ? 'white'
                                    : 'gray'
                            }
                        />
                    }
                    disabled={showFooterButtons.disableNext}
                >
                    {t('builder_footer.next_button_text')}
                </Button>
            )}
        </Group>
    );
};

export default BuilderFooter;
