import SnowflakeImage from '@assets/images/snowflake-logo.svg';
import GenericOption from '@components/common/Card/GenericOption';
import { CreateProjectConnection } from '@components/ProjectConnection/index';
import BigqueryIcon from '@components/ProjectConnection/ProjectConnectFlow/Assets/bigquery.svg';
import DatabricksIcon from '@components/ProjectConnection/ProjectConnectFlow/Assets/databricks.svg';
import RedshiftIcon from '@components/ProjectConnection/ProjectConnectFlow/Assets/redshift.svg';
import { useLocale } from '@hooks/useLocale';
import { WarehouseTypes } from '@lightdash/common';
import { List } from '@mantine/core';
import { t as translate } from 'i18next';
import React, { useState } from 'react';

interface WarehouseOptionProps {
    title: string;
    icon: string;
    warehouseType: WarehouseTypes;
    selectedWarehouse: WarehouseTypes | 'none';
    setSelectedWarehouse: React.Dispatch<
        React.SetStateAction<WarehouseTypes | 'none'>
    >;
}

const WarehouseOption: React.FC<WarehouseOptionProps> = ({
    title,
    icon,
    warehouseType,
    selectedWarehouse,
    setSelectedWarehouse,
}) => {
    const { t } = useLocale();
    const handleClick = () => {
        if (selectedWarehouse === warehouseType) {
            return;
        } else {
            setSelectedWarehouse(warehouseType);
        }
    };

    if (selectedWarehouse !== warehouseType && selectedWarehouse !== 'none')
        return null;

    return (
        <List.Item role="button" onClick={handleClick} className={`w-fit`}>
            <GenericOption
                title={title}
                iconComponent={<img src={icon} alt={title} />}
                rightSection={
                    selectedWarehouse === warehouseType && (
                        <button
                            className="m-0 text-sm font-medium text-blu-800"
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSelectedWarehouse('none');
                            }}
                        >
                            {t('warehouse.change_button')}
                        </button>
                    )
                }
            />
        </List.Item>
    );
};

const wareHouses: Pick<
    WarehouseOptionProps,
    'title' | 'icon' | 'warehouseType'
>[] = [
    {
        title: translate('warehouse.snowflake_title'),
        icon: SnowflakeImage,
        warehouseType: WarehouseTypes.SNOWFLAKE,
    },
    {
        title: translate('warehouse.bigquery_title'),
        icon: BigqueryIcon,
        warehouseType: WarehouseTypes.BIGQUERY,
    },
    {
        title: translate('warehouse.redshift_title'),
        icon: RedshiftIcon,
        warehouseType: WarehouseTypes.REDSHIFT,
    },
    {
        title: translate('warehouse.databricks_title'),
        icon: DatabricksIcon,
        warehouseType: WarehouseTypes.DATABRICKS,
    },
];

const ProjectManager: React.FC<{}> = ({}) => {
    const [selectedWarehouse, setSelectedWarehouse] = useState<
        WarehouseTypes | 'none'
    >('none');

    return (
        <>
            <section>
                <List className="flex flex-col gap-4">
                    {wareHouses.map((warehouse) => (
                        <WarehouseOption
                            title={warehouse.title}
                            icon={warehouse.icon}
                            warehouseType={warehouse.warehouseType}
                            selectedWarehouse={selectedWarehouse}
                            setSelectedWarehouse={setSelectedWarehouse}
                            key={warehouse.title}
                        />
                    ))}
                </List>
            </section>
            {selectedWarehouse && selectedWarehouse !== 'none' && (
                <section className="mt-6">
                    <CreateProjectConnection
                        isCreatingFirstProject={true}
                        selectedWarehouse={selectedWarehouse as WarehouseTypes}
                    />
                </section>
            )}
        </>
    );
};

export default ProjectManager;
