import PasswordTextInput from '@components/Auth/PasswordTextInput';
import PasswordInput from '@components/common/Inputs/PasswordInput';
import { useLocale } from '@hooks/useLocale';
import {
    getEmailSchema,
    getPasswordSchema,
    type CreateUserArgs,
} from '@lightdash/common';
import { Button, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { type FC } from 'react';
import { z } from 'zod';

type Props = {
    isLoading: boolean;
    readOnlyEmail?: string;
    onSubmit: (data: CreateUserArgs) => void;
};

const validationSchema = z.object({
    email: getEmailSchema(),
    password: getPasswordSchema(),
});

const CreateUserForm: FC<Props> = ({ isLoading, readOnlyEmail, onSubmit }) => {
    const form = useForm<CreateUserArgs>({
        initialValues: {
            firstName: '',
            lastName: '',
            email: readOnlyEmail || '',
            password: '',
        },
        validate: zodResolver(validationSchema),
    });
    const { t } = useLocale();

    return (
        <form
            name="register"
            onSubmit={form.onSubmit(onSubmit)}
            className="w-full"
            autoComplete="new-password"
        >
            <div className="flex flex-col w-full gap-3.5">
                <TextInput
                    label={t('common.first_name')}
                    name="firstName"
                    placeholder={t('common.first_name_placeholder')}
                    disabled={isLoading}
                    required
                    {...form.getInputProps('firstName')}
                    withAsterisk={false}
                />
                <TextInput
                    label={t('common.last_name')}
                    name="lastName"
                    placeholder={t('common.last_name_placeholder')}
                    disabled={isLoading}
                    required
                    {...form.getInputProps('lastName')}
                    withAsterisk={false}
                />

                <TextInput
                    label={t('common.email_label')}
                    name="email"
                    placeholder={t('common.email_placeholder')}
                    required
                    {...form.getInputProps('email')}
                    disabled={isLoading || !!readOnlyEmail}
                    data-cy="email-address-input"
                    withAsterisk={false}
                    autoComplete="new-password"
                />
                <PasswordTextInput
                    passwordValue={form.values.password as string}
                >
                    <PasswordInput
                        label={t('common.password_label')}
                        name="password"
                        placeholder={t('common.password_placeholder')}
                        required
                        {...form.getInputProps('password')}
                        data-cy="password-input"
                        disabled={isLoading}
                        autoComplete="new-password"
                        withAsterisk={false}
                    />
                </PasswordTextInput>
                <Button
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading}
                    data-cy="signup-button"
                    className="py-3.5 rounded-lg"
                >
                    {t('register.register_button')}
                </Button>
            </div>
        </form>
    );
};

export default CreateUserForm;
