import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type ApiSuccessEmpty,
    type CreateEntityAPIKey,
    type CreateEntityAPIKeyResponse,
    type CreateEventColumnFromLabel,
    type CreateEventMapperSchema,
    type CreateSourceEventNameKeyMapping,
    type CreateSourceEventNameKeyMappingResponse,
    type EntityApiKey,
    type EventColumn,
    type EventMapperSchema,
    type SourceEventKeyMapping,
    type UpdateEventMapperSchema,
    type UpdateSourceEventKeyMappings,
} from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

const getAllEvents = async (projectUuid: string) =>
    sortmentApi<EventMapperSchema[]>({
        url: `/projects/${projectUuid}/events/all-events`,
        method: 'GET',
        body: undefined,
    });

export const useAllEvents = () => {
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();

    return useQuery<EventMapperSchema[], ApiError>({
        queryKey: [QueryKeys.GET_ALL_WHITELISTED_EVENTS, projectUuid],
        queryFn: () => getAllEvents(projectUuid),
        enabled: !!projectUuid,
        retry: true,
        keepPreviousData: true,
    });
};

const getAllEventSources = async (projectId: string) => {
    return sortmentApi<Array<SourceEventKeyMapping>>({
        url: `/projects/${projectId}/events/sources`,
        method: 'GET',
        body: undefined,
    });
};

export const useGetAllEventSources = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const allEventSources = useQuery<Array<SourceEventKeyMapping>, ApiError>({
        queryKey: [QueryKeys.GET_ALL_EVENT_SOURCES, projectUuid],
        queryFn: () => getAllEventSources(projectUuid),
        enabled: !!projectUuid,
        retry: true,
    });
    return allEventSources;
};

const createEventSource = async (
    data: CreateSourceEventNameKeyMapping,
    projectId: string,
) =>
    sortmentApi<CreateSourceEventNameKeyMappingResponse>({
        url: `/projects/${projectId}/events/sources`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useCreateEventSource = () => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    return useMutation<
        CreateSourceEventNameKeyMappingResponse,
        ApiError,
        { payload: CreateSourceEventNameKeyMapping }
    >(({ payload }) => createEventSource(payload, projectUuid), {
        mutationKey: [QueryKeys.CREATE_EVENT_SOURCE, projectUuid],
        onSuccess: async () => {
            showToastSuccess({
                title: t('use_events.create_source_success'),
            });
        },
        onError: () => {
            showToastError({
                title: t('use_events.create_source_error'),
            });
        },
    });
};

const updateEventSource = async (
    data: UpdateSourceEventKeyMappings,
    projectId: string,
    sourceId: string,
) =>
    sortmentApi<SourceEventKeyMapping>({
        url: `/projects/${projectId}/events/sources/${sourceId}`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useUpdateEventSource = () => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    return useMutation<
        SourceEventKeyMapping,
        ApiError,
        { payload: UpdateSourceEventKeyMappings; sourceId: string }
    >(
        ({ payload, sourceId }) =>
            updateEventSource(payload, projectUuid, sourceId),
        {
            mutationKey: [QueryKeys.UPDATE_EVENT_SOURCE, projectUuid],
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    QueryKeys.GET_ALL_EVENT_SOURCES,
                ]);
                showToastSuccess({
                    title: t('event_source.update_source_success'),
                });
            },
            onError: () => {
                showToastError({
                    title: t('event_source.update_source_error'),
                });
            },
        },
    );
};

const getAllEntityKeys = async (projectId: string, sourceId: string) => {
    return sortmentApi<Array<EntityApiKey>>({
        url: `/projects/${projectId}/entity-api-key/entities/${sourceId}`,
        method: 'GET',
        body: undefined,
    });
};

export const useGetAllEntityKeys = (sourceId: string) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const allEntityKeys = useQuery<Array<EntityApiKey>, ApiError>({
        queryKey: [QueryKeys.GET_ALL_ENTITY_KEYS, projectUuid, sourceId],
        queryFn: () => getAllEntityKeys(projectUuid, sourceId),
        refetchOnMount: 'always',
        enabled: !!sourceId && !!projectUuid,
    });
    return allEntityKeys;
};

const createEntityKey = async (data: CreateEntityAPIKey, projectId: string) =>
    sortmentApi<CreateEntityAPIKeyResponse>({
        url: `/projects/${projectId}/entity-api-key`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useCreateEntityKey = () => {
    const { showToastError } = useNotify();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    return useMutation<
        CreateEntityAPIKeyResponse,
        ApiError,
        { payload: CreateEntityAPIKey }
    >(({ payload }) => createEntityKey(payload, projectUuid), {
        mutationKey: [QueryKeys.CREATE_ENTITY_KEY, projectUuid],
        onError: () => {
            showToastError({
                title: t('event_source.create_entity_error'),
            });
        },
    });
};

const deleteEntityKey = async (keyId: string, projectUuid: string) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/entity-api-key/${keyId}`,
        method: 'DELETE',
        body: undefined,
    });

export const useDeleteEntityKey = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<ApiSuccessEmpty, ApiError, { keyId: string }>(
        ({ keyId }) => deleteEntityKey(keyId, projectUuid),
        {
            mutationKey: [QueryKeys.DELETE_ENTITY_KEY],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('event_source.delete_entity_success'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('event_source.delete_entity_error'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const getEvent = async (projectUuid: string, eventId: string, source: string) =>
    sortmentApi<EventMapperSchema>({
        url: `/projects/${projectUuid}/events/sources/${source}/mappers/${eventId}`,
        method: 'GET',
        body: undefined,
    });

export const useGetEventById = ({
    eventId,
    source,
}: {
    eventId: string;
    source: string;
}) => {
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();

    return useQuery<EventMapperSchema, ApiError>({
        queryKey: [QueryKeys.GET_EVENT, projectUuid, eventId],
        queryFn: () => getEvent(projectUuid, eventId, source),
        enabled: !!projectUuid && !!eventId && !!source,
        retry: true,
    });
};
const CreateEvent = async (
    payload: CreateEventMapperSchema,
    projectUuid: string,
) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/events/sources/${payload.source}/mappers`,
        method: 'POST',
        body: JSON.stringify(payload),
    });

export const useCreateEvent = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const history = useHistory();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    return useMutation<ApiSuccessEmpty, ApiError, CreateEventMapperSchema>(
        (payload) => CreateEvent(payload, projectUuid),
        {
            mutationKey: [QueryKeys.CREATE_EVENT, projectUuid],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('events.create_event_success'),
                });
                await queryClient.invalidateQueries([
                    QueryKeys.GET_ALL_WHITELISTED_EVENTS,
                ]);
                history.push(`/projects/${projectUuid}/events`);
            },
            onError: (error) => {
                showToastError({
                    title: t('events.create_event_failed'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const UpdateEvent = async (
    payload: UpdateEventMapperSchema,
    projectUuid: string,
    eventUuid: string,
    source: string,
) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/events/sources/${source}/mappers/${eventUuid}`,
        method: 'PUT',
        body: JSON.stringify(payload),
    });

export const useUpdateEvent = (eventUuid: string, source: string) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const history = useHistory();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    return useMutation<ApiSuccessEmpty, ApiError, UpdateEventMapperSchema>(
        (payload) => UpdateEvent(payload, projectUuid, eventUuid, source),
        {
            mutationKey: [QueryKeys.CREATE_EVENT, projectUuid, eventUuid],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('events.update_event_success'),
                });
                await queryClient.invalidateQueries([
                    QueryKeys.GET_ALL_WHITELISTED_EVENTS,
                ]);
                await queryClient.invalidateQueries([QueryKeys.GET_EVENT]);
                history.push(`/projects/${projectUuid}/events`);
            },
            onError: (error) => {
                showToastError({
                    title: t('events.update_event_failed'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};
const getColumns = async (projectUuid: string) =>
    sortmentApi<EventColumn[]>({
        url: `/projects/${projectUuid}/events/column`,
        method: 'GET',
        body: undefined,
    });

export const useAllColumns = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    return useQuery<EventColumn[], ApiError>({
        queryKey: [QueryKeys.EVENT_COLUMNS, projectUuid],
        queryFn: () => getColumns(projectUuid),
        enabled: !!projectUuid,
    });
};

const createColumn = async (
    projectUuid: string,
    payload: CreateEventColumnFromLabel,
) =>
    sortmentApi<EventColumn>({
        url: `/projects/${projectUuid}/events/column`,
        method: 'POST',
        body: JSON.stringify(payload),
    });

export const useCreateColumn = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    return useMutation<EventColumn, ApiError, CreateEventColumnFromLabel>(
        (payload) => createColumn(projectUuid, payload),
        {
            mutationKey: [QueryKeys.CREATE_EVENT_COLUMNS, projectUuid],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('events.create_column_label_success'),
                });
                await queryClient.invalidateQueries([QueryKeys.EVENT_COLUMNS]);
            },
            onError: (error) => {
                showToastError({
                    title: t('events.create_column_label_failed'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};
