import InputErrorText from '@components/common/InputErrorText';
import {
    createStyles,
    Stack,
    Text,
    TextInput as MantineTextInput,
    type TextInputProps as MantineInputProps,
} from '@mantine/core';
import React from 'react';
import { FontWeights } from '../../../../mantineTheme';

interface TextInputProps extends MantineInputProps {
    error?: React.ReactNode;
    description?: React.ReactNode;
}

const useStyles = createStyles(() => ({
    input: {
        border: 'none',
        boxShadow: 'none',
        '&:hover': {
            border: 'none',
            boxShadow: 'none',
            backgroundColor: 'rgb(var(--color-black)/0.02)',
        },
        '&:focus': {
            border: 'none',
            boxShadow: 'none',
        },
        '&:focus-within': {
            backgroundColor: 'rgb(var(--color-black)/0.02)',
            cursor: 'text',
            caretColor: 'black',
        },
        '::placeholder': {
            color: 'rgb(var(--color-gray-500))',
            opacity: 1,
            fontWeight: FontWeights.normal,
        },
    },
}));

const TransparentInput = React.forwardRef<HTMLInputElement, TextInputProps>(
    ({ error, description, ...rest }, ref) => {
        const { classes } = useStyles();
        return (
            <Stack className="gap-1.5">
                <MantineTextInput
                    {...rest}
                    ref={ref}
                    classNames={{ input: classes.input }}
                />
                {error && <InputErrorText value={error} />}
                {description && (
                    <Text className="text-sm font-medium text-gray-500">
                        {description}
                    </Text>
                )}
            </Stack>
        );
    },
);

export default TransparentInput;
