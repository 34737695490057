import { Button, Menu } from '@mantine/core';
import { type FloatingPosition } from '@mantine/core/lib/Floating';
import { DotsThree } from '@phosphor-icons/react';
import { type ReactNode } from 'react';
import { ButtonVariant } from '../../../mantineTheme';

export interface CustomMenuItemProps<MData> {
    leftSection: ReactNode;
    children: ReactNode;
    subtitle?: ReactNode;
    rightSection?: ReactNode;
    color?: string;
    onClick?: (data: MData) => void;
    isDivider?: boolean;
    data?: MData;
}

export interface CustomMenuProps<MData> {
    menuItems: CustomMenuItemProps<MData>[];
    data?: MData;
    icon?: ReactNode;
    optionsPosition?: FloatingPosition;
    width?: number;
}

function CustomMenuItem<MData>({
    leftSection,
    rightSection,
    color,
    children,
    subtitle,
    onClick,
    isDivider,
    data,
}: CustomMenuItemProps<MData>) {
    return (
        <div>
            <Menu.Item
                color={color}
                rightSection={rightSection}
                onClick={(event) => {
                    event.stopPropagation();
                    if (onClick) onClick(data as MData);
                }}
                className="px-1.5 py-1 hover:bg-shade-4"
            >
                <div className="flex flex-row justify-start items-center px-2 py-2 text-sm font-medium text-gray-800 gap-1.5">
                    <span>{leftSection}</span>
                    {children}
                </div>
                {!!subtitle && (
                    <p className="mx-3 my-0 text-gray-600">{subtitle}</p>
                )}
            </Menu.Item>
            {isDivider && <Menu.Divider className="mx-4" />}
        </div>
    );
}

function CustomMenu<MData>({
    menuItems,
    data,
    icon = <DotsThree weight="bold" size={14} />,
    optionsPosition = 'bottom-end',
    width,
}: CustomMenuProps<MData>) {
    return (
        <div
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <Menu
                withinPortal
                shadow="lg"
                position={optionsPosition}
                offset={-4}
                width={width}
            >
                <Menu.Target>
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        className="rounded-lg p-1.5"
                        style={{
                            border: '1px solid rgb(var(--color-gray-200))',
                        }}
                    >
                        {icon && icon}
                    </Button>
                </Menu.Target>

                <Menu.Dropdown className="py-1 border-base shadow-card">
                    {menuItems.map((item, index) => (
                        <CustomMenuItem key={index} {...item} data={data} />
                    ))}
                </Menu.Dropdown>
            </Menu>
        </div>
    );
}

export default CustomMenu;
