import GenericOption from '@components/common/Card/GenericOption';
import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import { Source } from '@components/ProjectSettings/EventSourcePanel';
import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import { Flex, Text } from '@mantine/core';
import { CaretRight, WebhooksLogo } from '@phosphor-icons/react';
import Fuse from 'fuse.js';
import React, { useMemo, useState } from 'react';
import { ButtonVariant } from '../../../mantineTheme';

interface AddSourceModalProps {
    showEventModal: boolean;
    eventModalClose: () => void;
    setEventSource: (data: Source) => void;
    webhookModalOpen: () => void;
}

const getIconBySource = (source: string) => {
    switch (source) {
        case Source.WEBHOOK:
            return <WebhooksLogo size={14} weight="duotone" />;
        default:
            return null;
    }
};

const AddSourceModal: React.FC<AddSourceModalProps> = ({
    showEventModal,
    eventModalClose,
    setEventSource,
    webhookModalOpen,
}) => {
    const { t } = useLocale();
    const [searchDebounce, setSearchDebounce] = useState<string>('');

    const filterItems = useMemo(() => {
        let fieldsData = Object.values(Source);

        if (searchDebounce !== '') {
            const fuse = new Fuse(fieldsData, {
                threshold: 0.3,
            });

            fieldsData = fuse.search(searchDebounce).map((res) => res.item);
        }

        return fieldsData;
    }, [searchDebounce]);

    return (
        <Modal
            opened={showEventModal}
            onClose={() => eventModalClose()}
            keepMounted={false}
            title={t('event_source.add_source')}
            size="lg"
            footerRightSection={
                <ModalFooter
                    showSecondaryButton={true}
                    secondaryButtonVariant={ButtonVariant.OUTLINED}
                    secondaryButtonClick={eventModalClose}
                    secondaryText={t(
                        'custom_metric.dimension_modal_cancel_button',
                    )}
                    primaryText={undefined}
                    primaryButtonClick={undefined}
                    showPrimaryButton={undefined}
                    primaryLeftIcon={undefined}
                    primaryRightIcon={undefined}
                    secondaryLeftIcon={undefined}
                    secondaryRightIcon={undefined}
                    primaryButtonVariant={undefined}
                    isLoading={undefined}
                    primaryButtonDisabled={undefined}
                    primaryButtonCustomClass={undefined}
                />
            }
        >
            <SearchInput
                onChange={(e) => setSearchDebounce(e.target.value)}
                placeholder={t('event_source.search_source_connectors')}
            />

            {filterItems.map((item, index) => (
                <GenericOption
                    customClass={'!w-full !mt-3.5'}
                    key={index}
                    title={
                        <Flex align="center" className="space-x-1">
                            {getIconBySource(item)}
                            <Text className="text-sm font-medium text-gray-800">
                                {item}
                            </Text>
                        </Flex>
                    }
                    rightSection={<CaretRight weight="regular" />}
                    onClick={() => {
                        setEventSource(item);
                        eventModalClose();
                        webhookModalOpen();
                    }}
                />
            ))}
        </Modal>
    );
};

export default AddSourceModal;
