import { type CommunicationChannels } from '@hooks/useCommunicationChannels';
import { type Project } from '@lightdash/common';
import { createContext, useContext, type FC } from 'react';

type ProjectContextType = {
    projectData: Project | undefined;
    communicationChannels: CommunicationChannels | undefined;
};

const Context = createContext<ProjectContextType | undefined>(undefined);

export const ProjectProvider: FC<
    React.PropsWithChildren<ProjectContextType>
> = ({ projectData, communicationChannels, children }) => {
    return (
        <Context.Provider value={{ projectData, communicationChannels }}>
            {children}
        </Context.Provider>
    );
};

export function useProjectContext(): ProjectContextType {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error(
            'useProjectContext must be used within a ProjectProvider',
        );
    }
    return context;
}
