import PropertySelect from '@components/common/Select/PropertySelect';
import { useLocale } from '@hooks/useLocale';
import { type JourneyDataSchema } from '@lightdash/common';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';
import { JourneyPropertySelectorFieldItem } from './JourneyPropertySelector';
import { type ActionEventField } from './types';
import {
    useJourneyEventProperties,
    type JourneyProperty,
} from './useJourneyProperties';

interface JourneyEventPropertySelectorProps {
    targetElement: React.ReactNode;
    journeyDataSchema: JourneyDataSchema;
    onSubmit: (item: JourneyProperty) => void;
    eventField: ActionEventField;
}

const JourneyEventPropertySelector: React.FC<
    JourneyEventPropertySelectorProps
> = ({ targetElement, journeyDataSchema, onSubmit, eventField }) => {
    const journeyProperties = useJourneyEventProperties(
        journeyDataSchema,
        eventField.table,
    );
    const [opened, { open, close }] = useDisclosure();
    const { t } = useLocale();
    return (
        <PropertySelect<JourneyProperty>
            items={journeyProperties}
            showGroup={false}
            headerRightSection={null}
            onSubmit={(field: JourneyProperty[]) => {
                if (!field[0]) return;
                onSubmit(field[0]);
                close();
            }}
            itemTemplate={({ item }) => {
                return <JourneyPropertySelectorFieldItem item={item} />;
            }}
            opened={opened}
            close={close}
            open={open}
            withinPortal={true}
            targetButton={targetElement}
            showSearch={true}
            searchKeys={['label']}
            searchPlaceholder={t('audience_filters.search_filter_label')}
            allowMultipleSelection={false}
            showAllItemsGroup={false}
        />
    );
};

export default JourneyEventPropertySelector;
