import TextInput from '@components/common/Inputs/TextInput';
import Select from '@components/common/Select';
import { TableTypeOptions } from '@components/Table';
import { useCreateUtmKey, useGetUtmKeys } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { ShowDataType } from '@lightdash/common';
import { Box, Button, CloseButton } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { ButtonVariant } from '../../../../../../mantineTheme';

export type UtmTableData = {
    key: string;
    value: string;
    type: 'internal' | 'external';
    required: boolean;
    error: string;
};

const InputBox = ({
    defaultValue,
    onChange: handleChange,
    error,
    ...rest
}: {
    defaultValue: string;
    onChange: (value: string) => void;
    error: string;
}) => {
    const [value, setValue] = useState<string>(defaultValue);
    const { t } = useLocale();
    return (
        <TextInput
            defaultValue={value}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            onBlur={() => {
                handleChange(value);
            }}
            placeholder={t('common.placeholder.table_row_text_input')}
            sx={{
                input: {
                    border: 0,
                    boxShadow: 'none',
                    padding: 0,
                    paddingLeft: 8,
                    '&:hover': {
                        background: 'rgba(var(--color-gray-50))',
                    },
                    '&:focus-within': {
                        border: 0,
                        boxShadow: 'none',
                    },
                },
            }}
            error={error}
            {...rest}
        />
    );
};

type UtmTrackingProps = {
    data: UtmTableData[];
    setData: (utmData: UtmTableData[]) => void;
};

const UtmTracking: React.FC<UtmTrackingProps> = ({
    data: utmData,
    setData,
}) => {
    const { t } = useLocale();
    const { data: utmKeys } = useGetUtmKeys();
    const { mutate } = useCreateUtmKey();

    const handleValueChange = (val: string, key: string) => {
        const newData = [...utmData];
        const index = newData.findIndex((item) => item.key === key);
        newData.splice(index, 1, {
            ...utmData[index],
            value: val.trim(),
            error: val.trim() ? '' : utmData[index].error,
        });
        setData([...newData]);
    };

    const handleDelete = (val: string) => {
        const index = utmData.findIndex((utm) => utm.key === val);
        const newData = [...utmData];
        newData.splice(index, 1);
        setData([...newData]);
    };

    const allUtmKeysUsed = useMemo(() => {
        if (utmData) {
            return utmData.map((item) => item.key);
        }
        return [];
    }, [utmData]);

    const table = useReactTable<UtmTableData>({
        columns: [
            {
                header: t(
                    'campaigns_builder.tracking.utm_tracking_table.parameter_key',
                ),
                accessorKey: 'key',
                cell: ({ row }) => {
                    const isInternalKey =
                        utmKeys?.find((utm) => row.original.key === utm.key)
                            ?.type === 'internal';
                    const options = utmKeys
                        ? utmKeys
                              .filter(
                                  (utmKey) =>
                                      !allUtmKeysUsed.includes(utmKey.key) ||
                                      utmKey.key === row.original.key,
                              )
                              .map((utmKey) => ({
                                  value: utmKey.key,
                                  label: utmKey.key,
                              }))
                        : [];
                    return (
                        <Select
                            data={options}
                            placeholder={t(
                                'campaigns_builder.tracking.utm_tracking_table.parameter_key_placeholder',
                            )}
                            readOnly={isInternalKey}
                            searchable
                            creatable
                            defaultValue={row.original.key}
                            value={row.original.key}
                            onChange={(value: string) => {
                                const newUtmData = [...utmData];
                                newUtmData.splice(row.index, 1, {
                                    ...utmData[row.index],
                                    key: value,
                                });
                                setData([...newUtmData]);
                            }}
                            shouldCreate={(query) => {
                                const formattedQuery = query.trim();
                                return utmKeys
                                    ? !utmKeys
                                          .map((utmKey) => utmKey.key)
                                          .includes(formattedQuery)
                                    : true;
                            }}
                            onCreate={(query) => {
                                const formattedQuery = query.trim();
                                if (formattedQuery.length > 0) {
                                    const item = {
                                        value: formattedQuery,
                                        label: formattedQuery,
                                    };
                                    const newUtmData = [...utmData];
                                    newUtmData.splice(row.index, 1, {
                                        ...utmData[row.index],
                                        key: formattedQuery,
                                    });
                                    mutate({
                                        key: formattedQuery,
                                        required: false,
                                        modifiable: false,
                                        type: 'external',
                                        value: {
                                            type: 'static',
                                            value: formattedQuery,
                                        },
                                    });
                                    setData([...newUtmData]);
                                    return item;
                                }
                            }}
                            readOnlyClasses={`w-[12.375rem] ${
                                row.original.error ? 'mb-7' : ''
                            }`}
                            styles={{
                                input: {
                                    border: 'none',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        background:
                                            'rgba(var(--color-gray-50))',
                                    },
                                },
                            }}
                        />
                    );
                },
            },
            {
                header: t(
                    'campaigns_builder.tracking.utm_tracking_table.parameter_value',
                ),
                accessorKey: 'value',
                cell: ({ row }) => {
                    return (
                        <Box>
                            <InputBox
                                defaultValue={row.original.value}
                                onChange={(val) => {
                                    handleValueChange(val, row.original.key);
                                }}
                                error={row.original.error}
                            />
                        </Box>
                    );
                },
            },
            {
                header: '-',
                accessorKey: 'required',
                cell: ({ row }) =>
                    !row.original.required && (
                        <CloseButton
                            onClick={() => handleDelete(row.original.key)}
                            className="border border-gray-200 rounded-lg h-7 w-7"
                        />
                    ),
            },
        ],
        data: utmData,
        getCoreRowModel: getCoreRowModel(),
        filterFns: {
            fuzzy: () => true,
        },
    });

    const handleAddItem = () => {
        setData([
            ...utmData,
            {
                key: '',
                value: '',
                type: 'external',
                required: false,
                error: '',
            },
        ]);
    };
    return (
        <Box>
            <TableTypeOptions
                viewType={ShowDataType.LIST}
                tableName={t('campaigns_builder.tracking.utm_tracking')}
                table={table}
                customClass={
                    'border rounded-t-lg max-h-[34rem] border-b-0 overflow-scroll'
                }
                cellHeight={'2rem'}
                gridItemBorder={false}
            />
            <Box className="border rounded-b-lg p-3 bg-white">
                <Button
                    variant={ButtonVariant.OUTLINED_ACCENTED}
                    leftIcon={<PlusCircle color="rgb(var(--color-blu-800))" />}
                    onClick={handleAddItem}
                >
                    {t('campaigns_builder.tracking.add_item')}
                </Button>
            </Box>
        </Box>
    );
};

export default UtmTracking;
