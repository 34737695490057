import { Box, Stack } from '@mantine/core';
import React, { useMemo } from 'react';

export interface GenericOptionProps {
    customClass?: String;
    title: string | React.ReactNode;
    subtitle?: React.ReactNode;
    rightSection?: React.ReactNode;
    iconComponent?: React.ReactNode;
    onClick?: () => void;
    border?: boolean;
    boxShadow?: boolean;
}

const GenericOption: React.FC<GenericOptionProps> = ({
    customClass,
    iconComponent,
    title,
    rightSection,
    subtitle,
    onClick,
    border = true,
    boxShadow = true,
    ...rest
}) => {
    const borderClass = border ? 'rounded-lg border-base' : '';
    const boxShadowClass = boxShadow ? 'shadow-card' : '';
    const optionClass = useMemo(
        () =>
            `w-[574px] p-3.5 justify-between items-start gap-[13px] inline-flex cursor-pointer hover:bg-shade-2 hover:rounded-md bg-white ${boxShadowClass} ${borderClass} ${customClass}`,
        [customClass, borderClass, boxShadowClass],
    );

    return (
        <Box className={optionClass} onClick={onClick} {...rest}>
            <Box className="flex-row justify-center items-center gap-2.5 inline-flex ">
                {Boolean(iconComponent) && (
                    <>
                        <Box className="w-4 h-4">{iconComponent}</Box>
                    </>
                )}
                <Stack className="gap-1.5">
                    <Box className="text-sm font-normal text-gray-800">
                        {title}
                    </Box>
                    {Boolean(subtitle) && (
                        <Box className="text-xs text-gray-600">{subtitle}</Box>
                    )}
                </Stack>
            </Box>
            {Boolean(rightSection) && <div>{rightSection}</div>}
        </Box>
    );
};

export default React.memo(GenericOption);
