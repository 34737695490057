import TransparentInput from '@components/common/Inputs/TransparentInput';
import { useIsTruncated } from '@hooks/useIsTruncated';
import { useLocale } from '@hooks/useLocale';
import { Tooltip } from '@mantine/core';

import React, { useState } from 'react';

interface InputCellProps {
    updateTable: (value: string) => void;
    inputValue: string;
}

const InputCell = ({ updateTable, inputValue }: InputCellProps) => {
    const { t } = useLocale();
    const [value, setValue] = useState(inputValue);
    const { ref: truncatedRef, isTruncated } =
        useIsTruncated<HTMLInputElement>();
    return (
        <Tooltip
            label={value}
            disabled={!isTruncated || value.length < 40}
            color="dark"
            position="bottom"
            styles={{
                tooltip: {
                    backgroundColor: 'black',
                    color: 'white',
                },
            }}
        >
            <TransparentInput
                value={value}
                onChange={(e) => setValue(e.target.value)}
                ref={truncatedRef}
                placeholder={t('common.type_here')}
                onBlur={() => {
                    updateTable(value);
                }}
            />
        </Tooltip>
    );
};

export default React.memo(InputCell);
