import { Container, Footer, getDefaultZIndex } from '@mantine/core';
import { FOOTER_HEIGHT, PAGE_CONTENT_WIDTH } from '@utils/constants';
import React from 'react';

const PageFooter: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <Footer
            height={FOOTER_HEIGHT}
            className="fixed flex items-center bg-white border-t rounded-none border-black/6"
            zIndex={getDefaultZIndex('app')}
            withBorder={false}
        >
            <Container
                className={`flex flex-row items-center justify-between shrink-0 px-28`}
                style={{
                    width: `${PAGE_CONTENT_WIDTH}px`,
                    minWidth: `${PAGE_CONTENT_WIDTH}px`,
                }}
            >
                {children}
            </Container>
        </Footer>
    );
};

export default PageFooter;
