import { sortmentApi } from '@api/index';
import { type ApiError, type ApiFlashResults } from '@lightdash/common';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';

const getFlash = async () =>
    sortmentApi<ApiFlashResults>({
        method: 'GET',
        url: '/flash',
        body: undefined,
    });

export const useFlashMessages = () =>
    useQuery<ApiFlashResults, ApiError>({
        queryKey: [QueryKeys.FLASH],
        queryFn: getFlash,
        cacheTime: 200,
        refetchInterval: false,
    });
