import {
    ActionType,
    JourneyGroup,
    type JourneyBlocksMetadata,
} from '../types/journeys';

export const journeyBlocks: JourneyBlocksMetadata[] = [
    {
        id: '1',
        title: 'Delay',
        description: undefined,
        group: JourneyGroup.DELAY,
        actions: [ActionType.WAIT],
    },
    {
        id: '2',
        title: 'Hold until',
        description: undefined,
        group: JourneyGroup.DELAY,
        actions: [ActionType.WAIT_UNTIL],
    },
    {
        id: '3',
        title: 'Filter',
        description: undefined,
        group: JourneyGroup.CONDITION,
        actions: [ActionType.FILTER],
    },
    // {
    //     id: '4',
    //     title: 'Split',
    //     description: undefined,
    //     group: JourneyGroup.CONDITION,
    //     actions: [ActionType.SPLIT],
    // },
    {
        id: '5',
        title: 'Send message',
        description: undefined,
        group: JourneyGroup.ACTION,
        actions: [ActionType.SEND_MESSAGE],
    },
    {
        id: '6',
        title: 'Call API',
        description: undefined,
        group: JourneyGroup.ACTION,
        actions: [ActionType.API],
    },
];
