import SchemaBuilderPage from '@components/SchemaBuilder/Builder/SchemaBuilderPage';
import { useSchemaInitialData } from '@components/SchemaBuilder/useSchemaInitialData';
import { RelationTableType } from '@lightdash/common';
import { useProjectContext } from '@providers/ProjectProvider';
import { SchemaProvider } from '@providers/SchemaProvider';
import { useHistory } from 'react-router-dom';

const CreateSchema = () => {
    const history = useHistory();
    const { projectData: activeProject } = useProjectContext();
    const initialState = useSchemaInitialData(activeProject);
    initialState.schemaPayload.type = RelationTableType.PRIMARY;

    if (!activeProject?.needsRelation) {
        history.push(`/projects/${activeProject?.projectUuid}/relations`);
        return null;
    }

    return (
        <SchemaProvider initialState={initialState}>
            <SchemaBuilderPage />
        </SchemaProvider>
    );
};

export default CreateSchema;
