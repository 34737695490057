import { type EditorConfig } from 'grapesjs';
import plugin from 'grapesjs-preset-newsletter';

import useFileUpload from '@hooks/useFileUpload';
import { type ApiResponse } from '@lightdash/common';

export const useGrapesEditorConfig = (): {
    newAsset: string | undefined;
    editorConfig: EditorConfig;
} => {
    const { mutate, data: uploadData } = useFileUpload<
        { url: string } & ApiResponse
    >();

    return {
        newAsset: uploadData?.url, // INFO - This state which we use to add any new images added by user
        editorConfig: {
            container: '#html-editor',
            plugins: [plugin],
            storageManager: false,
            pluginsOpts: {
                [`${plugin}`]: {
                    options: {},
                },
            },
            deviceManager: {
                devices: [
                    {
                        id: 'desktop',
                        name: 'Desktop',
                        width: '', // default size,
                    },
                    {
                        id: 'tablet',
                        name: 'Tablet',
                        width: '760px',
                    },
                    {
                        id: 'mobile',
                        name: 'Mobile portrait',
                        width: '320px',
                    },
                ],
            },
            panels: {
                defaults: [],
            },
            assetManager: {
                uploadFile: (e) => {
                    let file: FileList | null = null;
                    if (e.dataTransfer) {
                        file = e.dataTransfer.files;
                    } else if (e.target instanceof HTMLInputElement) {
                        file = e.target.files;
                    }
                    if (file) {
                        mutate(file);
                    }
                },
                showUrlInput: false,
            },
        },
    };
};
