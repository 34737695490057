import Modal from '@components/common/modal/Modal';
import TemplateBuilderManager from '@components/Templates/Builder/TemplateBuilderManager';
import { useGetProviderMetada } from '@hooks/useChannels';
import { type Campaign, type ProviderTemplateDetails } from '@lightdash/common';
import { Box } from '@mantine/core';
import {
    useCampaignContext,
    type TemplateDetails,
} from '@providers/CampaignProvider';
import React, { useCallback } from 'react';

interface JourneyTemplateSelectionModalProps {
    opened: boolean;
    closeTemplateSelection: () => void;
    communicationDetails: Campaign['communicationDetails'] | undefined;
    setJourneyContentPayload: (
        content: Partial<TemplateDetails> | null,
        communicationDetails: Campaign['communicationDetails'] | undefined,
    ) => void;
}

const JourneyTemplateSelectionModal: React.FC<
    JourneyTemplateSelectionModalProps
> = ({
    opened,
    closeTemplateSelection,
    communicationDetails,
    setJourneyContentPayload,
}) => {
    const { setCampaignContentPayload } = useCampaignContext(
        (context) => context.actions,
    );

    const { data: providerMetadata } = useGetProviderMetada(
        communicationDetails?.providerId ?? '',
        communicationDetails?.channel ?? '',
    );

    const handleTemplateSelect = useCallback(
        (value: ProviderTemplateDetails) => {
            if (!value) return;
            setJourneyContentPayload(
                {
                    id: value.id,
                },
                communicationDetails,
            );
            setCampaignContentPayload({
                id: value.id,
            });
        },
        [
            setJourneyContentPayload,
            setCampaignContentPayload,
            communicationDetails,
        ],
    );

    return (
        <Modal
            opened={opened}
            onClose={closeTemplateSelection}
            title={'Select your email content'}
            size="lg"
        >
            {providerMetadata && communicationDetails?.channel && (
                <Box className="w-full">
                    <TemplateBuilderManager
                        integration={providerMetadata}
                        isSingleSelect={true}
                        handleTemplateSelect={handleTemplateSelect}
                        integrationId={communicationDetails?.id}
                        channel={communicationDetails?.channel}
                        onClose={undefined}
                        editMode={false}
                    />
                </Box>
            )}
        </Modal>
    );
};

export default React.memo(JourneyTemplateSelectionModal);
