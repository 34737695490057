import { Button, Flex } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import React from 'react';
import { ButtonVariant } from '../../../../mantineTheme';

interface ModalFooterProps {
    primaryText: string;
    secondaryText: string;
    primaryButtonClick: () => void;
    secondaryButtonClick: () => void;
}

const ModalFooter: React.FC<ModalFooterProps> = ({
    primaryText,
    secondaryText,
    primaryButtonClick,
    secondaryButtonClick,
}) => {
    return (
        <Flex className="pt-2 gap-2" justify={'flex-end'}>
            <Button
                variant={ButtonVariant.OUTLINED}
                onClick={primaryButtonClick}
            >
                {primaryText}
            </Button>
            <Button variant="filled" onClick={secondaryButtonClick}>
                {secondaryText}
                <CaretRight weight="duotone" color="rgb(var(--color-white))" />
            </Button>
        </Flex>
    );
};

export default ModalFooter;
