import { RelationTableType } from '@lightdash/common';
import {
    Clock,
    GridFour,
    User,
    UsersThree,
    type IconProps,
} from '@phosphor-icons/react';
import { type CSSProperties } from 'styled-components';

interface TableIconProps extends IconProps {
    type: RelationTableType;
    style?: CSSProperties;
}

const TableIcon = ({ type, ...rest }: TableIconProps) => {
    switch (type) {
        case RelationTableType.PRIMARY: {
            return (
                <User
                    color={'rgb(var(--color-blu-800)'}
                    weight="duotone"
                    {...rest}
                />
            );
        }
        case RelationTableType.RELATED: {
            return (
                <GridFour
                    color={'rgb(var(--color-green)'}
                    weight="duotone"
                    {...rest}
                />
            );
        }
        case RelationTableType.EVENT: {
            return (
                <Clock
                    color={'rgb(var(--color-mustard-800)'}
                    weight="duotone"
                    {...rest}
                />
            );
        }
        case RelationTableType.AUDIENCE: {
            return (
                <UsersThree
                    color={'rgb(var(--color-pink-800)'}
                    weight="duotone"
                    {...rest}
                />
            );
        }
        default:
            return (
                <User
                    color={'rgb(var(--color-blu-800)'}
                    weight="duotone"
                    {...rest}
                />
            );
    }
    return null;
};

export default TableIcon;
