import {
    ChatContainer,
    Message,
    MessageInput,
    MessageList,
    TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { useAIBuilderMutation } from '@hooks/useAIBuilder';
import { useLocale } from '@hooks/useLocale';
import useSearchParams from '@hooks/useSearchParams';
import {
    AIContentType,
    Author,
    type MessageContext,
    type MessageTypeMap,
    type WidgetMessage,
} from '@lightdash/common';
import { Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAudienceContext } from '@providers/AudienceProvider';
import React, { useCallback, useEffect, useState } from 'react';
import { Star } from 'react-feather';
import { ButtonVariant } from '../../mantineTheme';
import { AiWidgetWrapper, TypingIndicatorStyled } from './AIStyle';
import { MessageValue, type AIWidgetProps } from './types';

function AIWidget<C extends MessageContext>({
    onMessageReceive,
    onIsLoading,
    context,
}: AIWidgetProps<C>) {
    const [opened, { open, close }] = useDisclosure(false);
    const { actions, state } = useAudienceContext((contextVal) => contextVal);
    const { setSqlQuery } = actions;
    const { audiencePayload } = state;
    const audienceTemplateUuid = useSearchParams('templateId');
    const AIBuilderMutation = useAIBuilderMutation();
    const { isError, mutateAsync, data, reset, isLoading } = AIBuilderMutation;
    const [messages, setMessages] = useState<WidgetMessage[]>([]);
    const { t } = useLocale();

    useEffect(() => {
        setMessages([]);
        if (!audienceTemplateUuid && audiencePayload.nestedMetricQuery) {
            setSqlQuery('');
        }
    }, [
        context,
        setMessages,
        setSqlQuery,
        audienceTemplateUuid,
        audiencePayload.nestedMetricQuery,
    ]);

    useEffect(() => {
        if (isLoading) {
            onIsLoading(isLoading);
            return;
        }

        if (isError) {
            open();
            onIsLoading(false);
            const newMessage = {
                id: `${messages.length}`,
                author: Author.SYSTEM,
                context: context,
                content: {
                    content_type: AIContentType.TEXT,
                    value: t('ai_widget_error_message'),
                },
                payload: undefined,
            };

            setMessages((prevMessages) => [...prevMessages, newMessage]);
        }

        if (data) {
            open();
            onMessageReceive(data.message as MessageTypeMap[C]);
            onIsLoading(false);

            const newMessage = {
                id: data.message.id,
                author: data.message.author,
                context: context,
                content: {
                    content_type: AIContentType.TEXT,
                    value: data.message.content.value,
                },
                payload: undefined,
            };

            setMessages((prevMessages) => [...prevMessages, newMessage]);
        }
        reset();
    }, [
        data,
        isLoading,
        open,
        onIsLoading,
        setMessages,
        onMessageReceive,
        reset,
        context,
        isError,
        messages.length,
        t,
    ]);

    const updateMessages = useCallback(async (): Promise<void> => {
        const newData = messages.filter((item) => item.author === Author.USER);

        if (!messages.length) return;

        if (messages[messages.length - 1].author === Author.USER) {
            {
                await mutateAsync({
                    messages: newData,
                });
            }
        }
    }, [messages, mutateAsync]);

    useEffect(() => {
        void updateMessages();
    }, [updateMessages]);

    const sanitizeInputText = useCallback((inputText: string) => {
        const temporaryElement = document.createElement('div');
        temporaryElement.innerHTML = inputText;
        return temporaryElement.textContent || temporaryElement.innerText || '';
    }, []);

    const [msgInputValue, setMsgInputValue] = useState<string>('');

    const ChatMessageInput: React.FC<{}> = useCallback(() => {
        if (isLoading) return null;
        return (
            <MessageInput
                attachButton={false}
                value={msgInputValue}
                onChange={(text) => {
                    const sanitizedValue = sanitizeInputText(text);
                    setMsgInputValue(sanitizedValue);
                }}
                placeholder={t('aiwidget.type_message_here')}
                onSend={async (inputText: string) => {
                    const newMessage = {
                        id: `${messages.length}`,
                        author: Author.USER,
                        context: context,
                        content: {
                            content_type: AIContentType.TEXT,
                            value: inputText,
                        },
                        payload: undefined,
                    };

                    setMessages((prevMessages) => [
                        ...prevMessages,
                        newMessage,
                    ]);
                    setMsgInputValue('');
                }}
            />
        );
    }, [
        isLoading,
        messages,
        t,
        context,
        sanitizeInputText,
        setMsgInputValue,
        msgInputValue,
    ]);

    return (
        <>
            <Modal
                styles={(_params) => ({
                    header: {
                        borderBottom:
                            '3px solid rgb(var(--color-black)/0.02) !important',
                    },
                    title: {
                        display: 'flex !important',
                        alignItems: 'center !important',
                        gap: '6px !important',
                    },
                    content: {
                        maxHeight: 'unset !important',
                    },
                    inner: {
                        paddingBottom: '2rem !important',
                        top: 'unset !important',
                    },
                    body: {
                        padding: '0px !important',
                    },
                })}
                opened={opened}
                onClose={close}
                closeOnClickOutside={false}
                title={
                    <>
                        <Star className="h-3.5 w-3.5 text-blu-800" />{' '}
                        {t('aiwidget.ai_assist')}
                    </>
                }
                transitionProps={{
                    transition: 'slide-up',
                    duration: 200,
                    timingFunction: 'linear',
                }}
            >
                <AiWidgetWrapper
                    style={{
                        height: '500px',
                    }}
                >
                    <ChatContainer>
                        <MessageList
                            typingIndicator={
                                isLoading && (
                                    <TypingIndicator
                                        className="!p-3.5"
                                        content={t('aiwidget.ai_is_thinking')}
                                    />
                                )
                            }
                        >
                            {!messages.length && (
                                <div className="text-sm font-normal text-gray-600 ">
                                    {t('aiwidget.how_can_i_help_you')}
                                </div>
                            )}

                            {!!messages.length &&
                                messages.map((item, index) => (
                                    <Message
                                        key={index}
                                        model={{
                                            message: item?.content.value,
                                            sender:
                                                item.author === Author.USER
                                                    ? MessageValue.USER
                                                    : MessageValue.SYSTEM,
                                            direction:
                                                item.author === Author.USER
                                                    ? MessageValue.OUTGOING_DIRECTION
                                                    : MessageValue.INCOMING_DIRECTION,
                                            position: MessageValue.POSITION,
                                        }}
                                    />
                                ))}
                        </MessageList>
                        {ChatMessageInput({})}
                    </ChatContainer>
                </AiWidgetWrapper>
            </Modal>

            <div>
                <Button
                    onClick={open}
                    className="rounded-lg !bg-white border-shade-6 text-gray-500 p-2.5 w-64 flex justify-start h-9 text-sm font-normal hover:border-blu-800"
                    variant={ButtonVariant.OUTLINED}
                >
                    {isLoading ? (
                        <TypingIndicatorStyled>
                            <TypingIndicator
                                content={t('aiwidget.ai_is_thinking')}
                            />
                        </TypingIndicatorStyled>
                    ) : (
                        <>
                            <Star className="!w-3.5 !h-3.5 text-blu-800 pr-1.5 " />
                            <div className="text-sm font-normal text-gray-500">
                                {t('aiwidget.ai_assist')}
                            </div>
                        </>
                    )}
                </Button>
            </div>
        </>
    );
}

export default AIWidget;
