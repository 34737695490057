import MantineIcon from '@components/common/MantineIcon';
import { useLocale } from '@hooks/useLocale';
import {
    createFilterRuleFromField,
    DimensionType,
    FilterGroupOperator,
    getFilterGroupItemsPropertyName,
    getItemId as getFieldId,
    getItemsFromFilterGroup,
    isAndFilterGroup,
    isFilterGroup,
    type CompiledRelation,
    type FilterableField,
    type FilterGroup,
    type FilterRule,
    type Filters,
} from '@lightdash/common';
import { Box, Button, Stack, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useCallback, useMemo, type FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ButtonVariant } from '../../../mantineTheme';
import {
    getEventFieldWithEventNameCount,
    hasEventNameFilter,
    isEventDimension,
    isEventField,
    isEventNameProperty,
} from '../utils';
import FilterRuleForm from './FilterRuleForm';
import { useFiltersContext } from './FiltersProvider';
import { isFieldAudience, isFilterRuleAudience } from './utils';

type Props = {
    hideButtons?: boolean;
    hideLine?: boolean;
    allowConvertToGroup?: boolean;
    conditionLabel: string;
    fields: FilterableField[];
    filterGroup: FilterGroup;
    isEditMode: boolean;
    onChange: (value: FilterGroup) => void;
    onDelete: () => void;
    nestedLevel?: number;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    groupIndex: number;
    relation: CompiledRelation | undefined;
    showFieldSource?: boolean;
};

const FilterGroupForm: FC<Props> = ({
    hideButtons,
    allowConvertToGroup,
    conditionLabel,
    fields,
    filterGroup,
    isEditMode,
    onChange,
    onDelete,
    nestedLevel = 1,
    filters,
    setFilters,
    groupIndex,
    showFieldSource = true,
    relation,
}) => {
    const { t } = useLocale();
    const items = getItemsFromFilterGroup(filterGroup);
    const marginLeft = nestedLevel > 1 ? `ml-8` : '';
    const { eventsMap, fieldsMap } = useFiltersContext();
    const paddingLeft = useMemo(
        () => (hasEventNameFilter(items, eventsMap) ? `pl-8` : ''),
        [items, eventsMap],
    );
    const onDeleteItem = useCallback(
        (index: number) => {
            if (items.length <= 1) {
                onDelete();
                return;
            }

            const isEvent =
                isEventField(items[index], fieldsMap) &&
                isEventNameProperty(items[index], eventsMap);
            const eventNameCountInGroup = getEventFieldWithEventNameCount(
                items,
                eventsMap,
                fieldsMap,
            );

            let newItems = [...items];

            if (isEvent && eventNameCountInGroup === 1) {
                // Remove all event filters
                newItems = newItems.filter(
                    (item) => !isEventField(item, fieldsMap),
                );
            } else {
                // Remove only the selected item
                newItems.splice(index, 1);
            }

            onChange({
                ...filterGroup,
                [getFilterGroupItemsPropertyName(filterGroup)]: newItems,
            });

            if (newItems.length === 0) {
                onDelete();
            }
        },
        [filterGroup, items, onChange, onDelete, eventsMap, fieldsMap],
    );
    const onChangeItem = useCallback(
        (index: number, item: FilterRule | FilterGroup) => {
            onChange({
                ...filterGroup,
                [getFilterGroupItemsPropertyName(filterGroup)]: [
                    ...items.slice(0, index),
                    item,
                    ...items.slice(index + 1),
                ],
            });
        },
        [filterGroup, items, onChange],
    );

    const onAddFilterRule = useCallback(() => {
        if (fields.length > 0) {
            onChange({
                ...filterGroup,
                [getFilterGroupItemsPropertyName(filterGroup)]: [
                    ...items,
                    createFilterRuleFromField(fields[0]),
                ],
            });
        }
    }, [fields, filterGroup, items, onChange]);

    const onChangeOperator = useCallback(
        (value: FilterGroupOperator) => {
            onChange({
                id: filterGroup.id,
                [value]: items,
            } as FilterGroup);
        },
        [filterGroup, items, onChange],
    );

    const getFilterRulePrefix = useCallback(
        (item: FilterRule) => {
            const fieldId = item.target.fieldId;
            const selectedField = fields.find(
                (field) => getFieldId(field) === fieldId,
            );
            if (
                selectedField &&
                relation &&
                (isFieldAudience(selectedField, relation) ||
                    isFilterRuleAudience(item, relation))
            ) {
                return t('filter_group_form.group_prefix_who');
            }

            switch (selectedField?.type) {
                case DimensionType.EVENT:
                    return t('filter_group_form.group_prefix_who');
                default:
                    return t('filter_group_form.group_prefix_where');
            }
        },
        [fields, relation, t],
    );
    return (
        <Stack pos="relative" spacing="sm" mb="xxs">
            <Stack spacing="xs" style={{ flexGrow: 1 }}>
                {items.map((item, index) => (
                    <Box
                        key={item.id}
                        className={`flex flex-row items-baseline w-full gap-2 ${
                            index > 0 ? marginLeft : ''
                        } ${
                            isEventDimension(item, fieldsMap, eventsMap)
                                ? paddingLeft
                                : ''
                        }`}
                    >
                        {!isFilterGroup(item) ? (
                            <>
                                <Box
                                    className={`w-32 text-sm text-gray-600 text-end `}
                                >
                                    {Boolean(index === 0) ? (
                                        <Text className="text-sm font-normal">
                                            {getFilterRulePrefix(item)}
                                        </Text>
                                    ) : (
                                        <Button
                                            onClick={() =>
                                                onChangeOperator(
                                                    isAndFilterGroup(
                                                        filterGroup,
                                                    )
                                                        ? FilterGroupOperator.or
                                                        : FilterGroupOperator.and,
                                                )
                                            }
                                            disabled={!isEditMode}
                                            variant={ButtonVariant.UNSTYLED}
                                            className="text-sm font-normal"
                                        >
                                            {isAndFilterGroup(filterGroup)
                                                ? FilterGroupOperator.and
                                                : FilterGroupOperator.or}
                                        </Button>
                                    )}
                                </Box>
                                <FilterRuleForm
                                    filterRule={item}
                                    fields={fields}
                                    isEditMode={isEditMode}
                                    onChange={(value) =>
                                        onChangeItem(index, value)
                                    }
                                    onDelete={() => onDeleteItem(index)}
                                    onConvertToGroup={
                                        allowConvertToGroup
                                            ? () => {
                                                  onChangeItem(index, {
                                                      id: uuidv4(),
                                                      and: [item],
                                                  });
                                              }
                                            : undefined
                                    }
                                    filters={filters}
                                    setFilters={setFilters}
                                    groupIndex={groupIndex}
                                    showFieldSource={showFieldSource}
                                />
                            </>
                        ) : (
                            <div className="">
                                <FilterGroupForm
                                    allowConvertToGroup={false}
                                    isEditMode={isEditMode}
                                    filterGroup={item}
                                    conditionLabel={conditionLabel}
                                    fields={fields}
                                    onChange={(value) =>
                                        onChangeItem(index, value)
                                    }
                                    onDelete={() => onDeleteItem(index)}
                                    nestedLevel={nestedLevel + 1}
                                    filters={filters}
                                    setFilters={setFilters}
                                    groupIndex={groupIndex}
                                    relation={relation}
                                />
                            </div>
                        )}
                    </Box>
                ))}
            </Stack>

            {isEditMode && !hideButtons && fields.length > 0 && (
                <Box bg="white" pos="relative" style={{ zIndex: 2 }}>
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        size="xs"
                        leftIcon={<MantineIcon icon={IconPlus} />}
                        onClick={onAddFilterRule}
                    >
                        Add group rule
                    </Button>
                </Box>
            )}
        </Stack>
    );
};

export default FilterGroupForm;
