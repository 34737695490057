import {
    useFiltersContext,
    type FieldWithSuggestions,
} from '@components/Audience/Filters/FiltersProvider';
import {
    isDimension,
    isMetric,
    type FilterableDimension,
    type Metric,
} from '@lightdash/common';
import { useMemo } from 'react';

export const useFilterFields = () => {
    const { fieldsMap, filterableFields, eventsMap, eventTables } =
        useFiltersContext();

    const [fields, dimensions, filteredDimensions, metrics, events] = useMemo<
        [
            FieldWithSuggestions[],
            FilterableDimension[],
            FilterableDimension[],
            Metric[],
            FieldWithSuggestions[],
        ]
    >(() => {
        const allFields = Object.values(fieldsMap);
        const { showEvents, showDimensions, showMetrics, showEventProperties } =
            filterableFields;

        let filteredDimensionsList: FilterableDimension[] = [];

        if (showDimensions && showEventProperties) {
            filteredDimensionsList = allFields.filter(isDimension);
        } else if (showDimensions && !showEventProperties) {
            filteredDimensionsList = allFields
                .filter(isDimension)
                .filter((field) => !eventTables.includes(field.table));
        } else if (!showDimensions && showEventProperties) {
            filteredDimensionsList = allFields
                .filter(isDimension)
                .filter((field) => eventTables.includes(field.table));
        }

        return [
            allFields,
            allFields.filter(isDimension),
            filteredDimensionsList,
            showMetrics ? allFields.filter(isMetric) : [],
            showEvents ? eventsMap : [],
        ];
    }, [fieldsMap, filterableFields, eventsMap, eventTables]);

    return { fields, dimensions, filteredDimensions, metrics, events };
};
