import TransparentInput from '@components/common/Inputs/TransparentInput';
import { fuzzyFilter, TableTypeOptions } from '@components/Table';
import { useLocale } from '@hooks/useLocale';
import { ShowDataType } from '@lightdash/common';
import { Box, Button } from '@mantine/core';
import { X } from '@phosphor-icons/react';
import {
    getCoreRowModel,
    useReactTable,
    type ColumnDef,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { type HeadersType } from '..';
import { ButtonVariant } from '../../../../../../../../mantineTheme';
import InputCell from '../../ResponsePayloadMapper/InputCell';

interface HeaderTableProps {
    headers: HeadersType[];
    updateHeaders: (index: number, { key, value }: HeadersType) => void;
    deleteHeader: (index: number) => void;
    containerRef: React.RefObject<HTMLDivElement>;
}

const HeaderTable: React.FC<HeaderTableProps> = ({
    headers,
    updateHeaders,
    deleteHeader,
    containerRef,
}) => {
    const { t } = useLocale();
    const columns: ColumnDef<HeadersType>[] = useMemo(() => {
        return [
            {
                header: t('common.uppercase_key'),
                accessorKey: 'key',
                cell: ({ row }) => {
                    return (
                        <InputCell
                            updateTable={(value) => {
                                updateHeaders(row.index, {
                                    key: value,
                                    value: row.original.value,
                                });
                            }}
                            inputValue={row.original.key}
                        />
                    );
                },
            },
            {
                header: t('journey_builder.header_table_value_header'),
                accessorKey: 'type',
                cell: ({ row }) => {
                    return (
                        <TransparentInput
                            defaultValue={row.original.value}
                            placeholder={t(
                                'journey_builder.header_table_value_placeholder',
                            )}
                            onBlur={(e) => {
                                updateHeaders(row.index, {
                                    key: row.original.key,
                                    value: e.target.value,
                                });
                            }}
                        />
                    );
                },
            },
            {
                header: '',
                accessorKey: 'cancel',
                cell: ({ row }) => (
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        className={`p-2`}
                        onClick={() => {
                            deleteHeader(row.index);
                        }}
                    >
                        <X weight="bold" color={'rgb(var(--color-gray-700))'} />
                    </Button>
                ),
            },
        ];
    }, [t, deleteHeader, updateHeaders]);
    const table = useReactTable({
        data: headers,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <Box className="border border-shade-4 rounded-2xl p-0.5">
            <Box className="border rounded-xl border-gray-50 overflow-hidden">
                <Box
                    className="max-h-[38rem] overflow-auto "
                    ref={containerRef}
                >
                    <TableTypeOptions
                        viewType={ShowDataType.LIST}
                        table={table}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default React.memo(HeaderTable);
