import {
    type AddditionalPropertySelectListProps,
    type PropertySelectListType,
} from '@components/common/Select/PropertySelect/type';
import {
    DimensionType,
    JourneyTableType,
    type JourneyDataSchema,
    type JourneyDimension,
    type JourneyTable,
} from '@lightdash/common';
import { Scan, User, UsersThree } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';

export type JourneyProperty = JourneyDimension &
    AddditionalPropertySelectListProps;

type PropertySelectAccumulator = {
    [key: string]: PropertySelectListType<JourneyProperty>;
};

const getGroupLabel = (key: JourneyTableType): string => {
    switch (key) {
        case JourneyTableType.EVENT:
            return 'In this journey';
        case JourneyTableType.PRIMARY:
            return 'Profile';
        case JourneyTableType.AUDIENCE:
            return 'Audience';
        default:
            return '';
    }
};

const getGroupIcon = (key: JourneyTableType): React.ReactNode => {
    switch (key) {
        case JourneyTableType.EVENT:
            return <Scan color="rgb(var(--color-gray-800))" />;
        case JourneyTableType.PRIMARY:
            return <User color="rgb(var(--color-gray-800))" />;
        case JourneyTableType.AUDIENCE:
            return <UsersThree color="rgb(var(--color-gray-800))" />;
        default:
            return null;
    }
};

const getGroupItems = (
    table: JourneyTable,
    tableName: string,
): JourneyProperty[] => {
    if (!table) return [];
    if (table.type === JourneyTableType.EVENT) {
        return [
            {
                type: DimensionType.EVENT,
                label: table.label ?? '',
                name: table.eventName ?? '',
                table: tableName,
                subGroupKey: table.nodeId,
                subGroupLabel: table.eventName,
            },
        ];
    }
    return Object.values(table.dimensions);
};

/**
 * Custom hook to process journey data schema and convert it into a property select list type.
 *
 * @param journeyDataSchema - The schema containing journey data.
 * @returns An array of property select list types.
 */
export const useJourneyProperties = (
    journeyDataSchema: JourneyDataSchema,
): PropertySelectListType<JourneyProperty>[] => {
    const [properties, setProperties] = useState<
        PropertySelectListType<JourneyProperty>[]
    >([]);

    useEffect(() => {
        if (!journeyDataSchema) return;

        const hierarchy = [
            JourneyTableType.EVENT,
            JourneyTableType.PRIMARY,
            JourneyTableType.AUDIENCE,
        ];

        const dataFields = Object.keys(
            journeyDataSchema.tables,
        ).reduce<PropertySelectAccumulator>((acc, tableKey) => {
            const table = journeyDataSchema.tables[tableKey];
            const key = table.type;
            const groupLabel = getGroupLabel(key);
            const groupIcon = getGroupIcon(key);

            if (key) {
                if (acc[key] === undefined) {
                    acc[key] = {
                        groupKey: key,
                        groupLabel: groupLabel,
                        groupIcon: groupIcon,
                        items: getGroupItems(table, tableKey),
                    };
                } else {
                    acc = {
                        ...acc,
                        [key]: {
                            ...acc[key],
                            items: [
                                ...acc[key].items,
                                ...getGroupItems(table, tableKey),
                            ],
                        },
                    };
                }
            }

            return acc;
        }, {} as PropertySelectAccumulator);

        const sortedProperties = hierarchy
            .filter((key) => dataFields[key] !== undefined)
            .map((key) => dataFields[key]);

        setProperties(sortedProperties);
    }, [journeyDataSchema]);

    return properties;
};

/**
 * Custom hook to process journey data schema and convert it into a property select list type for event properties.
 *
 * @param journeyDataSchema - The schema containing journey data.
 * @param eventField - The event field to look up in the journey data schema.
 * @returns An array of property select list types.
 */
export const useJourneyEventProperties = (
    journeyDataSchema: JourneyDataSchema,
    eventTable: string,
): PropertySelectListType<JourneyProperty>[] => {
    const [properties, setProperties] = useState<
        PropertySelectListType<JourneyProperty>[]
    >([]);

    useEffect(() => {
        if (!journeyDataSchema || !eventTable) return;

        const table = journeyDataSchema.tables[eventTable];
        if (!table) return;

        const key = table.type;
        const groupLabel = getGroupLabel(key);
        const groupIcon = getGroupIcon(key);

        const dataFields: PropertySelectAccumulator = {
            [key]: {
                groupKey: key,
                groupLabel: groupLabel,
                groupIcon: groupIcon,
                items: Object.values(table.dimensions),
            },
        };

        setProperties([dataFields[key]]);
    }, [journeyDataSchema, eventTable]);

    return properties;
};
