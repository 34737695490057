import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Timestamp from '@components/common/Timestamp';
import TemplateGridItem from '@components/TemplateGridItem';
import { useLocale } from '@hooks/useLocale';
import { type ReducedTemplate } from '@lightdash/common';
import { Box, Button, Text } from '@mantine/core';
import { type ColumnDef, type Row } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonVariant } from '../../../mantineTheme';

const TEMPLATE_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start !w-56`;
const TEMPLATE_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-56`;
const TEMPLATE_TABLE_CELL_DESCRIPTION = `text-xs font-normal text-gray-600 truncate !w-56`;

interface TemplateMenuItemProps {
    rowData: Row<ReducedTemplate>;
}

const TemplateMenuItem: React.FC<TemplateMenuItemProps> = ({ rowData }) => {
    const menuItems = useMemo(() => {
        const items: CustomMenuItemProps<ReducedTemplate>[] = [];
        return items;
    }, []);

    return (
        <>
            <CustomMenu<ReducedTemplate>
                menuItems={menuItems}
                data={rowData.original}
            />
        </>
    );
};

interface TemplateColumnsProps {
    handleRowPress: (id: string) => void;
}

export const useTemplateColumns = ({
    handleRowPress,
}: TemplateColumnsProps) => {
    const [columns, setColumns] = useState<ColumnDef<ReducedTemplate>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: t('templates'),
                    cell: ({ row, getValue }) => (
                        <Box className={TEMPLATE_TABLE_CELL_CLASSNAME}>
                            <Button
                                variant={ButtonVariant.UNSTYLED}
                                onClick={() => handleRowPress(row.original.id)}
                            >
                                <Text className={TEMPLATE_TABLE_CELL_NAME}>
                                    {getValue<string>()}
                                </Text>
                            </Button>
                            {row.original.description && (
                                <Text
                                    className={TEMPLATE_TABLE_CELL_DESCRIPTION}
                                >
                                    {row.original.description}
                                </Text>
                            )}
                        </Box>
                    ),
                },
                {
                    accessorKey: 'createdAt',
                    header: t('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`by ${row.original.createdBy.firstName || ''}`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => <TemplateMenuItem rowData={row} />,
                },
            ]);
        }
    }, [handleRowPress, projectUuid, t]);

    return columns;
};

export const useTemplateGrid = ({
    handleClick,
}: {
    handleClick: (id: string) => void;
}) => {
    {
        const [gridColumns, setGridColumns] =
            useState<ColumnDef<ReducedTemplate>[]>();
        const { projectUuid } = useParams<{ projectUuid: string }>();
        const { t } = useLocale();

        useEffect(() => {
            if (projectUuid) {
                setGridColumns([
                    {
                        accessorKey: 'name',
                        header: t('templates'),
                        cell: ({ row }) => {
                            const { original } = row;
                            const { channel } = original;
                            return (
                                <TemplateGridItem
                                    channel={channel}
                                    template={original}
                                    handleTemplateSelect={() =>
                                        handleClick(original?.id)
                                    }
                                />
                            );
                        },
                    },
                ]);
            }
        }, [projectUuid, t, handleClick]);

        return gridColumns;
    }
};
