import TextInput from '@components/common/Inputs/TextInput';
import { useLocale } from '@hooks/useLocale';
import { type CampaignCreateRequest } from '@lightdash/common';
import { Stack, Text, Textarea } from '@mantine/core';
import { useCampaignContext } from '@providers/CampaignProvider';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

type CampaignDetailsPayloadType = Pick<
    CampaignCreateRequest,
    'name' | 'description'
>;

interface CampaignDetailsProps {
    validateFormRef: React.MutableRefObject<() => Promise<boolean>>;
}

const CampaignDetails: React.FC<CampaignDetailsProps> = ({
    validateFormRef,
}) => {
    const { t } = useLocale();
    const { campaignPayload, isEditMode } = useCampaignContext(
        (context) => context.state,
    );
    const { setCampaignName, setCampaignDescription } = useCampaignContext(
        (context) => context.actions,
    );

    const defaultValues: CampaignDetailsPayloadType = useMemo(() => {
        return {
            name: campaignPayload?.name || '',
            description: campaignPayload?.description || '',
        };
    }, [campaignPayload]);

    const form = useForm<CampaignDetailsPayloadType>({
        defaultValues,
    });

    const {
        trigger,
        register,
        formState: { errors },
        clearErrors,
    } = form;

    useEffect(() => {
        validateFormRef.current = async () => {
            const result = await trigger();
            return result && Object.keys(errors).length === 0;
        };
    }, [trigger, errors, validateFormRef]);

    return (
        <form name="campaign_details_form" id="campaign_details_form">
            <Stack>
                <TextInput
                    {...register('name', { required: 'Name is required' })}
                    label={t('campaigns_builder_details.campaign_name_label')}
                    required
                    disabled={!isEditMode}
                    labelProps={{ style: { marginTop: '8px' } }}
                    placeholder={t(
                        'campaigns_builder_details.campaign_name_placeholder',
                    )}
                    onChange={(event) => {
                        const name = event.currentTarget.value;
                        setCampaignName(name);
                        if (name.length > 0) {
                            clearErrors('name');
                            return;
                        }
                    }}
                    withAsterisk={false}
                    error={errors.name?.message}
                    data-gramm="false" //To disable grammarly
                    data-gramm_editor="false" //To disable grammarly
                    data-enable-grammarly="false" //To disable grammarly
                />
                <Textarea
                    label={
                        <Text>
                            {t(
                                'campaigns_builder_details.campaign_description_label',
                            )}{' '}
                            <span className="text-gray-600">(optional)</span>
                        </Text>
                    }
                    disabled={!isEditMode}
                    labelProps={{ style: { marginTop: '8px' } }}
                    placeholder={t(
                        'campaigns_builder_details.campaign_description_placeholder',
                    )}
                    {...register('description')}
                    onChange={(event) =>
                        setCampaignDescription(event.currentTarget.value)
                    }
                    data-gramm="false" //To disable grammarly
                    data-gramm_editor="false" //To disable grammarly
                    data-enable-grammarly="false" //To disable grammarly
                />
            </Stack>
        </form>
    );
};

export default React.memo(CampaignDetails);
