import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';

/**
 * Hook to determine if there has been a change between the initial state and the current state of an object.
 * This can optionally compare partial objects to allow for scenarios where only a subset of properties may have changed.
 *
 * @param {T} initialState - The initial state object to compare against. This should be the full object state on initial render.
 * @param {Partial<T> | T} currentState - The current state object. This can be a partial or whole object based on `comparePartial`.
 * @param {boolean} [comparePartial=false] - Optional flag to indicate if the comparison should be done as a partial object.
 *     Defaults to `false`, meaning it compares all keys from both initialState and currentState.
 *     When `true`, it only compares keys present in the currentState against those in the initialState.
 *
 * @returns {boolean} - Returns `true` if there has been a change between the initial state and the current state based on the comparison criteria; otherwise, returns `false`.
 *
 * @template T - The type of the state object being compared.
 */

export const useIsEqual = <T extends object>(
    initialState: T,
    currentState: Partial<T> | T,
    comparePartial: boolean = false,
): boolean => {
    const [hasChanged, setHasChanged] = useState(false);
    useEffect(() => {
        // Conditionally set the keys to compare based on the comparePartial flag
        const keysToCompare = comparePartial
            ? Object.keys(currentState) // If partial, only use keys from currentState
            : new Set([
                  ...Object.keys(initialState),
                  ...Object.keys(currentState),
              ]); // If whole, merge keys from both

        let changed = false;

        const keysArray = Array.isArray(keysToCompare)
            ? keysToCompare
            : Array.from(keysToCompare);

        for (const key of keysArray) {
            // Check if the key exists in both states when not comparing partials, or just in initialState when comparing partials
            if (
                comparePartial
                    ? key in initialState
                    : key in initialState && key in currentState
            ) {
                if (
                    !isEqual(
                        initialState[key as keyof T],
                        currentState[key as keyof T],
                    )
                ) {
                    changed = true;
                    break;
                }
            }
        }

        setHasChanged(changed);
    }, [initialState, currentState, comparePartial]);

    return hasChanged;
};
