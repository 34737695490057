import Modal from '@components/common/modal/Modal';
import { useLocale } from '@hooks/useLocale';
import { type CommunicationChannel } from '@lightdash/common';
import { Group, Text } from '@mantine/core';
import React from 'react';
import SubscriptionGroupContent from './SubscriptionGroupContent';

interface SubscriptionGroupModalProps {
    activeTab: CommunicationChannel;
    isOpen: boolean;
    onClose: () => void;
}
const SubscriptionGroupModal: React.FC<SubscriptionGroupModalProps> = ({
    activeTab,
    isOpen,
    onClose,
}) => {
    const { t } = useLocale();

    return (
        <Modal
            opened={isOpen}
            onClose={onClose}
            size="xl"
            title={
                <Group className="flex flex-col items-start gap-0">
                    <Text className="text-gray-800 text-base font-medium pb-0 mb-0">
                        {t(
                            'subscription_group_modal.create_a_subscription_group',
                        )}
                    </Text>
                </Group>
            }
            styles={() => ({
                content: {
                    maxWidth: '48vw !important',
                },
                header: {
                    display: 'flex',
                    alignItems: 'start',
                },
                body: {
                    padding: '0px',
                },
            })}
        >
            <SubscriptionGroupContent
                activeTab={activeTab}
                onClose={onClose}
                groupData={undefined}
            />
        </Modal>
    );
};

export default SubscriptionGroupModal;
