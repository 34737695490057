import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import { useActivateJourney } from '@hooks/useJourney';
import { useLocale } from '@hooks/useLocale';
import { JourneyStatus } from '@lightdash/common';
import { Box, Radio, Text } from '@mantine/core';
import { PauseCircle, Umbrella } from '@phosphor-icons/react';
import { SunHorizon } from '@phosphor-icons/react/dist/ssr';
import React, { useCallback, useMemo, useState } from 'react';
import { ButtonVariant } from '../../../mantineTheme';

enum DeactivateProps {
    SUNSET = 'SUNSET',
    DEACTIVATE = 'DEACTIVATE',
}

interface DeactivateJourneyModalProps {
    uuid: string;
    endDate: Date | undefined;
    journeyStatus: JourneyStatus;
    showDeactivateModal: boolean;
    onClose: () => void;
}

const DeactivateJourneyModal: React.FC<DeactivateJourneyModalProps> = ({
    uuid,
    endDate,
    journeyStatus,
    showDeactivateModal,
    onClose,
}) => {
    const { t } = useLocale();
    const {
        mutateAsync: activateJourney,
        isLoading: isLoadingActivateJourney,
    } = useActivateJourney();

    const [deactivateStatus, setDeactivateStatus] = useState<DeactivateProps>(
        DeactivateProps.SUNSET,
    );

    const isSunsetJourney = useMemo(() => {
        return (
            journeyStatus === JourneyStatus.ACTIVE &&
            endDate &&
            new Date(endDate) < new Date()
        );
    }, [journeyStatus, endDate]);

    const getPrimaryButtonText = useMemo(() => {
        if (isLoadingActivateJourney) {
            if (journeyStatus === JourneyStatus.SCHEDULED) {
                return t('journeys_deactivate.deactivating_journey');
            }
            if (
                isSunsetJourney ||
                deactivateStatus === DeactivateProps.SUNSET
            ) {
                return t('journeys_deactivate.sunsetting_journey');
            }
            return t('journeys_deactivate.stopping_journey');
        }
        if (journeyStatus === JourneyStatus.SCHEDULED) {
            return t('journeys_deactivate.deactivate_now');
        }
        if (isSunsetJourney) {
            return t('journeys_deactivate.stop_journey');
        }
        if (deactivateStatus === DeactivateProps.SUNSET) {
            return t('journeys_deactivate.sunset_journey');
        }
        return t('journeys_deactivate.stop_journey');
    }, [
        isLoadingActivateJourney,
        journeyStatus,
        isSunsetJourney,
        deactivateStatus,
        t,
    ]);

    const handleDeactivateStatusChange = useCallback(
        (value: DeactivateProps) => {
            setDeactivateStatus(value);
        },
        [],
    );

    const handleDeactivate = useCallback(async () => {
        let data = {};

        if (journeyStatus === JourneyStatus.SCHEDULED) {
            data = { status: JourneyStatus.DRAFT };
            await activateJourney({ data, uuid: uuid ?? '' });
            onClose();
            return;
        }

        if (
            isSunsetJourney ||
            deactivateStatus === DeactivateProps.DEACTIVATE
        ) {
            data = { status: JourneyStatus.CANCELED };
            await activateJourney({ data, uuid: uuid ?? '' });
            onClose();
            return;
        }

        if (deactivateStatus === DeactivateProps.SUNSET) {
            data = { endDate: new Date() };
            await activateJourney({ data, uuid: uuid ?? '' });
            onClose();
            return;
        }

        onClose();
    }, [
        journeyStatus,
        activateJourney,
        uuid,
        isSunsetJourney,
        deactivateStatus,
        onClose,
    ]);

    const primaryLeftIcon = useMemo(() => {
        if (journeyStatus === JourneyStatus.SCHEDULED) {
            return (
                <PauseCircle
                    size={14}
                    weight="duotone"
                    color="rgb(var(--color-white))"
                />
            );
        }
        if (deactivateStatus === DeactivateProps.SUNSET) {
            return (
                <SunHorizon
                    size={14}
                    weight="duotone"
                    color="rgb(var(--color-white))"
                />
            );
        }
        return (
            <Umbrella
                size={14}
                weight="duotone"
                color="rgb(var(--color-white))"
            />
        );
    }, [journeyStatus, deactivateStatus]);

    const journeyOptions = useMemo(() => {
        if (journeyStatus === JourneyStatus.SCHEDULED) {
            return (
                <Box>
                    <Text className="text-gray-800 text-sm font-normal">
                        {t('journeys_deactivate.deactivate_journey_scheduled')}
                    </Text>
                </Box>
            );
        }

        if (!isSunsetJourney) {
            return (
                <Radio.Group
                    value={deactivateStatus}
                    onChange={handleDeactivateStatusChange}
                    withAsterisk
                >
                    <Radio
                        className="pb-3.5 text-sm text-gray-800 font-normal"
                        value={DeactivateProps.SUNSET}
                        label={t(
                            'journeys_deactivate.deactivate_journey_label_sunset',
                        )}
                        description={t(
                            'journeys_deactivate.deactivate_journey_description_sunset',
                        )}
                    />
                    <Radio
                        className="text-sm font-normal text-gray-800"
                        value={DeactivateProps.DEACTIVATE}
                        label={t(
                            'journeys_deactivate.deactivate_journey_label_stop',
                        )}
                        description={t(
                            'journeys_deactivate.deactivate_journey_description_stop',
                        )}
                    />
                </Radio.Group>
            );
        }

        return (
            <Box>
                <Text className="text-gray-800 text-sm font-normal">
                    {t('journeys_deactivate.deactivate_journey_sunset')}
                </Text>
            </Box>
        );
    }, [
        journeyStatus,
        isSunsetJourney,
        t,
        deactivateStatus,
        handleDeactivateStatusChange,
    ]);

    return (
        <Modal
            opened={showDeactivateModal}
            onClose={onClose}
            keepMounted={false}
            title={t('Deactivate journey')}
            size="md"
            footerRightSection={
                <ModalFooter
                    showSecondaryButton={true}
                    secondaryButtonVariant={ButtonVariant.OUTLINED}
                    secondaryButtonClick={onClose}
                    secondaryText={t('user_profile_sync_modal.cancel_button')}
                    primaryText={getPrimaryButtonText}
                    primaryButtonClick={handleDeactivate}
                    showPrimaryButton={true}
                    primaryLeftIcon={primaryLeftIcon}
                    primaryRightIcon={undefined}
                    secondaryLeftIcon={undefined}
                    secondaryRightIcon={undefined}
                    primaryButtonVariant={ButtonVariant.OUTLINED}
                    isLoading={isLoadingActivateJourney}
                    primaryButtonDisabled={undefined}
                    primaryButtonCustomClass="!bg-halt-800 !text-white hover:!bg-halt-800 hover:!text-white"
                />
            }
        >
            {journeyOptions}
        </Modal>
    );
};

export default DeactivateJourneyModal;
