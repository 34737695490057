import dagre from 'dagre';
import { type Edge, type Node, type Position } from 'reactflow';

const nodeWidth = 260;
const nodeHeight = 72;

export const getLayoutedElements = (
    nodes: Node[],
    edges: Edge[],
    direction = 'TB',
) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({
        rankdir: direction,
    });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const graphWidth: number = dagreGraph.graph().width || 0;
    const graphHeight: number = dagreGraph.graph().height || 0;

    const screenWidth: number = window.innerWidth;
    const screenHeight: number = window.innerHeight;

    let newx: number = 0;
    let newy: number = 0;
    if (graphWidth && graphHeight) {
        newx = screenWidth > graphWidth ? (screenWidth - graphWidth) / 2 : 0;
        newy =
            screenHeight > graphHeight ? (screenHeight - graphHeight) / 4 : 0;
    }

    nodes.forEach((node, index) => {
        const nodeWithPosition = dagreGraph.node(node.id);

        node.targetPosition = (isHorizontal ? 'left' : 'top') as Position;
        node.sourcePosition = (isHorizontal ? 'right' : 'bottom') as Position;

        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2 + newx,
            y: nodeWithPosition.y - nodeHeight / 2 + newy,
        };

        if (index === nodes.length - 1) {
            node.position.y += 20;
        }

        return node;
    });

    return { nodes, edges };
};
