import { useLocale } from '@hooks/useLocale';
import { Box, Text } from '@mantine/core';
import { UsersThree } from '@phosphor-icons/react';
import React, { useMemo } from 'react';

function convertStringToNumber(
    value: string | number | undefined,
): number | undefined {
    if (typeof value === 'number') return value;
    if (value !== undefined && value !== '') {
        const parsedValue = parseFloat(value as string);
        return !isNaN(parsedValue) ? parsedValue : undefined;
    }
    return undefined;
}
interface UserCountProps {
    count: number | string | undefined;
    rightSection?: React.ReactNode;
    leftSection?: React.ReactNode;
    formatValue?: boolean;
    withRightSection?: boolean;
    withLeftSection?: boolean;
    withApproximation?: boolean;
}

const UserCount: React.FC<UserCountProps> = ({
    count,
    rightSection,
    leftSection,
    formatValue = true,
    withRightSection = true,
    withLeftSection = true,
    withApproximation = true,
}) => {
    const { t } = useLocale();

    let formattedValue = t('common.unknown');
    count = convertStringToNumber(count);

    if (count !== undefined) {
        if (formatValue) {
            switch (true) {
                case count > 999999:
                    const millionValueCeil = Math.ceil(count / 100000) / 10;
                    formattedValue = `~${
                        millionValueCeil % 1 === 0
                            ? millionValueCeil.toFixed(0)
                            : millionValueCeil.toFixed(1)
                    }M`;
                    break;

                case count > 999:
                    const kValueCeil = Math.ceil(count / 100) / 10;
                    formattedValue = `~${
                        kValueCeil % 1 === 0
                            ? kValueCeil.toFixed(0)
                            : kValueCeil.toFixed(1)
                    }k`;
                    break;

                default:
                    formattedValue = count.toLocaleString();
                    break;
            }
        } else {
            formattedValue = count.toLocaleString();
        }
    }

    const formatNumberWithCommas = useMemo(() => {
        return count && count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }, [count]);

    return (
        <Box className="flex flex-row items-center gap-1.5 text-sm font-medium text-gray-600">
            {withLeftSection && (leftSection ?? <UsersThree />)}
            <Text>
                {withApproximation ? formattedValue : formatNumberWithCommas}
            </Text>
            {withRightSection &&
                (rightSection ?? (
                    <Text className="lowercase">{t('common.users')}</Text>
                ))}
        </Box>
    );
};

export default UserCount;
