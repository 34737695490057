import { Box, Flex, Text } from '@mantine/core';

interface ProfileInitialCreateStateProps {
    title: string;
    description: string;
    buttonComponent: React.ReactNode;
}
const ProfileInitialCreateState: React.FC<ProfileInitialCreateStateProps> = ({
    title,
    description,
    buttonComponent,
}) => {
    return (
        <>
            <Box className="border border-shade-4 rounded-2xl p-0.5 mt-4">
                <Box className="w-full h-[38.5rem] bg-white border rounded-xl border-gray-50 ">
                    <Flex
                        justify="center"
                        align="center"
                        className="h-full "
                        direction={'column'}
                        gap={6}
                    >
                        <Text className="text-gray-800 font-semibold text-lg">
                            {title}
                        </Text>
                        <Text className="text-gray-600 text-sm pb-4">
                            {description}
                        </Text>
                        {buttonComponent}
                    </Flex>
                </Box>
            </Box>
        </>
    );
};
export default ProfileInitialCreateState;
