import DataTable from '@components/Table';
import { useLocale } from '@hooks/useLocale';
import {
    ShowDataType,
    type CampaignList,
    type ReducedCampaign,
} from '@lightdash/common';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCampaignColumns } from './useCampaignTableUtils';
interface CampaignsTableProps {
    campaigns: CampaignList | undefined;
    onPageChange: (page: number) => void;
    onSearchChange: (newSearchString: string) => void;
    searchValue: string;
}

const CampaignsTable: React.FC<CampaignsTableProps> = ({
    campaigns,
    onPageChange,
    onSearchChange,
    searchValue,
}) => {
    const campaignsColumns = useCampaignColumns();
    const { t } = useLocale();
    const history = useHistory();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    return (
        <div>
            {campaigns?.data && campaignsColumns && (
                <DataTable<ReducedCampaign>
                    name={t('campaign_manager.th_campaign_name')}
                    tableData={campaigns.data}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: campaignsColumns,
                        },
                    ]}
                    searchPlaceholder={t(
                        'campaign_manager.table_search_placeholder',
                    )}
                    paginationText={t('campaign_manager.pagination_text')}
                    isManualPagination={true}
                    onPageChange={onPageChange}
                    pagination={campaigns.paginate}
                    onSearchChange={onSearchChange}
                    isEditable={false}
                    handleRowClick={(row) => {
                        history.push(
                            `/projects/${projectUuid}/campaigns/${row.original?.id}/preview`,
                        );
                    }}
                    searchValue={searchValue}
                />
            )}
        </div>
    );
};

export default React.memo(CampaignsTable);
