import TransparentInput from '@components/common/Inputs/TransparentInput';
import UnsavedChangesModal from '@components/common/modal/UnsavedChangesModal';
import Select from '@components/common/Select';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, Text } from '@mantine/core';
import { DoorOpen, PlusCircle, Trash } from '@phosphor-icons/react';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import React, { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ButtonVariant } from '../../../../mantineTheme';
import SettingsTitle from '../SettingsTitle';

const ExitTriggers: React.FC = () => {
    const { t } = useLocale();

    const { journeyEvents: eventsData, journeyPayload } =
        useJourneyBuilderContext((context) => context.state);

    const { triggers } = journeyPayload;
    const { exit } = triggers!;

    const { setExitTriggers } = useJourneyBuilderContext(
        (context) => context.actions,
    );

    const [hasDataChanged, setHasDataChanged] = useState<boolean>(false);

    const [exitTriggersData, setExitTriggersData] = useState([
        {
            id: uuidv4(),
            eventName: 'another sample test',
            eventSource: 'fyno',
            name: 'Exit 1',
        },
        {
            id: uuidv4(),
            eventName: 'another sample test',
            eventSource: 'fyno',
            name: 'Exit 2',
        },
    ]);
    const [initialData] = useState([
        {
            id: uuidv4(),
            eventName: 'another sample test',
            eventSource: 'fyno',
            name: 'Exit 1',
        },
        {
            id: uuidv4(),
            eventName: 'another sample test',
            eventSource: 'fyno',
            name: 'Exit 2',
        },
    ]);

    const transformedData = useMemo(() => {
        return (
            eventsData &&
            eventsData.map((event) => ({
                label: event.label ? event.label : event.eventName,
                value: event.eventName,
                source: event.source,
            }))
        );
    }, [eventsData]);

    const handleAddRule = useCallback(() => {
        setHasDataChanged(true);
        setExitTriggers([
            ...(exit ?? []),
            {
                id: uuidv4(),
                eventName: '',
                eventSource: '',
                metadata: {
                    title: `Exit ${exitTriggersData.length + 1}`,
                },
            },
        ]);
        setExitTriggersData([
            ...exitTriggersData,
            {
                id: uuidv4(),
                eventName: '',
                eventSource: '',
                name: `Exit ${exitTriggersData.length + 1}`,
            },
        ]);
    }, [exit, exitTriggersData, setExitTriggers]);

    const handleEventChange = useCallback(
        (id: string, eventName: string) => {
            setHasDataChanged(true);
            const selectedEvent =
                transformedData &&
                transformedData.find((event) => event.value === eventName);
            const eventSource = selectedEvent ? selectedEvent.source : '';
            // if(exit && exit.length){
            //     setExitTriggersData(
            //         exit.map((trigger)=>
            //             trigger.id===id? { ...trigger, eventName, eventSource}
            //             : trigger,
            //         )
            //     )
            // }

            setExitTriggersData((prevTriggers) =>
                prevTriggers.map((trigger) =>
                    trigger.id === id
                        ? { ...trigger, eventName, eventSource }
                        : trigger,
                ),
            );
        },
        [transformedData],
    );

    const handleInputChange = useCallback((id: string, value: string) => {
        setHasDataChanged(true);
        setExitTriggersData((prevTriggers) =>
            prevTriggers.map((trigger) =>
                trigger.id === id ? { ...trigger, name: value } : trigger,
            ),
        );
    }, []);

    const handleSave = useCallback(() => {
        setHasDataChanged(false);
        setExitTriggers(
            exitTriggersData.map((trigger) => ({
                id: trigger.id,
                eventName: trigger.eventName,
                eventSource: trigger.eventSource,
                metadata: { title: trigger.name },
            })),
        );
    }, [exitTriggersData, setExitTriggers]);

    return (
        <Box>
            <SettingsTitle
                icon={<DoorOpen size={14} weight="duotone" />}
                title={t('exit_triggers.title')}
                subtitle={t('exit_triggers.subtitle')}
            />
            {exitTriggersData &&
                exitTriggersData.map((item, index) => (
                    <Box
                        key={index}
                        className="border-[1px] m-3 p-1 rounded-lg"
                    >
                        <Box className="flex items-center justify-between p-3 bg-gray-50 rounded-xl">
                            <Box className="flex items-center">
                                <Text className="border-[1px] px-2 py-1 rounded-lg bg-white text-sm font-medium text-gray-800">
                                    {index + 1}
                                </Text>
                                <TransparentInput
                                    styles={() => ({
                                        input: {
                                            backgroundColor:
                                                'rgb(var( --color-gray-50)) !important',
                                        },
                                    })}
                                    onChange={(e) =>
                                        handleInputChange(
                                            item.id,
                                            e.target.value,
                                        )
                                    }
                                    value={item.name}
                                />
                            </Box>
                            <Trash
                                className="mx-3 my-2"
                                size={14}
                                weight="duotone"
                            />
                        </Box>
                        <Box className="m-3">
                            {transformedData && item.id && (
                                <Select
                                    value={item.eventName}
                                    data={transformedData}
                                    onChange={(eventName: string) => {
                                        handleEventChange(item.id, eventName);
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                ))}
            <Button
                className="mx-3"
                onClick={handleAddRule}
                variant={ButtonVariant.OUTLINED_ACCENTED}
                leftIcon={<PlusCircle color="rgb(var(--color-blu-800))" />}
            >
                {t(
                    'workspace_settings.delivery_controls.frequency_capping_button',
                )}
            </Button>

            <UnsavedChangesModal
                opened={hasDataChanged}
                secondaryActionButtonClick={() => {
                    setExitTriggersData(initialData);
                    setHasDataChanged(false);
                }}
                disableButtons={false}
                primaryActionButtonClick={() => handleSave()}
            />
        </Box>
    );
};

export default ExitTriggers;
