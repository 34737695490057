import { DimensionType, type ResponseConfig } from '@lightdash/common';
import { type HeadersType } from './Headers';
import { type ResponseTableType } from './ResponsePayloadMapper';

export enum CallAPIMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}
/**
 * Recursively traverses the JSON object to build a response table.
 * @param {Record<string, any>} json - The JSON object to traverse.
 * @param {ResponseTableType[]} responseTable - The response table to build.
 * @param {number} arrayCount - The count of arrays.
 * @param {string} parentKey - The parent key.
 * @returns {void}
 */
const recursiveTraverse = (
    json: Record<string, any>,
    responseTable: ResponseTableType[],
    arrayCount: { count: number },
    parentKey: string,
) => {
    Object.entries(json).forEach(([key, value]) => {
        const timeRegex = /^([01]?\d|2[0-3]):([0-5]?\d):?([0-5]?\d)?$/;
        const isoTimestampRegex =
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

        const newKey = parentKey ? `${parentKey}.${key}` : key;

        if (Array.isArray(value)) {
            if (
                value[0] &&
                typeof value[0] === 'object' &&
                !Array.isArray(value[0])
            ) {
                recursiveTraverse(value[0], responseTable, arrayCount, newKey);
            } else {
                arrayCount.count++;
            }
            return;
        }

        if (typeof value === 'object') {
            recursiveTraverse(value, responseTable, arrayCount, newKey);
            return;
        }

        if (typeof value === 'string') {
            responseTable.push({
                key: newKey,
                type:
                    timeRegex.test(value) || isoTimestampRegex.test(value)
                        ? DimensionType.TIMESTAMP
                        : typeof value,
            });
            return;
        }

        responseTable.push({ key: newKey, type: typeof value });
    });
};

/**
 * Converts the JSON string into a response table with key-value types and counts arrays.
 * @param {string} jsonString - The JSON string input.
 * @returns {{responseTable: ResponseTableType[], arrayCount: number}} The response table and the count of arrays other than arrays of objects.
 */
export const getResponseTable = (jsonString: string) => {
    const responseTable: ResponseTableType[] = [];
    const arrayCount = { count: 0 };
    const json = JSON.parse(jsonString);
    recursiveTraverse(json, responseTable, arrayCount, '');
    return { responseTable, arrayCount: arrayCount.count };
};

/**
 * Filters out empty headers and reduces the headers to a key-value object.
 * @param {HeadersType[]} headers - The headers to filter and reduce.
 * @returns {Record<string, string>} The filtered and reduced headers.
 */
export const filterAndReduceHeaders = (
    headers: HeadersType[],
): Record<string, string> => {
    return headers
        .filter((header) => header.key && header.value)
        .reduce((acc: Record<string, string>, header) => {
            acc[header.key] = header.value;
            return acc;
        }, {});
};

/**
 * Converts the headers object to an array of headers.
 * @param {Record<string, string>} headers - The headers object to convert.
 * @returns {HeadersType[]} The array of headers.
 */
export const convertHeadersToObject = (
    headers: Record<string, string>,
): HeadersType[] => {
    return Object.entries(headers).map(([key, value]) => ({ key, value }));
};

/**
 * Converts the response config to a response table.
 * @param {Record<string, any>} responseConfig - The response config to convert.
 * @returns {ResponseTableType[]} The response table.
 */
export const convertResponseConfigToResponseTable = (
    responseConfig: ResponseConfig,
): ResponseTableType[] => {
    if (!responseConfig.responseMapper) return [];
    return Object.entries(responseConfig.responseMapper).map(([key, type]) => ({
        key: key.replace(/^["']|["']$/g, '').slice(2),
        type: type as string,
    }));
};

/**
 * Converts the response table to a response config.
 * @param {ResponseTableType[]} responseTable - The response table to convert.
 * @returns {Record<string, any>} The response config.
 */
export const convertResponseTableToResponseConfig = (
    responseTable: ResponseTableType[],
): Record<string, any> => {
    const responseMapper = responseTable
        .filter((item) => item.key && item.type)
        .reduce((acc: Record<string, any>, item) => {
            acc[`$.${item.key}`] = item.type;
            return acc;
        }, {});
    return { type: 'sync', responseMapper };
};

/**
 * Converts the input object to a string with variables.
 * @param {object} input - The object to convert.
 * @returns {string} The string with variables.
 */
export const stringifyObjectWithVariables = (input: object): string => {
    // Convert the object to a JSON string
    const stringifiedObject = JSON.stringify(input);

    // Remove handlebars from variables (handles both double and single quotes)
    const removeHandleBars = stringifiedObject.replace(
        /["']{{(\w+)}}["']/g,
        '$.$1',
    );

    return removeHandleBars;
};
/**
 * Reverts a string with variables back into an object with handlebars-style variables.
 * Converts standalone text that looks like a variable (not wrapped in quotes) into handlebars format.
 * @param {string} input - The string with variables.
 * @returns {object} The original object with handlebars variables.
 */
export const parseStringWithVariablesToObject = (input: string): object => {
    // Replace any standalone text that looks like a variable into "{{variable}}"
    const restoredHandlebars = input.replace(
        /\$\.(\w+)/g, // Match any word that is not within quotes
        (_, variable) => `"{{${variable}}}"`,
    );

    const modifiedInput = restoredHandlebars.replace(
        /['"]['"]{{(\w+)}}['"]['"]/g,
        '"$1"',
    );
    let parsedObject;
    try {
        parsedObject = JSON.parse(modifiedInput);
    } catch (error) {
        parsedObject = {};
    }
    return parsedObject;
};

/**
 * Converts a string with variables in handlebars to a formatted string.
 * @param {string} input - The string with variables in the format `Hello {{name}}!`
 * @returns {string} The formatted string.
 */
export const stringifyStringWithVariables = (input: string): string => {
    // Replace handlebars variables with &variable&
    const replacedVariables = input.replace(/{{(\w+)}}/g, '&$1&');

    // Wrap the entire string in single quotes if it's not a variable
    const result = replacedVariables
        .split(/(&[^&]*&)/) // Split by variables to isolate literals
        .map((part) => (part.startsWith('&') ? part : `'${part}'`)) // Wrap non-variable parts in single quotes
        .join('');

    return result;
};

/**
 * Reverts a formatted string back to the original handlebars format.
 * @param {string} input - The formatted string with variables in the format `&variable&` and literals wrapped in single quotes.
 * @returns {string} The reverted string with handlebars variables.
 */
export const revertStringifyStringWithVariables = (input: string): string => {
    // Remove single quotes from literals
    const withoutQuotes = input.replace(/'(.*?)'/g, '$1');

    // Replace &variable& with {{variable}}
    const revertedVariables = withoutQuotes.replace(/&(\w+)&/g, '{{ $1 }}');

    return revertedVariables;
};
