import { CronInternalInputs } from '@components/Audience/Scheduler/ReactHookForm/CronInput';
import Modal from '@components/common/modal/Modal';
import { useLocale } from '@hooks/useLocale';
import { Box, Text } from '@mantine/core';
import { Warning } from '@phosphor-icons/react';

import { isHourlyCron } from '@components/Audience/Scheduler/ReactHookForm/CronInput/cronInputUtils';
import { updateProfilePayload } from '@components/Profiles/utils';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import { useGetProfileConfig, useUpdateProfile } from '@hooks/useProfile';
import { useCallback, type Dispatch, type SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import { cronHelperText } from './UserProfileSync';

interface UserProfileSyncModalProps {
    onClose: () => void;
    opened: boolean;
    cronExpression: string;
    setCronExpression: Dispatch<SetStateAction<string>>;
}

const UserProfileSyncModal: React.FC<UserProfileSyncModalProps> = ({
    onClose,
    opened,
    cronExpression,
    setCronExpression,
}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { data: config } = useGetProfileConfig(projectUuid);
    const { mutate: updateProfile, isLoading } = useUpdateProfile(projectUuid);

    const handleSave = useCallback(() => {
        if (config)
            updateProfile(
                updateProfilePayload({
                    payload: config,
                    cron: cronExpression,
                }),

                {
                    onSuccess: () => {
                        onClose();
                    },
                },
            );
    }, [cronExpression, onClose, updateProfile, config]);

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            title={t('user_profile_sync_modal.title')}
            size={'lg'}
            footerRightSection={
                <ButtonGroup
                    primaryButtonLabel={t('common.save')}
                    secondaryButtonLabel={undefined}
                    handlePrimaryButtonClick={() => {
                        handleSave();
                    }}
                    handleSecondaryButtonClick={onClose}
                    isUpdating={isLoading}
                    isPrimaryButtonDisabled={config?.cron === cronExpression}
                />
            }
        >
            {isHourlyCron(cronExpression) && (
                <Text className="text-warn border border-mustard-800/40 rounded-lg p-2 flex gap-1.5 items-center">
                    <Warning color={'rgb(var(--color-mustard-800))'} />
                    {t('user_profile_sync_modal.high_frequency_warning')}
                </Text>
            )}
            <Box className="py-3.5">
                <Text className="text-gray-800 text-sm font-medium mb-1">
                    {t('audience_scheduler.cron_label')}
                </Text>
                <CronInternalInputs
                    disabled={isLoading}
                    value={cronExpression}
                    onChange={(event) => setCronExpression(event)}
                    name="cron"
                    disableHourly
                />
                <Text className="text-sm font-medium text-gray-600 pt-3">
                    {t('user_profile_sync.update_message')}{' '}
                    {cronHelperText(cronExpression).length < 50
                        ? cronHelperText(cronExpression)
                        : '--'}
                </Text>
            </Box>
        </Modal>
    );
};

export default UserProfileSyncModal;
