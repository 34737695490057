import {
    isField,
    type AndFilterGroup,
    type DashboardFilters,
    type FilterableField,
    type FilterableItem,
    type FilterRule,
    type RelationTableType,
    type WeekDay,
} from '@lightdash/common';
import { type PopoverProps } from '@mantine/core';
import { uuid4 } from '@sentry/utils';
import {
    createContext,
    useCallback,
    useContext,
    useState,
    type FC,
} from 'react';
import { useToggle } from 'react-use';

export type FieldWithSuggestions = FilterableField & {
    suggestions?: string[];
    tableType?: RelationTableType;
    uniqueIdentifier?: string; //TODO: To remove this field
    label?: string;
};

export type FieldsWithSuggestions = Record<string, FieldWithSuggestions>;

interface FilterableFieldsType {
    showEvents: boolean;
    showDimensions: boolean;
    showMetrics: boolean;
    showEventProperties: boolean;
}

type FiltersContext = {
    projectUuid?: string;
    fieldsMap: FieldsWithSuggestions;
    eventsMap: FieldWithSuggestions[];
    eventTables: string[];
    startOfWeek?: WeekDay;
    getField: (filterRule: FilterRule) => FieldWithSuggestions | undefined;
    getAutocompleteFilterGroup: (
        filterId: string,
        item: FilterableItem,
    ) => AndFilterGroup | undefined;
    popoverProps?: Omit<PopoverProps, 'children'>;
    showFilter: boolean;
    setShowFilter: (show: boolean) => void;
    setFilterableFields: (props: FilterableFieldsType) => void;
    filterableFields: FilterableFieldsType;
};

const Context = createContext<FiltersContext | undefined>(undefined);

type Props = {
    projectUuid?: string;
    fieldsMap?: Record<string, FieldWithSuggestions>;
    eventsMap?: FieldWithSuggestions[];
    eventTables?: string[];
    startOfWeek?: WeekDay;
    dashboardFilters?: DashboardFilters;
    popoverProps?: Omit<PopoverProps, 'children'>;
};

export const FiltersProvider: FC<React.PropsWithChildren<Props>> = ({
    projectUuid,
    fieldsMap = {},
    eventsMap = [],
    eventTables = [],
    startOfWeek,
    dashboardFilters,
    popoverProps,
    children,
}) => {
    const [isOpen, toggleFieldInput] = useToggle(false);
    const [filterableFields, setFilterableFields] =
        useState<FilterableFieldsType>({
            showEvents: false,
            showDimensions: true,
            showMetrics: true,
            showEventProperties: true,
        });

    const getField = useCallback(
        (filterRule: FilterRule) => {
            if (fieldsMap) {
                return fieldsMap[filterRule.target.fieldId];
            }
        },
        [fieldsMap],
    );

    const getAutocompleteFilterGroup = useCallback(
        (filterId: string, item: FilterableItem) => {
            if (!dashboardFilters || !isField(item)) {
                return undefined;
            }
            return {
                id: uuid4(),
                and: dashboardFilters.dimensions.filter(
                    (dimensionFilterRule) => {
                        const isNotSelectedFilter =
                            dimensionFilterRule.id !== filterId;
                        const hasSameTable =
                            dimensionFilterRule.target.tableName === item.table;
                        return isNotSelectedFilter && hasSameTable;
                    },
                ),
            };
        },
        [dashboardFilters],
    );

    const setFilterableFieldsFn = useCallback(
        ({ ...props }: FilterableFieldsType) => {
            setFilterableFields(props);
        },
        [],
    );

    return (
        <Context.Provider
            value={{
                projectUuid,
                fieldsMap,
                eventsMap,
                startOfWeek,
                getField,
                getAutocompleteFilterGroup,
                popoverProps,
                setShowFilter: toggleFieldInput,
                showFilter: isOpen,
                setFilterableFields: setFilterableFieldsFn,
                filterableFields: filterableFields,
                eventTables,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export function useFiltersContext(): FiltersContext {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error(
            'useFiltersContext must be used within a FiltersProvider',
        );
    }
    return context;
}
