import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SegmentedTimeFilterOptions } from './types';

dayjs.extend(utc);

export const getTimestampForOption = (
    option: SegmentedTimeFilterOptions,
): [number, number] | undefined => {
    let start: dayjs.Dayjs, end: dayjs.Dayjs;

    switch (option) {
        case SegmentedTimeFilterOptions.TODAY:
            start = dayjs().utc().startOf('day');
            end = dayjs().utc().endOf('day');
            break;
        case SegmentedTimeFilterOptions.YESTERDAY:
            start = dayjs().utc().subtract(1, 'day').startOf('day');
            end = dayjs().utc().subtract(1, 'day').endOf('day');
            break;
        case SegmentedTimeFilterOptions.LAST_7_DAYS:
            start = dayjs().utc().subtract(6, 'day').startOf('day');
            end = dayjs().utc().endOf('day');
            break;
        case SegmentedTimeFilterOptions.LAST_30_DAYS:
            start = dayjs().utc().subtract(29, 'day').startOf('day');
            end = dayjs().utc().endOf('day');
            break;
        case SegmentedTimeFilterOptions.THIS_MONTH:
            start = dayjs().utc().startOf('month');
            end = dayjs().utc().endOf('month');
            break;
        case SegmentedTimeFilterOptions.LAST_MONTH:
            start = dayjs().utc().subtract(1, 'month').startOf('month');
            end = dayjs().utc().subtract(1, 'month').endOf('month');
            break;
        case SegmentedTimeFilterOptions.CUSTOM:
        case SegmentedTimeFilterOptions.ALL_TIME:
        default:
            return undefined;
    }

    return [start.valueOf(), end.valueOf()];
};

export const determineInitialOption = (
    defaultRange: [Date, Date] | undefined,
    availableOptions: SegmentedTimeFilterOptions[],
): SegmentedTimeFilterOptions => {
    if (!defaultRange) {
        return SegmentedTimeFilterOptions.ALL_TIME;
    }

    // Convert defaultRange to UTC dates at the start of the day for comparison
    const [start, end] = defaultRange.map((date) => {
        return Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
        );
    });

    // Today in UTC
    const today = new Date(
        Date.UTC(
            new Date().getUTCFullYear(),
            new Date().getUTCMonth(),
            new Date().getUTCDate(),
        ),
    );
    const yesterday = new Date(today);
    yesterday.setUTCDate(yesterday.getUTCDate() - 1);
    const last7Days = new Date(today);
    last7Days.setUTCDate(last7Days.getUTCDate() - 6);

    // Iterate over availableOptions and compare with defaultRange
    for (let option of availableOptions) {
        let optionStart: number, optionEnd: number;

        switch (option) {
            case SegmentedTimeFilterOptions.TODAY:
                optionStart = optionEnd = today.getTime();
                break;
            case SegmentedTimeFilterOptions.YESTERDAY:
                optionStart = optionEnd = yesterday.getTime();
                break;
            case SegmentedTimeFilterOptions.LAST_7_DAYS:
                optionStart = last7Days.getTime();
                optionEnd = today.getTime();
                break;
            case SegmentedTimeFilterOptions.ALL_TIME:
                // Assuming ALL_TIME doesn't have a specific start/end, skip comparison
                continue;
            default:
                // For CUSTOM or any other option not covered, we'll need specific logic
                // If your getTimestampForOption can handle UTC, you might want to use it here
                continue;
        }

        if (start === optionStart && end === optionEnd) {
            return option;
        }
    }

    // If no predefined option matches, return CUSTOM
    return SegmentedTimeFilterOptions.CUSTOM;
};
