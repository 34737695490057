// Attach the entities which could be attached to entity api key below
import { type SourceEventKeyMapping } from './events';

export enum AllowedEntities {
    EVENT_INGESTION_SOURCE = 'EVENT_INGESTION_SOURCE',
}

// Attach the entities which could be attached to entity api key below
export type SerialisableEntitiesFromApiKey = SourceEventKeyMapping | undefined;

export type EntityApiKey = {
    uuid: string;
    entityId: string;
    entityType: AllowedEntities;
    projectUuid: string;
    entityAPIKey: string;
    enabled: boolean;
    createdAt: Date;
    createdBy: string;
};

export type CreateEntityAPIKey = Pick<EntityApiKey, 'entityId' | 'entityType'>;

export type CreateEntityAPIKeyResponse = Pick<
    EntityApiKey,
    'uuid' | 'entityAPIKey'
>;

export type UpdateEntityAPIKey = Pick<EntityApiKey, 'enabled'>;
