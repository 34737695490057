import { type LightdashUser } from './user';

export enum CommunicationChannel {
    SMS = 'sms',
    EMAIL = 'email',
    WHATSAPP = 'whatsapp',
    SLACK = 'slack',
    ANY = 'any',
    UNKNOWN = 'unknown',
}

export const CommunicationChannelName: Record<CommunicationChannel, string> = {
    [CommunicationChannel.SMS]: 'SMS',
    [CommunicationChannel.EMAIL]: 'Email',
    [CommunicationChannel.WHATSAPP]: 'WhatsApp',
    [CommunicationChannel.SLACK]: 'Slack',
    [CommunicationChannel.ANY]: 'Any',
    [CommunicationChannel.UNKNOWN]: 'Unknown',
};

export type FetchIntegrationListResponse = Record<
    CommunicationChannel,
    Integration[]
>;

export interface Integration {
    customName: string;
    channelId: string;
    channelName: string;
    description: string;
    displayName: string;
    groupName: string;
    integrated: string;
    logoUrl: string;
    providerId: string;
    providerName: string;
    integrationId: string;
    isTemplateImportAllowed: boolean;
    config: ChannelConfig[];
}

export type AllIntegrationChannels = Record<
    CommunicationChannel,
    Integration[]
>;

export type TemplateBasicDetails = {
    templateId: string;
    templateName: string;
    status: number;
    devVersion: number;
    prodVersion: number;
    version: number;
    updatedAt: string;
    createdAt: string;
    channels: CommunicationChannel[];
};

export type FynoTemplateBasicDetails = {
    templateId: string;
    templateName: string;
    status: number;
    devVersion: number;
    prodVersion: number;
    version: number;
    updatedAt: string;
    createdAt: string;
    channels: CommunicationChannel[];
};

export type FynoSMSTemplateDetails = {
    text: string;
    unicode: boolean;
    flash: boolean;
    templateId: string;
};

export type FynoEmailTemplateDetails = {
    subject: string;
    body: string;
};

export type FynoTemplateDetails = FynoTemplateBasicDetails & {
    channels: Partial<{
        sms: FynoSMSTemplateDetails;
        email: FynoEmailTemplateDetails;
    }>;
    placeholders: {
        [key: string]: string[];
    };
};

export type ChannelConfig = {
    fieldName: string;
    defaultValue: string;
    options: string[] | undefined;
    override: boolean;
    required: boolean;
    show: boolean;
    configKey: string;
    configValue: string;
    encryption: boolean;
};

export type ChannelIntegrationDetails = {
    integrationId: string | null;
    providerId: string;
    customName: string;
    config: ChannelConfig[];
    allowTest: boolean;
    allowSync: boolean;
    isTemplateImportAllowed: boolean;
    fromEmail: string;
    fromName: string;
    replyTo: string;
};

export type TestEmailDestination = {
    email?: string;
    sms?: string;
    whatsapp?: string;
    push?: string;
};

export type Primitive = number | string | boolean | null | undefined;

export type Name = Pick<LightdashUser, 'firstName' | 'lastName'>;

export type IntegrationAddRequest = {
    customName: string;
    metadata: Record<string, Primitive>;
};

export type IntegrationTestRequest = IntegrationAddRequest;

export type IntegrationUpdateRequest = {
    customName: string;
    config: Record<string, Primitive>;
};

export type UtmKeyCreateRequest = {
    key: string;
    value: Value | null;
};

export type Value = {
    type: 'static' | 'dynamic';
    value: string;
};

export type UtmKeyMapping = {
    key: string;
    required: boolean;
    modifiable: boolean;
    type: 'internal' | 'external';
    value: Value;
};
