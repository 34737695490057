import {
    CommunicationChannel as CommunicationChannelEnum,
    type CommunicationChannel,
    type EmailTemplateContentDetails,
    type SmsTemplateContentDetails,
    type Template,
    type WhatsAppTemplateContentDetails,
} from '@lightdash/common';
import { type TemplateBuilderState } from '@providers/TemplateBuilderProvider';
import { useMemo } from 'react';

export const useTemplateBuilderInitialData = ({
    templatePayload,
    channel,
    templateId,
}: {
    templatePayload: Template | undefined;
    channel: CommunicationChannel;
    templateId: string | undefined;
}): TemplateBuilderState => {
    const defaultContent = useMemo(() => {
        if (channel === CommunicationChannelEnum.EMAIL) {
            const payload: EmailTemplateContentDetails = {
                subject: '',
                html: '',
            };

            return payload;
        }
        if (channel === CommunicationChannelEnum.SMS) {
            const payload: SmsTemplateContentDetails = {
                body: '',
                dltTemplateId: '',
                unicode: false,
                flash: false,
            };
            return payload;
        }

        if (channel === CommunicationChannelEnum.WHATSAPP) {
            const payload: WhatsAppTemplateContentDetails = {
                header: '',
                body: '',
                footer: '',
                language: '',
            };
            return payload;
        }

        return {
            subject: '',
            html: '',
        };
    }, [channel]);

    return useMemo(() => {
        const initialData = {
            name: templatePayload?.name || '',
            channel,
            content: templatePayload?.contents[0].content ?? defaultContent,
            description: templatePayload?.description || '',
            consideredInLibrary: templatePayload?.consideredInLibrary ?? true,
            previewUrl: '',
        };

        return {
            openLibraryModal: false,
            openImportModal: false,
            openCreateTemplateModal: false, // Boolean(templateId),
            templatePayload: initialData,
            templateId: templateId || '',
            channel: channel,
            initialPayload: initialData,
            selectedIntegration: null,
            selectedTemplate: null,
            version: templatePayload?.contents[0].version,
        };
    }, [channel, templateId, templatePayload, defaultContent]);
};
