import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import { type Campaign, type Template } from '@lightdash/common';

export enum CampaignBuilderStep {
    AUDIENCE = 'audience',
    CHANNEL = 'channel',
    CONTENT = 'content',
    SCHEDULE = 'schedule',
}

export interface CampaignBuilderStepMap {
    key: CampaignBuilderStep;
    label: string;
    component: React.ComponentType;
    pageHeader: string;
}

export enum CampaignContentStep {
    SETUP = 'setup',
    PERSONALISE = 'personalise',
    TRACK = 'track',
    UTM_PARAMETERS = 'utmParameters',
    TEST = 'test',
}

export interface ContentStepComponentProps {
    setActiveContentStep: (step: CampaignContentStep) => void;
    activeContentStep: CampaignContentStep;
    templateMetadata: Template | undefined;
    fields: FieldsWithSuggestions | undefined;
}

export interface CampaignContentStepMap {
    key: CampaignContentStep;
    label: string;
    component: React.ComponentType<ContentStepComponentProps>;
}

export interface CampaignContentDetails {
    sendAsName: string;
    sendAsEmail: string;
    replyToSameAsSender: boolean;
    emailSubject: string;
    previewText?: string;
}

export type TemplateSetupCompleteArgs = {
    template: Campaign['templateDetails'] | undefined;
    communicationDetails: Campaign['communicationDetails'];
};
