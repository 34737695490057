import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import { useLocale } from '@hooks/useLocale';
import {
    type AdditionalMetric,
    type CompiledDimension,
    type CompiledRelationTable,
    type CustomAttribute,
    type CustomDimension,
    type FieldType,
    type InsertCustomAttribute,
    type MetricFilterRule,
    type MetricQuery,
    type MetricType,
    type TableCalculation,
} from '@lightdash/common';
import React, { useCallback, useMemo, useReducer, type FC } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

export enum ActionType {
    OPEN_CUSTOM_METRIC_BASE_TABLE_MODAL,
    CLOSE_CUSTOM_METRIC_BASE_TABLE_MODAL,
    OPEN_CUSTOM_METRIC_MANAGER_MODAL,
    CLOSE_CUSTOM_METRIC_MANAGER_MODAL,
    SELECT_TABLE,
    SELECT_DIMENSION,
    SET_FILTERS,
    ADD_BASE_TABLE_DETAILS_TO_PAYLOAD,
    ADD_DIMENSION_DETAILS_TO_PAYLOAD,
    ADD_CUSTOM_METRIC_NAME_DETAILS,
    ADD_CUSTOM_METRIC_DESCRIPTION_DETAILS,
    ADD_FILTERS_TO_CUSTOM_METRIC,
    ADD_CUSTOM_METRIC_TYPE_DETAIL,
    SET_CUSTOM_METRIC,
    TOGGLE_IS_DUPLICATE_METRIC,
    TOGGLE_IS_VIEW_MODE,
    TOGGLE_IS_BACK_BUTTON_DISABILITY,
    RESET,
}

export interface CustomMetricReducerState {
    customAttributePayload: InsertCustomAttribute;
    initialCustomAttributePayload: InsertCustomAttribute;
    showBaseTableModal: boolean;
    showMetricManagerModal: boolean;
    duplicatedMetric: boolean;
    viewMode: boolean;
    selectedTable: CompiledRelationTable | undefined;
    selectedDimension: CompiledDimension | FieldWithSuggestions | undefined;
    filters: MetricQuery['filters'] | undefined;
    disableBackButton: boolean;
}
type DefinitionType =
    | AdditionalMetric
    | TableCalculation
    | CustomDimension
    | null;
type Action =
    | {
          type: ActionType.OPEN_CUSTOM_METRIC_BASE_TABLE_MODAL;
      }
    | {
          type: ActionType.CLOSE_CUSTOM_METRIC_BASE_TABLE_MODAL;
      }
    | {
          type: ActionType.OPEN_CUSTOM_METRIC_MANAGER_MODAL;
      }
    | {
          type: ActionType.CLOSE_CUSTOM_METRIC_MANAGER_MODAL;
      }
    | {
          type: ActionType.SELECT_TABLE;
          payload: CompiledRelationTable | undefined;
      }
    | {
          type: ActionType.SELECT_DIMENSION;
          payload: CompiledDimension | FieldWithSuggestions | undefined;
      }
    | {
          type: ActionType.SET_FILTERS;
          payload: MetricQuery['filters'];
      }
    | {
          type: ActionType.ADD_BASE_TABLE_DETAILS_TO_PAYLOAD;
          payload: CompiledRelationTable | undefined;
      }
    | {
          type: ActionType.ADD_DIMENSION_DETAILS_TO_PAYLOAD;
          payload: CompiledDimension | FieldWithSuggestions | undefined;
      }
    | {
          type: ActionType.ADD_CUSTOM_METRIC_NAME_DETAILS;
          payload: string;
      }
    | {
          type: ActionType.ADD_CUSTOM_METRIC_DESCRIPTION_DETAILS;
          payload: string;
      }
    | {
          type: ActionType.ADD_CUSTOM_METRIC_TYPE_DETAIL;
          payload: MetricType;
      }
    | {
          type: ActionType.ADD_FILTERS_TO_CUSTOM_METRIC;
          payload: MetricFilterRule[] | undefined;
      }
    | {
          type: ActionType.SET_CUSTOM_METRIC;
          payload: CustomAttribute;
      }
    | {
          type: ActionType.TOGGLE_IS_DUPLICATE_METRIC;
          payload: boolean;
      }
    | {
          type: ActionType.TOGGLE_IS_VIEW_MODE;
          payload: boolean;
      }
    | {
          type: ActionType.TOGGLE_IS_BACK_BUTTON_DISABILITY;
          payload: boolean;
      }
    | {
          type: ActionType.RESET;
          payload: CustomMetricReducerState;
      };

type CustomMetricContextType = {
    reducerState: CustomMetricReducerState;
    actions: {
        openCustomMetricBaseTableModal: () => void;
        closeCustomMetricBaseTableModal: () => void;
        openCustomMetricManagerModal: () => void;
        closeCustomMetricManagerModal: () => void;
        selectTable: (selectedTable: CompiledRelationTable | undefined) => void;
        selectDimension: (
            selectedDimension:
                | CompiledDimension
                | FieldWithSuggestions
                | undefined,
        ) => void;
        setFilters: (filters: MetricQuery['filters']) => void;
        addBaseTableDetails: (
            selectedTable: CompiledRelationTable | undefined,
        ) => void;
        addSelectedDimensionDetails: (
            selectedDimension:
                | CompiledDimension
                | FieldWithSuggestions
                | undefined,
        ) => void;
        addNameDetailsToPayload: (name: string) => void;
        addDescriptionDetailsToPayload: (description: string) => void;
        addMetricTypeDetailsToPayload: (metric: MetricType) => void;
        addFilttersToCustomMetric: (
            filters: MetricFilterRule[] | undefined,
        ) => void;
        setCustomMetricData: (data: CustomAttribute) => void;
        toogleIsDuplicated: (canDuplicate: boolean) => void;
        toggleIsViewMode: (onlyView: boolean) => void;
        toggleIsBackButtonDisabled: (disableBackButton: boolean) => void;
        resetTheCustometricContext: (data: CustomMetricReducerState) => void;
    };
};

const Context = createContext<CustomMetricContextType | undefined>(undefined);

function reducer(
    state: CustomMetricReducerState,
    action: Action,
): CustomMetricReducerState {
    switch (action.type) {
        case ActionType.OPEN_CUSTOM_METRIC_BASE_TABLE_MODAL:
            return {
                ...state,
                showBaseTableModal: true,
            };
        case ActionType.CLOSE_CUSTOM_METRIC_BASE_TABLE_MODAL:
            return {
                ...state,
                showBaseTableModal: false,
            };
        case ActionType.OPEN_CUSTOM_METRIC_MANAGER_MODAL:
            return {
                ...state,
                showMetricManagerModal: true,
            };
        case ActionType.CLOSE_CUSTOM_METRIC_MANAGER_MODAL:
            return {
                ...state,
                showMetricManagerModal: false,
            };
        case ActionType.SELECT_TABLE:
            return {
                ...state,
                selectedTable: action.payload,
            };
        case ActionType.SELECT_DIMENSION:
            return {
                ...state,
                selectedDimension: action.payload,
            };
        case ActionType.SET_FILTERS: {
            return {
                ...state,
                filters: action.payload,
            };
        }

        case ActionType.ADD_BASE_TABLE_DETAILS_TO_PAYLOAD: {
            return {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    srcTable: action.payload?.name ?? '',
                    definition: {
                        ...state.customAttributePayload?.definition,
                        tableLabel: action.payload?.label,
                    } as DefinitionType,
                },
                initialCustomAttributePayload: {
                    ...state.initialCustomAttributePayload,
                    srcTable: action.payload?.name ?? '',
                    definition: {
                        ...state.initialCustomAttributePayload?.definition,
                        tableLabel: action.payload?.label,
                    } as DefinitionType,
                },
            };
        }
        case ActionType.ADD_DIMENSION_DETAILS_TO_PAYLOAD: {
            return {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    definition: {
                        ...state.customAttributePayload?.definition,
                        sql: action.payload?.sql ?? '',
                        baseDimensionName: action.payload?.name,
                        table: action.payload?.table ?? '',
                        fieldType:
                            action.payload?.fieldType ?? ('' as FieldType),
                        tablesReferences: (action.payload as CompiledDimension)
                            ?.tablesReferences,
                    } as DefinitionType,
                },
            };
        }
        case ActionType.ADD_CUSTOM_METRIC_NAME_DETAILS: {
            return {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    name: action.payload,
                    definition: {
                        ...state.customAttributePayload.definition,
                        name: action.payload,
                        label: action.payload,
                    } as DefinitionType,
                },
            };
        }
        case ActionType.ADD_CUSTOM_METRIC_DESCRIPTION_DETAILS: {
            return {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    description: action.payload,
                    definition: {
                        ...state.customAttributePayload.definition,
                        description: action.payload,
                    } as DefinitionType,
                },
            };
        }
        case ActionType.ADD_CUSTOM_METRIC_TYPE_DETAIL: {
            return {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    definition: {
                        ...state.customAttributePayload.definition,
                        type: action.payload,
                    } as DefinitionType,
                },
            };
        }
        case ActionType.ADD_FILTERS_TO_CUSTOM_METRIC: {
            return {
                ...state,
                customAttributePayload: {
                    ...state.customAttributePayload,
                    definition: {
                        ...state.customAttributePayload.definition,
                        filters: action.payload,
                    } as DefinitionType,
                },
            };
        }
        case ActionType.SET_CUSTOM_METRIC: {
            return {
                ...state,
                customAttributePayload: action.payload,
            };
        }
        case ActionType.TOGGLE_IS_DUPLICATE_METRIC: {
            return {
                ...state,
                duplicatedMetric: action.payload,
            };
        }
        case ActionType.TOGGLE_IS_VIEW_MODE: {
            return {
                ...state,
                viewMode: action.payload,
            };
        }
        case ActionType.TOGGLE_IS_BACK_BUTTON_DISABILITY: {
            return {
                ...state,
                disableBackButton: action.payload,
            };
        }
        case ActionType.RESET: {
            return action.payload;
        }
        default:
            return state;
    }
}

export const CustomMetricProvider: FC<
    React.PropsWithChildren<{ initialState: CustomMetricReducerState }>
> = ({ initialState, children }) => {
    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const openCustomMetricBaseTableModal = useCallback(() => {
        dispatch({
            type: ActionType.OPEN_CUSTOM_METRIC_BASE_TABLE_MODAL,
        });
    }, []);

    const closeCustomMetricBaseTableModal = useCallback(() => {
        dispatch({
            type: ActionType.CLOSE_CUSTOM_METRIC_BASE_TABLE_MODAL,
        });
    }, []);
    const openCustomMetricManagerModal = useCallback(() => {
        dispatch({
            type: ActionType.OPEN_CUSTOM_METRIC_MANAGER_MODAL,
        });
    }, []);

    const closeCustomMetricManagerModal = useCallback(() => {
        dispatch({
            type: ActionType.CLOSE_CUSTOM_METRIC_MANAGER_MODAL,
        });
    }, []);
    const selectTable = useCallback(
        (selectedTable: CompiledRelationTable | undefined) => {
            dispatch({
                type: ActionType.SELECT_TABLE,
                payload: selectedTable,
            });
        },
        [],
    );
    const selectDimension = useCallback(
        (
            selectedDimension:
                | CompiledDimension
                | FieldWithSuggestions
                | undefined,
        ) => {
            dispatch({
                type: ActionType.SELECT_DIMENSION,
                payload: selectedDimension,
            });
        },
        [],
    );
    const setFilters = useCallback((filters: MetricQuery['filters']) => {
        dispatch({
            type: ActionType.SET_FILTERS,
            payload: filters,
        });
    }, []);

    const addBaseTableDetails = useCallback(
        (selectedTable: CompiledRelationTable | undefined) => {
            dispatch({
                type: ActionType.ADD_BASE_TABLE_DETAILS_TO_PAYLOAD,
                payload: selectedTable,
            });
        },
        [],
    );
    const addSelectedDimensionDetails = useCallback(
        (
            selectedDimension:
                | CompiledDimension
                | FieldWithSuggestions
                | undefined,
        ) => {
            dispatch({
                type: ActionType.ADD_DIMENSION_DETAILS_TO_PAYLOAD,
                payload: selectedDimension,
            });
        },
        [],
    );
    const addNameDetailsToPayload = useCallback((name: string) => {
        dispatch({
            type: ActionType.ADD_CUSTOM_METRIC_NAME_DETAILS,
            payload: name,
        });
    }, []);
    const addDescriptionDetailsToPayload = useCallback(
        (description: string) => {
            dispatch({
                type: ActionType.ADD_CUSTOM_METRIC_DESCRIPTION_DETAILS,
                payload: description,
            });
        },
        [],
    );
    const addMetricTypeDetailsToPayload = useCallback((metric: MetricType) => {
        dispatch({
            type: ActionType.ADD_CUSTOM_METRIC_TYPE_DETAIL,
            payload: metric,
        });
    }, []);
    const addFilttersToCustomMetric = useCallback(
        (filters: MetricFilterRule[] | undefined) => {
            dispatch({
                type: ActionType.ADD_FILTERS_TO_CUSTOM_METRIC,
                payload: filters,
            });
        },
        [],
    );
    const setCustomMetricData = useCallback((data: CustomAttribute) => {
        dispatch({
            type: ActionType.SET_CUSTOM_METRIC,
            payload: data,
        });
    }, []);
    const toogleIsDuplicated = useCallback((canDuplicate: boolean) => {
        dispatch({
            type: ActionType.TOGGLE_IS_DUPLICATE_METRIC,
            payload: canDuplicate,
        });
    }, []);
    const toggleIsViewMode = useCallback((onlyView: boolean) => {
        dispatch({
            type: ActionType.TOGGLE_IS_VIEW_MODE,
            payload: onlyView,
        });
    }, []);
    const toggleIsBackButtonDisabled = useCallback(
        (disableBackButton: boolean) => {
            dispatch({
                type: ActionType.TOGGLE_IS_BACK_BUTTON_DISABILITY,
                payload: disableBackButton,
            });
        },
        [],
    );
    const resetTheCustometricContext = useCallback(
        (data: CustomMetricReducerState) => {
            dispatch({
                type: ActionType.RESET,
                payload: data,
            });
        },
        [],
    );

    const actions = useMemo(
        () => ({
            openCustomMetricBaseTableModal,
            closeCustomMetricBaseTableModal,
            openCustomMetricManagerModal,
            closeCustomMetricManagerModal,
            selectTable,
            selectDimension,
            setFilters,
            addBaseTableDetails,
            addSelectedDimensionDetails,
            addNameDetailsToPayload,
            addFilttersToCustomMetric,
            addDescriptionDetailsToPayload,
            addMetricTypeDetailsToPayload,
            setCustomMetricData,
            toogleIsDuplicated,
            resetTheCustometricContext,
            toggleIsViewMode,
            toggleIsBackButtonDisabled,
        }),
        [
            openCustomMetricBaseTableModal,
            closeCustomMetricBaseTableModal,
            openCustomMetricManagerModal,
            closeCustomMetricManagerModal,
            selectTable,
            selectDimension,
            setFilters,
            addBaseTableDetails,
            addSelectedDimensionDetails,
            addNameDetailsToPayload,
            addFilttersToCustomMetric,
            addDescriptionDetailsToPayload,
            addMetricTypeDetailsToPayload,
            setCustomMetricData,
            toogleIsDuplicated,
            resetTheCustometricContext,
            toggleIsViewMode,
            toggleIsBackButtonDisabled,
        ],
    );

    const value = useMemo(
        () => ({ reducerState, actions }),
        [reducerState, actions],
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useCustomMetricContext<Selected>(
    selector: (value: CustomMetricContextType) => Selected,
) {
    const { t } = useLocale();
    return useContextSelector(Context, (context) => {
        if (context === undefined) {
            throw new Error(t('error.campaign_context_not_found'));
        }
        return selector(context);
    });
}
