import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import useSubscription from '@hooks/subscriptionGroups/useSubscription';
import {
    useDeleteSubscriptionCategory,
    useGetAllSubscriptionGroups,
    useUpdateSubscriptionGroup,
    useUpdateTree,
} from '@hooks/subscriptionGroups/useSubscriptionGroups';
import { useLocale } from '@hooks/useLocale';
import { ProjectSettings, type CommunicationChannel } from '@lightdash/common';
import { Box, Button, Flex, Loader, Text } from '@mantine/core';
import { useDisclosure, useResizeObserver } from '@mantine/hooks';
import { PlusCircle } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { REACT_ARBORIST_PARENT_ID } from '@utils/constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Tree, type NodeApi } from 'react-arborist';
import { useParams } from 'react-router-dom';
import Node from './Node';
import EditCategoryModal from './SubscriptionModal/EditCategoryModal';
import EditGroupModal from './SubscriptionModal/EditGroupModal';
import GroupCategoryUpdateModal from './SubscriptionModal/GroupCategoryUpdateModal';

export interface NodeData {
    id: string;
    name: string;
    categoryId: string | null;
    description: string;
    type?: string;
    weight?: number;
    children?: NodeData[];
}

interface SubscriptionBodyProps {
    activeTab: CommunicationChannel;
    onGroupCreate: () => void;
}

const SubscriptionBody: React.FC<SubscriptionBodyProps> = ({
    activeTab,
    onGroupCreate,
}) => {
    const [ref, rect] = useResizeObserver();
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const ability = useAbilityContext();
    const { user } = useApp();
    const {
        transformData,
        removeNodes,
        addNodes,
        processTreeData,
        moveItemUp,
        moveItemDown,
    } = useSubscription();

    const { mutateAsync: updateGroup } = useUpdateSubscriptionGroup();
    const { data: allGroups, isFetching } = useGetAllSubscriptionGroups({
        channel: activeTab,
    });
    const { mutateAsync: updateTree } = useUpdateTree();
    const { mutateAsync: mutateDeleteCategory } =
        useDeleteSubscriptionCategory();

    const [editModal, { open: editModalOpen, close: editModalClose }] =
        useDisclosure(false);
    const [editCategory, { open: editCategoryOpen, close: editCategoryClose }] =
        useDisclosure(false);
    const [
        groupCategory,
        { open: groupCategoryOpen, close: groupCategoryClose },
    ] = useDisclosure(false);
    const [dataId, setDataId] = useState<string>('');
    const [treeData, setTreeData] = useState<NodeData[]>([]);
    const treeRef = useRef();

    const canOnlyViewSubscription =
        ability.can(
            'view',
            subject(ProjectSettings.subscriptionGroups, { projectUuid }),
        ) &&
        ability.cannot(
            'update',
            subject(ProjectSettings.subscriptionGroups, {
                organizationUuid: user.data?.organizationUuid,
                projectUuid,
            }),
        );

    useEffect(() => {
        if (allGroups && !isFetching) {
            const transformedData = transformData(allGroups);
            setTreeData(transformedData);
        }
    }, [allGroups, setTreeData, isFetching, transformData]);

    useEffect(() => {
        const updatedTreeDate = processTreeData(treeData);
        void updateTree({ payload: updatedTreeDate });
    }, [treeData, processTreeData, updateTree]);

    const handleMove = useCallback(
        async ({
            dragIds,
            parentId,
            index,
            dragNodes,
        }: {
            dragIds: string[];
            dragNodes: any[];
            parentId: string | null;
            index: number;
        }) => {
            const updatedTreeData = [...treeData];

            const newDragNodes = dragNodes.map((node) => ({
                id: node.id,
                name: node.data.name,
                categoryId: node.data.categoryId,
                description: node.data.description,
                type: node.data.type,
                weight: node.data.weight,
                children: node.data.children,
            }));

            const nodesWithoutDragged = removeNodes(updatedTreeData, dragIds);
            const nodesWithNewNodes = addNodes(
                nodesWithoutDragged,
                newDragNodes,
                parentId,
                index,
            );

            const filteredData = nodesWithNewNodes.filter(
                (item) => !(item.children && item.children.length === 0),
            );
            setTreeData(filteredData);

            if (!dragNodes[0].children) {
                await updateGroup({
                    groupId: dragNodes[0].data.id,
                    payload: {
                        name: dragNodes[0].data.name,
                        description: dragNodes[0].data.description,
                        categoryId: parentId ? parentId : null,
                        type: dragNodes[0].data.type,
                    },
                });
            }

            nodesWithNewNodes.map(async (item) => {
                if (item.children && item.children.length === 0) {
                    await mutateDeleteCategory({ catgoryId: item.id });
                }
            });
        },
        [treeData, mutateDeleteCategory, updateGroup, removeNodes, addNodes],
    );

    const disableDrop = useCallback(
        (node: {
            parentNode: NodeApi<NodeData>;
            dragNodes: NodeApi<NodeData>[];
        }) => {
            if (
                node.dragNodes[0].children &&
                node.parentNode.id !== REACT_ARBORIST_PARENT_ID
            ) {
                return true;
            }
            return false;
        },
        [],
    );

    if (isFetching) {
        return (
            <Flex className="p-3.5" align="center">
                <Loader size={11} />
                <Text className="text-sm font-normal text-blu-800 px-1.5">
                    {t('subscription_body.loading')}
                </Text>
            </Flex>
        );
    }

    if (!treeData.length && canOnlyViewSubscription) {
        return (
            <Text className="m-1 text-gray-600 text-sm font-medium">
                {t('subscription_body.empty_data')}
            </Text>
        );
    }

    if (!treeData.length && !isFetching) {
        return (
            <Button
                className="m-2"
                onClick={onGroupCreate}
                variant="filled"
                leftIcon={<PlusCircle color="#FFF" />}
            >
                {t('subscription_groups.new_group')}
            </Button>
        );
    }

    return (
        <>
            <Box className="bg-white rounded-2xl w-fit border border-gray-100 p-0.5">
                <Box
                    ref={ref}
                    className="bg-gray-50 w-fit pt-2  h-[41.5vmax] border border-gray-200 rounded-xl"
                >
                    <Flex className="px-12 flex justify-between">
                        <Text className="text-gray-500 text-xs font-normal">
                            {t('subscription_body.groups')}
                        </Text>
                        <Text className="text-gray-500 mr-[70px] text-xs font-normal">
                            {t('subscription_body.type')}
                        </Text>
                    </Flex>
                    <Tree
                        ref={treeRef}
                        data={treeData}
                        disableDrop={!canOnlyViewSubscription && disableDrop}
                        rowHeight={66}
                        width={840}
                        height={rect.height - 17}
                        onMove={handleMove}
                        padding={10}
                    >
                        {(nodeProps) => (
                            <Node
                                {...nodeProps}
                                onGroupCallback={(id: string) => {
                                    setDataId(id);
                                    editModalOpen();
                                }}
                                onCategoryCallback={(id: string) => {
                                    setDataId(id);
                                    editCategoryOpen();
                                }}
                                onUpdateGroupCategory={(id: string) => {
                                    setDataId(id);
                                    groupCategoryOpen();
                                }}
                                onMoveUp={(id: string) => {
                                    moveItemUp(treeData, setTreeData, id);
                                }}
                                onMoveDown={(id: string) => {
                                    moveItemDown(treeData, setTreeData, id);
                                }}
                            />
                        )}
                    </Tree>
                </Box>
            </Box>

            {editModal && (
                <EditGroupModal
                    groupId={dataId}
                    editModal={editModal}
                    onClose={() => editModalClose()}
                    activeTab={activeTab}
                />
            )}

            {editCategory && (
                <EditCategoryModal
                    categoryId={dataId}
                    editCategory={editCategory}
                    onClose={() => editCategoryClose()}
                />
            )}

            {groupCategory && (
                <GroupCategoryUpdateModal
                    groupId={dataId}
                    showGroupCategory={groupCategory}
                    onClose={() => groupCategoryClose()}
                    activeTab={activeTab}
                />
            )}
        </>
    );
};

export default SubscriptionBody;
