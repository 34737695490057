import {
    SchemaConfigStep,
    type SchemaBuilderStep,
} from '@components/SchemaBuilder/Builder/types';
import {
    assertUnreachable,
    type Project,
    type RelationSchemaTableResponse,
    type RelationTableType,
} from '@lightdash/common';
import React, { useCallback, useMemo, useReducer } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

interface SchemaContext {
    state: SchemaReducerState;
    actions: {
        setDatabase: (database: string) => void;
        setSchema: (schema: string) => void;
        setTableName: (name: string) => void;
        setCurrentTable: (tableDetail: RelationSchemaTableResponse) => void;
        setCurrentBuilderStep: (value: SchemaBuilderStep) => void;
        setCurrentConfigStep: (value: SchemaConfigStep) => void;
        addTable: (table: RelationTableType) => void;
        editTable: (table: RelationSchemaTableResponse) => void;
        toggleDrawer: (open?: boolean) => void;
    };
}

const Context = createContext<SchemaContext | undefined>(undefined);

export enum ActionType {
    SET_DATABASE,
    SET_SCHEMA,
    SET_TABLE_NAME,
    SET_CURRENT_TABLE,
    SET_CURRENT_BUILDER_STEP,
    SET_CURRENT_CONFIG_STEP,
    ADD_TABLE,
    EDIT_TABLE,
    TOGGLE_DRAWER,
}

type Action =
    | {
          type: ActionType.SET_DATABASE;
          payload: string;
      }
    | {
          type: ActionType.SET_SCHEMA;
          payload: string;
      }
    | {
          type: ActionType.SET_TABLE_NAME;
          payload: string;
      }
    | {
          type: ActionType.SET_CURRENT_TABLE;
          payload: RelationSchemaTableResponse;
      }
    | {
          type: ActionType.SET_CURRENT_BUILDER_STEP;
          payload: SchemaBuilderStep;
      }
    | {
          type: ActionType.SET_CURRENT_CONFIG_STEP;
          payload: SchemaConfigStep;
      }
    | {
          type: ActionType.ADD_TABLE;
          payload: RelationTableType;
      }
    | {
          type: ActionType.EDIT_TABLE;
          payload: RelationSchemaTableResponse;
      }
    | {
          type: ActionType.TOGGLE_DRAWER;
          payload: boolean;
      };

export interface SchemaReducerState {
    schemaPayload: any;
    activeProject: Project | null;
    currentBuilderStep: SchemaBuilderStep;
    currentConfigStep: SchemaConfigStep;
    isEditMode: boolean;
    isDrawerOpen: boolean;
}

function reducer(
    state: SchemaReducerState,
    action: Action,
): SchemaReducerState {
    switch (action.type) {
        case ActionType.SET_DATABASE:
            return {
                ...state,
                schemaPayload: {
                    database: action.payload,
                    ...state.schemaPayload,
                },
            };

        case ActionType.SET_SCHEMA:
            return {
                ...state,
                schemaPayload: {
                    schema: action.payload,
                    ...state.schemaPayload,
                },
            };

        case ActionType.SET_TABLE_NAME:
            return {
                ...state,
                schemaPayload: {
                    ...state.schemaPayload,
                    name: action.payload,
                },
            };

        case ActionType.SET_CURRENT_TABLE:
            return {
                ...state,
                schemaPayload: {
                    name: state.schemaPayload.name,
                    ...state.schemaPayload,
                },
            };
        case ActionType.SET_CURRENT_BUILDER_STEP:
            return {
                ...state,
                currentBuilderStep: action.payload,
            };

        case ActionType.SET_CURRENT_CONFIG_STEP:
            return {
                ...state,
                currentConfigStep: action.payload,
            };

        case ActionType.ADD_TABLE:
            return {
                ...state,
                isEditMode: false,
                isDrawerOpen: true,
                schemaPayload: {
                    type: action.payload as RelationTableType,
                },
            };

        case ActionType.EDIT_TABLE:
            return {
                ...state,
                isEditMode: true,
                isDrawerOpen: true,
                schemaPayload: {
                    ...state.schemaPayload,
                    ...action.payload,
                },
            };

        case ActionType.TOGGLE_DRAWER:
            return {
                ...state,
                isDrawerOpen: action.payload,
            };

        default:
            return assertUnreachable(
                action,
                'Unexpected action in explore reducer',
            );
    }
}

export const SchemaProvider: React.FC<
    React.PropsWithChildren<{ initialState: SchemaReducerState }>
> = ({ children, initialState }) => {
    const [reducerState, dispatch] = useReducer(reducer, initialState);

    const setDatabase = useCallback((database: string) => {
        dispatch({
            type: ActionType.SET_DATABASE,
            payload: database,
        });
    }, []);

    const setSchema = useCallback((schema: string) => {
        dispatch({
            type: ActionType.SET_SCHEMA,
            payload: schema,
        });
    }, []);

    const setTableName = useCallback((name: string) => {
        dispatch({
            type: ActionType.SET_TABLE_NAME,
            payload: name,
        });
    }, []);

    const setCurrentTable = useCallback(
        (table: RelationSchemaTableResponse) => {
            dispatch({
                type: ActionType.SET_CURRENT_TABLE,
                payload: table,
            });
        },
        [],
    );

    const setCurrentBuilderStep = useCallback((value: SchemaBuilderStep) => {
        dispatch({
            type: ActionType.SET_CURRENT_BUILDER_STEP,
            payload: value,
        });
    }, []);

    const setCurrentConfigStep = useCallback((value: SchemaConfigStep) => {
        dispatch({
            type: ActionType.SET_CURRENT_CONFIG_STEP,
            payload: value,
        });
    }, []);

    const addTable = useCallback((table: RelationTableType) => {
        dispatch({
            type: ActionType.ADD_TABLE,
            payload: table,
        });

        dispatch({
            type: ActionType.SET_CURRENT_CONFIG_STEP,
            payload: SchemaConfigStep.CONFIGURE,
        });
    }, []);

    const editTable = useCallback((table: RelationSchemaTableResponse) => {
        dispatch({
            type: ActionType.EDIT_TABLE,
            payload: table,
        });

        dispatch({
            type: ActionType.SET_CURRENT_CONFIG_STEP,
            payload: SchemaConfigStep.CONFIGURE,
        });
    }, []);

    const toggleDrawer = useCallback(() => {
        dispatch({
            type: ActionType.TOGGLE_DRAWER,
            payload: !reducerState.isDrawerOpen,
        });
    }, [reducerState.isDrawerOpen]);

    const state = useMemo(() => ({ ...reducerState }), [reducerState]);

    const actions = useMemo(
        () => ({
            setDatabase,
            setSchema,
            setTableName,
            setCurrentTable,
            setCurrentBuilderStep,
            setCurrentConfigStep,
            addTable,
            editTable,
            toggleDrawer,
        }),
        [
            setDatabase,
            setSchema,
            setTableName,
            setCurrentTable,
            setCurrentBuilderStep,
            setCurrentConfigStep,
            addTable,
            editTable,
            toggleDrawer,
        ],
    );

    const value: SchemaContext = useMemo(
        () => ({ state, actions }),
        [state, actions],
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useSchemaContext<Selected>(
    selector: (value: SchemaContext) => Selected,
) {
    return useContextSelector(Context, (context) => {
        if (context === undefined) {
            throw new Error(
                'useExplorer must be used within a ExplorerProvider',
            );
        }

        return selector(context);
    });
}
