import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import { useLocale } from '@hooks/useLocale';
import { Text } from '@mantine/core';
import React from 'react';
import { ButtonVariant } from '../../../mantineTheme';

interface LaunchErrorModalProps {
    showErrorModal: boolean;
    onClose: () => void;
}

const LaunchErrorModal: React.FC<LaunchErrorModalProps> = ({
    showErrorModal,
    onClose,
}) => {
    const { t } = useLocale();
    return (
        <Modal
            opened={showErrorModal}
            onClose={onClose}
            keepMounted={false}
            title={t('journeys_error_modal.journey_cannot_be_launched')}
            size="md"
            footerRightSection={
                <ModalFooter
                    showSecondaryButton={true}
                    secondaryButtonVariant={ButtonVariant.OUTLINED}
                    secondaryButtonClick={onClose}
                    secondaryText={t('Review journey')}
                    primaryText={undefined}
                    primaryButtonClick={undefined}
                    showPrimaryButton={undefined}
                    primaryLeftIcon={undefined}
                    primaryRightIcon={undefined}
                    secondaryLeftIcon={undefined}
                    secondaryRightIcon={undefined}
                    primaryButtonVariant={undefined}
                    isLoading={undefined}
                    primaryButtonDisabled={undefined}
                    primaryButtonCustomClass={undefined}
                />
            }
        >
            <Text className="text-gray-800 font-normal text-sm">
                {t(
                    'journeys_error_modal.journey_cannot_be_launched_description',
                )}
            </Text>
        </Modal>
    );
};

export default LaunchErrorModal;
