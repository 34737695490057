import ChannelTabs from '@components/ChannelTabs';
import { type CommunicationChannel } from '@lightdash/common';
import { Tabs } from '@mantine/core';
import React from 'react';
import SubscriptionGroupModal from '../SubscriptionGroupModal';
import SubscriptionBody from './SubscriptionBody';

interface SubscriptionDetailsProps {
    onClose: () => void;
    isOpen: boolean;
    onGroupCreate: () => void;
    activeTab: CommunicationChannel;
    setActiveTab: (pill: CommunicationChannel) => void;
}

const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({
    onClose,
    isOpen,
    onGroupCreate,
    activeTab,
    setActiveTab,
}) => {
    const handlePillChange = (pill: CommunicationChannel) => {
        setActiveTab(pill);
    };

    return (
        <>
            <Tabs
                keepMounted={false}
                variant="pills"
                radius="lg"
                defaultValue={activeTab}
                onTabChange={handlePillChange}
            >
                <ChannelTabs activeTab={activeTab} />
            </Tabs>
            <SubscriptionBody
                onGroupCreate={onGroupCreate}
                activeTab={activeTab}
            />
            <SubscriptionGroupModal
                activeTab={activeTab}
                onClose={onClose}
                isOpen={isOpen}
            />
        </>
    );
};

export default SubscriptionDetails;
