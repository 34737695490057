import { Timezones } from '@lightdash/common';
import moment from 'moment';
import 'moment-timezone';
import { useCallback } from 'react';
import { useActiveProjectUuid } from './useActiveProject';
import { useProject } from './useProject';

export type TimestampType = {
    time: string;
    timezone: Timezones;
};

export const TIMEZONE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

const useTimestamp = () => {
    const { activeProjectUuid } = useActiveProjectUuid();
    const { data: projectData } = useProject(activeProjectUuid);
    const projectTimezone = projectData?.timezone ?? Timezones.UTC;

    const getTimestamp = useCallback(
        (
            inputTimestamp: string | number, // Can be a string or a number (epoch in milliseconds)
            newTimezone: Timezones = projectTimezone,
        ): string => {
            const isEpoch =
                typeof inputTimestamp === 'number' ||
                /^\d+$/.test(inputTimestamp);

            let momentDate;
            if (isEpoch) {
                const epochInMilliseconds =
                    typeof inputTimestamp === 'number'
                        ? inputTimestamp
                        : parseInt(inputTimestamp, 10);
                momentDate = moment(epochInMilliseconds);
            } else {
                const isValidTime = moment(
                    inputTimestamp,
                    TIMEZONE_FORMAT,
                    true,
                ).isValid();
                if (!isValidTime) {
                    console.error(
                        'Invalid time input provided to getTimestamp',
                    );
                    return '';
                }
                momentDate = moment(inputTimestamp, TIMEZONE_FORMAT);
            }

            return momentDate.tz(newTimezone).format(TIMEZONE_FORMAT);
        },
        [projectTimezone],
    );

    return { getTimestamp };
};

export default useTimestamp;
