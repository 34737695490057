import MantineIcon from '@components/common/MantineIcon';
import { useLocale } from '@hooks/useLocale';
import { type Filters } from '@lightdash/common';
import { ActionIcon, Box, Divider, Group, Menu, Text } from '@mantine/core';
import { CalendarBlank, Copy, Funnel } from '@phosphor-icons/react';
import { IconDots, IconX } from '@tabler/icons-react';
import React from 'react';
import FilterFormPropertySelect from './FilterFormPropertySelect';
import { useFiltersContext } from './FiltersProvider';

interface MenuForEventsFilterRuleProps {
    onDelete: () => void;
    addTimeWindow: () => void;
    duplicateFilter: () => void;
    filters?: Filters;
    setFilters?: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    groupIndex?: number;
}

export const MenuForEventFilterRule: React.FC<MenuForEventsFilterRuleProps> = ({
    onDelete,
    addTimeWindow,
    duplicateFilter,
    filters,
    setFilters,
    groupIndex,
}) => {
    const { setFilterableFields, setShowFilter } = useFiltersContext();
    const { t } = useLocale();

    return (
        <Group className="flex items-center justify-center gap-1">
            <Menu
                position="bottom-end"
                shadow="md"
                closeOnItemClick
                withArrow
                arrowPosition="center"
            >
                <Menu.Target>
                    <Box>
                        <ActionIcon variant="subtle">
                            <IconDots size="13" />
                        </ActionIcon>
                    </Box>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Item
                        onClick={addTimeWindow}
                        icon={<CalendarBlank weight="duotone" />}
                    >
                        {t('audience_filter.event_filter_menu_item')}
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => {
                            setShowFilter(true);
                            setFilterableFields({
                                showDimensions: false,
                                showMetrics: true,
                                showEvents: false,
                                showEventProperties: true,
                            });
                        }}
                        icon={<Funnel weight="duotone" />}
                    >
                        <FilterFormPropertySelect
                            targetButton={
                                <Text>
                                    {t(
                                        'audience_filter.event_property_filter_menu_item',
                                    )}
                                </Text>
                            }
                            filters={filters ?? {}}
                            setFilters={setFilters ?? (() => {})}
                            index={groupIndex ?? 0}
                        />
                    </Menu.Item>

                    <Divider />

                    <Menu.Item
                        onClick={duplicateFilter}
                        icon={<Copy weight="duotone" />}
                    >
                        {t('audience_filter.duplicate_filter')}
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
            <ActionIcon onClick={onDelete}>
                <MantineIcon icon={IconX} size="md" />
            </ActionIcon>
        </Group>
    );
};

interface MenuForDimensionFilterRuleProps {
    duplicateFilter: () => void;
    onDelete: () => void;
}

export const MenuForDimensionFilterRule: React.FC<
    MenuForDimensionFilterRuleProps
> = ({ duplicateFilter, onDelete }) => {
    return (
        <Group className="flex items-center justify-center gap-1">
            <Menu
                position="bottom-end"
                shadow="md"
                closeOnItemClick
                withArrow
                arrowPosition="center"
            >
                <Menu.Target>
                    <Box>
                        <ActionIcon variant="subtle">
                            <IconDots size="13" />
                        </ActionIcon>
                    </Box>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Item onClick={duplicateFilter}>
                        Duplicate filter
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
            <ActionIcon onClick={onDelete}>
                <MantineIcon icon={IconX} size="md" />
            </ActionIcon>
        </Group>
    );
};
