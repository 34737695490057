import { useLocale } from '@hooks/useLocale';
import { Button, Dialog, Flex, Group, Text } from '@mantine/core';
import React from 'react';
import { AlertCircle } from 'react-feather';
import { ButtonVariant } from '../../../mantineTheme';

interface UnsavedChangesModalProps {
    opened: boolean;
    close?: () => void;
    disclaimerText?: string;
    primaryActionButtonText?: string;
    secondaryActionButtonText?: string;
    primaryActionButtonClick?: () => void;
    secondaryActionButtonClick: () => void;
    disableButtons: boolean;
    form?: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
}

const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({
    opened,
    close,
    disclaimerText,
    primaryActionButtonText,
    secondaryActionButtonText,
    primaryActionButtonClick,
    secondaryActionButtonClick,
    disableButtons = false,
    ...rest
}) => {
    const { t } = useLocale();

    return (
        <Dialog
            opened={opened}
            onClose={close}
            position={{ bottom: '20px', left: 0, right: 0 }}
            className="flex items-center justify-center w-full bg-transparent border-none shadow-none"
        >
            <Flex className="border-base max-w-[1000px] min-w-[800px] py-3.5 px-7 shadow-card rounded-3xl bg-white backdrop-blur-md">
                <Flex className="flex-row gap-2.5 items-center justify-between w-full">
                    <Group className="flex gap-1.5 items-center justify-between">
                        <AlertCircle
                            size={13}
                            color={'rgb(var(--color-gray-600))'}
                            strokeWidth={2.5}
                        />
                        <Text className="text-sm font-medium text-gray-600">
                            {disclaimerText
                                ? disclaimerText
                                : t('unsaved_changes_modal.disclaimer_text')}
                        </Text>
                    </Group>
                    <Group>
                        <Button
                            onClick={secondaryActionButtonClick}
                            variant={ButtonVariant.OUTLINED}
                            disabled={disableButtons}
                        >
                            {secondaryActionButtonText
                                ? secondaryActionButtonText
                                : t(
                                      'unsaved_changes_modal.secondary_button_text',
                                  )}
                        </Button>
                        <Button
                            onClick={primaryActionButtonClick}
                            disabled={disableButtons}
                            {...rest}
                        >
                            {primaryActionButtonText
                                ? primaryActionButtonText
                                : t(
                                      'unsaved_changes_modal.primary_button_text',
                                  )}
                        </Button>
                    </Group>
                </Flex>
            </Flex>
        </Dialog>
    );
};

export default React.memo(UnsavedChangesModal);
