import StepTitle from '@components/Campaigns/Builder/Steps/StepTitle';
import TimeInput from '@components/common/Inputs/TimeInput';
import { useActiveProjectUuid } from '@hooks/useActiveProject';
import { useLocale } from '@hooks/useLocale';
import { useProject } from '@hooks/useProject';
import useTimestamp from '@hooks/useTimestamp';
import { CampaignScheduleType, Timezones } from '@lightdash/common';
import { Box, Group, Radio, Text } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useCampaignContext } from '@providers/CampaignProvider';
import moment from 'moment';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Calendar, Clock } from 'react-feather';

type CampaignSchedulerProps = {
    setTimeError: (val: boolean) => void;
    timeError: boolean;
};

const CampaignScheduler: React.FC<CampaignSchedulerProps> = ({
    setTimeError,
    timeError,
}) => {
    const { t } = useLocale();
    const ref = useRef<HTMLInputElement>(null);
    const { getTimestamp } = useTimestamp();

    const { activeProjectUuid } = useActiveProjectUuid();
    const { data: projectData } = useProject(activeProjectUuid);
    const projectTimezone = projectData?.timezone ?? Timezones.UTC;

    const { state, actions } = useCampaignContext((context) => context);
    const { campaignPayload, isEditMode } = state;
    const { setCampaignScheduleType, setSchedulerExecutionTime } = actions;

    const handleRadioSelect = useCallback(
        (value: string) => {
            setCampaignScheduleType(value as CampaignScheduleType);
        },
        [setCampaignScheduleType],
    );

    const minimumDateTime = useMemo(() => {
        if (campaignPayload.schedule?.executionTime) {
            if (typeof campaignPayload.schedule?.executionTime === 'string') {
                return new Date(campaignPayload.schedule?.executionTime);
            }
            return new Date(
                getTimestamp(campaignPayload.schedule.executionTime.getTime()),
            );
        }

        const date = new Date();
        date.setMinutes(date.getMinutes() + 15);
        return new Date(getTimestamp(date.getTime()));
    }, [getTimestamp, campaignPayload]);

    const [scheduleTime, setScheduleTime] = useState<Date>(minimumDateTime);

    useEffect(() => {
        if (ref.current) {
            ref.current.value = `${scheduleTime
                .getHours()
                .toString()
                .padStart(2, '0')}:${scheduleTime
                .getMinutes()
                .toString()
                .padStart(2, '0')}`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderCampaignScheduleTypeNote = useMemo(() => {
        if (campaignPayload.schedule?.type === CampaignScheduleType.MANUAL) {
            return t('campaign_scheduler.manual_note');
        }

        if (campaignPayload.schedule?.type === CampaignScheduleType.DELAYED) {
            return t('campaign_scheduler.scheduled_note', {
                time: scheduleTime.toLocaleString(), //FIXME: change this to the readable string
            });
        }

        return '';
    }, [campaignPayload.schedule?.type, t, scheduleTime]);

    // const handleDateChange = useCallback(
    //     (value: Date) => {
    //         setScheduleTime(value);
    //         setSchedulerExecutionTime(value);
    //     },
    //     [setScheduleTime, setSchedulerExecutionTime],
    // );

    // const handleTimeChange = useCallback(
    //     (value: string) => {
    //         console.log(projectTimezone);
    //         const [hours, minutes] = value.split(':');
    //         const date = new Date(scheduleTime);
    //         date.setHours(parseInt(hours, 10));
    //         date.setMinutes(parseInt(minutes, 10));
    //         setScheduleTime(date);
    //         setSchedulerExecutionTime(date);
    //     },
    //     [scheduleTime, setSchedulerExecutionTime],
    // );

    const convertToProjectTimezone = useCallback(
        (date: Date): Date => {
            const localTime = moment(date);
            const projectTime = moment.tz(
                localTime.format('YYYY-MM-DDTHH:mm:ss'),
                projectTimezone,
            );
            return new Date(projectTime.format());
        },
        [projectTimezone],
    );

    const handleDateChange = useCallback(
        (value: Date) => {
            setScheduleTime(value);
            const datetime = convertToProjectTimezone(value);
            setSchedulerExecutionTime(datetime);
        },
        [setScheduleTime, setSchedulerExecutionTime, convertToProjectTimezone],
    );

    const handleTimeChange = useCallback(
        (value: string) => {
            const [hours, minutes] = value.split(':');
            if (Number(minutes) % 15 !== 0) {
                setTimeError(true);
                return;
            }
            setTimeError(false);
            const date = new Date(scheduleTime);
            date.setHours(parseInt(hours, 10));
            date.setMinutes(parseInt(minutes, 10));
            setScheduleTime(date);
            const datetime = convertToProjectTimezone(date);
            setSchedulerExecutionTime(datetime);
        },
        [
            setTimeError,
            scheduleTime,
            convertToProjectTimezone,
            setSchedulerExecutionTime,
        ],
    );

    return (
        <Box>
            <StepTitle title={'When should this campaign be triggered?'} />
            <Box className="mt-6">
                <Radio.Group
                    name="campaign-trigger"
                    value={campaignPayload.schedule?.type}
                    onChange={handleRadioSelect}
                    className="flex flex-col gap-3"
                >
                    <Radio
                        value={CampaignScheduleType.MANUAL}
                        label={t('campaign_schedule.manual')}
                        disabled={!isEditMode}
                    />

                    <Radio
                        value={CampaignScheduleType.DELAYED}
                        label={t('campaign_schedule.delayed_label')}
                        disabled={!isEditMode}
                    />
                </Radio.Group>
            </Box>
            {campaignPayload.schedule?.type ===
                CampaignScheduleType.DELAYED && (
                <Box className="flex items-start mt-7">
                    <Group className="gap-3" align="flex-start">
                        <DateInput
                            valueFormat="DD MMM YYYY"
                            label="Scheduled date"
                            placeholder="DD/MM/YYYY"
                            icon={
                                <Calendar
                                    size={13}
                                    strokeWidth={2}
                                    color={'rgb(var(--color-gray-600))'}
                                />
                            }
                            minDate={new Date()}
                            onChange={handleDateChange}
                            value={scheduleTime}
                        />
                        <TimeInput
                            ref={ref}
                            label="Scheduled time"
                            icon={
                                <Clock
                                    size={13}
                                    strokeWidth={2}
                                    color={'rgb(var(--color-gray-600))'}
                                />
                            }
                            onClick={() => ref?.current?.showPicker()}
                            onChange={(event) =>
                                handleTimeChange(event.target.value)
                            }
                            error={
                                timeError &&
                                t('campaign_scheduler.minutes_error')
                            }
                            className="w-[120px]"
                        />
                    </Group>
                </Box>
            )}

            <Box className="mt-6">
                <Text className="text-sm text-gray-600">
                    {renderCampaignScheduleTypeNote}
                </Text>
            </Box>
        </Box>
    );
};

export default React.memo(CampaignScheduler);
