import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import LinkButton from '@components/common/LinkButton';
import { useOrganization } from '@hooks/organization/useOrganization';
import useNotify from '@hooks/toaster/useNotify';
import {
    useActiveProjectUuid,
    useUpdateActiveProjectMutation,
} from '@hooks/useActiveProject';
import { useLocale } from '@hooks/useLocale';
import { useProjects } from '@hooks/useProjects';
import {
    Box,
    Button,
    createStyles,
    Flex,
    Menu,
    Stack,
    Text,
} from '@mantine/core';
import { PlusCircle, Suitcase } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import React, { useCallback, useMemo } from 'react';
import { ChevronDown } from 'react-feather';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useToggle } from 'react-use';
import { ButtonVariant } from '../../mantineTheme';

const useStyles = createStyles((_theme) => ({
    dropdown: {
        width: '220px !important',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}));

const swappableProjectRoutes = (activeProjectUuid: string) => [
    `/projects/${activeProjectUuid}/relations`,
    `/projects/${activeProjectUuid}/audiences/create`,
    `/projects/${activeProjectUuid}/audiences`,
    `/projects/${activeProjectUuid}/campaigns/create`,
    `/projects/${activeProjectUuid}/campaigns`,
    `/projects/${activeProjectUuid}`,
];

const ProjectSwitcher = () => {
    const { isInitialLoading: isLoadingProjects, data: projects } =
        useProjects();
    const { isLoading: isLoadingActiveProjectUuid, activeProjectUuid } =
        useActiveProjectUuid();
    const { mutate: setLastProjectMutation } = useUpdateActiveProjectMutation();
    const { isInitialLoading: isOrgLoading, data: organization } =
        useOrganization();
    const { showToastSuccess } = useNotify();
    const history = useHistory();
    const { user } = useApp();
    const [isOpen, toggleOpen] = useToggle(false);

    const { classes } = useStyles();
    const { t } = useLocale();
    const ability = useAbilityContext();
    const showWorkSpaceSettings = ability.can(
        'view',
        subject('ProjectSettings', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid: activeProjectUuid,
        }),
    );

    const showCreateWorkspace = ability.can(
        'create',
        subject('Project', { organizationUuid: user.data?.organizationUuid }),
    );
    const showManageOrganization = ability.can(
        'manage',
        subject('OrganizationSetting', {
            organizationUuid: user.data?.organizationUuid,
        }),
    );
    const isHomePage = !!useRouteMatch({
        path: '/projects/:projectUuid/relations',
        exact: true,
    });

    const swappableRouteMatch = useRouteMatch(
        activeProjectUuid
            ? { path: swappableProjectRoutes(activeProjectUuid), exact: true }
            : [],
    );

    const shouldSwapProjectRoute = !!swappableRouteMatch && activeProjectUuid;
    const handleNewProject = useCallback(() => {
        history.push('/create-workspace');
    }, [history]);

    const currentProjectName = useMemo(() => {
        if (isLoadingProjects || isLoadingActiveProjectUuid) return null;

        const currentProject = projects?.find(
            (project) => project.projectUuid === activeProjectUuid,
        );

        return currentProject?.name;
    }, [
        activeProjectUuid,
        isLoadingActiveProjectUuid,
        isLoadingProjects,
        projects,
    ]);

    // const activeProject = useMemo(() => {
    //     if (!activeProjectUuid || !projects) return null;
    //     return projects.find((p) => p.projectUuid === activeProjectUuid);
    // }, [activeProjectUuid, projects]);

    // const inactiveProjects = useMemo(() => {
    //     if (!activeProjectUuid || !projects) return [];
    //     return projects.filter((p) => p.projectUuid !== activeProjectUuid);
    // }, [activeProjectUuid, projects]);

    if (
        isLoadingProjects ||
        isLoadingActiveProjectUuid ||
        isOrgLoading ||
        !projects ||
        projects.length === 0
    ) {
        return null;
    }

    const handleProjectChange = (newUuid: string) => {
        if (!newUuid) return;

        const project = projects?.find((p) => p.projectUuid === newUuid);
        if (!project) return;
        setLastProjectMutation(project.projectUuid);

        showToastSuccess({
            title: t('workspace_settings_menu.project_switch_success', {
                projectName: project.name,
            }),
            action:
                !isHomePage && shouldSwapProjectRoute
                    ? {
                          children: t(
                              'workspace_settings_menu.project_switch_success_btn',
                          ),
                          onClick: () => {
                              history.push(
                                  `/projects/${project.projectUuid}/home`,
                              );
                          },
                      }
                    : undefined,
        });

        if (shouldSwapProjectRoute) {
            history.push(
                swappableRouteMatch.path.replace(
                    activeProjectUuid,
                    project.projectUuid,
                ),
            );
        } else {
            history.push(`/projects/${project.projectUuid}/relations`);
        }
    };

    return (
        <>
            <Menu
                position="bottom-end"
                offset={6}
                classNames={classes}
                opened={isOpen}
                onChange={toggleOpen}
                styles={{
                    itemLabel: {
                        width: '100%',
                    },
                    item: {
                        width: '100% !important',
                    },
                }}
            >
                <Menu.Target>
                    <Button
                        data-testid="settings-menu"
                        className={`px-3 font-semibold text-white rounded-lg hover:rounded-lg  hover:!bg-none ${
                            isOpen ? 'active-nav-link' : 'inactive-nav-link'
                        }`}
                        variant={ButtonVariant.PRIMARY}
                        rightIcon={
                            <ChevronDown
                                className={`h-5 w-3.5 text-coal-dark ${
                                    isOpen
                                        ? `rotate-180 transform duration-300 ease-in-out`
                                        : `rotate-0 transform duration-300 ease-in-out`
                                }`}
                                strokeWidth={3}
                            />
                        }
                    >
                        {currentProjectName}
                    </Button>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Item className="group">
                        {showWorkSpaceSettings ? (
                            <LinkButton
                                href={`/projects/${activeProjectUuid}/settings/overview`}
                                className="w-full"
                            >
                                <Flex className="flex items-center justify-between w-full gap-2 py-1 cursor-pointer ">
                                    <Text className="truncate w-[150px] text-sm  font-medium text-gray-800">
                                        {currentProjectName}
                                    </Text>
                                    <Text className="text-xs font-semibold text-gray-600 group-hover:text-blu-800">
                                        {t(
                                            'workspace_settings_menu.modify_workspace_btn',
                                        )}
                                    </Text>
                                </Flex>
                            </LinkButton>
                        ) : (
                            <Text className="truncate w-[150px] text-sm  font-medium text-gray-800 hover:bg-none">
                                {currentProjectName}
                            </Text>
                        )}
                    </Menu.Item>

                    {projects?.length > 0 && (
                        <>
                            <Box className="px-3">
                                <Menu.Divider className="border rounded border-shade-2" />
                            </Box>
                            <Stack className="flex flex-col gap-0 overflow-y-scroll max-h-60">
                                {projects.length != 1 ? (
                                    <Box className="flex items-center justify-between w-full px-3 py-1.5 text-gray-600 sticky top-0 bg-white">
                                        <Text className="text-xs font-semibold">
                                            {t(
                                                'workspace_settings_menu.switch_workspace_section',
                                            )}
                                        </Text>
                                        {showCreateWorkspace && (
                                            <PlusCircle
                                                onClick={handleNewProject}
                                                className="hover:cursor-pointer"
                                            />
                                        )}
                                    </Box>
                                ) : (
                                    showCreateWorkspace && (
                                        <Box
                                            className=" flex text-gray-600 hover:cursor-pointer py-1.5 px-3 gap-x-1.5 hover:bg-shade-4"
                                            onClick={handleNewProject}
                                        >
                                            <PlusCircle />
                                            <Text>
                                                {t(
                                                    'workspace_settings_menu.create_workspace_text',
                                                )}
                                            </Text>
                                        </Box>
                                    )
                                )}

                                {projects?.map((project) => {
                                    if (
                                        project.projectUuid ===
                                        activeProjectUuid
                                    )
                                        return null;
                                    return (
                                        <Menu.Item
                                            role="menuitem"
                                            key={project.projectUuid}
                                            className="hover:bg-shade-4"
                                            onClick={() =>
                                                handleProjectChange(
                                                    project.projectUuid,
                                                )
                                            }
                                        >
                                            <Text className="text-sm font-medium text-gray-800">
                                                {project.name}
                                            </Text>
                                        </Menu.Item>
                                    );
                                })}
                            </Stack>
                        </>
                    )}

                    {showManageOrganization && (
                        <>
                            <Box>
                                <Menu.Divider className="border border-shade-2" />
                            </Box>

                            <Box>
                                <Menu.Item
                                    role="menuitem"
                                    className="hover:bg-shade-4"
                                    icon={<Suitcase />}
                                >
                                    <LinkButton href={'/organization/overview'}>
                                        <Text className="text-sm font-medium text-gray-800 truncate w-[165px]">
                                            {t(
                                                'workspace_settings_menu.manage_organization_btn',
                                                {
                                                    organizationName:
                                                        organization?.name,
                                                },
                                            )}
                                        </Text>
                                    </LinkButton>
                                </Menu.Item>
                            </Box>
                        </>
                    )}
                </Menu.Dropdown>
            </Menu>
        </>
    );
};

export default React.memo(ProjectSwitcher);
