import { subject } from '@casl/ability';
import { getCampaignAnalyticsDashboardUrl } from '@components/Campaigns/campaignUtils';
import { useAbilityContext } from '@components/common/Authorization';
import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Modal from '@components/common/modal/Modal';
import Timestamp from '@components/common/Timestamp';
import UserCount from '@components/common/UserCount';
import { useDeleteCampaign, useTriggerCampaign } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceType,
    CampaignStatus,
    type CommunicationChannel,
    type ReducedCampaign,
} from '@lightdash/common';
import { Box, Button, Flex, Loader, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    CheckCircle,
    CopySimple,
    FileCsv,
    HourglassLow,
    PaperPlaneTilt,
    Pencil,
    Trash,
    TrendUp,
    WarningCircle,
} from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { useProjectContext } from '@providers/ProjectProvider';
import { useQueryClient } from '@tanstack/react-query';
import { type ColumnDef } from '@tanstack/react-table';
import { t as translate } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';
import { allowCampaignEdits } from '../utils';

interface CampaignMenuItemProps {
    rowData: ReducedCampaign;
}
const CAMPAIGN_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start !w-56`;
const CAMPAIGN_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-56`;
const CAMPAIGN_TABLE_CELL_DESCRIPTION = `text-xs font-normal text-gray-600 truncate !w-56`;

const CampaignMenuItem: React.FC<CampaignMenuItemProps> = ({ rowData }) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { mutateAsync: mutateDeleteCampaign } = useDeleteCampaign();
    const { mutateAsync: mutateTriggerCampaign } = useTriggerCampaign();
    const queryClient = useQueryClient();
    const { projectData } = useProjectContext();
    const history = useHistory();
    const { t } = useLocale();
    const { user } = useApp();
    const [opened, { open, close }] = useDisclosure(false);
    const ability = useAbilityContext();
    const canEditCampaigns = ability.can(
        'manage',
        subject('Campaign', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const handleDuplicate = useCallback(
        (id: string) => {
            history.push(
                `/projects/${projectUuid}/campaigns/create?templateId=${id}`,
            );
        },
        [projectUuid, history],
    );

    const handleDelete = useCallback(
        async (id: string) => {
            await mutateDeleteCampaign({ campaignId: id });
            await queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
            close();
        },
        [mutateDeleteCampaign, queryClient, close],
    );

    const handleTrigger = useCallback(
        async (id: string) => {
            await mutateTriggerCampaign({ campaignId: id });
            await queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
        },
        [mutateTriggerCampaign, queryClient],
    );

    const handleEdit = useCallback(
        (id: string) => {
            history.push(`/projects/${projectUuid}/campaigns/${id}/edit`);
        },
        [projectUuid, history],
    );

    const isCampaignEditable = useMemo(
        () =>
            allowCampaignEdits({
                executionTime: rowData?.scheduledAt,
                status: rowData?.status,
            }),
        [rowData],
    );

    const menuItems = useMemo(() => {
        let items: CustomMenuItemProps<ReducedCampaign>[] = [];
        if (rowData?.status === CampaignStatus.DRAFT) {
            items = [
                {
                    leftSection: (
                        <PaperPlaneTilt
                            size={14}
                            weight="duotone"
                            color={'rgb(var(--color-gray-600))'}
                            strokeWidth={2.5}
                        />
                    ),
                    children: translate('campaign_manager.menu_item_send'),
                    onClick: (values) => handleTrigger(values?.id ?? ''),
                    isDivider: true,
                },
                {
                    leftSection: (
                        <Pencil
                            size={14}
                            weight="duotone"
                            color={'rgb(var(--color-gray-600))'}
                            strokeWidth={2.5}
                        />
                    ),
                    children: translate('campaign_manager.menu_item_edit'),
                    onClick: (values) => handleEdit(values?.id ?? ''),
                },
            ];
        } else if (rowData?.status === CampaignStatus.COMPLETED) {
            items = [
                {
                    leftSection: (
                        <TrendUp
                            size={14}
                            weight="duotone"
                            color={'rgb(var(--color-gray-600))'}
                            strokeWidth={2.5}
                        />
                    ),
                    children: translate(
                        'campaign_manager.menu_item_view_report',
                    ),
                    onClick: (_values) => {
                        const dashboardUrl = getCampaignAnalyticsDashboardUrl(
                            projectData,
                            rowData.id,
                        );
                        history.push(dashboardUrl);
                    },
                    isDivider: true,
                },
            ];
        } else if (rowData?.status === CampaignStatus.IN_PROGRESS) {
            items = [
                {
                    leftSection: (
                        <Loader
                            size={14}
                            color={'rgb(var(--color-blu-800))'}
                            strokeWidth={2.5}
                        />
                    ),
                    children: translate('campaign_manager.tr_status_progress'),
                    isDivider: true,
                },
            ];
        } else if (rowData?.status === CampaignStatus.SCHEDULED) {
            if (isCampaignEditable) {
                items = [
                    {
                        leftSection: (
                            <Pencil
                                size={14}
                                color={'rgb(var(--color-gray-600))'}
                                strokeWidth={2.5}
                                weight="duotone"
                            />
                        ),
                        children: translate('campaign_manager.menu_item_edit'),
                        onClick: (values) => handleEdit(values?.id ?? ''),
                    },
                ];
            } else {
                items = [
                    {
                        leftSection: (
                            <Loader
                                size={14}
                                color={'rgb(var(--color-blu-800))'}
                                strokeWidth={2.5}
                            />
                        ),
                        children: translate(
                            'campaign_manager.tr_status_progress',
                        ),
                        isDivider: true,
                    },
                ];
            }
        }
        if (rowData.audienceType === AudienceType.WAREHOUSE) {
            items.push({
                leftSection: (
                    <CopySimple
                        size={14}
                        weight="duotone"
                        color={'rgb(var(--color-gray-600))'}
                        strokeWidth={2.5}
                    />
                ),
                children: translate('campaign_manager.menu_item_duplicate'),
                onClick: (values) => handleDuplicate(values?.id ?? ''),
            });
        }
        items = [
            ...items,
            {
                leftSection: (
                    <Trash
                        size={14}
                        weight="duotone"
                        color={'rgb(var(--color-gray-600))'}
                        strokeWidth={2.5}
                    />
                ),
                children: translate('campaign_manager.menu_item_delete'),
                onClick: () => open(),
            },
        ];

        return items;
    }, [
        handleDuplicate,
        handleEdit,
        handleTrigger,
        history,
        open,
        projectData,
        rowData,
        isCampaignEditable,
    ]);

    return (
        <>
            {canEditCampaigns && (
                <CustomMenu<ReducedCampaign>
                    menuItems={menuItems}
                    data={rowData}
                />
            )}
            <Modal
                opened={opened}
                onClose={close}
                title={
                    <Flex direction={'column'} align={'flex-start'}>
                        <Text>{t('campaign.delete_modal_title')}</Text>
                        <Text className="text-sm font-normal text-gray-600">
                            {rowData?.name}
                        </Text>
                    </Flex>
                }
                centered
                footerRightSection={
                    <Flex justify={'flex-end'}>
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            className="py-2 mr-2"
                            onClick={() => close()}
                        >
                            {t('campaign.delete.modal.dont_delete')}
                        </Button>
                        <Button
                            variant={ButtonVariant.FILLED_DESTRUCTIVE}
                            style={{
                                backgroundColor:
                                    'rgb(var(--color-halt-800)) !important',
                            }}
                            onClick={async (e) => {
                                e.stopPropagation();
                                if (rowData?.id)
                                    await handleDelete(rowData?.id);
                            }}
                        >
                            {t('campaign.delete.modal.confirm_button')}{' '}
                            <Trash
                                size={14}
                                weight="duotone"
                                color={'rgb(var(--color-gray-600))'}
                            />
                        </Button>
                    </Flex>
                }
            >
                <Text className="text-sm">
                    {t('campaign.delete.modal.description')}
                </Text>
            </Modal>
        </>
    );
};

export const useCampaignColumns = () => {
    const [columns, setColumns] = useState<ColumnDef<ReducedCampaign>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: translate('campaign_manager.th_campaign_name'),
                    cell: ({ row, getValue }) => (
                        <Box className={CAMPAIGN_TABLE_CELL_CLASSNAME}>
                            <Text className={CAMPAIGN_TABLE_CELL_NAME}>
                                {getValue<string>()}
                            </Text>
                            {row?.original?.description && (
                                <Text
                                    className={CAMPAIGN_TABLE_CELL_DESCRIPTION}
                                >
                                    {row.original.description}
                                </Text>
                            )}
                        </Box>
                    ),
                },
                {
                    accessorKey: 'channel',
                    header: translate('campaign_manager.th_channel'),
                    cell: ({ row }) => (
                        <Box>
                            <ChannelIcon
                                channel={
                                    row?.original
                                        ?.channel as CommunicationChannel
                                }
                                color={'rgb(var(--color-gray-600)'}
                            />
                        </Box>
                    ),
                },
                {
                    accessorKey: 'audienceCount',
                    header: translate('campaign_manager.th_audience_count'),
                    cell: ({ getValue, row }) => (
                        <Box>
                            <UserCount
                                count={getValue<string>()}
                                withRightSection={false}
                                leftSection={
                                    row.original.audienceType ===
                                    AudienceType.CSV ? (
                                        <FileCsv
                                            size={14}
                                            color="rgb(var(--color-gray-700)"
                                        />
                                    ) : undefined
                                }
                            />
                            <Text className="text-xs text-gray-500">
                                {row.original.audienceType ===
                                AudienceType.WAREHOUSE
                                    ? row?.original?.audienceName
                                    : row.original.fileName}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'scheduledAt',
                    header: translate('campaign_manager.th_status'),
                    cell: ({ row }) => {
                        const { original } = row;
                        const status = original?.status;
                        const scheduledAt =
                            original?.scheduledAt?.toString() ?? '';

                        let content;

                        switch (status) {
                            case CampaignStatus.COMPLETED:
                                content = (
                                    <Box className="flex flex-row items-center justify-start gap-1">
                                        <CheckCircle
                                            size={14}
                                            weight="duotone"
                                            color={'rgb(var(--color-blu-800))'}
                                        />
                                        <Text className="text-sm font-normal text-blu-800">
                                            {translate(
                                                'campaign_manager.tr_status_completed',
                                            )}
                                        </Text>
                                    </Box>
                                );
                                break;
                            case CampaignStatus.IN_PROGRESS:
                                content = (
                                    <Box className="flex flex-row items-center justify-start gap-1">
                                        <Loader
                                            size={14}
                                            color={'rgb(var(--color-blu-800))'}
                                        />
                                        <Text className="text-sm font-normal text-blu-800">
                                            {translate(
                                                'campaign_manager.tr_status_progress',
                                            )}
                                        </Text>
                                    </Box>
                                );
                                break;
                            case CampaignStatus.DRAFT:
                                content = (
                                    <Text className="text-sm font-normal text-gray-600">
                                        {translate(
                                            'campaign_manager.tr_status_draft',
                                        )}
                                    </Text>
                                );
                                break;
                            case CampaignStatus.FAILURE:
                                content = (
                                    <Box className="flex flex-row items-center justify-start gap-1">
                                        <WarningCircle
                                            size={14}
                                            weight="duotone"
                                            color={'rgb(var(--color-halt-800))'}
                                        />
                                        <Text className="text-sm font-normal text-halt-800">
                                            {translate(
                                                'campaign_manager.tr_status_failed',
                                            )}
                                        </Text>
                                    </Box>
                                );
                                break;
                            case CampaignStatus.SCHEDULED:
                                content = (
                                    <Box className="flex flex-row items-center justify-start gap-1">
                                        <HourglassLow
                                            size={14}
                                            weight="duotone"
                                            color={
                                                'rgb(var(--color-mustard-800))'
                                            }
                                        />
                                        <Text
                                            className="text-sm font-normal"
                                            style={{
                                                color: 'rgb(var(--color-mustard-800))',
                                            }}
                                        >
                                            <Timestamp
                                                timestamp={scheduledAt}
                                            />
                                        </Text>
                                    </Box>
                                );
                                break;
                            default:
                                content = (
                                    <Text className="text-sm font-normal text-halt-800">
                                        {translate(
                                            'campaign_manager.tr_status_draft',
                                        )}
                                    </Text>
                                );
                        }
                        return <Box>{content}</Box>;
                    },
                },
                {
                    accessorKey: 'createdAt',
                    header: translate('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`by ${
                                    row?.original?.createdBy?.firstName || ''
                                } ${row?.original?.createdBy?.lastName || ''}`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => (
                        <CampaignMenuItem rowData={row?.original} />
                    ),
                },
            ]);
        }
    }, [projectUuid]);

    return columns;
};
