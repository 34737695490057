import { Text, Tooltip, type TextProps } from '@mantine/core';

interface TruncatedTextProps extends TextProps {
    text: string;
    maxWidth: number;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({
    text,
    maxWidth,
    ...rest
}) => {
    const maxLength = maxWidth / 5;
    const truncatedText =
        text.length > maxLength
            ? text.slice(0, maxLength / 2) + '...' + text.slice(-maxLength / 2)
            : text;

    return (
        <Tooltip
            label={text}
            disabled={text.length <= maxLength}
            color="dark"
            position="bottom"
            styles={{
                tooltip: {
                    backgroundColor: 'black',
                    color: 'white',
                },
            }}
        >
            <Text {...rest} className={`max-w-[${maxWidth}px]`}>
                {truncatedText}
            </Text>
        </Tooltip>
    );
};

export default TruncatedText;
