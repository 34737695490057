import BlockNode from '@components/Journeys/Builder/ReactFlow/Nodes/BlockNode';
import PlaceholderNode from '@components/Journeys/Builder/ReactFlow/Nodes/Placeholders/PlaceholderNode';
import { type EdgeTypes, type NodeTypes } from 'reactflow';
import { JourneyEdgeEnum, JourneyNodeEnum } from '../types';
import BlockEdge from './Edges/BlockEdge';

export const reactFlowNodeTypes: NodeTypes = {
    [JourneyNodeEnum.PLACEHOLDER]: PlaceholderNode,
    [JourneyNodeEnum.BLOCK]: BlockNode,
    [JourneyNodeEnum.TRIGGER]: BlockNode,
};

export const reactFlowEdgeTypes: EdgeTypes = {
    [JourneyEdgeEnum.BLOCK]: BlockEdge,
};
