import moment from 'moment';

/**
 * Converts a timestamp to a human-readable string format.
 * @param timestamp - The timestamp to be converted, either as a string or a number.
 * @returns The formatted timestamp as a human-readable string.
 */

// export const toCampaignAnalyticsTimeFormat = (
//     timestamp: string | number,
// ): string => {
//     return moment(timestamp).format('DD MMM YYYY hh:mm A');
// };

export const toCampaignAnalyticsTimeFormat = (
    timestamp: string | number,
): string => {
    const parsedMoment = moment.parseZone(timestamp);

    const formattedDate = parsedMoment.format('DD MMM YYYY hh:mm A');

    return formattedDate;
};
