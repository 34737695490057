import SkeletonLoader from '@components/common/SkeletonLoader';
import HTMLPreview from '@components/Templates/HTMLManager/HTMLPreview';
import SMSPreview from '@components/Templates/SMSManager/SMSPreview';
import TemplateOptionsModal from '@components/Templates/TemplateOptionsModal';
import {
    useGetIntegratedProviderConfig,
    useGetProviderMetada,
} from '@hooks/useChannels';
import {
    AudienceType,
    CommunicationChannel,
    TemplateVariables,
    type EmailTemplateContentDetails,
    type ProviderTemplateDetails,
    type SmsTemplateContentDetails,
    type Template,
} from '@lightdash/common';
import { useDisclosure } from '@mantine/hooks';
import { useCampaignContext } from '@providers/CampaignProvider';
import React, { useCallback, useMemo } from 'react';

interface TemplateContentPreviewProps {
    templateMetadata: Template;
}

const TemplateContentPreview: React.FC<TemplateContentPreviewProps> = ({
    templateMetadata,
}) => {
    const { campaignPayload } = useCampaignContext((context) => context.state);
    const { communicationDetails, templateDetails } = campaignPayload;
    const { setCampaignContentPayload, setGrapesPreviewInstance } =
        useCampaignContext((context) => context.actions);

    const {
        data: integrationConfig,
        isInitialLoading: isIntegrationConfigLoading,
    } = useGetIntegratedProviderConfig(
        communicationDetails.providerId,
        communicationDetails.id,
    );

    const [opened, { toggle, close }] = useDisclosure(false);

    const { data: providerMetadata, isInitialLoading } = useGetProviderMetada(
        communicationDetails?.providerId,
        communicationDetails?.channel ?? '',
    );

    const handleTemplateSelect = useCallback(
        (value: ProviderTemplateDetails) => {
            if (!value) return;
            setCampaignContentPayload({
                id: value.id,
            });
        },
        [setCampaignContentPayload],
    );

    const templateContentPreview = useMemo(() => {
        if (
            !templateMetadata ||
            !templateMetadata.contents ||
            !templateMetadata.contents.length ||
            !templateMetadata.contents[0].content
        )
            return null;

        switch (templateMetadata.channel) {
            case CommunicationChannel.EMAIL:
                const emailContent = templateMetadata.contents[0]
                    .content as EmailTemplateContentDetails;
                const templateDetail = {
                    id: templateMetadata.id,
                    name: templateMetadata.name,
                    html: emailContent.html,
                    unsubscribeBlock:
                        campaignPayload.audienceType === AudienceType.WAREHOUSE
                            ? emailContent.html.includes(
                                  TemplateVariables.UNSUBSCRIBE,
                              )
                            : true,
                };

                if (!providerMetadata || !integrationConfig) return null;

                return (
                    <HTMLPreview
                        templateDetail={templateDetail}
                        subject={
                            (
                                templateDetails?.content as EmailTemplateContentDetails
                            )?.subject ??
                            (
                                templateMetadata?.contents?.[0]
                                    ?.content as EmailTemplateContentDetails
                            )?.subject ??
                            ''
                        }
                        senderName={integrationConfig?.fromName ?? ''}
                        senderMail={integrationConfig?.fromEmail ?? ''}
                        handleModifyTemplate={toggle}
                        setGrapesPreviewInstance={setGrapesPreviewInstance}
                    />
                );
            case CommunicationChannel.SMS:
                const smsAppContent = templateMetadata.contents[0]
                    .content as SmsTemplateContentDetails;
                return (
                    <SMSPreview
                        content={smsAppContent.body}
                        isFlash={smsAppContent.flash || false}
                        allowModify={true}
                        onModify={toggle}
                        templateName={templateMetadata.name}
                    />
                );
            default:
                return null;
        }
    }, [
        templateMetadata,
        providerMetadata,
        integrationConfig,
        templateDetails?.content,
        toggle,
        setGrapesPreviewInstance,
        campaignPayload,
    ]);

    if (isIntegrationConfigLoading || isInitialLoading)
        return <SkeletonLoader height={480} width={300} />;

    return (
        <>
            {templateContentPreview}

            {providerMetadata && (
                <TemplateOptionsModal
                    opened={opened}
                    onClose={close}
                    providerMetadata={providerMetadata}
                    integrationId={communicationDetails.id}
                    handleTemplateSelect={handleTemplateSelect}
                    channel={templateMetadata.channel}
                    showCreateEditTemplate={true}
                    templateId={templateMetadata?.id ?? undefined}
                    isTemplateLibrary={false}
                    editMode={false}
                />
            )}
        </>
    );
};

export default React.memo(TemplateContentPreview);
