import { sortmentApi } from '@api/index';
import { type ApiResponse } from '@lightdash/common';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';

const useFileUpload = <T extends ApiResponse>(
    url = '/templates/upload-file',
) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();

    return useMutation({
        mutationFn: (file: FileList) => {
            const formData = new FormData();
            formData.append('file', file[0]);
            return sortmentApi<T>({
                url: `/projects/${projectUuid}${url}`,
                method: 'POST',
                body: formData,
                headers: null,
            });
        },
        mutationKey: [QueryKeys.UPLOAD_FILE, projectUuid],
    });
};

export default useFileUpload;
