import OnBoardingFooter from '@components/Onboarding/OnboardingFooter';
import { useLocale } from '@hooks/useLocale';
import { Box, Stack, Text } from '@mantine/core';
import { type FC } from 'react';
import { AlertCircle } from 'react-feather';
interface AuthorizationErrorProps {
    message?: string;
    showFooter?: boolean;
}

const AuthorizationError: FC<AuthorizationErrorProps> = ({
    message,
    showFooter = true,
}) => {
    const { t } = useLocale();
    return (
        <>
            <Box className="flex justify-center bg-gray-900 h-[100vh] w-[100wh] overflow-hidden">
                <Stack
                    className="!min-w-96 pt-24 overscroll-none "
                    align="center"
                    justify="space-between"
                >
                    <Box className=" pe-3 mx-3">
                        <Box className="flex justify-center pb-6">
                            <Text className="text-white">
                                {t(
                                    'organization_creation.member_limbo_heading',
                                )}
                            </Text>
                        </Box>
                        <Box className="flex h-10 gap-1 px-2 py-1 rounded bg-light-6">
                            <AlertCircle color="white" className="text-xs" />
                            <Text className="text-xs text-white">
                                {message
                                    ? message
                                    : t('authorization_access_error.message')}
                            </Text>
                        </Box>
                    </Box>
                    {showFooter && <OnBoardingFooter />}
                </Stack>
            </Box>
        </>
    );
};
export default AuthorizationError;
