import TextInput from '@components/common/Inputs/TextInput';
import Select from '@components/common/Select';
import {
    useCreateSubscriptionCategory,
    useCreateSubscriptionGroup,
    useGetAllSubscriptionCategories,
    useUpdateSubscriptionGroup,
} from '@hooks/subscriptionGroups/useSubscriptionGroups';
import { useLocale } from '@hooks/useLocale';
import { type CommunicationChannel } from '@lightdash/common';
import {
    SubscriptionGroupType,
    type SubscriptionGroup,
} from '@lightdash/common/src/types/subscriptionGroup';
import { Box, Button, Flex, Text, Textarea } from '@mantine/core';
import {
    CaretRight,
    CheckCircle,
    CircleDashed,
    Gavel,
} from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../mantineTheme';

enum NewCategory {
    NEW = 'new',
    NEW_CATEGORY = 'New Category',
}

interface SubscriptionGroupContentProps {
    activeTab: CommunicationChannel;
    groupData: SubscriptionGroup | undefined;
    onClose: () => void;
}

const SubscriptionGroupContent: React.FC<SubscriptionGroupContentProps> = ({
    activeTab,
    onClose,
    groupData,
}) => {
    const { mutateAsync: updateGroup } = useUpdateSubscriptionGroup();

    const { data: subscriptionCategories } = useGetAllSubscriptionCategories();
    const { mutateAsync: mutateAsyncGroup } = useCreateSubscriptionGroup();
    const {
        mutateAsync: mutateAsyncCategory,
        data: categoryData,
        isLoading: isCategoryDataLoading,
        reset: categoryReset,
    } = useCreateSubscriptionCategory();

    const { t } = useLocale();
    const queryClient = useQueryClient();

    const [groupName, setGroupName] = useState<string>('');
    const [groupDescription, setGroupDescription] = useState<string>('');
    const [selectedType, setSelectedType] = useState<SubscriptionGroupType>(
        SubscriptionGroupType.REQUIRED,
    );
    const [newCategory, setNewCategory] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [isNewCategorySelected, setIsNewCategorySelected] =
        useState<boolean>(false);
    const [isNameEmpty, setisNameEmpty] = useState<boolean>(false);

    const selectData = useMemo(() => {
        if (subscriptionCategories) {
            return subscriptionCategories
                .filter((item) => item.channel === activeTab)
                .map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
        }
        return [];
    }, [subscriptionCategories, activeTab]);

    const subscriptionGroupTypeDetails = useMemo(
        () => ({
            [SubscriptionGroupType.REQUIRED]: {
                label: t('subscription_group_content.required'),
                icon: (color: string) => (
                    <Gavel weight="duotone" color={color} />
                ),
            },
            [SubscriptionGroupType.OPT_IN]: {
                label: t('subscirption_group_content.opt_in'),
                icon: (color: string) => (
                    <CircleDashed weight="duotone" color={color} />
                ),
            },
            [SubscriptionGroupType.OPT_OUT]: {
                label: t('subscription_group_content.opt_out'),
                icon: (color: string) => (
                    <CheckCircle weight="duotone" color={color} />
                ),
            },
        }),
        [t],
    );

    const handleTypeClick = useCallback(
        (type: SubscriptionGroupType) => {
            setSelectedType(type);
        },
        [setSelectedType],
    );

    const handleSelectChange = useCallback(
        (value: string) => {
            if (value === 'new') {
                setIsNewCategorySelected(true);
                setSelectedCategory(value);
            } else {
                setIsNewCategorySelected(false);
                setSelectedCategory(value);
            }
        },
        [setIsNewCategorySelected, setSelectedCategory],
    );

    const handleNewCategoryChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setNewCategory(event.target.value);
        },
        [setNewCategory],
    );

    const handleSaveClick = useCallback(async () => {
        if (!groupName.trim().length) {
            setisNameEmpty(true);
            return;
        }
        if (isNewCategorySelected) {
            await mutateAsyncCategory({
                payload: {
                    name: newCategory,
                    channel: activeTab,
                    description: '',
                },
            });
            return;
        }
        if (groupData) {
            await updateGroup({
                groupId: groupData.id,
                payload: {
                    name: groupName,
                    description: groupDescription,
                    categoryId: selectedCategory,
                    type: selectedType,
                },
            });
            await queryClient.invalidateQueries([
                QueryKeys.GET_SUBSCRIPTION_GROUPS,
            ]);
            onClose();
            return;
        }

        await mutateAsyncGroup({
            payload: {
                name: groupName,
                description: groupDescription,
                channel: activeTab,
                categoryId: selectedCategory,
                type: selectedType,
            },
        });
        await queryClient.invalidateQueries([
            QueryKeys.GET_SUBSCRIPTION_GROUPS,
        ]);
        onClose();
    }, [
        mutateAsyncGroup,
        activeTab,
        onClose,
        queryClient,
        isNewCategorySelected,
        mutateAsyncCategory,
        selectedType,
        selectedCategory,
        newCategory,
        updateGroup,
        groupData,
        groupName,
        groupDescription,
    ]);

    useEffect(() => {
        if (groupData && categoryData) {
            void updateGroup({
                groupId: groupData.id,
                payload: {
                    name: groupName,
                    description: groupDescription,
                    categoryId: categoryData.id,
                    type: selectedType,
                },
            }).then(() => {
                void queryClient.invalidateQueries([
                    QueryKeys.GET_SUBSCRIPTION_GROUPS,
                ]);
                onClose();
            });

            return;
        }
        if (categoryData) {
            void mutateAsyncGroup({
                payload: {
                    name: groupName,
                    description: groupDescription,
                    channel: activeTab,
                    categoryId: categoryData.id,
                    type: selectedType,
                },
            }).then(() => {
                void queryClient.invalidateQueries([
                    QueryKeys.GET_SUBSCRIPTION_GROUPS,
                ]);

                categoryReset();
                onClose();
            });
        }
    }, [
        categoryReset,
        onClose,
        isCategoryDataLoading,
        groupData,
        queryClient,
        updateGroup,
        categoryData,
        mutateAsyncGroup,
        activeTab,
        groupDescription,
        groupName,
        selectedType,
    ]);

    useEffect(() => {
        if (groupData) {
            setGroupName(groupData.name || '');
            setGroupDescription(groupData.description || '');
            setSelectedType(groupData.type || SubscriptionGroupType.REQUIRED);
            if (groupData.categoryId) {
                const field = selectData.find(
                    (item) => item.value === groupData.categoryId,
                );
                if (field) {
                    setSelectedCategory(field.value);
                }
            }
        }
    }, [groupData, selectData]);

    return (
        <Box>
            <Box className="w-2/3 mb-3.5">
                <Text className="text-gray-800 text-sm mb-1.5">
                    {t('subscription_group_content.name_this_group')}
                </Text>
                <TextInput
                    error={isNameEmpty && t('subscription_group.invalid_name')}
                    required
                    placeholder="Example: Marketing communications"
                    value={groupName}
                    onChange={(e) => {
                        setisNameEmpty(false);
                        setGroupName(e.target.value);
                    }}
                />

                <Text className="text-sm font-medium text-gray-500 mt-1.5">
                    {t('subscription_group_content.name_definition')}
                </Text>
            </Box>
            <Box className="mb-3.5">
                <Flex align="center" className="space-x-1 mb-1.5">
                    <Text className="text-gray-800 text-sm font-medium">
                        {t('custom_metric.dimension_modal_description_label')}
                    </Text>
                    <Text className="text-gray-600 text-sm">
                        {t(
                            'custom_metric.dimension_modal_description_label_optional',
                        )}
                    </Text>
                </Flex>
                <Textarea
                    placeholder={t('subscription_group_content.description')}
                    withAsterisk
                    value={groupDescription}
                    onChange={(e) => setGroupDescription(e.target.value)}
                />
            </Box>
            <Select
                className="mb-3.5 !w-2/5"
                label={t('subscription_group_content.select_label')}
                placeholder={t('subscription_group_content.select_placeholder')}
                withinPortal={true}
                value={selectedCategory}
                data={[
                    ...selectData,
                    { value: NewCategory.NEW, label: NewCategory.NEW_CATEGORY },
                ]}
                onChange={(value) => {
                    if (value) handleSelectChange(value);
                }}
            />
            {isNewCategorySelected && (
                <TextInput
                    required
                    label={t('subscription_group_content.new_category_label')}
                    className="mt-2"
                    placeholder={t(
                        'subscription_group_content.new_category_placeholder',
                    )}
                    value={newCategory}
                    onChange={handleNewCategoryChange}
                />
            )}
            <Text className="text-gray-800 text-sm font-medium mb-1.5">
                {t('subscription_group_content.type')}
            </Text>
            <Flex className="space-x-2">
                {Object.entries(subscriptionGroupTypeDetails).map(
                    ([type, details]) => (
                        <Box
                            key={type}
                            onClick={() =>
                                handleTypeClick(type as SubscriptionGroupType)
                            }
                            className={`cursor-pointer p-2 border rounded-lg ${
                                selectedType === type
                                    ? 'bg-blu-800/6 text-blu-800 border-blu-800  '
                                    : ''
                            }`}
                        >
                            <Box className="flex items-center">
                                {details.icon(
                                    selectedType === type
                                        ? 'rgb(var(--color-blu-800))'
                                        : 'rgb(var(--color-gray-700))',
                                )}
                                <Text
                                    className={`text-sm font-semibold  ml-2 
                                    ${
                                        selectedType === type
                                            ? 'text-blu-800'
                                            : 'text-gray-700'
                                    }
                                `}
                                >
                                    {details.label}
                                </Text>
                            </Box>
                        </Box>
                    ),
                )}
            </Flex>
            <Flex className="pt-2 gap-2" justify={'flex-end'}>
                <Button variant={ButtonVariant.OUTLINED} onClick={onClose}>
                    {t('password_modal.secondary_btn_text')}
                </Button>
                <Button
                    variant={ButtonVariant.FILLED}
                    onClick={handleSaveClick}
                >
                    {t('subscription_group_content.save_group')}
                    <CaretRight
                        weight="duotone"
                        color="rgb(var(--color-white))"
                    />
                </Button>
            </Flex>
        </Box>
    );
};

export default SubscriptionGroupContent;
