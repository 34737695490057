import {
    Card,
    Container,
    Flex,
    getDefaultZIndex,
    type CardProps,
} from '@mantine/core';
import { PAGE_CONTENT_WIDTH, PAGE_HEADER_HEIGHT } from '@utils/constants';
import { type FC } from 'react';

type PageHeaderProps = {
    withFullWidthHeader: boolean;
} & Pick<CardProps, 'h'>;

const PageHeader: FC<React.PropsWithChildren<PageHeaderProps>> = ({
    h,
    withFullWidthHeader,
    children,
}) => (
    <Card
        component={Flex}
        justify="flex-end"
        align="center"
        pos="relative"
        h={h ?? PAGE_HEADER_HEIGHT}
        sx={{ zIndex: getDefaultZIndex('app') }}
        className="bg-white border-b rounded-none border-black/6"
    >
        <Container
            className={`flex flex-row items-center justify-between shrink-0 ${
                withFullWidthHeader ? '' : 'px-28'
            }`}
            style={{
                width: withFullWidthHeader ? '100%' : `${PAGE_CONTENT_WIDTH}px`,
                minWidth: withFullWidthHeader
                    ? '100%'
                    : `${PAGE_CONTENT_WIDTH}px`,
            }}
        >
            {children}
        </Container>
    </Card>
);

export default PageHeader;
