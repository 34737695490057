import {
    isAndNestedMetricQuery,
    isDimension,
    isFilterableDimension,
    isFilterGroup,
    RelationTableType,
    type AndFilterGroup,
    type CreateSavedChartVersion,
    type FilterableDimension,
    type FilterGroup,
    type FilterGroupItem,
    type FilterRule,
    type MetricQuery,
    type NestedMetricQueryGroup,
    type OrFilterGroup,
} from '@lightdash/common';
import { metricQueryDefaultState } from '@providers/AudienceProvider';
import type {
    FieldsWithSuggestions,
    FieldWithSuggestions,
} from './Filters/FiltersProvider';

export const getUnsavedChartVersion = (
    nestedMetricQuery: NestedMetricQueryGroup | undefined,
) => {
    if (!nestedMetricQuery) return [];
    let metricQueries: CreateSavedChartVersion[] = [];

    if (nestedMetricQuery) {
        const savedMetricQueries = isAndNestedMetricQuery(nestedMetricQuery)
            ? nestedMetricQuery.and
            : nestedMetricQuery.or;
        savedMetricQueries.forEach((metricQuery) => {
            metricQueries.push({
                ...metricQueryDefaultState,
                metricQuery: metricQuery as MetricQuery,
            });
        });
    }
    return metricQueries;
};

/**
 * this function is used to check if the filter is an event
 * @param filter it is the filter that is passed to the function
 * @param fieldsMap it is the fields map that is passed to the function
 * @returns it returns true if the filter is an event
 */
export const isEventField = (
    filter: FilterGroupItem,
    fieldsMap: FieldsWithSuggestions,
) => {
    if (isFilterGroup(filter)) return false;
    const fieldId = filter.target.fieldId;
    return fieldsMap[fieldId]?.tableType === RelationTableType.EVENT;
};

/**
 * this function is used to check if the filter is an event name
 * @param filter it is the filter that is passed to the function
 * @param eventsMap it is the events map that is passed to the function
 * @returns it returns true if the filter is an event name
 */
export const isEventNameProperty = (
    filter: FilterGroupItem,
    eventsMap: FieldWithSuggestions[],
) => {
    if (isFilterGroup(filter)) return false;
    const filterValue = filter?.values?.[0] ?? '';
    return eventsMap.some(
        (event) =>
            (event as FilterableDimension).fieldReference === filterValue,
    );
};

/**
 * this function used to place all the events at top if the first filter is an event and if the first filter is dimension it places all the dimensions at top
 * @param filterGroup it is the filter group that is passed to the function
 * @param eventFieldIds it is the event field ids that is passed to the function
 * @returns it returns the categorized filters we have the filter group in filter group so in eeach nested groups we tries to arrange the dimensions or events at their desired places.
 */
export const categorizeDimensionsAndEvents = (
    filterGroup: FilterGroup | undefined,
    fieldsMap: FieldsWithSuggestions,
    eventsMap: FieldWithSuggestions[],
): FilterGroup | undefined => {
    const categorizeFilters = (
        filters: FilterGroupItem[],
    ): FilterGroupItem[] => {
        const nonGroupFilters = filters.filter(
            (filter) => !isFilterGroup(filter),
        );
        const eventsName = nonGroupFilters
            .filter((filter) => isEventField(filter, fieldsMap))
            .filter((event) => isEventNameProperty(event, eventsMap));
        const eventDimensions = nonGroupFilters
            .filter((filter) => isEventField(filter, fieldsMap))
            .filter((event) => !isEventNameProperty(event, eventsMap));
        const dimensions = nonGroupFilters.filter(
            (filter) => !isEventField(filter, fieldsMap),
        );
        const groups = filters.filter(isFilterGroup);

        return [
            ...eventsName,
            ...eventDimensions,
            ...dimensions,
            ...groups
                .map((group) =>
                    categorizeDimensionsAndEvents(group, fieldsMap, eventsMap),
                )
                .filter((item): item is FilterGroup => item !== undefined),
        ];
    };

    if (!filterGroup) return undefined;

    if ('or' in filterGroup) {
        const orFilters = (filterGroup as OrFilterGroup).or;
        return {
            ...filterGroup,
            or: categorizeFilters(orFilters as FilterGroupItem[]),
        };
    } else if ('and' in filterGroup) {
        const andFilters = (filterGroup as AndFilterGroup).and;
        return {
            ...filterGroup,
            and: categorizeFilters(andFilters as FilterGroupItem[]),
        };
    }

    return filterGroup;
};

/**
 * this function is used to check if the filter is an event dimension
 * @param filter it is the filter that is passed to the function
 * @param fieldsMap it is the fields map that is passed to the function
 * @param eventsMap it is the events map that is passed to the function
 * @returns it returns true if the filter is an event dimension
 */
export const isEventDimension = (
    filter: FilterGroupItem,
    fieldsMap: FieldsWithSuggestions,
    eventsMap: FieldWithSuggestions[],
) => {
    if (isFilterGroup(filter)) return false;
    const filterValue = filter?.values?.[0] ?? '';
    return (
        fieldsMap[filter.target.fieldId]?.tableType ===
            RelationTableType.EVENT &&
        !eventsMap.find(
            (event) =>
                isDimension(event) && event.fieldReference === filterValue,
        )
    );
};

/**
 * this function is used to check if any filter is an event dimension
 * @param filters it is the filters that is passed to the function
 * @param eventsMap it is the events map that is passed to the function
 * @returns it returns true if the filter is an event dimension
 */
export const hasEventNameFilter = (
    filters: FilterGroupItem[],
    eventsMap: FieldWithSuggestions[],
) => {
    return filters.some((filter) => {
        const filterValue = (filter as FilterRule)?.values?.[0] ?? '';
        return eventsMap.find(
            (event) =>
                isDimension(event) &&
                isFilterableDimension(event) &&
                event.fieldReference === filterValue,
        );
    });
};

/**
 * this function is used to count the event name filters in the filterGroup
 * @param filters it is the filters that is passed to the function
 * @param eventsMap it is the events map that is passed to the function
 * @returns it returns the count of the event name filters in the group
 */
export const getEventFieldWithEventNameCount = (
    filters: FilterGroupItem[],
    eventsMap: FieldWithSuggestions[],
    fieldsMap: FieldsWithSuggestions,
) => {
    return filters.filter(
        (filter) =>
            isEventField(filter, fieldsMap) &&
            isEventNameProperty(filter, eventsMap),
    ).length;
};
