import TextInput from '@components/common/Inputs/TextInput';
import { useLocale } from '@hooks/useLocale';
import { Stack, Text } from '@mantine/core';
import { useEventContext } from '@providers/EventsProvider';
import { useEffect } from 'react';

const SingleEventReview: React.FC = ({}) => {
    const { t } = useLocale();
    const { state, actions } = useEventContext((context) => context);
    const { eventPayload, isViewMode, isEditMode } = state;
    const { setEventLabel, setEventName, mutateEvent, setCurrentStepCallback } =
        actions;
    useEffect(() => {
        setCurrentStepCallback({
            callback: mutateEvent,
            skipExecutionAfterCallback: false,
        });
    }, [mutateEvent, setCurrentStepCallback]);
    return (
        <Stack>
            <TextInput
                id="single-event-name"
                label={t('event_create.review_single_event_name_title')}
                placeholder={t(
                    'event_create.review_single_event_name_placeholder',
                )}
                type="text"
                required
                withAsterisk={false}
                className="w-[15rem]"
                value={eventPayload.eventName}
                onChange={(event) => setEventName(event.target.value.trim())}
                disabled={isViewMode || isEditMode}
            />
            <Text className="text-gray-500">
                {t('event_create.review_single_event_name_description')}
            </Text>
            <TextInput
                id="single-event-label"
                label={t('event_create.review_single_event_label_title')}
                placeholder={t(
                    'event_create.review_single_event_label_placeholder',
                )}
                type="text"
                className="w-[15rem]"
                value={eventPayload.label ?? ''}
                onChange={(event) => setEventLabel(event.target.value.trim())}
                disabled={isViewMode}
            />
            <Text className="text-gray-500">
                {t('event_create.review_single_event_label_description')}
            </Text>
        </Stack>
    );
};
export default SingleEventReview;
