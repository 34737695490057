import { type CampaignScheduleType } from './CampaignScheduleType';
import { type CampaignStatus } from './CampaignStatus';
import { type CommunicationChannel, type Name } from './communications';
import { type FieldType, type MaterializationFieldType } from './field';
import { type TemplateContentDetails } from './templates';

export enum AudienceType {
    WAREHOUSE = 'WAREHOUSE',
    CSV = 'CSV',
}

export type CommunicationDetails = {
    id: string;
    providerId: string;
    eventId: string | undefined;
    runDetails?: {
        runAt: number;
        uploadIds: string[];
    }[];
    sendTo?: {
        [key: string]: string;
        type: FieldType;
    };
    channel?: CommunicationChannel | null;
    subscriptionGroupId?: string;
};

export type TemplateDetails = {
    id: string;
    version?: number | null;
    mappings: {
        [key: string]: {
            value: string;
            defaultValue: string;
            type: MaterializationFieldType;
            enableTracking?: boolean; // TODO: Make this mandatory after UI changes are completed. For now added this so that UI can be done afterwards.
        };
    };
    content?: TemplateContentDetails;
};

export type Schedule = {
    type: CampaignScheduleType;
    executionTime?: Date | null;
    jobId?: string;
};

export type BaseCampaignType = {
    id?: string;
    organizationId?: string;
    projectId?: string;
    name: string;
    description: string;
    status?: CampaignStatus;
    communicationDetails: CommunicationDetails;
    templateDetails: TemplateDetails;
    schedule: Schedule;
    campaignTrackingParameters: {
        [key: string]: string;
    };
    createdBy: string | undefined;
    updatedBy: string | undefined;
    createdAt: Date | undefined;
    updatedAt: Date | undefined;
    tags?: string[] | undefined;
};

export type WarehouseAudienceCampaign = BaseCampaignType & {
    audienceId: string;
    audienceType: 'WAREHOUSE';
};
export type CSVUploadDetailsType = {
    uploadId: string;
    fileName: string;
    audienceCount: number;
};
export type CSVAudienceCampaign = BaseCampaignType & {
    csvUploadDetails: CSVUploadDetailsType;
    audienceType: 'CSV';
};

export type Campaign = WarehouseAudienceCampaign | CSVAudienceCampaign;

export type BaseReducedCampaignType =
    | (Pick<
          BaseCampaignType,
          'id' | 'name' | 'description' | 'status' | 'createdAt' | 'updatedAt'
      > & {
          audienceCount: number | undefined;
          scheduledAt: Date | null;
          channel: string | null;
          createdBy: Name;
          updatedBy: Name;
      })
    | null;

export type WarehouseAudienceReducedCampaignType = BaseReducedCampaignType & {
    audienceId: string | null;
    audienceName: string | null;
    audienceType: 'WAREHOUSE';
};

export type CSVAudienceReducedCampaignType = BaseReducedCampaignType & {
    uploadId: string;
    fileName: string;
    audienceType: 'CSV';
};

export type ReducedCampaign =
    | WarehouseAudienceReducedCampaignType
    | CSVAudienceReducedCampaignType;

export type CampaignList = {
    data: ReducedCampaign[] | null;
    paginate: {
        total?: number;
        lastPage?: number;
        currentPage: number;
        perPage: number;
        from: number;
        to: number;
    };
};

export type ApiCampaign = {
    status: 'ok';
    results: Campaign;
};

export type ApiCampaignList = {
    status: 'ok';
    results: CampaignList;
};

export function convertCampaignToCampaignList(
    campaign: Campaign,
): ReducedCampaign {
    const baseReducedCampaign: BaseReducedCampaignType = {
        id: campaign.id,
        name: campaign.name,
        description: campaign.description,
        status: campaign.status,
        scheduledAt: campaign?.schedule?.executionTime ?? null,
        createdBy: {
            firstName: '',
            lastName: '',
        },
        updatedBy: {
            firstName: '',
            lastName: '',
        },
        channel: campaign?.communicationDetails?.channel ?? null,
        createdAt: campaign.createdAt,
        updatedAt: campaign.updatedAt,
        audienceCount: undefined,
    };

    switch (campaign.audienceType) {
        case AudienceType.CSV: {
            return {
                ...baseReducedCampaign,
                uploadId: campaign.csvUploadDetails.uploadId,
                fileName: campaign.csvUploadDetails.fileName,
                audienceCount: campaign.csvUploadDetails.audienceCount,
                audienceType: AudienceType.CSV,
            };
        }
        case AudienceType.WAREHOUSE:
        default: {
            return {
                ...baseReducedCampaign,
                audienceId: (campaign as WarehouseAudienceCampaign).audienceId,
                audienceName: null,
                audienceType: AudienceType.WAREHOUSE,
            };
        }
    }
}

export type CampaignCreateRequestBaseType = Required<
    Pick<
        BaseCampaignType,
        | 'name'
        | 'description'
        | 'communicationDetails'
        | 'templateDetails'
        | 'schedule'
        | 'campaignTrackingParameters'
        | 'tags'
    >
>;
export type CampaignCreateRequestWarehouseAudience =
    CampaignCreateRequestBaseType & {
        audienceId: string;
        audienceType: AudienceType.WAREHOUSE;
    };

export type CampaignCreateRequestCSVAudience = CampaignCreateRequestBaseType & {
    csvUploadDetails: {
        uploadId: string;
        fileName: string;
        audienceCount: number;
    };
    audienceType: AudienceType.CSV;
};
export type CampaignCreateRequest =
    | CampaignCreateRequestWarehouseAudience
    | CampaignCreateRequestCSVAudience;

export type CampaignUpdateRequestWarehouseAudience =
    Partial<CampaignCreateRequestWarehouseAudience> &
        Pick<CampaignCreateRequestWarehouseAudience, 'audienceType'>;
export type CampaignUpdateRequestCSVAudience =
    Partial<CampaignCreateRequestCSVAudience> &
        Pick<CampaignCreateRequestCSVAudience, 'audienceType'>;

export type CampaignUpdateRequest = (
    | CampaignUpdateRequestCSVAudience
    | CampaignUpdateRequestWarehouseAudience
) &
    Partial<Pick<Campaign, 'status' | 'updatedBy'>>;

export type TriggerCampaignRequest = {
    scheduleAt: number;
};

export type CampaignTagsUpdateRequest = {
    tags: string[];
};

export type CampaignStatusResponse = {
    id: string | undefined;
    status: CampaignStatus | undefined;
};

export type WarehouseCampaignWithAudienceType = BaseCampaignType & {
    audienceId: string;
    audienceName: string;
    audienceCount: number;
    audienceType: AudienceType.WAREHOUSE;
};
export type CSVCampaignWithAudienceType = BaseCampaignType & {
    csvUploadDetails: {
        uploadId: string;
        fileName: string;
        audienceCount: number;
    };
    audienceType: AudienceType.CSV;
};

export type CampaignWithAudienceDetails =
    | WarehouseCampaignWithAudienceType
    | CSVCampaignWithAudienceType;

export type ExternalCampaignTriggerRequest = {
    templateDetails: Pick<TemplateDetails, 'id' | 'content' | 'mappings'>;
    communicationDetails: Partial<CommunicationDetails>;
};

export type UserDetails = {
    userId: string;
    email?: string;
    phone?: string;
};
