import { Group, Text } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import React from 'react';

import { type Option } from '.';
import LabelIcon from './LabelIcon';

const Label: React.FC<{ data: Option | undefined; className?: string }> = ({
    data,
    className,
}) => {
    if (!data) {
        return <Text className="text-gray-500">Select</Text>;
    }
    return (
        <Group spacing="sm" noWrap className={`w-[85%] ${className}`}>
            {data?.leftLabel && (
                <Group spacing="xs" noWrap className="max-w-[50%]">
                    {data?.leftLabelIcon && (
                        <LabelIcon
                            className="flex-grow flex-shrink-0"
                            name={data?.leftLabelIcon}
                        />
                    )}
                    <Text className="text-gray-600 font-medium max-w-[11.5rem] text-ellipsis overflow-hidden whitespace-nowrap">
                        {data.leftLabel}
                    </Text>
                </Group>
            )}
            {data?.leftLabel && data?.rightLabel && (
                <CaretRight className="flex-shrink-0" weight="duotone" />
            )}
            {data?.rightLabel && (
                <Group spacing="xs" noWrap className="max-w-[40%]">
                    {data.rightLabelIcon && (
                        <LabelIcon
                            className="flex-grow flex-shrink-0"
                            name={data.rightLabelIcon}
                        />
                    )}
                    <Text className="text-gray-800 font-medium max-w-[10rem] text-ellipsis overflow-hidden whitespace-nowrap">
                        {data.rightLabel}
                    </Text>
                </Group>
            )}
        </Group>
    );
};

export default Label;
