import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import { useOrganization } from '@hooks/organization/useOrganization';
import { useOrganizationUpdateMutation } from '@hooks/organization/useOrganizationUpdateMutation';
import { useIsEqual } from '@hooks/useIsEqual';
import { useLocale } from '@hooks/useLocale';
import { type UpdateOrganization } from '@lightdash/common';
import { Stack, TextInput } from '@mantine/core';
import { useApp } from '@providers/AppProvider';
import React, { useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import UnsavedChangesModal from '../../common/modal/UnsavedChangesModal';

const WorkspaceSettingsForm: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { isLoading: isOrganizationLoading, data: orgData } =
        useOrganization();
    const { user } = useApp();
    const {
        isLoading: isOrganizationUpdateLoading,
        mutate: updateOrganization,
    } = useOrganizationUpdateMutation();
    const isLoading = isOrganizationUpdateLoading || isOrganizationLoading;
    const ability = useAbilityContext();
    const canUpdateOrganization = ability.can(
        'update',
        subject('Organization', {
            organizationUuid: user.data?.organizationUuid,
        }),
    );
    const defaultValues: UpdateOrganization = useMemo(() => {
        return {
            name: orgData?.name ?? '',
        };
    }, [orgData]);

    const form = useForm<UpdateOrganization>({
        defaultValues,
    });

    const { control, handleSubmit, register, reset, setValue } = form;

    useEffect(() => {
        if (isLoading) return;
        if (!orgData) return;
        setValue('name', orgData.name);
    }, [orgData, setValue, isLoading]);

    const watchFields = useWatch<UpdateOrganization>({ control });
    const hasFormChanged = useIsEqual<UpdateOrganization>(
        defaultValues,
        watchFields,
    );

    const submitForm = (formData: UpdateOrganization) => {
        const { name } = formData;
        updateOrganization({ name: name });
    };

    const onSubmit = () => {
        void handleSubmit(submitForm)();
    };

    return (
        <>
            <form>
                <Stack>
                    <TextInput
                        id="organization-name-input"
                        placeholder={t(
                            'organization_settings_form.name_placeholder',
                        )}
                        label={t('organization_settings_form.name_label')}
                        type="text"
                        required
                        disabled={isLoading || !canUpdateOrganization}
                        data-cy="organization-name-input"
                        {...register('name')}
                        withAsterisk={false}
                        className="w-72"
                    />
                </Stack>
            </form>

            <UnsavedChangesModal
                opened={hasFormChanged}
                primaryActionButtonClick={() => onSubmit()}
                secondaryActionButtonClick={() => reset()}
                disableButtons={isLoading}
            />
        </>
    );
};

export default WorkspaceSettingsForm;
