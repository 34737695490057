import {
    Checkbox as MantineCheckbox,
    type CheckboxProps as MantineCheckboxProps,
} from '@mantine/core';
import React from 'react';
import { FontSizes, FontWeights } from '../../../mantineTheme';

const Checkbox = React.forwardRef<HTMLInputElement, MantineCheckboxProps>(
    ({ ...rest }, ref) => {
        return (
            <MantineCheckbox
                color={'rgb(var(--color-blu-800))'}
                size={14}
                radius="sm"
                styles={{
                    label: {
                        color: 'rgb(var(--color-gray-800))',
                        fontSize: FontSizes.sm,
                        fontWeight: FontWeights.medium,
                        paddingLeft: '0.375rem',
                    },
                    body: {
                        alignItems: 'center',
                    },
                }}
                {...rest}
                ref={ref}
            />
        );
    },
);

export default React.memo(Checkbox);
