import {
    AudienceType,
    CampaignScheduleType,
    type Campaign,
    type CampaignCreateRequest,
    type CampaignCreateRequestCSVAudience,
    type CampaignCreateRequestWarehouseAudience,
    type WarehouseAudienceCampaign,
} from '@lightdash/common';
import { type CampaignReducerState } from '@providers/CampaignProvider';
import { useMemo } from 'react';
import { CampaignBuilderStep } from './Builder/types';

export const useCampaignInitialData = (
    campaignData: Campaign | undefined,
): CampaignReducerState => {
    return useMemo(() => {
        let campaignPayload: CampaignCreateRequest = {
            name: campaignData?.name || 'Untitled Campaign',
            description: campaignData?.description || '',
            communicationDetails: campaignData?.communicationDetails || {
                id: '',
                providerId: '',
                eventId: '',
            },
            templateDetails: campaignData?.templateDetails || {
                id: '',
                mappings: {},
            },
            schedule: campaignData?.schedule || {
                type: CampaignScheduleType.MANUAL,
                executionTime: null,
            },
            campaignTrackingParameters:
                campaignData?.campaignTrackingParameters ?? {},
            audienceType: AudienceType.WAREHOUSE,
            audienceId:
                (campaignData as WarehouseAudienceCampaign)?.audienceId ??
                undefined,
            tags: campaignData?.tags ?? [],
        };
        if (campaignData?.audienceType === AudienceType.WAREHOUSE) {
            campaignPayload = {
                ...campaignPayload,
                audienceType: AudienceType.WAREHOUSE,
                audienceId: campaignData?.audienceId || '',
            } as CampaignCreateRequestWarehouseAudience;
        } else if (campaignData?.audienceType === AudienceType.CSV) {
            campaignPayload = {
                ...campaignPayload,
                csvUploadDetails: campaignData.csvUploadDetails ?? {},
                audienceType: AudienceType.CSV,
            } as CampaignCreateRequestCSVAudience;
        }
        return {
            campaignPayload,
            currentStep: CampaignBuilderStep.AUDIENCE,
            currentStepCallback: null,
            previousStepCallback: null,
            showFooterButtons: {
                next: false,
                back: false,
            },
            editor: undefined,
        };
    }, [campaignData]);
};
