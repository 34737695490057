import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import JourneyBuilderCanvas from '@components/Journeys/Builder';
import JourneyBuilderHeader from '@components/Journeys/Builder/JourneyBuilderHeader';
import { JourneyBuilderMode } from '@components/Journeys/Builder/types';
import useJourneyInitialData from '@components/Journeys/Builder/useJourneyBuilderInitialData';
import Page from '@components/Page/Page';
import {
    useGetJourneyBlocks,
    useGetJourneyById,
    useGetJourneyEvents,
} from '@hooks/useJourney';
import { useLocale } from '@hooks/useLocale';
import useSearchParams from '@hooks/useSearchParams';
import {
    JourneyBuilderProvider,
    type JourneyReducerState,
} from '@providers/JourneyBuilderProvider';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const JourneyBuilder: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { journeyUuid, projectUuid, mode } = useParams<{
        journeyUuid: string;
        projectUuid: string;
        mode?: string;
    }>();

    const { data: blocksList, isLoading } = useGetJourneyBlocks();
    const { data: eventsList, isLoading: eventsLoading } =
        useGetJourneyEvents();

    const location = useLocation();
    const isNewMode = location.pathname.includes(JourneyBuilderMode.CREATE);
    const isEditMode = isNewMode || mode === JourneyBuilderMode.EDIT;
    const journeyTemplateId = useSearchParams('templateId');

    const { data: journeyDataById, isInitialLoading } = useGetJourneyById(
        journeyUuid ?? journeyTemplateId,
    );

    const initialState: JourneyReducerState = useJourneyInitialData({
        mode: isNewMode ? JourneyBuilderMode.CREATE : JourneyBuilderMode.EDIT,
        blocksList: blocksList ?? [],
        projectUuid: projectUuid,
        journeyDataById,
    });

    if (isLoading || eventsLoading || isInitialLoading) {
        return <SuboptimalState loading />;
    }

    return (
        <JourneyBuilderProvider
            initialState={initialState}
            isEditable={isEditMode}
            uuid={journeyUuid}
            journeyEvents={eventsList}
            journeyStatus={initialState.journeyStatus}
        >
            <Page
                withFullHeight={false}
                backgroundColor="white"
                withNavbar={false}
                header={<JourneyBuilderHeader />}
                withFullWidthHeader
                title={
                    isNewMode
                        ? t('journey.create_title')
                        : journeyDataById && journeyDataById.name
                }
            >
                <JourneyBuilderCanvas />
            </Page>
        </JourneyBuilderProvider>
    );
};

export default JourneyBuilder;
