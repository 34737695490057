import { useLocale } from '@hooks/useLocale';
import { SubscriptionGroupType } from '@lightdash/common/src/types/subscriptionGroup';
import { Box, Checkbox, Text } from '@mantine/core';
import React from 'react';

interface NodeData {
    id: string;
    name: string;
    categoryId: string | null;
    description: string;
    type: string | undefined;
    weight: number | undefined;
    children: NodeData[] | undefined;
    userPreference: boolean | undefined;
}

interface NodeProps {
    nodeData: NodeData;
    onChecked: (id: string) => void;
}

const Node: React.FC<NodeProps> = ({ onChecked, nodeData }) => {
    const { t } = useLocale();
    return (
        <Box>
            <Box className="flex mb-2.5">
                <Checkbox
                    disabled={nodeData.type === SubscriptionGroupType.REQUIRED}
                    color="dark"
                    size={'xs'}
                    className="mt-[3px]"
                    checked={nodeData.userPreference}
                    onChange={() => onChecked(nodeData.id)}
                />
                <Text className="text-gray-800 text-sm font-medium ml-1.5">
                    {nodeData.name}
                    {nodeData.type === SubscriptionGroupType.REQUIRED &&
                        t('user_subscription_preference.required')}
                </Text>
            </Box>
            {nodeData.children &&
                nodeData.children.map((child) => (
                    <Box key={child.id} className="flex ml-5">
                        <Checkbox
                            disabled={
                                child.type === SubscriptionGroupType.REQUIRED
                            }
                            color="dark"
                            size={'xs'}
                            className="mt-[3px]"
                            checked={child.userPreference}
                            onChange={() => onChecked(child.id)}
                        />
                        <Box className="ml-1.5 mb-2.5">
                            <Text className="text-gray-800 text-sm font-medium mb-1">
                                {child.name}
                                {child.type ===
                                    SubscriptionGroupType.REQUIRED &&
                                    t('user_subscription_preference.required')}
                            </Text>
                            <Text className="text-gray-600 text-xs font-normal">
                                {child.description}
                            </Text>
                        </Box>
                    </Box>
                ))}
        </Box>
    );
};

export default Node;
