import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import { useLocale } from '@hooks/useLocale';
import { ProjectSettings } from '@lightdash/common';
import {
    Button,
    Container,
    Flex,
    Loader as LoaderIcon,
    Title,
} from '@mantine/core';
import { Broadcast, PlusCircle } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { useChannelContext } from '@providers/ChannelProvider';
import { useParams } from 'react-router-dom';
import { ButtonVariant } from '../../mantineTheme';
import ChannelTabs from './ChannelTabs';

const ChannelList: React.FC<{
    showSetupLabels?: boolean;
}> = ({ showSetupLabels = true }) => {
    const { t } = useLocale();
    const { user } = useApp();
    const { state, actions } = useChannelContext((context) => context);
    const { title, allowChannelSetup, allChannels, showSetupModal } = state;
    const { handleShowSetUpModal } = actions;

    const { projectUuid: projectId } = useParams<{ projectUuid: string }>();
    const ability = useAbilityContext();
    const canSetupChannel = ability.can(
        'update',
        subject(ProjectSettings.channels, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid: projectId,
        }),
    );
    const handleAddChannelClick = () => {
        if (!allChannels) return;

        handleShowSetUpModal(!showSetupModal);
    };
    return (
        <Container className="mx-0 !p-0">
            <Flex
                direction={'row'}
                justify={'space-between'}
                align="center"
                className="mb-4"
            >
                {showSetupLabels && (
                    <Flex align="center" gap="xs">
                        <Broadcast size={18} />
                        <Title
                            className="text-lg font-medium text-gray-800 "
                            order={3}
                        >
                            {title || t('channel_settings.header')}
                        </Title>
                    </Flex>
                )}

                {canSetupChannel && allowChannelSetup && (
                    <Button
                        variant={ButtonVariant.FILLED}
                        leftIcon={
                            !allChannels ? (
                                <LoaderIcon color="white" size={16} />
                            ) : (
                                <PlusCircle color="white" />
                            )
                        }
                        onClick={handleAddChannelClick}
                    >
                        {t('channel_settings.set_up_header')}
                    </Button>
                )}
            </Flex>
            {allChannels && <ChannelTabs />}
        </Container>
    );
};

export default ChannelList;
