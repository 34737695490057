import RouterNavLink from '@components/Router/RouterNavLink';
import { useLocale } from '@hooks/useLocale';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

interface NavBarMenuItem {
    label: string;
    href: string;
}

const MenuItems: React.FC<{}> = ({}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    const navBarMenuItems: NavBarMenuItem[] = useMemo(
        () => [
            {
                label: t('nav_bar_tabs.audiences'),
                href: `/projects/${projectUuid}/audiences`,
            },
            {
                label: t('nav_bar_tabs.profiles'),
                href: `/projects/${projectUuid}/profiles`,
            },
            {
                label: t('nav_bar_tabs.campaigns'),
                href: `/projects/${projectUuid}/campaigns`,
            },
            {
                label: t('nav_bar_tabs.templates'),
                href: `/projects/${projectUuid}/templates`,
            },
            {
                label: t('nav_bar_tabs.analytics'),
                href: `/projects/${projectUuid}/spaces`,
            },
            {
                label: 'Journeys',
                href: `/projects/${projectUuid}/journeys`,
            },
        ],
        [projectUuid, t],
    );

    return (
        <div className="flex flex-row gap-3.5 text-xs">
            {navBarMenuItems.map((item) => (
                <RouterNavLink
                    key={item.label}
                    to={item.href}
                    label={item.label}
                />
            ))}
        </div>
    );
};

export default MenuItems;
