import { useLocale } from '@hooks/useLocale';
import {
    CommunicationChannel,
    PeriodType,
    type CappingRule,
    type DeliveryControl,
} from '@lightdash/common';
import { Box, Button, Card, Text } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import { set } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    useCreateDeliveryControl,
    useUpdateDeliveryControl,
} from '../../../../hooks/useDeliveryControlsApi';
import { ButtonVariant } from '../../../../mantineTheme';
import CardHeader from '../CardHeader';
import FrequencyCappingRule from './FrequencyCappingRule';

type FrequencyCappingProps = {
    data: DeliveryControl | undefined;
    isEditAllowed: boolean;
};

const FrequencyCapping: React.FC<FrequencyCappingProps> = ({
    data,
    isEditAllowed,
}) => {
    const [frequencyCappingInputMode, setFrequencyCappingInputMode] =
        useState(false);
    const [frequencyRules, setFrequencyRules] = useState<CappingRule[]>([]);
    const { t } = useLocale();
    const { mutate: createDeliveryControl, isLoading: createLoading } =
        useCreateDeliveryControl();
    const { mutate: updateDeliveryControl, isLoading: updateLoading } =
        useUpdateDeliveryControl();

    const setInitialState = useCallback(() => {
        if (data?.cappingRules) {
            setFrequencyRules([...data?.cappingRules]);
        }
    }, [data]);

    useEffect(() => {
        setInitialState();
    }, [data, setInitialState]);

    useEffect(() => {
        if (createLoading || updateLoading) {
            setFrequencyCappingInputMode(false);
        }
    }, [createLoading, updateLoading]);

    const handleAddRule = () => {
        setFrequencyCappingInputMode(true);
        setFrequencyRules([
            ...frequencyRules,
            {
                name: '',
                description: '',
                priority: 1,
                limit: 1,
                channel: CommunicationChannel.ANY,
                id: uuidv4(),
                period: {
                    type: PeriodType.HOUR,
                    value: 1,
                },
            },
        ]);
    };

    const handleInputChange = (
        value: string | number | null,
        type: string,
        index: number,
    ) => {
        const newRules = [...frequencyRules];
        const newRule = { ...frequencyRules[index] };
        newRules.splice(index, 1, {
            ...set(newRule, type, value),
        });
        setFrequencyRules([...newRules]);
    };

    const handleDelete = (index: number) => {
        const newRules = [...frequencyRules];
        newRules.splice(index, 1);
        setFrequencyRules([...newRules]);
    };

    const isSaveButtonDisabled = useMemo(
        () => frequencyRules.some((rule) => !rule.limit || !rule.period.value),
        [frequencyRules],
    );

    const handleSave = useCallback(() => {
        if (isSaveButtonDisabled) return;

        const payload = {
            cappingRules: frequencyRules,
        };
        if (data) {
            updateDeliveryControl(payload);
        } else {
            createDeliveryControl({
                ...payload,
                quietHours: { enabled: false, start: '', end: '' },
            });
        }
    }, [
        isSaveButtonDisabled,
        frequencyRules,
        updateDeliveryControl,
        createDeliveryControl,
        data,
    ]);

    const handleCancel = () => {
        setFrequencyCappingInputMode(false);
        setInitialState();
    };

    const outerBorderColor = useMemo(
        () =>
            frequencyCappingInputMode ? 'border-blu-800/40' : 'border-shade-4',
        [frequencyCappingInputMode],
    );

    const innerBorderColor = useMemo(
        () => (frequencyCappingInputMode ? 'border-blu-800' : 'border-gray-50'),
        [frequencyCappingInputMode],
    );

    return (
        <Box className={`mt-7 p-0.5 border ${outerBorderColor} rounded-2xl`}>
            <Card
                className={`border ${innerBorderColor} rounded-1xl relative`}
                padding={3}
            >
                <CardHeader
                    title={t(
                        'workspace_settings.delivery_controls.frequency_capping_title',
                    )}
                    subtitle={t(
                        'workspace_settings.delivery_controls.frequency_capping_subtitle',
                    )}
                    handleSecondaryButtonClick={handleCancel}
                    handlePrimaryButtonClick={handleSave}
                    isActive={frequencyCappingInputMode}
                    isUpdating={createLoading || updateLoading}
                    isEditAllowed={frequencyRules?.length > 0 && isEditAllowed}
                    handleEditClick={() => setFrequencyCappingInputMode(true)}
                    primaryButtonLabel={undefined}
                    secondaryButtonLabel={undefined}
                />
                <Card.Section py="sm" px="sm">
                    {frequencyRules.map((rule, index) => (
                        <FrequencyCappingRule
                            readOnly={!frequencyCappingInputMode}
                            key={rule.id}
                            index={index}
                            onChange={handleInputChange}
                            value={rule}
                            onDelete={handleDelete}
                            inputMode={frequencyCappingInputMode}
                            isLastItem={index === frequencyRules.length - 1}
                        />
                    ))}
                    {isEditAllowed &&
                        ((frequencyCappingInputMode &&
                            frequencyRules.length < 10) ||
                            frequencyRules.length === 0) && (
                            <Button
                                onClick={handleAddRule}
                                variant={ButtonVariant.OUTLINED_ACCENTED}
                                leftIcon={
                                    <PlusCircle color="rgb(var(--color-blu-800))" />
                                }
                            >
                                {t(
                                    'workspace_settings.delivery_controls.frequency_capping_button',
                                )}
                            </Button>
                        )}
                    {!isEditAllowed && frequencyRules.length === 0 && (
                        <Text className="text-gray-800">
                            {t(
                                'workspace_settings.delivery_controls.frequency_capping_not_set',
                            )}
                        </Text>
                    )}
                </Card.Section>
            </Card>
        </Box>
    );
};

export default FrequencyCapping;
