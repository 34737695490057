import { sortmentApi } from '@api/index';
import { type ApiError, type AudienceList } from '@lightdash/common';
import { useRelationContext } from '@providers/RelationProvider';
import { useQuery } from '@tanstack/react-query';
import { LIST_REFETCH_TIME } from '@utils/constants';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
const getAudiences = async (
    perPage: number,
    currentPage: number,
    projectId: string,
    relationId: string,
    query: string,
) =>
    sortmentApi<AudienceList>({
        url: `/projects/${projectId}/relations/${relationId}/audiences?perPage=${perPage}&currentPage=${currentPage}&${query}`,
        method: 'GET',
        body: undefined,
    });

interface UseAudiencesProps {
    perPage: number;
    currentPage: number;
    query?: string;
    name?: string;
    polling: boolean;
}

export const useAudiences = ({
    perPage,
    currentPage,
    query,
    polling = true,
}: UseAudiencesProps) => {
    const params = useParams<{ projectUuid?: string }>();
    const { activeRelationUuid: relationId } = useRelationContext();
    const audiences = useQuery<AudienceList, ApiError>({
        queryKey: [QueryKeys.GET_AUDIENCE, query, perPage, currentPage, query],
        queryFn: () =>
            getAudiences(
                perPage,
                currentPage,
                params?.projectUuid || '',
                relationId,
                query || '',
            ),
        enabled: !!relationId,
        keepPreviousData: true,
        refetchInterval: polling ? LIST_REFETCH_TIME : false,
    });
    return audiences;
};
