import Modal from '@components/common/modal/Modal';
import { useLocale } from '@hooks/useLocale';
import { Button, Group } from '@mantine/core';
import { useTemplateBuilderContext } from '@providers/TemplateBuilderProvider';
import React from 'react';
import { ButtonVariant } from '../../../../mantineTheme';

interface ConfirmTemplateSaveModalProps {
    opened: boolean;
    onClose: () => void;
    handleSave: () => void;
    handleSaveAsNewTemplate: () => void;
    isLoading: boolean;
}

const ConfirmTemplateSaveModal: React.FC<ConfirmTemplateSaveModalProps> = ({
    opened,
    onClose,
    handleSave,
    handleSaveAsNewTemplate,
    isLoading,
}) => {
    const { templatePayload } = useTemplateBuilderContext(
        (context) => context.state,
    );

    const { t } = useLocale();

    return (
        <>
            <Modal
                opened={opened}
                onClose={onClose}
                title={`Save changes to '${templatePayload.name}'`}
                withCloseButton={true}
                withOverlay={true}
                footerLeftSection={
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        onClick={onClose}
                        disabled={isLoading}
                    >
                        {t('common.dont_save')}
                    </Button>
                }
                footerRightSection={
                    <Group className="flex gap-1.5">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={handleSave}
                            disabled={isLoading}
                        >
                            {t('common.save')}
                        </Button>
                        <Button
                            variant={ButtonVariant.OUTLINED_ACCENTED}
                            onClick={handleSaveAsNewTemplate}
                            disabled={isLoading}
                        >
                            {t('template_manager.save_as_new_template')}
                        </Button>
                    </Group>
                }
            >
                <></>
            </Modal>
        </>
    );
};

export default React.memo(ConfirmTemplateSaveModal);
