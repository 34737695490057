import { Group, Text } from '@mantine/core';
import { WarningCircle } from '@phosphor-icons/react';
import React from 'react';

const InputErrorText: React.FC<{
    value: React.ReactNode;
    size?: 'xs' | 'sm';
    className?: string;
}> = ({ value, size, className }) => {
    const fontSize = size === 'xs' ? 'text-xs' : 'text-sm';
    return (
        <Group className={`gap-1 ${className}`}>
            <WarningCircle color="rgb(var(--color-halt-800))" />
            <Text className={`${fontSize} font-medium text-halt-800`}>
                {value}
            </Text>
        </Group>
    );
};

export default InputErrorText;
