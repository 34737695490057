import Page from '@components/Page/Page';

import React from 'react';

import ProfileBuilderContainer from '@components/Profiles/ProfileManager/ProfileBuilderContainer';

const Profiles: React.FC<{}> = ({}) => {
    return (
        <Page
            title="Profiles"
            withFullHeight={false}
            withNavbar
            withFixedContent
            withPaddedContent
        >
            <ProfileBuilderContainer />
        </Page>
    );
};
export default Profiles;
