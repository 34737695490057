import IntegrationOption from '@components/Campaigns/Builder/IntegrationOption';
import StepTitle from '@components/Campaigns/Builder/Steps/StepTitle';
import { CampaignBuilderStep } from '@components/Campaigns/Builder/types';
import { CampaignBuilderSteps } from '@components/Campaigns/Builder/utils';
import SkeletonLoader from '@components/common/SkeletonLoader';
import {
    useGetIntegratedProviderConfig,
    useGetProviderConfig,
} from '@hooks/useChannels';
import { useLocale } from '@hooks/useLocale';
import { CommunicationChannelName, type Integration } from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import { ChannelPage } from '@pages/Channel';
import { useCampaignContext } from '@providers/CampaignProvider';
import React, { useEffect } from 'react';

const CampaignBuilderChannel: React.FC<{}> = () => {
    const { t } = useLocale();

    const {
        setCampaignChannelPayload,
        setShowFooterButtons,
        setCurrentStep,
        setCampaignContentPayload,
    } = useCampaignContext((context) => context.actions);

    const { campaignPayload, isEditMode } = useCampaignContext(
        (context) => context.state,
    );
    const { communicationDetails } = campaignPayload;

    const {
        data: integrationConfig,
        isInitialLoading: isIntegrationConfigLoading,
    } = useGetIntegratedProviderConfig(
        communicationDetails.providerId,
        communicationDetails.id,
    );

    const { data: providerConfig, isInitialLoading: isProviderConfigLoading } =
        useGetProviderConfig(communicationDetails.providerId);

    const title = CampaignBuilderSteps.find(
        (step) => step.key === CampaignBuilderStep.CHANNEL,
    )?.pageHeader;

    const onProviderChange = (provider: Integration) => {
        setCampaignChannelPayload(provider);
        setCurrentStep(CampaignBuilderStep.CONTENT);
    };

    useEffect(() => {
        if (communicationDetails.providerId) {
            setShowFooterButtons({
                next: true,
                back: true,
            });
            return;
        }
        setShowFooterButtons({
            next: false,
            back: true,
        });
    }, [communicationDetails, setShowFooterButtons]);

    return (
        <Box>
            <StepTitle title={title || ''} />
            <Box className="mt-6 max-w-[800px] w-[500px] pb-[6rem]">
                {isIntegrationConfigLoading || isProviderConfigLoading ? (
                    <>
                        <SkeletonLoader width={500} height={100} />
                    </>
                ) : (
                    <>
                        {communicationDetails?.providerId &&
                        integrationConfig &&
                        providerConfig ? (
                            <Box
                                className={`w-full ${
                                    isEditMode ? 'cursor-pointer' : ''
                                } `}
                                onClick={() => {
                                    if (!isEditMode) return;
                                    setCampaignChannelPayload(null);
                                    setCampaignContentPayload(null);
                                }}
                            >
                                {communicationDetails.channel && (
                                    <Text className="text-sm font-medium text-gray-800 mb-1.5">
                                        {t('channels.channel_selected', {
                                            channelName:
                                                CommunicationChannelName[
                                                    communicationDetails.channel
                                                ],
                                        })}
                                    </Text>
                                )}

                                <IntegrationOption
                                    title={integrationConfig?.customName}
                                    providerName={providerConfig.providerName}
                                    providerLogoUrl={providerConfig.logoUrl}
                                    integrationId={
                                        integrationConfig.integrationId ?? ''
                                    }
                                    isMutable={isEditMode}
                                    showRightSection={isEditMode}
                                />
                            </Box>
                        ) : (
                            <>
                                <ChannelPage
                                    allowChannelSetup={false}
                                    handleProviderSelect={onProviderChange}
                                    showSetupLabels={false}
                                />
                            </>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default React.memo(CampaignBuilderChannel);
