import { sortmentApi } from '@api/index';
import { type ApiError, type UserAllowedOrganization } from '@lightdash/common';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';

const getAllowedOrganizations = async (): Promise<UserAllowedOrganization[]> =>
    sortmentApi<UserAllowedOrganization[]>({
        url: `/user/me/allowedOrganizations`,
        method: 'GET',
        body: undefined,
    });

const useAllowedOrganizations = () => {
    return useQuery<UserAllowedOrganization[], ApiError>({
        queryKey: [QueryKeys.USER_ALLOWED_ORGANIZATIONS],
        queryFn: getAllowedOrganizations,
    });
};

export default useAllowedOrganizations;
