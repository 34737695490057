import { Stack, TextInput } from '@mantine/core';
// import { hasNoWhiteSpaces } from '@utils/fieldValidators';
import { WarehouseTypes } from '@lightdash/common';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const RedshiftStorageForm: React.FC<{
    disabled: boolean;
}> = ({ disabled }) => {
    const { register, setValue } = useFormContext();
    useEffect(() => {
        setValue('warehouseConfig.warehouseType', WarehouseTypes.REDSHIFT);
    }, [setValue]);
    return (
        <Stack>
            <TextInput
                label="IAM Role ARN"
                required
                {...register('warehouseConfig.iamRole', {
                    // validate: {
                    //     hasNoWhiteSpaces: hasNoWhiteSpaces('Host'),
                    // },
                })}
                disabled={disabled}
                labelProps={{ style: { marginTop: '8px' } }}
                placeholder="arn:aws:iam::1234567890:role/SORTMENT_ROLE"
            />
        </Stack>
    );
};

export default RedshiftStorageForm;
