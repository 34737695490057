import { subject } from '@casl/ability';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { useAbilityContext } from '@components/common/Authorization';
import TableIcon from '@components/common/IconPack/TableIcon';
import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Timestamp from '@components/common/Timestamp';
import { useGetCustomMetricById } from '@hooks/useCustomMetric';
import { useLocale } from '@hooks/useLocale';
import { RelationTableType, type CustomAttribute } from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { useApp } from '@providers/AppProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { type ColumnDef, type Row } from '@tanstack/react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronLeft, Copy, Hash } from 'react-feather';
import { useParams } from 'react-router-dom';
import { GlobalStyles } from '../../../mantineTheme';
import CreateCustomMetric from '../CreateCustomMetric';
interface CustomMetricMenuItemProps {
    rowData: Row<CustomAttribute>;
}
const CustomMetricMenuItem: React.FC<CustomMetricMenuItemProps> = ({
    rowData,
}) => {
    const { t } = useLocale();
    const ability = useAbilityContext();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { user } = useApp();
    const canCreateCustomMetrics = ability.can(
        'create',
        subject('CustomMetric', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const [customMetricId, setCustomMetricId] = useState<string | undefined>(
        undefined,
    );
    const {
        data: customMetricData,
        refetch,
        isRefetching,
        isFetching,
    } = useGetCustomMetricById(customMetricId ?? '');

    useEffect(() => {
        if (customMetricId && !isRefetching && !isFetching) {
            void refetch();
        }
    }, [customMetricId, refetch, isRefetching, isFetching]);
    const handleModalClose = () => {
        setCustomMetricId(undefined);
    };

    const handleDuplicate = useCallback(
        (id: string) => {
            if (id) {
                setCustomMetricId(id);
            }
        },
        [setCustomMetricId],
    );
    const menuItems = useMemo(() => {
        const items: CustomMenuItemProps<CustomAttribute>[] = [
            {
                leftSection: (
                    <Copy
                        size={14}
                        color={'rgb(var(--color-gray-500))'}
                        strokeWidth={2.5}
                    />
                ),
                children: t('custom_metric_manager.menu_item_duplicate'),
                onClick: (values) => handleDuplicate(values.uuid),
            },
        ];
        return items;
    }, [t, handleDuplicate]);
    if (!canCreateCustomMetrics) {
        return null;
    }

    return (
        canCreateCustomMetrics && (
            <>
                <CustomMenu menuItems={menuItems} data={rowData.original} />
                {
                    <CreateCustomMetric
                        customMetricData={customMetricData}
                        customMetricId={customMetricId}
                        isDuplicate={true}
                        onModalClose={handleModalClose}
                        openBaseTable={undefined}
                        tableName={undefined}
                        isViewMode={undefined}
                        disableTableChange={undefined}
                    />
                }
            </>
        )
    );
};
export const useCustomMetricCoulumns = () => {
    const [columns, setColumns] = useState<ColumnDef<CustomAttribute>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const { activeRelation } = useRelationContext();
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: t('custom_metric_manager.th_custom_metric_name'),
                    cell: ({ row }) => {
                        return (
                            <Box className="flex flex-col gap-1 justify-center items-start ">
                                <Flex
                                    justify="center"
                                    align="center"
                                    className="gap-1"
                                >
                                    <Hash
                                        color={'rgb(var(--color-gray-600)'}
                                        size={13}
                                        strokeWidth={2}
                                        className="inline me-1"
                                    />
                                    <Text
                                        span
                                        className="text-sm font-medium text-gray-800 whitespace-nowrap "
                                    >
                                        {row.original.name}
                                    </Text>

                                    <ChevronLeft
                                        color={'rgb(var(--color-gray-600)'}
                                        size={13}
                                        strokeWidth={2}
                                    />
                                    <TableIcon
                                        type={
                                            activeRelation?.tables[
                                                row.original.srcTable
                                            ]?.type ?? RelationTableType.RELATED
                                        }
                                        strokeWidth={2.5}
                                    />
                                    {activeRelation?.tables[
                                        row.original.srcTable
                                    ]?.type && (
                                        <Text
                                            className={
                                                `
                                ${
                                    GlobalStyles.tableStyles[
                                        activeRelation?.tables[
                                            row.original.srcTable
                                        ].type
                                    ].textColorTailwind
                                }` + ' whitespace-nowrap'
                                            }
                                        >
                                            {
                                                activeRelation?.tables[
                                                    row.original.srcTable
                                                ].label
                                            }
                                        </Text>
                                    )}
                                </Flex>
                                {row.original.description && (
                                    <Text
                                        span
                                        className="text-xs font-normal text-gray-600 "
                                    >
                                        {row.original.description}
                                    </Text>
                                )}
                            </Box>
                        );
                    },
                },
                {
                    accessorKey: 'createdAt',
                    header: t('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`by ${
                                    row.original.createdBy?.firstName || ''
                                } ${row.original.createdBy?.lastName || ''}`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => <CustomMetricMenuItem rowData={row} />,
                },
            ]);
        }
    }, [t, projectUuid, activeRelation, fieldsWithSuggestions]);
    return columns;
};
