import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { useLocale } from '@hooks/useLocale';
import { useGetProfileConfig } from '@hooks/useProfile';
import { ProjectSettings } from '@lightdash/common';
import { Box, Button, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PencilSimple } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { isInvalidCronExpression } from '@utils/fieldValidators';
import cronstrue from 'cronstrue';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonVariant } from '../../mantineTheme';
import UserProfileSyncModal from './UserProfileSyncModal';

export const cronHelperText = (cron: string) => {
    const validationError = isInvalidCronExpression('Cron expression')(cron);
    const cronHumanString = cronstrue.toString(cron, {
        verbose: true,
        throwExceptionOnParseError: false,
    });
    return validationError ?? cronHumanString;
};

const UserProfileSync: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const [opened, { open, close }] = useDisclosure(false);
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { data: config, isInitialLoading } = useGetProfileConfig(projectUuid);
    const [cronExpression, setCronExpression] = useState('0 0 * * *');
    const ability = useAbilityContext();
    const { user } = useApp();
    const canEditSyncSchedule = ability.can(
        'update',
        subject(ProjectSettings.syncSchedules, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    useEffect(() => {
        if (config && config.cron) {
            setCronExpression(config.cron);
        }
    }, [config]);
    const isProfileCreated = config && Object.values(config).length > 0;
    if (isInitialLoading && config) {
        return (
            <Flex direction={'column'} gap={8}>
                <SkeletonLoader height={10} />
                <SkeletonLoader width={500} height={15} />
            </Flex>
        );
    }

    return (
        <>
            <Box className="border border-shade-4 rounded-2xl p-0.5 mt-4">
                <Box className="w-full text-gray-700 bg-white border border-gray-50 rounded-xl">
                    <Flex
                        className="p-3 border-b border-shade-4"
                        justify="space-between"
                        align="center"
                    >
                        <Text className="font-semibold ">
                            {t('user_profile_sync.title')}
                        </Text>
                        {isProfileCreated && canEditSyncSchedule && (
                            <Button
                                variant={ButtonVariant.OUTLINED}
                                leftIcon={<PencilSimple />}
                                onClick={open}
                            >
                                {t('user_profile_sync.edit_button')}
                            </Button>
                        )}
                    </Flex>
                    {isProfileCreated && config.cron ? (
                        <Text className="p-3">
                            {' '}
                            {cronHelperText(config?.cron).length < 50
                                ? `${t('user_profile_sync.update_message')} ${
                                      cronHelperText(config.cron)
                                          .charAt(0)
                                          .toLowerCase() +
                                      cronHelperText(config.cron).slice(1)
                                  }.`
                                : cronHelperText(config.cron)}
                        </Text>
                    ) : (
                        <Text className="p-3">
                            {t('user_profile_sync.create_message')}
                        </Text>
                    )}
                </Box>
            </Box>
            <UserProfileSyncModal
                opened={opened}
                onClose={() => {
                    close();
                    setCronExpression(config?.cron ?? '0 0 * * *');
                }}
                cronExpression={cronExpression}
                setCronExpression={setCronExpression}
            />
        </>
    );
};
export default React.memo(UserProfileSync);
