import { Title } from '@mantine/core';
import React from 'react';

interface StepTitleProps {
    title: string;
}

const StepTitle: React.FC<StepTitleProps> = ({ title }) => {
    return <Title className="text-lg font-medium text-gray-800">{title}</Title>;
};

export default React.memo(StepTitle);
