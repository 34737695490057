import { Text } from '@mantine/core';
import React from 'react';

interface ActionTitleProps {
    title: string;
}

const ActionTitle: React.FC<ActionTitleProps> = ({ title }) => {
    return (
        <Text className="text-xs font-medium text-gray-500 uppercase">
            {title}
        </Text>
    );
};

export default ActionTitle;
