import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type CreatePasswordResetLink,
    type PasswordReset,
} from '@lightdash/common';
import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';

const getPasswordResetLinkQuery = async (
    code: string,
): Promise<CreatePasswordResetLink> =>
    sortmentApi<CreatePasswordResetLink>({
        url: `/password-reset/${code}`,
        method: 'GET',
        body: undefined,
    });

const sendPasswordResetLinkQuery = async (
    data: CreatePasswordResetLink,
): Promise<null> =>
    sortmentApi<null>({
        url: `/password-reset`,
        method: 'POST',
        body: JSON.stringify(data),
    });

const resetPasswordQuery = async (data: PasswordReset): Promise<null> =>
    sortmentApi<null>({
        url: `/user/password/reset`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const usePasswordResetLink = (code: string) =>
    useQuery<CreatePasswordResetLink, ApiError>({
        queryKey: [QueryKeys.PASSWORD_RESET_LINK],
        queryFn: () => getPasswordResetLinkQuery(code),
    });

export const usePasswordResetLinkMutation = () => {
    const { showToastApiError, showToastSuccess } = useNotify();
    return useMutation<null, ApiError, CreatePasswordResetLink>(
        sendPasswordResetLinkQuery,
        {
            mutationKey: [QueryKeys.SEND_PASSWORD_RESET_EMAIL],
            onSuccess: async () => {
                showToastSuccess({
                    title: 'Password recovery email sent successfully',
                });
            },
            onError: ({ error }) => {
                showToastApiError({
                    title: `Failed to send password recovery email`,
                    apiError: error,
                });
            },
        },
    );
};

export const usePasswordResetMutation = () => {
    const { showToastApiError, showToastSuccess } = useNotify();
    return useMutation<null, ApiError, PasswordReset>(resetPasswordQuery, {
        mutationKey: [QueryKeys.RESET_PASSWORD],
        onSuccess: async () => {
            showToastSuccess({
                title: 'Password updated successfully',
            });
        },
        onError: ({ error }) => {
            showToastApiError({
                title: `Failed to reset password`,
                apiError: error,
            });
        },
    });
};
