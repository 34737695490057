import TextInput from '@components/common/Inputs/TextInput';
import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import { useActiveProjectUuid } from '@hooks/useActiveProject';
import { useLocale } from '@hooks/useLocale';
import { useProject } from '@hooks/useProject';
import useTimestamp from '@hooks/useTimestamp';
import { JourneyStatus, Timezones } from '@lightdash/common';
import { Box, Input, Radio, Text } from '@mantine/core';
import { DateInput, TimeInput } from '@mantine/dates';
import { Calendar, CheckCircle, Clock } from '@phosphor-icons/react';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import moment from 'moment';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import { extractDate, extractTime } from '../utils';

interface LaunchModalProps {
    showLaunch: boolean;
    onClose: () => void;
}

enum SchedulerType {
    NOW = 'now',
    SCHEDULED = 'scheduled',
}

const LaunchModal: React.FC<LaunchModalProps> = ({ showLaunch, onClose }) => {
    const { t } = useLocale();
    const { getTimestamp } = useTimestamp();

    const { journeyPayload, isLoading: activateJourneyLoading } =
        useJourneyBuilderContext((context) => context.state);

    const { updateJourneyPayload, mutateActivateJourney } =
        useJourneyBuilderContext((context) => context.actions);

    const startTimeRef = useRef<HTMLInputElement>(null);

    const { activeProjectUuid } = useActiveProjectUuid();
    const { data: projectData } = useProject(activeProjectUuid);
    const projectTimezone = projectData?.timezone ?? Timezones.UTC;

    const [scheduler, setScheduler] = useState<SchedulerType>(
        journeyPayload.startDate ? SchedulerType.SCHEDULED : SchedulerType.NOW,
    );

    const [startTimeError, setStartTimeError] = useState<boolean>(false);

    const offset = moment().tz(projectTimezone).format('Z');

    const handleChange = useCallback((value: SchedulerType) => {
        if (value === SchedulerType.NOW) {
            setScheduler(SchedulerType.NOW);
            return;
        }
        setScheduler(SchedulerType.SCHEDULED);
    }, []);

    const convertToProjectTimezone = useCallback(
        (date: Date): Date => {
            const localTime = moment(date);
            const projectTime = moment.tz(
                localTime.format('YYYY-MM-DDTHH:mm:ss'),
                projectTimezone,
            );
            return new Date(projectTime.format());
        },
        [projectTimezone],
    );

    const handleStartDateChange = useCallback(
        (value: Date) => {
            updateJourneyPayload({ startDate: value });
            const datetime = convertToProjectTimezone(value);
            updateJourneyPayload({ startDate: datetime });
        },
        [convertToProjectTimezone, updateJourneyPayload],
    );

    const handleStartTimeChange = useCallback(
        (value: string) => {
            const [hours, minutes] = value.split(':');
            if (Number(minutes) % 15 !== 0) {
                setStartTimeError(true);
                return;
            }
            setStartTimeError(false);
            const date =
                journeyPayload.startDate &&
                getTimestamp(new Date(journeyPayload.startDate).getTime());

            if (!date) return;

            const datePart = date.split('T')[0];
            const newDate = new Date(datePart);
            newDate.setHours(parseInt(hours, 10));
            newDate.setMinutes(parseInt(minutes, 10));
            const datetime = convertToProjectTimezone(newDate);
            updateJourneyPayload({
                startDate: datetime,
            });
        },
        [
            convertToProjectTimezone,
            getTimestamp,
            journeyPayload,
            updateJourneyPayload,
        ],
    );

    const startDate = useMemo(
        () =>
            journeyPayload.startDate
                ? extractDate(
                      getTimestamp(
                          new Date(journeyPayload.startDate).getTime(),
                      ),
                  )
                : undefined,
        [journeyPayload, getTimestamp],
    );

    const { hours: startHours, minutes: startMinutes } =
        journeyPayload.startDate
            ? extractTime(
                  getTimestamp(new Date(journeyPayload.startDate).getTime()),
              )
            : { hours: '', minutes: '' };

    return (
        <Modal
            opened={showLaunch}
            onClose={() => onClose()}
            keepMounted={false}
            title={t('journey_launch_modal.launch_journey')}
            size="lg"
            withContentPadding={false}
            footerRightSection={
                <ModalFooter
                    showSecondaryButton={true}
                    secondaryButtonVariant={ButtonVariant.OUTLINED}
                    secondaryText={t(
                        'custom_metric.dimension_modal_cancel_button',
                    )}
                    secondaryButtonClick={onClose}
                    primaryText={
                        activateJourneyLoading
                            ? t('journey_launch_modal.launching_journey')
                            : t('journey_launch_modal.launch_now')
                    }
                    primaryButtonClick={async () => {
                        if (scheduler === SchedulerType.NOW)
                            await mutateActivateJourney({
                                status: JourneyStatus.ACTIVE,
                            });
                        if (scheduler === SchedulerType.SCHEDULED)
                            await mutateActivateJourney({
                                status: JourneyStatus.SCHEDULED,
                                startDate: journeyPayload.startDate,
                                endDate: journeyPayload.endDate,
                            });
                        onClose();
                    }}
                    showPrimaryButton={true}
                    primaryLeftIcon={
                        <CheckCircle
                            color="white"
                            size={14}
                            strokeWidth={2.5}
                        />
                    }
                    primaryRightIcon={undefined}
                    secondaryLeftIcon={undefined}
                    secondaryRightIcon={undefined}
                    primaryButtonVariant={ButtonVariant.FILLED}
                    isLoading={activateJourneyLoading}
                    primaryButtonDisabled={undefined}
                    primaryButtonCustomClass={undefined}
                />
            }
        >
            <Box className="m-3">
                <Text className="text-gray-800 text-base font-semibold">
                    {t('organization_settings.menu_item_overview')}
                </Text>
                <Box className="my-3">
                    <Text className="mb-1.5 text-gray-800 text-sm font-medium">
                        {t('campaigns.create.name')}
                    </Text>
                    <TextInput
                        value={journeyPayload.name}
                        onChange={(e) =>
                            updateJourneyPayload({ name: e.target.value })
                        }
                    />
                </Box>
                <Box>
                    <Box className="flex space-x-1 mb-1.5">
                        <Text className="text-gray-800 text-sm font-medium">
                            {t('campaigns.create.description')}
                        </Text>
                        <Text className="text-gray-600 text-sm font-medium">
                            {t(
                                'custom_metric.dimension_modal_description_label_optional',
                            )}
                        </Text>
                    </Box>
                    <TextInput
                        placeholder={t(
                            'journey_launch_modal.give_this_journey_a_description',
                        )}
                        value={journeyPayload.description}
                        onChange={(e) =>
                            updateJourneyPayload({
                                description: e.target.value,
                            })
                        }
                    />
                </Box>
            </Box>
            <Box className="p-3 border-[1px]">
                <Text className="mb-4">{t('audience_scheduler.schedule')}</Text>
                <Radio.Group value={scheduler} onChange={handleChange}>
                    <Radio
                        className="pb-3"
                        value={SchedulerType.NOW}
                        label={t(
                            'journey_launch_modal.activate_this_journey_now',
                        )}
                    />
                    <Radio
                        value={SchedulerType.SCHEDULED}
                        label={t(
                            'journey_settings_scheduling.activate_this_journey',
                        )}
                    />
                </Radio.Group>
                {scheduler === SchedulerType.SCHEDULED && (
                    <Box className="flex my-3 space-x-2">
                        <DateInput
                            valueFormat="DD MMM YYYY"
                            placeholder="DD/MM/YYYY"
                            icon={
                                <Calendar
                                    size={13}
                                    strokeWidth={2}
                                    color={'rgb(var(--color-gray-600))'}
                                />
                            }
                            minDate={new Date()}
                            onChange={handleStartDateChange}
                            value={startDate}
                        />
                        <Text className="my-2">
                            {t('audience_scheduler.at')}
                        </Text>
                        <TimeInput
                            ref={startTimeRef}
                            icon={
                                <Clock
                                    size={13}
                                    strokeWidth={2}
                                    color={'rgb(var(--color-gray-600))'}
                                />
                            }
                            onClick={() => startTimeRef?.current?.showPicker()}
                            onChange={(event) =>
                                handleStartTimeChange(event.target.value)
                            }
                            error={
                                startTimeError &&
                                t('campaign_scheduler.minutes_error')
                            }
                            className="w-[120px]"
                            value={`${startHours}:${startMinutes}`}
                        />
                        <Input.Label className="text-gray-600 text-sm font-normal my-2">
                            {`(GMT${offset})`}
                        </Input.Label>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default LaunchModal;
