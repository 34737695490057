import { type Audience } from './audiences';
import { type NestedMetricQueryGroup } from './metricQuery';

export type AgentMessage = {
    id: string;
    author: Author;
    context: AIMessageContext;
    content: TextContent | ImageContent;
    payload?: SQLAudience | VisualAudience;
};

type TextContent = {
    content_type: 'text';
    value: string;
};

type ImageContent = {
    content_type: 'image';
    url: string;
    alt?: string;
};

type VisualAudience = NestedMetricQueryGroup;

type SQLAudience = string;

export type NaturalLanguageSQLResult = {
    question: string;
    response: string | null;
    summary?: string;
};

export type VisualPromptResult = {
    nestedMetricQuery: NestedMetricQueryGroup | undefined;
    message: string;
};

export type CreateNaturalLanguageSQL = {
    question: string;
};

export enum QueryClassification {
    EASY = 'EASY',
    NON_NESTED = 'NON-NESTED',
    NESTED = 'NESTED',
}

export enum AIContentType {
    TEXT = 'text',
}

export enum Author {
    USER = 'user',
    SYSTEM = 'system',
}

export type BaseMessage<T> = {
    id: string;
    author: Author;
    context: MessageContext;
    content: {
        content_type: AIContentType;
        value: string; // Enhance this when we have more content types
    };
    payload: T;
};

export type AIVisualMessage = BaseMessage<NestedMetricQueryGroup> & {
    context: MessageContext.VISUAL_AUDIENCE;
};

export type AISQLMessage = BaseMessage<Pick<Audience, 'sqlQuery'>> & {
    context: MessageContext.SQL_AUDIENCE;
};

// TODO: Possibly chang this with page context from frontend as we would want to add context of all page paths
export enum MessageContext {
    SQL_AUDIENCE = 'sql_audience',
    VISUAL_AUDIENCE = 'visual_audience',
}

export enum AIMessageContext {
    SQL_AUDIENCE = 'sql_audience',
    VISUAL_AUDIENCE = 'visual_audience',
    HOME_PAGE = 'home_page',
    EVENT_SETUP = 'events',
    OTHERS = 'others',
}

export type MessageTypeMap = {
    [MessageContext.VISUAL_AUDIENCE]: AIVisualMessage;
    [MessageContext.SQL_AUDIENCE]: AISQLMessage;
};

export type WidgetMessage =
    | MessageTypeMap[keyof MessageTypeMap]
    | BaseMessage<undefined>;

export type AIWidgetRequest = {
    messages: Array<WidgetMessage>;
};

export type AIWidgetResult = {
    message: WidgetMessage;
};
