import Modal from '@components/common/modal/Modal';
import { useLocale } from '@hooks/useLocale';
import { AudienceRunTypes, type Audience } from '@lightdash/common';
import { Group, Text } from '@mantine/core';
import { UsersThree } from '@phosphor-icons/react';
import { type FC } from 'react';
import SchedulerModalContent from './SchedulerModalContent';

interface SchedulersModalProps {
    isOpen: boolean;
    onClose: () => void;
    audienceData?: Audience;
}

const SchedulersModal: FC<SchedulersModalProps> = ({
    isOpen = false,
    onClose,
    audienceData,
}) => {
    const { t } = useLocale();
    return (
        <Modal
            opened={isOpen}
            onClose={onClose}
            size="xl"
            title={
                <Group className="flex flex-col items-start gap-0">
                    <Text className="text-gray-800 text-base font-medium pb-0 mb-0">
                        {audienceData?.runType === AudienceRunTypes.MANUAL
                            ? t('scheduler_modal.set_up_run_schedule')
                            : t('scheduler_modal.change_run_schedule')}
                    </Text>
                    <Text className="flex items-center text-sm text-gray-600 font-medium pt-0 mt-0 gap-1.5">
                        <UsersThree size={13} />
                        {audienceData?.name}
                    </Text>
                </Group>
            }
            styles={() => ({
                content: {
                    maxWidth: '48vw !important',
                },
                header: {
                    display: 'flex',
                    alignItems: 'start',
                },
                body: {
                    padding: '0px',
                },
            })}
        >
            <SchedulerModalContent
                onClose={onClose}
                audienceData={audienceData}
            />
        </Modal>
    );
};

export default SchedulersModal;
