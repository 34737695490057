import GenericOption, {
    type GenericOptionProps,
} from '@components/common/Card/GenericOption';
import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import { type CommunicationChannel } from '@lightdash/common';
import { Group, Image, Text } from '@mantine/core';
import { CaretRight, CaretUpDown } from '@phosphor-icons/react';
import React from 'react';

interface IntegrationOptionProps extends GenericOptionProps {
    title: string;
    integrationId: string;
    providerName: string;
    providerLogoUrl: string;
    modifiedDate?: string;
    isMutable?: boolean;
    customClass?: string;
    channel?: CommunicationChannel | null;
    showRightSection?: boolean;
    onClick?: () => void;
    rightSection?: React.ReactNode;
}

const IntegrationOption: React.FC<IntegrationOptionProps> = ({
    title,
    integrationId,
    isMutable = false,
    providerLogoUrl,
    providerName,
    channel,
    showRightSection = true,
    onClick,
    ...rest
}) => {
    return (
        <GenericOption
            title={
                <Text className="text-sm font-medium text-gray-800">
                    {title}
                </Text>
            }
            subtitle={
                <Group className="gap-1.5 text-xs text-gray-600">
                    {channel && (
                        <Group className="gap-1">
                            <ChannelIcon channel={channel} />
                            <span>·</span>
                        </Group>
                    )}
                    <Image
                        src={providerLogoUrl}
                        alt={integrationId}
                        width={16}
                        height={16}
                    />
                    <Text>{providerName}</Text>
                </Group>
            }
            rightSection={
                showRightSection ? (
                    isMutable ? (
                        <CaretUpDown weight="regular" />
                    ) : (
                        <CaretRight weight="regular" />
                    )
                ) : null
            }
            onClick={onClick}
            {...rest}
        />
    );
};

export default React.memo(IntegrationOption);
