//TODO: Add field validators
import { useLocale } from '@hooks/useLocale';
import { BlobStorageType, RegionLabels } from '@lightdash/common';
import { Select, Stack, TextInput } from '@mantine/core';
// import { useProjectContext } from '@providers/ProjectProvider';
// import { hasNoWhiteSpaces } from '@utils/fieldValidators';
import PasswordInput from '@components/common/Inputs/PasswordInput';
import { isValidBucketName } from '@utils/fieldValidators';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
function mapEnumValuesToOptions(enumValues: {
    [key: string]: string;
}): { value: string; label: string }[] {
    return Object.keys(enumValues).map((key) => ({
        value: key,
        label: enumValues[key],
    }));
}

const AmazonS3: React.FC<{ disabled: boolean }> = ({ disabled }) => {
    // const { projectData } = useProjectContext();
    const { t } = useLocale();
    const { register, setValue } = useFormContext();

    useEffect(() => {
        setValue('blobStorageConfig.blobStorageType', BlobStorageType.S3);
    }, [setValue]);

    return (
        <Stack>
            <Controller
                name="blobStorageConfig.region"
                // defaultValue="prefer"
                render={({ field }) => (
                    <Select
                        name={field.name}
                        required
                        label={t('s3_form.region_label')}
                        placeholder={t('s3_form.region_placeholder')}
                        description={<p>{t('s3_form.region_description')}</p>}
                        data={mapEnumValuesToOptions(RegionLabels)} //TODO: Can enhance this to show both label and value in the dropdown like in AWS
                        value={field.value}
                        onChange={field.onChange}
                        disabled={disabled}
                        searchable
                    />
                )}
            />

            <TextInput
                label={t('s3_form.bucket_label')}
                required
                {...register('blobStorageConfig.bucket', {
                    validate: {
                        isValidBucketName: isValidBucketName('Bucket Name'),
                    },
                })}
                disabled={disabled}
                labelProps={{ style: { marginTop: '8px' } }}
                placeholder={t('s3_form.bucket_placeholder')}
            />

            <TextInput
                label={t('s3_form.accessKey_label')}
                description={t('s3_form.accessKey_description')}
                required
                {...register('blobStorageConfig.accessKey', {
                    // validate: {
                    //     hasNoWhiteSpaces: hasNoWhiteSpaces('Host'),
                    // },
                })}
                placeholder={t('s3_form.accessKey_placeholder')}
                disabled={disabled}
                labelProps={{ style: { marginTop: '8px' } }}
            />
            <Controller
                name="blobStorageConfig.secretKey"
                render={({ field }) => (
                    <PasswordInput
                        label={t('s3_form.secretKey_label')}
                        description={t('s3_form.secretKey_description')}
                        autoComplete="new-password"
                        required
                        {...field}
                        disabled={disabled}
                    />
                )}
            />
        </Stack>
    );
};

export default AmazonS3;
