import TriggerFilterGroupFormProps from '@components/Journeys/Builder/ControlPanel/TriggerConfigPanel/TriggerFilterGroupForm';
import { useGetJourneyNodeParams } from '@hooks/useJourney';
import {
    DimensionType,
    FieldType,
    getFilterRulesFromGroup,
    PeriodType,
    type FilterableDimension,
    type FilterGroup,
    type Filters,
} from '@lightdash/common';
import { Divider, Group, Stack } from '@mantine/core';
import { Cursor } from '@phosphor-icons/react';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import { t } from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useNodeData } from '../../ReactFlow/Nodes/useNodeData';
import ActionTitle from '../Actions/ActionTitle';
import ReEntryBlock from '../Actions/ReEntry';
import BlockConfigContainer from '../BlockConfigPanel/BlockConfigContainer';
import useJourneyEventsFilterableFields from './useJourneyEventsFilterableFields';

interface TriggerConfigPanelProps {
    triggerId: string;
}

const TriggerConfigPanel: React.FC<TriggerConfigPanelProps> = ({
    triggerId,
}) => {
    const { journeyNodeData: triggerBlockData, icon } = useNodeData(triggerId);
    const { journeyPayload, journeyEvents } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const { mutateAsync } = useGetJourneyNodeParams('entry');

    const getJourneyNodeParams = useCallback(async () => {
        const { config } = journeyPayload;
        if (!config) return;
        const result = await mutateAsync(journeyPayload);
        console.log(result);
    }, [journeyPayload, mutateAsync]);

    useEffect(() => {
        void getJourneyNodeParams();
    }, [getJourneyNodeParams]);

    const { closeControlPanel, setEntryLogic, updateTriggerNode } =
        useJourneyBuilderContext((context) => context.actions);

    const filterableFields = useJourneyEventsFilterableFields(journeyEvents);

    const { entryLogic, triggers } = journeyPayload;
    const filterConfig = triggers?.entry?.[0]?.filterConfig as FilterGroup;
    const filters: Filters = {
        dimensions: filterConfig,
    };
    const filterRules = getFilterRulesFromGroup(filterConfig);

    const activeField: FilterableDimension = {
        label: triggers?.entry?.[0]?.eventName ?? '',
        type: DimensionType.EVENT,
        fieldType: FieldType.DIMENSION,
        table: triggers?.entry?.[0]?.eventSource ?? '',
        tableLabel: triggers?.entry?.[0]?.eventSource ?? '',
        sql: '',
        name: triggers?.entry?.[0]?.eventName ?? '',
        hidden: false,
        // tableType: RelationTableType.EVENT,
    };

    const cooldown = entryLogic!.cooldown;
    const cooldownDuration = cooldown >= 0 ? cooldown : undefined;
    const cooldownType = entryLogic?.uiConfig?.cooldownType ?? PeriodType.DAY;
    const isReEntryBlockChecked = cooldown >= 0;

    const onDescriptionChange = useCallback(
        (description: string) => {
            if (!triggerBlockData) return;
            updateTriggerNode({
                metadata: {
                    description,
                },
            });
        },
        [triggerBlockData, updateTriggerNode],
    );

    const onBlockClose = () => {
        closeControlPanel();
    };

    const handleDurationChange = useCallback(
        (duration: number, granularity: PeriodType) => {
            setEntryLogic({
                cooldown: duration,
                uiConfig: {
                    cooldownType: granularity,
                },
            });
        },
        [setEntryLogic],
    );

    const handleReEntryChange = useCallback(
        (checked: boolean) => {
            setEntryLogic({
                cooldown: checked ? undefined : -1,
            });
        },
        [setEntryLogic],
    );

    const handleEventChange = useCallback(
        (eventName: string, eventSource: string) => {
            updateTriggerNode({
                eventName,
                eventSource,
            });
        },
        [updateTriggerNode],
    );

    if (!triggerId || !triggerBlockData) return null;

    return (
        <BlockConfigContainer
            nodeData={triggerBlockData}
            icon={icon}
            onDescriptionChange={onDescriptionChange}
            onBlockClose={onBlockClose}
            showChangeButton={false}
        >
            <Stack>
                <Group className="gap-1">
                    <Cursor color={'rgb(var(--color-gray-500))'} />
                    <ActionTitle
                        title={t('journey_builder.event_action_title')}
                    />
                </Group>
                <TriggerFilterGroupFormProps
                    fields={filterableFields}
                    onChange={() => {}}
                    filters={filters}
                    setFilters={(values) => {
                        console.log(values);
                    }}
                    isEditMode={true}
                    filterRules={filterRules}
                    activeField={activeField}
                    handleEventChange={handleEventChange}
                />

                <Divider className="border-t-gray-200" />

                <ReEntryBlock
                    checked={isReEntryBlockChecked}
                    duration={cooldownDuration}
                    granularity={cooldownType}
                    onDurationChange={handleDurationChange}
                    onReEntryChange={handleReEntryChange}
                />
            </Stack>
        </BlockConfigContainer>
    );
};

export default React.memo(TriggerConfigPanel);
