import {
    CampaignStatus,
    CommunicationChannel,
    type Integration,
} from '@lightdash/common';
import { type DateValue } from '@mantine/dates';
import { CAMPAIGN_EDIT_DELTA_GAP } from '@utils/constants';
import moment from 'moment';

export const convertTo24Hour = (time: string): string => {
    const [hourMin, period] = time.split(' ');
    let [hours, minutes] = hourMin.split(':');

    if (period === 'PM' && hours !== '12') {
        hours = String(Number(hours) + 12);
    } else if (period === 'AM' && hours === '12') {
        hours = '00';
    }
    return `${hours}:${minutes}`;
};
// Date object change to UTC and to epoch time
export const handleDateFormatChange = (value: DateValue) => {
    // Convert to UTC by creating a new Date object
    if (!value || value === null) return null;

    const utcDate = new Date(
        value.getUTCFullYear(),
        value.getUTCMonth(),
        value.getUTCDate(),
        value.getUTCHours(),
        value.getUTCMinutes(),
        value.getUTCSeconds(),
    );

    // Convert to epoch time
    const utcEpochTime = utcDate.getTime();
    return utcEpochTime;
};

export const createIntegrationObjectsForImports = (
    providersInChannels: Integration[],
    integratedChannels: Integration[],
    integration: Integration,
) => {
    if (integration?.channelName === CommunicationChannel.EMAIL) {
        return integratedChannels.map((integratedChannel: Integration) => {
            let findOriginalProviderObject = providersInChannels.find(
                (providersInChannel: Integration) => {
                    return (
                        providersInChannel?.isTemplateImportAllowed &&
                        providersInChannel.providerId ===
                            integratedChannel.providerId
                    );
                },
            );
            return {
                ...integratedChannel,
                ...findOriginalProviderObject,
            };
        });
    } else {
        let findOriginalProviderObject = providersInChannels.find(
            (providersInChannel: Integration) => {
                return (
                    providersInChannel?.isTemplateImportAllowed &&
                    providersInChannel.providerId === integration.providerId
                );
            },
        );
        return [
            {
                ...integration,
                ...findOriginalProviderObject,
            },
        ];
    }
};

export const createIntegratedProviderObject = (
    providersInChannels: Integration[],
    integratedChannels: Integration[],
    isTemplateImportAllowedCheck: boolean = false,
) => {
    let finalObject: Integration[] = [];
    integratedChannels.forEach((integratedChannel: Integration) => {
        let findOriginalProviderObject = providersInChannels.find(
            (providersInChannel: Integration) => {
                return (
                    providersInChannel.providerId ===
                    integratedChannel.providerId
                );
            },
        );
        if (!isTemplateImportAllowedCheck) {
            finalObject.push({
                ...integratedChannel,
                ...findOriginalProviderObject,
            });
        } else {
            if (findOriginalProviderObject?.isTemplateImportAllowed) {
                finalObject.push({
                    ...integratedChannel,
                    ...findOriginalProviderObject,
                });
            }
        }
    });
    return finalObject.length > 0 ? finalObject : undefined;
};

// INFO - Kept the props as an object to make this more scalable for adding more props in future if required
/**
 * Function to check whether to allow edits for a particular campaign or not
 * @param param0 - Object containing executionTime and status of the campaign.
 * @param {Date | string | undefined | null} param0.executionTime - The scheduled execution time of the campaign, which can be a Date, string, null, or undefined.
 * @param {string | undefined} param0.status - The current status of the campaign, which can be of type CampaignStatus or undefined.
 * @returns {boolean} - Returns true if edits are allowed, false otherwise.
 */
export const allowCampaignEdits = ({
    executionTime,
    status,
}: {
    executionTime: Date | string | null | undefined;
    status: CampaignStatus | undefined;
}): boolean => {
    if (!executionTime && CampaignStatus.DRAFT !== status) return false;

    const currentDateTime = moment(new Date()).utc();
    const savedTime = moment(executionTime);
    // INFO - We are not allowing campaign edits if the status is not in draft/scheduled
    // In case of scheduled we'll allow edits only if the scheduled time is greater than 15mins from the currentTime
    // NOTE - Both the times here are compared in UTC
    if (status) {
        if (CampaignStatus.DRAFT === status) {
            return true;
        }
        if (CampaignStatus.SCHEDULED === status) {
            return (
                savedTime.valueOf() - currentDateTime.valueOf() >
                CAMPAIGN_EDIT_DELTA_GAP
            );
        }
    }
    return false;
};

/**
 * This function extracts the subject variables from the subject during campaign creation.
 * @param {string} - string containing subject.
 * @returns {TemplateVariableDetails} - Returns an object containing headerVariables,bodyVariables,footerVariables.
 */

export const extractSubjectVariables = (str: string) => {
    const regex = /{{(.*?)}}/g;
    const variables: string[] = [];
    let match;
    while ((match = regex.exec(str)) !== null) {
        variables.push(match[1].trim());
    }

    return variables.length ? variables : undefined;
};

// export const getRequiredSubscriptionGroups = (subscriptionGroups) => {
//     if (!subscriptionGroups) return []

//     return subscriptionGroups.reduce((accum, curr) => {
//         const requiredGroups = curr.groups.filter((group) => group.type === 'REQUIRED')
//         if (accum) return [...accum, ...requiredGroups]
//         return [...requiredGroups]
//     }, [])
// }
