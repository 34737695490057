import TableIcon from '@components/common/IconPack/TableIcon';
import Modal from '@components/common/modal/Modal';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import { isLeafTable, useFilteringTables } from '@hooks/useGetRelatedTables';
import { useLocale } from '@hooks/useLocale';
import useRelation from '@hooks/useRelation';
import {
    RelationTableType,
    type CompiledRelationTable,
} from '@lightdash/common';
import { ActionIcon, Box, Card, Flex, Text, TextInput } from '@mantine/core';
import { useCustomMetricContext } from '@providers/CustomMetricProvider';
import { useRelationContext } from '@providers/RelationProvider';
import Fuse from 'fuse.js';
import { useCallback, useState } from 'react';
import { ChevronRight, RefreshCw, Search } from 'react-feather';
import { useDebounce } from 'react-use';
import { GlobalStyles } from '../../mantineTheme';

const BaseTableField = ({
    table,
    isDisabled,
}: {
    table: CompiledRelationTable;
    isDisabled: boolean;
}) => {
    const {
        closeCustomMetricBaseTableModal,
        openCustomMetricManagerModal,
        selectTable,
        addBaseTableDetails,
    } = useCustomMetricContext((context) => context.actions);
    const [isHovered, setIsHovered] = useState(false);
    const { t } = useLocale();
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const handleSelectTable = useCallback(() => {
        if (!isDisabled) {
            selectTable(table);
            addBaseTableDetails(table);
            openCustomMetricManagerModal();
            closeCustomMetricBaseTableModal();
        }
    }, [
        closeCustomMetricBaseTableModal,
        openCustomMetricManagerModal,
        table,
        selectTable,
        isDisabled,
        addBaseTableDetails,
    ]);
    return (
        <Card
            onClick={() => handleSelectTable()}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={
                'border-b border-solid border-shade-4 text-sm font-semibold cursor-pointer flex flex-row justify-between'
            }
        >
            <Flex direction="row" gap="xs" align="center">
                <TableIcon
                    type={table.type ?? RelationTableType.RELATED}
                    strokeWidth={2.5}
                    color={
                        isDisabled
                            ? 'rgb(var(--color-gray-400)'
                            : GlobalStyles.tableStyles[
                                  table.type ?? RelationTableType.RELATED
                              ].textColor
                    }
                />
                <Text
                    className={
                        `text-sm font-medium ${
                            GlobalStyles.tableStyles[
                                table.type ?? RelationTableType.RELATED
                            ].textColorTailwind
                        } ` + (isDisabled ? '!text-gray-400' : '')
                    }
                >
                    {table.label}
                </Text>
            </Flex>
            <Flex align="center">
                {isHovered &&
                    (isDisabled ? (
                        <Text className="text-sm text-gray-400">
                            {t('custom_metric.create_custom_metric_disable')}
                        </Text>
                    ) : (
                        <ChevronRight
                            size={15}
                            color={'rgb(var(--color-gray-500))'}
                        />
                    ))}
            </Flex>
        </Card>
    );
};

const CustomMetricBaseTableModal = () => {
    const { t } = useLocale();
    const { showBaseTableModal } = useCustomMetricContext(
        (context) => context.reducerState,
    );
    const [search, setSearch] = useState('');
    const { closeCustomMetricBaseTableModal } = useCustomMetricContext(
        (context) => context.actions,
    );
    const { activeRelation, activeRelationUuid } = useRelationContext();
    const { refetch, isLoading, isRefetching } =
        useRelation(activeRelationUuid);
    const [searchDebounce, setSearchDebounce] = useState<string>('');
    const { availableTables, nonAvailableTables } =
        useFilteringTables(activeRelation);
    useDebounce(
        () => {
            setSearchDebounce(search);
        },
        300,
        [search],
    );

    const filterItems = useCallback(
        (data: CompiledRelationTable[]) => {
            let fields = data;

            if (searchDebounce !== '') {
                const fuse = new Fuse(data, {
                    keys: ['label', 'name', 'table'],
                    threshold: 0.3,
                });

                fields = fuse.search(searchDebounce).map((res) => res.item);
            }

            return fields;
        },
        [searchDebounce],
    );

    return (
        <Modal
            opened={showBaseTableModal}
            title={t('custom_metric_table_modal.title')}
            onClose={() => {
                closeCustomMetricBaseTableModal();
            }}
            size="xl"
        >
            <Text className="font-medium text-gray-800">
                {t('custom_metric_table_modal.table_title')}
            </Text>
            <Flex
                justify="space-between"
                className="border-t border-x border-b-none border-shade-6 rounded-t-md"
            >
                <TextInput
                    icon={
                        <Search
                            size={13}
                            color={'rgb(var(--color-gray-500))'}
                        />
                    }
                    sx={{
                        input: {
                            border: '0px',
                            borderRadius: '0px',
                        },
                    }}
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    placeholder={`${t('create_campaign.channel_search')} ${
                        Object.values(activeRelation?.tables ?? {}).length
                    } ${t('create_custom_metric.search_label')}`}
                    className="w-full text-gray-800"
                />
                <Flex align="center" className="mx-2">
                    <ActionIcon
                        onClick={() => {
                            refetch().catch((error) => {
                                console.log(error);
                            });
                        }}
                    >
                        <RefreshCw
                            size={13}
                            strokeWidth={2.5}
                            color={'rgb(var(--color-gray-600))'}
                            className={isRefetching ? 'animate-spin' : ''}
                        />
                    </ActionIcon>
                </Flex>
            </Flex>
            <Box className="border border-solid border-shade-6 h-[462px] overflow-scroll rounded-b-md flex flex-col">
                {isRefetching || isLoading ? (
                    <SuboptimalState />
                ) : (
                    <>
                        {filterItems(availableTables).map(
                            (eachTable, index) => (
                                <BaseTableField
                                    table={eachTable}
                                    isDisabled={false}
                                    key={index}
                                />
                            ),
                        )}
                        {filterItems(nonAvailableTables).map((eachTable) => (
                            <BaseTableField
                                table={eachTable}
                                isDisabled={isLeafTable(
                                    eachTable.name,
                                    activeRelation?.joinedTables,
                                )}
                                key={eachTable.name}
                            />
                        ))}
                    </>
                )}
            </Box>
        </Modal>
    );
};
export default CustomMetricBaseTableModal;
