import { sortmentApi } from '@api/index';
import useNotify from '@hooks/toaster/useNotify';
import { type ApiError, type ApiSshKeyPairResponse } from '@lightdash/common';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';

export const useCreateSshKeyPair = (
    options: UseMutationOptions<
        ApiSshKeyPairResponse[QueryKeys.RESULTS],
        ApiError
    >,
) => {
    const { showToastApiError } = useNotify();
    return useMutation<ApiSshKeyPairResponse['results'], ApiError>(
        async () =>
            sortmentApi({
                method: 'POST',
                url: '/ssh/key-pairs',
                body: undefined,
            }),
        {
            mutationKey: [QueryKeys.ACTIVE_SSH_KEY_PAIR],
            onError: ({ error }) => {
                showToastApiError({
                    title: 'Failed to create SSH keypair',
                    apiError: error,
                });
            },
            ...options,
        },
    );
};
