import {
    CampaignBuilderStep as CampaignBuilderStepEnum,
    CampaignContentStep as CampaignContentStepEnum,
    type CampaignBuilderStep,
    type CampaignContentStep,
} from '@components/Campaigns/Builder/types';
import { formatTimestamp } from '@components/common/Timestamp';
import useNotify from '@hooks/toaster/useNotify';
import { useCreateCampaign, useUpdateCampaign } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import useTimestamp from '@hooks/useTimestamp';
import {
    assertUnreachable,
    AudienceType,
    CampaignScheduleType,
    type Audience,
    type Campaign,
    type CampaignCreateRequest,
    type CampaignStatus,
    type CommunicationChannel,
    type FieldType,
    type Integration,
    type MaterializationFieldType,
} from '@lightdash/common';
import { type Editor } from 'grapesjs';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createContext, useContextSelector } from 'use-context-selector';
import { v4 } from 'uuid';

interface CurrentStepCallbackProps {
    callback: (() => void) | null;
    skipExecutionAfterCallback?: boolean;
}

export type TemplateDetails = Pick<
    Campaign['templateDetails'],
    'id' | 'mappings' | 'content'
>;

export type TrackedLinks = Record<
    string,
    {
        value: string;
        defaultValue: string;
        type: MaterializationFieldType;
        enableTracking?: boolean;
    }
>;

export type CsvUploadDetails = {
    uploadId: string;
    fileName: string;
    audienceCount: number;
};
interface CampaignContext {
    state: CampaignState;
    actions: {
        setCampaignAudience: (audience: Audience | null | undefined) => void;
        setCampaignName: (value: string) => void;
        setCampaignDescription: (value: string) => void;
        setCampaignScheduleType: (value: CampaignScheduleType) => void;
        setCurrentStep: (value: CampaignBuilderStep) => void;
        setSchedulerExecutionTime: (value: Date) => void;
        setCurrentStepCallback: ({
            callback,
            skipExecutionAfterCallback,
        }: CurrentStepCallbackProps) => void;
        setPreviousStepCallback: ({
            callback,
            skipExecutionAfterCallback,
        }: CurrentStepCallbackProps) => void;
        setCampaignChannelPayload: (channel: Integration | null) => void;
        setCampaignContentPayload: (
            content: Partial<TemplateDetails> | null,
        ) => void;
        setShowFooterButtons: (value: { next: boolean; back: boolean }) => void;
        setSendToVariableMapping: (value: {
            fieldKey: string;
            fieldType: FieldType;
        }) => void;
        mutateCampaign: () => Promise<void>;
        setSubscriptionGroup: (val: string) => void;
        setAudienceCsvData: (data: unknown[]) => void;
        setGrapesPreviewInstance: (editor: Editor) => void;
        setTrackedLinks: (link: TrackedLinks) => void;
        setUtmTracking: (utmObject: Record<string, string>) => void;
        setAudienceCsvUploadData: (
            payload: CsvUploadDetails | undefined,
        ) => void;
        setCampaignType: (type: AudienceType) => void;
    };
}

const Context = createContext<CampaignContext | undefined>(undefined);

export enum ActionType {
    SET_UNSAVED_CAMPAIGN_DATA,
    SET_AUDIENCE_PAYLOAD,
    SET_CHANNEL_PAYLOAD,
    SET_CONTENT_PAYLOAD,
    SET_SCHEDULER_EXECUTION_TIME,
    SET_CAMPAIGN_NAME,
    SET_CAMPAIGN_DESCRIPTION,
    SET_CURRENT_STEP,
    SET_SCHEDULE_TYPE,
    SET_CURRENT_STEP_CALLBACK,
    SET_PREVIOUS_STEP_CALLBACK,
    SET_SHOW_FOOTER_BUTTONS,
    SET_SEND_TO_VARIABLE_MAPPING,
    SET_SUBSCRIPTION_GROUP,
    SET_AUDIENCE_UPLOAD_LINK,
    SET_AUDIENCE_CSV_DATA,
    SET_GRAPES_PREVIEW_INSTANCE,
    SET_TRACKED_LINKS,
    SET_UTM_TRACKING,
    SET_AUDIENCE_CSV_UPLOAD_DATA,
    SET_CAMPAIGN_TYPE,
}

type Action =
    | {
          type: ActionType.SET_AUDIENCE_PAYLOAD;
          payload: Audience | null | undefined;
      }
    | { type: ActionType.SET_CHANNEL_PAYLOAD; payload: Integration | null }
    | {
          type: ActionType.SET_CONTENT_PAYLOAD;
          payload: Partial<TemplateDetails> | null;
      }
    | { type: ActionType.SET_SCHEDULER_EXECUTION_TIME; payload: Date }
    | { type: ActionType.SET_CAMPAIGN_NAME; payload: string }
    | { type: ActionType.SET_CAMPAIGN_DESCRIPTION; payload: string }
    | { type: ActionType.SET_CURRENT_STEP; payload: CampaignBuilderStep }
    | { type: ActionType.SET_SCHEDULE_TYPE; payload: CampaignScheduleType }
    | {
          type: ActionType.SET_CURRENT_STEP_CALLBACK;
          payload: CurrentStepCallbackProps;
      }
    | {
          type: ActionType.SET_PREVIOUS_STEP_CALLBACK;
          payload: CurrentStepCallbackProps;
      }
    | {
          type: ActionType.SET_SHOW_FOOTER_BUTTONS;
          payload: { next: boolean; back: boolean };
      }
    | {
          type: ActionType.SET_SEND_TO_VARIABLE_MAPPING;
          payload: {
              fieldKey: string;
              fieldType: FieldType;
          };
      }
    | { type: ActionType.SET_SUBSCRIPTION_GROUP; payload: string }
    | { type: ActionType.SET_AUDIENCE_UPLOAD_LINK; payload: string }
    | { type: ActionType.SET_AUDIENCE_CSV_DATA; payload: unknown[] }
    | { type: ActionType.SET_GRAPES_PREVIEW_INSTANCE; payload: Editor }
    | { type: ActionType.SET_TRACKED_LINKS; payload: TrackedLinks }
    | { type: ActionType.SET_UTM_TRACKING; payload: Record<string, string> }
    | {
          type: ActionType.SET_AUDIENCE_CSV_UPLOAD_DATA;
          payload: CsvUploadDetails | undefined;
      }
    | { type: ActionType.SET_CAMPAIGN_TYPE; payload: AudienceType };

export interface CampaignReducerState {
    campaignPayload: CampaignCreateRequest;
    currentStep: CampaignBuilderStep;
    currentStepCallback: (() => boolean) | null;
    previousStepCallback: (() => boolean) | null;
    showFooterButtons: {
        next: boolean;
        back: boolean;
    };
    audienceCsvData?: unknown[];
    editor: Editor | undefined;
}

export interface CampaignState extends CampaignReducerState {
    isEditMode: boolean | undefined;
    uuid: string | undefined;
    status: CampaignStatus | null;
    isNewMode: boolean | undefined;
    isValidStep: (step: CampaignBuilderStep | CampaignContentStep) => boolean;
}

function reducer(
    state: CampaignReducerState,
    action: Action,
): CampaignReducerState {
    switch (action.type) {
        case ActionType.SET_AUDIENCE_PAYLOAD: {
            if (state.campaignPayload.audienceType === AudienceType.WAREHOUSE) {
                return {
                    ...state,
                    campaignPayload: {
                        ...state.campaignPayload,
                        audienceId: action.payload?.id as string,
                    },
                };
            }
            return state;
        }
        case ActionType.SET_CHANNEL_PAYLOAD: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    communicationDetails: action.payload
                        ? {
                              ...state.campaignPayload.communicationDetails,
                              eventId: '',
                              id: action.payload?.integrationId || '',
                              providerId: action.payload?.providerId || '',
                              channel: action.payload
                                  ?.channelName as CommunicationChannel,
                          }
                        : {
                              id: '',
                              providerId: '',
                              eventId: '',
                          },
                },
            };
        }
        case ActionType.SET_CONTENT_PAYLOAD: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    templateDetails: action.payload
                        ? {
                              //   id: '', //FIXME: Added this to avoid TS error
                              //   mappings: {}, //FIXME: Added this to avoid TS error
                              ...state.campaignPayload.templateDetails,
                              ...action.payload,
                          }
                        : {
                              id: '',
                              mappings: {},
                          },
                },
            };
        }
        case ActionType.SET_SCHEDULER_EXECUTION_TIME: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    schedule: {
                        type: CampaignScheduleType.DELAYED,
                        executionTime: action.payload,
                    },
                },
            };
        }
        case ActionType.SET_CAMPAIGN_NAME: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    name: action.payload,
                },
            };
        }
        case ActionType.SET_CAMPAIGN_DESCRIPTION: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    description: action.payload,
                },
            };
        }

        case ActionType.SET_CURRENT_STEP: {
            return {
                ...state,
                currentStep: action.payload,
            };
        }

        case ActionType.SET_SCHEDULE_TYPE: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    schedule: {
                        type: action.payload,
                        executionTime: null,
                    },
                },
            };
        }

        case ActionType.SET_CURRENT_STEP_CALLBACK: {
            const callbackFn = () => {
                if (action.payload.callback) {
                    action.payload.callback();
                }
                return action.payload.skipExecutionAfterCallback || false;
            };
            return {
                ...state,
                currentStepCallback: callbackFn,
            };
        }

        case ActionType.SET_PREVIOUS_STEP_CALLBACK: {
            const callbackFn = () => {
                if (action.payload.callback) {
                    action.payload.callback();
                }
                return action.payload.skipExecutionAfterCallback || false;
            };
            return {
                ...state,
                previousStepCallback: callbackFn,
            };
        }

        case ActionType.SET_SHOW_FOOTER_BUTTONS: {
            return {
                ...state,
                showFooterButtons: action.payload,
            };
        }

        case ActionType.SET_SEND_TO_VARIABLE_MAPPING: {
            const channel = state.campaignPayload.communicationDetails.channel;
            if (!channel) return state;
            const sendToMapping = {
                [channel]: action.payload.fieldKey,
                type: action.payload.fieldType,
            };
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    communicationDetails: {
                        ...state.campaignPayload.communicationDetails,
                        sendTo: sendToMapping,
                    },
                },
            };
        }

        case ActionType.SET_SUBSCRIPTION_GROUP: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    communicationDetails: {
                        ...state.campaignPayload.communicationDetails,
                        subscriptionGroupId: action.payload,
                    },
                },
            };
        }

        case ActionType.SET_GRAPES_PREVIEW_INSTANCE: {
            return {
                ...state,
                editor: action.payload,
            };
        }

        case ActionType.SET_TRACKED_LINKS: {
            if (state.campaignPayload.templateDetails) {
                return {
                    ...state,
                    campaignPayload: {
                        ...state.campaignPayload,
                        templateDetails: {
                            ...state.campaignPayload.templateDetails,
                            mappings: {
                                ...state.campaignPayload.templateDetails
                                    ?.mappings,
                                ...action.payload,
                            },
                        },
                    },
                };
            }
            return { ...state };
        }

        case ActionType.SET_UTM_TRACKING: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    campaignTrackingParameters: action.payload,
                },
            };
        }

        case ActionType.SET_AUDIENCE_CSV_DATA: {
            return {
                ...state,
                audienceCsvData: action.payload,
            };
        }

        case ActionType.SET_AUDIENCE_CSV_UPLOAD_DATA: {
            if (state.campaignPayload.audienceType === AudienceType.CSV) {
                return {
                    ...state,
                    campaignPayload: {
                        ...state.campaignPayload,
                        csvUploadDetails: action.payload as CsvUploadDetails,
                    },
                };
            }
            return state;
        }

        case ActionType.SET_CAMPAIGN_TYPE: {
            return {
                ...state,
                campaignPayload: {
                    ...state.campaignPayload,
                    // INFO - Typecasting this as any because if we change the types here it affects the types at BE
                    // and we can't modify the types on BE due to some tsoa errors
                    audienceType: action.payload as any, // FIX THIS TypeError
                },
            };
        }

        default: {
            return assertUnreachable(
                action,
                'Unexpected action in explore reducer',
            );
        }
    }
}

export const CampaignProvider: React.FC<
    React.PropsWithChildren<
        Pick<CampaignState, 'isEditMode' | 'uuid' | 'isNewMode'> & {
            initialState: CampaignReducerState;
        }
    >
> = ({ isEditMode, children, initialState, uuid, isNewMode }) => {
    const [reducerState, dispatch] = useReducer(reducer, initialState);

    const {
        mutateAsync: createCampaignMutation,
        isLoading: createCampaignLoading,
        isSuccess: createCampaignSuccess,
    } = useCreateCampaign();
    const { showToastSuccess, closeToast } = useNotify();
    const history = useHistory();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const { getTimestamp } = useTimestamp();
    const [loadingToastKey, setLoadingToastKey] = useState<string | null>(v4());
    const formattedTime = useMemo(() => {
        return formatTimestamp(
            getTimestamp(
                reducerState.campaignPayload.schedule?.executionTime?.toString() ??
                    '',
            ),
        );
    }, [reducerState.campaignPayload.schedule?.executionTime, getTimestamp]);
    const subTitle = useMemo(() => {
        return reducerState.campaignPayload.schedule?.type ===
            CampaignScheduleType.MANUAL
            ? t('campaign.create.loading_subtitle_manual')
            : t('campaign.create.loading_subtitle_scheduled', {
                  time: formattedTime,
              });
    }, [formattedTime, reducerState.campaignPayload.schedule?.type, t]);
    useEffect(() => {
        if (createCampaignLoading) {
            showToastSuccess({
                key: loadingToastKey ?? '',
                title: t('campaign.create.loading'),
                loading: true,
                subtitle: subTitle,
            });
        } else if (createCampaignSuccess && loadingToastKey) {
            closeToast(loadingToastKey);
            setLoadingToastKey(null);
            showToastSuccess({
                title: t('campaign.create.success'),
                subtitle: subTitle,
            });
            history.push(`/projects/${projectUuid}/campaigns`);
        }
    }, [
        createCampaignLoading,
        createCampaignSuccess,
        loadingToastKey,
        reducerState.campaignPayload.schedule?.type,
        showToastSuccess,
        closeToast,
        history,
        projectUuid,
        t,
        uuid,
        reducerState.campaignPayload.schedule?.executionTime,
        getTimestamp,
        formattedTime,
        subTitle,
    ]);

    const { mutateAsync: updateCampaignMutation } = useUpdateCampaign(
        uuid ?? '',
        true,
    );

    const setCampaignAudience = useCallback(
        (audience: Audience | null | undefined) => {
            dispatch({
                type: ActionType.SET_AUDIENCE_PAYLOAD,
                payload: audience,
            });
        },
        [],
    );

    const setCampaignName = useCallback((value: string) => {
        dispatch({
            type: ActionType.SET_CAMPAIGN_NAME,
            payload: value,
        });
    }, []);

    const setCampaignDescription = useCallback((value: string) => {
        dispatch({
            type: ActionType.SET_CAMPAIGN_DESCRIPTION,
            payload: value,
        });
    }, []);

    const setCampaignScheduleType = useCallback(
        (value: CampaignScheduleType) => {
            dispatch({
                type: ActionType.SET_SCHEDULE_TYPE,
                payload: value,
            });
        },
        [],
    );

    const setPreviousStepCallback = useCallback(
        ({
            callback,
            skipExecutionAfterCallback,
        }: CurrentStepCallbackProps) => {
            dispatch({
                type: ActionType.SET_PREVIOUS_STEP_CALLBACK,
                payload: {
                    callback,
                    skipExecutionAfterCallback,
                },
            });
        },
        [],
    );

    const setCurrentStepCallback = useCallback(
        ({
            callback,
            skipExecutionAfterCallback,
        }: CurrentStepCallbackProps) => {
            dispatch({
                type: ActionType.SET_CURRENT_STEP_CALLBACK,
                payload: {
                    callback,
                    skipExecutionAfterCallback,
                },
            });
        },
        [],
    );

    const setCurrentStep = useCallback(
        (value: CampaignBuilderStep) => {
            setCurrentStepCallback({
                callback: null,
                skipExecutionAfterCallback: false,
            });
            setPreviousStepCallback({
                callback: null,
                skipExecutionAfterCallback: false,
            });
            dispatch({
                type: ActionType.SET_CURRENT_STEP,
                payload: value,
            });
        },
        [setCurrentStepCallback, setPreviousStepCallback],
    );

    const setSchedulerExecutionTime = useCallback((value: Date) => {
        dispatch({
            type: ActionType.SET_SCHEDULER_EXECUTION_TIME,
            payload: value, //TODO: Check the timestamp here
        });
    }, []);

    const setCampaignChannelPayload = useCallback(
        (channel: Integration | null) => {
            dispatch({
                type: ActionType.SET_CHANNEL_PAYLOAD,
                payload: channel,
            });
        },
        [],
    );

    const setCampaignContentPayload = useCallback(
        (content: Partial<TemplateDetails> | null) => {
            dispatch({
                type: ActionType.SET_CONTENT_PAYLOAD,
                payload: content,
            });
        },
        [],
    );

    const setShowFooterButtons = useCallback(
        (value: { next: boolean; back: boolean }) => {
            dispatch({
                type: ActionType.SET_SHOW_FOOTER_BUTTONS,
                payload: value,
            });
        },
        [],
    );

    const setSendToVariableMapping = useCallback(
        (value: { fieldKey: string; fieldType: FieldType }) => {
            dispatch({
                type: ActionType.SET_SEND_TO_VARIABLE_MAPPING,
                payload: value,
            });
        },
        [],
    );

    const setSubscriptionGroup = useCallback((payload: string) => {
        dispatch({
            type: ActionType.SET_SUBSCRIPTION_GROUP,
            payload,
        });
    }, []);

    const setAudienceCsvUploadData = useCallback(
        (payload: CsvUploadDetails | undefined) => {
            dispatch({
                type: ActionType.SET_AUDIENCE_CSV_UPLOAD_DATA,
                payload,
            });
        },
        [],
    );

    const setGrapesPreviewInstance = useCallback((payload: Editor) => {
        dispatch({
            type: ActionType.SET_GRAPES_PREVIEW_INSTANCE,
            payload,
        });
    }, []);

    const setAudienceCsvData = useCallback((payload: unknown[]) => {
        dispatch({
            type: ActionType.SET_AUDIENCE_CSV_DATA,
            payload,
        });
    }, []);

    const setTrackedLinks = useCallback((payload: any) => {
        dispatch({
            type: ActionType.SET_TRACKED_LINKS,
            payload,
        });
    }, []);

    const setUtmTracking = useCallback((payload: Record<string, string>) => {
        dispatch({
            type: ActionType.SET_UTM_TRACKING,
            payload,
        });
    }, []);

    const setCampaignType = useCallback((payload: AudienceType) => {
        dispatch({
            type: ActionType.SET_CAMPAIGN_TYPE,
            payload,
        });
    }, []);

    const mutateCampaign = useCallback(async () => {
        if (!isEditMode) return;
        if (isNewMode) {
            await createCampaignMutation(reducerState.campaignPayload);
            return;
        }
        await updateCampaignMutation(reducerState.campaignPayload);
    }, [
        isEditMode,
        isNewMode,
        updateCampaignMutation,
        reducerState.campaignPayload,
        createCampaignMutation,
    ]);

    const isValidStep = useCallback(
        (step: CampaignBuilderStep | CampaignContentStep) => {
            switch (step) {
                case CampaignBuilderStepEnum.AUDIENCE:
                    return (
                        reducerState.campaignPayload.audienceType ===
                            AudienceType.WAREHOUSE &&
                        !!reducerState.campaignPayload.audienceId
                    );
                case CampaignBuilderStepEnum.CHANNEL:
                    return !!reducerState.campaignPayload.communicationDetails
                        .providerId;
                case CampaignBuilderStepEnum.CONTENT:
                    return !!reducerState.campaignPayload.templateDetails?.id;
                case CampaignBuilderStepEnum.SCHEDULE:
                    return !!reducerState.campaignPayload.schedule
                        ?.executionTime;
                case CampaignContentStepEnum.SETUP:
                    return true;
                case CampaignContentStepEnum.UTM_PARAMETERS:
                    return !!reducerState.campaignPayload.communicationDetails
                        .sendTo;
                case CampaignContentStepEnum.TRACK:
                    return reducerState.campaignPayload
                        .campaignTrackingParameters &&
                        Object.keys(
                            reducerState.campaignPayload
                                .campaignTrackingParameters,
                        ).length > 0
                        ? true
                        : false;
                case CampaignContentStepEnum.PERSONALISE:
                    return !!reducerState.campaignPayload.communicationDetails
                        .sendTo;
                case CampaignContentStepEnum.TEST:
                    return !!reducerState.campaignPayload.communicationDetails
                        .sendTo;
                default:
                    return false;
            }
            return false;
        },
        [reducerState],
    );

    const state = useMemo(
        () => ({
            ...reducerState,
            isEditMode,
            uuid,
            status: null,
            isNewMode,
            isValidStep,
        }),
        [isEditMode, reducerState, uuid, isNewMode, isValidStep],
    );

    const actions = useMemo(
        () => ({
            setCampaignAudience,
            setCampaignName,
            setCampaignDescription,
            setCampaignScheduleType,
            setCurrentStep,
            setSchedulerExecutionTime,
            setCurrentStepCallback,
            setPreviousStepCallback,
            setCampaignChannelPayload,
            setCampaignContentPayload,
            setShowFooterButtons,
            setSendToVariableMapping,
            mutateCampaign,
            setSubscriptionGroup,
            setAudienceCsvUploadData,
            setAudienceCsvData,
            setGrapesPreviewInstance,
            setTrackedLinks,
            setUtmTracking,
            setCampaignType,
        }),
        [
            setCampaignAudience,
            setCampaignName,
            setCampaignDescription,
            setCampaignScheduleType,
            setCurrentStep,
            setSchedulerExecutionTime,
            setCurrentStepCallback,
            setPreviousStepCallback,
            setCampaignChannelPayload,
            setCampaignContentPayload,
            setShowFooterButtons,
            setSendToVariableMapping,
            mutateCampaign,
            setSubscriptionGroup,
            setAudienceCsvUploadData,
            setAudienceCsvData,
            setGrapesPreviewInstance,
            setTrackedLinks,
            setUtmTracking,
            setCampaignType,
        ],
    );

    const value: CampaignContext = useMemo(
        () => ({
            state,
            actions,
        }),
        [state, actions],
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useCampaignContext<Selected>(
    selector: (value: CampaignContext) => Selected,
) {
    return useContextSelector(Context, (context) => {
        if (context === undefined) {
            throw new Error(
                'useCampaign must be used within a CampaignProvider',
            );
        }
        return selector(context);
    });
}
