import { type ContentStepComponentProps } from '@components/Campaigns/Builder/types';
import { getPreviousContentStep } from '@components/Campaigns/Builder/utils';
import Modal from '@components/common/modal/Modal';
import { useTestCampaign } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import useUser from '@hooks/user/useUser';
import { useGetTemplateById } from '@hooks/useTemplate';
import {
    CommunicationChannel,
    CommunicationChannelName,
    type Primitive,
    type TestEmailRequest,
} from '@lightdash/common';
import { Box, Button, Group, Text, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretRight } from '@phosphor-icons/react';
import { useCampaignContext } from '@providers/CampaignProvider';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Send } from 'react-feather';
import { ButtonVariant } from '../../../../../../mantineTheme';

const TestCommunication: React.FC<ContentStepComponentProps> = ({
    setActiveContentStep,
    activeContentStep,
}) => {
    const [isModalOpen, { open: openModal, close: closeModal }] =
        useDisclosure();
    const { t } = useLocale();

    const { setPreviousStepCallback } = useCampaignContext(
        (context) => context.actions,
    );
    const { campaignPayload } = useCampaignContext((context) => context.state);
    const { communicationDetails, templateDetails } = campaignPayload;

    const { mutateAsync: triggerTestMutation, isLoading } = useTestCampaign(
        communicationDetails?.providerId,
        communicationDetails?.id,
    );

    const { data: templateMetadata, isFetching: isTemplateMetadataLoading } =
        useGetTemplateById(templateDetails?.id ?? '');

    const [recipientComm, setRecipientComm] = useState<string>('');

    const { data: user } = useUser(true);

    useEffect(() => {
        if (
            communicationDetails?.channel === CommunicationChannel.EMAIL &&
            user?.email
        ) {
            setRecipientComm(user.email);
        }
    }, [communicationDetails?.channel, user]);

    useEffect(() => {
        const prevStep = getPreviousContentStep(
            activeContentStep,
            communicationDetails.channel,
            templateMetadata?.contents[0].parsedVariables,
        );
        const handlePrevStep = () => {
            if (prevStep) {
                setActiveContentStep(prevStep);
            }
            return;
        };

        setPreviousStepCallback({
            callback: handlePrevStep,
            skipExecutionAfterCallback: true,
        });
    }, [
        activeContentStep,
        setActiveContentStep,
        setPreviousStepCallback,
        communicationDetails,
        templateMetadata,
    ]);

    const triggerTestCommunication = useCallback(async () => {
        if (!recipientComm) return;
        if (!campaignPayload.communicationDetails.channel) return;

        const mappings = campaignPayload.templateDetails?.mappings;

        if (!isEmpty(templateDetails?.mappings) && isEmpty(mappings)) return;
        if (!mappings) return;
        const variableMappings: { [key: string]: Primitive } = Object.keys(
            mappings,
        ).reduce((acc: Record<string, string>, key) => {
            acc[key] = mappings[key].defaultValue;
            return acc;
        }, {});
        const payload: TestEmailRequest = {
            to: {
                [campaignPayload.communicationDetails.channel]: recipientComm,
            },
            channel: campaignPayload.communicationDetails.channel,
            mappings: variableMappings,
            templateContent:
                templateDetails?.content && !isEmpty(templateDetails?.content)
                    ? { ...templateDetails?.content }
                    : templateMetadata?.contents?.[0]?.content
                    ? { ...templateMetadata?.contents?.[0]?.content }
                    : {
                          subject: '',
                          html: '',
                      },
            templateDetails: {
                name: templateMetadata?.name || '',
            },
        };
        await triggerTestMutation({ payload });
        closeModal();
    }, [
        recipientComm,
        campaignPayload,
        templateDetails,
        templateMetadata,
        triggerTestMutation,
        closeModal,
    ]);

    const sendTestCommTitle = useMemo(() => {
        return t('campaigns_builder.send_test_communication', {
            channel: campaignPayload.communicationDetails.channel
                ? CommunicationChannelName[
                      campaignPayload.communicationDetails.channel
                  ]
                : '',
        });
    }, [campaignPayload.communicationDetails.channel, t]);

    const sendTestCommInputLabel = useMemo(() => {
        return t('campaigns_builder.send_test_communication_input_label', {
            channel: campaignPayload.communicationDetails.channel
                ? CommunicationChannelName[
                      campaignPayload.communicationDetails.channel
                  ]
                : '',
        });
    }, [campaignPayload.communicationDetails.channel, t]);

    return (
        <>
            <Box>
                <Text className="text-sm text-gray-800">
                    Test your campaign by sending it to yourself.
                </Text>

                <Button
                    variant={ButtonVariant.OUTLINED_ACCENTED}
                    leftIcon={
                        <Send
                            size={13}
                            strokeWidth={2.5}
                            color={'rgb(var(--color-blu-800))'}
                        />
                    }
                    className="mt-6 font-semibold"
                    onClick={openModal}
                >
                    {sendTestCommTitle}
                </Button>
            </Box>
            <Modal
                opened={isModalOpen}
                onClose={closeModal}
                keepMounted={false}
                title={sendTestCommTitle}
                size="lg"
                footerRightSection={
                    <Group className="flex gap-1.5">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={closeModal}
                            disabled={isLoading || isTemplateMetadataLoading}
                        >
                            {t('common.cancel')}
                        </Button>

                        <Button
                            disabled={isLoading}
                            type="submit"
                            rightIcon={
                                <CaretRight color={'white'} weight="regular" />
                            }
                            onClick={() => triggerTestCommunication()}
                        >
                            {sendTestCommTitle}
                        </Button>
                    </Group>
                }
            >
                <Box>
                    <TextInput
                        label={sendTestCommInputLabel}
                        placeholder={sendTestCommInputLabel}
                        disabled={isLoading || isTemplateMetadataLoading}
                        required
                        withAsterisk={false}
                        onChange={(e) =>
                            setRecipientComm(e.currentTarget.value)
                        }
                        value={recipientComm}
                    />
                </Box>
            </Modal>
        </>
    );
};

export default React.memo(TestCommunication);
