import { useLocale } from '@hooks/useLocale';
import { BlobStorageType } from '@lightdash/common';
import { Stack, TextInput } from '@mantine/core';
import { isValidBucketName } from '@utils/fieldValidators';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const GCPStorage: React.FC<{ disabled: boolean }> = ({ disabled }) => {
    const { register, setValue } = useFormContext();
    const { t } = useLocale();
    useEffect(() => {
        setValue(
            'blobStorageConfig.blobStorageType',
            BlobStorageType.GoogleCloudStorage,
        );
    }, [setValue]);
    return (
        <Stack>
            <TextInput
                label={t('gcs_form.bucket_label')}
                required
                {...register('blobStorageConfig.bucket', {
                    validate: {
                        isValidBucketName: isValidBucketName('Bucket Name'),
                    },
                })}
                disabled={disabled}
                labelProps={{ style: { marginTop: '8px' } }}
                placeholder={t('s3_form.bucket_placeholder')}
            />
        </Stack>
    );
};
export default GCPStorage;
