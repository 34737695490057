import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, Flex, Group, Loader, Menu } from '@mantine/core';
import { MagnifyingGlass } from '@phosphor-icons/react';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { FixedSizeList } from 'react-window';
import { ButtonVariant } from '../../../../mantineTheme';
import {
    type AddditionalPropertySelectListProps,
    type BasePropertySelectPropsWithMultipleSelection,
    type PropertySelectListType,
    type PropertySelectProps,
} from './type';

const PropertySelect = <T, K extends string = string>({
    items,
    showGroup,
    headerRightSection,
    onSubmit,
    itemTemplate,
    targetButton,
    close,
    opened,
    open,
    withinPortal = false,
    showAllItemsGroup = true,
    ...props
}: PropertySelectProps<T, K>) => {
    const { isLoading } = props as BasePropertySelectPropsWithMultipleSelection<
        T,
        K
    >;
    const { t } = useLocale();
    const [search, setSearch] = useState<string>('');
    const [searchDebounce, setSearchDebounce] = useState<string>('');
    const [selectedGroup, setSelectedGroup] = useState<K | undefined>(
        undefined,
    );

    useEffect(() => {
        setSelectedGroup(
            showAllItemsGroup ? undefined : items?.[0]?.groupKey ?? undefined,
        );
    }, [items, showAllItemsGroup]);

    const [propertyItems, setPropertyItems] =
        useState<PropertySelectListType<T, K>[]>(items);
    useDebounce(() => setSearchDebounce(search), 300, [search]);
    useEffect(() => {
        if (!opened) {
            setSearch('');
            setSearchDebounce('');
        }
    }, [opened]);
    useEffect(() => {
        if (
            !props.allowMultipleSelection ||
            (props.allowMultipleSelection && !isLoading)
        ) {
            setPropertyItems(items);
        }
    }, [items, props.allowMultipleSelection, isLoading]);
    const eachGroupSelectedItemsCount = useMemo(() => {
        return propertyItems.reduce((counts, group) => {
            counts[group.groupKey] = group.items.reduce(
                (count, item) => count + (item.isChecked ? 1 : 0),
                0,
            );
            return counts;
        }, {} as Record<string, number>);
    }, [propertyItems]);

    const filteredItems = useMemo(() => {
        let data = propertyItems
            .filter(
                (group) => !selectedGroup || group.groupKey === selectedGroup,
            )
            .flatMap((group) => group.items);
        if (props.showSearch && searchDebounce) {
            const fuse = new Fuse(data, {
                keys: props.searchKeys,
                threshold: 0.3,
            });
            data = fuse.search(searchDebounce).map((result) => result.item);
        }
        return data;
    }, [propertyItems, searchDebounce, props, selectedGroup]);

    const toggleSelection = useCallback(
        (item: T & AddditionalPropertySelectListProps) => {
            setPropertyItems((prevItems) =>
                prevItems.map((group) => ({
                    ...group,
                    items: group.items.map((groupItem) =>
                        groupItem === item
                            ? { ...groupItem, isChecked: !groupItem.isChecked }
                            : groupItem,
                    ),
                })),
            );
        },
        [],
    );

    const isSelectButtonDisabled = useMemo(() => {
        return _.isEqual(items, propertyItems);
    }, [items, propertyItems]);

    const totalSelectedProperties = useMemo(() => {
        return Object.values(eachGroupSelectedItemsCount).reduce(
            (sum, count) => sum + count,
            0,
        );
    }, [eachGroupSelectedItemsCount]);

    const EachProperty = useCallback(
        ({ index, style }: { index: number; style: React.CSSProperties }) => {
            const item = filteredItems[index];
            return (
                <Box
                    style={style}
                    className={
                        item.isDisabled
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                    }
                    onClick={() => {
                        if (item.isDisabled) return;
                        if (props.allowMultipleSelection) {
                            toggleSelection(item);
                        } else {
                            onSubmit([item]);
                        }
                    }}
                >
                    {itemTemplate({ item, groupKey: selectedGroup })}
                </Box>
            );
        },
        [
            filteredItems,
            itemTemplate,
            onSubmit,
            props.allowMultipleSelection,
            toggleSelection,
            selectedGroup,
        ],
    );

    return (
        <Menu
            opened={opened}
            onOpen={open}
            onClose={close}
            position="bottom-start"
            width={454}
            withinPortal={withinPortal}
        >
            <Menu.Target>
                <Box className="w-fit">{targetButton}</Box>
            </Menu.Target>
            <Menu.Dropdown>
                <Box>
                    <Flex
                        justify="space-between items-center"
                        className="border-b border-shade-6"
                    >
                        <Box className="m-2.5 grow">
                            {props.showSearch && (
                                <SearchInput
                                    icon={
                                        <MagnifyingGlass
                                            color={'rgb(var(--color-gray-500))'}
                                        />
                                    }
                                    variant="unstyled"
                                    placeholder={
                                        props.searchPlaceholder ??
                                        t(
                                            'property_select_type.search_placeholder',
                                        )
                                    }
                                    className="w-full "
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            )}
                        </Box>
                        {headerRightSection}
                    </Flex>
                    <Group
                        className={`flex items-start gap-0 ${
                            showGroup ? 'pl-2' : ''
                        } ${
                            props.allowMultipleSelection && props.isLoading
                                ? 'opacity-50 pointer-events-none'
                                : ''
                        }`}
                    >
                        {showGroup && (
                            <Box className="pt-3 basis-1/3">
                                <ul className="flex flex-col gap-2">
                                    {showAllItemsGroup && (
                                        <li
                                            key="ALL"
                                            className={`rounded-lg cursor-pointer flex flex-col items-start p-1 text-sm me-1 ${
                                                !selectedGroup
                                                    ? 'bg-gray-200'
                                                    : 'bg-white hover:bg-gray-50'
                                            }`}
                                            onClick={() =>
                                                setSelectedGroup(undefined)
                                            }
                                        >
                                            <Box className="flex justify-between w-full">
                                                <Box
                                                    className={`text-sm font-semibold ps-1 ${
                                                        !selectedGroup
                                                            ? 'text-gray-800'
                                                            : 'text-gray-700'
                                                    }`}
                                                >
                                                    {t(
                                                        'property_select_type.all_table_label',
                                                    )}
                                                </Box>
                                                {props.allowMultipleSelection &&
                                                    totalSelectedProperties >
                                                        0 && (
                                                        <Box className="flex text-gray-700 pe-1 align-center">
                                                            {
                                                                totalSelectedProperties
                                                            }
                                                        </Box>
                                                    )}
                                            </Box>
                                        </li>
                                    )}
                                    {items.map((group) => (
                                        <li
                                            key={group.groupKey}
                                            className={`rounded-lg cursor-pointer flex flex-col items-start p-1 me-1 ${
                                                selectedGroup === group.groupKey
                                                    ? 'bg-gray-200'
                                                    : 'bg-white hover:bg-gray-50'
                                            }`}
                                            onClick={() =>
                                                setSelectedGroup(group.groupKey)
                                            }
                                        >
                                            <Box className="flex justify-between w-full">
                                                <Box className="flex flex-row items-center gap-1.5">
                                                    {group.groupIcon}
                                                    <Box
                                                        className={`text-sm font-semibold ${
                                                            selectedGroup ===
                                                            group.groupKey
                                                                ? 'text-gray-800'
                                                                : 'text-gray-700'
                                                        }`}
                                                    >
                                                        {group.groupLabel}
                                                    </Box>
                                                </Box>
                                                {props.allowMultipleSelection && (
                                                    <Box className="flex p-1 text-gray-700 align-center">
                                                        {eachGroupSelectedItemsCount[
                                                            group.groupKey
                                                        ] > 0 &&
                                                            eachGroupSelectedItemsCount[
                                                                group.groupKey
                                                            ]}
                                                    </Box>
                                                )}
                                            </Box>
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        )}
                        <Box
                            className={`flex-1 overflow-scroll ${
                                showGroup ? 'border-l border-shade-6' : ''
                            }`}
                        >
                            <Box className="!p-0">
                                <FixedSizeList
                                    height={240}
                                    itemCount={filteredItems.length}
                                    itemSize={45}
                                    width="100%"
                                    className="List !overflow-x-hidden"
                                >
                                    {EachProperty}
                                </FixedSizeList>
                            </Box>
                        </Box>
                    </Group>
                    {props.allowMultipleSelection && (
                        <Box className="flex justify-end p-2 border-t border-shade-6">
                            <Button
                                variant={ButtonVariant.FILLED}
                                leftIcon={
                                    props.isLoading ? (
                                        <Loader color="white" size={14} />
                                    ) : null
                                }
                                onClick={() => {
                                    if (totalSelectedProperties > 0) {
                                        onSubmit(
                                            propertyItems
                                                .flatMap((group) => group.items)
                                                .filter(
                                                    (item) => item.isChecked,
                                                ),
                                        );
                                    }
                                }}
                                disabled={
                                    props.isLoading || isSelectButtonDisabled
                                }
                            >
                                {props.isLoading
                                    ? t('audience_preview.select_saving_state')
                                    : t('audience_preview.select')}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Menu.Dropdown>
        </Menu>
    );
};

export default PropertySelect;
