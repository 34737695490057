import Select from '@components/common/Select';
import InviteSuccess from '@components/UserSettings/InviteSuccess';
import { useCreateInviteLinkMutation } from '@hooks/useInviteLink';
import { useLocale } from '@hooks/useLocale';
import { useOrganizationUsers } from '@hooks/useOrganizationUsers';
import {
    useCreateProjectAccessMutation,
    useProjectAccess,
} from '@hooks/useProjectAccess';
import {
    OrganizationMemberRole,
    ProjectMemberRole,
    ProjectMemberRoleDescription,
    ProjectMemberRoleLabels,
    validateEmail,
    type CreateProjectMember,
    type InviteLink,
} from '@lightdash/common';
import { Box, Button, Group, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useApp } from '@providers/AppProvider';
import { useProjectContext } from '@providers/ProjectProvider';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Check, ChevronRight } from 'react-feather';
import { ButtonVariant } from '../../../mantineTheme';
import Modal from '../../common/modal/Modal';
import { ProjectMemberRoleFilter } from './types';
interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    label: string;
    description: string;
}
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, description, ...others }: ItemProps, ref) => (
        <Box ref={ref} {...others}>
            <Box className="flex justify-between">
                <Text className="text-sm font-medium text-gray-800">
                    {label}
                </Text>

                <Check
                    size={13}
                    strokeWidth={2.5}
                    color={'rgb(var(--color-gray-600))'}
                    className="selected__item--icon-selected"
                />
            </Box>
            <Text className="text-sm text-gray-600 break-normal">
                {description}
            </Text>
        </Box>
    ),
);
interface InviteMemberModalProps {
    projectUuid: string;
    onClose: () => void;
    opened: boolean;
}

const InviteMemberModal: React.FC<InviteMemberModalProps> = ({
    projectUuid,
    onClose,
    opened,
}) => {
    const { t } = useLocale();
    const { data: organizationUsers } = useOrganizationUsers();
    const { mutateAsync: createMutation, isLoading } =
        useCreateProjectAccessMutation(projectUuid);

    const {
        mutateAsync: inviteMutation,
        data: inviteLinkForOrg,
        isLoading: isInvitationLoading,
        reset,
    } = useCreateInviteLinkMutation();

    const { projectData } = useProjectContext();
    const form = useForm<Pick<CreateProjectMember, 'email' | 'role'>>({
        initialValues: {
            email: '',
            role: ProjectMemberRole.VIEWER,
        },
    });
    const [addNewMember, setAddNewMember] = useState<boolean>(false);
    const [inviteLink, setInviteLink] = useState<InviteLink | undefined>();
    const [emailOptions, setEmailOptions] = useState<string[]>([]);
    const { data: users } = useProjectAccess(projectUuid as string);
    const { user } = useApp();
    useEffect(() => {
        if (organizationUsers) {
            setEmailOptions(organizationUsers.map(({ email }) => email));
        }
        if (inviteLinkForOrg) setInviteLink(inviteLinkForOrg);
    }, [organizationUsers, inviteLinkForOrg]);

    const handleSubmit = async (
        formData: Pick<CreateProjectMember, 'email' | 'role'>,
    ) => {
        if (addNewMember) {
            await inviteMutation({
                email: formData.email,
                role: OrganizationMemberRole.MEMBER,
            });
            await createMutation({
                ...formData,
                sendEmail: false,
            });
            setAddNewMember(false);

            reset();
            form.reset();
        } else {
            await createMutation({
                ...formData,
                sendEmail: true,
            });
            form.reset();
            onClose();
        }
    };
    // FIXME: Role access is currently handled on the frontend, but it should be in ability rules from the backend.
    // To address this issue, we have a ticket on backend: https://shopflo.atlassian.net/browse/SORT-516
    //If we have role hierarchy in the ability rules we just filter the role before got displayed.
    const roleAccess = useCallback(() => {
        if (!users || !user || !user.data) return ProjectMemberRoleFilter;

        const presentUserRole = users.find(
            (obj) => obj.userUuid === user.data.userUuid,
        )?.role;

        switch (presentUserRole) {
            case ProjectMemberRole.ADMIN:
                return ProjectMemberRoleFilter;
            case ProjectMemberRole.EDITOR:
                return ProjectMemberRoleFilter.slice(1);
            case ProjectMemberRole.MARKETER:
                return ProjectMemberRoleFilter.slice(2);
            case ProjectMemberRole.VIEWER:
                return [ProjectMemberRole.VIEWER];
            default:
                return ProjectMemberRoleFilter;
        }
    }, [user, users]);
    return (
        <Modal
            opened={opened}
            onClose={onClose}
            keepMounted={false}
            title={t('workspace_member_invite_modal.title')}
            size="lg"
            footerRightSection={
                <Group className="flex gap-1.5">
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        onClick={onClose}
                        disabled={isLoading || isInvitationLoading}
                    >
                        {t('workspace_member_invite_modal.secondary_btn_text')}
                    </Button>

                    <Button
                        form="add_user_to_project"
                        disabled={isLoading || isInvitationLoading}
                        type="submit"
                        rightIcon={<ChevronRight size={13} strokeWidth={2.5} />}
                    >
                        {t('workspace_member_invite_modal.primary_btn_text')}
                    </Button>
                </Group>
            }
        >
            <form
                name="add_user_to_project"
                id="add_user_to_project"
                onSubmit={form.onSubmit(
                    (values: Pick<CreateProjectMember, 'email' | 'role'>) =>
                        handleSubmit(values),
                )}
            >
                <Stack>
                    <Select
                        withAsterisk={false}
                        name={'email'}
                        withinPortal
                        label={t('workspace_member_invite_modal.email_label')}
                        placeholder={t(
                            'workspace_member_invite_modal.email_placeholder',
                        )}
                        // nothingFound="Nothing found"
                        searchable
                        creatable
                        required
                        disabled={isLoading}
                        getCreateLabel={(query) =>
                            validateEmail(query) ? (
                                <Text className="break-normal">{`Invite "${query}" as new member of this organization`}</Text>
                            ) : null
                        }
                        onCreate={(query) => {
                            if (validateEmail(query)) {
                                setAddNewMember(true);
                                setEmailOptions((prevState) => [
                                    ...prevState,
                                    query,
                                ]);
                                return query;
                            }
                        }}
                        data={emailOptions}
                        {...form.getInputProps('email')}
                        sx={{ flexGrow: 1 }}
                        className="!w-2/5"
                    />
                    <Stack className="gap-2">
                        {roleAccess() && (
                            <Select
                                label={t(
                                    'workspace_member_invite_modal.role_label',
                                )}
                                itemComponent={SelectItem}
                                data={[
                                    ...(roleAccess() as ProjectMemberRole[]),
                                ].map((orgMemberRole: ProjectMemberRole) => ({
                                    value: orgMemberRole,
                                    label: ProjectMemberRoleLabels[
                                        orgMemberRole
                                    ].replace('_', ' '),
                                    description:
                                        ProjectMemberRoleDescription[
                                            orgMemberRole
                                        ],
                                }))}
                                disabled={isLoading}
                                required
                                placeholder={t(
                                    'workspace_member_invite_modal.role_placeholder',
                                )}
                                dropdownPosition="bottom"
                                maxDropdownHeight={400}
                                withinPortal
                                className="!w-2/5"
                                withAsterisk={false}
                                {...form.getInputProps('role')}
                            />
                        )}
                        <Text className="text-sm font-medium text-gray-500">
                            {t('workspace_member_invite_modal.workspace_name', {
                                name: projectData?.name,
                            })}
                        </Text>
                    </Stack>
                </Stack>
            </form>

            {inviteLink && <InviteSuccess invite={inviteLink} hasMarginTop />}
        </Modal>
    );
};

export default React.memo(InviteMemberModal);
