import Modal from '@components/common/modal/Modal';
import { useGetSubscriptionGroupById } from '@hooks/subscriptionGroups/useSubscriptionGroups';
import { useLocale } from '@hooks/useLocale';
import { type CommunicationChannel } from '@lightdash/common';
import { Group, Text } from '@mantine/core';
import React from 'react';
import SubscriptionGroupContent from '../../SubscriptionGroupContent';

interface EditGroupModalProps {
    editModal: boolean;
    onClose: () => void;
    groupId: string;
    activeTab: CommunicationChannel;
}

const EditGroupModal: React.FC<EditGroupModalProps> = ({
    editModal,
    onClose,
    groupId,
    activeTab,
}) => {
    const { t } = useLocale();
    const { data: editData } = useGetSubscriptionGroupById({
        groupId: groupId,
    });
    return (
        <Modal
            opened={editModal}
            onClose={onClose}
            size="xl"
            title={
                <Group className="flex flex-col items-start gap-0">
                    <Text className="text-gray-800 text-base font-medium pb-0 mb-0">
                        {t('edit_group_modal.edit_a_subscription_group')}
                    </Text>
                </Group>
            }
            styles={() => ({
                content: {
                    maxWidth: '48vw !important',
                },
                header: {
                    display: 'flex',
                    alignItems: 'start',
                },
                body: {
                    padding: '0px',
                },
            })}
        >
            <SubscriptionGroupContent
                activeTab={activeTab}
                onClose={onClose}
                groupData={editData}
            />
        </Modal>
    );
};

export default EditGroupModal;
