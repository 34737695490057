import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type ApiResponse,
    type ApiSuccessEmpty,
    type ChannelConfig,
    type ChannelIntegrationDetails,
    type FetchIntegrationListResponse,
    type Integration,
    type IntegrationAddRequest,
    type IntegrationTestRequest,
    type IntegrationUpdateRequest,
} from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

const getChannels = async (projectId: string) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/communications/integrations`,
        method: 'GET',
        body: undefined,
    });

export const useGetChannels = () => {
    const params = useParams<{ projectUuid?: string }>();
    return useQuery<ApiResponse, ApiError, FetchIntegrationListResponse>({
        queryKey: [QueryKeys.CHANNELS],
        queryFn: () => getChannels(params?.projectUuid || ''),
    });
};

const getProviderIntegratedConfig = async (
    providerId: string,
    projectId: string,
    integrationId: string,
) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/communications/providers/${providerId}/integrations/${integrationId}`,
        method: 'GET',
        body: undefined,
    });

export const useGetIntegratedProviderConfig = (
    providerId: string | undefined,
    integrationId: string | undefined,
) => {
    const params = useParams<{ projectUuid?: string }>();
    let integratedChannels = useQuery<
        ApiResponse,
        ApiError,
        ChannelIntegrationDetails
    >({
        queryKey: [
            QueryKeys.INTEGRATED_CHANNELS,
            providerId,
            params.projectUuid,
        ],
        queryFn: () =>
            getProviderIntegratedConfig(
                providerId || '',
                params.projectUuid || '',
                integrationId || '',
            ),
        enabled: !!providerId && !!integrationId,
        // INFO - Added this to get updated data for the next time while editing a
        // provider config after the user has updated the config once
        staleTime: 0,
    });
    return integratedChannels;
};

const getProviderConfig = async (providerId: string, projectId: string) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/communications/providers/${providerId}`,
        method: 'GET',
        body: undefined,
    });

export const useGetProviderConfig = (providerId: string | undefined) => {
    const params = useParams<{ projectUuid?: string }>();
    let integratedChannels = useQuery<ApiResponse, ApiError, Integration>({
        queryKey: [QueryKeys.PROVIDER_CONFIG, providerId, params.projectUuid],
        queryFn: () =>
            getProviderConfig(providerId || '', params.projectUuid || ''),
        enabled: !!providerId,
    });
    return integratedChannels;
};

export type ConfigChannelIntegrationDetailsPayload<T extends ChannelConfig[]> =
    {
        metadata: { [K in T[number]['configKey']]: string };
        customName: string;
        providerId: string;
    };

const addChannelProvider = async (
    data: IntegrationAddRequest,
    projectId: string,
) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/communications/integrations`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useAddChannelProvider = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    const updateChannelProvider = useMutation({
        mutationFn: (payload: IntegrationAddRequest) =>
            addChannelProvider(payload, projectUuid),
        mutationKey: [QueryKeys.ADD_CHANNEL, projectUuid],
        onSuccess: async () => {
            await queryClient.refetchQueries([QueryKeys.PROVIDER_CONFIG]);
            await queryClient.refetchQueries([QueryKeys.INTEGRATED_CHANNELS]);
            showToastSuccess({
                title: t('channel_integration.update.success'),
            });
        },
        onError: (error: ApiError) => {
            showToastError({
                title: t('channel_integration.update.error'),
                subtitle: error.error.message,
            });
        },
    });
    return updateChannelProvider;
};

const testChannelIntegrationApi = ({
    projectId,
    data,
    providerId,
}: {
    projectId: string;
    data: IntegrationTestRequest;
    providerId: string;
}) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/communications/providers/${providerId}/test`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useTestChannelIntegration = () => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastError } = useNotify();
    return useMutation({
        mutationKey: [QueryKeys.TEST_PROVIDER_INTEGRATION, projectUuid],
        mutationFn: ({
            providerId,
            data,
        }: {
            providerId: string;
            data: IntegrationTestRequest;
        }) =>
            testChannelIntegrationApi({
                projectId: projectUuid,
                data,
                providerId,
            }),
        onError: () => {
            showToastError({
                title: t('workspace_settings.channel.test_failed'),
            });
        },
    });
};

const updateCommunications = async (
    data: IntegrationUpdateRequest,
    projectId: string,
    providerId: string,
    integrationId: string,
) => {
    void sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/communications/providers/${providerId}/integrations/${integrationId}`,
        method: 'PUT',
        body: JSON.stringify(data),
    });
};

export const useUpdateChannels = (
    providerId: string,
    integrationId: string,
    // channelName: string,
) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    const updateChannelProvider = useMutation<
        void,
        ApiError,
        IntegrationUpdateRequest
    >({
        mutationFn: (payload) =>
            updateCommunications(
                payload,
                projectUuid,
                providerId,
                integrationId,
            ),
        mutationKey: [QueryKeys.UPDATE_CHANNELS, providerId, integrationId],
        onSuccess: async () => {
            await queryClient.refetchQueries([QueryKeys.PROVIDER_CONFIG]);
            await queryClient.refetchQueries([QueryKeys.INTEGRATED_CHANNELS]);
            // queryClient.refetchQueries([QueryKeys.INTEGRATED_CHANNELS, channelName])
            showToastSuccess({
                title: t('channel_integration.update.success'),
            });
        },
        onError: (error) => {
            showToastError({
                title: t('channel_integration.update.error'),
                subtitle: error.error.message,
            });
        },
    });
    return updateChannelProvider;
};

const getChannelIntegration = async (projectId: string, channel: string) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/communications/integrations/${channel}`,
        method: 'GET',
        body: undefined,
    });

export const useGetChannelIntegrations = (channel: string) => {
    const params = useParams<{ projectUuid?: string }>();
    const channels = useQuery<ApiResponse, ApiError, Integration[]>({
        queryKey: [QueryKeys.INTEGRATED_CHANNELS, channel],
        queryFn: () =>
            getChannelIntegration(params?.projectUuid || '', channel),
        cacheTime: 120000,
        enabled: !!channel,
    });
    return channels;
};

//FIXME: This is a hacky solution as of now but can be removed when channels are revamped
const getProviderMetadata = async (
    providerId: string,
    channel: string,
    projectId: string,
) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/communications/integrations/${channel}/providers/${providerId}`,
        method: 'GET',
        body: undefined,
    });

export const useGetProviderMetada = (providerId: string, channel: string) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    let integratedChannels = useQuery<ApiResponse, ApiError, Integration>({
        queryKey: [QueryKeys.PROVIDER_CONFIG, providerId, channel, projectUuid],
        queryFn: () => getProviderMetadata(providerId, channel, projectUuid),
        enabled: !!providerId && !!channel && !!projectUuid,
        cacheTime: 120000,
    });
    return integratedChannels;
};

const getChannelProviderConfig = async (
    providerId: string,
    channel: string,
    projectId: string,
) => {
    return sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/communications/channels/${channel}/providers/${providerId}/get-details`,
        method: 'GET',
        body: undefined,
    });
};

export const useGetChannelProviderConfig = ({
    providerId,
    channel,
    enabled,
}: {
    providerId: string;
    channel: string;
    enabled: boolean;
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const channelProviderConfig = useQuery<ApiResponse, ApiError, Integration>({
        queryKey: [QueryKeys.CHANNELS, providerId, channel, projectUuid],
        queryFn: () =>
            getChannelProviderConfig(providerId, channel, projectUuid),
        enabled,
    });
    return channelProviderConfig;
};
