import {
    JourneyEdgeEnum,
    JourneyNodeEnum,
    type JourneyNodeData,
} from '@components/Journeys/Builder/types';
import { getEdgeId } from '@components/Journeys/Builder/utils';
import {
    type BaseTrigger,
    type JourneyCreatePayload,
    type JourneyNode,
} from '@lightdash/common';
import { useMemo } from 'react';
import { type Edge, type Node } from 'reactflow';

/**
 * Hook to generate Nodes and Edges from journeyPayload
 * @param journeyPayload - The journey payload containing the configuration nodes
 * @returns An object containing the generated nodes and edges
 */
export const useJourneyNodesAndEdges = (
    journeyPayload: JourneyCreatePayload | undefined,
) => {
    return useMemo(() => {
        if (!journeyPayload) return { nodes: [], edges: [] };
        const nodes: Node<JourneyNodeData>[] = [];
        const edges: Edge[] = [];

        // Add trigger node
        const triggerNode: BaseTrigger | undefined =
            journeyPayload.triggers!.entry[0];
        if (triggerNode) {
            nodes.push({
                id: triggerNode.id,
                position: { x: 0, y: 0 }, // Default position, can be updated later
                type: JourneyNodeEnum.TRIGGER,
                data: {
                    type: JourneyNodeEnum.TRIGGER,
                    nodeId: triggerNode.id,
                    blockId: triggerNode.metadata?.metadata?.blockId ?? '',
                },
            });
        }

        // Add journey nodes and edges
        journeyPayload.config!.nodes.forEach(
            (journeyNode: JourneyNode, index: number) => {
                nodes.push({
                    id: journeyNode.id,
                    position: { x: 0, y: 0 }, // Default position, can be updated later
                    type: JourneyNodeEnum.BLOCK,
                    data: {
                        type: JourneyNodeEnum.BLOCK,
                        nodeId: journeyNode.id,
                        blockId: journeyNode.metadata?.blockId ?? '',
                    },
                });

                // Add edge from trigger node to the first journey node
                if (index === 0 && triggerNode) {
                    edges.push({
                        id: getEdgeId(triggerNode.id, journeyNode.id),
                        source: triggerNode.id,
                        target: journeyNode.id,
                        type: JourneyEdgeEnum.BLOCK,
                    });
                }

                // Add edges based on branchConfig
                journeyNode.branchConfig?.children.branches.forEach(
                    (branch) => {
                        edges.push({
                            id: getEdgeId(journeyNode.id, branch.destination),
                            source: journeyNode.id,
                            target: branch.destination,
                            type: JourneyEdgeEnum.BLOCK,
                        });
                    },
                );
            },
        );

        return { nodes, edges };
    }, [journeyPayload]);
};
