import PasswordTextInput from '@components/Auth/PasswordTextInput';
import PasswordInput from '@components/common/Inputs/PasswordInput';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import {
    useUserHasPassword,
    useUserUpdatePasswordMutation,
} from '@hooks/user/usePassword';
import { getPasswordSchema } from '@lightdash/common';
import { Button, Group, Stack } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import React from 'react';
import { z } from 'zod';
import { ButtonVariant } from '../../../mantineTheme';
import Modal from '../../common/modal/Modal';

const passwordSchema = getPasswordSchema();

const validationSchema = (hasCurrentPassword: boolean) => {
    return hasCurrentPassword
        ? z.object({
              // we check validity of current password on the server
              currentPassword: z.string().nonempty(),
              newPassword: passwordSchema,
          })
        : z.object({
              newPassword: passwordSchema,
          });
};

interface PasswordModalProps {
    opened: boolean;
    onClose: () => void;
}

const PasswordModal: React.FC<PasswordModalProps> = ({ opened, onClose }) => {
    const { t } = useLocale();

    const { data: hasPassword } = useUserHasPassword();
    const { showToastSuccess, showToastError } = useNotify();

    const form = useForm({
        initialValues: {
            currentPassword: '',
            newPassword: '',
        },
        validate: zodResolver(validationSchema(!!hasPassword)),
    });

    const { isLoading, mutate: updateUserPassword } =
        useUserUpdatePasswordMutation({
            onSuccess: () => {
                showToastSuccess({
                    title: t(
                        'account_settings.password_modal.password_changed_success',
                    ),
                });

                window.location.href = '/login';
            },
            onError: ({ error }) => {
                showToastError({
                    title: t(
                        'account_settings.password_modal.password_changed_error',
                    ),
                    subtitle: error.message,
                });
            },
        });

    const handleOnSubmit = form.onSubmit(({ currentPassword, newPassword }) => {
        if (hasPassword) {
            updateUserPassword({
                password: currentPassword,
                newPassword,
            });
        } else {
            updateUserPassword({
                newPassword,
            });
        }
    });

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            keepMounted={false}
            title={t('password_modal.change_password_title')}
            size="lg"
            footerRightSection={
                <Group className="flex space-x-0.5">
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        onClick={onClose}
                        disabled={isLoading}
                    >
                        {t('password_modal.secondary_btn_text')}
                    </Button>

                    <Button
                        form="add_user_to_project"
                        disabled={isLoading}
                        type="submit"
                        onClick={() => handleOnSubmit()}
                    >
                        {t('password_modal.primary_btn_text')}
                    </Button>
                </Group>
            }
        >
            <>
                <form onSubmit={handleOnSubmit}>
                    <Stack>
                        {hasPassword && (
                            <PasswordInput
                                label="Current password"
                                placeholder="Enter your password"
                                disabled={isLoading}
                                className="w-2/5"
                                {...form.getInputProps('currentPassword')}
                            />
                        )}
                        <PasswordTextInput
                            passwordValue={form.values.newPassword}
                        >
                            <PasswordInput
                                label="New password"
                                placeholder="Enter your new password"
                                disabled={isLoading}
                                className="w-2/5"
                                {...form.getInputProps('newPassword')}
                            />
                        </PasswordTextInput>
                    </Stack>
                </form>
            </>
        </Modal>
    );
};

export default PasswordModal;
