import {
    type FieldsWithSuggestions,
    type FieldWithSuggestions,
} from '@components/Audience/Filters/FiltersProvider';
import {
    getItemId,
    JoinType,
    type CompiledDimension,
    type CompiledRelation,
    type CompiledRelationJoin,
    type CompiledRelationTable,
} from '@lightdash/common';
import { useEffect, useState } from 'react';

const getRightTables = (
    tableName: string,
    joinedTable: CompiledRelationJoin[] | undefined,
    tables: string[],
    tableData: Record<string, CompiledRelationTable>,
): string[] => {
    let updatedTables: string[] = [...tables];
    joinedTable?.forEach((table) => {
        if (
            table.source.table === tableName &&
            !updatedTables.includes(table.target.table)
        ) {
            updatedTables.push(table.target?.table);
            updatedTables = getRightTables(
                table.target.table,
                joinedTable,
                updatedTables,
                tableData,
            );
        }
    });
    return updatedTables;
};

const getPathBetweenTables = (
    source: string,
    destination: string,
    joinedTables: CompiledRelationJoin[] | undefined,
    path: string[],
    visitedNodes: Record<string, boolean>,
): boolean => {
    path.push(source);
    if (source === destination) return true;
    visitedNodes[source] = true;
    if (joinedTables) {
        for (const relation of joinedTables) {
            if (
                visitedNodes[relation.target.table] === undefined &&
                relation.source.table === source &&
                getPathBetweenTables(
                    relation.target.table,
                    destination,
                    joinedTables,
                    path,
                    visitedNodes,
                )
            ) {
                return true;
            }
        }
    }
    path.pop();
    return false;
};
const getFilterFieldWithSuggestions = (
    tableNames: string[],
    tables: { [tableName: string]: CompiledRelationTable } | undefined,
    fieldsMap: FieldsWithSuggestions,
) => {
    const ans: Record<string, FieldWithSuggestions> = {};
    if (tables)
        tableNames.forEach((eachTableName) => {
            Object.values(tables[eachTableName].dimensions).forEach(
                (eachDimension) => {
                    ans[getItemId(eachDimension)] =
                        fieldsMap[getItemId(eachDimension)];
                },
            );
        });
    return ans;
};
const getCanCreateCustomMetric = (
    joinedTables: CompiledRelationJoin[] | undefined,
    tables: { [tableName: string]: CompiledRelationTable } | undefined,
): boolean => {
    if (!tables) return true;

    return (
        joinedTables?.some(({ relationType }) => {
            const validRelationTypes = [
                JoinType.one_many,
                JoinType.one_one,
                JoinType.many_one,
                JoinType.many_many,
            ];
            return validRelationTypes.includes(relationType);
        }) ?? false
    );
};

export const isLeafTable = (
    tableName: string,
    joinedTables: CompiledRelationJoin[] | undefined,
) => {
    return !joinedTables?.some(
        (each_joint) => each_joint.source.table === tableName,
    );
};

const hasOneToManyRelation = (
    tableName: string,
    joinedTables: CompiledRelationJoin[] | undefined,
): boolean | undefined => {
    return joinedTables?.some((joint) => joint.source.table === tableName);
};

export const useGetRelatedTables = (
    tableName: string,
    activeRelationData: CompiledRelation | undefined,
    fieldsMap: FieldsWithSuggestions,
) => {
    const [relatedTables, setRelatedTables] =
        useState<(FieldWithSuggestions | CompiledDimension)[]>();

    useEffect(() => {
        if (activeRelationData?.joinedTables && tableName) {
            const rightData: (FieldWithSuggestions | CompiledDimension)[] =
                getRightTables(
                    tableName,
                    activeRelationData.joinedTables,
                    [],
                    activeRelationData.tables,
                ).reduce(
                    (
                        manyDimensions: (
                            | FieldWithSuggestions
                            | CompiledDimension
                        )[],
                        eachTable,
                    ) => {
                        manyDimensions = [
                            ...manyDimensions,
                            ...Object.values(
                                activeRelationData.tables[eachTable].dimensions,
                            ).map(
                                (dimension) => fieldsMap[getItemId(dimension)],
                            ),
                        ];
                        return manyDimensions;
                    },
                    [] as CompiledDimension[],
                );
            setRelatedTables(rightData);
        }
    }, [activeRelationData, tableName, fieldsMap]);

    return relatedTables;
};

export const useGetFieldsBetweenTables = (
    tableName: string,
    destination: string,
    activeRelationData: CompiledRelation | undefined,
    fieldsMap: FieldsWithSuggestions,
) => {
    const [fieldsBetween, setFieldsBetween] = useState<FieldsWithSuggestions>(
        {},
    );

    useEffect(() => {
        if (activeRelationData?.joinedTables && tableName && destination) {
            const path: string[] = [];
            getPathBetweenTables(
                tableName,
                destination,
                activeRelationData.joinedTables,
                path,
                {},
            );
            if (path.length > 0) {
                const fields = getFilterFieldWithSuggestions(
                    path,
                    activeRelationData.tables,
                    fieldsMap,
                );
                setFieldsBetween(fields);
            }
        }
    }, [activeRelationData, tableName, destination, fieldsMap]);

    return fieldsBetween;
};

export const useCanCreateCustomMetric = (
    activeRelationData: CompiledRelation | undefined,
) => {
    const [canCreateCustomMetric, setCanCreateCustomMetric] = useState<
        boolean | undefined
    >(true);

    useEffect(() => {
        if (activeRelationData?.joinedTables) {
            const canCreateCustom = getCanCreateCustomMetric(
                activeRelationData.joinedTables,
                activeRelationData.tables,
            );
            setCanCreateCustomMetric(canCreateCustom);
        }
    }, [activeRelationData]);

    return canCreateCustomMetric;
};

export const useFilteringTables = (
    activeRelationData: CompiledRelation | undefined,
) => {
    const [availableTables, setAvailableTables] = useState<
        CompiledRelationTable[]
    >([]);
    const [nonAvailableTables, setNonAvailableTables] = useState<
        CompiledRelationTable[]
    >([]);

    useEffect(() => {
        if (!activeRelationData) {
            return;
        }

        const newAvailableTables: CompiledRelationTable[] = [];
        const newNonAvailableTables: CompiledRelationTable[] = [];
        const baseTableName = activeRelationData.baseTable;
        if (
            !hasOneToManyRelation(
                baseTableName,
                activeRelationData.joinedTables,
            )
        ) {
            newNonAvailableTables.push(
                activeRelationData.tables[baseTableName],
            );
        } else {
            newAvailableTables.push(activeRelationData.tables[baseTableName]);
        }
        Object.values(activeRelationData.tables).forEach((table) => {
            if (table.name === baseTableName) return;
            if (
                isLeafTable(table.name, activeRelationData.joinedTables) ||
                !hasOneToManyRelation(
                    table.name,
                    activeRelationData.joinedTables,
                )
            ) {
                newNonAvailableTables.push(table);
            } else {
                newAvailableTables.push(table);
            }
        });

        setAvailableTables(newAvailableTables);
        setNonAvailableTables(newNonAvailableTables);
    }, [activeRelationData]);

    return { availableTables, nonAvailableTables };
};
