import { NavLink, type NavLinkProps } from '@mantine/core';
import { type FC } from 'react';
import {
    NavLink as ReactRouterNavLink,
    useHistory,
    type NavLinkProps as ReactRouterNavLinkProps,
} from 'react-router-dom';

type RouterNavLinkProps = Omit<NavLinkProps, 'component' | 'active'> &
    Omit<ReactRouterNavLinkProps, 'component'> &
    Partial<{
        activeclass: string;
        inactiveclass: string;
    }>;

const RouterNavLink: FC<RouterNavLinkProps> = (props) => {
    // const match = useRouteMatch(props.to.toString());
    const path = props.to.toString();
    const history = useHistory();
    const isActive = history.location.pathname.includes(path);
    const {
        activeclass = 'active-nav-link',
        inactiveclass = 'inactive-nav-link',
    } = props;

    // const isActive = match
    //     ? match.isExact && (props.exact === undefined || props.exact)
    //     : false;

    return (
        <NavLink
            className={`${
                isActive ? activeclass : inactiveclass
            } rounded-lg hover:rounded-lg`}
            {...props}
            component={ReactRouterNavLink}
            active={isActive}
        />
    );
};

export default RouterNavLink;
