import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import SubscriptionGroupFilter, {
    type Option,
} from '@components/Campaigns/Builder/Steps/CampaignBuilderContent/SetupContent/SubscriptionGroupFilter';
import LeftLabelInput from '@components/common/Inputs/LeftLabelInput';
import FieldSelect from '@components/common/Select/FieldSelect';
import { useLocale } from '@hooks/useLocale';
import { type TemplateVariableDetails } from '@lightdash/common';
import { Group, Stack, Text } from '@mantine/core';
import { useCampaignContext } from '@providers/CampaignProvider';
import React, { useMemo } from 'react';

export enum TemplateVariableGroups {
    'headerVariables' = 'Subject variables',
    'bodyVariables' = 'Content variables',
    'footerVariables' = 'Footer variables',
}

interface VariablesProps {
    dimensions: FieldWithSuggestions[];
    variablesContent: TemplateVariableDetails;
    isDisabled?: boolean;
    errors: {
        [key: string]: string;
    };
    onVariableChange: (
        value: FieldWithSuggestions | undefined,
        key: string,
    ) => void;
    onDefaultValueChange: (value: string, key: string) => void;
    options: Option[] | undefined;
}

const Variables: React.FC<VariablesProps> = ({
    dimensions,
    variablesContent,
    isDisabled,
    errors,
    onVariableChange,
    onDefaultValueChange,
    options,
}) => {
    const { campaignPayload } = useCampaignContext((context) => context.state);
    const { templateDetails } = campaignPayload;

    const dataVariables = useMemo(() => {
        const variables = variablesContent;
        if (!variables || !Object.keys(variables)?.length) return;

        let variablesObject: {
            [key: string]: {
                value: string;
                defaultValue: string;
            };
        } = {};
        Object.keys(variables)?.forEach((groupName: string) => {
            variables?.[groupName as keyof TemplateVariableDetails]?.forEach(
                (key: string) => {
                    variablesObject = {
                        ...variablesObject,
                        [key]: {
                            value:
                                templateDetails?.mappings?.[key]?.value || '',
                            defaultValue:
                                templateDetails?.mappings?.[key]
                                    ?.defaultValue || '',
                        },
                    };
                },
            );
        });

        return variablesObject;
    }, [variablesContent, templateDetails?.mappings]);

    const { t } = useLocale();
    return (
        <Stack>
            {Object.keys(variablesContent).map((groupName: string) => {
                const variableObject =
                    variablesContent?.[
                        groupName as keyof TemplateVariableDetails
                    ];

                return variableObject && variableObject?.length > 0 ? (
                    <Stack key={groupName} className="">
                        <Text className="text-sm text-gray-500">
                            {
                                TemplateVariableGroups?.[
                                    groupName as keyof TemplateVariableDetails
                                ]
                            }
                        </Text>
                        {variableObject?.map((key: string) => {
                            const variableValue =
                                (dataVariables?.[key]?.value &&
                                    dimensions?.find(
                                        (dimension) =>
                                            dimension?.uniqueIdentifier ===
                                            dataVariables?.[key]?.value,
                                    )) ||
                                undefined;
                            const variableDefaultValue =
                                dataVariables?.[key].defaultValue;

                            return (
                                <Stack className="gap-1.5" key={key}>
                                    <Text className="text-sm text-gray-600">
                                        {'{{ '}
                                        <span className="font-medium text-gray-800">
                                            {key}
                                        </span>
                                        {' }}'}
                                    </Text>

                                    <Group
                                        className="gap-2.5 flex-nowrap"
                                        align="flex-start"
                                    >
                                        {options ? (
                                            <SubscriptionGroupFilter
                                                label={undefined}
                                                error={errors?.[key]}
                                                value={
                                                    variableValue?.uniqueIdentifier
                                                }
                                                onChange={(value) =>
                                                    onVariableChange(
                                                        {
                                                            uniqueIdentifier:
                                                                value,
                                                        } as FieldWithSuggestions,
                                                        key,
                                                    )
                                                }
                                                width="25rem"
                                                anchorClass="!w-[18rem]"
                                                options={options}
                                            />
                                        ) : (
                                            <FieldSelect
                                                size="sm"
                                                item={variableValue}
                                                items={dimensions}
                                                onChange={(
                                                    value: FieldWithSuggestions,
                                                ) =>
                                                    onVariableChange(value, key)
                                                }
                                                placeholder={t(
                                                    'campaign.create.template_variable.placeholder',
                                                    { variable: key },
                                                )}
                                                isDisabled={isDisabled}
                                                error={errors?.[key]}
                                            />
                                        )}
                                        <LeftLabelInput
                                            value={variableDefaultValue}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                onDefaultValueChange(
                                                    event.target.value,
                                                    key,
                                                );
                                            }}
                                            disabled={isDisabled}
                                        />
                                    </Group>
                                    <br />
                                </Stack>
                            );
                        })}
                    </Stack>
                ) : null;
            })}
        </Stack>
    );
};

export default Variables;
