import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type CustomAttribute,
    type InsertCustomAttribute,
} from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

const getCustomMetrics = async (projectUuid: string) =>
    sortmentApi<CustomAttribute[]>({
        url: `/projects/${projectUuid}/custom-attributes`,
        method: 'GET',
        body: undefined,
    });

const getCustomMetricById = async (
    projectUuid: string,
    customMetricUuid: string,
) =>
    sortmentApi<CustomAttribute>({
        url: `/projects/${projectUuid}/custom-attributes/${customMetricUuid}`,
        method: 'GET',
        body: undefined,
    });

const createCustomMetric = async (
    data: InsertCustomAttribute,
    projectUuid: string,
) =>
    sortmentApi<CustomAttribute>({
        url: `/projects/${projectUuid}/custom-attributes`,
        method: 'POST',
        body: JSON.stringify(data),
    });
export const useGetCustomMetrics = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const customMetrics = useQuery<CustomAttribute[], ApiError>({
        queryKey: [QueryKeys.GET_CUSTOM_METRICS, projectUuid],
        queryFn: () => getCustomMetrics(projectUuid),
    });
    return customMetrics;
};
export const useGetCustomMetricById = (customMetricUuid: string) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    return useQuery<CustomAttribute, ApiError>({
        queryKey: [QueryKeys.GET_CUSTOM_METRICS_BY_ID, projectUuid],
        queryFn: () => getCustomMetricById(projectUuid, customMetricUuid),
        enabled: !!projectUuid && !!customMetricUuid,
        retry: false,
    });
};

export const useCreateCustomMetricMutation = () => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const queryClient = useQueryClient();
    const { t } = useLocale();
    return useMutation<CustomAttribute, ApiError, InsertCustomAttribute>(
        (data) => createCustomMetric(data, projectUuid),
        {
            mutationKey: [QueryKeys.CREATE_CUSTOM_METRIC],
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    QueryKeys.GET_CUSTOM_METRICS,
                ]);
                await queryClient.invalidateQueries([
                    QueryKeys.RELATION_SCHEMA,
                ]);
                showToastSuccess({
                    title: t('create_custom_metric.toast_success_message'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('create_custom_metric.toast_failure_message'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};
