import { sortmentApi } from '@api/index';
import { type ApiError, type ApiSuccessEmpty } from '@lightdash/common';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';

const logoutQuery = async () =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/logout`,
        method: 'GET',
        body: undefined,
    });

const useLogoutMutation = (
    options: UseMutationOptions<ApiSuccessEmpty, ApiError, void>,
) => {
    return useMutation(logoutQuery, {
        mutationKey: [QueryKeys.LOGOUT],
        ...options,
    });
};

export default useLogoutMutation;
