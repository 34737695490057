import {
    type MessageContext,
    type MessageTypeMap,
    type WidgetMessage,
} from '@lightdash/common';

export type AIDataTypes = WidgetMessage;

export interface AIWidgetProps<C extends MessageContext> {
    context: C;
    onMessageReceive: (data: MessageTypeMap[C]) => void;
    onIsLoading: (data: boolean) => void;
}

export enum MessageValue {
    USER = 'user',
    SYSTEM = 'system',
    INCOMING_DIRECTION = 'incoming',
    OUTGOING_DIRECTION = 'outgoing',
    POSITION = 'normal',
}
