import TextInput from '@components/common/Inputs/TextInput';
import { type TextInputProps } from '@mantine/core';
import { MagnifyingGlass } from '@phosphor-icons/react';
import React from 'react';
const textInputStyles = () => ({
    input: {
        boxShadow: 'none',
    },
});

const SearchInput: React.FC<TextInputProps> = ({
    placeholder,
    onChange,
    ...rest
}) => {
    return (
        <TextInput
            placeholder={placeholder}
            icon={<MagnifyingGlass weight="duotone" />}
            styles={textInputStyles}
            onChange={onChange}
            {...rest}
        />
    );
};

export default SearchInput;
