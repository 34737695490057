import { Box } from '@mantine/core';
import React from 'react';

import SkeletonLoader from '@components/common/SkeletonLoader';

const Loader: React.FC<{ length?: number }> = ({ length = 3 }) => {
    return (
        <Box className="flex flex-col gap-3">
            {new Array(length).fill(0).map(() => (
                <>
                    <SkeletonLoader height={20} width={100} />
                    <SkeletonLoader height={36} width={270} />
                </>
            ))}
        </Box>
    );
};

export default Loader;
