import { useFieldsWithEvents } from '@components/Audience/Filters/FiltersCard/useFieldsWithEvents';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider';
import {} from '@lightdash/common';
import { CustomMetricProvider } from '@providers/CustomMetricProvider';
import { useProjectContext } from '@providers/ProjectProvider';
import { useRelationContext } from '@providers/RelationProvider';
import React from 'react';
import { useParams } from 'react-router-dom';
import CustomMetricBaseTableModal from '../CustomMetricBaseTableModal';
import CustomMetricManagerModal from '../CustomMetricManagerModal';
import {
    useCustomMetricInitialData,
    type CreateCustomMetricProps,
} from '../utils';
import useCreateCustomMetric from './useCreateCustomMetric';

const CustomMetricModals: React.FC<CreateCustomMetricProps> = (props) => {
    useCreateCustomMetric(props);
    return (
        <>
            <CustomMetricBaseTableModal />
            <CustomMetricManagerModal />
        </>
    );
};

const CreateCustomMetric: React.FC<CreateCustomMetricProps> = ({
    ...props
}) => {
    const { activeRelation, activeRelationUuid } = useRelationContext();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { projectData } = useProjectContext();
    const initialState = useCustomMetricInitialData(
        undefined,
        activeRelationUuid,
    );
    const { data: eventsData, eventsTableNames } = useFieldsWithEvents({
        relationData: activeRelation,
        activeRelationUuid,
        projectUuid,
    });
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });

    return (
        <>
            <FiltersProvider
                projectUuid={projectUuid}
                fieldsMap={fieldsWithSuggestions}
                startOfWeek={
                    projectData?.warehouseConnection?.startOfWeek ?? undefined
                }
                eventsMap={eventsData}
                eventTables={eventsTableNames}
            >
                <CustomMetricProvider initialState={initialState}>
                    <CustomMetricModals
                        openBaseTable={props.openBaseTable}
                        tableName={props.tableName}
                        customMetricData={props.customMetricData}
                        isDuplicate={props.isDuplicate}
                        isViewMode={props.isViewMode}
                        onModalClose={props.onModalClose}
                        customMetricId={props.customMetricId}
                        disableTableChange={props.disableTableChange}
                    />
                </CustomMetricProvider>
            </FiltersProvider>
        </>
    );
};
export default CreateCustomMetric;
