import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import List from '@components/common/List';
import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import {
    CommunicationChannelName,
    type JourneyEventMapperSchema,
} from '@lightdash/common';
import { Divider, Group, ScrollArea, Stack, Text } from '@mantine/core';
import { CaretRight, CursorClick } from '@phosphor-icons/react';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import Fuse from 'fuse.js';
import React, { useMemo, useState } from 'react';

const InternalEventItem: React.FC<{ event: JourneyEventMapperSchema }> = ({
    event,
}) => (
    <Group className="items-center max-w-full gap-1 overflow-hidden">
        <ChannelIcon channel={event.sourceLabel} showChannelName={false} />
        <CaretRight />
        <Text className="flex-grow text-sm font-medium text-gray-800 truncate flex-nowrap">
            {event.sourceLabel && CommunicationChannelName[event.sourceLabel]}{' '}
            {event.label || event.eventName}
        </Text>
    </Group>
);

const ExternalEventItem: React.FC<{ event: JourneyEventMapperSchema }> = ({
    event,
}) => (
    <Group className="items-center max-w-full gap-1 overflow-hidden">
        <CursorClick color={'rgb(var(--color-mustard-800))'} />
        <Text className="text-sm font-medium text-gray-500 truncate max-w-[7.5rem] flex-nowrap">
            {event.source}
        </Text>
        <CaretRight />
        <Text className="flex-grow text-sm font-medium text-gray-800 truncate max-w-[7.5rem] flex-nowrap">
            {event.label || event.eventName}
        </Text>
    </Group>
);

const EventsList: React.FC<{}> = () => {
    const { t } = useLocale();
    const { addTriggerNode } = useJourneyBuilderContext(
        (context) => context.actions,
    );

    const { journeyEvents } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const [searchQuery, setSearchQuery] = useState<string>('');

    const fuse = useMemo(() => {
        if (!journeyEvents) return null;
        return new Fuse(journeyEvents, {
            keys: ['source', 'label', 'eventName'],
            threshold: 0.3,
        });
    }, [journeyEvents]);

    const filteredEvents = useMemo(() => {
        if (!journeyEvents) return { internal: [], external: [] };
        let result = journeyEvents;

        if (searchQuery && fuse) {
            result = fuse.search(searchQuery).map(({ item }) => item);
        }

        const internal = result.filter((event) => event.isInternal);
        const external = result.filter((event) => !event.isInternal);

        internal.sort((a, b) => {
            if (a.source < b.source) return -1;
            if (a.source > b.source) return 1;
            if ((a.sourceLabel || '') < (b.sourceLabel || '')) return -1;
            if ((a.sourceLabel || '') > (b.sourceLabel || '')) return 1;
            if ((a.label || a.eventName) < (b.label || b.eventName)) return -1;
            if ((a.label || a.eventName) > (b.label || b.eventName)) return 1;
            return 0;
        });

        external.sort((a, b) => {
            if (a.source < b.source) return -1;
            if (a.source > b.source) return 1;
            if ((a.label || a.eventName) < (b.label || b.eventName)) return -1;
            if ((a.label || a.eventName) > (b.label || b.eventName)) return 1;
            return 0;
        });

        return { internal, external };
    }, [journeyEvents, searchQuery, fuse]);

    return (
        <Stack className="gap-2.5 h-full">
            <SearchInput
                placeholder={t(
                    'journey_builder.trigger_block_search_placeholder',
                )}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Text className="px-2 text-xs font-medium text-gray-500 uppercase">
                {t('journey_builder.trigger_block_events_section_title')}
            </Text>
            <ScrollArea className="flex-grow">
                <List
                    items={filteredEvents.external}
                    itemTemplate={(event) => (
                        <ExternalEventItem event={event} />
                    )}
                    onItemClick={(event) => {
                        addTriggerNode({
                            eventName: event.eventName,
                            eventSource: event.source,
                        });
                    }}
                />
                <Divider className="mx-4 my-1 border-t-2 border-t-shade-2" />
                <List
                    items={filteredEvents.internal}
                    itemTemplate={(event) => (
                        <InternalEventItem event={event} />
                    )}
                    onItemClick={(event) => {
                        addTriggerNode({
                            eventName: event.eventName,
                            eventSource: event.source,
                        });
                    }}
                />
            </ScrollArea>
        </Stack>
    );
};

export default React.memo(EventsList);
