import LinkButton from '@components/common/LinkButton';
import { useLocale } from '@hooks/useLocale';
import { Group, Title } from '@mantine/core';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonVariant } from '../../../../mantineTheme';
import CampaignBuilderProgress from './Progress';

interface CampaignBuilderHeaderProps {
    isNew: boolean;
    isViewOnly: boolean;
}

const CampaignBuilderHeader: React.FC<CampaignBuilderHeaderProps> = ({
    isNew,
    isViewOnly,
}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();

    const title = useMemo(() => {
        if (isNew) {
            return t('campaign.create.title');
        }

        if (isViewOnly) {
            return t('campaign.view.title');
        }

        return t('campaign.edit.title');
    }, [isNew, isViewOnly, t]);

    return (
        <Group position="apart" className="w-full">
            <Title className="text-base font-medium text-gray-800">
                {title}
            </Title>
            <CampaignBuilderProgress />
            <LinkButton
                variant={ButtonVariant.OUTLINED}
                href={`/projects/${projectUuid}/campaigns`}
            >
                {t('common.exit')}
            </LinkButton>
        </Group>
    );
};

export default React.memo(CampaignBuilderHeader);
