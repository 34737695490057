import TextInputVariableWidth from '@components/common/Inputs/TextInputVariableWidth';
import Select from '@components/common/Select';
import { FREQUENCY_CAPPING_RULE_INPUT_MAX } from '@components/Templates/HTMLManager/HTMLPreview/config';
import { useLocale } from '@hooks/useLocale';
import {
    CommunicationChannel,
    PeriodType,
    type CappingRule,
} from '@lightdash/common';
import { Box, Group, Text } from '@mantine/core';
import { X as DeleteIcon } from '@phosphor-icons/react';
import { t as translate } from 'i18next';

type FrequencyCappingRuleProps = {
    index: number;
    onChange: (
        value: string | number | null,
        type: string,
        index: number,
    ) => void;
    value: CappingRule;
    onDelete: (index: number) => void;
    readOnly: boolean;
    inputMode: boolean;
    isLastItem: boolean;
};

type DropdownOptions<T> = {
    label: string;
    value: T;
}[];

const MESSAGE_TYPE_OPTIONS: DropdownOptions<CommunicationChannel> = [
    {
        label: translate(
            'workspace_settings.delivery_controls.frequency_capping.message_type.any',
        ),
        value: CommunicationChannel.ANY,
    },
    {
        label: translate(
            'workspace_settings.delivery_controls.frequency_capping.message_type.email',
        ),
        value: CommunicationChannel.EMAIL,
    },
    {
        label: translate(
            'workspace_settings.delivery_controls.frequency_capping.message_type.sms',
        ),
        value: CommunicationChannel.SMS,
    },
    {
        label: translate(
            'workspace_settings.delivery_controls.frequency_capping.message_type.whatsapp',
        ),
        value: CommunicationChannel.WHATSAPP,
    },
];

const DURATION_TYPE_OPTIONS: DropdownOptions<PeriodType> = [
    {
        label: translate(
            'workspace_settings.delivery_controls.frequency_capping.duration_type.hours',
        ),
        value: PeriodType.HOUR,
    },
    {
        label: translate(
            'workspace_settings.delivery_controls.frequency_capping.duration_type.days',
        ),
        value: PeriodType.DAY,
    },
    {
        label: translate(
            'workspace_settings.delivery_controls.frequency_capping.duration_type.weeks',
        ),
        value: PeriodType.WEEK,
    },
];

const FrequencyCappingRule: React.FC<FrequencyCappingRuleProps> = ({
    index,
    onChange,
    value,
    onDelete,
    readOnly,
    inputMode,
    isLastItem,
}) => {
    const { t } = useLocale();

    const handleChange = (val: string | number | null, type: string) => {
        if (
            (type === 'limit' || type === 'period.value') &&
            typeof val === 'string' &&
            parseInt(val) > FREQUENCY_CAPPING_RULE_INPUT_MAX
        ) {
            return;
        }
        onChange(val, type, index);
    };

    return (
        <Group
            className={`my-3 ${(!isLastItem || inputMode) && 'border-b pb-3'}`}
            position="apart"
            align="center"
        >
            <Group>
                {index !== 0 && (
                    <Box className="p-1 text-xs text-white bg-gray-500 rounded-md">
                        AND
                    </Box>
                )}
                <Text className="text-gray-600">
                    {index === 0 && 'Send'} no more than
                </Text>
                <TextInputVariableWidth
                    type="number"
                    value={value.limit}
                    onChange={(e) => handleChange(e.target.value, 'limit')}
                    readOnly={readOnly}
                    error={!value.limit}
                />
                <Select
                    data={MESSAGE_TYPE_OPTIONS}
                    withinPortal
                    dropdownPosition="bottom"
                    value={value.channel}
                    onChange={(val) => handleChange(val, 'channel')}
                    readOnly={readOnly}
                />
                <Text className="text-gray-600">
                    {t(
                        'workspace_settings.delivery_controls.frequency_capping.rule_text',
                    )}
                </Text>
                <TextInputVariableWidth
                    placeholder="#"
                    type="number"
                    value={value.period.value}
                    onChange={(e) =>
                        handleChange(e.target.value, 'period.value')
                    }
                    readOnly={readOnly}
                    error={!value.period.value}
                />
                <Select
                    withinPortal
                    dropdownPosition="bottom"
                    data={DURATION_TYPE_OPTIONS}
                    value={value.period.type}
                    onChange={(val) => handleChange(val, 'period.type')}
                    className="!w-[100px]"
                    readOnly={readOnly}
                />
            </Group>
            {inputMode && (
                <Box className="mr-2">
                    <DeleteIcon
                        className="cursor-pointer"
                        onClick={() => onDelete(index)}
                        weight="regular"
                    />
                </Box>
            )}
        </Group>
    );
};

export default FrequencyCappingRule;
