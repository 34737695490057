import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import HTMLEditor from '@components/Templates/HTMLManager/HTMLEditor';
import SMSEditor from '@components/Templates/SMSManager/SMSEditor';
import TemplateOptionsModal from '@components/Templates/TemplateOptionsModal';
import {
    useCreateTemplate,
    useGetTemplateById,
    useUpdateTemplate,
    useUpdateTemplateName,
} from '@hooks/useTemplate';
import {
    CommunicationChannel as CommunicationChannelEnum,
    type CreateTemplateRequest,
    type EmailTemplateContentDetails,
    type Integration,
    type ProviderTemplateDetails,
    type SmsTemplateContentDetails,
    type UpdateTemplateRequest,
} from '@lightdash/common';
import { useDisclosure } from '@mantine/hooks';
import { useTemplateBuilderContext } from '@providers/TemplateBuilderProvider';
import { useQueryClient } from '@tanstack/react-query';
import juice from 'juice';
import { isEqual } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { QueryKeys } from 'types/UseQuery';
import { Action } from '../../TemplateActions';
import ConfirmTemplateSaveModal from '../modals/ConfirmTemplateSaveModal';
import NewTemplateNameModal from '../modals/NewTemplateNameModal';
import { useTemplateBuilderInitialData } from '../useTemplateBuilderInitialData';

interface TemplateBuilderProps {
    integration: Integration;
    integrationId: string;
    onClose: (() => void) | undefined;
    // handleTemplateSelect: (obj: ProviderTemplateDetails) => void;
}

const TemplateBuilder: React.FC<TemplateBuilderProps> = ({
    integration,
    integrationId,
    onClose,
    // handleTemplateSelect,
}) => {
    // TODO - Handle versioning when we support multiple template versions
    const { templatePayload, templateId, initialPayload, version } =
        useTemplateBuilderContext((context) => context.state);

    const {
        setTemplatePayload,
        setTemplateId,
        setOpenCreateTemplateModal,
        handleTemplateSelect,
    } = useTemplateBuilderContext((context) => context.actions);

    const {
        mutateAsync: mutateAsyncCreateTemplate,
        isLoading: isCreatingTemplate,
        isSuccess: templateCreateSuccess,
    } = useCreateTemplate();
    const query = useQueryClient();

    const {
        mutateAsync: mutateAsyncUpdateTemplate,
        isLoading: isUpdatingTemplate,
        isSuccess: templateUpdateSuccess,
    } = useUpdateTemplate(templateId, version);

    const {
        mutateAsync: mutateAsyncUpdateTemplateName,
        isSuccess: templateNameUpdateSuccess,
    } = useUpdateTemplateName(templateId);

    const [selectedTemplateId, setSelectedTemplateId] = useState<
        string | undefined
    >(undefined);

    const {
        data: templateMetadata,
        isFetching,
        isSuccess,
    } = useGetTemplateById(selectedTemplateId ?? '');

    useEffect(() => {
        if (!isSuccess || !templateMetadata) return;

        const payload: CreateTemplateRequest = {
            name: templateMetadata.name,
            description: templateMetadata.description,
            channel: templateMetadata.channel,
            consideredInLibrary: templateMetadata.consideredInLibrary,
            content: templateMetadata.contents[0].content,
            previewUrl: '',
        };
        setTemplatePayload(payload);
        setTemplateId(templateMetadata.id);
    }, [isSuccess, templateMetadata, setTemplatePayload, setTemplateId]);

    const [
        confirmTemplateSaveModalOpened,
        {
            open: openConfirmTemplateSaveModal,
            close: closeConfirmTemplateSaveModal,
        },
    ] = useDisclosure(false);

    const [
        newTemplateNameModalOpened,
        { open: openNewTemplateNameModal, close: closeNewTemplateNameModal },
    ] = useDisclosure(false);

    const [opened, { open, close }] = useDisclosure(false);

    const { channel } = useTemplateBuilderContext((context) => context.state);

    useEffect(() => {
        if (
            templateUpdateSuccess ||
            templateNameUpdateSuccess ||
            templateCreateSuccess
        ) {
            closeConfirmTemplateSaveModal();
            setOpenCreateTemplateModal(false);
            (async () => {
                if (onClose && typeof onClose === 'function') onClose();
                await query.refetchQueries([
                    QueryKeys.GET_TEMPLATE,
                    templateId,
                ]);
            })();
        }
    }, [
        templateUpdateSuccess,
        templateNameUpdateSuccess,
        closeConfirmTemplateSaveModal,
        close,
        closeNewTemplateNameModal,
        setOpenCreateTemplateModal,
        query,
        templateId,
        templateCreateSuccess,
        onClose,
    ]);

    const onTitleChange = useCallback(
        (value: string) => {
            setTemplatePayload({
                ...templatePayload,
                name: value,
            });
        },
        [setTemplatePayload, templatePayload],
    );

    const handleTemplateSave = useCallback(
        async (
            content:
                | EmailTemplateContentDetails
                | SmsTemplateContentDetails
                | undefined,
        ) => {
            const updatedPayload = {
                ...templatePayload,
                ...(content && { content }),
            };

            if (isEqual(updatedPayload, initialPayload)) {
                setOpenCreateTemplateModal(false);
                return;
            }

            setTemplatePayload(updatedPayload);

            if (!templateId) {
                if (!templatePayload.name) {
                    openNewTemplateNameModal();
                    return;
                }
                let payload: CreateTemplateRequest = {
                    ...templatePayload,
                    ...(content && content),
                };
                if (content && Object.keys(content).includes('html')) {
                    payload = {
                        ...payload,
                        content,
                    };
                }
                const response = await mutateAsyncCreateTemplate(payload);
                const newTemplateObj: ProviderTemplateDetails = {
                    id: response.id,
                    name: response.name,
                    description: response.description,
                };
                handleTemplateSelect(newTemplateObj);
                setOpenCreateTemplateModal(false);
                return;
            }

            openConfirmTemplateSaveModal();
        },
        [
            templatePayload,
            initialPayload,
            setTemplatePayload,
            templateId,
            openConfirmTemplateSaveModal,
            setOpenCreateTemplateModal,
            mutateAsyncCreateTemplate,
            handleTemplateSelect,
            openNewTemplateNameModal,
        ],
    );

    const handleTemplateUpdate = useCallback(async () => {
        if (!templateId) return;

        await mutateAsyncUpdateTemplate(templatePayload.content);
        if (templatePayload.name !== initialPayload.name) {
            const payload: UpdateTemplateRequest = {
                name: templatePayload.name,
                description: templatePayload.description,
                content: templatePayload.content,
            };
            await mutateAsyncUpdateTemplateName(payload);
        }
    }, [
        templateId,
        templatePayload,
        mutateAsyncUpdateTemplate,
        mutateAsyncUpdateTemplateName,
        initialPayload,
    ]);

    const handleLoadTemplate = useCallback(() => {
        open();
    }, [open]);

    const handleDuplicateTemplate = useCallback(() => {
        setTemplatePayload({
            ...templatePayload,
            name: `Copy of ${templatePayload.name}`,
        });
        setTemplateId('');
    }, [setTemplateId, setTemplatePayload, templatePayload]);

    const initialState = useTemplateBuilderInitialData({
        templatePayload: undefined,
        channel,
        templateId: '',
    });

    const handleNewTemplate = useCallback(() => {
        setTemplatePayload(initialState.templatePayload);
        setTemplateId('');
    }, [setTemplateId, setTemplatePayload, initialState]);

    const onHeaderAction = useMemo(
        () =>
            (action: Action): void => {
                if (action === Action.RESET) {
                    handleNewTemplate();
                    return;
                }

                if (action === Action.LOAD_TEMPLATE) {
                    handleLoadTemplate();
                    return;
                }

                if (action === Action.DUPLICATE) {
                    handleDuplicateTemplate();
                    return;
                }
            },
        [handleDuplicateTemplate, handleLoadTemplate, handleNewTemplate],
    );

    const renderTemplateBuilder = useMemo(() => {
        switch (channel) {
            case CommunicationChannelEnum.EMAIL:
                return (
                    <HTMLEditor
                        templateDetail={{
                            id: templateId,
                            name: templatePayload.name,
                            html: (
                                templatePayload.content as EmailTemplateContentDetails
                            )?.html,
                            unsubscribeBlock: false,
                        }}
                        onSave={async (value) => {
                            await handleTemplateSave({
                                html: juice(value.html),
                                subject: '',
                            });
                        }}
                        onClose={() => {
                            if (onClose) onClose();
                            setOpenCreateTemplateModal(false);
                        }}
                        onTitleChange={onTitleChange}
                        onHeaderAction={onHeaderAction}
                    />
                );
            case CommunicationChannelEnum.SMS:
                return (
                    <SMSEditor
                        onTitleChange={onTitleChange}
                        onHeaderAction={onHeaderAction}
                        handleTemplateSave={() => handleTemplateSave(undefined)}
                    />
                );
            default:
                return null;
        }
    }, [
        channel,
        handleTemplateSave,
        onTitleChange,
        templateId,
        templatePayload,
        onHeaderAction,
        setOpenCreateTemplateModal,
        onClose,
    ]);

    const handleLoadTemplateSelect = useCallback(
        (obj: ProviderTemplateDetails) => {
            if (!obj) return;

            setSelectedTemplateId(obj.id);
        },
        [],
    );

    const handleSaveAsNewTemplate = useCallback(() => {
        setTemplatePayload({
            ...templatePayload,
            name: `Copy of ${templatePayload.name}`,
        });
        setTemplateId('');
        closeConfirmTemplateSaveModal();
        openNewTemplateNameModal();
    }, [
        closeConfirmTemplateSaveModal,
        setTemplatePayload,
        templatePayload,
        openNewTemplateNameModal,
        setTemplateId,
    ]);

    return (
        <>
            {isFetching && <SuboptimalState loading />}

            {renderTemplateBuilder}

            <ConfirmTemplateSaveModal
                opened={confirmTemplateSaveModalOpened}
                onClose={closeConfirmTemplateSaveModal}
                handleSave={handleTemplateUpdate}
                handleSaveAsNewTemplate={handleSaveAsNewTemplate}
                isLoading={isCreatingTemplate || isUpdatingTemplate}
            />
            <NewTemplateNameModal
                opened={newTemplateNameModalOpened}
                onClose={closeNewTemplateNameModal}
                onSave={() => handleTemplateSave(undefined)}
                isLoading={isCreatingTemplate || isUpdatingTemplate}
            />

            <TemplateOptionsModal
                opened={opened}
                onClose={close}
                providerMetadata={integration}
                integrationId={integrationId}
                handleTemplateSelect={handleLoadTemplateSelect}
                channel={channel}
                showCreateEditTemplate={false}
                templateId={undefined}
                isTemplateLibrary={false}
                editMode={false}
            />
        </>
    );
};

export default React.memo(TemplateBuilder);
