import {
    TemplateActions,
    type Action,
} from '@components/Templates/TemplateActions';
import { useLocale } from '@hooks/useLocale';
import { ActionIcon, Button, Group } from '@mantine/core';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { useTemplateBuilderContext } from '@providers/TemplateBuilderProvider';
import React from 'react';

interface HeaderProps {
    onTitleChange: (title: string) => void;
    onAction: (action: Action) => void;
    onSave: () => void;
}

const Header: React.FC<HeaderProps> = ({ onTitleChange, onAction, onSave }) => {
    const { t } = useLocale();
    const { setOpenCreateTemplateModal } = useTemplateBuilderContext(
        (context) => context.actions,
    );
    const { templatePayload } = useTemplateBuilderContext(
        (context) => context.state,
    );

    return (
        <Group
            className="sticky z-10 w-full px-3 py-2 bg-white border-b border-b-shade-6"
            position="apart"
            noWrap
        >
            <ActionIcon onClick={() => setOpenCreateTemplateModal(false)}>
                <CaretLeft weight="bold" />
            </ActionIcon>
            <TemplateActions
                title={templatePayload.name ?? ''}
                placeholder={t('campaigns_builder_editor.title.placeholder')}
                onTitleChange={onTitleChange}
                onAction={onAction}
            />
            <Button
                rightIcon={<CaretRight weight="regular" color="white" />}
                onClick={onSave}
            >
                Continue
            </Button>
        </Group>
    );
};

export default Header;
