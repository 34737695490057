import { ShowDataType } from '@lightdash/common';
import { Box } from '@mantine/core';
import { flexRender, type Row, type Table } from '@tanstack/react-table';
import { type Virtualizer } from '@tanstack/react-virtual';
import React from 'react';

interface InfinityScrollTableProps<TData> {
    viewType: ShowDataType;
    table: Table<TData>;
    handleRowClick: ((data: Row<TData>) => void) | undefined;
    tableContainerRef: React.RefObject<HTMLDivElement>;
    rowVirtualizer: Virtualizer<any, Element>;
    cellHeight?: string;
    gridSize: number;
}
interface InfinityScrollListProps<TData> {
    table: Table<TData>;
    rowVirtualizer: Virtualizer<any, Element>;
    handleRowClick: ((data: Row<TData>) => void) | undefined;
    cellHeight?: string;
    tableContainerRef: React.RefObject<HTMLDivElement>;
}

interface InfinityScrollGridProps<TData> {
    table: Table<TData>;
    rowVirtualizer: Virtualizer<any, Element>;
    gridSize: number;
    tableContainerRef: React.RefObject<HTMLDivElement>;
}
const createGridRows = (virtualItems: any[], gridSize: number) => {
    const gridRows = [];
    for (let i = 0; i < virtualItems.length; i += gridSize) {
        gridRows.push(virtualItems.slice(i, i + gridSize));
    }
    return gridRows;
};

const InfinityScrollList = <TData,>({
    table,
    rowVirtualizer,
    handleRowClick,
    cellHeight,
    tableContainerRef,
}: InfinityScrollListProps<TData>) => {
    return (
        <Box className="overflow-auto h-[650px]" ref={tableContainerRef}>
            <table className="w-full">
                <thead className="sticky top-0 z-10 bg-white">
                    {table.getHeaderGroups()?.map((headerGroup) => (
                        <tr
                            key={headerGroup.id}
                            className="grid w-full bg-shade-4"
                            style={{
                                gridTemplateColumns: headerGroup.headers
                                    .map(() => `minmax(0, 1fr)`)
                                    .join(' '),
                            }}
                        >
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    className="px-3.5 py-2.5 text-xs font-normal text-left text-gray-500 uppercase bg-shade-4"
                                >
                                    <Box>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                    </Box>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody
                    className={`relative divide-y bg-white`}
                    style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const row = table.getRowModel().rows[virtualRow.index];
                        return (
                            <tr
                                data-index={virtualRow.index}
                                ref={(node) =>
                                    rowVirtualizer.measureElement(node)
                                }
                                key={row.id}
                                className={`grid absolute w-full  ${
                                    handleRowClick
                                        ? 'cursor-pointer hover:bg-gray-50'
                                        : ''
                                }`}
                                style={{
                                    transform: `translateY(${virtualRow.start}px)`,
                                    gridTemplateColumns: row
                                        .getVisibleCells()
                                        .map(() => `minmax(0, 1fr)`)
                                        .join(' '),
                                }}
                                onClick={() =>
                                    handleRowClick && handleRowClick(row)
                                }
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <td
                                        key={cell.id}
                                        className={`px-3.5 py-2.5 ${
                                            cellHeight ?? 'h-16'
                                        }`}
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Box>
    );
};

const InfinityScrollGrid = <TData,>({
    table,
    rowVirtualizer,
    gridSize,
    tableContainerRef,
}: InfinityScrollGridProps<TData>) => {
    const virtualItems = rowVirtualizer.getVirtualItems();
    const gridRows = createGridRows(virtualItems, gridSize);
    return (
        <Box className="overflow-auto h-[650px]" ref={tableContainerRef}>
            <Box
                style={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                }}
            >
                {gridRows.map((gridRow, rowIndex) => (
                    <Box
                        key={rowIndex}
                        className={`grid gap-2 `}
                        style={{
                            transform: `translate-y-${gridRow[0].start}px`,
                            gridTemplateColumns: `repeat(${gridSize}, 1fr)`,
                        }}
                    >
                        {gridRow.map((virtualItem) => {
                            const row =
                                table.getRowModel().rows[virtualItem.index];
                            return (
                                <Box
                                    key={virtualItem.index}
                                    className="flex flex-wrap"
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <Box
                                            key={cell.id}
                                            className="border rounded-lg bg-white"
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            );
                        })}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

const InfinityScrollTable = <TData,>({
    viewType,
    table,
    rowVirtualizer,
    handleRowClick,
    cellHeight,
    tableContainerRef,
    gridSize,
}: InfinityScrollTableProps<TData>) => {
    switch (viewType) {
        case ShowDataType.GRID:
            return (
                <InfinityScrollGrid
                    table={table}
                    rowVirtualizer={rowVirtualizer}
                    gridSize={gridSize}
                    tableContainerRef={tableContainerRef}
                />
            );

        case ShowDataType.LIST:
        default:
            return (
                <InfinityScrollList
                    table={table}
                    rowVirtualizer={rowVirtualizer}
                    handleRowClick={handleRowClick}
                    cellHeight={cellHeight}
                    tableContainerRef={tableContainerRef}
                />
            );
    }
};

export default InfinityScrollTable;
