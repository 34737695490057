import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Select from '@components/common/Select';
import { useLocale } from '@hooks/useLocale';
import {
    useProjectAccess,
    useRevokeProjectAccessMutation,
    useUpdateProjectAccessMutation,
} from '@hooks/useProjectAccess';
import {
    ProjectMemberRole,
    ProjectMemberRoleDescription,
    ProjectMemberRoleLabels,
    ProjectSettings,
    type ProjectMemberProfile,
    type ProjectMemberProfileWithInviteInformation,
} from '@lightdash/common';
import { Box, Button, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useApp } from '@providers/AppProvider';
import { type ColumnDef, type Row } from '@tanstack/react-table';
import { capitalizeFirstCharacter } from '@utils/helpers';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Check, ChevronRight, Send, UserMinus, Users } from 'react-feather';
import { useParams } from 'react-router-dom';
import { ButtonVariant } from '../../../mantineTheme';
// import Modal from '../common/modal/Modal';
import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import Modal from '@components/common/modal/Modal';
import { useCreateInviteLinkMutation } from '@hooks/useInviteLink';
import { useOrganizationUserById } from '@hooks/useOrganizationUsers';
import { useProject } from '@hooks/useProject';
const allowedRoles: ProjectMemberRole[] = [
    ProjectMemberRole.ADMIN,
    ProjectMemberRole.VIEWER,
    ProjectMemberRole.MARKETER,
];
interface WorkspaceMenuItemProps {
    rowData: Row<ProjectMemberProfileWithInviteInformation>;
}
interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    label: string;
    description: string;
}
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, description, ...others }: ItemProps, ref) => (
        <Box ref={ref} {...others}>
            <Box className="flex justify-between">
                <Text className="text-sm text-gray-800 font-medium">
                    {label}
                </Text>

                <Check
                    size={13}
                    strokeWidth={2.5}
                    color={'rgb(var(--color-gray-600))'}
                    className="selected__item--icon-selected"
                />
            </Box>
            <Text className="text-sm text-gray-600 break-normal">
                {description}
            </Text>
        </Box>
    ),
);
const WorksapceMenuItem: React.FC<WorkspaceMenuItemProps> = ({ rowData }) => {
    const { user } = useApp();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { data: currentProject } = useProject(projectUuid);
    const { t } = useLocale();
    const { mutateAsync } = useCreateInviteLinkMutation();
    const { data } = useOrganizationUserById(rowData.original.userUuid);
    const [selectedRole, setSelectedRole] = useState<ProjectMemberRole>(
        rowData.original.role,
    );
    const { mutate: deleteUser } = useRevokeProjectAccessMutation(projectUuid);
    const { mutate: updateUser } = useUpdateProjectAccessMutation(projectUuid);
    const [leaveWorkspace, setLeaveWorkspace] = useState(false);
    const ability = useAbilityContext();
    const canEditProjectAccess = ability.can(
        'update',
        subject(ProjectSettings.members, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const handleUserUpdateClick = useCallback(() => {
        updateUser({ role: selectedRole, userUuid: rowData.original.userUuid });
    }, [selectedRole, rowData, updateUser]);
    const [
        openedChangeMemberRole,
        { open: openChangeMemberRole, close: closeChangeMemberRole },
    ] = useDisclosure(false);
    const [
        confirmRemoveMember,
        { open: openConfirmRemoveMember, close: closeConfirmRemoveMember },
    ] = useDisclosure(false);
    const [
        confirmLeaveWorkspace,
        { open: openConfirmLeaveWorkspace, close: closeConfirmLeaveWorkspace },
    ] = useDisclosure(false);
    const [
        confirmRevokeInvite,
        { open: openConfirmRevokeInvite, close: closeConfirmRevokeInvite },
    ] = useDisclosure(false);
    const [
        confirmResendInvite,
        { open: openConfirmResendInvite, close: closeConfirmResendInvite },
    ] = useDisclosure(false);
    const createChangeMemberRoleItem = useCallback(
        () => ({
            leftSection: (
                <Users
                    size={14}
                    color={'rgb(var(--color-gray-500))'}
                    strokeWidth={2.5}
                />
            ),
            children: (
                <div>{t('organization_settings.admin_change_member_role')}</div>
            ),
            onClick: () => openChangeMemberRole(),
        }),
        [t, openChangeMemberRole],
    );
    const createRemoveMemberItem = useCallback(
        () => ({
            leftSection: (
                <UserMinus
                    size={14}
                    color={'rgb(var(--color-gray-500))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('organization_settings.admin_remove_member'),
            onClick: () => openConfirmRemoveMember(),
        }),
        [openConfirmRemoveMember, t],
    );
    const createLeaveWorkspaceItem = useCallback(
        () => ({
            leftSection: (
                <UserMinus
                    size={14}
                    color={'rgb(var(--color-gray-500))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('organization_settings.admin_leave_organization'),
            onClick: () => openConfirmLeaveWorkspace(),
        }),
        [openConfirmLeaveWorkspace, t],
    );
    const createRevokeInviteItem = useCallback(
        () => ({
            leftSection: (
                <UserMinus
                    size={14}
                    color={'rgb(var(--color-gray-500))'}
                    strokeWidth={2.5}
                />
            ),
            children: ` ${t(
                'organization_settings.admin_revokes_member_invite',
            )}`,
            onClick: () => openConfirmRevokeInvite(),
        }),
        [openConfirmRevokeInvite, t],
    );
    const createResendInviteItem = useCallback(
        () => ({
            leftSection: (
                <Send
                    size={14}
                    color={'rgb(var(--color-gray-500))'}
                    strokeWidth={2.5}
                />
            ),
            children: ` ${t(
                'organization_settings.admin_resend_member_invite',
            )}`,
            onClick: async () => {
                try {
                    openConfirmResendInvite();
                    await mutateAsync({
                        email: rowData.original?.email ?? '',
                        role: data?.role,
                    });
                } catch (_err) {}
            },
        }),
        [openConfirmResendInvite, data, mutateAsync, t, rowData],
    );

    const menuItems = useMemo(() => {
        let items: CustomMenuItemProps<ProjectMemberProfile>[] = [];

        if (user.data?.userUuid === rowData.original.userUuid) {
            items = [createLeaveWorkspaceItem()];
            setLeaveWorkspace(true);
        } else {
            setLeaveWorkspace(false);
            if (
                !rowData?.original?.isActive &&
                !rowData?.original?.isInviteExpired
            ) {
                items = [createRevokeInviteItem()];
            } else if (
                rowData?.original?.isInviteExpired &&
                !rowData?.original?.isActive
            ) {
                items = [createResendInviteItem()];
            } else {
                items = [
                    createChangeMemberRoleItem(),
                    createRemoveMemberItem(),
                ];
            }
        }
        return items;
    }, [
        createChangeMemberRoleItem,

        createRemoveMemberItem,
        createLeaveWorkspaceItem,
        createResendInviteItem,
        createRevokeInviteItem,
        rowData,
        user,
    ]);
    return (
        <>
            {(canEditProjectAccess || leaveWorkspace) && (
                <CustomMenu<ProjectMemberProfile>
                    menuItems={menuItems}
                    data={rowData.original}
                />
            )}
            <Modal
                opened={confirmLeaveWorkspace}
                onClose={closeConfirmLeaveWorkspace}
                title={t('workspace_settings.admin_leave_workspace')}
                footerRightSection={
                    <Group spacing="xs">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={closeConfirmLeaveWorkspace}
                        >
                            {t(
                                'organization_settings.admin_remove_member_modal_back_button',
                            )}
                        </Button>

                        <Button
                            form="add_user_to_project"
                            type="submit"
                            variant={ButtonVariant.FILLED_DESTRUCTIVE}
                            rightIcon={
                                <ChevronRight size={13} strokeWidth={2.5} />
                            }
                            onClick={() => {
                                deleteUser(rowData.original.userUuid);

                                closeConfirmLeaveWorkspace();
                            }}
                        >
                            {t(
                                'workspace_settings.admin_leave_modal_confirm_button',
                            )}
                        </Button>
                    </Group>
                }
            >
                {t('workspace_settings.admin_leave_modal_description')}
            </Modal>
            <Modal
                opened={confirmRemoveMember}
                onClose={closeConfirmRemoveMember}
                className="modal__close--posChange-top"
                title={
                    <Box>
                        {t('organization_settings.admin_remove_member')}
                        <Text className="text-gray-300 ">
                            {t(
                                'organization_settings.admin_remove_member_modal_from',
                            )}{' '}
                            <Box className="text-gray-500 inline">
                                {currentProject?.name}
                            </Box>{' '}
                        </Text>
                    </Box>
                }
                footerRightSection={
                    <Group spacing="xs">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={closeConfirmRemoveMember}
                        >
                            {t(
                                'organization_settings.admin_remove_member_modal_back_button',
                            )}
                        </Button>

                        <Button
                            form="add_user_to_project"
                            type="submit"
                            variant={ButtonVariant.FILLED_DESTRUCTIVE}
                            rightIcon={
                                <ChevronRight size={13} strokeWidth={2.5} />
                            }
                            onClick={() => {
                                deleteUser(rowData.original.userUuid);

                                closeConfirmRemoveMember();
                            }}
                        >
                            {t(
                                'organization_settings.admin_remove_member_modal_okay_button',
                            )}
                        </Button>
                    </Group>
                }
                closeButtonProps={{
                    className: 'absolute top-4 right-3',
                }}
            >
                {t('workspace_settings.admin_remove_member_modal_description')}
            </Modal>
            <Modal
                opened={openedChangeMemberRole}
                onClose={closeChangeMemberRole}
                keepMounted={false}
                title={t('workspace_settings.change_role_title')}
                size="lg"
                footerRightSection={
                    <Box className="flex gap-2">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={closeChangeMemberRole}
                        >
                            {t(
                                'organization_settings.admin_change_role_cancel',
                            )}
                        </Button>

                        <Button
                            form="add_user_to_project"
                            type="submit"
                            onClick={handleUserUpdateClick}
                            rightIcon={
                                <ChevronRight size={13} strokeWidth={2.5} />
                            }
                            disabled={false}
                        >
                            {t(
                                'organization_settings.admin_change_role_confirm',
                            )}
                        </Button>
                    </Box>
                }
            >
                <Select
                    label={
                        <Box className="text-gray-500 whitespace-nowrap py-0">
                            <Box className="inline text-gray-300">
                                {t(
                                    'organization_settings.change_role_select_label',
                                )}
                            </Box>{' '}
                            {rowData?.original?.firstName}{' '}
                            {`(${rowData?.original?.email})`}
                        </Box>
                    }
                    itemComponent={SelectItem}
                    data={Object.values(ProjectMemberRole)
                        .filter((role) => allowedRoles.indexOf(role) > -1)
                        .map((orgMemberRole: ProjectMemberRole) => ({
                            value: orgMemberRole,
                            label: ProjectMemberRoleLabels[orgMemberRole],
                            description:
                                ProjectMemberRoleDescription[orgMemberRole],
                        }))}
                    required
                    dropdownPosition="bottom"
                    maxDropdownHeight={400}
                    withinPortal
                    className="!w-2/5"
                    withAsterisk={false}
                    value={selectedRole}
                    onChange={(value) => {
                        setSelectedRole(value as ProjectMemberRole);
                    }}
                    disabled={false}
                />
            </Modal>
            <Modal
                opened={confirmRevokeInvite}
                onClose={closeConfirmRevokeInvite}
                title={t('organization_settings.admin_revokes_member_invite')}
                footerRightSection={
                    <Group spacing="xs">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={closeConfirmRevokeInvite}
                        >
                            {t(
                                'organization_settings.admin_remove_member_modal_back_button',
                            )}
                        </Button>

                        <Button
                            form="add_user_to_project"
                            type="submit"
                            rightIcon={
                                <ChevronRight size={13} strokeWidth={2.5} />
                            }
                            onClick={() => {
                                deleteUser(rowData.original.userUuid);
                                closeConfirmRevokeInvite();
                            }}
                        >
                            {t(
                                'organization_settings.admin_revokes_member_invite_modal_confirm_button',
                            )}
                        </Button>
                    </Group>
                }
            >
                {t(
                    'organization_settings.admin_revokes_member_invite_modal_description',
                )}
            </Modal>
            <Modal
                title={t(
                    'organization_settings.admin_resend_member_invite_modal_title',
                )}
                opened={confirmResendInvite}
                onClose={() => {
                    closeConfirmResendInvite();
                }}
                footerRightSection={
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        onClick={() => {
                            closeConfirmResendInvite();
                        }}
                    >
                        {t(
                            'workspace_settings.data_connection_request_modal_button',
                        )}
                    </Button>
                }
            >
                {t(
                    'organization_settings.admin_resend_member_invite_modal_description',
                )}
            </Modal>
        </>
    );
};
export const useWorkspaceMembersColumns = () => {
    const [columns, setColumns] =
        useState<ColumnDef<ProjectMemberProfileWithInviteInformation>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const { user } = useApp();
    const { data: users } = useProjectAccess(projectUuid as string);
    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: t('workspace_members_table.th_name'),
                    cell: ({ row }) => (
                        <Box className="flex flex-col gap-0.5 justify-center items-start">
                            <Text className="text-sm font-medium text-gray-800">
                                {row.original.firstName} {row.original.lastName}
                                {user?.data?.userUuid ===
                                    row.original.userUuid && (
                                    <span className="font-normal text-gray-500">
                                        {' '}
                                        {t(
                                            'workspace_members_table.tr_name_logged_in_user_identifier',
                                        )}
                                    </span>
                                )}
                            </Text>

                            {row.original.email && (
                                <div>
                                    <Text className="text-xs font-normal text-gray-600 inline">
                                        {row.original.email}
                                    </Text>
                                    {!row.original.isActive && (
                                        <Text
                                            variant="outline"
                                            size="xs"
                                            className={`inline rounded border px-1 mx-1' ${
                                                row.original.isInviteExpired
                                                    ? 'border-red-100 text-red-400'
                                                    : 'border-amber-100 text-amber-400'
                                            }`}
                                        >
                                            {row.original.isInviteExpired
                                                ? t(
                                                      'organization_settings.admin_invite_expired_button',
                                                  )
                                                : t(
                                                      'organization_settings.admin_invite_sent_button',
                                                  )}
                                        </Text>
                                    )}
                                </div>
                            )}
                        </Box>
                    ),
                },
                {
                    accessorKey: 'role',
                    header: t('workspace_members_table.th_role'),
                    cell: ({ getValue }) => (
                        <Text className="text-xs font-normal text-gray-800">
                            {capitalizeFirstCharacter(getValue<string>())}
                        </Text>
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => {
                        return <WorksapceMenuItem rowData={row} />;
                    },
                },
            ]);
        }
    }, [projectUuid, t, user?.data?.userUuid, users]);

    return columns;
};
