import { useLocale } from '@hooks/useLocale';
import { Box, Divider, Stack, Text, Title } from '@mantine/core';
import React from 'react';
import JourneySettingsMenuGroups, {
    type JourneySettingType,
    type SettingListItemType,
} from './JourneySettingsMenuLists';

const SettingsGroup = ({
    settingsGroup,
    groupIndex,
    settingsType,
    onSettingChange,
}: {
    settingsGroup: SettingListItemType[];
    groupIndex: number;
    settingsType: JourneySettingType;
    onSettingChange: (type: JourneySettingType) => void;
}) => (
    <>
        <Stack key={groupIndex} className="gap-1.5 py-3.5 mx-3.5">
            {settingsGroup.map((item, itemIndex) => (
                <Box
                    onClick={() => onSettingChange(item.type)}
                    className={`cursor-pointer flex p-2 rounded-lg items-center ${
                        item.type === settingsType ? 'bg-gray-200' : ''
                    }`}
                    key={`item-${item.label || itemIndex}`}
                >
                    {item.leftSection && item.leftSection}
                    <Text className="ml-1 text-sm font-semibold text-gray-700">
                        {item.label}
                    </Text>
                </Box>
            ))}
        </Stack>
        {groupIndex < JourneySettingsMenuGroups.length - 1 && (
            <Divider className="mx-3.5 border-t-shade-4" />
        )}
    </>
);

interface JourneySettingsSidebarProps {
    settingsType: JourneySettingType;
    onSettingChange: (type: JourneySettingType) => void;
}

const JourneySettingsSidebar: React.FC<JourneySettingsSidebarProps> = ({
    settingsType,
    onSettingChange,
}) => {
    const { t } = useLocale();

    const renderedSettingsGroups = JourneySettingsMenuGroups.map(
        (group, index) => (
            <React.Fragment key={`settings-group-${index}`}>
                <SettingsGroup
                    settingsType={settingsType}
                    onSettingChange={onSettingChange}
                    settingsGroup={group}
                    groupIndex={index}
                />
            </React.Fragment>
        ),
    );

    return (
        <Stack sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <Stack sx={{ flexGrow: 1, overflow: 'auto' }} spacing={'none'}>
                <Box>
                    <Title className="px-6 pt-6 text-xs font-semibold text-gray-500">
                        {t('journey_settings.journey_settings')}
                    </Title>
                </Box>
                <Box>{renderedSettingsGroups}</Box>
            </Stack>
        </Stack>
    );
};

export default JourneySettingsSidebar;
