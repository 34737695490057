import { Button, Group, Loader as LoaderIcon } from '@mantine/core';
import { CheckCircle } from '@phosphor-icons/react';
import { t as translate } from 'i18next';
import React, { type ReactNode } from 'react';
import { ButtonVariant } from '../../../../mantineTheme';

export interface ButtonGroupProps {
    secondaryButtonLabel: string | undefined;
    primaryButtonLabel: string | undefined;
    handleSecondaryButtonClick: () => void;
    handlePrimaryButtonClick: () => void;
    isSecondaryButtonDisabled?: boolean;
    isPrimaryButtonDisabled?: boolean;
    primaryButtonIcon?: ReactNode;
    isUpdating: boolean;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
    secondaryButtonLabel = translate('common.cancel'),
    primaryButtonLabel = translate('common.save'),
    handleSecondaryButtonClick,
    handlePrimaryButtonClick,
    isSecondaryButtonDisabled,
    isPrimaryButtonDisabled,
    primaryButtonIcon = <CheckCircle color="white" />,
    isUpdating,
}) => {
    return (
        <Group>
            <Button
                variant={ButtonVariant.OUTLINED}
                onClick={handleSecondaryButtonClick}
                disabled={isSecondaryButtonDisabled}
            >
                {secondaryButtonLabel}
            </Button>
            <Button
                disabled={isPrimaryButtonDisabled}
                leftIcon={
                    isUpdating ? (
                        <LoaderIcon color="white" size={14} />
                    ) : (
                        primaryButtonIcon
                    )
                }
                onClick={handlePrimaryButtonClick}
            >
                {isUpdating ? 'Saving...' : primaryButtonLabel}
            </Button>
        </Group>
    );
};

export default ButtonGroup;
