import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import DataTable from '@components/Table';
import { useLocale } from '@hooks/useLocale';
import { useSortedUsers } from '@hooks/useSortedUsers';
import {
    ShowDataType,
    type ProjectMemberProfileWithInviteInformation,
} from '@lightdash/common';
import { useApp } from '@providers/AppProvider';
import React from 'react';
import { useProjectAccessWithLinkInfo } from '../../../hooks/useProjectAccess';
import { useWorkspaceMembersColumns } from './useWorkspaceMembersTableUtils';
interface WorkspaceMembersTableProps {
    projectUuid: string;
}
export const filterSuperAdmin = <
    T extends { isInternal?: boolean; userUuid: string },
>(
    users: T[],
    userUuid: string,
) => {
    return users.filter(
        (eachUser) => !eachUser.isInternal || eachUser.userUuid === userUuid,
    );
};

const WorkspaceMembersTable: React.FC<WorkspaceMembersTableProps> = ({
    projectUuid,
}) => {
    const { data: projectAccess, isLoading: isProjectAccessLoading } =
        useProjectAccessWithLinkInfo(projectUuid);
    const { user } = useApp();
    const workspaceMembersColumns = useWorkspaceMembersColumns();
    const { t } = useLocale();
    const { sortedUsers } =
        useSortedUsers<ProjectMemberProfileWithInviteInformation>(
            projectAccess,
        );

    return (
        <div>
            {isProjectAccessLoading ? (
                <SuboptimalState />
            ) : (
                projectAccess &&
                workspaceMembersColumns && (
                    <DataTable<ProjectMemberProfileWithInviteInformation>
                        tableData={filterSuperAdmin<ProjectMemberProfileWithInviteInformation>(
                            sortedUsers || projectAccess,
                            user?.data?.userUuid || '',
                        )}
                        options={[
                            {
                                format: ShowDataType.LIST,
                                formatData: workspaceMembersColumns,
                            },
                        ]}
                        isManualPagination={false}
                        searchPlaceholder={t(
                            'workspace_members.table_search_placeholder',
                        )}
                        paginationText={t('workspace_members.pagination_text')}
                        isEditable={false}
                        // withBorder
                    />
                )
            )}
        </div>
    );
};

export default React.memo(WorkspaceMembersTable);
