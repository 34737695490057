import TextInput from '@components/common/Inputs/TextInput';
import Select from '@components/common/Select';
import { useLocale } from '@hooks/useLocale';
import { type HttpConfig } from '@lightdash/common';
import { Flex } from '@mantine/core';
import React, { useState } from 'react';
import {
    CallAPIMethod,
    revertStringifyStringWithVariables,
    stringifyStringWithVariables,
} from '../utils';

interface EndPointSelectProps {
    onHttpConfigChange: (value: HttpConfig) => void;
    httpConfig: HttpConfig;
}

const EndPointSelect: React.FC<EndPointSelectProps> = ({
    onHttpConfigChange,
    httpConfig,
}) => {
    const [error, setError] = useState<string | null>(null);
    const { t } = useLocale();

    const handleUrlChange = (value: string) => {
        onHttpConfigChange({
            ...httpConfig,
            url: stringifyStringWithVariables(value),
        });
    };

    const validateEndpoint = (value: string) => {
        const urlPattern =
            /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
        const handlebarsPattern = /\{\{.*?\}\}/g;

        // Remove Handlebars expressions for URL validation
        const cleanUrl = value.replace(handlebarsPattern, '');

        if (!urlPattern.test(cleanUrl) && !handlebarsPattern.test(value)) {
            setError(t('journey_builder.call_api_endpoint_error'));
        } else {
            setError(null);
        }

        handleUrlChange(value);
    };

    return (
        <Flex align="center" gap="md">
            <Select
                withinPortal
                label={t('journey_builder.call_api_method_label')}
                dropdownPosition="bottom"
                defaultValue={httpConfig.method}
                data={Object.values(CallAPIMethod)}
                className="!w-[6rem]"
                onChange={(value) => {
                    if (value) {
                        const method = value as CallAPIMethod;
                        onHttpConfigChange({
                            ...httpConfig,
                            method,
                        });
                    }
                }}
            />

            <TextInput
                placeholder={t('journey_builder.call_api_endpoint_placeholder')}
                className="!min-w-[18rem]"
                label={t('journey_builder.call_api_endpoint_label')}
                value={revertStringifyStringWithVariables(httpConfig.url)}
                onChange={(e) => {
                    validateEndpoint(e.target.value);
                }}
                error={
                    revertStringifyStringWithVariables(httpConfig.url) && error
                }
            />
        </Flex>
    );
};

export default React.memo(EndPointSelect);
