import { generateFriendlyLabel } from '../utils/utils';
import { type UUID } from './api/uuid';
import { DimensionType } from './field';

export const COLUMN_INFO = 'columnInfo';
export const EVENT_TABLE_PREFIX = 'raw_events';
export const DEDUPED_EVENTS_TABLE_PREFIX = 'srtdedupedevents';
export const DEDUPED_SUBSCRIPTION_EVENTS_TABLE_PREFIX = 'subscriptionevents';
export const DELIVERY_CONTROL_EVENTS_TABLE_PREFIX = 'deliverycontrolevents';
export const SUBSCRIPTION_EVENT_NAME = 'SUBSCRIPTION_EVENT';
export const DELIVERY_CONTROL_EVENT_NAME = 'DELIVERY_CONTROL_EVENT';
export const SOURCE_EVENT_KEY_MAPPINGS_TABLE = 'source_event_key_mappings';

export enum Sources {
    GUPSHUP = 'gupshup',
    TWILIO = 'twilio',
    SENDGRID = 'sendgrid',
    FYNO = 'fyno',
    SRT_INTERNAL = 'srt_internal',
}

export enum ReservedCampaignEventColumns {
    MSG_ID = 'srt_cmp_msg_id',
    STATUS = 'srt_cmp_status',
    CUSTOM_ID = 'srt_cmp_custom_id',
    RECIPENT = 'srt_cmp_recipent',
    PROVIDER_NAME = 'srt_cmp_provider_name',
    MESSAGE = 'srt_cmp_message',
    REQUEST_TIME = 'srt_cmp_request_time',
    DELIVERY_TIME = 'srt_cmp_delivery_time',
    ACCOUNT_NAME = 'srt_cmp_account_name',
    CHANNEL = 'srt_cmp_channel',
    CAMPAIGN_NAME = 'srt_campaign_name',
    // This also should not change frequently
}
export enum CommonEventColumns {
    CLIENT_EVENT_ID = 'srt_client_event_id',
    CLIENT_TIMESTAMP = 'srt_client_timestamp',
    // Add more common columns here
}

export enum SubscriptionEventColumns {
    CHANNEL_NAME = 'srt_subscription_channel_name',
    SUBSCRIPTION_GROUP_ID = 'srt_subscription_group_id',
    SUBSCRIPTION_GROUP_TYPE = 'srt_subscription_group_type',
    USER_ACTION_TAKEN = 'srt_subscription_user_action_taken',
    SUBSCRIBE_TIMESTAMP = 'srt_subscribe_timestamp',
    UNSUBSCRIBE_TIMESTAMP = 'srt_unsubscribe_timestamp',
}

export enum DeliveryControlEventsColumns {
    DELIVERY_CONTROL_RULE_ID = 'srt_delivery_control_rule_id',
    DELIVERY_CONTROL_RULE_COUNTER = 'srt_delivery_control_rule_counter',
}

export enum ReservedEventColumns {
    SOURCE = 'srt_source',
    EVENT_NAME = 'srt_event_name',
    RAW_PAYLOAD = 'srt_raw_payload',
    EVENT_ID = 'srt_event_id',
    SERVER_TIMESTAMP = 'srt_server_timestamp',
    EVENT_MAPPING_STATUS = 'srt_event_mapping_status',
    MAPPING_ERRORS = 'srt_mapping_errors',
    USER_ID = 'srt_user_id',
    AUDIENCE_ID = 'srt_audience_id',
    // This should not be changed frequently
}

export type MappedEvent = {
    [ReservedEventColumns.SOURCE]: string;
    [ReservedEventColumns.RAW_PAYLOAD]: string;
    [ReservedEventColumns.EVENT_ID]: string;
    [ReservedEventColumns.EVENT_NAME]: string;
    [ReservedEventColumns.SERVER_TIMESTAMP]: number;
    [ReservedEventColumns.EVENT_MAPPING_STATUS]: string;
    [ReservedEventColumns.MAPPING_ERRORS]: string;
    [ReservedEventColumns.USER_ID]: string;
    [ReservedEventColumns.AUDIENCE_ID]: string;
    [CommonEventColumns.CLIENT_EVENT_ID]: string;
    [CommonEventColumns.CLIENT_TIMESTAMP]: number | null;
    [ReservedCampaignEventColumns.MSG_ID]: string | null;
    [ReservedCampaignEventColumns.STATUS]: string | null;
    [ReservedCampaignEventColumns.CUSTOM_ID]: string | null;
    [ReservedCampaignEventColumns.RECIPENT]: string | null;
    [ReservedCampaignEventColumns.PROVIDER_NAME]: string | null;
    [ReservedCampaignEventColumns.MESSAGE]: string | null;
    [ReservedCampaignEventColumns.REQUEST_TIME]: number | null;
    [ReservedCampaignEventColumns.DELIVERY_TIME]: number | null;
    [ReservedCampaignEventColumns.ACCOUNT_NAME]: string | null;
    [ReservedCampaignEventColumns.CHANNEL]: string | null;
    [ReservedCampaignEventColumns.CAMPAIGN_NAME]: string | null;
    [SubscriptionEventColumns.CHANNEL_NAME]: string | null;
    [SubscriptionEventColumns.SUBSCRIPTION_GROUP_ID]: string | null;
    [SubscriptionEventColumns.SUBSCRIPTION_GROUP_TYPE]: string | null;
    [SubscriptionEventColumns.USER_ACTION_TAKEN]: string | null;
    [SubscriptionEventColumns.SUBSCRIBE_TIMESTAMP]: number | null;
    [SubscriptionEventColumns.UNSUBSCRIBE_TIMESTAMP]: number | null;
    [DeliveryControlEventsColumns.DELIVERY_CONTROL_RULE_COUNTER]: number | null;
    [DeliveryControlEventsColumns.DELIVERY_CONTROL_RULE_ID]: string | null;
    [key: string]: string | number | null;
};

export const createEmptyMappedEvent = (): MappedEvent => ({
    [ReservedEventColumns.SOURCE]: '',
    [ReservedEventColumns.RAW_PAYLOAD]: '',
    [ReservedEventColumns.EVENT_ID]: '',
    [ReservedEventColumns.EVENT_NAME]: '',
    [ReservedEventColumns.SERVER_TIMESTAMP]: new Date().getTime(),
    [ReservedEventColumns.EVENT_MAPPING_STATUS]: '',
    [ReservedEventColumns.MAPPING_ERRORS]: '',
    [ReservedEventColumns.USER_ID]: '',
    [ReservedEventColumns.AUDIENCE_ID]: '',
    [CommonEventColumns.CLIENT_EVENT_ID]: '',
    [CommonEventColumns.CLIENT_TIMESTAMP]: null,
    [ReservedCampaignEventColumns.MSG_ID]: null,
    [ReservedCampaignEventColumns.STATUS]: null,
    [ReservedCampaignEventColumns.CUSTOM_ID]: null,
    [ReservedCampaignEventColumns.RECIPENT]: null,
    [ReservedCampaignEventColumns.PROVIDER_NAME]: null,
    [ReservedCampaignEventColumns.MESSAGE]: null,
    [ReservedCampaignEventColumns.REQUEST_TIME]: null,
    [ReservedCampaignEventColumns.DELIVERY_TIME]: null,
    [ReservedCampaignEventColumns.ACCOUNT_NAME]: null,
    [ReservedCampaignEventColumns.CHANNEL]: null,
    [ReservedCampaignEventColumns.CAMPAIGN_NAME]: null,
    [SubscriptionEventColumns.CHANNEL_NAME]: '',
    [SubscriptionEventColumns.SUBSCRIPTION_GROUP_ID]: '',
    [SubscriptionEventColumns.SUBSCRIPTION_GROUP_TYPE]: '',
    [SubscriptionEventColumns.USER_ACTION_TAKEN]: '',
    [SubscriptionEventColumns.SUBSCRIBE_TIMESTAMP]: null,
    [SubscriptionEventColumns.UNSUBSCRIBE_TIMESTAMP]: null,
    [DeliveryControlEventsColumns.DELIVERY_CONTROL_RULE_COUNTER]: null,
    [DeliveryControlEventsColumns.DELIVERY_CONTROL_RULE_ID]: null,
});

export type MappedEventColumn = {
    [key in keyof MappedEvent]: CreateEventColumn;
};

// varchar -> datatype default varchar, string -> datatype varchar(MAX)
export const MappedEventColumnDetails: MappedEventColumn = {
    [ReservedEventColumns.SOURCE]: {
        columnName: ReservedEventColumns.SOURCE,
        columnDataType: DimensionType.STRING,
        nullable: false,
        persistToWh: true,
        label: generateFriendlyLabel(ReservedEventColumns.SOURCE, '_'),
    },
    [ReservedEventColumns.RAW_PAYLOAD]: {
        columnName: ReservedEventColumns.RAW_PAYLOAD,
        columnDataType: DimensionType.STRING,
        nullable: false,
        persistToWh: true,
        label: generateFriendlyLabel(ReservedEventColumns.RAW_PAYLOAD, '_'),
    },
    [ReservedEventColumns.EVENT_ID]: {
        columnName: ReservedEventColumns.EVENT_ID,
        columnDataType: DimensionType.STRING,
        nullable: false,
        persistToWh: true,
        label: generateFriendlyLabel(ReservedEventColumns.EVENT_ID, '_'),
    },
    [ReservedEventColumns.EVENT_NAME]: {
        columnName: ReservedEventColumns.EVENT_NAME,
        columnDataType: DimensionType.STRING,
        nullable: false,
        persistToWh: true,
        label: generateFriendlyLabel(ReservedEventColumns.EVENT_NAME, '_'),
    },
    [ReservedEventColumns.SERVER_TIMESTAMP]: {
        columnName: ReservedEventColumns.SERVER_TIMESTAMP,
        columnDataType: DimensionType.TIMESTAMP,
        nullable: false,
        persistToWh: true,
        label: generateFriendlyLabel(
            ReservedEventColumns.SERVER_TIMESTAMP,
            '_',
        ),
    },
    [ReservedEventColumns.EVENT_MAPPING_STATUS]: {
        columnName: ReservedEventColumns.EVENT_MAPPING_STATUS,
        columnDataType: DimensionType.STRING,
        nullable: false,
        persistToWh: true,
        label: generateFriendlyLabel(
            ReservedEventColumns.EVENT_MAPPING_STATUS,
            '_',
        ),
    },
    [ReservedEventColumns.MAPPING_ERRORS]: {
        columnName: ReservedEventColumns.MAPPING_ERRORS,
        columnDataType: DimensionType.STRING,
        nullable: false,
        persistToWh: true,
        label: generateFriendlyLabel(ReservedEventColumns.MAPPING_ERRORS, '_'),
    },
    [ReservedEventColumns.USER_ID]: {
        columnName: ReservedEventColumns.USER_ID,
        columnDataType: DimensionType.STRING,
        nullable: false,
        persistToWh: true,
        label: generateFriendlyLabel(ReservedEventColumns.USER_ID, '_'),
    },
    [ReservedEventColumns.AUDIENCE_ID]: {
        columnName: ReservedEventColumns.AUDIENCE_ID,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(ReservedEventColumns.AUDIENCE_ID, '_'),
    },
    [CommonEventColumns.CLIENT_EVENT_ID]: {
        columnName: CommonEventColumns.CLIENT_EVENT_ID,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(CommonEventColumns.CLIENT_EVENT_ID, '_'),
    },
    [CommonEventColumns.CLIENT_TIMESTAMP]: {
        columnName: CommonEventColumns.CLIENT_TIMESTAMP,
        columnDataType: DimensionType.TIMESTAMP,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(CommonEventColumns.CLIENT_TIMESTAMP, '_'),
    },
    [ReservedCampaignEventColumns.MSG_ID]: {
        columnName: ReservedCampaignEventColumns.MSG_ID,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(ReservedCampaignEventColumns.MSG_ID, '_'),
    },
    [ReservedCampaignEventColumns.STATUS]: {
        columnName: ReservedCampaignEventColumns.STATUS,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(ReservedCampaignEventColumns.STATUS, '_'),
    },
    [ReservedCampaignEventColumns.CUSTOM_ID]: {
        columnName: ReservedCampaignEventColumns.CUSTOM_ID,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            ReservedCampaignEventColumns.CUSTOM_ID,
            '_',
        ),
    },
    [ReservedCampaignEventColumns.RECIPENT]: {
        columnName: ReservedCampaignEventColumns.RECIPENT,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            ReservedCampaignEventColumns.RECIPENT,
            '_',
        ),
    },
    [ReservedCampaignEventColumns.PROVIDER_NAME]: {
        columnName: ReservedCampaignEventColumns.PROVIDER_NAME,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            ReservedCampaignEventColumns.PROVIDER_NAME,
            '_',
        ),
    },
    [ReservedCampaignEventColumns.MESSAGE]: {
        columnName: ReservedCampaignEventColumns.MESSAGE,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(ReservedCampaignEventColumns.MESSAGE, '_'),
    },
    [ReservedCampaignEventColumns.REQUEST_TIME]: {
        columnName: ReservedCampaignEventColumns.REQUEST_TIME,
        columnDataType: DimensionType.TIMESTAMP,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            ReservedCampaignEventColumns.REQUEST_TIME,
            '_',
        ),
    },
    [ReservedCampaignEventColumns.DELIVERY_TIME]: {
        columnName: ReservedCampaignEventColumns.DELIVERY_TIME,
        columnDataType: DimensionType.TIMESTAMP,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            ReservedCampaignEventColumns.DELIVERY_TIME,
            '_',
        ),
    },
    [ReservedCampaignEventColumns.ACCOUNT_NAME]: {
        columnName: ReservedCampaignEventColumns.ACCOUNT_NAME,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            ReservedCampaignEventColumns.ACCOUNT_NAME,
            '_',
        ),
    },
    [ReservedCampaignEventColumns.CHANNEL]: {
        columnName: ReservedCampaignEventColumns.CHANNEL,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(ReservedCampaignEventColumns.CHANNEL, '_'),
    },
    [ReservedCampaignEventColumns.CAMPAIGN_NAME]: {
        columnName: ReservedCampaignEventColumns.CAMPAIGN_NAME,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            ReservedCampaignEventColumns.CAMPAIGN_NAME,
            '_',
        ),
    },
    [SubscriptionEventColumns.CHANNEL_NAME]: {
        columnName: SubscriptionEventColumns.CHANNEL_NAME,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            SubscriptionEventColumns.CHANNEL_NAME,
            '_',
        ),
    },
    [SubscriptionEventColumns.SUBSCRIPTION_GROUP_ID]: {
        columnName: SubscriptionEventColumns.SUBSCRIPTION_GROUP_ID,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            SubscriptionEventColumns.SUBSCRIPTION_GROUP_ID,
            '_',
        ),
    },
    [SubscriptionEventColumns.SUBSCRIPTION_GROUP_TYPE]: {
        columnName: SubscriptionEventColumns.SUBSCRIPTION_GROUP_TYPE,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            SubscriptionEventColumns.SUBSCRIPTION_GROUP_TYPE,
            '_',
        ),
    },
    [SubscriptionEventColumns.USER_ACTION_TAKEN]: {
        columnName: SubscriptionEventColumns.USER_ACTION_TAKEN,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            SubscriptionEventColumns.USER_ACTION_TAKEN,
            '_',
        ),
    },
    [SubscriptionEventColumns.SUBSCRIBE_TIMESTAMP]: {
        columnName: SubscriptionEventColumns.SUBSCRIBE_TIMESTAMP,
        columnDataType: DimensionType.TIMESTAMP,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            SubscriptionEventColumns.SUBSCRIBE_TIMESTAMP,
            '_',
        ),
    },
    [SubscriptionEventColumns.UNSUBSCRIBE_TIMESTAMP]: {
        columnName: SubscriptionEventColumns.UNSUBSCRIBE_TIMESTAMP,
        columnDataType: DimensionType.TIMESTAMP,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            SubscriptionEventColumns.UNSUBSCRIBE_TIMESTAMP,
            '_',
        ),
    },
    [DeliveryControlEventsColumns.DELIVERY_CONTROL_RULE_COUNTER]: {
        columnName: DeliveryControlEventsColumns.DELIVERY_CONTROL_RULE_COUNTER,
        columnDataType: DimensionType.NUMBER,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            DeliveryControlEventsColumns.DELIVERY_CONTROL_RULE_COUNTER,
            '_',
        ),
    },
    [DeliveryControlEventsColumns.DELIVERY_CONTROL_RULE_ID]: {
        columnName: DeliveryControlEventsColumns.DELIVERY_CONTROL_RULE_ID,
        columnDataType: DimensionType.STRING,
        nullable: true,
        persistToWh: true,
        label: generateFriendlyLabel(
            DeliveryControlEventsColumns.DELIVERY_CONTROL_RULE_ID,
            '_',
        ),
    },
};

export enum QueueType {
    SQLITE = 'SQLITE',
    KAFKA = 'KAFKA',
}

export enum STATUSES {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

export enum MapperType {
    VALUE = 'VALUE',
    CONCAT = 'CONCAT',
    CUSTOM = 'CUSTOM',
}

export type MapperConfig = ValueConfig | ConcatConfig | CustomConfig;

export type ValueConfig = {
    path: string;
    type: MapperType.VALUE;
};

export type ConcatConfig = {
    path: string[];
    delimeter: string;
    type: MapperType.CONCAT;
};

export type CustomConfig = {
    path: string[];
    expression: string | null;
    type: MapperType.CUSTOM;
};

export type DateTimeFieldConfig = {
    hasTimezone: boolean;
    // Below field is required in cases where hasTimezone is false
    offset: string | null;
    type: FieldConfig.TIMESTAMPTZ;
};
export type EpochFieldConfig = {
    isEpoch: boolean;
    type: FieldConfig.EPOCH;
};

export type MapperSchema = {
    required: boolean;
    mapperType: MapperType;
    mapperConfig: MapperConfig;
    fieldConfig: DateTimeFieldConfig | EpochFieldConfig | null;
    dataType: DimensionType;
    isUrlEncoded: boolean;
};

export type RawEvent = {
    projectUuid: string;
    [ReservedEventColumns.SOURCE]: string;
    [key: string]: any;
};

export type SourceEventKeyMapping = {
    uuid: string;
    projectUuid: string;
    source: string;
    eventNameKeys: Array<string>;
    description: string;
    createdAt: Date;
    createdBy: string | null;
    isInternal: boolean;
};

export type UpdateSourceEventKeyMappings = Partial<
    Pick<SourceEventKeyMapping, 'eventNameKeys' | 'description'>
>;

/**
 *
 * Below is the structure like {
 *   projectUuid:{
 *       gupshup:{
 *           event_delivered:{
 *               MapperSChema
 *           }
 *       }
 *   }
 * }
 */
export type EventMapperSchema = {
    uuid: string;
    projectUuid: string;
    source: string;
    eventName: string;
    mapperSchema: Record<string, MapperSchema>;
    sampleEventPayload: Record<string, unknown>;
    label: string | null;
    createdBy: string | null;
    createdAt: Date | null;
    updatedBy: string | null;
    updatedAt: Date | null;
    persistToWh: boolean;
    isInternal: boolean;
};

export type CreateSourceEventNameKeyMapping = Pick<
    SourceEventKeyMapping,
    'source' | 'eventNameKeys' | 'description'
>;
export type CreateSourceEventNameKeyMappingResponse = SourceEventKeyMapping;

export type CreateEventMapperSchema = Omit<
    EventMapperSchema,
    | 'uuid'
    | 'projectUuid'
    | 'createdBy'
    | 'createdAt'
    | 'updatedAt'
    | 'updatedBy'
>;

export type UpdateEventMapperSchema = Pick<
    EventMapperSchema,
    | 'eventName'
    | 'mapperSchema'
    | 'sampleEventPayload'
    | 'label'
    | 'persistToWh'
    | 'isInternal'
>;

export type CachedMappers = Record<
    string,
    Record<string, Record<string, Record<string, MapperSchema>>>
>;

export type EventColumn = {
    uuid: UUID;
    projectUuid: UUID;
    columnName: string;
    columnDataType: DimensionType;
    nullable: boolean;
    label: string;
    persistToWh: boolean;
};

export type CreateEventColumn = Omit<EventColumn, 'uuid' | 'projectUuid'>;

export type CreateEventColumnFromLabel = Omit<
    EventColumn,
    'uuid' | 'projectUuid' | 'columnName' | 'persistToWh'
>;

export enum FieldConfig {
    TIMESTAMPTZ = 'TIMESTAMPTZ',
    EPOCH = 'EPOCH',
}

export enum PSQLDimensionTypeColumnInfo {
    TIMESTAMP = 'timestamp without time zone',
}

export type ColumnInfo = {
    type: string; // TODO these types which are based on DB(psql can send character varying, other can send
    // string or text etc), map these to DimensionType types
    maxLength: number;
    nullable: boolean;
    defaultValue: string | null;
};

export type DirectTableFailureEntry = {
    count: number;
    entry: MappedEvent;
};

export enum ParquetDataType {
    BOOLEAN = 'BOOLEAN',
    UTF8 = 'UTF8',
    INT32 = 'INT32',
    INT64 = 'INT64',
    TIMESTAMP_MILLIS = 'TIMESTAMP_MILLIS',
}

export const dimensionTypeToParquetDataType = (
    dimensionType: DimensionType,
): ParquetDataType => {
    switch (dimensionType) {
        case DimensionType.BOOLEAN:
            return ParquetDataType.BOOLEAN;
        case DimensionType.DATE:
            return ParquetDataType.INT32;
        case DimensionType.STRING:
            return ParquetDataType.UTF8;
        case DimensionType.TIMESTAMP:
            return ParquetDataType.TIMESTAMP_MILLIS;
        case DimensionType.NUMBER:
            return ParquetDataType.INT64;
        default:
            return ParquetDataType.UTF8;
    }
};

export const CampaignAnalyticsRequiredCols: string[] = [
    ...Object.values(ReservedCampaignEventColumns),
    ...Object.values(CommonEventColumns),
    ...Object.values(ReservedEventColumns),
    ...Object.values(SubscriptionEventColumns),
];

export const SubscriptionEventsRequiredCols: string[] = [
    ReservedEventColumns.SOURCE,
    ReservedEventColumns.EVENT_NAME,
    CommonEventColumns.CLIENT_EVENT_ID,
    ReservedEventColumns.USER_ID,
    ReservedEventColumns.SERVER_TIMESTAMP,
    SubscriptionEventColumns.CHANNEL_NAME,
    SubscriptionEventColumns.SUBSCRIPTION_GROUP_ID,
    SubscriptionEventColumns.SUBSCRIPTION_GROUP_TYPE,
    SubscriptionEventColumns.USER_ACTION_TAKEN,
    SubscriptionEventColumns.SUBSCRIBE_TIMESTAMP,
    SubscriptionEventColumns.UNSUBSCRIBE_TIMESTAMP,
];

export const DeliveryControlEventsRequiredCols: string[] = [
    ReservedEventColumns.SOURCE,
    ReservedEventColumns.EVENT_NAME,
    CommonEventColumns.CLIENT_EVENT_ID,
    ReservedEventColumns.USER_ID,
    ReservedEventColumns.SERVER_TIMESTAMP,
    DeliveryControlEventsColumns.DELIVERY_CONTROL_RULE_COUNTER,
    DeliveryControlEventsColumns.DELIVERY_CONTROL_RULE_ID,
];

export enum FynoEventNames {
    SUCCESS = 'Success',
    REJECTED = 'REJECTED',
    PROCESSED = 'PROCESSED',
    OPEN = 'OPEN',
    DELIVERED = 'DELIVERED',
    CLICKED = 'CLICKED',
    READ = 'READ',
}
