import BlobStorageManager from '@components/BlobStorage/BlobStorageManager';
import Page from '@components/Page/Page';
import { useLocale } from '@hooks/useLocale';
import React from 'react';

const BlogStorage: React.FC<{}> = ({}) => {
    const { t } = useLocale();

    return (
        <Page
            title={t('blob.page_title')}
            withFullHeight
            withPaddedContent
            withNavbar
            withFixedContent
        >
            <h1 className="text-lg font-medium text-gray-800">
                {t('blob.main_title')}
            </h1>
            <main className="mt-8">
                <BlobStorageManager />
            </main>
        </Page>
    );
};

export default BlogStorage;
