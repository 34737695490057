import Page from '@components/Page/Page';
import UserSubscriptionPreferences from '@components/SubscriptionGroups/UserSubscriptionPreferences';
import { useLocale } from '@hooks/useLocale';

const SubscriptionPreferences = () => {
    const { t } = useLocale();
    return (
        <Page title={t('subscription_group')} withFullHeight withNavbar>
            <UserSubscriptionPreferences />
        </Page>
    );
};

export default SubscriptionPreferences;
