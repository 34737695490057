import TableIcon from '@components/common/IconPack/TableIcon';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import {
    FieldType,
    getItemId,
    RelationTableType as RelationTableTypeEnum,
    type RelationTableType,
} from '@lightdash/common';
import { Nut } from '@phosphor-icons/react';
import { GlobalStyles } from '../../../../mantineTheme';
import { filterPropertyTypeMapper } from '../FilterSelect/useFilterSelectUtils';
import { type FieldWithSuggestions } from '../FiltersProvider';

export type PropertySelectGroupType = FieldType.METRIC | RelationTableTypeEnum;

type PropertySelectAccumulator = {
    [key: string]: PropertySelectListType<
        FieldWithSelectStatus,
        PropertySelectGroupType
    >;
};
export interface BaseFieldListItemProps {
    item: FieldWithSuggestions;
    isDisabled?: boolean;
    isChecked?: boolean;
}
export type FieldListItemProps = BaseFieldListItemProps & {
    showCheckbox: boolean;
    checked: boolean;
    disabled: boolean;
    showFieldSource?: boolean;
    disableHover?: boolean;
};
export const convertFieldsIntoPropertySelectListType = (
    items: FieldWithSelectStatus[],
): PropertySelectListType<FieldWithSelectStatus, PropertySelectGroupType>[] => {
    const dataFields = items.reduce<PropertySelectAccumulator>((acc, pass) => {
        if (!pass) return acc;
        const { shortLabel, label } =
            filterPropertyTypeMapper.find(
                (item) => (item.propertyType as string) === pass?.tableType,
            ) || {};
        const key =
            pass?.fieldType === FieldType.METRIC
                ? FieldType.METRIC
                : pass?.tableType ?? RelationTableTypeEnum.RELATED;
        if (key) {
            if (acc[key] === undefined) {
                acc[key] = {
                    groupKey: key,
                    groupLabel:
                        pass?.fieldType === FieldType.METRIC
                            ? filterPropertyTypeMapper.find(
                                  (item) =>
                                      item.propertyType === FieldType.METRIC,
                              )?.shortLabel ?? ''
                            : shortLabel ?? label ?? '',
                    groupIcon:
                        pass?.fieldType === FieldType.METRIC ? (
                            <Nut color="rgb(var(--color-gray-800))" />
                        ) : (
                            <TableIcon
                                type={
                                    pass?.tableType ??
                                    RelationTableTypeEnum.RELATED
                                }
                                strokeWidth={2.5}
                                color="rgb(var(--color-gray-800))"
                            />
                        ),
                    items: [pass],
                };
            } else {
                acc = {
                    ...acc,
                    [key]: { ...acc[key], items: [...acc[key].items, pass] },
                };
            }
        }

        return acc;
    }, {} as PropertySelectAccumulator);
    return Object.values(dataFields);
};
export const getTextColor = (type: RelationTableType | undefined) => {
    switch (type) {
        case RelationTableTypeEnum.PRIMARY:
            return GlobalStyles?.tableStyles?.[RelationTableTypeEnum.PRIMARY]
                ?.textColor;
        case RelationTableTypeEnum.EVENT:
            return GlobalStyles?.tableStyles?.[RelationTableTypeEnum.EVENT]
                ?.textColor;
        case RelationTableTypeEnum.AUDIENCE:
            return GlobalStyles?.tableStyles?.[RelationTableTypeEnum.AUDIENCE]
                ?.textColor;
        default:
            return GlobalStyles?.tableStyles?.[RelationTableTypeEnum.RELATED]
                ?.textColor;
    }
};
export const renderTableIcon = (type: RelationTableTypeEnum | undefined) => {
    let iconType;
    switch (type) {
        case RelationTableTypeEnum.PRIMARY:
            iconType = RelationTableTypeEnum.PRIMARY;
            break;
        case RelationTableTypeEnum.EVENT:
            iconType = RelationTableTypeEnum.EVENT;
            break;
        case RelationTableTypeEnum.AUDIENCE:
            iconType = RelationTableTypeEnum.AUDIENCE;
            break;
        default:
            iconType = RelationTableTypeEnum.RELATED;
            break;
    }
    return <TableIcon type={iconType} strokeWidth={2.5} />;
};

/**
 * Generates the class name string for the menu target based on the opened state.
 *
 * @param {boolean} opened - The state indicating whether the menu is opened or not.
 * @returns {string} The generated class name string.
 */
export const getButtonHighlightClassName = (opened: boolean): string => {
    return `mx-2 ${
        !opened
            ? 'hover:!bg-shade-2 hover:!text-gray-800 hover:border-shade-2'
            : ''
    }  ${opened ? '!text-blu-800 !bg-blu-100 !border-blu-100' : ''}`;
};

export type FieldWithSelectStatus = FieldWithSuggestions & {
    isChecked?: boolean;
    isDisabled?: boolean;
};

export interface ModifyFieldProps {
    fields: FieldWithSuggestions[];
    selectedFieldIds: string[];
    shouldDisableChecked: boolean;
}

/**
 * Modifies the given fields by adding `isChecked` and `isDisabled` properties
 * based on whether a field is selected and if checked fields should be disabled.
 *
 * @param fields - Array of fields to be modified.
 * @param selectedFieldIds - Array of field IDs that should be marked as checked.
 * @param shouldDisableChecked - If true, disables fields that are checked.
 * @returns An array of fields with added `isChecked` and `isDisabled` properties.
 */
export const modifyFieldsWithSelectionStatus = ({
    fields,
    selectedFieldIds,
    shouldDisableChecked,
}: ModifyFieldProps): FieldWithSelectStatus[] => {
    return fields.map((field) => {
        const isChecked = selectedFieldIds.includes(getItemId(field));
        const isDisabled = shouldDisableChecked && isChecked;
        return { ...field, isChecked, isDisabled };
    });
};
