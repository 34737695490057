export const generateFriendlyLabel = (str: string, delimiter: string) => {
    if (!str.length) {
        return str;
    }

    const newStr = str.replace('srt_', '');
    const parts = newStr.split(delimiter);
    parts[0] = parts[0]
        ? `${parts[0].charAt(0).toUpperCase()}${parts[0]
              .slice(1)
              .toLowerCase()}`
        : '';
    return parts.join(' ');
};

export const generateColumnNameByLabel = (
    str: string,
    splitDelimiter: string,
    joinDelimiter: string,
) => {
    if (!str.length) {
        return str;
    }
    const parts = str.split(splitDelimiter);
    const columnName = parts
        .map((part) => part.toLowerCase())
        .join(joinDelimiter);
    return columnName;
};

/**
 * Returns a promise that resolves after a given number of milliseconds
 * @param ms - number of milliseconds to wait
 * @returns a promise that resolves after the given number of milliseconds
 * @example
 * ```typescript
 * await delay(1000);
 * ```
 */
export const delay = (ms: number) =>
    new Promise((resolve) => {
        setTimeout(resolve, ms);
    });

/**
 * Calculates an exponential backoff time based on the number of failures.
 *
 * @param {number} failureCount - The number of consecutive failures or retries.
 * @param {number} [baseTime=1000] - The base time in milliseconds. The initial delay before the first retry.
 * @param {number} [factor=2] - The exponential factor. Determines the growth rate of the delay after each failure.
 * @param {number} [maxBackoffTime=Number.MAX_VALUE] - The maximum allowable backoff time in milliseconds.
 *
 * @returns {number} - The calculated backoff time in milliseconds, capped at maxBackoffTime.
 */
export const calculateBackoffTime = (
    failureCount: number,
    maxBackoffTime: number = Number.MAX_VALUE, // Max backoff time (default: Number.MAX_VALUE)
    baseTime: number = 1000, // Base time in milliseconds (default: 1 second)
    factor: number = 2, // Exponential factor (default: 2)
): number => {
    // Calculate backoff time using exponential backoff formula: baseTime * (factor^failureCount)
    const backoffTime = baseTime * factor ** failureCount;

    // Return the minimum of the calculated backoff time or the maxBackoffTime
    return Math.min(backoffTime, maxBackoffTime);
};
