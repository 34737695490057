import TableIcon from '@components/common/IconPack/TableIcon';
import ButtonInput from '@components/common/Inputs/ButtonInput';
import { useUpdateTableDetail } from '@hooks/useSchemaBuilder';
import { Box, Drawer, Flex, ScrollArea, Text } from '@mantine/core';
import { useRelationContext } from '@providers/RelationProvider';
import { useSchemaContext } from '@providers/SchemaProvider';
import { useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { memo, useCallback, useMemo, type FC } from 'react';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import { FontSizes, FontWeights } from '../../../mantineTheme';
import SchemaBuilderTabs from './SchemaBuilderTabs';
import { SchemaConfigSteps } from './utils';

const debouncedUpdateDetail = debounce(async (updateDetail, field, value) => {
    await updateDetail({ [field]: value });
}, 1000);

const SchemaBuilderDrawer: FC<{}> = memo(() => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { schemaPayload, isDrawerOpen, isEditMode, currentConfigStep } =
        useSchemaContext((context) => context.state);
    const { toggleDrawer } = useSchemaContext((context) => context.actions);
    const { activeRelationUuid } = useRelationContext();
    const { mutateAsync: updateTableDetail } =
        useUpdateTableDetail(activeRelationUuid);
    const queryClient = useQueryClient();

    const CurrentConfigComponent = useMemo(
        () =>
            SchemaConfigSteps.find((step) => step.key === currentConfigStep)
                ?.component,
        [currentConfigStep],
    );

    const updateDetail = useCallback(
        async (payload: Record<string, string>) => {
            if (!schemaPayload.name) return;

            if (payload.label) {
                payload.label = payload.label.trim();
            }

            await updateTableDetail({
                tableName: schemaPayload.name,
                payload: {
                    type: schemaPayload.type,
                    ...payload,
                },
            });

            await queryClient.invalidateQueries([
                QueryKeys.RELATION_SCHEMA,
                projectUuid,
                activeRelationUuid,
            ]);
        },
        [
            activeRelationUuid,
            projectUuid,
            queryClient,
            schemaPayload,
            updateTableDetail,
        ],
    );

    const onValueChange = useCallback(
        (field: string, value: string) => {
            void debouncedUpdateDetail(updateDetail, field, value);
        },
        [updateDetail],
    );

    if (!CurrentConfigComponent) return <></>;

    return (
        <>
            <Drawer.Root
                position="right"
                opened={isDrawerOpen}
                onClose={toggleDrawer}
                scrollAreaComponent={ScrollArea.Autosize}
                size="55rem"
            >
                <Drawer.Content className="m-4 mt-16 rounded-lg h-[calc(100%-5rem)] overflow-hidden">
                    <Drawer.Header className="flex items-start">
                        <Drawer.Title>
                            <Flex align={'center'}>
                                <TableIcon
                                    type={schemaPayload?.type}
                                    size={FontSizes.lg}
                                />
                                {!isEditMode && (
                                    <Text className="ml-2">
                                        Add {schemaPayload?.type?.toLowerCase()}{' '}
                                        table
                                    </Text>
                                )}
                                {!!isEditMode && (
                                    <ButtonInput
                                        defaultValue={schemaPayload?.label}
                                        fontSize={FontSizes.lg}
                                        fontWeight={FontWeights.semibold}
                                        placeholder=""
                                        onChange={(value: string) =>
                                            onValueChange('label', value)
                                        }
                                        rightSection={null}
                                        className="z-0"
                                    />
                                )}
                            </Flex>
                            {!!isEditMode && (
                                <ButtonInput
                                    defaultValue={schemaPayload?.description}
                                    fontSize={FontSizes.sm}
                                    fontWeight={FontWeights.normal}
                                    placeholder="Click to add a description"
                                    onChange={(value: string) => {
                                        onValueChange('description', value);
                                    }}
                                    rightSection={null}
                                    className="z-0"
                                />
                            )}
                        </Drawer.Title>
                        <Drawer.CloseButton className="mt-1" />
                    </Drawer.Header>
                    <Drawer.Body className="p-0 pb-[100px]">
                        <hr className="w-full mb-2 mx-0" />

                        {isEditMode && schemaPayload.isConfigured && (
                            <>
                                <SchemaBuilderTabs />
                                <hr className="w-full my-2 mx-0" />
                            </>
                        )}

                        <Box className="px-4">
                            <CurrentConfigComponent />
                        </Box>
                    </Drawer.Body>
                </Drawer.Content>
            </Drawer.Root>
        </>
    );
});

export default SchemaBuilderDrawer;
