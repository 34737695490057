import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import SettingsTitle from '@components/common/Settings/SettingsTitle';
import EventSources from '@components/EventSources';
import AddSourceModal from '@components/EventSources/EventModals/AddSourceModal';
import SourceDataModal from '@components/EventSources/EventModals/SourceDataModal';
import WebhookModal from '@components/EventSources/EventModals/WebHookModal';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import { ProjectSettings, type SourceEventKeyMapping } from '@lightdash/common';
import { Box, Button, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Broadcast, PlusCircle } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import React, { useCallback, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

export enum Source {
    WEBHOOK = 'webhook',
}

const EventSource = () => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { user } = useApp();
    const ability = useAbilityContext();
    const { showToastError } = useNotify();

    const cannotViewEventSources = ability.cannot(
        'view',
        subject('ProjectSettings', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const isDisabled = ability.cannot(
        'view',
        subject(ProjectSettings.eventSources, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const [showEventModal, { open: eventModalOpen, close: eventModalClose }] =
        useDisclosure(false);
    const [showSourceData, { open: sourceDataOpen, close: sourceDataClose }] =
        useDisclosure(false);
    const [
        showWebhookModal,
        { open: webhookModalOpen, close: webhookModalClose },
    ] = useDisclosure(false);

    const [sourceData, setSourceData] = useState<SourceEventKeyMapping>();
    const [eventSource, setEventSource] = useState<Source>(Source.WEBHOOK);

    const onSourceSelect = useCallback(
        (item: SourceEventKeyMapping) => {
            setSourceData(item);
            sourceDataOpen();
        },
        [sourceDataOpen],
    );

    if (cannotViewEventSources) {
        showToastError({ title: t('authorization_access_error.message') });
        return <Redirect to="/no-access" />;
    }
    return (
        <>
            <Box>
                <Flex className="items-center justify-between w-full">
                    <Flex align="center" className="space-x-2">
                        <Broadcast />
                        <SettingsTitle title={t('event_sources')} />
                    </Flex>
                    {!isDisabled && (
                        <Button
                            leftIcon={
                                <PlusCircle
                                    color="white"
                                    size={14}
                                    strokeWidth={2.5}
                                />
                            }
                            onClick={() => eventModalOpen()}
                        >
                            {t('event_sources_panel.add_event_source')}
                        </Button>
                    )}
                </Flex>

                <Box className="my-6">
                    <EventSources onSourceSelect={onSourceSelect} />
                </Box>
            </Box>

            <AddSourceModal
                setEventSource={setEventSource}
                showEventModal={showEventModal}
                webhookModalOpen={webhookModalOpen}
                eventModalClose={eventModalClose}
            />
            {showWebhookModal && (
                <WebhookModal
                    onSourceAdded={(item: SourceEventKeyMapping) => {
                        onSourceSelect(item);
                    }}
                    eventSource={eventSource}
                    showWebhookModal={showWebhookModal}
                    webhookModalClose={webhookModalClose}
                />
            )}

            {showSourceData && sourceData && !showWebhookModal && (
                <SourceDataModal
                    sourceData={sourceData}
                    showSourceData={showSourceData}
                    sourceDataClose={sourceDataClose}
                />
            )}
        </>
    );
};

export default React.memo(EventSource);
