// organize-imports-ignore
import { type Ace } from 'ace-builds';
import 'react-ace'; // Note: we need this import before the langTools import
import langTools from 'ace-builds/src-noconflict/ext-language_tools';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { useRelationContext } from '../providers/RelationProvider';
import { type CompiledDimension, type CompiledMetric } from '@lightdash/common';

const customCompleter: (fields: Ace.Completion[]) => Ace.Completer = (
    fields,
) => ({
    getCompletions: (editor, session, pos, prefix, callback) => {
        callback(null, fields);
    },
});

const mapActiveDimensionsToCompletions = (
    fields: CompiledDimension[],
    meta: string,
): Ace.Completion[] =>
    fields.reduce<Ace.Completion[]>((acc, field) => {
        const technicalOption: Ace.Completion = {
            caption: `${field.tableLabel} -> ${field.label}`,
            value: field.compiledSql,
            meta,
            score: Number.MIN_VALUE,
        };
        return [...acc, technicalOption];
    }, []);

const mapActiveMetricsToCompletions = (
    metrics: CompiledMetric[],
    meta: string,
): Ace.Completion[] =>
    metrics.reduce<Ace.Completion[]>((acc, metric) => {
        const technicalOption: Ace.Completion = {
            caption: `${metric.tableLabel} -> ${metric.label}`,
            value: metric.compiledSql,
            meta,
            score: Number.MIN_VALUE,
        };
        return [...acc, technicalOption];
    }, []);

export const useRelationAceEditorCompleter = (): {
    setAceEditor: Dispatch<SetStateAction<Ace.Editor | undefined>>;
} => {
    const { activeRelation } = useRelationContext();

    const [aceEditor, setAceEditor] = useState<Ace.Editor>();

    useEffect(() => {
        if (aceEditor && activeRelation) {
            const tables = Object.values(activeRelation.tables).reduce<
                Ace.Completion[]
            >(
                (acc, table) => [
                    ...acc,
                    {
                        caption: table.label,
                        value: table.sqlTable,
                        meta: 'Table',
                        score: Number.MAX_VALUE,
                    },
                    ...mapActiveDimensionsToCompletions(
                        Object.values(table.dimensions),
                        'Dimension',
                    ),
                    ...mapActiveMetricsToCompletions(
                        Object.values(table.metrics),
                        'Metric',
                    ),
                ],
                [],
            );
            langTools.addCompleter(customCompleter(tables));
        }
    }, [aceEditor, activeRelation]);

    return {
        setAceEditor,
    };
};
