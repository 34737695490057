import StepTitle from '@components/Campaigns/Builder/Steps/StepTitle';
import { CampaignBuilderStep } from '@components/Campaigns/Builder/types';
import { CampaignBuilderSteps } from '@components/Campaigns/Builder/utils';
import { Box, Stack } from '@mantine/core';
import { useCampaignContext } from '@providers/CampaignProvider';
import { useEffect, useRef, useState } from 'react';
import CampaignDetails from './CampaignDetails';
import CampaignScheduler from './CampaignScheduler';

const CampaignBuilderScheduler: React.FC<{}> = () => {
    const title = CampaignBuilderSteps.find(
        (step) => step.key === CampaignBuilderStep.SCHEDULE,
    )?.pageHeader;

    const { setCurrentStepCallback, mutateCampaign } = useCampaignContext(
        (context) => context.actions,
    );

    const submitFormRef = useRef<() => Promise<boolean>>(() =>
        Promise.resolve(true),
    );

    const [timeError, setTimeError] = useState(false);

    useEffect(() => {
        setCurrentStepCallback({
            callback: async () => {
                const isValidForm = await submitFormRef.current();
                if (isValidForm && !timeError) {
                    await mutateCampaign();
                }
            },
            skipExecutionAfterCallback: true,
        });
    }, [setCurrentStepCallback, mutateCampaign, timeError]);

    return (
        <Box>
            <StepTitle title={title || ''} />
            <Stack className="mt-6 max-w-[800px] w-[600px] gap-10">
                <CampaignDetails validateFormRef={submitFormRef} />
                <CampaignScheduler
                    timeError={timeError}
                    setTimeError={setTimeError}
                />
            </Stack>
        </Box>
    );
};

export default CampaignBuilderScheduler;
