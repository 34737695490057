import Timestamp from '@components/common/Timestamp';
import { Button, Group, Menu, Text, Tooltip } from '@mantine/core';
import { useInterval } from '@mantine/hooks';
import { useCallback, useEffect, useState } from 'react';
import { Check, ChevronDown, RefreshCw } from 'react-feather';
import { useDashboardRefresh } from '../../../hooks/dashboard/useDashboardRefresh';
import useNotify from '../../../hooks/toaster/useNotify';
import { ButtonVariant } from '../../../mantineTheme';
import { useDashboardContext } from '../../../providers/DashboardProvider';

const REFRESH_INTERVAL_OPTIONS = [
    {
        value: '0.5',
        label: '30s',
    },
    {
        value: '1',
        label: '1m',
    },
    {
        value: '5',
        label: '5m',
    },
    {
        value: '15',
        label: '15m',
    },
    {
        value: '30',
        label: '30m',
    },
    {
        value: '60',
        label: '1h',
    },
    {
        value: '120',
        label: '2h',
    },
];

export const CampaignAnalyticsDashboardRefreshButton = () => {
    const { showToastSuccess } = useNotify();
    const [isOpen, setIsOpen] = useState(false);
    const [lastRefreshTime, setLastRefreshTime] = useState<Date | null>(
        new Date(),
    );
    const [refreshInterval, setRefreshInterval] = useState<
        undefined | number
    >();

    const { isFetching, invalidateDashboardRelatedQueries } =
        useDashboardRefresh();
    const clearCacheAndFetch = useDashboardContext((c) => c.clearCacheAndFetch);

    const isOneAtLeastFetching = isFetching > 0;

    const invalidateAndSetRefreshTime = useCallback(() => {
        clearCacheAndFetch();
        void invalidateDashboardRelatedQueries();
        setLastRefreshTime(new Date());
    }, [clearCacheAndFetch, invalidateDashboardRelatedQueries]);

    const interval = useInterval(
        () => invalidateAndSetRefreshTime(),
        refreshInterval ? refreshInterval * 1000 * 60 : 0,
    );

    useEffect(() => {
        if (refreshInterval !== undefined) {
            interval.start();
        }
        return interval.stop;
    }, [interval, refreshInterval, showToastSuccess]);

    return (
        <Group>
            <Text className="flex gap-1 text-sm text-gray-600">
                Last refreshed:{' '}
                {lastRefreshTime ? (
                    <>
                        <Timestamp timestamp={lastRefreshTime.toISOString()} />
                    </>
                ) : (
                    'Never'
                )}
            </Text>
            <Button.Group>
                <Tooltip
                    withinPortal
                    position="bottom"
                    label={
                        <Text>
                            Last refreshed at:{' '}
                            {lastRefreshTime
                                ? lastRefreshTime.toLocaleTimeString()
                                : 'Never'}
                        </Text>
                    }
                >
                    <Button
                        h={35}
                        variant={ButtonVariant.OUTLINED}
                        loading={isOneAtLeastFetching}
                        loaderPosition="center"
                        onClick={() => {
                            invalidateAndSetRefreshTime();
                        }}
                        className="pl-3 rounded-lg shadow-card"
                    >
                        <RefreshCw
                            size={13}
                            strokeWidth={2.5}
                            color={'rgb(var(--color-gray-600))'}
                        />
                        <Text className="ml-1.5">
                            {interval.active && refreshInterval ? (
                                <>
                                    Every{' '}
                                    {
                                        REFRESH_INTERVAL_OPTIONS.find(
                                            ({ value }) =>
                                                refreshInterval === +value,
                                        )?.label
                                    }
                                </>
                            ) : (
                                <>Refresh</>
                            )}
                        </Text>
                    </Button>
                </Tooltip>
                <Menu
                    withinPortal
                    withArrow
                    closeOnItemClick
                    closeOnClickOutside
                    opened={isOpen}
                    onClose={() => setIsOpen((prev) => !prev)}
                >
                    <Menu.Target>
                        <Button
                            h={35}
                            variant={ButtonVariant.OUTLINED}
                            disabled={isOneAtLeastFetching}
                            p={4}
                            onClick={() => setIsOpen((prev) => !prev)}
                            className="px-3 rounded-lg shadow-card"
                        >
                            <ChevronDown
                                size={13}
                                strokeWidth={2.5}
                                color={'rgb(var(--color-gray-600))'}
                            />
                        </Button>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Label>Auto-refresh</Menu.Label>
                        <Menu.Item
                            fz="xs"
                            onClick={() => {
                                setRefreshInterval(undefined);
                            }}
                            disabled={refreshInterval === undefined}
                            sx={{
                                '&[disabled]': {
                                    color:
                                        refreshInterval === undefined
                                            ? 'text-gray-800'
                                            : 'text-gray-800',
                                },
                            }}
                            className={`text-sm font-medium text-gray-800 hover:bg-shade-4`}
                            rightSection={
                                refreshInterval === undefined ? (
                                    <Check
                                        size={13}
                                        strokeWidth={2.5}
                                        color={'rgb(var(--color-gray-600))'}
                                    />
                                ) : null
                            }
                        >
                            Off
                        </Menu.Item>
                        {REFRESH_INTERVAL_OPTIONS.map(({ value, label }) => (
                            <Menu.Item
                                fz="xs"
                                key={value}
                                onClick={() => {
                                    setRefreshInterval(+value);
                                    showToastSuccess({
                                        title: `Your dashboard will refresh every ${
                                            REFRESH_INTERVAL_OPTIONS.find(
                                                (option) =>
                                                    value === option.value,
                                            )?.label
                                        }`,
                                    });
                                }}
                                disabled={refreshInterval === +value}
                                sx={{
                                    '&[disabled]': {
                                        color:
                                            refreshInterval === +value
                                                ? 'text-gray-800'
                                                : 'text-gray-800',
                                    },
                                }}
                                className="text-sm font-medium text-gray-800 hover:bg-shade-4"
                                rightSection={
                                    refreshInterval === +value ? (
                                        <Check
                                            size={13}
                                            strokeWidth={2.5}
                                            color={'rgb(var(--color-gray-600))'}
                                        />
                                    ) : null
                                }
                            >
                                {label}
                            </Menu.Item>
                        ))}
                    </Menu.Dropdown>
                </Menu>
            </Button.Group>
        </Group>
    );
};
