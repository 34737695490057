import { type JourneyDataSchema } from '@lightdash/common';
import { Group, Stack } from '@mantine/core';
import { ClockCountdown } from '@phosphor-icons/react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import ActionTitle from '../ActionTitle';
import DelayAction, { type DelayActionProps } from '../Delay';
import FilterAction, { type FilterActionProps } from '../Filter';

export interface WaitUntilProps extends FilterActionProps, DelayActionProps {
    setEvent: ({
        eventName,
        eventSource,
        eventLabel,
    }: {
        eventName: string;
        eventSource: string;
        eventLabel: string;
    }) => void;
    event: {
        eventName: string;
        eventSource: string;
    };
}

const WaitUntil: React.FC<WaitUntilProps> = ({
    filters,
    nodeId,
    setFilters,
    selectedDuration,
    onDurationChange,
    granularity,
    durationOptions,
    setEvent,
    event,
}) => {
    const [filterDataSchema, setFilterDataSchema] =
        useState<JourneyDataSchema>();

    const handleTransientEventFilterTable = useCallback(
        (value: string) => {
            const eventTable = filterDataSchema?.tables[value];
            if (!eventTable) return;
            setEvent({
                eventName: eventTable.name,
                eventSource: eventTable.eventSource ?? '',
                eventLabel: eventTable.label ?? '',
            });
        },
        [filterDataSchema, setEvent],
    );

    const getTransientEventFilterTable = useMemo(() => {
        if (
            !event ||
            isEmpty(event) ||
            !event.eventName ||
            !event.eventSource ||
            !filterDataSchema
        )
            return;
        const eventTableKey = Object.keys(filterDataSchema.tables).find(
            (key) => {
                const table = filterDataSchema.tables[key];
                return (
                    table.name === event.eventName &&
                    table.eventSource === event.eventSource
                );
            },
        );

        if (!eventTableKey) return;
        return eventTableKey;
    }, [filterDataSchema, event]);

    return (
        <Stack>
            <FilterAction
                filters={filters}
                nodeId={nodeId}
                setFilters={setFilters}
                actionTitle={
                    <ActionTitle
                        title={t(
                            'journey_builder.wait_until_filter_action_title',
                        )}
                    />
                }
                transientEventFilterTable={getTransientEventFilterTable}
                setTransientEventFilterTable={handleTransientEventFilterTable}
                setFilterDataSchema={setFilterDataSchema}
            />

            <DelayAction
                selectedDuration={selectedDuration}
                onDurationChange={onDurationChange}
                granularity={granularity}
                durationOptions={durationOptions}
                actionTitle={
                    <Group className="gap-1">
                        <ClockCountdown color={'rgb(var(--color-gray-500))'} />
                        <ActionTitle title={'Timeout after...'} />
                    </Group>
                }
                withLeftPadding={false}
            />
        </Stack>
    );
};

export default React.memo(WaitUntil);
