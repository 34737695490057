import { useApp } from '@providers/AppProvider';
import { useEffect, useState } from 'react';

interface User {
    firstName: string;
    lastName: string;
    email: string;
    userUuid: string;
}

export const useSortedUsers = <T extends User>(users: T[] | undefined) => {
    const { user } = useApp();
    const [sortedUsers, setSortedUsers] = useState<T[] | undefined>(undefined);

    useEffect(() => {
        const sortUsers = users?.slice().sort((a, b) => {
            const name1 = `${a.firstName} ${a.lastName}`.trim() || a.email;
            const name2 = `${b.firstName} ${b.lastName}`.trim() || b.email;
            const nameComparison = name1.localeCompare(name2, undefined, {
                sensitivity: 'base',
            });
            if (nameComparison !== 0) {
                return nameComparison;
            }
            return a.email.localeCompare(b.email, undefined, {
                sensitivity: 'base',
            });
        });

        if (sortUsers) {
            const index = sortUsers.findIndex(
                (obj) => obj.userUuid === user.data?.userUuid,
            );
            if (index !== -1) {
                const [loggedUser] = sortUsers.splice(index, 1);
                sortUsers.unshift(loggedUser);
            }
        }

        setSortedUsers(sortUsers);
    }, [users, user]);

    return { sortedUsers };
};
