import Select from '@components/common/Select';
import { StandardTimeZoneAbbreviation, Timezones } from '@lightdash/common';
import { Box, Text, type SelectProps } from '@mantine/core';
import { t } from 'i18next';
import moment from 'moment';
import React, { forwardRef, useMemo } from 'react';
import { Check } from 'react-feather';
interface TimezoneSelectProps extends SelectProps {}
interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    label: string;
}
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, ...others }: ItemProps, ref) => (
        <Box ref={ref} {...others}>
            <Box className="flex justify-between">
                <Text className="text-sm text-gray-800 font-medium break-normal">
                    {label}
                </Text>

                <Check
                    size={13}
                    strokeWidth={2.5}
                    color={'rgb(var(--color-gray-600))'}
                    className="selected__item--icon-selected ms-0.5"
                />
            </Box>
        </Box>
    ),
);
const TimezoneSelect: React.FC<TimezoneSelectProps> = ({ ...props }) => {
    const timezoneArray = useMemo(
        () =>
            Object.entries(Timezones).map(([_key, value]) => {
                const offset = moment().tz(value).format('Z');
                const st = moment().tz(value).format('z');
                const valueArray = value.split('/');
                const place = valueArray[valueArray.length - 1];
                const label = `(GMT${offset}) ${
                    StandardTimeZoneAbbreviation[
                        st as keyof typeof StandardTimeZoneAbbreviation
                    ]
                        ? StandardTimeZoneAbbreviation[
                              st as keyof typeof StandardTimeZoneAbbreviation
                          ] + ' - '
                        : ''
                }${place ?? ''}`;
                return { value, label };
            }),
        [],
    );
    return (
        <Select
            itemComponent={SelectItem}
            id="workspace-timezone-select"
            label={t('workspace_settings_form.timezone_label')}
            placeholder={t('workspace_settings_form.timezone_placeholder')}
            dropdownPosition="bottom"
            withAsterisk={false}
            {...props}
            data={timezoneArray}
        />
    );
};
export default React.memo(TimezoneSelect);
