import { sortmentApi } from '@api/index';
import useNotify from '@hooks/toaster/useNotify';
import { type ApiError, type EmailStatusExpiring } from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';

const getEmailStatusQuery = async () => {
    return sortmentApi<EmailStatusExpiring>({
        url: `/user/me/email/status`,
        method: 'GET',
        body: undefined,
    });
};

const sendOneTimePasscodeQuery = async () => {
    return sortmentApi<EmailStatusExpiring>({
        url: `/user/me/email/otp`,
        method: 'PUT',
        body: undefined,
    });
};

const verifyOTPQuery = async (code: string) => {
    return sortmentApi<EmailStatusExpiring>({
        url: `/user/me/email/status?passcode=${code}`,
        method: 'GET',
        body: undefined,
    });
};

export const useEmailStatus = (enabled = true) =>
    useQuery<EmailStatusExpiring, ApiError>({
        queryKey: [QueryKeys.EMAIL_STATUS],
        queryFn: () => getEmailStatusQuery(),
        enabled,
    });

export const useOneTimePassword = () => {
    const queryClient = useQueryClient();
    const { showToastApiError } = useNotify();
    return useMutation<EmailStatusExpiring, ApiError>(
        () => sendOneTimePasscodeQuery(),
        {
            mutationKey: [QueryKeys.SEND_VERIFICATION_EMAIL],
            onSuccess: async () => {
                await queryClient.invalidateQueries([QueryKeys.EMAIL_STATUS]);
            },
            onError: ({ error }) => {
                showToastApiError({
                    title: `We couldn't send a verification e-mail to your inbox.`,
                    apiError: error,
                });
            },
        },
    );
};

export const useVerifyEmail = () => {
    const queryClient = useQueryClient();
    const { showToastSuccess } = useNotify();
    return useMutation<EmailStatusExpiring, ApiError, string>(
        (code) => verifyOTPQuery(code),
        {
            mutationKey: [QueryKeys.VERIFY_ONE_TIME_PASSWORD],
            onSuccess: async (data) => {
                await queryClient.invalidateQueries([QueryKeys.EMAIL_STATUS]);

                if (data.isVerified)
                    showToastSuccess({
                        title: 'Success! Your e-mail has been verified.',
                    });
            },
        },
    );
};
