import CreateBlobStorage from '@components/BlobStorage';
import { blobStores } from '@components/BlobStorage/blobConfig';
import GenericOption from '@components/common/Card/GenericOption';
import { List } from '@mantine/core';
import { useProjectContext } from '@providers/ProjectProvider';
import React, { useCallback, useState } from 'react';
import {
    type BlobStorageOptionProps,
    type SelectedBlobStorageType,
} from 'types/BlobStorage';

const BlobStorageOption: React.FC<BlobStorageOptionProps> = ({
    title,
    icon,
    blobType,
    selectedBlobStorage,
    setSelectedBlobStorage,
}) => {
    const handleClick = useCallback(() => {
        if (selectedBlobStorage !== blobType) {
            setSelectedBlobStorage(blobType);
        }
    }, [blobType, selectedBlobStorage, setSelectedBlobStorage]);

    if (selectedBlobStorage !== blobType && selectedBlobStorage !== 'none')
        return null;

    return (
        <List.Item role="button" onClick={handleClick} className="w-fit">
            <GenericOption
                title={title}
                iconComponent={<img src={icon} alt={title} />}
                rightSection={
                    selectedBlobStorage === blobType && (
                        <button
                            className="m-0 text-sm font-medium text-blu-800"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSelectedBlobStorage('none');
                            }}
                        >
                            Change
                        </button>
                    )
                }
            />
        </List.Item>
    );
};

const BlobStorageManager: React.FC = () => {
    const [selectedBlobStorage, setSelectedBlobStorage] =
        useState<SelectedBlobStorageType>('none');
    const { projectData } = useProjectContext();
    return (
        <>
            <section>
                <List className="flex flex-col gap-4">
                    {projectData?.warehouseConnection?.type &&
                        blobStores[projectData?.warehouseConnection?.type].map(
                            (blobStore) =>
                                blobStore && (
                                    <BlobStorageOption
                                        title={blobStore.title}
                                        icon={blobStore.icon}
                                        blobType={blobStore.blobType}
                                        selectedBlobStorage={
                                            selectedBlobStorage
                                        }
                                        setSelectedBlobStorage={
                                            setSelectedBlobStorage
                                        }
                                        key={blobStore.title}
                                    />
                                ),
                        )}
                </List>
            </section>

            {selectedBlobStorage !== 'none' && (
                <section className="mt-6">
                    <CreateBlobStorage selectedBlob={selectedBlobStorage} />
                </section>
            )}
        </>
    );
};

export default React.memo(BlobStorageManager);
