import Modal from '@components/common/modal/Modal';
import React from 'react';
import JourneysSettings from '.';

interface SettingsModalProps {
    showSettings: boolean;
    onClose: () => void;
}

const SettingsModal: React.FC<SettingsModalProps> = ({
    showSettings,
    onClose,
}) => {
    return (
        <Modal
            opened={showSettings}
            onClose={onClose}
            withCloseButton={false}
            withContentPadding={false}
            contentCustomClass="h-full"
            styles={{
                content: {
                    height: '100%',
                    maxHeight: 'unset !important',
                },
                body: {
                    height: '100%',
                },
            }}
            size="calc(100vw - 3rem)"
        >
            <JourneysSettings onClose={onClose} />
        </Modal>
    );
};

export default SettingsModal;
