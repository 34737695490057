import ChannelContainer from '@components/Channels/ChannelsContainer';
import { DEFAULT_PROVIDER } from '@components/Channels/ChannelTabs';
import Page from '@components/Page/Page';
import { type Integration } from '@lightdash/common';
import {
    ChannelProvider,
    CHANNEL_SETUP_STATES,
    type ChannelProviderData,
} from '@providers/ChannelProvider';

type ChannelContainerProps = {
    handleProviderSelect: undefined | ((obj: Integration) => void);
    allowChannelSetup: boolean;
    title?: string;
    subtitle?: string;
    isOnboarding?: boolean;
    showSetupLabels?: boolean;
};

export const ChannelPage = ({
    handleProviderSelect,
    allowChannelSetup,
    subtitle,
    showSetupLabels,
}: ChannelContainerProps) => {
    const initialState: ChannelProviderData = {
        title: '',
        subtitle: subtitle,
        showProviderConfigModal: false,
        showSetupModal: false,
        allChannels: undefined,
        providers: undefined,
        selectedProvider: undefined,
        searchText: undefined,
        allowChannelSetup: allowChannelSetup,
        handleProviderSelectCallback: handleProviderSelect,
        selectedChannel: DEFAULT_PROVIDER,
        channelSetupState: CHANNEL_SETUP_STATES.SELECT_PROVIDER,
    };
    return (
        <ChannelProvider initialState={initialState}>
            <ChannelContainer showSetupLabels={showSetupLabels} />
        </ChannelProvider>
    );
};
const ChannelsWrapper = ({ ...props }: ChannelContainerProps) => {
    return (
        <Page
            title={props?.title}
            withFullHeight
            withNavbar
            withFooter
            withFixedContent
            className="h-full overflow-x-hidden"
        >
            <ChannelPage {...props} />
        </Page>
    );
};

export default ChannelsWrapper;
