import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import SubscriptionComponent from '@components/SubscriptionGroups';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import { useApp } from '@providers/AppProvider';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

const Subscription: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastError } = useNotify();
    const ability = useAbilityContext();
    const { user } = useApp();
    const canViewSubscription = ability.can(
        'view',
        subject('ProjectSettings', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    if (!canViewSubscription) {
        showToastError({ title: t('authorization_access_error.message') });
        return <Redirect to="/no-access" />;
    }
    return <SubscriptionComponent />;
};

export default React.memo(Subscription);
