import SkeletonLoader from '@components/common/SkeletonLoader';
import { useGetJourneyNodeParams } from '@hooks/useJourney';
import { type HttpConfig, type ResponseConfig } from '@lightdash/common';
import { Divider, Flex, Stack } from '@mantine/core';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import React, { useCallback, useEffect } from 'react';
import EndPointSelect from './EndPointSelect';
import Headers from './Headers';
import RequestBody from './RequestBody';
import ResponsePayloadMapper from './ResponsePayloadMapper';

export interface CallAPIActionProps {
    nodeId: string;
    payloadMapper: string;
    httpConfig: HttpConfig;
    responseConfig: ResponseConfig;
    onPayloadMapperChange: (value: string) => void;
    onHttpConfigChange: (value: HttpConfig) => void;
    onResponsePayloadMapperChange: (value: Record<string, any>) => void;
}

const CallAPIAction: React.FC<CallAPIActionProps> = ({
    nodeId,
    onHttpConfigChange,
    httpConfig,
    responseConfig,
    payloadMapper,
    onPayloadMapperChange,
    onResponsePayloadMapperChange,
}) => {
    const { journeyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );
    const { mutateAsync, isLoading: isJourneyNodeParamsLoading } =
        useGetJourneyNodeParams(nodeId);
    const updateJourneyNodeParams = useCallback(async () => {
        const { config } = journeyPayload;
        if (!config) return;
        await mutateAsync(journeyPayload);
    }, [journeyPayload, mutateAsync]);
    useEffect(() => {
        void updateJourneyNodeParams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (isJourneyNodeParamsLoading) {
        return (
            <Stack className="gap-4 w-[30vw]">
                <SkeletonLoader height={70} />
            </Stack>
        );
    }
    return (
        <Flex direction="column" gap="md">
            <EndPointSelect
                onHttpConfigChange={onHttpConfigChange}
                httpConfig={httpConfig}
            />
            <Divider className="border-t-gray-200 " />
            <Headers
                onHttpConfigChange={onHttpConfigChange}
                httpConfig={httpConfig}
            />
            <Divider className="border-t-gray-200" />
            <RequestBody
                payloadMapper={payloadMapper}
                onPayloadMapperChange={onPayloadMapperChange}
            />
            <Divider className="border-t-gray-200" />
            <ResponsePayloadMapper
                onResponsePayloadMapperChange={onResponsePayloadMapperChange}
                responseConfig={responseConfig}
            />
        </Flex>
    );
};

export default React.memo(CallAPIAction);
