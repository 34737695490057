import { getDurationValue } from '@components/common/Time/TimeInputWithOptions';
import TruncatedText from '@components/common/TruncateAtMiddleText';
import {
    ControlPanel,
    JourneyNodeEnum,
    type JourneyBlockConfigType,
    type JourneyGroupConfigType,
    type JourneyNodeData,
} from '@components/Journeys/Builder/types';
import {
    ActionType,
    JourneyGroup,
    PeriodType,
    type ApiAction,
    type BaseTrigger,
    type FilterAction,
    type JourneyAction,
    type JourneyEntryLogic,
    type SendMessageAction,
    type SetAction,
    type SplitAction,
    type WaitAction,
    type WaitUntilAction,
} from '@lightdash/common';
import { Group, Text } from '@mantine/core';
import {
    ArrowsSplit,
    CursorClick,
    FunnelSimple,
    Hourglass,
    PaperPlaneTilt,
    PauseCircle,
    PlayCircle,
    StarFour,
} from '@phosphor-icons/react';
import { durationTypeOptions } from '@utils/constants';
import { isEmpty } from 'lodash';
import React from 'react';

export const journeyGroupConfig: JourneyGroupConfigType = {
    [JourneyGroup.TRIGGER]: {
        title: 'Triggers',
        description: undefined,
        iconColor: 'rgb(var(--color-blu-800))',
    },
    [JourneyGroup.ACTION]: {
        title: 'Actions',
        description: undefined,
        iconColor: 'rgb(var(--color-green))',
    },
    [JourneyGroup.CONDITION]: {
        title: 'Conditions',
        description: undefined,
        iconColor: 'rgb(var(--color-halt-850))',
    },
    [JourneyGroup.DELAY]: {
        title: 'Delays',
        description: undefined,
        iconColor: 'rgb(var(--color-mustard-800))',
    },
};

//TODO: Added icons as part of FE as of now since on BE these should be added as string and lazy loaded on FE. This is a temporary solution.
export const journeyBlockConfig: JourneyBlockConfigType = {
    [ActionType.FILTER]: {
        getIcon: (color) => <FunnelSimple color={color} />,
    },
    [ActionType.SPLIT]: {
        getIcon: (color) => <ArrowsSplit color={color} />,
    },
    [ActionType.WAIT]: {
        getIcon: (color) => <Hourglass color={color} />,
    },
    [ActionType.WAIT_UNTIL]: {
        getIcon: (color) => <PauseCircle color={color} />,
    },
    [ActionType.API]: {
        getIcon: () => <StarFour color={'rgb(var(--color-purple)'} />,
    },
    [ActionType.SEND_MESSAGE]: {
        getIcon: (color) => <PaperPlaneTilt color={color} />,
    },
    [ActionType.SET]: {
        getIcon: (color) => <PaperPlaneTilt color={color} />,
    },
};

export const getBlockIcon = ({
    type,
    actions,
    group,
}: {
    type: JourneyNodeEnum;
    actions: ActionType[];
    group: JourneyGroup;
}) => {
    if (!type || !actions || !group) return null; //Can be defaulted to a default icon

    if (type === JourneyNodeEnum.TRIGGER) {
        return <PlayCircle color={'rgb(var(--color-green))'} />;
    }

    if (type === JourneyNodeEnum.BLOCK) {
        const iconColor =
            journeyGroupConfig[group].iconColor ?? 'rgb(var(--color-blu-800))';
        return journeyBlockConfig[actions[0]]?.getIcon(iconColor); //Defaulting to first action type
    }
};

export const getControlPanelType = (
    nodeData: JourneyNodeData,
): ControlPanel => {
    switch (nodeData.type) {
        case JourneyNodeEnum.BLOCK:
            return ControlPanel.BLOCK_CONFIG;
        case JourneyNodeEnum.TRIGGER:
            return ControlPanel.TRIGGER_CONFIG;
        case JourneyNodeEnum.PLACEHOLDER:
            return nodeData.placeHolderType === JourneyNodeEnum.TRIGGER
                ? ControlPanel.TRIGGERS
                : ControlPanel.BLOCKS_LIST;
        default:
            throw new Error('Unknown JourneyNodeEnum type');
    }
};

export const getEdgeId = (sourceId: string, targetId: string) => {
    return `e-${sourceId}-${targetId}`;
};

type ActionSeedDataFunction<T extends JourneyAction> = () => T;
type ActionSeedDataMap = {
    [key in ActionType]: ActionSeedDataFunction<JourneyAction>;
};

const getWaitActionSeedData: ActionSeedDataFunction<WaitAction> = () => ({
    type: ActionType.WAIT,
    config: {
        time: NaN,
        responseConfig: {},
    },
    uiConfig: {
        timeGranularity: PeriodType.HOUR,
    },
});

const getWaitUntilActionSeedData: ActionSeedDataFunction<
    WaitUntilAction
> = () => ({
    type: ActionType.WAIT_UNTIL,
    config: {
        time: 0,
        eventConfig: {
            eventName: '',
            eventSource: '',
        },
        responseConfig: {},
    },
    uiConfig: {
        timeoutGranularity: PeriodType.HOUR,
    },
});

const getFilterActionSeedData: ActionSeedDataFunction<FilterAction> = () => ({
    type: ActionType.FILTER,
    config: {
        filterConfig: { and: [] },
        responseConfig: {},
    },
});

const getSplitActionSeedData: ActionSeedDataFunction<SplitAction> = () => ({
    type: ActionType.SPLIT,
    config: {
        responseConfig: {},
    },
});

const getSetActionSeedData: ActionSeedDataFunction<SetAction> = () => ({
    type: ActionType.SET,
    config: {
        payloadMapper: {},
        responseConfig: {},
    },
});

const getSendMessageActionSeedData: ActionSeedDataFunction<
    SendMessageAction
> = () => ({
    type: ActionType.SEND_MESSAGE,
    config: {
        messageConfig: {
            templateDetails: {
                id: '',
                content: undefined,
                mappings: {},
            },
            communicationDetails: {},
        },
        responseConfig: {},
    },
});

const getApiActionSeedData: ActionSeedDataFunction<ApiAction> = () => ({
    type: ActionType.API,
    config: {
        apiConfig: {
            payloadMapper: '',
            httpConfig: {
                url: '',
                method: 'GET',
                headers: '',
            },
        },
        responseConfig: {},
    },
});

const actionSeedDataMap: ActionSeedDataMap = {
    [ActionType.WAIT]: getWaitActionSeedData,
    [ActionType.WAIT_UNTIL]: getWaitUntilActionSeedData,
    [ActionType.FILTER]: getFilterActionSeedData,
    [ActionType.SPLIT]: getSplitActionSeedData,
    [ActionType.SET]: getSetActionSeedData,
    [ActionType.SEND_MESSAGE]: getSendMessageActionSeedData,
    [ActionType.API]: getApiActionSeedData,
};

/**
 * Generates seed data for given block actions.
 *
 * @param {ActionType[]} blockActions - An array of action types.
 * @returns {AllowedActionTypes[]} - An array of seed data objects corresponding to the action types.
 */
export const getActionSeedData = (
    blockActions: ActionType[],
): JourneyAction[] => {
    return blockActions
        .map((actionType) => {
            const getSeedData = actionSeedDataMap[actionType];
            return getSeedData ? getSeedData() : null;
        })
        .filter((action) => action !== null) as JourneyAction[];
};

type ExtractNodeDataFunction<T extends JourneyAction> = (
    actionConfig: T,
) => React.ReactNode;

type ExtractNodeDataMap = {
    [key in ActionType]: ExtractNodeDataFunction<any>;
};

const extractWaitNodeValue: ExtractNodeDataFunction<WaitAction> = (
    actionConfig,
) => {
    let time = actionConfig.config.time;
    if (!time) {
        return null;
    }
    const timeGranularity = actionConfig.uiConfig?.timeGranularity;
    let timeGranularityString = 'seconds';
    if (timeGranularity) {
        const granularity = durationTypeOptions.find(
            (option) => option.value === timeGranularity,
        );
        timeGranularityString = granularity?.label ?? 'seconds';
        time = getDurationValue(time, timeGranularity);
    } else {
        time = time / 1000; //Defaulting to seconds
    }

    return (
        <Text>
            {time} {timeGranularityString}
        </Text>
    );
};

const extractWaitUntilNodeValue: ExtractNodeDataFunction<WaitUntilAction> = (
    actionConfig,
) => {
    if (
        !actionConfig?.config?.eventConfig?.eventName ||
        !actionConfig?.config?.eventConfig?.eventSource
    )
        return null;
    return (
        <Group className="gap-1">
            <CursorClick size={14} />
            <Text>
                {actionConfig.uiConfig?.eventLabel ??
                    actionConfig.config.eventConfig.eventName}
            </Text>
        </Group>
    );
};

const extractFilterNodeValue: ExtractNodeDataFunction<FilterAction> = () =>
    // actionConfig,
    {
        return null;
    };

const extractSplitNodeValue: ExtractNodeDataFunction<SplitAction> = () => {
    return <div>Split</div>;
};

const extractSetNodeValue: ExtractNodeDataFunction<SetAction> = (
    actionConfig,
) => {
    return <div>Set {JSON.stringify(actionConfig.config.payloadMapper)}</div>;
};

const extractSendMessageNodeValue: ExtractNodeDataFunction<
    SendMessageAction
> = () => {
    return null;
};

const extractApiNodeValue: ExtractNodeDataFunction<ApiAction> = (
    actionConfig,
) => {
    const displayUrl = actionConfig.config.apiConfig.httpConfig.url
        .replace(/{{.*?}}/g, '..')
        .replace(/'(.*?)'/g, '$1');
    return <TruncatedText text={displayUrl} maxWidth={105} />;
};

const extractNodeDataMap: ExtractNodeDataMap = {
    [ActionType.WAIT]: extractWaitNodeValue,
    [ActionType.WAIT_UNTIL]: extractWaitUntilNodeValue,
    [ActionType.FILTER]: extractFilterNodeValue,
    [ActionType.SPLIT]: extractSplitNodeValue,
    [ActionType.SET]: extractSetNodeValue,
    [ActionType.SEND_MESSAGE]: extractSendMessageNodeValue,
    [ActionType.API]: extractApiNodeValue,
};

/**
 * Extracts and returns the node value for a given action configuration.
 *
 * @param {JourneyAction[]} actionConfig - An array of action configurations.
 * @returns {React.ReactNode} - The extracted node value as a React component.
 */
export const extractNodeValue = (
    actionConfig: JourneyAction[],
): React.ReactNode => {
    const nodeData = actionConfig.map((action) => {
        const extractNodeData = extractNodeDataMap[action.type];
        return extractNodeData ? extractNodeData(action) : null;
    });

    return (
        <Group>
            {nodeData.map((node, index) => (
                <React.Fragment key={index}>
                    {node}
                    {index < nodeData.length - 1 && <span> · </span>}
                </React.Fragment>
            ))}
        </Group>
    );
};

type CheckNodeErrorFunction<T extends JourneyAction> = (
    actionConfig: T,
) => boolean;

type CheckNodeErrorMap = {
    [key in ActionType]: CheckNodeErrorFunction<any>;
};

/**
 * Checks if the wait action configuration has an error.
 *
 * @param {WaitAction} actionConfig - The wait action configuration.
 * @returns {boolean} - True if the time is missing, otherwise false.
 */
const checkWaitNodeError: CheckNodeErrorFunction<WaitAction> = (
    actionConfig,
) => {
    return (
        actionConfig.config.time === undefined ||
        actionConfig.config.time === null
    );
};

/**
 * Checks if the wait until action configuration has an error.
 *
 * @param {WaitUntilAction} actionConfig - The wait until action configuration.
 * @returns {boolean} - True if the event name or event source is missing, or the time is missing, otherwise false.
 */
const checkWaitUntilNodeError: CheckNodeErrorFunction<WaitUntilAction> = (
    actionConfig,
) => {
    return (
        !actionConfig.config.eventConfig.eventName ||
        !actionConfig.config.eventConfig.eventSource ||
        actionConfig.config.time === undefined ||
        actionConfig.config.time === null
    );
};

/**
 * Checks if the filter action configuration has an error.
 *
 * @param {FilterAction} actionConfig - The filter action configuration.
 * @returns {boolean} - True if the filter configuration is empty, otherwise false.
 */
const checkFilterNodeError: CheckNodeErrorFunction<FilterAction> = (
    actionConfig,
) => {
    return isEmpty(actionConfig.config.filterConfig);
};

/**
 * Checks if the split action configuration has an error.
 *
 * @param {SplitAction} actionConfig - The split action configuration.
 * @returns {boolean} - Always returns false as no error condition is assumed for SplitAction.
 */
const checkSplitNodeError: CheckNodeErrorFunction<SplitAction> = () => {
    return false; // Assuming no error condition for SplitAction
};

/**
 * Checks if the set action configuration has an error.
 *
 * @param {SetAction} actionConfig - The set action configuration.
 * @returns {boolean} - Always returns false as no error condition is assumed for SetAction.
 */
const checkSetNodeError: CheckNodeErrorFunction<SetAction> = () => {
    return false;
};

/**
 * Checks if the send message action configuration has an error.
 *
 * @param {SendMessageAction} actionConfig - The send message action configuration.
 * @returns {boolean} - True if the template ID or communication details ID is missing, otherwise false.
 */
const checkSendMessageNodeError: CheckNodeErrorFunction<SendMessageAction> = (
    actionConfig,
) => {
    return (
        !actionConfig.config.messageConfig.templateDetails.id ||
        !actionConfig.config.messageConfig.communicationDetails.id
    );
};

/**
 * Checks if the API action configuration has an error.
 *
 * @param {ApiAction} actionConfig - The API action configuration.
 * @returns {boolean} - True if the API URL is missing, otherwise false.
 */
const checkApiNodeError: CheckNodeErrorFunction<ApiAction> = (actionConfig) => {
    return !actionConfig.config.apiConfig.httpConfig.url;
};

/**
 * Checks if any action in the actionConfig has an error.
 *
 * @param {JourneyAction[]} actionConfig - An array of action configurations.
 * @returns {boolean} - True if any action has an error, otherwise false.
 */
const checkNodeErrorMap: CheckNodeErrorMap = {
    [ActionType.WAIT]: checkWaitNodeError,
    [ActionType.WAIT_UNTIL]: checkWaitUntilNodeError,
    [ActionType.FILTER]: checkFilterNodeError,
    [ActionType.SPLIT]: checkSplitNodeError,
    [ActionType.SET]: checkSetNodeError,
    [ActionType.SEND_MESSAGE]: checkSendMessageNodeError,
    [ActionType.API]: checkApiNodeError,
};

/**
 * Checks if any action in the actionConfig has an error.
 *
 * @param {JourneyAction[]} actionConfig - An array of action configurations.
 * @returns {boolean} - True if any action has an error, otherwise false.
 */
export const hasNodeError = (actionConfig: JourneyAction[]): boolean => {
    return actionConfig.some((action) => {
        const checkNodeError = checkNodeErrorMap[action.type];
        return checkNodeError ? checkNodeError(action) : false;
    });
};

/**
 * Checks if the trigger node has an error.
 *
 * @param {BaseTrigger} triggerConfig - The trigger configuration.
 * @returns {boolean} - True if the event name or event source is missing, otherwise false.
 */
export const hasTriggerNodeError = (triggerConfig: BaseTrigger) => {
    return !triggerConfig.eventName || !triggerConfig.eventSource;
};

/**
 * Checks if the entry logic has an error.
 *
 * @param {JourneyEntryLogic} entryLogic - The entry logic configuration.
 * @returns {boolean} - True if the cooldown is missing, otherwise false.
 */
export const hasEntryLogicError = (entryLogic: JourneyEntryLogic) => {
    return entryLogic.cooldown === null;
};
