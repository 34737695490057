import { type CommunicationChannel } from './communications';

export type DeliveryControl = {
    projectId: string;
    quietHours: QuietHours;
    cappingRules: CappingRule[] | null;
    createdAt: Date;
    updatedAt: Date;
};

export type QuietHours = {
    enabled: boolean;
    start: string; // HH:mm
    end: string; // HH:mm
};

export type CappingRule = {
    id: string;
    name: string;
    description: string;
    channel: CommunicationChannel;
    limit: number;
    period: Period;
    priority: number;
    startAt?: number;
};

export type Period = {
    type: PeriodType;
    value: number;
};

export enum PeriodType {
    MINUTE = 'MINUTE',
    HOUR = 'HOUR',
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
}

export type DeliveryControlCreateRequest = Omit<
    DeliveryControl,
    'createdAt' | 'updatedAt' | 'projectId'
>;

export type DeliveryControlUpdateRequest =
    Partial<DeliveryControlCreateRequest>;

export type DeliveryControlRuleCounter = {
    userId: string;
    channel: string;
    counter: number;
    ruleId: string;
};
