import { subject } from '@casl/ability';
import { UpdateBlobStorage } from '@components/BlobStorage';
import { useAbilityContext } from '@components/common/Authorization';
import SettingsTitle from '@components/common/Settings/SettingsTitle';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import { Box, Flex } from '@mantine/core';
import { useApp } from '@providers/AppProvider';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

const BlobSettings: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const ability = useAbilityContext();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { user } = useApp();
    const cannotViewBlob = ability.cannot(
        'view',
        subject('ProjectSettings', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const { showToastError } = useNotify();
    if (cannotViewBlob) {
        showToastError({ title: t('authorization_access_error.message') });
        return <Redirect to="/no-access" />;
    }
    return (
        <>
            <Box>
                <Flex className="items-center justify-between w-full">
                    <SettingsTitle
                        title={t('workspace_settings.blob_settings_title')}
                    />
                </Flex>

                <Box className="my-6">
                    <UpdateBlobStorage />
                </Box>
            </Box>
        </>
    );
};

export default React.memo(BlobSettings);
