import { Title } from '@mantine/core';
import React from 'react';

interface SettingsTitleProps {
    title: string;
}

const SettingsTitle: React.FC<SettingsTitleProps> = ({ title }) => {
    if (!title) return null;
    return (
        <Title className="text-base font-semibold text-gray-800">{title}</Title>
    );
};

export default SettingsTitle;
