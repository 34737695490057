import { type ContentStepComponentProps } from '@components/Campaigns/Builder/types';
import {
    getNextContentStep,
    getPreviousContentStep,
} from '@components/Campaigns/Builder/utils';
import Checkbox from '@components/common/Checkbox';
import { useLocale } from '@hooks/useLocale';
import { CampaignFieldType, type ParsedVariable } from '@lightdash/common';
import { Box, Divider, Group, Menu, Stack, Text } from '@mantine/core';
import {
    useCampaignContext,
    type TrackedLinks,
} from '@providers/CampaignProvider';
import React, {
    useCallback,
    useEffect,
    useMemo,
    type SyntheticEvent,
} from 'react';

const LinkTracking: React.FC<ContentStepComponentProps> = ({
    templateMetadata,
    setActiveContentStep,
    activeContentStep,
}) => {
    const { t } = useLocale();
    const { state, actions } = useCampaignContext((context) => context);
    const { campaignPayload, editor } = state;
    const { communicationDetails, templateDetails } = campaignPayload;
    const { setCurrentStepCallback, setPreviousStepCallback, setTrackedLinks } =
        actions;

    const linkChildren = useMemo(() => {
        let result: {
            [key: string]: {
                tagName: string;
                imageUrl: string;
                content: string;
            };
        } = {};
        if (editor) {
            const rootComponent = editor?.DomComponents.getWrapper();
            if (rootComponent) {
                const linkComponents = rootComponent?.findType('link');
                linkComponents.forEach((comp) => {
                    const childrenComponents = comp.components();
                    let childrenComponentType = '';
                    let imageUrl: string = '';
                    childrenComponents.forEach((component, index) => {
                        if (component.attributes.type && index === 0) {
                            childrenComponentType = component.attributes.type;
                            if (childrenComponentType === 'image') {
                                imageUrl = component.attributes.src;
                            }
                        }
                    });
                    const child = comp.getChildAt(0);
                    const id = comp?.attributes?.attributes?.['data-srt-id'];
                    if (id) {
                        result = {
                            ...result,
                            [id]: {
                                tagName:
                                    (childrenComponentType === 'textnode'
                                        ? comp.attributes.type
                                        : childrenComponentType) || '',
                                content:
                                    childrenComponentType !== 'image'
                                        ? child.content || comp.getInnerHTML()
                                        : '',
                                imageUrl,
                            },
                        };
                    }
                });
            }
        }
        return result;
    }, [editor]);

    const links = useMemo(
        () => templateMetadata?.contents[0].parsedVariables,
        [templateMetadata],
    );

    const getTrackedLinksPayload = useCallback(
        (enableTracking: boolean) => {
            if (links) {
                const payload = links.reduce((accum, curr) => {
                    if (accum) {
                        return {
                            ...accum,
                            [curr.key]: {
                                value: curr.value,
                                defaultValue: '',
                                type: CampaignFieldType.STATIC,
                                enableTracking,
                            },
                        };
                    }
                    return {
                        [curr.key]: {
                            value: curr.value,
                            defaultValue: '',
                            type: CampaignFieldType.STATIC,
                            enableTracking,
                        },
                    };
                }, {});
                return payload;
            }
            return {};
        },
        [links],
    );

    const trackedLinks: TrackedLinks = useMemo(() => {
        if (templateDetails?.mappings) {
            return Object.keys(templateDetails?.mappings)
                .filter(
                    (mapping) =>
                        templateDetails?.mappings?.[mapping]?.enableTracking !==
                        undefined,
                )
                .reduce((accum, curr) => {
                    if (accum) {
                        return {
                            ...accum,
                            [curr]: templateDetails?.mappings[curr],
                        };
                    }
                    return {
                        [curr]: templateDetails?.mappings[curr],
                    };
                }, {});
        }
        return [];
    }, [templateDetails?.mappings]);

    const handleTrackAll = (e: SyntheticEvent) => {
        if ('checked' in e.target && e.target.checked && links) {
            const payload = getTrackedLinksPayload(true);
            setTrackedLinks({ ...trackedLinks, ...payload });
            return;
        }
        const payload = getTrackedLinksPayload(false);
        setTrackedLinks({ ...trackedLinks, ...payload });
    };

    const handleTrackLink = (val: boolean, link: ParsedVariable) => {
        if (val) {
            setTrackedLinks({
                ...trackedLinks,
                [link.key]: {
                    value: link.value,
                    type: CampaignFieldType.STATIC,
                    defaultValue: '',
                    enableTracking: true,
                },
            });
            return;
        }
        setTrackedLinks({
            ...trackedLinks,
            [link.key]: {
                ...trackedLinks[link.key],
                enableTracking: false,
            },
        });
    };

    const nextStep = useMemo(
        () =>
            getNextContentStep(
                activeContentStep,
                communicationDetails.channel,
                templateMetadata?.contents[0].parsedVariables,
            ),
        [activeContentStep, communicationDetails, templateMetadata],
    );

    const prevStep = useMemo(
        () =>
            getPreviousContentStep(
                activeContentStep,
                communicationDetails.channel,
                templateMetadata?.contents[0].parsedVariables,
            ),
        [activeContentStep, communicationDetails, templateMetadata],
    );

    const nextStepCallback = useCallback(() => {
        if (nextStep) {
            setActiveContentStep(nextStep);
        }
    }, [nextStep, setActiveContentStep]);

    const prevStepCallback = useCallback(() => {
        if (prevStep) {
            setActiveContentStep(prevStep);
        }
    }, [prevStep, setActiveContentStep]);

    useEffect(() => {
        setCurrentStepCallback({
            callback: nextStepCallback,
            skipExecutionAfterCallback: Boolean(nextStep),
        });
    }, [nextStepCallback, nextStep, setCurrentStepCallback]);

    useEffect(() => {
        setPreviousStepCallback({
            callback: prevStepCallback,
            skipExecutionAfterCallback: Boolean(prevStep),
        });
    }, [prevStepCallback, prevStep, setPreviousStepCallback]);

    return (
        <Box>
            <Text>{t('campaigns_builder.tracking.link_tracking')}</Text>
            <Text className="text-xs text-gray-600 font-medium mt-1">
                {t('campaigns_builder.tracking.link_tracking_description')}
            </Text>
            <Box className="border-base p-[6px] mt-3 max-h-[550px] overflow-scroll">
                <Group className="py-[6px] px-3">
                    <Checkbox
                        checked={
                            Object.keys(trackedLinks).length ===
                                links?.length &&
                            Object.values(trackedLinks).every(
                                (link) => link.enableTracking,
                            )
                        }
                        onClick={handleTrackAll}
                    />
                    <Text>All links</Text>
                </Group>
                <Divider size="md" className="mt-2 mb-2 border-t-shade-2" />
                {links?.map((link) => (
                    <Stack className="py-2 px-3" spacing={4} key={link.key}>
                        <Group>
                            <Checkbox
                                checked={
                                    trackedLinks?.[link.key]?.enableTracking
                                }
                                onChange={(e) =>
                                    handleTrackLink(e.target.checked, link)
                                }
                            />
                            <Text>{link.value}</Text>
                        </Group>
                        {linkChildren && (
                            <Text className="text-gray-600 underline flex gap-1">
                                <Menu
                                    trigger="hover"
                                    position="top"
                                    disabled={
                                        !linkChildren?.[link.key].imageUrl
                                    }
                                >
                                    <Menu.Target>
                                        <span className="capitalize">
                                            {linkChildren?.[link.key].tagName}{' '}
                                        </span>
                                    </Menu.Target>
                                    <Menu.Dropdown className="p-2 bg-gray-850 rounded-lg">
                                        <Box>
                                            <img
                                                src={
                                                    linkChildren?.[link.key]
                                                        .imageUrl
                                                }
                                                alt="something"
                                                width={500}
                                            />
                                        </Box>
                                    </Menu.Dropdown>
                                </Menu>

                                {linkChildren?.[link.key].content ? (
                                    <Text
                                        className="truncate w-[450px]"
                                        dangerouslySetInnerHTML={{
                                            __html: linkChildren?.[link.key]
                                                .content,
                                        }}
                                    />
                                ) : null}
                            </Text>
                        )}
                    </Stack>
                ))}
            </Box>
        </Box>
    );
};

export default LinkTracking;
