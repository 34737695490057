import { sortmentApi } from '@api/index';
import { type ApiError } from '@lightdash/common';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';

const getCommunicationChannelsApi = ({
    projectUuid,
}: {
    projectUuid: string;
}) =>
    sortmentApi({
        url: `/projects/${projectUuid}/communications/channels`,
        method: 'GET',
        body: undefined,
    });

export type CommunicationChannels = {
    name: string;
    value: string;
}[];

export const useCommunicationChannels = (isEnabled: boolean) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    return useQuery<any, ApiError, CommunicationChannels>({
        queryFn: () => getCommunicationChannelsApi({ projectUuid }),
        queryKey: [QueryKeys.GET_COMMUNICATION_CHANNEL, projectUuid, isEnabled],
        enabled: isEnabled,
    });
};
