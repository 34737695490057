import { Group, Text } from '@mantine/core';
import {
    DateTimePicker,
    type DateTimePickerProps,
    type DayOfWeek,
} from '@mantine/dates';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment';

import { type FC } from 'react';

dayjs.extend(timezone);

interface Props
    extends Omit<
        DateTimePickerProps,
        'firstDayOfWeek' | 'getDayProps' | 'value' | 'onChange'
    > {
    value: Date | null;
    onChange: (value: Date) => void;
    firstDayOfWeek: DayOfWeek;
    placeholder?: string;
    showTimezone?: boolean;
}

const FilterDateTimePicker: FC<React.PropsWithChildren<Props>> = ({
    value,
    onChange,
    firstDayOfWeek,
    showTimezone = true,
    ...rest
}) => {
    const displayFormat = 'YYYY-MM-DD HH:mm:ss';
    return (
        <Group noWrap spacing="xs" align="center" w="100%">
            {/* // FIXME: until mantine 7.4: https://github.com/mantinedev/mantine/issues/5401#issuecomment-1874906064
            // @ts-ignore */}
            <DateTimePicker
                size="xs"
                w="100%"
                miw={185}
                valueFormat={displayFormat}
                {...rest}
                popoverProps={{ shadow: 'sm', ...rest.popoverProps }}
                firstDayOfWeek={firstDayOfWeek}
                value={value}
                onChange={(date) => {
                    if (!date) return;
                    onChange(date);
                }}
                inputWrapperOrder={['input', 'description']}
                //Disabled the description for now as we it is not alligned with sortment design.
                // description={
                //     <Text ml="two">
                //         UTC time: {value?.toUTCString().replace('GMT', '')}
                //     </Text>
                // }
                styles={{
                    input: {
                        color: 'rgb(var(--color-gray-600))',
                        height: 38,
                    },
                }}
            />
            {showTimezone && (
                <Text
                    size="xs"
                    sx={{ whiteSpace: 'nowrap' }}
                    className="text-gray-500"
                >
                    {(() => {
                        const tz = dayjs.tz.guess();
                        const abbr = moment.tz(tz).zoneAbbr();
                        const offset = moment.tz(tz).format('Z');
                        return `(${abbr}, GMT ${offset})`;
                    })()}
                </Text>
            )}
        </Group>
    );
};

export default FilterDateTimePicker;
