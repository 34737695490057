import IntegrationOption from '@components/Campaigns/Builder/IntegrationOption';
import Modal from '@components/common/modal/Modal';
import SkeletonLoader from '@components/common/SkeletonLoader';
import {
    useGetIntegratedProviderConfig,
    useGetProviderConfig,
} from '@hooks/useChannels';
import { useLocale } from '@hooks/useLocale';
import { type Campaign, type Integration } from '@lightdash/common';
import { Box, Button, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ChannelPage } from '@pages/Channel';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { ButtonVariant } from '../../../../../../../mantineTheme';
import ActionTitle from '../../ActionTitle';

interface CampaignActionChannelProps {
    communicationDetails: Campaign['communicationDetails'] | undefined;
    setJourneyChannelPayload: (channel: Integration | null) => void;
}

const CampaignActionChannel: React.FC<CampaignActionChannelProps> = ({
    communicationDetails,
    setJourneyChannelPayload,
}) => {
    const { t } = useLocale();

    const {
        data: integrationConfig,
        isInitialLoading: isIntegrationConfigLoading,
    } = useGetIntegratedProviderConfig(
        communicationDetails?.providerId,
        communicationDetails?.id,
    );

    const { data: providerConfig, isInitialLoading: isProviderConfigLoading } =
        useGetProviderConfig(communicationDetails?.providerId);

    const [opened, { open: openChannelSelector, close: closeChannelSelector }] =
        useDisclosure(false);

    const onProviderChange = useCallback(
        (provider: Integration) => {
            closeChannelSelector();
            setJourneyChannelPayload(provider);
        },
        [setJourneyChannelPayload, closeChannelSelector],
    );

    const renderChannelElement = useMemo(() => {
        if (!communicationDetails || isEmpty(communicationDetails)) {
            return (
                <Button
                    variant={ButtonVariant.DEFAULT}
                    className="w-fit"
                    onClick={openChannelSelector}
                >
                    {t('journey_builder.channel_element_select_channel_btn')}
                </Button>
            );
        }

        if (isIntegrationConfigLoading || isProviderConfigLoading)
            return (
                <Box className="p-3">
                    <SkeletonLoader height={70} />
                </Box>
            );
        if (!integrationConfig || !providerConfig) return <></>;

        return (
            <IntegrationOption
                title={integrationConfig?.customName}
                providerName={providerConfig.providerName}
                providerLogoUrl={providerConfig.logoUrl}
                integrationId={integrationConfig.integrationId ?? ''}
                isMutable={false}
                showRightSection={true}
                channel={communicationDetails.channel}
                customClass="w-full border-none !cursor-default hover:bg-white"
                rightSection={
                    <Text
                        className="text-sm font-semibold cursor-pointer text-blu-800"
                        onClick={openChannelSelector}
                    >
                        Change
                    </Text>
                }
                boxShadow={false}
            />
        );
    }, [
        communicationDetails,
        isIntegrationConfigLoading,
        isProviderConfigLoading,
        integrationConfig,
        providerConfig,
        openChannelSelector,
        t,
    ]);

    return (
        <>
            <Stack>
                <ActionTitle
                    title={t('journey_builder.channel_element_title')}
                />
                {renderChannelElement}
            </Stack>
            <Modal
                opened={opened}
                onClose={closeChannelSelector}
                title={'Choose channel'}
                size="lg"
            >
                {/* TODO: Check why onClick is opening settings */}
                <ChannelPage
                    allowChannelSetup={false}
                    handleProviderSelect={onProviderChange}
                    showSetupLabels={false}
                />
            </Modal>
        </>
    );
};

export default CampaignActionChannel;
