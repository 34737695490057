import FieldListItem from '@components/Audience/Filters/FieldListItem';
import {
    FilterInputComponent,
    getFilterOperatorOptions,
} from '@components/Audience/Filters/FilterInputs';
import {
    MenuForDimensionFilterRule,
    MenuForEventFilterRule,
} from '@components/Audience/Filters/FilterRuleMenuItem';
import { useFiltersContext } from '@components/Audience/Filters/FiltersProvider';
import { getTimeFrameFieldFromTable } from '@components/Audience/Filters/utils/getTimeframeFieldFromTable';
import MantineIcon from '@components/common/MantineIcon';
import FilterOperatorSelect from '@components/common/Select/FilterOperatorSelect';
import {
    addFilterRule,
    DimensionType,
    FilterType,
    getFilterRuleWithDefaultValue,
    getFilterTypeFromItem,
    getItemId as getFieldId,
    isField,
    isFilterableField,
    isTableCalculation,
    type FilterableField,
    type FilterRule,
    type Filters,
    type JourneyDataSchema,
} from '@lightdash/common';
import { ActionIcon, Box, Button, Flex, Group, Text } from '@mantine/core';
import { CaretDown } from '@phosphor-icons/react';
import { useRelationContext } from '@providers/RelationProvider';
import { IconX } from '@tabler/icons-react';
import { useCallback, useMemo, type FC } from 'react';
import { ButtonVariant } from '../../../../mantineTheme';
import JourneyPropertySelector from './JourneyPropertySelector';
import { type JourneyProperty } from './useJourneyProperties';

type Props = {
    fields: FilterableField[];
    filterRule: FilterRule;
    isEditMode: boolean;
    onChange: (value: FilterRule) => void;
    onDelete: () => void;
    onConvertToGroup?: () => void;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    groupIndex: number;
    journeyDataSchema: JourneyDataSchema;
    customMetricFilter?: boolean;
    withinPortal?: boolean;
    onSubmit: (item: JourneyProperty) => void;
};

const JourneyFilterRuleForm: FC<Props> = ({
    fields,
    filterRule,
    isEditMode,
    onChange,
    onDelete,
    onConvertToGroup,
    filters,
    setFilters,
    groupIndex,
    journeyDataSchema,
    onSubmit,
}) => {
    const { popoverProps } = useFiltersContext();

    const activeField = useMemo(() => {
        return fields.find((field) =>
            field?.type === DimensionType.EVENT
                ? field.fieldReference === filterRule.values?.[0]
                : getFieldId(field) === filterRule.target.fieldId,
        );
    }, [fields, filterRule.target.fieldId, filterRule.values]);

    const { activeRelation } = useRelationContext();
    const filterType = useMemo(() => {
        return activeField
            ? getFilterTypeFromItem(activeField)
            : FilterType.STRING;
    }, [activeField]);

    const filterOperatorOptions = useMemo(() => {
        return getFilterOperatorOptions(filterType);
    }, [filterType]);

    const addTimeWindow = useCallback(() => {
        const field = getTimeFrameFieldFromTable({
            fields,
            tableId:
                activeField && isTableCalculation(activeField)
                    ? ''
                    : activeField?.table,
            activeRelation,
        });
        if (!field) return;

        if (isField(field) && isFilterableField(field)) {
            setFilters(
                addFilterRule({
                    filters,
                    field,
                    ...(Boolean(field.fieldReference) && {
                        value: field.fieldReference,
                    }),
                }),
                false,
                groupIndex,
            );
        }
    }, [activeField, activeRelation, fields, filters, groupIndex, setFilters]);

    const duplicateFilter = useCallback(() => {
        if (activeField) {
            setFilters(
                addFilterRule({
                    filters,
                    field: activeField,
                    ...(filterRule.values?.length && {
                        value: filterRule.values,
                    }),
                }),
                false,
                groupIndex,
            );
        }
    }, [activeField, filterRule.values, filters, groupIndex, setFilters]);

    const renderSelectComponent = useMemo(() => {
        if (!activeField) return null;
        return (
            <FilterOperatorSelect
                isDisabled={!isEditMode}
                filterOperatorOptions={filterOperatorOptions}
                selectedOperator={filterRule.operator}
                onChange={(value) => {
                    if (!value) return;
                    onChange(
                        getFilterRuleWithDefaultValue(
                            activeField,
                            {
                                ...filterRule,
                                operator: value as FilterRule['operator'],
                            },
                            (filterRule.values?.length || 0) > 0
                                ? filterRule.values
                                : [1],
                        ),
                    );
                }}
            />
        );
    }, [activeField, filterOperatorOptions, filterRule, isEditMode, onChange]);

    const renderFieldSelect = useMemo(() => {
        if (!activeField) return null;

        return (
            <JourneyPropertySelector
                targetElement={
                    <Button
                        variant={ButtonVariant.UNSTYLED}
                        className="px-2 w-fit"
                        style={{
                            borderRadius: '8px',
                            background: 'white',
                            border: '1px solid',
                            borderColor: 'rgba(0, 0, 0, 0.06)',
                            boxShadow: `0px -1px 4px 0px rgba(0, 0, 0, 0.06) inset`,
                            fontSize: '14px',
                            color: 'rgb(var(--color-gray-600))',
                            width: 'wrap-content !important',
                            cursor: !isEditMode ? 'auto' : 'pointer',
                            minWidth: '8rem',
                        }}
                        disabled={!isEditMode}
                    >
                        <Flex justify="space-between" align={'center'}>
                            <FieldListItem
                                item={activeField}
                                disabled={!isEditMode}
                                showCheckbox={false}
                                checked={false}
                                disableHover
                            />
                            <CaretDown
                                color={'rgb(var(--color-gray-600)'}
                                weight={'regular'}
                            />
                        </Flex>
                    </Button>
                }
                journeyDataSchema={journeyDataSchema}
                onSubmit={(item) => {
                    onSubmit(item);
                }}
            />
        );
    }, [activeField, isEditMode, journeyDataSchema, onSubmit]);

    const renderFilterRuleOptions = useMemo(() => {
        if (!isEditMode || !activeField) return null;

        if (!onConvertToGroup)
            return (
                <ActionIcon onClick={onDelete}>
                    <MantineIcon icon={IconX} size="lg" />
                </ActionIcon>
            );

        if (activeField.type === DimensionType.EVENT) {
            return (
                <MenuForEventFilterRule
                    onDelete={onDelete}
                    addTimeWindow={addTimeWindow}
                    duplicateFilter={duplicateFilter}
                />
            );
        }

        return (
            <MenuForDimensionFilterRule
                onDelete={onDelete}
                duplicateFilter={duplicateFilter}
            />
        );
    }, [
        isEditMode,
        activeField,
        onConvertToGroup,
        onDelete,
        addTimeWindow,
        duplicateFilter,
    ]);

    return (
        <Group className="flex flex-row items-center gap-1.5 w-full flex-nowrap">
            {activeField ? (
                <>
                    {activeField.type === DimensionType.EVENT ? (
                        <>
                            {renderSelectComponent}
                            {renderFieldSelect}
                        </>
                    ) : (
                        <>
                            <Box>{renderFieldSelect}</Box>
                            <Box> {renderSelectComponent}</Box>

                            <Box>
                                <FilterInputComponent
                                    filterType={filterType}
                                    field={activeField}
                                    rule={filterRule}
                                    onChange={onChange}
                                    disabled={!isEditMode}
                                    popoverProps={popoverProps}
                                />
                            </Box>
                        </>
                    )}
                </>
            ) : (
                <Text color="dimmed">
                    Tried to reference field with unknown id:{' '}
                    {filterRule.target.fieldId}
                </Text>
            )}

            {renderFilterRuleOptions}
        </Group>
    );
};

export default JourneyFilterRuleForm;
