import Page from '@components/Page/Page';
import PageSpinner from '@components/PageSpinner';
import ProjectSettingsSidebar from '@components/ProjectSettings/Sidebar';
import {
    ProjectSettingsMenuGroups,
    type SettingListItemType,
} from '@components/ProjectSettings/Sidebar/ProjectSettingsMenuItems';
import { useOrganization } from '@hooks/organization/useOrganization';
import { useActiveProjectUuid } from '@hooks/useActiveProject';
import { useLocale } from '@hooks/useLocale';
import React from 'react';
import { useProject } from '../hooks/useProject';

import ErrorState from '@components/common/ErrorState';
import { Route, Switch } from 'react-router-dom';

const ProjectSettings: React.FC = () => {
    const {
        data: organization,
        isInitialLoading: isOrganizationLoading,
        error: organizationError,
    } = useOrganization();
    const { activeProjectUuid, isLoading: isActiveProjectUuidLoading } =
        useActiveProjectUuid();
    const {
        data: project,
        isInitialLoading: isProjectLoading,
        error: projectError,
    } = useProject(activeProjectUuid);
    const { t } = useLocale();

    const renderSettingRoute = (
        setting: SettingListItemType,
        index: number,
    ) => (
        <Route key={index} exact path={setting.routeMatch}>
            {setting.children}
        </Route>
    );

    const renderedSettingRoutes = ProjectSettingsMenuGroups(
        project?.projectUuid ?? '',
    ).flatMap((group) => group.map(renderSettingRoute));

    if (
        isOrganizationLoading ||
        isActiveProjectUuidLoading ||
        isProjectLoading
    ) {
        return <PageSpinner />;
    }

    if (organizationError || projectError) {
        return <ErrorState error={projectError?.error} />;
    }

    if (!organization || !project) return null;

    return (
        <Page
            withFullHeight
            withPaddedContent
            title={t('project_settings.page_title')}
            sidebar={
                <ProjectSettingsSidebar projectUuid={project.projectUuid} />
            }
        >
            <Switch>{renderedSettingRoutes}</Switch>
        </Page>
    );
};

export default React.memo(ProjectSettings);
