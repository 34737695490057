import GCPImage from '@assets/images/GCP.svg';
import S3Image from '@assets/images/s3.svg';
import AmazonS3 from '@components/BlobStorage/BlobStorageForms/AmazonS3';
import { BlobStorageType, WarehouseTypes } from '@lightdash/common';
import { t as translate } from 'i18next';
import { type BlobStorageOptionProps } from 'types/BlobStorage';
import GCPStorage from './BlobStorageForms/GCPStorage';
import BigQueryStorageForm from './WarehouseStorageForms/BigQueryStorageForm';
import DatabricksStorageForm from './WarehouseStorageForms/DatabricksStorageForms';
import RedshiftStorageForm from './WarehouseStorageForms/RedshiftStorageForm';
import SnowflakeStorageForm from './WarehouseStorageForms/SnowflakeStorageForm';
export const blobStores: Record<
    WarehouseTypes,
    Pick<BlobStorageOptionProps, 'title' | 'icon' | 'blobType'>[]
> = {
    [WarehouseTypes.SNOWFLAKE]: [
        {
            title: translate('blobStorage.s3_title'),
            icon: S3Image,
            blobType: BlobStorageType.S3,
        },
        {
            title: translate('blobStorage.gcp_title'),
            icon: GCPImage,
            blobType: BlobStorageType.GoogleCloudStorage,
        },
        // TODO azure blob need to be added.
    ],
    [WarehouseTypes.BIGQUERY]: [
        {
            title: translate('blobStorage.gcp_title'),
            icon: GCPImage,
            blobType: BlobStorageType.GoogleCloudStorage,
        },
    ],
    [WarehouseTypes.REDSHIFT]: [
        {
            title: translate('blobStorage.s3_title'),
            icon: S3Image,
            blobType: BlobStorageType.S3,
        },
    ],
    [WarehouseTypes.DATABRICKS]: [
        {
            title: translate('blobStorage.s3_title'),
            icon: S3Image,
            blobType: BlobStorageType.S3,
        },
        {
            title: translate('blobStorage.gcp_title'),
            icon: GCPImage,
            blobType: BlobStorageType.GoogleCloudStorage,
        },
        //TODO azure cloud need to be added
    ],
    [WarehouseTypes.POSTGRES]: [],
    [WarehouseTypes.TRINO]: [],
};

export const BlobStorageTypeForms = {
    [BlobStorageType.S3]: AmazonS3,
    [BlobStorageType.GoogleCloudStorage]: GCPStorage,
};

export const WarehouseStorageTypeForms = {
    [WarehouseTypes.REDSHIFT]: RedshiftStorageForm,
    [WarehouseTypes.BIGQUERY]: BigQueryStorageForm,
    [WarehouseTypes.DATABRICKS]: DatabricksStorageForm,
    [WarehouseTypes.POSTGRES]: RedshiftStorageForm, //TODO: Replace with POSTGRES when implemented
    [WarehouseTypes.SNOWFLAKE]: SnowflakeStorageForm,
    [WarehouseTypes.TRINO]: RedshiftStorageForm, //TODO: Replace with TRINO when implemented
};
