import TransparentInput from '@components/common/Inputs/TransparentInput';
import { useLocale } from '@hooks/useLocale';
import { type JourneyNode } from '@lightdash/common';
import {
    ActionIcon,
    Box,
    Button,
    Divider,
    Group,
    Stack,
    Text,
} from '@mantine/core';
import { X } from '@phosphor-icons/react';
import React from 'react';
import { ButtonVariant } from '../../../../../mantineTheme';

interface BaseBlockConfigContainerProps {
    nodeData: JourneyNode;
    icon: React.ReactNode;
    onDescriptionChange: (description: string) => void;
    onBlockClose: () => void;
}

interface ShowChangeButtonProps extends BaseBlockConfigContainerProps {
    showChangeButton: true;
    onChangeButtonClick: () => void;
}

interface NoChangeButtonProps extends BaseBlockConfigContainerProps {
    showChangeButton: false;
}

type BlockConfigContainerProps = ShowChangeButtonProps | NoChangeButtonProps;

const BlockConfigContainer: React.FC<
    React.PropsWithChildren<BlockConfigContainerProps>
> = ({
    children,
    nodeData,
    onDescriptionChange,
    onBlockClose,
    icon,
    showChangeButton,
    ...rest
}) => {
    const { t } = useLocale();

    return (
        <Stack className="h-full gap-0">
            <Box className="px-2 py-2">
                <ActionIcon size={'lg'} onClick={onBlockClose}>
                    <X weight="bold" color={'rgb(var(--color-gray-700))'} />
                </ActionIcon>
            </Box>
            <Divider className="border-t-gray-200" />
            <Stack className="w-full gap-1 py-3">
                <Group className="justify-between px-3">
                    <Group className="gap-2">
                        <ActionIcon
                            variant="outline"
                            className="bg-white border-gray-200 rounded-lg cursor-default w-7 h-7"
                        >
                            {icon}
                        </ActionIcon>
                        <Text className="font-medium text-gray-800">
                            {nodeData.title}
                        </Text>
                    </Group>

                    {showChangeButton && (
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={
                                showChangeButton &&
                                'onChangeButtonClick' in rest
                                    ? (rest as ShowChangeButtonProps)
                                          .onChangeButtonClick
                                    : () => {}
                            }
                        >
                            {t('common.change')}
                        </Button>
                    )}
                </Group>
                <Box className="px-1">
                    <TransparentInput
                        placeholder={t('event_source.add_a_description')}
                        value={nodeData.description}
                        onChange={(e) =>
                            onDescriptionChange?.(e.currentTarget.value)
                        }
                        styles={() => ({
                            input: {
                                color: 'rgb(var(--color-gray-500))',
                                fontWeight: 400,
                            },
                        })}
                    />
                </Box>
            </Stack>

            <Divider className="border-t-gray-200" />
            <Stack className="flex-grow p-3 overflow-y-auto">{children}</Stack>
        </Stack>
    );
};

export default React.memo(BlockConfigContainer);
