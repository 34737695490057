import { WarehouseStorageTypeForms } from '@components/BlobStorage/blobConfig';
import { type WarehouseTypes } from '@lightdash/common';
import React from 'react';

interface WarehouseStorageProps {
    disabled: boolean;
    selectedWarehouse: WarehouseTypes | undefined;
}

const WarehouseStorageForms: React.FC<WarehouseStorageProps> = ({
    disabled,
    selectedWarehouse,
}) => {
    if (selectedWarehouse === undefined) return null;

    const BlobStorageForm =
        (selectedWarehouse && WarehouseStorageTypeForms[selectedWarehouse]) ||
        WarehouseStorageTypeForms[selectedWarehouse] ||
        WarehouseStorageTypeForms.redshift;

    return (
        <div>
            <BlobStorageForm disabled={disabled} />
        </div>
    );
};

export default WarehouseStorageForms;
