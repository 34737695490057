import { Select } from '@mantine/core';
import React, { type FC } from 'react';
import { filterHourly, Frequency } from './cronInputUtils';

export type FrequencyItem = {
    value: Frequency;
    label: string;
};

const FrequencyItems: Array<FrequencyItem> = [
    {
        value: Frequency.HOURLY,
        label: 'Hourly',
    },
    {
        value: Frequency.DAILY,
        label: 'Daily',
    },
    {
        value: Frequency.WEEKLY,
        label: 'Weekly',
    },
    {
        value: Frequency.MONTHLY,
        label: 'Monthly',
    },
    {
        value: Frequency.CUSTOM,
        label: 'CRON expression',
    },
];

const FrequencySelect: FC<{
    disabled?: boolean;
    value: Frequency;
    onChange: (value: Frequency) => void;
    disableHourly: boolean | undefined;
}> = ({ disabled, value, onChange, disableHourly }) => {
    return (
        <Select
            // styles={() => ({
            //     input: {
            //         width: '12rem !important',
            //         padding: '0 0.9rem',
            //     },
            //     rightSection: {
            //         display: 'none',
            //     },
            // })}
            data={disableHourly ? filterHourly(FrequencyItems) : FrequencyItems}
            value={value}
            withinPortal
            disabled={disabled}
            onChange={onChange}
            // w={210}
            styles={{
                input: {
                    maxWidth: '150px',
                    paddingRight: '5px',
                    paddingLeft: '8px',
                },
            }}
            sx={{ alignSelf: 'start' }}
        />
    );
};
export default FrequencySelect;
