import Stepper from '@components/common/Stepper';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { type BuilderProgressProps } from '../../type';

const BuilderProgress = <T,>({
    currentStep,
    isValidStep,
    setCurrentStep,
    builderSteps,
}: BuilderProgressProps<T>) => {
    const currentStepIndex = useMemo(
        () => builderSteps.findIndex((step) => step.key === currentStep),
        [currentStep, builderSteps],
    );

    const [active, setActive] = useState(currentStepIndex);

    useEffect(() => {
        setActive(currentStepIndex);
    }, [currentStepIndex]);

    const handleStepClick = useCallback(
        (index: number) => {
            if (index <= active || isValidStep(builderSteps[index].key)) {
                setActive(index);
                setCurrentStep(builderSteps[index].key);
            }
        },
        [active, setCurrentStep, isValidStep, builderSteps],
    );

    return (
        <Stepper
            active={active}
            onStepClick={handleStepClick}
            steps={builderSteps.map((step) => ({
                key: step.key as string,
                label: step.label,
                isVisited: isValidStep(step.key),
            }))}
        />
    );
};

export default BuilderProgress;
