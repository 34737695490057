import JourneyBuilderControlPanel from '@components/Journeys/Builder/ControlPanel';
import ReactFlowCanvas from '@components/Journeys/Builder/ReactFlow/ReactFlowCanvas';
import { Box, Group } from '@mantine/core';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import { PAGE_HEADER_HEIGHT } from '@utils/constants';
import React from 'react';

const JourneyBuilderCanvas: React.FC<{}> = () => {
    const { controlPanel } = useJourneyBuilderContext(
        (context) => context.state,
    );
    const { isOpen: opened } = controlPanel;

    return (
        <Group
            style={{
                height: `calc(100vh - ${PAGE_HEADER_HEIGHT}px)`,
                overflow: 'hidden',
                position: 'relative',
            }}
            className="flex flex-row gap-0"
        >
            <Box
                style={{
                    width: '100%',
                }}
                className="flex-grow h-full"
            >
                <ReactFlowCanvas />
            </Box>

            <Box
                style={{
                    height: `calc(100vh - ${PAGE_HEADER_HEIGHT}px)`,
                    position: 'fixed',
                    marginTop: PAGE_HEADER_HEIGHT,
                    right: 0,
                    top: 0,
                    transition: 'transform 0.3s ease-in-out',
                    transform: opened ? 'translateX(0)' : 'translateX(100%)',
                }}
                className="bg-white border-l border-l-gray-200 overflow-y-auto overflow-x-hidden min-w-[21rem] max-w-[40vw]"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <JourneyBuilderControlPanel />
            </Box>
        </Group>
    );
};

export default React.memo(JourneyBuilderCanvas);
