import {
    CommunicationChannel as CommunicationChannelEnum,
    type CommunicationChannel,
    type EmailTemplateContentDetails,
    type ReducedTemplate,
    type SmsTemplateContentDetails,
    type WhatsAppTemplateContentDetails,
} from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import { useMemo } from 'react';

interface TemplateLibararyItemProps {
    template: ReducedTemplate;
    handleTemplateSelect: (template: ReducedTemplate) => void;
    channel: CommunicationChannel;
}

const TemplateGridItem = ({
    template,
    handleTemplateSelect,
    channel,
}: TemplateLibararyItemProps) => {
    const templateContent = useMemo(() => {
        if (channel === CommunicationChannelEnum.EMAIL) {
            return (
                <iframe
                    title="Mini Browser"
                    srcDoc={
                        (template?.content as EmailTemplateContentDetails)?.html
                    }
                    style={{
                        width: '210px',
                        height: '140px',
                    }}
                />
            );
        }

        if (channel === CommunicationChannelEnum.WHATSAPP) {
            return (
                <Text
                    style={{
                        width: '210px',
                        height: '250px',
                    }}
                    className="p-4 font-medium text-gray-800"
                >
                    {
                        (template?.content as WhatsAppTemplateContentDetails)
                            ?.body
                    }
                </Text>
            );
        }

        if (channel === CommunicationChannelEnum.SMS) {
            return (
                <Text
                    style={{
                        width: '210px',
                        height: '250px',
                    }}
                    className="p-4 font-medium text-gray-800"
                >
                    {(template?.content as SmsTemplateContentDetails)?.body}
                </Text>
            );
        }
    }, [channel, template?.content]);

    return (
        <Box
            key={template?.id}
            className="border-lg m-3.5 cursor-pointer w-[230px] p-1"
            onClick={() => handleTemplateSelect(template)}
            sx={() => ({
                '&:hover': {
                    borderRadius: '12px',
                    background: 'rgb(var(--color-gray-50))',
                    '&:template-container': {
                        backgroundColor: 'black',
                    },
                },
            })}
        >
            <Box className="bg-white template-container border-lg p-2 shadow-card border-base hover:bg-gray-800/[0.03] pointer-events-none">
                {templateContent}
            </Box>
            <Box className="p-2 text-sm font-semibold text-center text-gray-600">
                {template?.name}
            </Box>
        </Box>
    );
};

export default TemplateGridItem;
