import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import SettingsTitle from '@components/common/Settings/SettingsTitle';
import SkeletonLoader from '@components/common/SkeletonLoader';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import { ProjectSettings } from '@lightdash/common';
import { Box, Stack } from '@mantine/core';
import { useApp } from '@providers/AppProvider';
import { Redirect, useParams } from 'react-router-dom';
import { useGetDeliveryControl } from '../../../hooks/useDeliveryControlsApi';
import FrequencyCapping from './FrequencyCapping';
import QuietHours from './QuietHours';

const DeliveryControls = () => {
    const { data, isInitialLoading } = useGetDeliveryControl();
    const ability = useAbilityContext();
    const { user } = useApp();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    const isEditAllowed = ability.can(
        'update',
        subject(ProjectSettings.deliveryControls, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const { showToastError } = useNotify();
    const canViewDeliveryControls = ability.can(
        'view',
        subject('ProjectSettings', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    if (!canViewDeliveryControls) {
        showToastError({ title: t('authorization_access_error.message') });
        return <Redirect to="/no-access" />;
    }

    if (isInitialLoading) {
        return (
            <Box>
                <Stack spacing={2}>
                    <SkeletonLoader height={20} width={200} />
                    <SkeletonLoader height={40} width={200} />
                </Stack>
            </Box>
        );
    }
    return (
        <Box>
            <SettingsTitle
                title={t('project_settings.delivery_controls_title')}
            />
            <QuietHours data={data} isEditAllowed={isEditAllowed} />
            <FrequencyCapping data={data} isEditAllowed={isEditAllowed} />
        </Box>
    );
};

export default DeliveryControls;
