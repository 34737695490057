import Stepper from '@components/common/Stepper';
import { useCampaignContext } from '@providers/CampaignProvider';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CampaignBuilderSteps } from './../utils';

const CampaignBuilderProgress = () => {
    const { currentStep, isValidStep } = useCampaignContext(
        (context) => context.state,
    );
    const { setCurrentStep } = useCampaignContext((context) => context.actions);

    const currentStepIndex = useMemo(
        () =>
            CampaignBuilderSteps.findIndex((step) => step.key === currentStep),
        [currentStep],
    );

    const [active, setActive] = useState(currentStepIndex);

    useEffect(() => {
        setActive(currentStepIndex);
    }, [currentStepIndex]);

    const handleStepClick = useCallback(
        (index: number) => {
            const isValid = isValidStep(CampaignBuilderSteps[index].key);
            if (index <= active || isValid) {
                setActive(index);
                setCurrentStep(CampaignBuilderSteps[index].key);
            }
        },
        [active, isValidStep, setCurrentStep],
    );

    return (
        <>
            <Stepper
                active={active}
                onStepClick={handleStepClick}
                steps={CampaignBuilderSteps.map((step) => ({
                    key: step.key,
                    label: step.label,
                    isVisited: isValidStep(step.key),
                }))}
            />
        </>
    );
};

export default React.memo(CampaignBuilderProgress);
