import { Box, List as MantineList } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import React from 'react';

interface CustomListItemProps<T> {
    icon: React.ReactNode;
    onClick?: (data: T) => void;
    data: T;
}

export const CustomListItem = <T,>({
    icon = <CaretRight weight="bold" />,
    children,
    onClick,
    data,
}: React.PropsWithChildren<CustomListItemProps<T>>) => {
    return (
        <MantineList.Item
            sx={{
                position: 'relative',
                '&:hover .icon': {
                    opacity: 1,
                },
            }}
            className="w-full rounded cursor-pointer hover:bg-gray-50 p-2.5"
            onClick={() => onClick && onClick(data)}
        >
            {children}

            <Box
                className="icon"
                sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    opacity: 0,
                    transition: 'opacity 0.1s ease-in-out',
                }}
            >
                {icon}
            </Box>
        </MantineList.Item>
    );
};

interface CustomListProps<T> {
    items: Array<T>;
    itemTemplate: (item: T) => React.ReactNode;
    listHoverIcon?: React.ReactNode;
    onItemClick?: (data: T) => void;
}

const List = <T,>({
    items,
    itemTemplate,
    listHoverIcon,
    onItemClick,
    ...props
}: CustomListProps<T>) => {
    return (
        <MantineList {...props} className="w-full">
            {items.map((item, index) => (
                <CustomListItem
                    key={index}
                    icon={listHoverIcon}
                    onClick={onItemClick}
                    data={item}
                >
                    {itemTemplate(item)}
                </CustomListItem>
            ))}
        </MantineList>
    );
};

export default List;
