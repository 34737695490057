import { Ability } from '@casl/ability';
import AuthorizationError from '@components/AuthorizationError';
import { AbilityContext } from '@components/common/Authorization';
import { IconContext } from '@phosphor-icons/react';
import { ActiveJobProvider } from '@providers/ActiveJobProvider';
import { AppProvider } from '@providers/AppProvider';
import { ErrorLogsProvider } from '@providers/ErrorLogsProvider';
import MantineProvider from '@providers/MantineProvider';
import ThirdPartyProvider from '@providers/ThirdPartyServicesProvider';
import { TrackingProvider } from '@providers/TrackingProvider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import VersionAutoUpdater from './components/VersionAutoUpdater/VersionAutoUpdater';
import { ErrorBoundary } from './features/errorBoundary';
import { ChartColorMappingContextProvider } from './hooks/useChartColorConfig';
import ReactQueryProvider from './providers/ReactQueryProvider';
import Routes from './Routes';

const defaultAbility = new Ability();

const isMobile = window.innerWidth < 768;

const isMinimalPage = window.location.pathname.startsWith('/minimal');

const App = () => (
    <>
        <Helmet>
            <title>Sortment</title>
        </Helmet>

        <ReactQueryProvider>
            <MantineProvider>
                <Router>
                    <IconContext.Provider
                        value={{
                            color: 'rgb(var(--color-gray-600))',
                            size: 16,
                            weight: 'duotone',
                            mirrored: false,
                        }}
                    >
                        <AppProvider>
                            <VersionAutoUpdater />
                            <ThirdPartyProvider
                                enabled={isMobile || !isMinimalPage}
                            >
                                <ErrorBoundary wrapper={{ mt: '4xl' }}>
                                    <ErrorLogsProvider>
                                        <TrackingProvider
                                            enabled={isMobile || !isMinimalPage}
                                        >
                                            <AbilityContext.Provider
                                                value={defaultAbility}
                                            >
                                                <ActiveJobProvider>
                                                    <ChartColorMappingContextProvider>
                                                        {isMobile ? (
                                                            // <MobileRoutes />
                                                            <AuthorizationError
                                                                showFooter={
                                                                    false
                                                                }
                                                                message="Sortment is best viewed on Desktop."
                                                            />
                                                        ) : (
                                                            <Routes />
                                                        )}
                                                    </ChartColorMappingContextProvider>
                                                </ActiveJobProvider>
                                            </AbilityContext.Provider>
                                        </TrackingProvider>
                                    </ErrorLogsProvider>
                                </ErrorBoundary>
                            </ThirdPartyProvider>
                        </AppProvider>
                    </IconContext.Provider>
                </Router>
            </MantineProvider>

            <ReactQueryDevtools initialIsOpen={false} />
        </ReactQueryProvider>
    </>
);

export default App;
