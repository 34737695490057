import {
    type CreateRelationTableRequest,
    type Project,
} from '@lightdash/common';
import { type SchemaReducerState } from '@providers/SchemaProvider';
import { useMemo } from 'react';
import { SchemaBuilderStep, SchemaConfigStep } from './Builder/types';

export const useSchemaInitialData = (
    project: Project | undefined,
): SchemaReducerState => {
    return useMemo(() => {
        return {
            schemaPayload: {} as CreateRelationTableRequest,
            activeProject: project ?? null,
            currentBuilderStep: SchemaBuilderStep.PERSONALISE,
            currentConfigStep: SchemaConfigStep.CONFIGURE,
            isEditMode: false,
            isDrawerOpen: false,
        };
    }, [project]);
};
