import MantineIcon from '@components/common/MantineIcon';
import FilterOperatorSelect from '@components/common/Select/FilterOperatorSelect';
import {
    addFilterRule,
    createFilterRuleFromField,
    createFilterRuleFromFieldForCustomMetric,
    DimensionType,
    FilterType,
    getFieldRef,
    getFilterRuleWithDefaultValue,
    getFilterTypeFromItem,
    getItemId as getFieldId,
    isDimension,
    isField,
    isFilterableField,
    isTableCalculation,
    type Field,
    type FilterableField,
    type FilterRule,
    type Filters,
} from '@lightdash/common';
import { ActionIcon, Box, Group, Text } from '@mantine/core';
import { useRelationContext } from '@providers/RelationProvider';
import { IconX } from '@tabler/icons-react';
import { useCallback, useMemo, type FC } from 'react';
import { FilterInputComponent, getFilterOperatorOptions } from './FilterInputs';
import FilterRuleFormPropertySelect from './FilterRuleFormPropertySelect';
import {
    MenuForDimensionFilterRule,
    MenuForEventFilterRule,
} from './FilterRuleMenuItem';
import { useFiltersContext } from './FiltersProvider';
import { isFieldAudience, isFilterRuleAudience } from './utils';
import { getTimeFrameFieldFromTable } from './utils/getTimeframeFieldFromTable';

type Props = {
    fields: FilterableField[];
    filterRule: FilterRule;
    isEditMode: boolean;
    onChange: (value: FilterRule) => void;
    onDelete: () => void;
    onConvertToGroup?: () => void;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    groupIndex: number;
    customMetricFilter?: boolean;
    withinPortal?: boolean;
    showFieldSource?: boolean;
};

const FilterRuleForm: FC<Props> = ({
    fields,
    filterRule,
    isEditMode,
    onChange,
    onDelete,
    onConvertToGroup,
    filters,
    setFilters,
    groupIndex,
    customMetricFilter = false,
    withinPortal = false,
    showFieldSource = true,
}) => {
    const { popoverProps } = useFiltersContext();

    const { activeRelation } = useRelationContext();

    const activeField = useMemo(() => {
        //Info: To check if the filter rule is an audience filter rule, we need to check if the filter rule value is a string that starts with 'srt_audience_'.
        const filterRuleValue = filterRule.values?.[0];

        if (
            activeRelation &&
            isFilterRuleAudience(filterRule, activeRelation)
        ) {
            return fields.find((field) => {
                return field.table === filterRuleValue;
            });
        }

        return fields.find((field) => {
            return field?.type === DimensionType.EVENT &&
                getFieldId(field) === filterRule.target.fieldId
                ? field.fieldReference === filterRule.values?.[0]
                : getFieldId(field) === filterRule.target.fieldId;
        });
    }, [filterRule, activeRelation, fields]);

    const isActiveFieldAudience = useMemo(() => {
        return (
            activeField &&
            activeRelation &&
            isFieldAudience(activeField, activeRelation)
        );
    }, [activeRelation, activeField]);

    const filterType = useMemo(() => {
        if (isActiveFieldAudience) {
            return FilterType.AUDIENCE;
        }
        return activeField
            ? getFilterTypeFromItem(activeField)
            : FilterType.STRING;
    }, [activeField, isActiveFieldAudience]);

    const filterOperatorOptions = useMemo(() => {
        return getFilterOperatorOptions(filterType);
    }, [filterType]);

    const onFieldChange = useCallback(
        (field: FilterableField) => {
            if (isDimension(field) && field?.type === DimensionType.EVENT) {
                const value = field?.fieldReference;
                onChange(createFilterRuleFromField(field, value));
                return;
            }
            const fieldId = getFieldId(field);
            const fieldRef = getFieldRef(field as Field);
            const selectedField = fields.find(
                (fieldValue) => getFieldId(fieldValue) === fieldId,
            );

            if (selectedField && activeField) {
                if (selectedField.type === activeField.type) {
                    if (customMetricFilter) {
                        onChange({
                            ...filterRule,
                            target: {
                                fieldId,
                                fieldRef,
                            },
                        });
                    } else {
                        onChange({
                            ...filterRule,
                            target: {
                                fieldId,
                            },
                        });
                    }
                } else {
                    if (customMetricFilter) {
                        onChange(
                            createFilterRuleFromFieldForCustomMetric(
                                selectedField,
                            ),
                        );
                    } else {
                        onChange(createFilterRuleFromField(selectedField));
                    }
                }
            }
        },
        [activeField, fields, filterRule, onChange, customMetricFilter],
    );

    const addTimeWindow = useCallback(() => {
        const field = getTimeFrameFieldFromTable({
            fields,
            tableId:
                activeField && isTableCalculation(activeField)
                    ? ''
                    : activeField?.table,
            activeRelation,
        });
        if (!field) return;

        if (isField(field) && isFilterableField(field)) {
            setFilters(
                addFilterRule({
                    filters,
                    field,
                    ...(Boolean(field.fieldReference) && {
                        value: field.fieldReference,
                    }),
                }),
                false,
                groupIndex,
            );
        }
    }, [activeField, activeRelation, fields, filters, groupIndex, setFilters]);

    const duplicateFilter = useCallback(() => {
        if (activeField) {
            setFilters(
                addFilterRule({
                    filters,
                    field: activeField,
                    ...(filterRule.values?.length && {
                        value: filterRule.values,
                    }),
                }),
                false,
                groupIndex,
            );
        }
    }, [activeField, filterRule.values, filters, groupIndex, setFilters]);

    const renderSelectComponent = useMemo(() => {
        if (!activeField) return null;
        return (
            <FilterOperatorSelect
                isDisabled={!isEditMode}
                filterOperatorOptions={filterOperatorOptions}
                selectedOperator={filterRule.operator}
                onChange={(value) => {
                    if (!value) return;
                    onChange(
                        getFilterRuleWithDefaultValue(
                            activeField,
                            {
                                ...filterRule,
                                operator: value as FilterRule['operator'],
                            },
                            (filterRule.values?.length || 0) > 0
                                ? filterRule.values
                                : [1],
                        ),
                    );
                }}
            />
        );
    }, [activeField, filterOperatorOptions, filterRule, isEditMode, onChange]);

    const renderFieldSelect = useMemo(() => {
        if (!activeField) return null;
        return (
            <FilterRuleFormPropertySelect
                fields={fields}
                onSubmit={(items) => {
                    onFieldChange(items[0]);
                }}
                isEditMode={isEditMode}
                activeField={activeField}
                withinPortal={withinPortal}
                showFieldSource={showFieldSource}
                relation={activeRelation}
            />
        );
    }, [
        activeField,
        fields,
        isEditMode,
        onFieldChange,
        showFieldSource,
        withinPortal,
        activeRelation,
    ]);

    const renderFilterRuleOptions = useMemo(() => {
        if (!isEditMode || !activeField) return null;

        if (!onConvertToGroup)
            return (
                <ActionIcon onClick={onDelete}>
                    <MantineIcon icon={IconX} size="lg" />
                </ActionIcon>
            );

        if (activeField.type === DimensionType.EVENT) {
            return (
                <MenuForEventFilterRule
                    onDelete={onDelete}
                    addTimeWindow={addTimeWindow}
                    duplicateFilter={duplicateFilter}
                    filters={filters}
                    setFilters={setFilters}
                    groupIndex={groupIndex}
                />
            );
        }

        return (
            <MenuForDimensionFilterRule
                onDelete={onDelete}
                duplicateFilter={duplicateFilter}
            />
        );
    }, [
        isEditMode,
        activeField,
        onConvertToGroup,
        onDelete,
        addTimeWindow,
        duplicateFilter,
        filters,
        groupIndex,
        setFilters,
    ]);
    return (
        <Group className="flex flex-row items-center gap-1.5 w-full flex-nowrap">
            {activeField ? (
                <>
                    {activeField.type === DimensionType.EVENT ||
                    isActiveFieldAudience ? (
                        <>
                            <Box>{renderSelectComponent}</Box>
                            <Box>{renderFieldSelect}</Box>
                        </>
                    ) : (
                        <>
                            <Box>{renderFieldSelect}</Box>
                            <Box> {renderSelectComponent}</Box>

                            <Box>
                                <FilterInputComponent
                                    filterType={filterType}
                                    field={activeField}
                                    rule={filterRule}
                                    onChange={onChange}
                                    disabled={!isEditMode}
                                    popoverProps={popoverProps}
                                />
                            </Box>
                        </>
                    )}
                </>
            ) : (
                <Text color="dimmed">
                    Tried to reference field with unknown id:{' '}
                    {filterRule.target.fieldId}
                </Text>
            )}

            {renderFilterRuleOptions}
        </Group>
    );
};

export default FilterRuleForm;
