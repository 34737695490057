import DateRangerPicker from '@components/common/Popovers/DateRangerPicker';
import { Box, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Calendar } from 'react-feather';
import {
    SegmentedTimeFilterOptions,
    SegmentedTimeFilterOptionsValues,
} from './types';
import { determineInitialOption, getTimestampForOption } from './utils';

interface SegmentedTimeFilterProps {
    onOptionChange: ({
        startTimestamp,
        endTimestamp,
    }: {
        startTimestamp: number | undefined;
        endTimestamp: number | undefined;
    }) => void;
    availableOptions?: SegmentedTimeFilterOptions[];
    defaultOption?: SegmentedTimeFilterOptions;
    defaultRange?: [Date, Date];
}

const DEFAULT_AVAILABLE_OPTIONS = [
    SegmentedTimeFilterOptions.CUSTOM,
    SegmentedTimeFilterOptions.TODAY,
    SegmentedTimeFilterOptions.YESTERDAY,
    SegmentedTimeFilterOptions.LAST_7_DAYS,
    SegmentedTimeFilterOptions.ALL_TIME,
];

const SegmentedTimeFilter: React.FC<SegmentedTimeFilterProps> = ({
    availableOptions = DEFAULT_AVAILABLE_OPTIONS,
    onOptionChange,
    defaultOption = SegmentedTimeFilterOptions.ALL_TIME,
    defaultRange,
}) => {
    const initialOption = useMemo(
        () => determineInitialOption(defaultRange, availableOptions),
        [defaultRange, availableOptions],
    );
    const [selectedOption, setSelectedOption] =
        useState<SegmentedTimeFilterOptions>(defaultOption ?? initialOption);
    const [
        isDatePickerOpened,
        { toggle: toggleDatePicker, close: closeDatePicker },
    ] = useDisclosure(false);

    const handleOptionChange = useCallback(
        (option: SegmentedTimeFilterOptions) => {
            setSelectedOption(option);
            if (option === SegmentedTimeFilterOptions.CUSTOM) return;
            const timestampRange = getTimestampForOption(option);
            onOptionChange({
                startTimestamp: timestampRange?.[0],
                endTimestamp: timestampRange?.[1],
            });
        },
        [onOptionChange],
    );

    const handleDatePickerRangeChange = useCallback(
        (values: [Date, Date]) => {
            const [startTimestamp, endTimestamp] = values.map((date) =>
                date.getTime(),
            );
            onOptionChange({ startTimestamp, endTimestamp });
            closeDatePicker();
            setSelectedOption(SegmentedTimeFilterOptions.CUSTOM);
        },
        [onOptionChange, closeDatePicker],
    );

    return (
        <Group className="gap-0 bg-white divide-x shadow-card w-fit border-base">
            {availableOptions.map((option, index) => {
                const isSelected = option === selectedOption;
                const boxClasses = `px-4 py-2.5 cursor-pointer text-sm text-gray-600 font-semibold ${
                    isSelected ? 'bg-shade-6 text-gray-800' : ''
                } ${index === 0 ? 'rounded-l-[inherit]' : ''} ${
                    index === availableOptions.length - 1
                        ? 'rounded-r-[inherit]'
                        : ''
                }`;
                if (option === SegmentedTimeFilterOptions.CUSTOM) {
                    // Render DateRangerPicker as popover target for CUSTOM option
                    if (defaultRange) {
                        return <></>;
                    }
                    return (
                        <DateRangerPicker
                            key={option}
                            popoverTarget={
                                <Group
                                    className={`${boxClasses} gap-1.5`}
                                    onClick={toggleDatePicker}
                                >
                                    <Calendar
                                        size={13}
                                        strokeWidth={2}
                                        color={'rgb(var(--color-gray-600))'}
                                    />
                                    {SegmentedTimeFilterOptionsValues[option]}
                                </Group>
                            }
                            popoverProps={{
                                opened: isDatePickerOpened,
                                onClose: closeDatePicker,
                                children: null,
                            }}
                            datePickerProps={{
                                onChange: (values) => {
                                    if (!_.isArray(values)) {
                                        return;
                                    }

                                    if (
                                        _.some(
                                            values,
                                            (value) =>
                                                _.isNull(value) ||
                                                _.isUndefined(value),
                                        )
                                    )
                                        return;

                                    const convertedValues: [Date, Date] = [
                                        values[0],
                                        values[1],
                                    ];

                                    handleDatePickerRangeChange(
                                        convertedValues,
                                    );
                                },
                            }}
                        />
                    );
                } else {
                    // Render other options normally
                    return (
                        <Box
                            key={option}
                            className={boxClasses}
                            onClick={() => handleOptionChange(option)}
                        >
                            {SegmentedTimeFilterOptionsValues[option]}
                        </Box>
                    );
                }
            })}
        </Group>
    );
};

export default React.memo(SegmentedTimeFilter);
