import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import { useGetCustomMetrics } from '@hooks/useCustomMetric';
import { useCanCreateCustomMetric } from '@hooks/useGetRelatedTables';
import { useLocale } from '@hooks/useLocale';
import { Button, Flex, Text } from '@mantine/core';
import { useApp } from '@providers/AppProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { AlertCircle, Plus } from 'react-feather';
import { useParams } from 'react-router-dom';
import { ButtonVariant } from '../../mantineTheme';
import CreateCustomMetric from './CreateCustomMetric';
import CustomMetricTable from './Table/CustomMetricTable';

const CustomMetricManagerContent = ({
    openBaseTable,
    setOpenBaseTable,
}: {
    openBaseTable: boolean;
    setOpenBaseTable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const ability = useAbilityContext();
    const { t } = useLocale();
    const { user } = useApp();

    const canCreateCustomMetricAbility = ability.can(
        'create',
        subject('CustomMetric', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const { activeRelation } = useRelationContext();
    const { data: customMetrics } = useGetCustomMetrics();
    const canCreateCustomMetric = useCanCreateCustomMetric(activeRelation);
    if (!canCreateCustomMetric)
        return (
            <Flex
                justify="start"
                gap="xs"
                className="p-2 m-2 rounded-lg border border-shade-6 mx-3"
            >
                <Flex justify="center" align="center">
                    <AlertCircle
                        size={13}
                        strokeWidth={2.5}
                        color={'rgb(var(--color-gray-600))'}
                    />
                </Flex>
                <Flex direction="column">
                    <Text className="block text-gray-800">
                        {t('custom_metric.cannot_create_message')}
                    </Text>
                </Flex>
            </Flex>
        );
    return (
        <>
            {canCreateCustomMetricAbility && customMetrics?.length === 0 && (
                <Button
                    className="m-3.5"
                    variant={
                        canCreateCustomMetric
                            ? ButtonVariant.PRIMARY
                            : ButtonVariant.OUTLINED
                    }
                    leftIcon={<Plus size={13} strokeWidth={2.5} />}
                    onClick={() => {
                        setOpenBaseTable(true);
                    }}
                    disabled={!canCreateCustomMetric}
                >
                    <Text className="text-sm font-semibold inline">
                        {t('custom_metric.create_custom_metric')}
                    </Text>
                </Button>
            )}

            <CreateCustomMetric
                openBaseTable={openBaseTable}
                onModalClose={() => {
                    setOpenBaseTable(false);
                }}
                tableName={undefined}
                isDuplicate={undefined}
                isViewMode={undefined}
                customMetricData={undefined}
                customMetricId={undefined}
                disableTableChange={undefined}
            />

            {!!customMetrics && customMetrics?.length > 0 && (
                <CustomMetricTable customMetrics={customMetrics} />
            )}
        </>
    );
};
export default CustomMetricManagerContent;
