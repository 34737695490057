import { type Project } from '@lightdash/common';

import { v4 as uuid } from 'uuid';

export const isCampaignExecutionDone = (executionTime: Date) => {
    const currentUTCTime = new Date().toUTCString();
    return executionTime <= new Date(currentUTCTime);
};

export const getCampaignAnalyticsDashboardUrl = (
    projectData: Project | undefined,
    campaignUuid: string | undefined,
) => {
    if (!projectData || !campaignUuid) return '';
    const dashboardUuid = projectData?.attributes?.analytics?.dashboardId;
    const tempFilters = {
        dimensions: projectData?.attributes?.analytics.filters.map(
            (filter) => ({
                id: uuid(),
                operator: 'equals',
                target: {
                    fieldId: filter.columnName,
                    tableName: filter.tableName,
                },
                tileTargets: [],
                disabled: false,
                values: [campaignUuid],
            }),
        ),
        metrics: [],
        tableCalculations: [],
    };
    const tempFiltersEncoded = encodeURIComponent(JSON.stringify(tempFilters));
    const dashboardUrl = `/projects/${projectData.projectUuid}/campaigns/dashboards/${dashboardUuid}/view?tempFilters=${tempFiltersEncoded}&campaignUuid=${campaignUuid}`;
    return dashboardUrl;
};
