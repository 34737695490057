import { useLocale } from '@hooks/useLocale';
import { WarehouseTypes } from '@lightdash/common';
import { Stack, TextInput } from '@mantine/core';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
const SnowflakeStorageForm: React.FC<{ disabled: boolean }> = ({
    disabled,
}) => {
    const { register, setValue } = useFormContext();
    const { t } = useLocale();
    useEffect(() => {
        setValue('warehouseConfig.warehouseType', WarehouseTypes.SNOWFLAKE);
    }, [setValue]);
    return (
        <Stack>
            <TextInput
                label={t('snowflake_storage_form.stagename_label')}
                required
                {...register('warehouseConfig.stageName', {
                    // validate: {
                    //     hasNoWhiteSpaces: hasNoWhiteSpaces('Host'),
                    // },
                })}
                disabled={disabled}
                labelProps={{ style: { marginTop: '8px' } }}
                description={t('snowflake_storage_form.stagename_description')}
                placeholder={t('snowflake_storage_form.stagename_placeholder')}
            />
        </Stack>
    );
};
export default SnowflakeStorageForm;
