import DataTable from '@components/Table';
import { useLocale } from '@hooks/useLocale';
import { ShowDataType, type EventMapperSchema } from '@lightdash/common';
import { useDebouncedState } from '@mantine/hooks';
import { type Row } from '@tanstack/react-table';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import Fuse from 'fuse.js';
import { useMemo } from 'react';
import { EventManagerType } from '../types';
import { useEventColumns } from './useEventTableUtils';
interface EventsTableProps {
    events: EventMapperSchema[];
    managerType: EventManagerType;
    handleRowClick?: (row: Row<EventMapperSchema>) => void;
}

const EventsTable: React.FC<EventsTableProps> = ({
    events,
    managerType,
    handleRowClick,
}) => {
    const { t } = useLocale();
    const [searchString, setSearchString] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const eventsColumns = useEventColumns(managerType);
    const filterItems = useMemo(() => {
        let eventsData = events;
        if (searchString !== '') {
            const fuse = new Fuse(eventsData, {
                keys: ['eventName', 'label'],
                threshold: 0.3,
            });
            eventsData = fuse.search(searchString).map((res) => res.item);
        }
        return eventsData.filter((event) => !event.isInternal);
    }, [events, searchString]);
    return (
        <div>
            {events && eventsColumns && (
                <DataTable<EventMapperSchema>
                    tableData={filterItems}
                    name={t('events.title')}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: eventsColumns,
                        },
                    ]}
                    searchPlaceholder={t(
                        'events_manager.table_search_placeholder',
                    )}
                    paginationText={t('events.title')}
                    isManualPagination={false}
                    showSearch={
                        managerType === EventManagerType.VIEW ? true : false
                    }
                    tableBackgroundColor={
                        managerType === EventManagerType.VIEW ? 'gray' : 'white'
                    }
                    isEditable={false}
                    handleRowClick={handleRowClick}
                    onSearchChange={setSearchString}
                    // onPageChange={onPageChange}
                    // pagination={campaigns.paginate}

                    // onSearchChange={onSearchChange}
                />
            )}
        </div>
    );
};
export default EventsTable;
