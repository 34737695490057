import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import { useGetAllSubscriptionGroups } from '@hooks/subscriptionGroups/useSubscriptionGroups';
import { useLocale } from '@hooks/useLocale';
import {
    CommunicationChannel as CommunicationChannelEnum,
    ProjectSettings,
    type CommunicationChannel,
} from '@lightdash/common';
import { Button, Container, Flex, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PlusCircle, Subtract } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import SubscriptionDetails from './SubscriptionDetails';

export const DEFAULT_PROVIDER: CommunicationChannel =
    CommunicationChannelEnum.EMAIL;

const SubscriptionComponent: React.FC = () => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const ability = useAbilityContext();
    const { user } = useApp();

    const [activeTab, setActiveTab] =
        useState<CommunicationChannel>(DEFAULT_PROVIDER);

    const { data: allGroups } = useGetAllSubscriptionGroups({
        channel: activeTab,
    });

    const canOnlyViewSubscirption =
        ability.can(
            'view',
            subject(ProjectSettings.subscriptionGroups, { projectUuid }),
        ) &&
        ability.cannot(
            'manage',
            subject(ProjectSettings.subscriptionGroups, {
                organizationUuid: user.data?.organizationUuid,
                projectUuid,
            }),
        );

    const [opened, { open: OpenModal, close: closeModal }] =
        useDisclosure(false);

    return (
        <Container className="mx-0 !p-0">
            <Flex
                direction={'row'}
                justify={'space-between'}
                align="center"
                className="mb-4"
            >
                <Flex align="center" gap="xs">
                    <Subtract weight="duotone" />
                    <Title
                        className="text-lg font-medium text-gray-800 "
                        order={3}
                    >
                        {t('subscription_group')}
                    </Title>
                </Flex>

                {!canOnlyViewSubscirption && allGroups && allGroups.length && (
                    <Flex align="center" gap="xs">
                        {/* <Button
                            variant="default"
                            leftIcon={<Question weight="duotone" />}
                        >
                            {t('subscription_groups.preference_center')}
                        </Button> */}
                        <Button
                            variant="filled"
                            leftIcon={<PlusCircle color="#FFF" />}
                            onClick={() => OpenModal()}
                        >
                            {t('subscription_groups.new_group')}
                        </Button>
                    </Flex>
                )}
            </Flex>
            <SubscriptionDetails
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                onGroupCreate={() => OpenModal()}
                onClose={() => closeModal()}
                isOpen={opened}
            />
        </Container>
    );
};

export default SubscriptionComponent;
