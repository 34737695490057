import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader';
import { LightdashMode, type HealthState } from '@lightdash/common';
import { useEffect, useState } from 'react';

const useMeticulous = (healthState: HealthState | undefined) => {
    const [isMeticulousLoaded, setIsMeticulousLoaded] = useState(false);
    useEffect(() => {
        if (
            !healthState ||
            isMeticulousLoaded ||
            !healthState.meticulous.key ||
            healthState.mode !== LightdashMode.CLOUD_BETA
        )
            return;

        tryLoadAndStartRecorder({
            projectId: healthState.meticulous.key,
            isProduction: false,
        }).catch(console.error);
        setIsMeticulousLoaded(true);
    }, [healthState, isMeticulousLoaded]);

    return isMeticulousLoaded;
};

export default useMeticulous;
