export const extractDate = (dateTimeString: string): Date => {
    return new Date(dateTimeString.split('T')[0]);
};

export const extractTime = (
    dateTimeString: string,
): { hours: string; minutes: string } => {
    const timePart = dateTimeString.split('T')[1]?.split('.')[0] || '00:00:00';
    const [hours, minutes] = timePart.split(':');
    return { hours, minutes };
};
