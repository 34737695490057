import {
    FieldType,
    type Campaign,
    type CommunicationChannel,
    type CommunicationDetails,
    type Integration,
    type TemplateDetails,
} from '@lightdash/common';
import { Divider, Stack } from '@mantine/core';
import React, { useCallback, useMemo } from 'react';
import CampaignActionChannel from './CampaignActionChannel';
import CampaignActionContent from './CampaignActionContent';

/**
 * @function modifyTemplateDetails
 * @description Modifies the template details by adding a '$' to the value if not already present.
 * @param {TemplateDetails} templateDetails - The template details to be modified.
 * @returns {TemplateDetails} - The modified template details.
 */
const encodeTemplateDetails = (
    templateDetails: TemplateDetails,
): TemplateDetails => {
    const modifiedMappings = Object.entries(templateDetails.mappings).reduce(
        (acc, [key, mapping]) => {
            acc[key] = {
                ...mapping,
                value: mapping.value.startsWith('$')
                    ? mapping.value
                    : `$${mapping.value}`,
            };
            return acc;
        },
        {} as TemplateDetails['mappings'],
    );

    return {
        ...templateDetails,
        mappings: modifiedMappings,
    };
};

/**
 * @function decodeTemplateDetails
 * @description Modifies the template details by removing the '$' from the value if present.
 * @param {TemplateDetails} templateDetails - The template details to be modified.
 * @returns {TemplateDetails} - The modified template details.
 */
const decodeTemplateDetails = (
    templateDetails: TemplateDetails,
): TemplateDetails => {
    const modifiedMappings = Object.entries(templateDetails.mappings).reduce(
        (acc, [key, mapping]) => {
            acc[key] = {
                ...mapping,
                value: mapping.value.startsWith('$')
                    ? mapping.value.slice(1)
                    : mapping.value,
            };
            return acc;
        },
        {} as TemplateDetails['mappings'],
    );

    return {
        ...templateDetails,
        mappings: modifiedMappings,
    };
};

export interface CampaignDetailsChangeProps {
    communicationDetails: Campaign['communicationDetails'];
    templateDetails: Campaign['templateDetails'];
}
export interface SendCampaignProps {
    communicationDetails: Campaign['communicationDetails'];
    templateDetails: Campaign['templateDetails'];
    onCampaignDetailsChange: ({
        communicationDetails,
        templateDetails,
    }: CampaignDetailsChangeProps) => void;
    nodeId: string;
}

const SendCampaign: React.FC<SendCampaignProps> = ({
    communicationDetails,
    templateDetails,
    onCampaignDetailsChange,
    nodeId,
}) => {
    const setJourneyChannelPayload = (channel: Integration | null) => {
        if (!channel) return;
        onCampaignDetailsChange({
            communicationDetails: {
                eventId: '',
                id: channel?.integrationId || '',
                providerId: channel?.providerId || '',
                channel: channel?.channelName as CommunicationChannel,
            },
            templateDetails,
        });
    };
    const setJourneyContentPayload = useCallback(
        (
            content: Partial<TemplateDetails> | null,
            comm: Campaign['communicationDetails'] | undefined,
        ) => {
            if (!content) {
                onCampaignDetailsChange({
                    communicationDetails,
                    templateDetails,
                });
                return;
            }

            let modifiedCommunicationDetails:
                | Campaign['communicationDetails']
                | undefined = comm;

            let modifiedTemplateDetails:
                | Campaign['templateDetails']
                | undefined = { ...templateDetails, ...content };

            if (comm) {
                const channel = comm.channel;
                if (channel) {
                    const sendTo = comm.sendTo?.[channel];
                    if (sendTo) {
                        // Info: Backend expects the variable to be prefixed with $
                        const modifiedSendTo = sendTo.startsWith('$')
                            ? sendTo
                            : `$${sendTo}`;
                        modifiedCommunicationDetails = {
                            ...comm,
                            sendTo: {
                                ...comm.sendTo,
                                [channel]: modifiedSendTo,
                                type: comm.sendTo?.type ?? FieldType.DIMENSION,
                            },
                        };
                    }
                }
            }

            if (content) {
                modifiedTemplateDetails = encodeTemplateDetails(
                    modifiedTemplateDetails,
                );
            }

            onCampaignDetailsChange({
                communicationDetails:
                    modifiedCommunicationDetails ?? communicationDetails,
                templateDetails: modifiedTemplateDetails,
            });
        },
        [communicationDetails, onCampaignDetailsChange, templateDetails],
    );

    const modifiedCommunicationDetails: CommunicationDetails = useMemo(() => {
        const channel = communicationDetails.channel;
        if (!channel) return communicationDetails;
        const sendTo = communicationDetails.sendTo?.[channel];
        if (!sendTo) return communicationDetails;
        //Info: Remove $ if it starts with $, this is to ensure backward compatibility with the Campaign Builder
        const modifiedSendTo = sendTo.startsWith('$')
            ? sendTo.slice(1)
            : sendTo;

        return {
            ...communicationDetails,
            sendTo: {
                ...communicationDetails.sendTo,
                [channel]: modifiedSendTo,
                type: communicationDetails.sendTo?.type ?? FieldType.DIMENSION,
            },
        };
    }, [communicationDetails]);

    const modifiedTemplateDetails = useMemo(() => {
        return decodeTemplateDetails(templateDetails);
    }, [templateDetails]);

    return (
        <Stack className="w-[30vw]">
            <CampaignActionChannel
                communicationDetails={modifiedCommunicationDetails}
                setJourneyChannelPayload={setJourneyChannelPayload}
            />
            <Divider className="border-t-gray-200" />
            <CampaignActionContent
                communicationDetails={modifiedCommunicationDetails}
                templateDetails={modifiedTemplateDetails}
                setJourneyContentPayload={setJourneyContentPayload}
                nodeId={nodeId}
            />
        </Stack>
    );
};

export default React.memo(SendCampaign);
