import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import CampaignBuilderFooter from '@components/Campaigns/Builder/CampaignBuilderFooter';
import SetupContent from '@components/Campaigns/Builder/Steps/CampaignBuilderContent/SetupContent';
import TemplateContentPreview from '@components/Campaigns/Builder/Steps/CampaignBuilderContent/SetupContent/TemplateContentPreview';
import { type TemplateSetupCompleteArgs } from '@components/Campaigns/Builder/types';
import Modal from '@components/common/modal/Modal';
import { useGetTemplateById } from '@hooks/useTemplate';
import { type Campaign } from '@lightdash/common';
import { Box, Group, Stack } from '@mantine/core';
import { type TemplateDetails } from '@providers/CampaignProvider';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';

interface JourneyTemplateSetupModalProps {
    opened: boolean;
    closeTemplateSetup: () => void;
    templateDetails: Campaign['templateDetails'] | undefined;
    setJourneyContentPayload: (
        content: Partial<TemplateDetails> | null,
        communicationDetails: Campaign['communicationDetails'],
    ) => void;
    fields: FieldsWithSuggestions;
}

const JourneyTemplateSetupModal: React.FC<JourneyTemplateSetupModalProps> = ({
    opened,
    closeTemplateSetup,
    templateDetails,
    setJourneyContentPayload,
    fields,
}) => {
    const { data: templateMetadata } = useGetTemplateById(
        templateDetails?.id ?? '',
    );

    const onTemplateSetupComplete = useCallback(
        (args: TemplateSetupCompleteArgs) => {
            const { template, communicationDetails } = args;
            if (template && !isEmpty(template)) {
                setJourneyContentPayload(template, communicationDetails);
            }
            closeTemplateSetup();
        },
        [closeTemplateSetup, setJourneyContentPayload],
    );

    return (
        <Modal
            opened={opened}
            onClose={closeTemplateSetup}
            title={'Setup your email content'}
            styles={() => ({
                content: {
                    maxHeight: '100% !important',
                    overflow: 'hidden',
                },
                body: {
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                },
            })}
            size="calc(100vw - 3rem)"
            footerRightSection={<CampaignBuilderFooter />}
        >
            {templateMetadata && (
                <>
                    <Stack style={{ flex: 1, overflow: 'hidden' }}>
                        <Group
                            className="w-full h-full p-3 items-start gap-3.5"
                            noWrap
                            style={{ flex: 1, overflowY: 'auto' }}
                        >
                            <Box className="w-1/2">
                                <SetupContent
                                    templateMetadata={templateMetadata}
                                    onTemplateSetupComplete={
                                        onTemplateSetupComplete
                                    }
                                    fields={fields}
                                />
                            </Box>
                            {templateDetails?.id && templateMetadata && (
                                <Box className="w-full">
                                    <TemplateContentPreview
                                        templateMetadata={templateMetadata}
                                    />
                                </Box>
                            )}
                        </Group>
                    </Stack>
                </>
            )}
        </Modal>
    );
};

export default React.memo(JourneyTemplateSetupModal);
