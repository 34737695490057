import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import {
    FieldType,
    isCustomDimension,
    isTableCalculation,
    RelationTableType,
    type CompiledRelation,
    type CompiledRelationTable,
} from '@lightdash/common';
import { isEmpty } from 'lodash';

export const sortRelationFields = (
    fields: FieldWithSuggestions[],
): FieldWithSuggestions[] => {
    return fields.sort((itemA, itemB) => {
        if (
            isTableCalculation(itemA) ||
            isTableCalculation(itemB) ||
            isCustomDimension(itemA) ||
            isCustomDimension(itemB)
        ) {
            return 0;
        }
        // First, sort by tableType
        if (
            itemA.tableType === RelationTableType.PRIMARY &&
            itemB.tableType !== RelationTableType.PRIMARY
        ) {
            return -1;
        }
        if (
            itemA.tableType !== RelationTableType.PRIMARY &&
            itemB.tableType === RelationTableType.PRIMARY
        ) {
            return 1;
        }

        // If both have the same tableType, sort by table
        if (itemA.tableType === itemB.tableType) {
            const tableSortOrderA = itemA.table.toLowerCase();
            const tableSortOrderB = itemB.table.toLowerCase();

            if (tableSortOrderA !== tableSortOrderB) {
                return tableSortOrderA.localeCompare(tableSortOrderB);
            }

            // If both have the same tableType and table, sort by fieldType
            if (
                itemA.fieldType === FieldType.DIMENSION &&
                itemB.fieldType !== FieldType.DIMENSION
            ) {
                return -1;
            }
            if (
                itemA.fieldType !== FieldType.DIMENSION &&
                itemB.fieldType === FieldType.DIMENSION
            ) {
                return 1;
            }

            // If both have the same tableType and table, sort by label
            const labelSortOrderA = itemA.label.toLowerCase();
            const labelSortOrderB = itemB.label.toLowerCase();
            return labelSortOrderA.localeCompare(labelSortOrderB);
        }

        // Sort by alphabetical order of tableType
        const typeSortOrderA = itemA?.tableType?.toLowerCase() ?? '';
        const typeSortOrderB = itemB?.tableType?.toLowerCase() ?? '';
        return typeSortOrderA.localeCompare(typeSortOrderB);
    });
};

/**
 * Gets all tables that match the specified table type.
 *
 * @param {Object} param - The parameters for the function.
 * @param {CompiledRelation['tables']} param.tables - The collection of tables to search through.
 * @param {RelationTableType} param.tableType - The type of table to match against.
 * @returns {T[]} - An array of tables that match the specified table type. If no tables match, an empty array is returned.
 */
interface GetTablesByTableTypeParam {
    tables: CompiledRelation['tables'];
    tableType: RelationTableType;
}

export const getTablesByTableType = <T extends CompiledRelationTable>({
    tables,
    tableType,
}: GetTablesByTableTypeParam): T[] => {
    if (!tables || !tableType) return [];
    return Object.values(tables).filter(
        (table) => table.type === tableType,
    ) as T[];
};

export const filterTablesFromRelation = (
    activeRelation: CompiledRelation,
    tableIds: string[],
): CompiledRelation | undefined => {
    if (!activeRelation || !tableIds || isEmpty(tableIds)) return;
    const filteredTables = Object.fromEntries(
        Object.entries(activeRelation.tables).filter(([key]) =>
            tableIds.includes(key),
        ),
    );
    const filteredJoinedTables = activeRelation.joinedTables.filter(
        (joinedTable) => tableIds.includes(joinedTable.table),
    );

    return {
        ...activeRelation,
        tables: filteredTables,
        joinedTables: filteredJoinedTables,
    };
};
