import CampaignPreviewContainer from '@components/Campaigns/Preview/CampaignPreviewContainer';
import NavBar from '@components/NavBar';
import Page from '@components/Page/Page';
import PageBreadcrumbs from '@components/PageBreadcrumbs';
import { useLocale } from '@hooks/useLocale';
import { type Campaign } from '@lightdash/common';
import { Box } from '@mantine/core';
import { useCampaignContext } from '@providers/CampaignProvider';
import React from 'react';
import { useParams } from 'react-router-dom';

interface PreviewCampaignProps {
    campaignData: Campaign;
}

const PreviewCampaign: React.FC<PreviewCampaignProps> = ({ campaignData }) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{
        projectUuid: string;
        audienceUuid: string;
        mode?: string;
    }>();
    const { campaignPayload } = useCampaignContext((context) => context.state);

    return (
        <Page
            title={campaignPayload.name}
            withFullHeight
            withPaddedContent
            withNavbar
            backgroundColor="gray"
            withFixedContent
        >
            <NavBar />
            <Box component="div" className="mb-3.5">
                <PageBreadcrumbs
                    items={[
                        {
                            title: t('campaign.title'),
                            to: `/projects/${projectUuid}/campaigns`,
                        },
                        {
                            title: t('campaign_analytics.view_campaign_cta'),
                            active: true,
                        },
                    ]}
                />
            </Box>

            <CampaignPreviewContainer campaignData={campaignData} />
        </Page>
    );
};

export default PreviewCampaign;
