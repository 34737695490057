import ChannelTabs from '@components/ChannelTabs';
import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';
import Page from '@components/Page/Page';
import TemplateTable from '@components/TemplateManager/Table/TemplateTable';
import TemplateBuilderManager from '@components/Templates/Builder/TemplateBuilderManager';
import TemplateOptionsModal from '@components/Templates/TemplateOptionsModal';
import { useGetChannelIntegrations } from '@hooks/useChannels';
import { useTemplates } from '@hooks/useGetTemplate';
import { useLocale } from '@hooks/useLocale';
import { CommunicationChannel } from '@lightdash/common';
import { Button, Tabs, Text } from '@mantine/core';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import { PlusCircle } from '@phosphor-icons/react';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonVariant } from '../mantineTheme';

export const DEFAULT_PROVIDER = CommunicationChannel.EMAIL;

const TemplateBuilderContainer: React.FC<{}> = ({}) => {
    // TODO - LOOK FOR AN ALTERNATE SOLUTION
    const [editMode, setEditMode] = useState(false);
    const [searchValue, setSearchValue] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const [activePill, setActivePill] =
        useState<CommunicationChannel>(DEFAULT_PROVIDER);
    const handlePillChange = (pill: CommunicationChannel) => {
        setActivePill(pill);
    };
    const { t } = useLocale();

    let { data: channelIntegration } = useGetChannelIntegrations(activePill);
    const [currentPage, setCurrentPage] = useState(1);

    const { data: templates, isFetching } = useTemplates(
        activePill,
        8,
        currentPage,
        searchValue,
    );
    const [opened, { toggle, close }] = useDisclosure(false);
    const [selectedTemplate, setSelectedTemplate] = useState<
        string | undefined
    >(undefined);
    const previousActivePill = useRef(activePill);
    const fetchData = useCallback(async () => {
        if (activePill !== previousActivePill.current) {
            previousActivePill.current = activePill;
            setCurrentPage(1);
        }
    }, [activePill]);
    useEffect(() => {
        void fetchData();
    }, [fetchData, currentPage, activePill]);

    const handleTemplateSelect = (id: string) => {
        if (activePill === CommunicationChannel.WHATSAPP) return;
        setSelectedTemplate(id);
        setEditMode(true);
        toggle();
    };

    const handleSearchInput = (value: string) => {
        setSearchValue(value);
        setCurrentPage(1);
    };

    return (
        <ManagerBuilderContainer
            withContentPadding={false}
            title={t('templates')}
            rightSection={
                <Button
                    variant={ButtonVariant.PRIMARY}
                    leftIcon={<PlusCircle color="white" />}
                    onClick={() => {
                        setSelectedTemplate(undefined);
                        toggle();
                    }}
                >
                    <Text className="text-sm font-semibold">
                        {t('template.create.title')}
                    </Text>
                </Button>
            }
        >
            <Tabs
                keepMounted={false}
                variant="pills"
                radius="lg"
                defaultValue={activePill}
                onTabChange={handlePillChange}
                className="pl-2 bg-gray-100/60"
            >
                <ChannelTabs activeTab={activePill} />
            </Tabs>

            {templates?.data ? (
                <TemplateTable
                    onPageChange={async (newPage) => {
                        setCurrentPage(newPage);
                    }}
                    pagination={templates.pagination}
                    templates={templates?.data}
                    isDataFetching={isFetching}
                    handleRowPress={handleTemplateSelect}
                    handleSearchInput={handleSearchInput}
                    searchValue={searchValue}
                    activeTab={activePill}
                />
            ) : (
                channelIntegration?.length && (
                    <TemplateBuilderManager
                        integration={channelIntegration[0]}
                        isSingleSelect={true}
                        handleTemplateSelect={() => {}}
                        integrationId={''}
                        channel={activePill}
                        onClose={() => {
                            close();
                            setEditMode(false);
                        }}
                        editMode={editMode}
                    />
                )
            )}

            <TemplateOptionsModal
                opened={opened}
                onClose={() => {
                    close();
                    setEditMode(false);
                }}
                providerMetadata={{} as any}
                integrationId={''}
                handleTemplateSelect={() => {}}
                channel={activePill}
                showCreateEditTemplate={true}
                templateId={selectedTemplate}
                isTemplateLibrary={true}
                editMode={editMode}
            />
        </ManagerBuilderContainer>
    );
};

const Templates = () => {
    const { t } = useLocale();

    return (
        <Page
            title={t('templates')}
            withFullHeight
            withNavbar
            withFixedContent
            withPaddedContent
        >
            <TemplateBuilderContainer />
        </Page>
    );
};

export default Templates;
