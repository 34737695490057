import { Box, Button, Card, Group, Text } from '@mantine/core';
import React from 'react';

import { ButtonVariant } from '../../../../mantineTheme';
import ButtonGroup, { type ButtonGroupProps } from '../ButtonGroup';

interface CardHeaderProps extends ButtonGroupProps {
    title: string;
    subtitle: string;
    isActive: boolean;
    isEditAllowed: boolean | undefined;
    handleEditClick: () => void;
}

const CardHeader: React.FC<CardHeaderProps> = ({
    title,
    subtitle,
    isActive,
    handlePrimaryButtonClick,
    handleSecondaryButtonClick,
    isUpdating,
    isEditAllowed,
    handleEditClick,
}) => {
    return (
        <Card.Section
            className={`${
                isActive ? 'bg-blu-100/60' : 'bg-gray-50'
            } p-3 m-0 !mt-0 rounded-[10px]`}
        >
            <Box>
                <Group position="apart" className="h-[30px]">
                    <Box>
                        <Text className="text-base font-semibold text-gray-800">
                            {title}
                        </Text>
                    </Box>
                    {isActive && (
                        <ButtonGroup
                            handleSecondaryButtonClick={
                                handleSecondaryButtonClick
                            }
                            handlePrimaryButtonClick={handlePrimaryButtonClick}
                            isUpdating={isUpdating}
                            secondaryButtonLabel={undefined}
                            primaryButtonLabel={undefined}
                        />
                    )}
                    {isEditAllowed && !isActive && (
                        <Button
                            onClick={handleEditClick}
                            variant={ButtonVariant.OUTLINED}
                        >
                            Edit
                        </Button>
                    )}
                </Group>
                <Text className="pt-1 text-gray-600">{subtitle}</Text>
            </Box>
        </Card.Section>
    );
};

export default CardHeader;
