export enum SegmentedTimeFilterOptions {
    CUSTOM = 'CUSTOM',
    TODAY = 'TODAY',
    YESTERDAY = 'YESTERDAY',
    LAST_7_DAYS = 'LAST_7_DAYS',
    LAST_30_DAYS = 'LAST_30_DAYS',
    THIS_MONTH = 'THIS_MONTH',
    LAST_MONTH = 'LAST_MONTH',
    ALL_TIME = 'ALL_TIME',
}

export const SegmentedTimeFilterOptionsValues: Record<
    SegmentedTimeFilterOptions,
    string
> = {
    [SegmentedTimeFilterOptions.CUSTOM]: 'Custom',
    [SegmentedTimeFilterOptions.TODAY]: 'Today',
    [SegmentedTimeFilterOptions.YESTERDAY]: 'Yesterday',
    [SegmentedTimeFilterOptions.LAST_7_DAYS]: '7D',
    [SegmentedTimeFilterOptions.LAST_30_DAYS]: '30D',
    [SegmentedTimeFilterOptions.THIS_MONTH]: 'This month',
    [SegmentedTimeFilterOptions.LAST_MONTH]: 'Last month',
    [SegmentedTimeFilterOptions.ALL_TIME]: 'Default · All time',
};
