import Modal from '@components/common/modal/Modal';
import {
    useCreateSubscriptionCategory,
    useGetAllSubscriptionCategories,
    useGetSubscriptionGroupById,
    useUpdateSubscriptionGroup,
} from '@hooks/subscriptionGroups/useSubscriptionGroups';
import { useLocale } from '@hooks/useLocale';
import { type CommunicationChannel } from '@lightdash/common';
import { Box, Flex, Group, Radio, Text, TextInput } from '@mantine/core';
import { Subtract } from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { QueryKeys } from 'types/UseQuery';
import { getStyles } from '../../utils';
import ModalFooter from './ModalFooter';

interface GroupCategoryUpdateModalProps {
    showGroupCategory: boolean;
    onClose: () => void;
    groupId: string;
    activeTab: CommunicationChannel;
}
const GroupCategoryUpdateModal: React.FC<GroupCategoryUpdateModalProps> = ({
    showGroupCategory,
    onClose,
    groupId,
    activeTab,
}) => {
    const { t } = useLocale();
    const queryClient = useQueryClient();
    const { data: groupData, isLoading: isGroupLoading } =
        useGetSubscriptionGroupById({
            groupId: groupId,
        });

    const { data: subscriptionCategories } = useGetAllSubscriptionCategories();

    const { mutateAsync: updateGroup } = useUpdateSubscriptionGroup();

    const { mutateAsync: mutateAsyncCategory, data: categoryData } =
        useCreateSubscriptionCategory();

    const selectData = useMemo(() => {
        if (subscriptionCategories) {
            return subscriptionCategories
                .filter((item) => item.channel === activeTab)
                .map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
        }
        return [];
    }, [subscriptionCategories, activeTab]);

    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [isNewCategory, setIsNewCategory] = useState<boolean>(false);
    const [newCategoryName, setNewCategoryName] = useState<string>('');

    useEffect(() => {
        if (groupData && groupData.categoryId) {
            setSelectedCategory(groupData.categoryId);
        }
    }, [groupData]);

    const handleCategoryChange = (value: string) => {
        if (value === 'new') {
            setIsNewCategory(true);
            setSelectedCategory(value);
        } else {
            setIsNewCategory(false);
            setSelectedCategory(value);
        }
    };

    useEffect(() => {
        if (categoryData && groupData) {
            void updateGroup({
                groupId: groupId,
                payload: {
                    name: groupData.name,
                    description: groupData.description,
                    categoryId: categoryData.id,
                    type: groupData.type,
                },
            }).then(() =>
                queryClient.invalidateQueries([
                    QueryKeys.GET_SUBSCRIPTION_GROUPS,
                ]),
            );
            onClose();
        }
    }, [onClose, categoryData, updateGroup, groupData, groupId, queryClient]);

    const handleSaveClick = useCallback(async () => {
        if (isNewCategory) {
            await mutateAsyncCategory({
                payload: {
                    name: newCategoryName.trim(),
                    channel: activeTab,
                    description: '',
                },
            });
            return;
        }
        if (groupData) {
            await updateGroup({
                groupId: groupId,
                payload: {
                    name: groupData.name,
                    description: groupData.description,
                    categoryId: selectedCategory,
                    type: groupData.type,
                },
            });
            await queryClient.invalidateQueries([
                QueryKeys.GET_SUBSCRIPTION_GROUPS,
            ]);
        }
        onClose();
    }, [
        activeTab,
        groupData,
        groupId,
        isNewCategory,
        mutateAsyncCategory,
        newCategoryName,
        onClose,
        queryClient,
        selectedCategory,
        updateGroup,
    ]);

    const ModalTitle = useMemo(() => {
        return (
            !isGroupLoading && (
                <Group className="flex flex-col items-start gap-0">
                    <Text className="text-gray-800 text-base font-medium pb-0 mb-0">
                        {groupData && groupData.categoryId
                            ? t('group_category_update_modal.change_category')
                            : t('group_category_update_modal.add_to_category')}
                    </Text>
                    <Flex align={'center'} className="mt-1 space-x-1">
                        <Subtract width={12.25} />
                        <Text className="text-gray-800 text-sm font-normal">
                            {groupData && groupData.name}{' '}
                        </Text>
                        <Text className="text-gray-600 text-sm">
                            {'· ID: '}
                            {groupData && groupData.id}
                        </Text>
                    </Flex>
                </Group>
            )
        );
    }, [isGroupLoading, groupData, t]);

    return (
        <Modal
            opened={showGroupCategory}
            onClose={onClose}
            size="xl"
            title={ModalTitle}
            styles={getStyles()}
            footerRightSection={
                <ModalFooter
                    primaryText={t(
                        'channel_settings_add_channel_modal.secondary_button',
                    )}
                    secondaryText={t(
                        'organization_settings.admin_change_role_confirm',
                    )}
                    primaryButtonClick={onClose}
                    secondaryButtonClick={handleSaveClick}
                />
            }
        >
            <Box>
                <Text className="text-gray-800 text-sm font-medium mb-3.5">
                    {t('group_category_update_modal.choose_a_category')}
                </Text>
                <Radio.Group
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    className="text-gray-800"
                >
                    {selectData.map((item) => (
                        <Radio
                            className="mb-1.5="
                            key={item.value}
                            value={item.value}
                            label={item.label}
                            styles={() => ({
                                inner: {
                                    marginTop: '3px !important',
                                },
                            })}
                        />
                    ))}
                    <Radio
                        value="new"
                        label={t(
                            'group_category_update_modal.create_a_new_category',
                        )}
                    />
                </Radio.Group>
                {isNewCategory && (
                    <TextInput
                        required
                        className="mt-1 mx-4 w-1/2"
                        value={newCategoryName}
                        onChange={(e) =>
                            setNewCategoryName(e.currentTarget.value)
                        }
                        placeholder={t(
                            'group_category_update_modal.enter_new_category_name',
                        )}
                    />
                )}
            </Box>
        </Modal>
    );
};

export default GroupCategoryUpdateModal;
