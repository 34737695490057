import RouterSidebarLink from '@components/Router/RouterSidebarLink';
import { useLocale } from '@hooks/useLocale';
import { Box, Divider, Stack, Title } from '@mantine/core';
import React from 'react';
import {
    ProjectSettingsMenuGroups,
    type SettingListItemType,
} from './ProjectSettingsMenuItems';

const SettingsGroup = ({
    settingsGroup,
    groupIndex,
    projectUuid,
}: {
    settingsGroup: SettingListItemType[];
    groupIndex: number;
    projectUuid: string;
}) => (
    <>
        <Stack key={groupIndex} className="gap-1.5 py-3.5 mx-1">
            {settingsGroup.map((item, itemIndex) => (
                <RouterSidebarLink
                    key={`item-${item.label || itemIndex}`}
                    label={item.label}
                    exact
                    to={item.redirectTo}
                    icon={item.leftSection}
                    disabled={item.disable}
                />
            ))}
        </Stack>
        {groupIndex < ProjectSettingsMenuGroups(projectUuid).length - 1 && (
            <Divider className="ml-2.5 mr-5 border-t-shade-4" />
        )}
    </>
);

interface ProjectSettingsSidebarProps {
    projectUuid: string;
}

const ProjectSettingsSidebar: React.FC<ProjectSettingsSidebarProps> = ({
    projectUuid,
}) => {
    const { t } = useLocale();

    const renderedSettingsGroups = ProjectSettingsMenuGroups(projectUuid).map(
        (group, index) => (
            <React.Fragment key={`settings-group-${index}`}>
                <SettingsGroup
                    settingsGroup={group}
                    groupIndex={index}
                    projectUuid={projectUuid}
                />
            </React.Fragment>
        ),
    );

    return (
        <Stack sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <Stack sx={{ flexGrow: 1, overflow: 'auto' }} spacing={'none'}>
                <Box>
                    <Title className="px-2.5 text-xs font-semibold text-gray-500">
                        {t('project_settings.sidebar_title')}
                    </Title>
                </Box>
                <Box>{renderedSettingsGroups}</Box>
            </Stack>
        </Stack>
    );
};

export default React.memo(ProjectSettingsSidebar);
