import RelationsReactFlow from '@components/SchemaBuilder/RelationsReactFlow';
import { useSchemaInitialData } from '@components/SchemaBuilder/useSchemaInitialData';
import { useProjectContext } from '@providers/ProjectProvider';
import { SchemaProvider } from '@providers/SchemaProvider';
import { useHistory } from 'react-router-dom';

const Relations = () => {
    const history = useHistory();
    const { projectData: activeProject } = useProjectContext();
    const initialSchemaState = useSchemaInitialData(activeProject);

    if (activeProject?.needsRelation) {
        history.push(
            `/projects/${activeProject?.projectUuid}/relations/create`,
        );
        return null;
    }

    return (
        <SchemaProvider initialState={initialSchemaState}>
            <RelationsReactFlow />
        </SchemaProvider>
    );
};

export default Relations;
