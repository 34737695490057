import ButtonInput from '@components/common/Inputs/ButtonInput';
import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import { useLocale } from '@hooks/useLocale';
import { Group } from '@mantine/core';
import { Book, CaretDown, CopySimple, FilePlus } from '@phosphor-icons/react';
import { useMemo } from 'react';
import { FontSizes, FontWeights } from '../../mantineTheme';

interface ITemplateActionsProps {
    title: string;
    placeholder: string;
    onTitleChange: (title: string) => void;
    onAction: (action: Action) => void;
}

export const enum Action {
    RESET = 'reset',
    LOAD_TEMPLATE = 'load-template',
    RENAME = 'rename',
    DUPLICATE = 'duplicate',
}

export const TemplateActions = ({
    title,
    placeholder,
    onTitleChange,
    onAction,
}: ITemplateActionsProps) => {
    const { t } = useLocale();

    const menuItems = useMemo(() => {
        const items: CustomMenuItemProps<string>[] = [
            {
                leftSection: <FilePlus />,
                children: t('campaigns_builder_editor.menu.create_new'),
                subtitle: t(
                    'campaigns_builder_editor.menu.create_new.subtitle',
                ),
                onClick: () => onAction(Action.RESET),
            },
            {
                leftSection: <Book />,
                children: t('campaigns_builder_editor.menu.load_template'),
                subtitle: t(
                    'campaigns_builder_editor.menu.load_template.subtitle',
                ),
                onClick: () => onAction(Action.LOAD_TEMPLATE),
            },
            // {
            //     leftSection: (
            //         <NotePencil color={'rgb(var(--color-gray-600))'} />
            //     ),
            //     children: t('campaigns_builder_editor.menu.rename'),
            //     onClick: () => onAction(Action.RENAME),
            // },
            {
                leftSection: <CopySimple />,
                children: t('campaigns_builder_editor.menu.duplicate'),
                onClick: () => onAction(Action.DUPLICATE),
            },
        ];

        return items;
    }, [onAction, t]);

    return (
        <Group spacing={0}>
            <ButtonInput
                defaultValue={title}
                fontSize={FontSizes.lg}
                fontWeight={FontWeights.semibold}
                onChange={onTitleChange}
                placeholder={placeholder}
                rightSection={null}
                className="max-w-60"
            />
            <CustomMenu
                icon={<CaretDown weight="bold" />}
                menuItems={menuItems}
                width={300}
            />
        </Group>
    );
};
