import { useLocale } from '@hooks/useLocale';
import { Box, Button, FileButton, Flex, Text } from '@mantine/core';
import {
    CheckCircle,
    FileArrowUp,
    SpinnerGap,
    Warning,
    X,
} from '@phosphor-icons/react';
import { useCallback, useRef, useState } from 'react';
import { ButtonVariant } from '../../../../mantineTheme';

const MultipleEventReview: React.FC = ({}) => {
    const { t } = useLocale();
    const [file, setFile] = useState<File | null>(null);
    const resetRef = useRef<() => void>(null);
    const clearFile = useCallback(() => {
        setFile(null);
        resetRef.current?.();
    }, []);
    return (
        <Flex direction={'column'} gap={12}>
            <Box>
                {file ? (
                    <Flex
                        gap={19}
                        align={'center'}
                        className="text-blu-800 font-semibold text-sm"
                    >
                        <Text>{file.name}</Text>
                        <X
                            weight="bold"
                            onClick={clearFile}
                            className="hover:cursor-pointer"
                            color={'rgb(var(--color-blu-800))'}
                        />
                    </Flex>
                ) : (
                    <FileButton
                        onChange={setFile}
                        accept=".csv"
                        resetRef={resetRef}
                    >
                        {(props) => (
                            <Button
                                variant={ButtonVariant.FILLED}
                                leftIcon={
                                    <FileArrowUp
                                        weight="duotone"
                                        color="white"
                                    />
                                }
                                className="w-[17rem]"
                                {...props}
                            >
                                {t(
                                    'event_create.review_multiple_event_upload_button',
                                )}
                            </Button>
                        )}
                    </FileButton>
                )}
            </Box>
            <CheckCircle weight="bold" color={'rgb(var(--color-green))'} />
            <SpinnerGap weight="bold" color={'rgb(var(--color-blu-800))'} />
            {!file && (
                <Flex gap={2} align="center">
                    <Text>
                        {t(
                            'event_create.review_multiple_event_upload_description',
                        )}
                    </Text>
                    <Button variant={ButtonVariant.SUBTLE_ACCENTED}>
                        {t(
                            'event_create.review_multiple_event_download_sample',
                        )}
                    </Button>
                </Flex>
            )}
            {
                <Flex
                    gap={6}
                    align={'center'}
                    className="border border-orange-800/40 bg-orange-800/2 rounded-lg p-2"
                >
                    <Warning
                        weight="duotone"
                        color={'rgb(var(--color-orange-800))'}
                    />
                    <Text className="text-halt-800">
                        {t('event_create.review_multiple_event_warning_toast')}
                    </Text>
                </Flex>
            }
        </Flex>
    );
};

export default MultipleEventReview;
