import Checkbox from '@components/common/Checkbox';
import { type PropertiesType } from '@components/EventsManager/types';
import { useLocale } from '@hooks/useLocale';
import { Box, Card, Flex, Text } from '@mantine/core';
import { useEventContext } from '@providers/EventsProvider';

interface AddEventKeysCardProps {
    keys: PropertiesType[];
}

interface KeyFieldProps {
    keyObj: PropertiesType;
    onChange: (key: string) => void;
}

const KeyField: React.FC<KeyFieldProps> = ({ keyObj, onChange }) => {
    return (
        <Card
            onClick={() => {
                if (!keyObj.reservedKey) {
                    onChange(keyObj.key);
                }
            }}
            className={`border-b border-gray-100 ${
                keyObj.reservedKey ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
        >
            <Flex gap="xs" align="center">
                <Checkbox
                    size="xs"
                    checked={keyObj.selectedProperty}
                    disabled={keyObj.reservedKey}
                />
                <Text>{keyObj.key}</Text>
            </Flex>
        </Card>
    );
};

const AddEventKeysCard: React.FC<AddEventKeysCardProps> = ({ keys }) => {
    const { t } = useLocale();
    const { state, actions } = useEventContext((context) => context);
    const { jsonPayloadKeys } = state;
    const { setJsonPayloadKeys } = actions;

    const handleSelectAll = () => {
        const allSelected = keys.every(
            (keyObject) => keyObject.selectedProperty,
        );

        setJsonPayloadKeys(
            jsonPayloadKeys.map((keyObject) => {
                const isKeyInView = keys.some(
                    (keyObj) => keyObj.key === keyObject.key,
                );

                if (isKeyInView) {
                    return {
                        ...keyObject,
                        selectedProperty: keyObject.reservedKey
                            ? keyObject.selectedProperty
                            : !allSelected,
                    };
                }
                return keyObject;
            }),
        );
    };

    return (
        <Box className="border border-solid border-shade-6 rounded-lg flex flex-col">
            <Card
                className="bg-gray-50 h-[2rem] py-1"
                onClick={handleSelectAll}
            >
                <Flex gap="xs" align="center">
                    <Checkbox
                        size="xs"
                        checked={
                            keys.length > 0 &&
                            keys.every(
                                (keyObject) => keyObject.selectedProperty,
                            )
                        }
                    />
                    <Text>
                        {t('event_create.properties_add_keys_card_label')}
                    </Text>
                </Flex>
            </Card>
            <Box className="overflow-auto h-[26rem]">
                {keys.map((keyObj) => (
                    <KeyField
                        key={keyObj.key}
                        keyObj={keyObj}
                        onChange={(key) => {
                            setJsonPayloadKeys(
                                jsonPayloadKeys.map((keyobject) => {
                                    if (keyobject.key === key) {
                                        return {
                                            ...keyobject,
                                            selectedProperty:
                                                !keyobject.selectedProperty,
                                        };
                                    }
                                    return keyobject;
                                }),
                            );
                        }}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default AddEventKeysCard;
