import InputErrorText from '@components/common/InputErrorText';
import { Stack } from '@mantine/core';
import {
    TimeInput as MantineTimeInput,
    type TimeInputProps,
} from '@mantine/dates';
import { forwardRef } from 'react';

const TimeInput = forwardRef<HTMLInputElement, TimeInputProps>(
    ({ error, ...rest }, ref) => {
        return (
            <Stack spacing={'xs'}>
                <MantineTimeInput ref={ref} {...rest} />
                {error && <InputErrorText value={error} />}
            </Stack>
        );
    },
);

export default TimeInput;
