import PrimaryTableConfiguration from './Steps/PrimaryTableConfiguration';
import PrimaryTableSetup from './Steps/PrimaryTableSetup';
import SchemaTableConfiguration from './Steps/SchemaTableConfiguration';
import SchemaTableRelationships from './Steps/SchemaTableRelationships';
import TableEvents from './Steps/TableEvents';
import TableProperties from './Steps/TableProperties';
import {
    SchemaBuilderStep,
    SchemaConfigStep,
    type SchemaStepMap,
} from './types';

export const SchemaBuilderSteps: SchemaStepMap[] = [
    {
        key: SchemaBuilderStep.SETUP,
        label: 'Setup',
        component: PrimaryTableSetup,
    },
    {
        key: SchemaBuilderStep.PERSONALISE,
        label: 'Personalise',
        component: PrimaryTableConfiguration,
    },
];

export const SchemaConfigSteps: SchemaStepMap[] = [
    {
        key: SchemaConfigStep.CONFIGURE,
        label: 'Configure',
        component: SchemaTableConfiguration,
    },
    {
        key: SchemaConfigStep.RELATIONSHIPS,
        label: 'Relationships',
        component: SchemaTableRelationships,
    },
    {
        key: SchemaConfigStep.EVENTS,
        label: 'Events',
        component: TableEvents,
    },
    {
        key: SchemaConfigStep.PROPERTIES,
        label: 'Configure',
        component: TableProperties,
    },
];
