import TimeInputWithOptions from '@components/common/Time/TimeInputWithOptions';
import { useLocale } from '@hooks/useLocale';
import {
    PeriodType as PeriodTypeEnum,
    type PeriodType,
} from '@lightdash/common';
import { Box, Group, Stack } from '@mantine/core';
import { ClockCountdown } from '@phosphor-icons/react';
import React from 'react';
import ActionTitle from '../ActionTitle';

export interface DelayActionProps {
    selectedDuration: number;
    onDurationChange: (duration: number, granularity: PeriodType) => void;
    granularity: PeriodType;
    durationOptions: PeriodType[];
    actionTitle: React.ReactNode;
    withLeftPadding?: boolean;
}

const DelayAction: React.FC<DelayActionProps> = ({
    selectedDuration,
    onDurationChange,
    granularity,
    durationOptions,
    actionTitle,
    withLeftPadding = true,
}) => {
    const { t } = useLocale();
    return (
        <Stack className="gap-2">
            {actionTitle ? (
                actionTitle
            ) : (
                <>
                    <Group className="gap-1">
                        <ClockCountdown color={'rgb(var(--color-gray-500))'} />
                        <ActionTitle title={'Time delay'} />
                    </Group>
                </>
            )}

            <Box className={`${withLeftPadding ? 'pl-6' : ''}`}>
                <TimeInputWithOptions
                    selectedDuration={selectedDuration}
                    onDurationChange={onDurationChange}
                    selectedGranularity={granularity}
                    granularityOptions={durationOptions}
                    focusOnMount={true}
                    onBlur={(value) => {
                        if (Number.isNaN(value)) {
                            onDurationChange(
                                null as unknown as number,
                                PeriodTypeEnum.HOUR,
                            ); //Typecasting the value here as null since the common type doesn't support null/undefined and we can use null/undefined to check if the time is missing
                        }
                    }}
                    error={
                        selectedDuration === null
                            ? t('common.error.empty_text_input')
                            : ''
                    }
                />
            </Box>
        </Stack>
    );
};

export default React.memo(DelayAction);
