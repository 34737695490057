import { Text, TextInput, type TextInputProps } from '@mantine/core';

interface LeftLabelInputProps extends TextInputProps {
    label?: string;
}

const LeftLabelInput: React.FC<LeftLabelInputProps> = ({
    label = 'Default',
    ...rest
}) => {
    return (
        <TextInput
            icon={
                <Text className="text-sm font-medium text-gray-500">
                    {label}
                </Text>
            }
            iconWidth={75}
            styles={() => ({
                icon: {
                    backgroundColor: 'rgb(var(--color-black)/0.02)',
                    borderRight: `1px solid rgb(var(--color-black)/0.06)`,
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                },
            })}
            {...rest}
        />
    );
};

export default LeftLabelInput;
