import {
    assertUnreachable,
    type AllIntegrationChannels,
    type Integration,
} from '@lightdash/common';
import React, { useMemo, useReducer } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

export const CHANNEL_SETUP_STATES = {
    SELECT_PROVIDER: 'SELECT_PROVIDER',
    ADD_CHANNEL_DETAILS: 'ADD_CHANNEL_DETAILS',
    UPDATE_CHANNEL_DETAILS: 'UPDATE_CHANNEL_DETAILS',
};

interface ChannelContextProps {
    state: {
        title: string;
        subtitle: string | undefined;
        showProviderConfigModal: boolean;
        showSetupModal: boolean;
        allChannels: AllIntegrationChannels | undefined;
        selectedChannel: string;
        providers: Integration[] | undefined;
        selectedProvider: Integration | undefined;
        searchText: string | undefined;
        allowChannelSetup: boolean;
        channelSetupState: string;
    };
    actions: {
        handleUpdateAllChannels: (data: AllIntegrationChannels) => void;
        handleShowProviderConfigModal: (show: boolean) => void;
        handleShowSetUpModal: (show: boolean) => void;
        handleIntegratedProvider: (data: Integration[]) => void;
        handleProviderSelect: (
            data: Integration | undefined,
            callback: boolean,
        ) => void;
        handleSearchKeyText: (key: string) => void;
        handleShowSetupFlow: (show: boolean) => void;
        updateChannelSetupStateAction: (val: string) => void;
    };
}

const ChannelContext = createContext<ChannelContextProps | undefined>(
    undefined,
);

export enum ActionType {
    SET_SHOW_PROVIDER_CONFIG_MODAL,
    SET_SHOW_SETUP_MODAL,
    SET_ALL_CHANNELS,
    SET_INTEGRATED_PROVIDER,
    SET_SELECTED_PROVIDER,
    SET_SEARCH_KEY,
    SET_SHOW_SETUP,
    UPDATE_CHANNEL_SETUP_STATE,
}

type Action =
    | { type: ActionType.SET_SHOW_PROVIDER_CONFIG_MODAL; payload: boolean }
    | { type: ActionType.SET_SHOW_SETUP_MODAL; payload: boolean }
    | { type: ActionType.SET_ALL_CHANNELS; payload: AllIntegrationChannels }
    | { type: ActionType.SET_INTEGRATED_PROVIDER; payload: Integration[] }
    | {
          type: ActionType.SET_SELECTED_PROVIDER;
          payload: Integration | undefined;
      }
    | { type: ActionType.SET_SEARCH_KEY; payload: string }
    | { type: ActionType.SET_SHOW_SETUP; payload: boolean }
    | { type: ActionType.UPDATE_CHANNEL_SETUP_STATE; payload: string };

export type ChannelProviderData = {
    title: string;
    subtitle: string | undefined;
    showProviderConfigModal: boolean;
    showSetupModal: boolean;
    allChannels: AllIntegrationChannels | undefined;
    selectedChannel: string;
    providers: Integration[] | undefined;
    selectedProvider: Integration | undefined;
    searchText: string | undefined;
    allowChannelSetup: boolean;
    handleProviderSelectCallback: undefined | ((obj: Integration) => void);
    channelSetupState: string;
};

function reducer(
    state: ChannelProviderData,
    action: Action,
): ChannelProviderData {
    switch (action.type) {
        case ActionType.SET_SHOW_PROVIDER_CONFIG_MODAL: {
            return {
                ...state,
                showProviderConfigModal: action.payload,
            };
        }

        case ActionType.SET_SHOW_SETUP_MODAL: {
            return {
                ...state,
                showSetupModal: action.payload,
            };
        }
        case ActionType.SET_ALL_CHANNELS: {
            return {
                ...state,
                allChannels: action.payload,
            };
        }
        case ActionType.SET_INTEGRATED_PROVIDER: {
            return {
                ...state,
                providers: action.payload,
            };
        }
        case ActionType.SET_SELECTED_PROVIDER: {
            return {
                ...state,
                selectedProvider: action.payload,
            };
        }
        case ActionType.SET_SEARCH_KEY: {
            return {
                ...state,
                searchText: action.payload,
            };
        }
        case ActionType.SET_SHOW_SETUP: {
            return {
                ...state,
                allowChannelSetup: action.payload,
            };
        }
        case ActionType.UPDATE_CHANNEL_SETUP_STATE: {
            return {
                ...state,
                channelSetupState: action.payload,
            };
        }

        default: {
            return assertUnreachable(
                action,
                'Unexpected action in explore reducer',
            );
        }
    }
}

const defaultChannelData: ChannelProviderData = {
    title: '',
    subtitle: undefined,
    showProviderConfigModal: true,
    showSetupModal: false,
    allChannels: undefined,
    providers: undefined,
    selectedProvider: undefined,
    searchText: undefined,
    allowChannelSetup: true,
    handleProviderSelectCallback: undefined,
    selectedChannel: '',
    channelSetupState: CHANNEL_SETUP_STATES.SELECT_PROVIDER,
};

interface ChannelProviderProps {
    initialState: ChannelProviderData;
    children: React.ReactNode;
}

export function ChannelProvider({
    children,
    initialState,
}: ChannelProviderProps) {
    const [reducerState, dispatch] = useReducer(
        reducer,
        initialState || defaultChannelData,
    );

    const handleUpdateAllChannels = React.useCallback(
        (data: AllIntegrationChannels): void =>
            dispatch({
                type: ActionType.SET_ALL_CHANNELS,
                payload: data,
            }),
        [],
    );

    const handleShowProviderConfigModal = React.useCallback(
        (show: boolean): void =>
            dispatch({
                type: ActionType.SET_SHOW_PROVIDER_CONFIG_MODAL,
                payload: show,
            }),
        [],
    );

    const handleShowSetUpModal = React.useCallback(
        (show: boolean): void =>
            dispatch({
                type: ActionType.SET_SHOW_SETUP_MODAL,
                payload: show,
            }),
        [],
    );

    const handleIntegratedProvider = React.useCallback(
        (data: Integration[]): void => {
            return dispatch({
                type: ActionType.SET_INTEGRATED_PROVIDER,
                payload: data,
            });
        },
        [],
    );

    const handleProviderSelect = React.useCallback(
        (data: Integration | undefined, callback: boolean): void => {
            dispatch({
                type: ActionType.SET_SELECTED_PROVIDER,
                payload: data,
            });
            if (callback && data && initialState.handleProviderSelectCallback) {
                initialState?.handleProviderSelectCallback(data);
            }
        },
        [initialState],
    );

    const handleSearchKeyText = React.useCallback(
        (searchKey: string): void =>
            dispatch({
                type: ActionType.SET_SEARCH_KEY,
                payload: searchKey,
            }),
        [],
    );

    const handleShowSetupFlow = React.useCallback(
        (show: boolean): void =>
            dispatch({
                type: ActionType.SET_SHOW_SETUP,
                payload: show,
            }),
        [],
    );

    const updateChannelSetupStateAction = React.useCallback(
        (val: string): void =>
            dispatch({
                type: ActionType.UPDATE_CHANNEL_SETUP_STATE,
                payload: val,
            }),
        [],
    );

    const value: ChannelContextProps = useMemo(
        () => ({
            state: reducerState,
            actions: {
                handleUpdateAllChannels,
                handleShowProviderConfigModal,
                handleShowSetUpModal,
                handleIntegratedProvider,
                handleProviderSelect,
                handleSearchKeyText,
                handleShowSetupFlow,
                updateChannelSetupStateAction,
            },
        }),
        [
            reducerState,
            handleUpdateAllChannels,
            handleShowProviderConfigModal,
            handleShowSetUpModal,
            handleIntegratedProvider,
            handleProviderSelect,
            handleSearchKeyText,
            handleShowSetupFlow,
            updateChannelSetupStateAction,
        ],
    );
    return (
        <ChannelContext.Provider value={value}>
            {children}
        </ChannelContext.Provider>
    );
}

export function useChannelContext<Selected>(
    selector: (value: ChannelContextProps) => Selected,
) {
    return useContextSelector(ChannelContext, (context) => {
        if (context === undefined) {
            throw new Error(
                'useContextSelector; must be used within a ChannelProvider',
            );
        }
        return selector(context);
    });
}
