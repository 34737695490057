import {
    FieldType,
    type FilterableDimension,
    type FilterableField,
    type JourneyDimension,
} from '@lightdash/common';

/**
 * Converts a JourneyDimension to a FilterableField.
 * @param {JourneyDimension} journeyDimension - The JourneyDimension to convert.
 * @returns {FilterableField} - The converted FilterableField.
 * @throws {Error} - If the conversion is not possible.
 */
export function convertToFilterableField(
    journeyDimension: JourneyDimension,
): FilterableField {
    const { type, label, name, table } = journeyDimension;

    const filterableField: FilterableDimension = {
        type,
        label,
        name,
        table,
        fieldType: FieldType.DIMENSION,
        tableLabel: table,
        sql: '',
        hidden: false,
    };

    return filterableField;
}
