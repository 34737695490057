import {
    ControlPanel,
    JourneyNodeEnum,
} from '@components/Journeys/Builder/types';
import {
    getBlockIcon,
    journeyGroupConfig,
} from '@components/Journeys/Builder/utils';
import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import { type JourneyBlockConfig, type JourneyGroup } from '@lightdash/common';
import { ActionIcon, Box, Divider, Group, Stack, Text } from '@mantine/core';
import { Spiral, X } from '@phosphor-icons/react';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import Fuse from 'fuse.js';
import React, { useCallback, useMemo, useState } from 'react';

interface BlockGroupListProps {
    group: JourneyGroup;
    blocks: JourneyBlockConfig[];
    onBlockClick: (blockId: string) => void;
}

const BlockGroupList: React.FC<BlockGroupListProps> = React.memo(
    ({ group, blocks, onBlockClick }) => {
        const renderItemTemplate = useCallback(
            (block: JourneyBlockConfig) => {
                const IconComponent = getBlockIcon({
                    type: JourneyNodeEnum.BLOCK,
                    actions: block.actions,
                    group: block.group,
                });
                return (
                    <Group
                        className="w-full gap-2 p-2 bg-white border border-gray-200 rounded-lg cursor-pointer hover:bg-gray-50"
                        onClick={() => onBlockClick(block.id)}
                    >
                        <Box className="flex items-center justify-center bg-white border border-gray-200 rounded-lg h-7 w-7">
                            {IconComponent}
                        </Box>
                        <Text className="text-sm font-medium text-gray-800">
                            {block.title}
                        </Text>
                    </Group>
                );
            },
            [onBlockClick],
        );

        if (!group) return null;
        const groupName = journeyGroupConfig[group].title;

        return (
            <Stack className="w-full gap-3">
                <Text className="text-xs font-medium text-gray-500 uppercase">
                    {groupName}
                </Text>
                <Stack className="gap-1.5">
                    {blocks.map((block) => (
                        <Box key={block.id}>{renderItemTemplate(block)}</Box>
                    ))}
                </Stack>
            </Stack>
        );
    },
);

const BlocksListPanel: React.FC = () => {
    const { closeControlPanel, addNode } = useJourneyBuilderContext(
        (context) => context.actions,
    );

    const { controlPanel } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const { blocksList } = useJourneyBuilderContext((context) => context.state);

    const { t } = useLocale();
    const [searchQuery, setSearchQuery] = useState<string>('');

    const fuse = useMemo(
        () =>
            new Fuse(blocksList || [], {
                keys: ['title', 'group'],
                threshold: 0.3,
            }),
        [blocksList],
    );

    const filteredBlocks = useMemo(() => {
        if (!searchQuery) return blocksList;
        const result = fuse.search(searchQuery);
        return result.map((res) => res.item);
    }, [searchQuery, fuse, blocksList]);

    const groupedBlocks = useMemo(
        () =>
            filteredBlocks?.reduce((acc, block) => {
                acc[block.group] = acc[block.group] || [];
                acc[block.group].push({ ...block });
                return acc;
            }, {} as Record<JourneyGroup, JourneyBlockConfig[]>),
        [filteredBlocks],
    );

    const handleBlockClick = useCallback(
        (blockId: string) => {
            const { isOpen } = controlPanel;
            if (!isOpen) return;
            const { type } = controlPanel;
            if (type === ControlPanel.BLOCKS_LIST) {
                const { reactFlowNodeId } = controlPanel;
                console.log('reactFlowNodeId', reactFlowNodeId);
                addNode(blockId, reactFlowNodeId);
            }
        },
        [addNode, controlPanel],
    );
    return (
        <Stack className="gap-0">
            <Box className="p-1">
                <ActionIcon size={'lg'} onClick={closeControlPanel}>
                    <X weight="bold" color={'rgb(var(--color-gray-700))'} />
                </ActionIcon>
            </Box>
            <Divider className="border-t-gray-200" />
            <Stack className="w-full gap-1 p-3 border-b border-b-gray-200">
                <Group className="gap-1.5">
                    <Spiral color={'rgb(var(--color-blu-800))'} />
                    <Text className="text-sm font-medium text-gray-800">
                        {t('journey_builder.blocks_list_title')}
                    </Text>
                </Group>
                <Text className="text-sm font-medium text-gray-600">
                    {t('journey_builder.blocks_list_description')}
                </Text>
            </Stack>

            <Stack className="gap-3 p-3">
                <SearchInput
                    placeholder={t(
                        'journey_builder.blocks_list_search_placeholder',
                    )}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                {groupedBlocks &&
                    (
                        Object.entries(groupedBlocks) as [
                            JourneyGroup,
                            JourneyBlockConfig[],
                        ][]
                    ).map(([group, blocks]) => (
                        <BlockGroupList
                            key={group}
                            group={group}
                            blocks={blocks}
                            onBlockClick={handleBlockClick}
                        />
                    ))}
            </Stack>
        </Stack>
    );
};

export default React.memo(BlocksListPanel);
