import { ListSelectItem } from '@components/Audience/Filters/FilterSelect';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import { useLocale } from '@hooks/useLocale';
import { DimensionType, isField, RelationTableType } from '@lightdash/common';
import {
    Box,
    createStyles,
    Flex,
    Group,
    Menu,
    Stack,
    Text,
    TextInput,
    Tooltip,
    type SelectProps,
} from '@mantine/core';
import { MagnifyingGlass, WarningCircle } from '@phosphor-icons/react';
import Fuse from 'fuse.js';
import { useCallback, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useDebounce } from 'react-use';

const sizes: any = {
    xs: '10rem',
    sm: '18rem',
    md: '22rem',
};

const generateKey = (item: FieldWithSuggestions) => {
    return item.tableType === RelationTableType.EVENT &&
        item.type === DimensionType.EVENT
        ? `${item.index}_${item.fieldReference}_${item.table}`
        : `${item.index}_${item.name}_${item.table}`;
};

const useStyles = createStyles((_theme) => ({
    dropdown: {
        height: '300px',
        overflow: 'scroll',
    },
}));

type FieldSelectProps = Omit<SelectProps, 'value' | 'data' | 'onChange'> & {
    item: FieldWithSuggestions | undefined;
    items: FieldWithSuggestions[];
    inactiveItemIds?: string[];
    onChange: (value: FieldWithSuggestions) => void;
    onClosed?: () => void;
    hasGrouping?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    error?: string;
    hideLabel?: boolean;
    hideTableLabel?: boolean;
    labelCustomClasses?: string;
    tableLabelCustomClasses?: string;
};

const FieldSelect = ({
    item,
    items,
    onChange,
    // onClosed,
    isDisabled = false,
    placeholder = '',
    error,
    size = 'sm',
    hideLabel = false,
    hideTableLabel = false,
    labelCustomClasses,
    tableLabelCustomClasses,
}: // ...rest
FieldSelectProps): JSX.Element => {
    const { classes } = useStyles();
    const [search, setSearch] = useState<string>('');
    const { t } = useLocale();
    const [searchDebounce, setSearchDebounce] = useState<string>('');
    useDebounce(
        () => {
            setSearchDebounce(search);
        },
        300,
        [search],
    );
    const filterItems = useCallback(
        (data: FieldWithSuggestions[]) => {
            let fields: FieldWithSuggestions[] = data;

            if (searchDebounce !== '') {
                const fuse = new Fuse(data, {
                    keys: ['label', 'table'],
                    threshold: 0.3,
                });

                fields = fuse.search(searchDebounce).map((res) => res.item);
            }

            return fields;
        },
        [searchDebounce],
    );

    return (
        <Menu
            classNames={classes}
            withinPortal={true}
            position="bottom-start"
            disabled={isDisabled}
            zIndex={9999}
            styles={{
                dropdown: {
                    padding: '0px !important',
                },
            }}
            onClose={() => setSearch('')}
        >
            <Menu.Target>
                <Stack className="gap-1.5 w-fit">
                    <button
                        className="p-2 w-fit"
                        style={{
                            borderRadius: '8px',
                            background: 'white',
                            border: '1px solid',
                            borderColor: 'rgba(0, 0, 0, 0.06)',
                            boxShadow: `0px -1px 4px 0px rgba(0, 0, 0, 0.06) inset`,
                            fontSize: '14px',
                            color: 'rgb(var(--color-gray-600))',
                            width: 'wrap-content !important',
                            cursor: isDisabled ? 'auto' : 'pointer',
                            minWidth: sizes[size],
                            minHeight: '2.3rem',
                        }}
                        form="some_random"
                    >
                        <Flex justify="space-between" align="center">
                            <ListSelectItem
                                item={item}
                                divider={
                                    <span className="text-sm text-gray-600">
                                        in
                                    </span>
                                }
                                placeholder={placeholder}
                                hideLabel={hideLabel}
                                hideTableLabel={hideTableLabel}
                                labelCustomClasses={labelCustomClasses}
                                tableLabelCustomClasses={
                                    tableLabelCustomClasses
                                }
                            />
                            <ChevronDown
                                color={'rgb(var(--color-gray-600)'}
                                size={13}
                                strokeWidth={2}
                            />
                        </Flex>
                    </button>
                    {error && (
                        <Group className="gap-1">
                            <WarningCircle
                                color={'rgb(var(--color-halt-800)'}
                            />
                            <Text className="text-sm font-medium text-halt-800">
                                {error}
                            </Text>
                        </Group>
                    )}
                </Stack>
            </Menu.Target>

            <Menu.Dropdown>
                <TextInput
                    icon={<MagnifyingGlass />}
                    sx={{
                        input: {
                            border: '0px',
                            borderRadius: '0px',
                            borderBottom: '1px solid rgb(var(--color-shade-6))',
                            borderEnd: '1px solid rgb(var(--color-shade-6))',
                        },
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={t('create_campaign.channel_search')}
                    className="sticky top-0 !w-full border-b border-shade-6 border-solid text-gray-800"
                />

                {filterItems(items)
                    .sort((a, b) => a.table?.localeCompare(b?.table ?? '') ?? 0)
                    .map((i) => {
                        return (
                            <Menu.Item
                                key={generateKey(i)}
                                onClick={() => onChange(i)}
                            >
                                <Tooltip
                                    label={
                                        <Text truncate size={size}>
                                            {isField(i)
                                                ? i.description
                                                : undefined}
                                        </Text>
                                    }
                                    position="top"
                                    multiline
                                    maw={400}
                                    offset={-2}
                                    withinPortal
                                >
                                    <Box>
                                        <Group
                                            noWrap
                                            spacing={size}
                                            maw="100%"
                                            sx={{ overflow: 'hidden' }}
                                        >
                                            <ListSelectItem
                                                item={i}
                                                hideLabel={hideLabel}
                                                hideTableLabel={hideTableLabel}
                                            />
                                        </Group>
                                    </Box>
                                </Tooltip>
                            </Menu.Item>
                        );
                    })}
            </Menu.Dropdown>
        </Menu>
    );
};

export default FieldSelect;
