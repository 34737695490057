import { sortmentApi } from '@api/index';
import { type ApiError, type Audience } from '@lightdash/common';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';

interface SavedAudienceProps {
    audienceUuid: string;
    relationId: string;
}

const getSavedAudience = async ({
    audienceUuid,
    relationId,
    projectId,
}: SavedAudienceProps & { projectId: string }): Promise<Audience> =>
    sortmentApi<Audience>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceUuid}`,
        method: 'GET',
        body: undefined,
    });

export const useSavedAudience = ({
    audienceUuid,
    relationId,
}: SavedAudienceProps) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    return useQuery<Audience, ApiError>({
        queryKey: [QueryKeys.SAVED_AUDIENCE, audienceUuid],
        queryFn: () =>
            getSavedAudience({
                audienceUuid,
                relationId,
                projectId: projectUuid,
            }),
        enabled: Boolean(audienceUuid) && Boolean(relationId),
        retry: false,
    });
};
