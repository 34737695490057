import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import TableIcon from '@components/common/IconPack/TableIcon';
import Select from '@components/common/Select';
import FieldSelect from '@components/common/Select/FieldSelect';
import { useLocale } from '@hooks/useLocale';
import {
    useTableMetadata,
    useUpdateTableDetail,
} from '@hooks/useSchemaBuilder';
import {
    JoinType,
    type CompiledRelation,
    type JoinResponse,
    type RelationTableType,
} from '@lightdash/common';
import { Box, Button, Flex, Grid, Group, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Plus } from '@phosphor-icons/react/dist/ssr';
import { useRelationContext } from '@providers/RelationProvider';
import { useSchemaContext } from '@providers/SchemaProvider';
import { useQueryClient } from '@tanstack/react-query';
import { t as translate } from 'i18next';
import { useEffect, useMemo, type FC } from 'react';
import { X } from 'react-feather';
import { QueryKeys } from 'types/UseQuery';
import {
    ButtonVariant,
    FontSizes,
    GlobalStyles,
} from '../../../../../mantineTheme';

const getValidators = () => {
    return {
        relations: {
            source: {
                field: (field: { name: string } | undefined) => {
                    return !field || !field.name || !field.name.length
                        ? 'Required field'
                        : null;
                },
            },
            target: {
                table: (table: { name: string } | undefined) => {
                    return !table || !table.name || !table.name.length
                        ? 'Required field'
                        : null;
                },
                field: (field: { name: string } | undefined) => {
                    return !field || !field.name || !field.name.length
                        ? 'Required field'
                        : null;
                },
            },
            joinType: (value: string | undefined | null) => {
                return !value || !value.length ? 'Required field' : null;
            },
        },
    };
};

const defaultRelation = {
    source: {
        field: null,
    },
    target: {
        table: null,
        field: null,
    },
    joinType: null,
};

const JOIN_TYPE_OPTIONS = [
    {
        label: translate('custom_edge.one_one'),
        value: JoinType.one_one,
    },
    {
        label: translate('custom_edge.one_many'),
        value: JoinType.one_many,
    },
    {
        label: translate('custom_edge.many_one'),
        value: JoinType.many_one,
    },
    {
        label: translate('custom_edge.many_many'),
        value: JoinType.many_many,
    },
];

const RelationshipManager: FC = () => {
    const { t } = useLocale();
    const { activeRelationUuid, activeRelation } = useRelationContext();
    const queryClient = useQueryClient();
    const { activeProject, schemaPayload } = useSchemaContext(
        (context) => context.state,
    );
    const { mutateAsync: updateTableDetail } =
        useUpdateTableDetail(activeRelationUuid);

    const tables = Object.values(activeRelation?.tables ?? {}).map(
        ({ database, dimensions, label, name, schema, type, isConfigured }) => {
            return {
                database,
                columns: Object.values(dimensions).map((item) => {
                    return {
                        label: item.label,
                        name: item.name,
                        type: item.type,
                        hidden: item.hidden,
                    };
                }),
                label,
                tableLabel: label,
                name,
                schema,
                type,
                isConfigured,
            };
        },
    );

    const sourceTable: any = tables?.find(
        (table) => table.name === schemaPayload.name,
    );

    const form = useForm({
        initialValues: {
            relations: [defaultRelation],
        },
        validate: getValidators(),
    });

    const addedTables = useMemo(() => {
        return form.values.relations
            .map((relation) =>
                relation.target.table
                    ? (relation.target.table as CompiledRelation)?.name
                    : '',
            )
            .filter((val) => val);
    }, [form]);

    const targetTables = useMemo(
        () =>
            tables?.filter(
                (table) =>
                    table.name !== schemaPayload.name &&
                    !table.isConfigured &&
                    !addedTables.includes(table.name),
            ),
        [tables, schemaPayload, addedTables],
    );

    useEffect(() => {
        if (!sourceTable) return;

        let formValues = schemaPayload?.relationships?.map(
            (relation: JoinResponse) => {
                const targetTable = tables.find(
                    (table) => table.name === relation.target.table,
                );

                return {
                    source: {
                        field: sourceTable.columns.find(
                            (column: any) =>
                                column.name === relation.source.field,
                        ),
                    },
                    target: {
                        table: targetTable,
                        field: targetTable?.columns.find(
                            (column) => column.name === relation.target.field,
                        ),
                    },
                    joinType: relation.joinType,
                };
            },
        );

        if (!formValues || !formValues.length) formValues = [];

        form.setValues({ relations: formValues });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schemaPayload?.relationships]);

    const tableColumns: any = {};
    for (const table of tables) {
        if (!tableColumns[table?.name]) tableColumns[table?.name] = [];

        tableColumns[table?.name] = table?.columns;
    }

    const handleSubmit = async () => {
        const { hasErrors } = form.validate();
        if (hasErrors) return;

        const payload = form.values.relations.map((relation: any) => {
            return {
                source: {
                    field: relation.source.field.name,
                },
                target: {
                    table: relation.target.table.name,
                    field: relation.target.field.name,
                },
                joinType: relation.joinType,
            };
        });

        await updateTableDetail({
            tableName: schemaPayload.name,
            payload: {
                type: schemaPayload.type,
                relationships: payload,
            },
        });

        await queryClient.invalidateQueries([
            QueryKeys.RELATION_SCHEMA,
            activeProject?.projectUuid,
            activeRelationUuid,
        ]);
    };

    const { data: sourceTableMetaData } = useTableMetadata({
        database: schemaPayload.database,
        schema: schemaPayload.schema,
        table: schemaPayload.name,
    });

    // const sourceTableFields = useMemo(() => {
    //     return sourceTableMetaData?.columns?.map((item: WarehouseTableColumn) => ({
    //         ...item,
    //         label: item.name,
    //         name: item.name,
    //     })) as FieldWithSuggestions[] || [];
    // }, [sourceTableMetaData]);

    if (!sourceTableMetaData) return null;

    const formFields = form.values.relations.map((row: any, index: number) => (
        <Flex align={'flex-start'} key={row.key} gap={'sm'} className="mb-2">
            <Group
                className="w-fit border rounded-lg p-2 shadow-custom-inset"
                spacing={'sm'}
            >
                <TableIcon type={schemaPayload.type} strokeWidth={2.5} />
                <Text
                    className={`
                        overflow-hidden text-ellipsis whitespace-nowrap
                        w-[110px]
                        ${
                            GlobalStyles.tableStyles[
                                schemaPayload.type as RelationTableType
                            ].textColorTailwind
                        }
                    `}
                >
                    {sourceTable.label}
                </Text>
            </Group>

            <Box className="w-40">
                <FieldSelect
                    size="xs"
                    width={200}
                    hideTableLabel
                    item={row.source.field || undefined}
                    items={sourceTable.columns}
                    placeholder={t(
                        'relationship_manager.relation.select_column',
                    )}
                    labelCustomClasses="whitespace-nowrap
                    overflow-hidden
                    text-ellipsis
                    w-[100px]"
                    {...form.getInputProps(`relations.${index}.source.field`)}
                />
            </Box>

            <Box className="w-30">
                <Select
                    placeholder={t(
                        'relationship_manager.relation.select_relation',
                    )}
                    data={JOIN_TYPE_OPTIONS}
                    {...form.getInputProps(`relations.${index}.joinType`)}
                    className="!w-[8rem]"
                />
            </Box>

            <Box className="w-40">
                <FieldSelect
                    size="xs"
                    width={200}
                    hideLabel
                    item={
                        row.target.table
                            ? {
                                  ...row.target.table,
                                  tableType: row.target.table?.type,
                              }
                            : {}
                    }
                    items={
                        (targetTables?.map((item: any) => ({
                            name: item.name,
                            tableLabel: item.tableLabel,
                            columns: item.dimensions,
                            tableType: item.type,
                            type: item.type,
                        })) as unknown as FieldWithSuggestions[]) || []
                    }
                    placeholder={t(
                        'relationship_manager.relation.select_table',
                    )}
                    onChange={(value) => {
                        form.setFieldValue(
                            `relations.${index}.target.table`,
                            value,
                        );
                        form.setFieldValue(
                            `relations.${index}.target.field`,
                            null,
                        );
                    }}
                    tableLabelCustomClasses="whitespace-nowrap overflow-hidden text-ellipsis w-[100px]"
                />
            </Box>

            <Box className="w-40">
                <FieldSelect
                    size="xs"
                    width={200}
                    hideTableLabel
                    item={row.target.field || ''}
                    items={
                        (tableColumns[row?.target?.table?.name]?.map(
                            (item: any) => ({
                                label: item.label,
                                name: item.name,
                                type: item.type,
                            }),
                        ) as unknown as FieldWithSuggestions[]) || []
                    }
                    placeholder={t(
                        'relationship_manager.relation.select_column',
                    )}
                    labelCustomClasses="whitespace-nowrap
                    overflow-hidden
                    text-ellipsis
                    w-[100px]"
                    {...form.getInputProps(`relations.${index}.target.field`)}
                />
            </Box>

            <Button
                variant={ButtonVariant.UNSTYLED}
                leftIcon={<X size={FontSizes.sm} />}
                onClick={() => {
                    form.removeListItem('relations', index);
                }}
            ></Button>
        </Flex>
    ));

    return (
        <Box>
            <Grid justify="space-between" align="center" className="m-4">
                <Text size={FontSizes.xs} color="gray.6" className="mx-2">
                    PROPERY IN `TABLE_NAME`
                </Text>
                <Box className="flex-1">
                    <hr />
                </Box>
                <Text size={FontSizes.xs} color="gray.6" className="mx-2">
                    TYPE OF RELATIONSHIP
                </Text>
                <Box className="flex-1">
                    <hr />
                </Box>
                <Text size={FontSizes.xs} color="gray.6" className="mx-2">
                    IS RELATED TO
                </Text>
            </Grid>

            <form className="mb-14">
                {formFields}

                <Button
                    variant={ButtonVariant.OUTLINED}
                    leftIcon={<Plus />}
                    className="my-4"
                    disabled={!targetTables.length}
                    onClick={() => {
                        form.setFieldValue('relations', [
                            ...form.values.relations,
                            defaultRelation,
                        ]);
                    }}
                >
                    Add another
                </Button>
            </form>

            <Group className="justify-end fixed w-full bottom-0 my-0 -ml-3 p-4 border-t-2 bg-white">
                <Button
                    type="submit"
                    variant={ButtonVariant.PRIMARY}
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </Group>
        </Box>
    );
};

export default RelationshipManager;
