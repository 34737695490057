import { useActiveProjectUuid } from '@hooks/useActiveProject';
import { useLocale } from '@hooks/useLocale';
import { useProject } from '@hooks/useProject';
import { Timezones } from '@lightdash/common';
import { Group, Input } from '@mantine/core';
import moment from 'moment';
import { type FC } from 'react';
import { getWeeklyCronExpression, parseCronExpression } from './cronInputUtils';
import TimePicker from './TimePicker';
import WeekDaySelect from './WeekDaySelect';

const WeeklyInputs: FC<{
    disabled?: boolean;
    cronExpression: string;
    onChange: (value: string) => void;
}> = ({ disabled, cronExpression, onChange }) => {
    const { minutes, hours, weekDay } = parseCronExpression(cronExpression);

    const { activeProjectUuid } = useActiveProjectUuid();
    const { data: projectData } = useProject(activeProjectUuid);
    const projectTimezone = projectData?.timezone ?? Timezones.UTC;

    const offset = moment().tz(projectTimezone).format('Z');
    const { t } = useLocale();

    const onDayChange = (newWeekday: number) => {
        onChange(getWeeklyCronExpression(minutes, hours, newWeekday));
    };

    const onTimeChange = (newTime: { hours: number; minutes: number }) => {
        onChange(
            getWeeklyCronExpression(newTime.minutes, newTime.hours, weekDay),
        );
    };

    return (
        <>
            <Input.Label className="text-gray-600 text-sm font-normal">
                {t('audience_scheduler.on')}
            </Input.Label>
            <WeekDaySelect value={weekDay} onChange={onDayChange} />
            <Input.Label className="text-gray-600 text-sm font-normal">
                {t('audience_scheduler.at')}
            </Input.Label>
            <Group noWrap spacing="sm">
                <TimePicker
                    disabled={disabled}
                    cronExpression={cronExpression}
                    onChange={onTimeChange}
                />
                <Input.Label className="text-gray-600 text-sm font-normal">
                    {`(GMT${offset})`}
                </Input.Label>
            </Group>
        </>
    );
};
export default WeeklyInputs;
