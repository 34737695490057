import SkeletonLoader from '@components/common/SkeletonLoader';
import { useGetChannels } from '@hooks/useChannels';
import { Stack } from '@mantine/core';
import { useChannelContext } from '@providers/ChannelProvider';
import { useEffect } from 'react';
import AddChannelModal from './AddChannelModal';
import ChannelList from './ChannelList';

interface ChannelContainerProps {
    showSetupLabels?: boolean;
}
const ChannelContainer: React.FC<ChannelContainerProps> = ({
    showSetupLabels,
}) => {
    const { data: allChannels, isFetching } = useGetChannels();

    const { actions } = useChannelContext((context) => context);
    const { handleUpdateAllChannels } = actions;

    useEffect(() => {
        if (allChannels) {
            handleUpdateAllChannels(allChannels);
        }
    }, [allChannels, handleUpdateAllChannels]);

    if (isFetching) {
        return (
            <Stack className="gap-2">
                <SkeletonLoader height={10} />
                <SkeletonLoader height={10} />
                <SkeletonLoader height={70} />
            </Stack>
        );
    }

    return (
        <>
            <ChannelList showSetupLabels={showSetupLabels} />
            <AddChannelModal loading={isFetching} />
        </>
    );
};

export default ChannelContainer;
