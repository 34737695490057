import {
    Box,
    Group,
    Modal as MantineModal,
    type ModalProps as MantineModalProps,
} from '@mantine/core';
import React from 'react';

export interface ModalProps extends MantineModalProps {
    children: React.ReactNode;
    title?: React.ReactNode;
    footerRightSection?: React.ReactNode;
    footerLeftSection?: React.ReactNode;
    withContentPadding?: boolean;
    withFooterBorder?: boolean;
    contentCustomClass?: string;
}

const Modal: React.FC<ModalProps> = ({
    children,
    title,
    footerRightSection,
    footerLeftSection,
    withContentPadding = true,
    withFooterBorder,
    contentCustomClass,
    ...props
}) => {
    const withFooter = footerLeftSection || footerRightSection;
    const footerBorder = withFooterBorder ? ' border-t border-shade-6' : '';
    return (
        <MantineModal centered {...props} title={title}>
            <Box
                className={`${withContentPadding ? 'px-4 py-3' : ''} ${
                    contentCustomClass ? contentCustomClass : ''
                }`}
            >
                {children}
            </Box>

            {withFooter && (
                <>
                    <Group
                        className={
                            'sticky bottom-0 flex items-center justify-between p-4 bg-white ' +
                            footerBorder
                        }
                    >
                        <Box>{footerLeftSection}</Box>
                        <Box>{footerRightSection}</Box>
                    </Group>
                </>
            )}
        </MantineModal>
    );
};

export default React.memo(Modal);
