import { Box, Button, Group } from '@mantine/core';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { useCampaignContext } from '@providers/CampaignProvider';
import React, { useCallback } from 'react';
import { ButtonVariant } from '../../../../mantineTheme';
import { CampaignBuilderSteps } from './../utils';

const CampaignBuilderFooter: React.FC<{}> = () => {
    const {
        currentStep,
        currentStepCallback,
        previousStepCallback,
        showFooterButtons,
    } = useCampaignContext((context) => context.state);
    const { setCurrentStep, setShowFooterButtons } = useCampaignContext(
        (context) => context.actions,
    );

    const getNextStep = useCallback(() => {
        const currentStepIndex = CampaignBuilderSteps.findIndex(
            (step) => step.key === currentStep,
        );

        return CampaignBuilderSteps[currentStepIndex + 1]?.key;
    }, [currentStep]);

    const getPreviousStep = useCallback(() => {
        const currentStepIndex = CampaignBuilderSteps.findIndex(
            (step) => step.key === currentStep,
        );
        return CampaignBuilderSteps[currentStepIndex - 1]?.key;
    }, [currentStep]);

    const handleBack = useCallback(() => {
        const previousStep = getPreviousStep();
        if (previousStepCallback) {
            const skip = previousStepCallback();
            if (skip) {
                return;
            }
        }
        if (previousStep) {
            setShowFooterButtons({
                next: true,
                back: true,
            });
            setCurrentStep(previousStep);
        }
    }, [
        getPreviousStep,
        previousStepCallback,
        setCurrentStep,
        setShowFooterButtons,
    ]);

    const handleNext = useCallback(() => {
        const nextStep = getNextStep();
        if (currentStepCallback) {
            const skipExecution = currentStepCallback();
            //Skips moving to the next step unless specified in the setCallback
            if (skipExecution) {
                return;
            }
        }
        if (nextStep) {
            setShowFooterButtons({
                next: true,
                back: true,
            });
            setCurrentStep(nextStep);
        }
    }, [
        getNextStep,
        currentStepCallback,
        setShowFooterButtons,
        setCurrentStep,
    ]);

    return (
        <Group className="justify-between w-full">
            {showFooterButtons.back ? (
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={handleBack}
                    leftIcon={<CaretLeft />}
                >
                    Go back
                </Button>
            ) : (
                <Box className="flex-1"></Box>
            )}

            {showFooterButtons.next && (
                <Button
                    variant={ButtonVariant.PRIMARY}
                    onClick={handleNext}
                    rightIcon={<CaretRight color={'white'} />}
                >
                    Next
                </Button>
            )}
        </Group>
    );
};

export default React.memo(CampaignBuilderFooter);
