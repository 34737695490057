import { useLocale } from '@hooks/useLocale';
import { Group, Stack, Text, Textarea } from '@mantine/core';
import { WarningCircle } from '@phosphor-icons/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SegmentedMessage } from 'sms-segments-calculator';

interface SMSContentAreaProps {
    content: string;
    onChange: (content: string, isUnicode: boolean) => void;
    error: boolean;
}

const SMSContentArea: React.FC<SMSContentAreaProps> = ({
    content,
    onChange,
    error,
}) => {
    const [segmentInfo, setSegmentInfo] = useState<string>('');
    const { t } = useLocale();

    const segmentedMessage = useMemo(
        () => new SegmentedMessage(content),
        [content],
    );

    const encodingName = segmentedMessage.encodingName;
    const bitsPerCharacter = encodingName === 'UCS-2' ? 16 : 7;
    const characters = segmentedMessage.messageSize / bitsPerCharacter;
    const credits = segmentedMessage.segmentsCount;
    const creditLimit =
        credits > 1
            ? encodingName === 'UCS-2'
                ? 67
                : 153
            : encodingName === 'UCS-2'
            ? 70
            : 160;

    useEffect(() => {
        setSegmentInfo(
            t('sms_template_builder.content_encoding', {
                encodingName,
                characters,
                credits,
                creditLimit,
            }),
        );
    }, [t, encodingName, characters, credits, creditLimit]);

    const handleContentChange = useCallback(
        (value: string) => {
            const valueWithNewlines = value.replace(/\n/g, '\\n');
            const newSegmentedMessage = new SegmentedMessage(value);
            const isUnicode = newSegmentedMessage.encodingName === 'UCS-2';
            onChange(valueWithNewlines, isUnicode);
        },
        [onChange],
    );

    return (
        <Stack className="gap-1.5">
            <Textarea
                placeholder="Type your message"
                label="Content"
                withAsterisk={false}
                value={content.replace(/\\n/g, '\n')}
                onChange={(event) => handleContentChange(event.target.value)}
                autosize
                minRows={12}
                data-gramm="false" // To disable grammarly
                data-gramm_editor="false" // To disable grammarly
                data-enable-grammarly="false" // To disable grammarly
            />
            {error && (
                <Group className="gap-1">
                    <WarningCircle color="rgb(var(--color-halt-800))" />
                    <Text className="text-sm font-medium text-halt-800">
                        {t('sms_template_builder.content_error')}
                    </Text>
                </Group>
            )}
            <Text className="text-sm font-medium text-gray-500">
                {segmentInfo}
            </Text>
        </Stack>
    );
};

export default React.memo(SMSContentArea);
