import { useLocale } from '@hooks/useLocale';
import { Box } from '@mantine/core';
import grapesjs, { type Editor } from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    type FC,
} from 'react';
import {
    Action as ActionEnum,
    TemplateActions,
    type Action,
} from '../../TemplateActions';
import { type HtmlTemplateDetails } from './../types';
import { useGrapesEditorConfig } from './useGrapesEditorConfig';

const getImageLinksFromTemplate = (html: string): string[] => {
    if (html) {
        const htmlElement = document.createElement('div');
        htmlElement.innerHTML = html;
        const images = htmlElement.querySelectorAll('img');
        const srcs = Array.from(images).map((img) => img.src);
        return srcs;
    }
    return [];
};

interface IHTMLEditorProps {
    templateDetail: HtmlTemplateDetails;
    onSave: (template: HtmlTemplateDetails) => void;
    onClose: () => void;
    onTitleChange: (title: string) => void;
    onHeaderAction: (action: Action) => void;
}

const HTMLEditor: FC<IHTMLEditorProps> = ({
    templateDetail,
    onSave,
    onClose,
    onTitleChange,
    onHeaderAction,
}: IHTMLEditorProps) => {
    const { t } = useLocale();
    const [editor, setEditor] = useState<Editor | null>(null);
    const editorContainer = useRef<HTMLDivElement>(null);
    const getHtmlRef = useRef<() => void>();
    const { newAsset, editorConfig } = useGrapesEditorConfig();

    const getHTML = useMemo(
        () => (): void => {
            const iframe = editorContainer.current?.querySelector('iframe');
            if (!iframe) return;

            const iframeDoc: Document | null = iframe.contentDocument;
            if (!iframeDoc) return;

            onSave({
                ...templateDetail,
                html: iframeDoc.documentElement.outerHTML,
            });
        },
        [templateDetail, onSave],
    );

    useEffect(() => {
        getHtmlRef.current = getHTML;
    }, [getHTML]);

    const handleHTMLActions = useCallback(
        (action: Action) => {
            if (!editor) return;
            if (action === ActionEnum.RESET) {
                editor.setComponents('');
            }
            onHeaderAction(action);
        },
        [editor, onHeaderAction],
    );

    useEffect(() => {
        const grapesEditor = grapesjs.init(editorConfig);
        grapesEditor.setComponents(templateDetail?.html);
        grapesEditor.Panels.getPanel('views')?.buttons.add([
            {
                id: 'exit-button',
                className: 'html-editor-exit-button',
                label: 'Exit',
                command: () => {
                    onClose();
                },
            },
            {
                id: 'alert-button',
                className: 'html-editor-continue-button',
                label: 'Continue',
                command: () => {
                    getHtmlRef.current?.();
                },
            },
        ]);

        grapesEditor.Panels.removeButton('options', 'fullscreen');
        grapesEditor.Panels.removeButton('options', 'export-template');
        grapesEditor.Panels.removeButton('options', 'gjs-toggle-images');

        grapesEditor.Traits.addType('toggle', {
            templateInput: undefined,
            noLabel: true,
            createInput() {
                const el = document.createElement('div');
                el.innerHTML = `
                <div style="display: flex; align-items: center; justify-content: space-between">
                    <span>Unsubscribe Preferences</span>
                    <input checked value="true" style="width: 20px; height: 20px; border: 1px solid black !important" class="toggle-input" type="checkbox" />
                </div>
                `;
                const toggleInput = el.querySelector('.toggle-input');
                toggleInput?.setAttribute('value', 'true');

                return el;
            },
            // Update the component based on element changes
            // `elInput` is the result HTMLElement you get from `createInput`
            onEvent({ elInput, component, event }) {
                const inputElement = elInput.querySelector(
                    '.toggle-input',
                ) as HTMLInputElement;

                if (event.target && 'checked' in event.target) {
                    inputElement.value = event.target.checked as string;
                    if (event.target.checked) {
                        component.setAttributes({
                            style: {
                                display: 'table-cell',
                                width: '50%',
                                'padding-left': '20px',
                            },
                        });
                    } else {
                        component.setAttributes({
                            style: {
                                display: 'none',
                            },
                        });
                    }
                }
            },
        });

        // Define and register the new component type
        grapesEditor.DomComponents.addType('unsubscribe-links', {
            isComponent: (el) => {
                if (
                    el.tagName === 'TABLE' &&
                    el.innerHTML.includes('Unsubscribe')
                ) {
                    return { type: 'unsubscribe-links' };
                }
            },
            model: {
                defaults: {
                    tagName: 'table',
                    droppable: false,
                    attributes: { style: 'width: 100%' },
                    components: [
                        {
                            tagName: 'tr',
                            droppable: false,
                            draggable: false,
                            attributes: {
                                id: 'unsubscribe-block',
                            },
                            components: [
                                {
                                    tagName: 'td',
                                    attributes: {
                                        style: 'width: 50%; max-width: 100%; text-align: right; padding-right: 20px',
                                    },
                                    droppable: false,
                                    draggable: false,
                                    components: [
                                        {
                                            tagName: 'a',
                                            droppable: false,
                                            draggable: false,
                                            attributes: {
                                                style: 'text-decoration: none; color: #9B9B9B',
                                                href: '{{sortment_unsub_url}}',
                                            },
                                            components: [
                                                {
                                                    tagName: 'p',
                                                    content: 'Unsubscribe',
                                                    editable: true,
                                                    droppable: false,
                                                    draggable: false,
                                                    type: 'text',
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    tagName: 'td',
                                    attributes: {
                                        style: 'width: 50%; padding-left: 20px',
                                        class: 'unsubscribe-preferences',
                                    },
                                    droppable: false,
                                    draggable: false,
                                    components: [
                                        {
                                            tagName: 'a',
                                            droppable: false,
                                            draggable: false,
                                            attributes: {
                                                style: 'text-decoration: none; color: #9B9B9B',
                                                href: '{{sortment_unsub_preference_url}}',
                                            },
                                            components: [
                                                {
                                                    tagName: 'p',
                                                    content:
                                                        'Unsubscribe preferences',
                                                    editable: true,
                                                    droppable: false,
                                                    draggable: false,
                                                    type: 'text',
                                                },
                                            ],
                                        },
                                    ],
                                    traits: [
                                        {
                                            type: 'toggle',
                                            name: 'toggle-trait',
                                            label: 'Unsubscribe Preferences',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            },
        });

        // Add the new component to the block manager
        grapesEditor.BlockManager.add('unsubscribe-links-block', {
            label: 'Unsubscribe Block',
            media: `<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2" d="M32.375 8.09375V28.9062C32.375 29.2129 32.2532 29.507 32.0363 29.7238C31.8195 29.9407 31.5254 30.0625 31.2188 30.0625H5.78125C5.47459 30.0625 5.1805 29.9407 4.96366 29.7238C4.74682 29.507 4.625 29.2129 4.625 28.9062V8.09375C4.625 7.78709 4.74682 7.493 4.96366 7.27616C5.1805 7.05932 5.47459 6.9375 5.78125 6.9375H31.2188C31.5254 6.9375 31.8195 7.05932 32.0363 7.27616C32.2532 7.493 32.375 7.78709 32.375 8.09375Z" fill="#BDBDBD"/>
                <path d="M31.2188 5.78125H5.78125C5.16794 5.78125 4.57974 6.02489 4.14607 6.45857C3.71239 6.89224 3.46875 7.48044 3.46875 8.09375V28.9062C3.46875 29.5196 3.71239 30.1078 4.14607 30.5414C4.57974 30.9751 5.16794 31.2188 5.78125 31.2188H31.2188C31.8321 31.2188 32.4203 30.9751 32.8539 30.5414C33.2876 30.1078 33.5312 29.5196 33.5312 28.9062V8.09375C33.5312 7.48044 33.2876 6.89224 32.8539 6.45857C32.4203 6.02489 31.8321 5.78125 31.2188 5.78125ZM31.2188 28.9062H5.78125V8.09375H31.2188V28.9062Z" fill="#BDBDBD"/>
                </svg>
            `,
            content: { type: 'unsubscribe-links' },
        });

        // INFO - This is to add images added in the template to asset manager
        // while editing a saved template
        const imagesSrcs = getImageLinksFromTemplate(templateDetail?.html);
        if (imagesSrcs?.length) {
            grapesEditor.AssetManager.add(imagesSrcs);
        }
        setEditor(grapesEditor);
        // INFO - Adding editor in the useEffect dependency causes issues with the
        // template editor
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateDetail?.html]);

    useEffect(() => {
        // INFO - When a new asset is uploaded add it to the asset manager
        if (newAsset) {
            editor?.AssetManager.add(newAsset);
        }
    }, [editor, newAsset]);

    return (
        <Box className="relative">
            <Box className="absolute z-10 justify-end px-2 text-white rounded-lg left-1/3">
                <TemplateActions
                    title={templateDetail?.name ?? ''}
                    placeholder={t(
                        'campaigns_builder_editor.title.placeholder',
                    )}
                    onTitleChange={onTitleChange}
                    onAction={handleHTMLActions}
                />
            </Box>

            <Box id="html-editor" ref={editorContainer} className="my-2"></Box>
        </Box>
    );
};

export default React.memo(HTMLEditor);
