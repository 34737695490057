import TemplateContentPreview from '@components/Campaigns/Builder/Steps/CampaignBuilderContent/SetupContent/TemplateContentPreview';
import { useCampaignInitialData } from '@components/Campaigns/useCampaignInitialData';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { useJourneySchemaFields } from '@components/Journeys/Builder/useJourneySchemaFields';
import { useGetJourneyNodeParams } from '@hooks/useJourney';
import { useLocale } from '@hooks/useLocale';
import { useGetTemplateById } from '@hooks/useTemplate';
import { type Campaign } from '@lightdash/common';
import { Box, Button, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    CampaignProvider,
    type TemplateDetails,
} from '@providers/CampaignProvider';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ButtonVariant } from '../../../../../../../mantineTheme';
import ActionTitle from '../../ActionTitle';
import JourneyContentSetupManagerModal from './SetupManager';

interface CampaignActionContentProps {
    communicationDetails: Campaign['communicationDetails'] | undefined;
    templateDetails: Campaign['templateDetails'] | undefined;
    setJourneyContentPayload: (
        content: Partial<TemplateDetails> | null,
        communicationDetails: Campaign['communicationDetails'] | undefined,
    ) => void;
    nodeId: string;
}

const CampaignActionContent: React.FC<CampaignActionContentProps> = ({
    communicationDetails,
    templateDetails,
    setJourneyContentPayload,
    nodeId,
}) => {
    const { t } = useLocale();

    const { journeyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const { fieldsWithSuggestions, convertSchema } = useJourneySchemaFields();
    const { mutateAsync, isLoading: isJourneyNodeParamsLoading } =
        useGetJourneyNodeParams(nodeId);

    const getJourneyNodeParams = useCallback(async () => {
        const { config } = journeyPayload;
        if (!config) return;
        const result = await mutateAsync(journeyPayload);
        convertSchema(result);
    }, [convertSchema, journeyPayload, mutateAsync]);

    useEffect(() => {
        void getJourneyNodeParams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        data: templateMetadata,
        isInitialLoading: isTemplateMetadataLoading,
    } = useGetTemplateById(templateDetails?.id ?? '');

    const [
        opened,
        { open: openContentSetupManager, close: closeContentSetupManager },
    ] = useDisclosure(false);

    const renderContentElement = useMemo(() => {
        // Case where a communication channel is selected but no template is selected
        if (
            !templateDetails ||
            isEmpty(templateDetails) ||
            !templateDetails.id
        ) {
            return (
                <Button
                    variant={ButtonVariant.DEFAULT}
                    className="w-fit"
                    onClick={openContentSetupManager}
                >
                    {/* {t('journey_builder.channel_element_select_channel_btn')} */}
                    Setup
                </Button>
            );
        }

        // Case where a communication channel and a template is selected but the template metadata for preview is still loading
        if (isTemplateMetadataLoading || isJourneyNodeParamsLoading)
            return (
                <Box className="p-3">
                    <SkeletonLoader height={70} />
                </Box>
            );

        // Case where a communication channel and a template is selected and the template metadata is loaded but empty
        if (!templateMetadata) return null;

        // Case where a communication channel and a template is selected and the template metadata is loaded
        return (
            <Box className="flex justify-end w-full">
                <TemplateContentPreview templateMetadata={templateMetadata} />
            </Box>
        );
    }, [
        openContentSetupManager,
        templateMetadata,
        isTemplateMetadataLoading,
        templateDetails,
        isJourneyNodeParamsLoading,
    ]);

    const initialState = useCampaignInitialData(undefined);

    return (
        <>
            <Stack>
                <ActionTitle
                    title={t('journey_builder.content_element_title')}
                />
                {!communicationDetails || !communicationDetails.id ? (
                    <Button
                        variant={ButtonVariant.DEFAULT}
                        className="w-fit"
                        onClick={openContentSetupManager}
                        disabled
                    >
                        {t(
                            'journey_builder.channel_element_select_channel_btn_disabled',
                        )}
                    </Button>
                ) : (
                    <>
                        <CampaignProvider
                            isNewMode={true}
                            isEditMode={true}
                            initialState={{
                                ...initialState,
                                showFooterButtons: { next: true, back: true },
                                campaignPayload: {
                                    ...initialState.campaignPayload,
                                    communicationDetails: communicationDetails,
                                    templateDetails: templateDetails!,
                                },
                            }}
                            uuid={''}
                        >
                            {renderContentElement}
                            <JourneyContentSetupManagerModal
                                opened={opened}
                                closeContentSetupManager={
                                    closeContentSetupManager
                                }
                                templateDetails={templateDetails}
                                setJourneyContentPayload={
                                    setJourneyContentPayload
                                }
                                communicationDetails={communicationDetails}
                                fields={fieldsWithSuggestions}
                            />
                        </CampaignProvider>
                    </>
                )}
            </Stack>
        </>
    );
};

export default React.memo(CampaignActionContent);
