import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import {
    FieldType,
    type JourneyDataSchema,
    type RelationTableType,
} from '@lightdash/common';
import { useCallback, useState } from 'react';

/**
 * Custom hook to convert JourneyDataSchema to FieldsWithSuggestions.
 * @returns The fields with suggestions and a function to convert schema.
 */
export const useJourneySchemaFields = () => {
    const [fieldsWithSuggestions, setFieldsWithSuggestions] =
        useState<FieldsWithSuggestions>({});

    const convertSchema = useCallback((schema: JourneyDataSchema) => {
        const fields: FieldsWithSuggestions = {};

        for (const tableName in schema.tables) {
            if (schema.tables.hasOwnProperty(tableName)) {
                const table = schema.tables[tableName];

                for (const dimensionName in table.dimensions) {
                    if (table.dimensions.hasOwnProperty(dimensionName)) {
                        const dimension = table.dimensions[dimensionName];
                        const fieldId = `${tableName}_${dimensionName}`;

                        fields[fieldId] = {
                            fieldType: FieldType.DIMENSION,
                            type: dimension.type,
                            name: dimensionName,
                            label: dimension.label,
                            table: tableName,
                            tableLabel: table.label,
                            sql: `${tableName}.${dimensionName}`,
                            hidden: false,
                            suggestions: [],
                            tableType:
                                table.type as unknown as RelationTableType,
                        };
                    }
                }
            }
        }

        setFieldsWithSuggestions(fields);
    }, []);

    return { fieldsWithSuggestions, convertSchema };
};
