import { sortmentApi } from '@api/index';
import {
    type ApiCampaign,
    type ApiCampaignList,
    type ApiError,
    type ApiSuccessEmpty,
    type CampaignCreateRequest,
    type CampaignList,
    type CampaignUpdateRequest,
    type CampaignWithAudienceDetails,
    type TestEmailRequest,
    type UtmKeyMapping,
} from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { LIST_REFETCH_TIME } from '@utils/constants';
import { useHistory, useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

// Fetch Campaign List
const getCampaignList = async (
    perPage: number,
    currentPage: number,
    query: string,
    projectId?: string,
) =>
    sortmentApi<ApiCampaignList>({
        url: `/projects/${projectId}/campaigns?perPage=${perPage}&currentPage=${currentPage}&${query}`,
        method: 'GET',
        body: undefined,
    });
interface UseCampaignsProps {
    perPage: number;
    currentPage: number;
    query?: string;
    polling: boolean;
}
export const useCampaigns = ({
    perPage,
    currentPage,
    query,
    polling = true,
}: UseCampaignsProps) => {
    const params = useParams<{ projectUuid?: string }>();
    const audiences = useQuery<ApiCampaignList, ApiError, CampaignList>({
        queryKey: [
            QueryKeys.GET_CAMPAIGNS,
            params,
            perPage,
            currentPage,
            query,
        ],
        refetchInterval: polling ? LIST_REFETCH_TIME : false,
        queryFn: () =>
            getCampaignList(
                perPage,
                currentPage,
                query || '',
                params?.projectUuid || '',
            ),
        enabled: !!params?.projectUuid,
        keepPreviousData: true,
    });

    return audiences;
};

// Fetch Campaign By Id
const getCampaignById = async (campaignId?: string, projectId?: string) =>
    sortmentApi<ApiCampaign>({
        url: `/projects/${projectId}/campaigns/${campaignId}`,
        method: 'GET',
        body: undefined,
    });

export const useGetCampaignById = (campaignId?: string) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const data = useQuery<ApiCampaign, ApiError, CampaignWithAudienceDetails>({
        queryKey: [QueryKeys.GET_CAMPAIGN_BY_ID, campaignId],
        queryFn: () => getCampaignById(campaignId, projectUuid),
        enabled: !!campaignId && !!projectUuid,
    });
    return data;
};

// Create Campaign
const CreateCampaign = async (data: CampaignCreateRequest, projectId: string) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/campaigns`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useCreateCampaign = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastError } = useNotify();
    const { t } = useLocale();

    const response = useMutation<
        ApiSuccessEmpty,
        ApiError,
        CampaignCreateRequest
    >(
        (payload: CampaignCreateRequest) =>
            CreateCampaign(payload, projectUuid),
        {
            mutationKey: [QueryKeys.CREATE_CAMPAIGN],
            onError: (error) => {
                showToastError({
                    title: t('campaign.create.error'),
                    subtitle: error.error.message,
                });
            },
        },
    );
    return response;
};

// Update Campaign
const UpdateCampaign = async (
    data: CampaignUpdateRequest,
    projectId: string,
    campaignId: string,
) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/campaigns/${campaignId}`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useUpdateCampaign = (
    campaignId: string,
    redirectOnSuccess: boolean = false,
) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const history = useHistory();
    const queryClient = useQueryClient();
    return useMutation<ApiSuccessEmpty, ApiError, CampaignUpdateRequest>(
        (payload: CampaignUpdateRequest) =>
            UpdateCampaign(payload, projectUuid, campaignId),
        {
            mutationKey: [QueryKeys.UPDATE_CAMPAIGN, campaignId, projectUuid],
            onSuccess: async () => {
                showToastSuccess({
                    title: 'Success! Your campaign is updated.',
                });
                void queryClient.invalidateQueries([
                    QueryKeys.GET_CAMPAIGN_BY_ID,
                    campaignId,
                ]);
                await queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
                if (redirectOnSuccess) {
                    history.push('/projects/' + projectUuid + '/campaigns');
                }
            },
            onError: (error) => {
                showToastError({
                    title: 'Error! Your campaign is not updated.',
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const triggerCampaign = async (campaignId: string, projectUuid: string) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/campaigns/${campaignId}/trigger`,
        method: 'POST',
        body: undefined,
    });

export const useTriggerCampaign = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<ApiSuccessEmpty, ApiError, { campaignId: string }>(
        ({ campaignId }) => triggerCampaign(campaignId, projectUuid), // Removed extra parentheses
        {
            mutationKey: [QueryKeys.TRIGGER_CAMPAIGN],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('campaign.trigger.success'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('campaign.trigger.error'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const deleteCampaign = async (campaignId: string, projectUuid: string) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/campaigns/${campaignId}`,
        method: 'DELETE',
        body: undefined,
    });

export const useDeleteCampaign = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<ApiSuccessEmpty, ApiError, { campaignId: string }>(
        ({ campaignId }) => deleteCampaign(campaignId, projectUuid), // Removed extra parentheses
        {
            mutationKey: [QueryKeys.DELETE_CAMPAIGN],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('campaign.delete.success'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('campaign.delete.error'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const triggerTestCampaign = async (
    projectId: string,
    providerId: string,
    integrationId: string,
    payload: TestEmailRequest,
) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectId}/communications/providers/${providerId}/integrations/${integrationId}/test`,
        method: 'POST',
        body: JSON.stringify(payload),
    });

export const useTestCampaign = (providerId: string, integrationId: string) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<
        ApiSuccessEmpty,
        ApiError,
        { payload: TestEmailRequest }
    >(
        ({ payload }) =>
            triggerTestCampaign(
                projectUuid,
                providerId,
                integrationId,
                payload,
            ),
        {
            mutationKey: [QueryKeys.TRIGGER_TEST_CAMPAIGN],
            onSuccess: async () => {
                showToastSuccess({
                    title: t(
                        'campaigns_builder.send_test_communication_success',
                    ),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t(
                        'campaigns_builder.send_test_communication_failed',
                    ),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const getUtmKeys = ({ projectUuid }: { projectUuid: string }) => {
    return sortmentApi<UtmKeyMapping[]>({
        url: `/projects/${projectUuid}/communications/utm-keys`,
        method: 'GET',
        body: undefined,
    });
};

export const useGetUtmKeys = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();

    return useQuery<UtmKeyMapping[], ApiError>({
        queryKey: [QueryKeys.GET_UTM_KEYS, projectUuid],
        queryFn: () => getUtmKeys({ projectUuid }),
        enabled: Boolean(projectUuid),
    });
};

const createUtmKey = ({
    projectUuid,
    body,
}: {
    projectUuid: string;
    body: UtmKeyMapping;
}) => {
    return sortmentApi<UtmKeyMapping[]>({
        url: `/projects/${projectUuid}/communications/utm-keys`,
        method: 'POST',
        body: JSON.stringify(body),
    });
};

export const useCreateUtmKey = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const queryClient = useQueryClient();

    return useMutation<UtmKeyMapping[], ApiError, UtmKeyMapping>({
        mutationKey: [QueryKeys.CREATE_UTM_KEYS, projectUuid],
        mutationFn: (body: UtmKeyMapping) =>
            createUtmKey({ projectUuid, body }),
        onSuccess: async () => {
            await queryClient.invalidateQueries([
                QueryKeys.GET_UTM_KEYS,
                projectUuid,
            ]);
        },
    });
};
