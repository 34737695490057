import { sortmentApi } from '@api/index';
import { type ApiError, type Organization } from '@lightdash/common';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';

const getOrganization = async () =>
    sortmentApi<Organization>({
        url: `/org`,
        method: 'GET',
        body: undefined,
    });

export const useOrganization = (
    useQueryOptions?: UseQueryOptions<Organization, ApiError>,
) =>
    useQuery<Organization, ApiError>({
        queryKey: [QueryKeys.ORGANIZATION],
        queryFn: getOrganization,
        ...useQueryOptions,
    });
