import { useLocale } from '@hooks/useLocale';
import {
    Box,
    Flex,
    Select as MantineSelect,
    Stack,
    Text,
    type SelectItem as SelectItemType,
    type SelectProps as MantineSelectProps,
} from '@mantine/core';
import { X } from '@phosphor-icons/react';
import React, { forwardRef } from 'react';
import { Check, ChevronDown } from 'react-feather';
import InputErrorText from '../InputErrorText';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    label: string;
}
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, ...others }: ItemProps, ref) => (
        <Box ref={ref} {...others}>
            <Box className="flex justify-between items-center gap-1 truncate max-w-[330px] ">
                <Text className="text-sm font-medium text-gray-800">
                    {label}
                </Text>

                <Check
                    size={13}
                    strokeWidth={2.5}
                    color={'rgb(var(--color-gray-600))'}
                    className="selected__item--icon-selected"
                />
            </Box>
        </Box>
    ),
);
interface SelectProps extends MantineSelectProps {
    SelectItem?: React.ReactNode;
    readOnlyClasses?: string;
    newOptionLabel?: string;
}

const Select = React.forwardRef<HTMLInputElement, SelectProps>(
    (
        {
            error,
            readOnly,
            readOnlyClasses,
            newOptionLabel,
            clearable,
            ...rest
        },
        ref,
    ) => {
        const { t } = useLocale();
        // INFO - Select does not support readonly attribute
        if (readOnly) {
            const selectedValue = rest.data.find(
                (option) => (option as SelectItemType).value === rest.value,
            ) as SelectItemType;
            if (selectedValue) {
                return (
                    <Box
                        className={`bg-gray-50 p-2 rounded-lg ${readOnlyClasses}`}
                    >
                        <Text className="text-gray-800">
                            {selectedValue.label}
                        </Text>
                    </Box>
                );
            }
        }
        return (
            <Stack spacing={'xs'}>
                <MantineSelect
                    rightSection={
                        clearable && rest.value ? (
                            <X
                                color={'rgb(var(--color-gray-600))'}
                                className="selected__item--icon-selected cursor-pointer"
                                weight="bold"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    rest.onChange?.('');
                                }}
                            />
                        ) : (
                            <ChevronDown
                                size={13}
                                strokeWidth={2.5}
                                color={'rgb(var(--color-gray-600))'}
                                className="selected__item--icon-selected"
                            />
                        )
                    }
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    itemComponent={SelectItem}
                    ref={ref}
                    getCreateLabel={(query) => {
                        return (
                            <Flex>
                                <Flex
                                    justify={'center'}
                                    align={'center'}
                                    className="h-5 border rounded-md bg-blue-500 text-white px-1 mr-1 text-xs"
                                >
                                    {newOptionLabel ?? t('common.new')}
                                </Flex>
                                <Text>{query}</Text>
                            </Flex>
                        );
                    }}
                    {...rest}
                />
                {error && <InputErrorText value={error} />}
            </Stack>
        );
    },
);

export default React.memo(Select);
