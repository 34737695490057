import { BlobStorageTypeForms } from '@components/BlobStorage/blobConfig';
import { BlobStorageType } from '@lightdash/common';
import React from 'react';

interface BlobStorageFormsProps {
    disabled: boolean;
    selectedBlob: BlobStorageType;
}

const BlobStorageForms: React.FC<BlobStorageFormsProps> = ({
    disabled,
    selectedBlob,
}) => {
    const BlobStorageForm =
        (selectedBlob && BlobStorageTypeForms[selectedBlob]) ||
        BlobStorageTypeForms[selectedBlob] ||
        BlobStorageType.S3;

    return (
        <div>
            <BlobStorageForm disabled={disabled} />
        </div>
    );
};

export default BlobStorageForms;
