import {
    DimensionType,
    FieldType,
    RelationTableType,
    type FilterableDimension,
    type JourneyEventMapperSchema,
} from '@lightdash/common';
import { useMemo } from 'react';

const useJourneyEventsFilterableFields = (
    journeyEvents: JourneyEventMapperSchema[] | undefined,
): FilterableDimension[] => {
    return useMemo(() => {
        if (!journeyEvents) return [];
        return journeyEvents.map((event) => ({
            fieldId: event.eventName,
            label: event.label ?? event.eventName,
            type: DimensionType.EVENT,
            fieldType: FieldType.DIMENSION,
            table: event.source,
            tableLabel: event.sourceLabel ?? event.source,
            sql: '',
            name: event.eventName,
            hidden: false,
            tableType: RelationTableType.EVENT,
        }));
    }, [journeyEvents]);
};

export default useJourneyEventsFilterableFields;
