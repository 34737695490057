import { useLocale } from '@hooks/useLocale';
import {
    AudienceStatus,
    QueryGenerationStrategy,
    type ApiSqlQueryResults,
    type Audience,
    type AudiencePreviewConfig,
    type AudiencePreviewPayload,
    type DimensionType,
} from '@lightdash/common';
import { Button, Select } from '@mantine/core';
import { useAudienceContext } from '@providers/AudienceProvider';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonVariant } from '../../mantineTheme';
import AudiencePreviewModal from './AudiencePreview/AudiencePreviewData';
const UserSelectionWithPreview: React.FC<{
    opened: boolean;
    open: () => void;
    close: () => void;
    previewData: ApiSqlQueryResults['rows'];
    totalResultCount: number;
    fields: Record<string, { type: DimensionType }>;
    audienceData: Audience | undefined;
    getAudiencePayload: AudiencePreviewPayload;
    initialColumns?: AudiencePreviewConfig;
    handlePreview: (fields: string[]) => Promise<void>;
}> = ({
    opened,
    close,
    previewData,
    fields,
    totalResultCount,
    audienceData,
    getAudiencePayload,
    initialColumns,
    handlePreview,
}) => {
    const history = useHistory();
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();
    const { t } = useLocale();
    const { actions, state } = useAudienceContext((context) => context);
    const { initialAudiencePayload, audiencePayload, isValidQuery } = state;
    const {
        createActivateAudience,
        updateActivateAudience,
        setInitialAudienceData,
        setAudiencePreviewConfigData,
        setUserAlias,
    } = actions;
    const { userAlias } = state.audiencePayload;
    const generationStrategy = state.audiencePayload.generationStrategy;

    const [handleSaveClicked, setHandleSaveClicked] = useState(false);
    useEffect(() => {
        if (handleSaveClicked) {
            history.push('/projects/' + projectUuid + '/audiences');
        }
    }, [history, initialAudiencePayload, projectUuid, handleSaveClicked]);

    const handleSaveAndPublish = useCallback(async () => {
        if (audienceData?.status === AudienceStatus.DRAFT)
            await updateActivateAudience(audienceData?.id);
        else await createActivateAudience();
        setInitialAudienceData();
        close();
        setHandleSaveClicked(true);
    }, [
        updateActivateAudience,
        createActivateAudience,
        close,
        setInitialAudienceData,
        setHandleSaveClicked,
        audienceData,
    ]);

    return (
        <AudiencePreviewModal
            getAudiencePayload={getAudiencePayload}
            totalResultCount={totalResultCount}
            opened={opened}
            close={close}
            data={previewData}
            fields={fields}
            initialColumns={initialColumns}
            generationStrategy={audiencePayload.generationStrategy}
            isEditMode={previewData ? true : false}
            onColumnChange={(columns: string[]) => {
                if (isValidQuery) {
                    setAudiencePreviewConfigData(columns);
                }
            }}
            handlePreview={handlePreview}
            isValidQuery={isValidQuery}
            showPropertySelect={true}
            footerRightSection={
                <Button
                    disabled={
                        generationStrategy === QueryGenerationStrategy.MANUAL &&
                        !userAlias
                    }
                    variant={ButtonVariant.PRIMARY}
                    onClick={async () => {
                        await handleSaveAndPublish();
                        close();
                    }}
                >
                    {t('audience_publish.modal_button')}
                </Button>
            }
            bottomSection={
                previewData?.length > 0 &&
                (generationStrategy === QueryGenerationStrategy.AI ||
                    generationStrategy === QueryGenerationStrategy.MANUAL) && (
                    <>
                        <br />
                        <Select
                            withinPortal={true}
                            value={userAlias}
                            label={t(
                                'audience_builder.user_selection_description',
                            )}
                            placeholder={t(
                                'audience_builder.user_selection_placeholder',
                            )}
                            data={Object.keys(previewData?.[0]).map(
                                (column) => ({
                                    value: column,
                                    label: column,
                                }),
                            )}
                            onChange={setUserAlias}
                        />
                    </>
                )
            }
        />
    );
};
export default React.memo(UserSelectionWithPreview);
