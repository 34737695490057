import { subject } from '@casl/ability';
import {
    getButtonHighlightClassName,
    modifyFieldsWithSelectionStatus,
} from '@components/Audience/Filters/FieldListItem/utils';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { useAbilityContext } from '@components/common/Authorization';
import BuilderContainer from '@components/common/BuilderContainer';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import CreateCustomMetric from '@components/CustomMetric/CreateCustomMetric';
import Page from '@components/Page/Page';
import ProfileCard from '@components/Profiles/ProfileCard';
import ProfileManagerPropertySelect from '@components/Profiles/ProfileManager/ProfileManagerPropertySelect';
import {
    toFieldsWithSuggestions,
    updateProfilePayload,
} from '@components/Profiles/utils';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import {
    useGetProfileConfig,
    useGetProfiles,
    useUpdateProfile,
} from '@hooks/useProfile';
import {
    getItemId,
    JoinType,
    type CompiledRelationTable,
} from '@lightdash/common';
import { Box, Button, Flex, Input, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    CaretLeft,
    MagnifyingGlass,
    SquareSplitHorizontal,
} from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { useRelationContext } from '@providers/RelationProvider';
import Fuse from 'fuse.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { ButtonVariant } from '../mantineTheme';

const ProfilesBuilder = () => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { getTableRelation, activeRelation } = useRelationContext();
    const [opened, { open, close }] = useDisclosure(false);
    const {
        mutate: updateProfile,
        isLoading,
        isSuccess,
    } = useUpdateProfile(projectUuid);
    const { data: config, isInitialLoading } = useGetProfileConfig(projectUuid);
    const { showToastError } = useNotify();
    const [search, setSearch] = useState('');
    const [searchDebounce, setSearchDebounce] = useState<string>('');
    const { profileId } = useParams<{ profileId: string }>();
    const { data: profile } = useGetProfiles({
        projectUuid,
        perPage: 1,
        currentPage: 1,
        data: {
            [config?.primaryKey as string]: {
                type: 'eq',
                value: profileId,
            },
        },
    });
    const ability = useAbilityContext();
    const { user } = useApp();
    const [openCustomMetric, setOpenCustomMetric] = useState<
        CompiledRelationTable | undefined
    >(undefined);
    const canManageProfiles = ability.can(
        'manage',
        subject('Profile', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const filteredRelation = getTableRelation([
        JoinType.one_one,
        JoinType.many_one,
    ]);
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });
    const fields = toFieldsWithSuggestions(
        filteredRelation,
        fieldsWithSuggestions,
    );
    const filterFields = useMemo(() => {
        const keysOfFields = Object.keys(fields);
        const filteredFields = config?.metricQuery?.dimensions
            .filter((field: string) =>
                keysOfFields.some((present_field) => present_field === field),
            )
            .filter((fieldId) => fieldId);
        return filteredFields;
    }, [fields, config]);

    useDebounce(
        () => {
            setSearchDebounce(search);
        },
        300,
        [search],
    );

    const filterItems = useCallback(
        (data: Record<string, unknown>) => {
            let fieldsData = data ? Object.entries(data) : [];

            if (searchDebounce !== '') {
                const flatData = data
                    ? Object.entries(data).map(([key, value]) => ({
                          key,
                          id: fieldsWithSuggestions[key]?.label,
                          value: value?.toString(),
                      }))
                    : [];

                const fuse = new Fuse(flatData, {
                    keys: ['id', 'value'],
                    threshold: 0.3,
                });

                fieldsData = fuse
                    .search(searchDebounce)
                    .map((res) => [res.item.key, res.item.value]);
            }
            if (config)
                return fieldsData.filter((res) =>
                    config.metricQuery?.dimensions.includes(res[0]),
                );
            return fieldsData;
        },
        [searchDebounce, fieldsWithSuggestions, config],
    );

    const history = useHistory();
    const handleBack = useCallback(() => {
        history.goBack();
    }, [history]);

    const handleDelete = useCallback(
        (fieldToBeDelete: string) => {
            if (profile?.data?.[0]) {
                const newColumns = config?.metricQuery?.dimensions.filter(
                    (field: string) => field !== fieldToBeDelete,
                );
                if (newColumns?.length && newColumns.length > 1 && config) {
                    updateProfile(
                        updateProfilePayload({ payload: config, newColumns }),
                    );
                } else {
                    showToastError({
                        title: t('profiles_view.delete_toast_error'),
                    });
                }
            }
        },
        [profile, showToastError, t, config, updateProfile],
    );
    useEffect(() => {
        if (isSuccess) {
            close();
        }
    }, [isSuccess, close]);
    if (isInitialLoading || !config) {
        return <SuboptimalState />;
    }
    return (
        <Page
            title={t('profiles')}
            withFullHeight
            withPaddedContent
            withNavbar
            backgroundColor="gray"
            withFixedContent
        >
            <Box component="div" className="mb-3.5">
                <Flex gap={9} align="center">
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        className="p-1"
                        onClick={handleBack}
                    >
                        <CaretLeft />
                    </Button>
                    <Text className="text-lg text-gray-600">
                        {t('user_profile_page_user_id_text')}
                    </Text>
                    <Text>{'·'}</Text>
                    <Text className="font-semibold text-gray-800">
                        {profileId}
                    </Text>
                </Flex>
            </Box>
            <BuilderContainer
                isEditable={false}
                title={t('user_profile_page.subtitle')}
                withContentPadding={false}
                rightSection={
                    Object.values(fields).length > 0 &&
                    config &&
                    canManageProfiles && (
                        <ProfileManagerPropertySelect
                            fields={modifyFieldsWithSelectionStatus({
                                fields: Object.values(fields),
                                selectedFieldIds: filterFields ?? [],
                                shouldDisableChecked: true,
                            })}
                            onSubmit={(items) => {
                                updateProfile(
                                    updateProfilePayload({
                                        payload: config,
                                        newColumns: items.map((item) =>
                                            getItemId(item),
                                        ),
                                    }),
                                );
                            }}
                            isLoading={isLoading}
                            isSuccess={isSuccess}
                            targetButton={
                                <Button
                                    variant={ButtonVariant.OUTLINED}
                                    className={getButtonHighlightClassName(
                                        opened,
                                    )}
                                    leftIcon={
                                        <SquareSplitHorizontal
                                            weight={'duotone'}
                                            color={
                                                opened
                                                    ? 'rgb(var(--color-blu-800))'
                                                    : 'rgb(var(--color-gray-800))'
                                            }
                                        />
                                    }
                                >
                                    {t('audience_preview.choose_properties')}
                                </Button>
                            }
                            opened={opened}
                            close={close}
                            open={open}
                        />
                    )
                }
            >
                <Flex className="p-3.5 border-y border-shade-6 rounded-none">
                    <Input
                        icon={<MagnifyingGlass />}
                        placeholder={t('profiles_view.search_placeholder')}
                        width="100%"
                        size="xs"
                        radius="md"
                        styles={{
                            wrapper: {
                                width: '100%',
                            },
                        }}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                    />
                </Flex>

                <Box className="rounded-lg !h-[60vh] overflow-auto">
                    {profile?.data?.[0] &&
                        filterItems(profile.data[0]).map((property) => {
                            return (
                                <ProfileCard
                                    property={property}
                                    key={property[0]}
                                    handleDelete={handleDelete}
                                    primaryProperty={config?.primaryKey || ''}
                                    isLoading={isLoading}
                                    onSuccess={isSuccess}
                                />
                            );
                        })}
                </Box>
                <CreateCustomMetric
                    tableName={openCustomMetric}
                    onModalClose={() => {
                        setOpenCustomMetric(undefined);
                    }}
                    openBaseTable={undefined}
                    isViewMode={undefined}
                    isDuplicate={undefined}
                    customMetricData={undefined}
                    customMetricId={undefined}
                    disableTableChange={true}
                />
            </BuilderContainer>
        </Page>
    );
};

export default React.memo(ProfilesBuilder);
