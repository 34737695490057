import {
    CommunicationChannel as CommunicationChannelEnum,
    type CommunicationChannel,
    type ParsedVariable,
} from '@lightdash/common';
import { t as translate } from 'i18next';
import CampaignBuilderAudience from './Steps/CampaignBuilderAudience';
import CampaignBuilderChannel from './Steps/CampaignBuilderChannel';
import CampaignBuilderContent from './Steps/CampaignBuilderContent';
import LinkTracking from './Steps/CampaignBuilderContent/LinkTracking';
import PersonaliseContent from './Steps/CampaignBuilderContent/SetupContent/PersonaliseContent';
import SetupContentDetails from './Steps/CampaignBuilderContent/SetupContent/SetupContentDetails';
import TestCommunication from './Steps/CampaignBuilderContent/SetupContent/TestCommunication';
import UtmTracking from './Steps/CampaignBuilderContent/UtmTracking';
import CampaignBuilderScheduler from './Steps/CampaignBuilderScheduler';
import {
    CampaignBuilderStep,
    CampaignContentStep,
    type CampaignBuilderStepMap,
    type CampaignContentStepMap,
} from './types';

export const CampaignBuilderSteps: CampaignBuilderStepMap[] = [
    {
        key: CampaignBuilderStep.AUDIENCE,
        label: translate('campaign_builder.steps.audience.title'),
        component: CampaignBuilderAudience,
        pageHeader: translate('campaign_builder.steps.audience.description'),
    },
    {
        key: CampaignBuilderStep.CHANNEL,
        label: translate('campaign_builder.steps.channel.title'),
        component: CampaignBuilderChannel,
        pageHeader: translate('campaign_builder.steps.channel.description'),
    },
    {
        key: CampaignBuilderStep.CONTENT,
        label: translate('campaign_builder.steps.content.title'),
        component: CampaignBuilderContent,
        pageHeader: translate('campaign_builder.steps.content.description'),
    },
    {
        key: CampaignBuilderStep.SCHEDULE,
        label: translate('campaign_builder.steps.schedule.title'),
        component: CampaignBuilderScheduler,
        pageHeader: translate('campaign_builder.steps.schedule.description'),
    },
];

export const CampaignContentSteps: CampaignContentStepMap[] = [
    {
        key: CampaignContentStep.SETUP,
        label: translate('campaign_builder.content_steps.setup'),
        component: SetupContentDetails,
    },
    {
        key: CampaignContentStep.PERSONALISE,
        label: translate('campaign_builder.content_steps.personalise'),
        component: PersonaliseContent,
    },
    {
        key: CampaignContentStep.TRACK,
        label: translate('campaign_builder.content_steps.track'),
        component: LinkTracking,
    },
    {
        key: CampaignContentStep.UTM_PARAMETERS,
        label: translate('campaign_builder.content_steps.utm_parameters'),
        component: UtmTracking,
    },
    {
        key: CampaignContentStep.TEST,
        label: translate('campaign_builder.content_steps.test'),
        component: TestCommunication,
    },
];

export const getChannelBasedContentSteps = (
    channel: CommunicationChannel,
    parsedVariables: ParsedVariable[] | null | undefined,
) => {
    let steps = CampaignContentSteps;
    if (
        channel === CommunicationChannelEnum.SMS ||
        channel === CommunicationChannelEnum.WHATSAPP
    ) {
        steps = CampaignContentSteps.filter(
            (step) =>
                step.key !== CampaignContentStep.SETUP &&
                step.key !== CampaignContentStep.TRACK,
        );
    }
    if (parsedVariables && parsedVariables.length === 0) {
        steps = steps.filter(
            (step) =>
                ![
                    CampaignContentStep.TRACK,
                    CampaignContentStep.UTM_PARAMETERS,
                ].includes(step.key),
        );
    }

    return steps;
};

export const getNextContentStep = (
    activeContentStep: CampaignContentStep,
    channel: CommunicationChannel | null | undefined,
    parsedVariables: ParsedVariable[] | null | undefined,
) => {
    if (!channel) return;
    const contentSteps = getChannelBasedContentSteps(channel, parsedVariables);

    const index = contentSteps.findIndex(
        (step) => step.key === activeContentStep,
    );

    if (index < contentSteps.length - 1) {
        return contentSteps[index + 1]?.key;
    }
    return;
};

export const getPreviousContentStep = (
    activeContentStep: CampaignContentStep,
    channel: CommunicationChannel | null | undefined,
    parsedVariables: ParsedVariable[] | null | undefined,
) => {
    if (!channel) return;
    const contentSteps = getChannelBasedContentSteps(channel, parsedVariables);
    const index = contentSteps.findIndex(
        (step) => step.key === activeContentStep,
    );

    if (index > 0) {
        return contentSteps[index - 1]?.key;
    }
    return;
};
