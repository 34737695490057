import { JourneyNodeEnum } from '@components/Journeys/Builder/types';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import React, { useMemo } from 'react';
import { ControlPanel } from '../types';
import BlockConfigPanel from './BlockConfigPanel';
import BlocksListPanel from './BlocksListPanel';
import TriggerConfigPanel from './TriggerConfigPanel';
import TriggerPanel from './TriggerPanel';

const JourneyBuilderControlPanel: React.FC<{}> = () => {
    const { controlPanel, nodes } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const renderPanel = useMemo(() => {
        const { isOpen } = controlPanel;
        if (!isOpen) return null;

        const { type } = controlPanel;

        switch (type) {
            case ControlPanel.BLOCK_CONFIG: {
                const nodeId = controlPanel.nodeId;
                const nodeData = nodes.find((node) => node.id === nodeId)?.data;
                if (!nodeData || nodeData.type !== JourneyNodeEnum.BLOCK)
                    return null;
                return <BlockConfigPanel nodeId={nodeData.nodeId} />;
            }

            case ControlPanel.TRIGGERS:
                return <TriggerPanel />;

            case ControlPanel.TRIGGER_CONFIG: {
                const triggerId = controlPanel.triggerId;
                return <TriggerConfigPanel triggerId={triggerId} />;
            }

            case ControlPanel.BLOCKS_LIST:
                return <BlocksListPanel />;

            default:
                return null;
        }
    }, [controlPanel, nodes]);

    return renderPanel;
};

export default React.memo(JourneyBuilderControlPanel);
