import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import { type ContentStepComponentProps } from '@components/Campaigns/Builder/types';
import {
    getNextContentStep,
    getPreviousContentStep,
} from '@components/Campaigns/Builder/utils';
import { extractSubjectVariables } from '@components/Campaigns/utils';
import FieldSelect from '@components/common/Select/FieldSelect';
import Variables from '@components/Templates/Variables';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceType,
    CampaignFieldType,
    CommunicationChannel,
    FieldType,
    isCustomDimension,
    isTableCalculation,
    JoinType,
    type TemplateDetails,
    type TemplateVariableDetails,
} from '@lightdash/common';
import { Box, Stack, Text } from '@mantine/core';
import { useCampaignContext } from '@providers/CampaignProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { filterTablesFromRelation } from '@utils/relation';
import { t as translate } from 'i18next';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SubscriptionGroupFilter, {
    type Option,
} from './SubscriptionGroupFilter';
import { LabelIconType } from './SubscriptionGroupFilter/LabelIcon';

const SendToFieldType: Record<CommunicationChannel, string> = {
    [CommunicationChannel.EMAIL]: 'an email',
    [CommunicationChannel.SMS]: 'a phone number',
    [CommunicationChannel.WHATSAPP]: 'a phone number',
    [CommunicationChannel.SLACK]: 'a slack account',
    [CommunicationChannel.UNKNOWN]: '',
    [CommunicationChannel.ANY]: '',
};

const PersonaliseContent: React.FC<ContentStepComponentProps> = ({
    setActiveContentStep,
    activeContentStep,
    templateMetadata,
    fields,
}) => {
    const { t } = useLocale();
    const { getTableRelation, activeRelation } = useRelationContext();
    const {
        setCurrentStepCallback,
        setPreviousStepCallback,
        setSendToVariableMapping,
        setCampaignContentPayload,
    } = useCampaignContext((context) => context.actions);
    const [errors, setErrors] = useState<Record<string, string>>({
        sendTo: '',
    });

    const { campaignPayload, isEditMode, audienceCsvData } = useCampaignContext(
        (context) => context.state,
    );
    const { communicationDetails, templateDetails } = campaignPayload;

    const isValidSendToVariable = useMemo(() => {
        if (!isEmpty(campaignPayload.communicationDetails.sendTo)) {
            return true;
        }
        return false;
    }, [campaignPayload.communicationDetails.sendTo]);

    const variablesContent = useMemo((): TemplateVariableDetails => {
        const content =
            campaignPayload.templateDetails &&
            campaignPayload.templateDetails.content;
        const variableContent: TemplateVariableDetails = {
            headerVariables: undefined,
            bodyVariables: undefined,
            footerVariables: undefined,
        };
        if (content && 'subject' in content) {
            variableContent.headerVariables = extractSubjectVariables(
                content.subject,
            );
        }
        if (templateMetadata) {
            variableContent.bodyVariables =
                templateMetadata.contents[0].variables.bodyVariables;
        }

        return variableContent;
    }, [campaignPayload.templateDetails, templateMetadata]);

    useEffect(() => {
        const nextStep = getNextContentStep(
            activeContentStep,
            communicationDetails?.channel,
            templateMetadata?.contents[0].parsedVariables,
        );
        const handleNextStep = () => {
            if (!isValidSendToVariable) {
                setErrors((oldValues) => ({
                    ...oldValues,
                    sendTo: 'Please select a field',
                }));
                return;
            }

            const templateVariables = variablesContent.bodyVariables;
            const subjectVariables = variablesContent.headerVariables;
            if (
                templateDetails &&
                ((templateVariables && templateVariables.length) ||
                    (subjectVariables && subjectVariables.length))
            ) {
                const emptyMappings = [
                    ...(templateVariables ?? []),
                    ...(subjectVariables ?? []),
                ].filter(
                    (variable) =>
                        !templateDetails.mappings?.[variable]?.value &&
                        !templateDetails.mappings?.[variable]?.defaultValue,
                );
                if (emptyMappings.length) {
                    const mappingErrors = emptyMappings.reduce(
                        (accum, curr) => {
                            if (Object.keys(accum).length) {
                                return {
                                    ...accum,
                                    [curr]: translate(
                                        'common.empty_select_error',
                                    ),
                                };
                            }
                            return {
                                [curr]: translate('common.empty_select_error'),
                            };
                        },
                        {},
                    );
                    setErrors((oldValues) => ({
                        ...oldValues,
                        ...mappingErrors,
                    }));
                    return;
                }
            }

            if (nextStep) {
                setActiveContentStep(nextStep);
            }
        };

        setCurrentStepCallback({
            callback: handleNextStep,
            skipExecutionAfterCallback: Boolean(nextStep),
        });
    }, [
        activeContentStep,
        isValidSendToVariable,
        setActiveContentStep,
        setCurrentStepCallback,
        communicationDetails,
        templateDetails,
        templateMetadata,
        variablesContent.bodyVariables,
        variablesContent.headerVariables,
    ]);

    useEffect(() => {
        const prevStep = getPreviousContentStep(
            activeContentStep,
            communicationDetails?.channel,
            templateMetadata?.contents[0].parsedVariables,
        );

        const handlePrevStep = () => {
            if (prevStep) {
                setActiveContentStep(prevStep);
            }
            return;
        };

        setPreviousStepCallback({
            callback: handlePrevStep,
            skipExecutionAfterCallback: Boolean(prevStep),
        });
    }, [
        activeContentStep,
        communicationDetails?.channel,
        setActiveContentStep,
        setPreviousStepCallback,
        templateMetadata,
    ]);

    const campaignRelationData = useMemo(() => {
        if (activeRelation) {
            const allowedRelationTables = getTableRelation([
                JoinType.one_one,
                JoinType.many_one,
            ]);
            if (!allowedRelationTables) return;
            const tableIds = allowedRelationTables.map((table) => table.name);
            const filteredRelation = filterTablesFromRelation(
                activeRelation,
                tableIds,
            );
            if (!filteredRelation) return;
            return filteredRelation;
        }
    }, [activeRelation, getTableRelation]);

    //TODO: Remove this and move this to Campaign Provider
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: campaignRelationData,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });

    const varibleFields = fields ?? fieldsWithSuggestions;

    const fieldWithSuggestionInArray = useMemo(() => {
        if (
            audienceCsvData &&
            audienceCsvData?.length > 0 &&
            campaignPayload.audienceType === AudienceType.CSV
        ) {
            return Object.keys(audienceCsvData[0] as 'object').map((item) => ({
                label: item,
                value: item,
                table: campaignPayload.csvUploadDetails?.fileName,
                tableLabel: campaignPayload.csvUploadDetails?.fileName,
                uniqueIdentifier: item,
            }));
        }
        return Object.keys(varibleFields)?.map((fieldKey: string) => {
            return {
                ...varibleFields?.[fieldKey],
                uniqueIdentifier: fieldKey,
            };
        });
    }, [audienceCsvData, campaignPayload, varibleFields]);

    const groupSelectMenuItems: Option[] = useMemo(() => {
        if (
            audienceCsvData &&
            audienceCsvData?.length > 0 &&
            campaignPayload.audienceType === AudienceType.CSV
        ) {
            return Object.keys(audienceCsvData[0] as 'object').map((item) => ({
                leftLabel: campaignPayload.csvUploadDetails?.fileName,
                rightLabel: item,
                leftLabelIcon: LabelIconType.CSV,
                rightLabelIcon: undefined,
                value: item,
                divider: false,
            }));
        }
        return Object.keys(fieldsWithSuggestions)?.map((fieldKey: string) => {
            return {
                leftLabel: fieldsWithSuggestions?.[fieldKey].label,
                rightLabel: fieldsWithSuggestions?.[fieldKey].name,
                leftLabelIcon: LabelIconType.CSV,
                rightLabelIcon: undefined,
                value: fieldKey,
                divider: false,
            };
        });
        // return []
    }, [audienceCsvData, campaignPayload, fieldsWithSuggestions]);

    const handleSendToChange = (item: FieldWithSuggestions) => {
        if (!item || !item.uniqueIdentifier) return;

        setSendToVariableMapping({
            fieldKey: item.uniqueIdentifier,
            fieldType:
                isCustomDimension(item) || isTableCalculation(item)
                    ? FieldType.DIMENSION
                    : item.fieldType,
        });
    };

    const sendToVariableField: FieldWithSuggestions | undefined =
        useMemo(() => {
            if (
                !communicationDetails ||
                !communicationDetails.sendTo ||
                isEmpty(communicationDetails.sendTo)
            )
                return;

            const channel = communicationDetails.channel;
            if (!channel) return;

            const sendToValues = communicationDetails.sendTo?.[channel];

            if (!sendToValues) return;
            setErrors((oldValues) => ({
                ...oldValues,
                sendTo: '',
            }));

            return varibleFields[sendToValues];
        }, [communicationDetails, varibleFields]);

    const handleVariableChange = useCallback(
        (
            value: FieldWithSuggestions | undefined,
            key: string,
            defaultValue?: string,
        ) => {
            if (!value || !value?.uniqueIdentifier) return;
            if (errors?.[key]) {
                setErrors({
                    ...errors,
                    [key]: '',
                });
            }
            const selectedVariable = {
                [key]: {
                    value: value?.uniqueIdentifier,
                    defaultValue:
                        defaultValue ||
                        templateDetails?.mappings?.[key]?.defaultValue ||
                        '',
                    type: value?.fieldType || FieldType.DIMENSION,
                },
            };

            setCampaignContentPayload({
                mappings: {
                    ...templateDetails?.mappings,
                    ...selectedVariable,
                },
            });
        },
        [setCampaignContentPayload, templateDetails, errors],
    );

    const handleVariableDefaultValue = useCallback(
        (value: string, key: string) => {
            const selectedVariable: TemplateDetails['mappings'] = {
                [key]: {
                    value: '',
                    defaultValue: value,
                    type: CampaignFieldType.STATIC,
                },
            };
            if (templateDetails?.mappings?.[key]?.value) {
                selectedVariable[key].value =
                    templateDetails?.mappings?.[key]?.value;
            }
            if (templateDetails?.mappings?.[key]?.type) {
                selectedVariable[key].type =
                    templateDetails?.mappings?.[key]?.type;
            }
            if (errors?.[key]) {
                setErrors({
                    ...errors,
                    [key]: '',
                });
            }
            setCampaignContentPayload({
                mappings: {
                    ...templateDetails?.mappings,
                    ...selectedVariable,
                },
            });
        },
        [errors, templateDetails, setCampaignContentPayload],
    );

    return (
        <Box>
            <Stack className="gap-6 pb-12">
                {/* Send To */}
                {templateMetadata?.id && (
                    <Stack className="gap-1.5">
                        {campaignPayload.audienceType ===
                            AudienceType.WAREHOUSE &&
                            fieldWithSuggestionInArray?.length > 0 && (
                                <>
                                    <Text className="text-sm font-medium text-gray-800">
                                        {t('campaign.create.step2_send_to')}
                                    </Text>
                                    <FieldSelect
                                        size="xs"
                                        item={sendToVariableField}
                                        items={
                                            fieldWithSuggestionInArray as FieldWithSuggestions[]
                                        }
                                        onChange={handleSendToChange}
                                        placeholder={t(
                                            'campaign.create.step2.send_to_placeholder',
                                            {
                                                fieldName:
                                                    SendToFieldType[
                                                        communicationDetails?.channel ??
                                                            CommunicationChannel.EMAIL
                                                    ],
                                            },
                                        )}
                                        isDisabled={!isEditMode}
                                        error={errors.sendTo}
                                    />
                                </>
                            )}
                        {campaignPayload.audienceType === AudienceType.CSV &&
                            audienceCsvData && (
                                <SubscriptionGroupFilter
                                    value={
                                        communicationDetails.channel &&
                                        communicationDetails?.sendTo?.[
                                            communicationDetails.channel
                                        ]
                                    }
                                    onChange={(value) => {
                                        setSendToVariableMapping({
                                            fieldKey: value,
                                            fieldType: FieldType.DIMENSION,
                                        });
                                    }}
                                    error={errors.sendTo}
                                    anchorClass="!w-[20rem]"
                                    width="25rem"
                                    label={t('campaign.create.step2_send_to')}
                                    options={groupSelectMenuItems}
                                />
                            )}
                    </Stack>
                )}
                <Box>
                    <Variables
                        dimensions={
                            fieldWithSuggestionInArray as FieldWithSuggestions[]
                        }
                        variablesContent={variablesContent}
                        isDisabled={!isEditMode}
                        errors={errors}
                        onVariableChange={handleVariableChange}
                        onDefaultValueChange={handleVariableDefaultValue}
                        options={
                            campaignPayload.audienceType === AudienceType.CSV
                                ? groupSelectMenuItems
                                : undefined
                        }
                    />
                </Box>
            </Stack>
        </Box>
    );
};

export default PersonaliseContent;
