import { sortmentApi } from '@api/index';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import {
    type ApiError,
    type CompleteUserArgs,
    type LightdashUser,
} from '@lightdash/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';

const completeUserQuery = async (data: CompleteUserArgs) =>
    sortmentApi<LightdashUser>({
        url: `/user/me/complete`,
        method: 'PATCH',
        body: JSON.stringify(data),
    });

export const useUserCompleteMutation = () => {
    const queryClient = useQueryClient();
    const { showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<LightdashUser, ApiError, CompleteUserArgs>(
        completeUserQuery,
        {
            mutationKey: [QueryKeys.USER_COMPLETE],
            onSuccess: async () => {
                await queryClient.invalidateQueries([QueryKeys.USER]);
                await queryClient.invalidateQueries([QueryKeys.ORGANIZATION]);
            },
            onError: (error) => {
                showToastError({
                    title: t('use_user_complete.error_msg'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};
