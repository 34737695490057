import { useLocale } from '@hooks/useLocale';
import { Box, Text } from '@mantine/core';
import React from 'react';

const NoResultsFound: React.FC<{
    searchValue: string;
}> = ({ searchValue }) => {
    const { t } = useLocale();
    return (
        <Box className="w-full py-12 border-base rounded-lg text-center">
            <Text className="text-sm text-gray-600">
                {t('channel_settings.no_results_found', {
                    searchValue,
                })}
            </Text>
        </Box>
    );
};

export default NoResultsFound;
