import AccountSettingsSidebar from '@components/AccountSettings/Sidebar';
import { settingsListGroups } from '@components/AccountSettings/Sidebar/menuItems';
import Page from '@components/Page/Page';
import { useLocale } from '@hooks/useLocale';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

interface SettingListItemType {
    label: string;
    redirectTo: string;
    routeMatch: string;
    children: React.ReactNode;
    leftSection?: React.ReactNode;
}

const AccountSettings: React.FC<{}> = ({}) => {
    const { t } = useLocale();

    const renderSettingRoute = (
        setting: SettingListItemType,
        index: number,
    ) => (
        <Route key={index} exact path={setting.routeMatch}>
            {setting.children}
        </Route>
    );

    const renderedSettingRoutes = settingsListGroups().flatMap((group) =>
        group.map(renderSettingRoute),
    );

    return (
        <Page
            withFullHeight
            withPaddedContent
            title={t('project_settings.page_title')}
            sidebar={<AccountSettingsSidebar />}
        >
            <Switch>{renderedSettingRoutes}</Switch>
        </Page>
    );
};

export default AccountSettings;
