import FilterGroupForm from '@components/Audience/Filters/FilterGroupForm';
import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider';
import { useLocale } from '@hooks/useLocale';
import {
    addFilterRule,
    DimensionType,
    getFilterRulesFromGroup,
    type FilterRule,
    type Filters,
    type JourneyDataSchema,
} from '@lightdash/common';
import { Button, Flex, Stack, Text } from '@mantine/core';
import { CaretDown, PlusCircle } from '@phosphor-icons/react';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ButtonVariant } from '../../../../mantineTheme';
import { useJourneySchemaFields } from '../useJourneySchemaFields';
import FilterSelectWrapper from './FilterSelectWrapper';
import JourneyactiveEventField from './JourneyActiveEventField';
import JourneyEventPropertySelector from './JourneyEventPropertySelector';
import JourneyFilterRuleForm from './JourneyFilterRuleForm';
import JourneyPropertySelector from './JourneyPropertySelector';
import { type ActionEventField } from './types';
import { type JourneyProperty } from './useJourneyProperties';
import { convertToFilterableField } from './utils';

interface JourneyFiltersProps {
    journeyDataSchema: JourneyDataSchema;
    filterRules: FilterRule[];
    isEditMode: boolean;
    onChange: (value: FilterRule[]) => void;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    projectUuid: string;
    activeEventField: ActionEventField | undefined; //This field is used for the selected JourneyEvent field
    nodeId: string;
    transientEventFilterTable: string | undefined;
    setTransientEventFilterTable: ((value: string) => void) | undefined;
}

const JourneyFilters: React.FC<JourneyFiltersProps> = ({
    journeyDataSchema,
    filterRules,
    isEditMode,
    onChange,
    filters,
    setFilters,
    projectUuid,
    activeEventField,
    setTransientEventFilterTable,
}) => {
    const { fieldsWithSuggestions, convertSchema } = useJourneySchemaFields();
    const { t } = useLocale();

    const allFields = useMemo(
        () => Object.values(fieldsWithSuggestions),
        [fieldsWithSuggestions],
    );

    useEffect(() => {
        if (!journeyDataSchema || isEmpty(journeyDataSchema)) return;
        convertSchema(journeyDataSchema);
    }, [convertSchema, journeyDataSchema]);

    const addFieldRule = useCallback(
        (field: JourneyProperty) => {
            const fieldDimensiontype = field.type;

            if (
                fieldDimensiontype === DimensionType.EVENT &&
                setTransientEventFilterTable
            ) {
                setTransientEventFilterTable(field.table);
                return;
            }
            const filterableField = convertToFilterableField(field);
            const newFilterRule = addFilterRule({
                filters: {},
                field: filterableField,
            });

            setFilters(newFilterRule, false, 0);
        },
        [setFilters, setTransientEventFilterTable],
    );

    const onDeleteItem = useCallback(
        (index: number) => {
            onChange([
                ...filterRules.slice(0, index),
                ...filterRules.slice(index + 1),
            ]);
        },
        [filterRules, onChange],
    );

    const onChangeItem = useCallback(
        (item: FilterRule) => {
            onChange([item]);
        },
        [onChange],
    );

    const addEventProperty = useCallback(
        (item: JourneyProperty) => {
            const fieldDimensiontype = item.type;

            //Info: Ideally event property should not be part of event dimensions
            if (fieldDimensiontype === DimensionType.EVENT) {
                return;
            }
            const filterableField = convertToFilterableField({
                ...item,
                table: activeEventField?.table ?? item.table,
            });
            const newFilterRule = addFilterRule({
                filters,
                field: filterableField,
            });

            setFilters(newFilterRule, false, 0);
        },
        [activeEventField?.table, filters, setFilters],
    );

    if (!activeEventField && !filterRules.length) {
        return (
            <JourneyPropertySelector
                targetElement={
                    <FilterSelectWrapper isEditMode={isEditMode}>
                        <Flex className="items-center justify-between !w-full">
                            <Text className="text-sm font-normal text-gray-500">
                                Select an item
                            </Text>
                            <CaretDown
                                color={'rgb(var(--color-gray-500)'}
                                weight={'regular'}
                            />
                        </Flex>
                    </FilterSelectWrapper>
                }
                journeyDataSchema={journeyDataSchema}
                onSubmit={addFieldRule}
            />
        );
    }

    const isDimensionsEmpty =
        isEmpty(filters.dimensions) ||
        getFilterRulesFromGroup(filters.dimensions).length === 0;

    return (
        <FiltersProvider projectUuid={projectUuid}>
            {activeEventField ? (
                <Stack className="gap-2">
                    <JourneyPropertySelector
                        targetElement={
                            <JourneyactiveEventField
                                activeEventField={activeEventField}
                                isEditMode={isEditMode}
                            />
                        }
                        journeyDataSchema={journeyDataSchema}
                        onSubmit={addFieldRule}
                    />

                    <Stack
                        className={`border-l-4 border-gray-100 relative ${
                            isDimensionsEmpty ? 'pl-8' : 'pl-12'
                        }`}
                    >
                        {!isDimensionsEmpty && filters.dimensions && (
                            <>
                                <FilterGroupForm
                                    allowConvertToGroup={false}
                                    hideLine
                                    hideButtons
                                    conditionLabel="dimension"
                                    filterGroup={filters.dimensions}
                                    fields={allFields}
                                    isEditMode={isEditMode}
                                    onChange={(value) =>
                                        setFilters(
                                            {
                                                ...filters,
                                                dimensions: value,
                                            },
                                            false,
                                            0,
                                        )
                                    }
                                    onDelete={() => {}}
                                    filters={filters}
                                    setFilters={setFilters}
                                    groupIndex={0}
                                    showFieldSource={false}
                                    relation={undefined}
                                />
                            </>
                        )}
                        <JourneyEventPropertySelector
                            targetElement={
                                <Button variant={ButtonVariant.UNSTYLED}>
                                    <PlusCircle
                                        color={'rgb(var(--color-gray-700))'}
                                    />
                                    <Text className="ml-1 text-sm font-semibold text-gray-700">
                                        {t('common.property')}
                                    </Text>
                                </Button>
                            }
                            eventField={activeEventField}
                            journeyDataSchema={journeyDataSchema}
                            onSubmit={addEventProperty}
                        />
                    </Stack>
                </Stack>
            ) : (
                <Stack>
                    {filterRules.map((item, index) => (
                        <JourneyFilterRuleForm
                            isEditMode={isEditMode}
                            key={item.id}
                            filterRule={item}
                            fields={allFields}
                            onChange={(value) => onChangeItem(value)}
                            onDelete={() => onDeleteItem(index)}
                            onConvertToGroup={undefined}
                            filters={filters}
                            setFilters={setFilters}
                            groupIndex={0}
                            withinPortal={true}
                            journeyDataSchema={journeyDataSchema}
                            onSubmit={addFieldRule}
                        />
                    ))}
                </Stack>
            )}
        </FiltersProvider>
    );
};

export default React.memo(JourneyFilters);
