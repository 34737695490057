import { FontSizes, FontWeights } from '../../../../mantineTheme';
import { type ButtonInputStylesProps } from './ButtonInput.types';

export const buttonInputStyles = ({
    fontSize = FontSizes.base,
    fontWeight = FontWeights.normal,
    placeholderColor,
    textColor,
}: ButtonInputStylesProps) => ({
    input: {
        border: 'none',
        cursor: 'pointer',
        boxShadow: 'none',
        ':hover': {
            backgroundColor: 'rgb(var(--color-black)/0.02)',
            cursor: 'pointer',
            '.buttonInput__icon': {
                color: 'red',
                display: 'none',
            },
        },
        '&:focus-within': {
            backgroundColor: 'rgb(var(--color-blu-800)/0.04)',
            cursor: 'text',
            caretColor: 'rgb(var(--color-blu-800))',
        },
        '::placeholder': {
            color: placeholderColor,
            opacity: 1,
            fontWeight: FontWeights.normal,
        },
        fontSize: fontSize,
        fontWeight: fontWeight,
        color: textColor,
        paddingLeft: '0.375rem !important',
    },
});
