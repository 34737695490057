import {
    RelationTableType,
    type CompiledRelation,
    type FilterableField,
} from '@lightdash/common';

interface Props {
    fields: FilterableField[];
    tableId: string | undefined;
    activeRelation: CompiledRelation | undefined;
}

export const getTimeFrameFieldFromTable = ({
    fields,
    tableId,
    activeRelation,
}: Props) => {
    if (!fields || !tableId || !activeRelation) {
        return null;
    }

    const selectedTable = activeRelation.tables[tableId];
    if (selectedTable.type === RelationTableType.EVENT) {
        const timeframeFieldId = selectedTable.eventTimestampColumn;
        return selectedTable.dimensions[timeframeFieldId];
    }
};
