import { sortmentApi } from '@api/index';
import {
    type AIWidgetRequest,
    type AIWidgetResult,
    type ApiError,
} from '@lightdash/common';
import { useRelationContext } from '@providers/RelationProvider';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';

const createAIBuilderQuery = async (
    data: AIWidgetRequest,
    projectUuid: string,
    relationUuid: string,
) =>
    sortmentApi<AIWidgetResult>({
        url: `/projects/${projectUuid}/relations/${relationUuid}/ai/widget`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useAIBuilderMutation = () => {
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();

    const { activeRelationUuid: relationUuid } = useRelationContext();

    return useMutation<AIWidgetResult, ApiError, AIWidgetRequest>(
        (payload) => createAIBuilderQuery(payload, projectUuid, relationUuid),
        {
            mutationKey: [QueryKeys.AIBUILDER],
        },
    );
};
