import PasswordInput from '@components/common/Inputs/PasswordInput';
import { WarehouseTypes } from '@lightdash/common';
import { Anchor, Stack, TextInput } from '@mantine/core';
import { hasNoWhiteSpaces } from '@utils/fieldValidators';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const DatabricksStorageForm: React.FC<{ disabled: boolean }> = ({
    disabled,
}) => {
    const { register, setValue } = useFormContext();
    useEffect(() => {
        setValue('warehouseConfig.warehouseType', WarehouseTypes.DATABRICKS);
    }, [setValue]);
    return (
        <Stack>
            <TextInput
                label="Server host name"
                description={
                    <p>
                        Check out for more details in{' '}
                        <Anchor
                            target="_blank"
                            href="https://sortment.gitbook.io/sortment-docs/getting-started/welcome-to-sortment"
                            rel="noreferrer"
                        >
                            given documentation
                        </Anchor>
                        .
                    </p>
                }
                required
                {...register('warehouseConfig.serverHostName', {
                    validate: {
                        hasNoWhiteSpaces: hasNoWhiteSpaces('Server host name'),
                    },
                })}
                disabled={disabled}
                placeholder="xxxx.gcp.databricks.com"
                labelProps={{ style: { marginTop: '8px' } }}
            />
            <TextInput
                label="HTTP Path"
                description={
                    <p>
                        Check out for more details in{' '}
                        <Anchor
                            target="_blank"
                            href="https://sortment.gitbook.io/sortment-docs/getting-started/welcome-to-sortment"
                            rel="noreferrer"
                        >
                            given documentation
                        </Anchor>
                        .
                    </p>
                }
                required
                {...register('warehouseConfig.httpPath', {
                    validate: {
                        hasNoWhiteSpaces: hasNoWhiteSpaces('HTTP Path'),
                    },
                })}
                disabled={disabled}
                placeholder="/sql/protocolv1/o/xxxx/xxxx"
                autoComplete="new-password"
            />
            <Controller
                name="warehouseConfig.personalAccessToken"
                render={({ field }) => (
                    <PasswordInput
                        {...field}
                        label="Personal access token"
                        description={
                            <p>
                                Check out for more details in{' '}
                                <Anchor
                                    target="_blank"
                                    href="https://sortment.gitbook.io/sortment-docs/getting-started/welcome-to-sortment"
                                    rel="noreferrer"
                                >
                                    given documentation
                                </Anchor>
                                .
                            </p>
                        }
                        required
                        placeholder={disabled ? '**************' : undefined}
                        disabled={disabled}
                        autoComplete="new-password"
                    />
                )}
            />
        </Stack>
    );
};
export default DatabricksStorageForm;
