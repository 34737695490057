import { ActionIcon } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import React from 'react';

interface AddNodeButtonProps {
    onAddNodeClick: () => void;
}

const AddNodeButton: React.FC<AddNodeButtonProps> = ({ onAddNodeClick }) => {
    return (
        <ActionIcon
            radius="xl"
            color={'rgb(var(--color-blu-100))'}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onAddNodeClick();
            }}
        >
            <PlusCircle
                color={'rgb(var(--color-blu-800))'}
                className="cursor-pointer"
                size={16}
            />
        </ActionIcon>
    );
};

export default React.memo(AddNodeButton);
