import AuthorizationError from '@components/AuthorizationError';
import PageSpinner from '@components/PageSpinner';
// import ErrorState from '@compontents/common/ErrorState';
import { useOrganization } from '@hooks/organization/useOrganization';
import { useProjects } from '@hooks/useProjects';
import { OrganizationMemberRole } from '@lightdash/common';
import { useApp } from '@providers/AppProvider';
import { type ComponentProps, type FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

const AppRoute: FC<React.PropsWithChildren<ComponentProps<typeof Route>>> = ({
    children,
    ...rest
}) => {
    ('');
    const { health, user } = useApp();
    const orgRequest = useOrganization();
    const projectData = useProjects();

    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (health.isInitialLoading || orgRequest.isInitialLoading) {
                    return <PageSpinner />;
                }

                if (orgRequest.error || health.error) {
                    return (
                        // <ErrorState
                        //     error={
                        //         orgRequest.error?.error || health.error?.error
                        //     }
                        // />
                        //TODO: Add common error page
                        <>Error</>
                    );
                }
                if (!orgRequest?.data?.name) {
                    return (
                        <Redirect
                            to={{
                                pathname: '/create-organization',
                                state: { from: location },
                            }}
                        />
                    );
                }
                if (orgRequest?.data?.needsProject) {
                    return (
                        <Redirect
                            to={{
                                pathname: 'create-workspace',
                                state: { from: location },
                            }}
                        />
                    );
                }
                if (projectData.data?.length === 0) {
                    if (user.data?.role === OrganizationMemberRole.MEMBER)
                        return <AuthorizationError />;
                    return (
                        <Redirect
                            to={{
                                pathname: '/create-workspace',
                                state: { from: location },
                            }}
                        />
                    );
                }

                return children;
            }}
        />
    );
};

export default AppRoute;
