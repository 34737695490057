import {
    Button,
    Popover,
    type PopoverProps as MantinePopoverProps,
} from '@mantine/core';
import {
    DatePicker,
    type DatePickerProps as MantineDatePickerProps,
} from '@mantine/dates';
import React from 'react';

interface DateRangerPickerProps {
    popoverProps?: MantinePopoverProps;
    popoverTarget?: React.ReactNode;
    datePickerProps?: MantineDatePickerProps;
}

const DateRangerPicker: React.FC<DateRangerPickerProps> = ({
    popoverProps,
    popoverTarget,
    datePickerProps,
}) => {
    return (
        <>
            <Popover position="bottom" withArrow shadow="md" {...popoverProps}>
                <Popover.Target>
                    {popoverTarget ? (
                        popoverTarget
                    ) : (
                        <Button>Toggle popover</Button>
                    )}
                </Popover.Target>
                <Popover.Dropdown>
                    <DatePicker type="range" {...datePickerProps} />
                </Popover.Dropdown>
            </Popover>
        </>
    );
};

export default React.memo(DateRangerPicker);
