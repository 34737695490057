import { subject } from '@casl/ability';
import AIWidget from '@components/AIWidget/AIWidget';
import { getUnsavedChartVersion } from '@components/Audience/utils';
import { useAbilityContext } from '@components/common/Authorization';
import BuilderContainer from '@components/common/BuilderContainer';
import UnsavedChangesConfirmModal from '@components/common/modal/UnsavedChangesConfirmModal';
import UserCount from '@components/common/UserCount';
import PageSpinner from '@components/PageSpinner';
import useNotify from '@hooks/toaster/useNotify';
import { useAudienceCountByPayload } from '@hooks/useAudience';
import {
    useAudiencePreviewById,
    useAudiencePreviewByPayload,
} from '@hooks/useAudiencePreview';
import { useIsEqual } from '@hooks/useIsEqual';
import { useLocale } from '@hooks/useLocale';
import {
    addFilterRule,
    MessageContext,
    QueryGenerationStrategy,
    type AISQLMessage,
    type AIVisualMessage,
    type Audience,
    type AudiencePreviewPayload,
    type CompiledRelationTablePrimary,
} from '@lightdash/common';
import { Box, Button, Flex, Group, Overlay, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    ArrowRight,
    CheckCircle,
    CopySimple,
    Table,
} from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { useAudienceContext } from '@providers/AudienceProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { convertUndefinedToEmptyString } from '@utils/helpers';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { v4 as uuid4 } from 'uuid';
import { ButtonVariant, PageLoaderColor } from '../../mantineTheme';
import AudiencePreviewModal from './AudiencePreview/AudiencePreviewData';
import AudienceSchedule from './AudienceSchedule';
import { useFieldsWithSuggestions } from './Filters/FiltersCard/useFieldsWithSuggestions';
import SchedulersModal from './Scheduler/SchedulerModal';
import SQLEditor from './SQLEditor';
import UserSelectionWithPreview from './UserSelectionWithPreview';
import VisualBuilder from './VisualBuilder';

interface AudienceContainerProps {
    isEditMode: boolean;
    audienceUuid: string | undefined;
    audienceData?: Audience;
}

const AudienceContainer: React.FC<AudienceContainerProps> = ({
    isEditMode,
    audienceUuid,
    audienceData,
}) => {
    const { t } = useLocale();
    const { showToastError } = useNotify();
    const history = useHistory();
    const { user } = useApp();
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();
    const { activeRelationUuid, activeRelation } = useRelationContext();
    const location = useLocation();
    const isNewMode = location.pathname.includes('create');
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });
    const [view, { open: viewOpen, close: viewClose }] = useDisclosure(false);
    const [
        scheduleModal,
        { open: scheduleModalOpen, close: scheduleModalClose },
    ] = useDisclosure(false);

    const {
        setAudienceName,
        setAudienceDescription,
        setGenerationStategy,
        createAudience,
        updateAudience,
        setUnsavedAudienceFilter,
        setSqlQuery,
        setAudiencePreviewConfigData,
    } = useAudienceContext((context) => context.actions);

    const {
        audiencePayload,
        initialAudiencePayload,
        isValidQuery,
        isValidName,
        isCreatingAudience,
        isPublishingAudience,
    } = useAudienceContext((context) => context.state);
    const [hasChanged, setHasChanged] = useState<boolean>(true);
    const [blockedNavigationLocation, setBlockedNavigationLocation] =
        useState<string>();
    const [isSaveWarningModalOpen, saveWarningModalHandlers] = useDisclosure();
    const isEqual = useIsEqual(
        convertUndefinedToEmptyString({
            ...initialAudiencePayload,
            generationStrategy: '',
        }),
        convertUndefinedToEmptyString({
            ...audiencePayload,
            generationStrategy: '',
        }),
    );
    useEffect(() => {
        if (isEqual) {
            setHasChanged(true);
        } else {
            setHasChanged(false);
        }
    }, [audiencePayload, isEqual, initialAudiencePayload]);
    useEffect(() => {
        const checkReload = (event: BeforeUnloadEvent) => {
            if (isEditMode && hasChanged) {
                const message = t('audience_unsaved_change_confirm_message');
                event.returnValue = message;
                return message;
            }
        };
        window.addEventListener('beforeunload', checkReload);
        return () => {
            window.removeEventListener('beforeunload', checkReload);
        };
    }, [hasChanged, isEditMode, t]);
    useEffect(() => {
        if (isEditMode && hasChanged) {
            const navigationBlockFunction = (prompt: { pathname: string }) => {
                if (
                    !prompt.pathname.includes(
                        `/projects/${projectUuid}/audience/create`,
                    )
                ) {
                    setBlockedNavigationLocation(prompt.pathname);
                    saveWarningModalHandlers.open();
                    return false;
                }
                return undefined;
            };
            const unblockNavigation = history.block(navigationBlockFunction);
            return () => {
                unblockNavigation();
            };
        }
    }, [
        hasChanged,
        isEditMode,
        history,
        projectUuid,
        saveWarningModalHandlers,
    ]);
    const {
        mutateAsync: mutateAsyncPreview,
        isLoading: isPreviewDataLoading,
        data: previewData,
        isSuccess: isPreviewSuccess,
    } = useAudiencePreviewByPayload();
    const {
        mutateAsync: mutateAsyncView,
        isLoading: isViewLoading,
        data: viewData,
        isSuccess: isViewSuccess,
    } = useAudiencePreviewById();
    useEffect(() => {
        if (isPreviewSuccess) {
            const newColumns = Object.keys(previewData.fields);
            setAudiencePreviewConfigData(newColumns);
            return;
        }
        if (isViewSuccess) {
            const newColumns = Object.keys(viewData.fields);
            setAudiencePreviewConfigData(newColumns);
        }
    }, [
        previewData,
        isPreviewSuccess,
        isViewSuccess,
        setAudiencePreviewConfigData,
        viewData,
    ]);

    const {
        mutateAsync: mutateAsyncCount,
        isLoading: isCountDataLoading,
        data: countData,
        reset: resetCountData,
    } = useAudienceCountByPayload();

    const { generationStrategy, name, description, previewConfig } =
        audiencePayload;

    const [previewOpened, { open: openPreview, close: previewClose }] =
        useDisclosure(false);
    const [
        userSelectionOpened,
        { open: openUserSelection, close: closeUserSelection },
    ] = useDisclosure(false);

    const [initialAudienceCount, setInitialAudienceCount] = React.useState<
        number | null
    >(null);

    const ability = useAbilityContext();
    const canEditAudience = ability.can(
        'manage',
        subject('VisualAudience', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const canEditSQLVisualizer = ability.can(
        'manage',
        subject('SQLAudience', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const getInitialAudienceCount = useCallback(async () => {
        if (!activeRelationUuid) return;
        const response = await mutateAsyncCount({
            relationUuid: activeRelationUuid,
            payload: {},
        });
        setInitialAudienceCount(response.count);
        resetCountData();
    }, [activeRelationUuid, mutateAsyncCount, resetCountData]);

    useEffect(() => {
        void getInitialAudienceCount();
    }, [getInitialAudienceCount]);

    const getAudiencePayload = useMemo(() => {
        let payload: AudiencePreviewPayload = {};
        if (
            audiencePayload.generationStrategy ===
            QueryGenerationStrategy.AUDIENCE_BUILDER
        ) {
            payload = {
                metricQuery: audiencePayload.nestedMetricQuery,
            };
        }

        if (
            audiencePayload.generationStrategy === QueryGenerationStrategy.AI ||
            audiencePayload.generationStrategy ===
                QueryGenerationStrategy.MANUAL
        ) {
            payload = {
                sqlQuery: audiencePayload.sqlQuery,
            };
        }
        return payload;
    }, [
        audiencePayload.generationStrategy,
        audiencePayload.nestedMetricQuery,
        audiencePayload.sqlQuery,
    ]);

    const handleFetchAudienceCount = useCallback(async () => {
        if (!activeRelationUuid) return;

        await mutateAsyncCount({
            relationUuid: activeRelationUuid,
            payload: getAudiencePayload,
        });
    }, [activeRelationUuid, getAudiencePayload, mutateAsyncCount]);

    const handleNameChange = useCallback(
        (value: string) => {
            setAudienceName(value);
        },
        [setAudienceName],
    );

    const handleAudienceDescription = useCallback(
        (value: string) => {
            setAudienceDescription(value);
        },
        [setAudienceDescription],
    );

    const toggleGenerationStrategy = useCallback(() => {
        setAudiencePreviewConfigData([]);

        setGenerationStategy(
            generationStrategy === QueryGenerationStrategy.AUDIENCE_BUILDER
                ? QueryGenerationStrategy.MANUAL
                : QueryGenerationStrategy.AUDIENCE_BUILDER,
        );
    }, [
        generationStrategy,
        setGenerationStategy,
        setAudiencePreviewConfigData,
    ]);
    const [handleDraft, setHandleDraft] = useState(false);
    useEffect(() => {
        if (handleDraft) {
            history.push('/projects/' + projectUuid + '/audiences');
        }
    }, [hasChanged, handleDraft, history, projectUuid]);
    const handleSave = useCallback(async () => {
        if (!isValidName) {
            showToastError({
                title: t(
                    'audience_create.please_enter_a_valid_name_to_save_the_audience',
                ),
            });
            return;
        }
        if (
            !isValidQuery &&
            audiencePayload.generationStrategy ===
                QueryGenerationStrategy.AUDIENCE_BUILDER
        ) {
            showToastError({
                title: t(
                    'audience_preview.please_add_a_filter_to_save_the_audience',
                ),
            });
            return;
        }
        try {
            if (audienceUuid && !isNewMode) {
                await updateAudience(audienceUuid);
            } else {
                await createAudience();
            }
            setHasChanged(false);
            setHandleDraft(true);
        } catch (err) {
            console.log(err);
        }
    }, [
        createAudience,
        isValidName,
        t,
        showToastError,
        isValidQuery,
        audiencePayload,
        updateAudience,
        audienceUuid,
        isNewMode,
    ]);
    const handlePreviewData = useCallback(async () => {
        await handleFetchAudienceCount();

        await mutateAsyncPreview({
            relationUuid: activeRelationUuid,
            data: getAudiencePayload,
            fields: audiencePayload.previewConfig
                ? audiencePayload.previewConfig?.previewFields
                : [],
        });
    }, [
        mutateAsyncPreview,
        activeRelationUuid,
        getAudiencePayload,
        handleFetchAudienceCount,
        audiencePayload.previewConfig,
    ]);

    const getAudiencePreviewData = useCallback(
        async (previewDataColumns: string[]) => {
            if (isEditMode) {
                await mutateAsyncPreview(
                    {
                        relationUuid: activeRelationUuid,
                        data: getAudiencePayload,
                        fields: previewDataColumns,
                    },
                    {
                        onSuccess: (response) => {
                            setAudiencePreviewConfigData(
                                Object.keys(response.fields),
                            );
                        },
                    },
                );
                return;
            }
            if (audienceUuid)
                await mutateAsyncView(
                    {
                        fields: previewDataColumns,
                        audienceId: audienceUuid,
                    },
                    {
                        onSuccess: (response) => {
                            setAudiencePreviewConfigData(
                                Object.keys(response.fields),
                            );
                        },
                    },
                );
        },
        [
            mutateAsyncView,
            mutateAsyncPreview,
            activeRelationUuid,
            audienceUuid,
            isEditMode,
            getAudiencePayload,
            setAudiencePreviewConfigData,
        ],
    );

    const handlePreview = useCallback(
        async (fields: string[]) => {
            await handleFetchAudienceCount();
            if (!isValidQuery) {
                const baseTable = activeRelation?.baseTable ?? '';
                const userId = (
                    activeRelation?.tables[
                        baseTable
                    ] as CompiledRelationTablePrimary
                ).userId;
                const fieldId = `${baseTable}_${userId.replaceAll('.', '__')}`;
                const defaultMetric = addFilterRule({
                    filters: {},
                    field: fieldsWithSuggestions[fieldId],
                    value: [],
                });
                await mutateAsyncPreview({
                    relationUuid: activeRelationUuid,
                    data: {
                        metricQuery: {
                            and: [
                                {
                                    filters: defaultMetric,
                                    additionalMetrics: [],
                                    dimensions: [],
                                    exploreName: '',
                                    limit: 500,
                                    metrics: [],
                                    sorts: [],
                                    tableCalculations: [],
                                },
                            ],
                            id: uuid4(),
                        },
                    },
                    fields: fields,
                });
                openPreview();
                return;
            }
            if (previewConfig?.previewFields) {
                await getAudiencePreviewData(previewConfig?.previewFields);
                openPreview();
            }
        },
        [
            openPreview,
            previewConfig?.previewFields,
            getAudiencePreviewData,
            isValidQuery,
            handleFetchAudienceCount,
            activeRelation,
            mutateAsyncPreview,
            activeRelationUuid,
            fieldsWithSuggestions,
        ],
    );

    const handleSaveVerify = useCallback(async () => {
        await handlePreviewData();
        openUserSelection();
    }, [openUserSelection, handlePreviewData]);

    const handleVerifyUsersModal = useCallback(async () => {
        if (!isValidName) {
            showToastError({
                title: t(
                    'audience_create.please_enter_a_valid_name_to_save_the_audience',
                ),
            });
            return;
        }
        if (isValidQuery) {
            await handleSaveVerify();
            return;
        }

        if (
            audiencePayload.generationStrategy ===
            QueryGenerationStrategy.AUDIENCE_BUILDER
        ) {
            showToastError({
                title: t(
                    'audience_preview.please_add_a_filter_to_save_the_audience',
                ),
            });
            return;
        }
        showToastError({
            title: t(
                'audience_preview.please_add_a_query_to_save_the_audience',
            ),
        });
        return;
    }, [
        isValidQuery,
        handleSaveVerify,
        isValidName,
        t,
        showToastError,
        audiencePayload,
    ]);

    const getUserCount = useMemo(() => {
        if (initialAudienceCount === null) return null;
        return (
            <Flex align="center" className="gap-1.5">
                <UserCount
                    count={initialAudienceCount}
                    formatValue={false}
                    withRightSection={false}
                />
                {isValidQuery && (
                    <>
                        <ArrowRight />
                        {isCountDataLoading ? (
                            <Text className="text-sm text-gray-500">
                                {t('audience_builder.user_count_loading')}
                            </Text>
                        ) : (
                            <>
                                {countData && (
                                    <UserCount
                                        count={countData.count}
                                        formatValue={false}
                                        withLeftSection={false}
                                    />
                                )}
                                <Button
                                    onClick={handleFetchAudienceCount}
                                    variant="default"
                                >
                                    {countData
                                        ? t(
                                              'audience_builder.user_count_again_btn',
                                          )
                                        : t('audience_builder.user_count_btn')}
                                </Button>
                            </>
                        )}
                    </>
                )}
            </Flex>
        );
    }, [
        countData,
        handleFetchAudienceCount,
        initialAudienceCount,
        isCountDataLoading,
        isValidQuery,
        t,
    ]);

    const handleAIWidgetResponse = useCallback(
        (message: AIVisualMessage | AISQLMessage) => {
            if (!message.payload) return;
            if (message.context === MessageContext.VISUAL_AUDIENCE) {
                const metricQueries = getUnsavedChartVersion(message.payload);
                setUnsavedAudienceFilter(metricQueries ?? []);
            }
            if (message.context === MessageContext.SQL_AUDIENCE) {
                setSqlQuery(message.payload.sqlQuery as string);
            }
        },
        [setUnsavedAudienceFilter, setSqlQuery],
    );

    const handleDuplicateAudience = useCallback(() => {
        if (!audienceUuid) return;

        history.push(
            `/projects/${projectUuid}/audiences/create?templateId=${audienceUuid}`,
        );
    }, [audienceUuid, history, projectUuid]);

    const renderBuilderContainerRightSection = useMemo(() => {
        if (!isEditMode) {
            return (
                canEditAudience && (
                    <Box className="flex justify-around">
                        <Button
                            className="m-2"
                            variant={ButtonVariant.OUTLINED}
                            onClick={handleDuplicateAudience}
                            leftIcon={<CopySimple />}
                        >
                            Duplicate audience
                        </Button>
                    </Box>
                )
            );
        }

        const disableButtons =
            (QueryGenerationStrategy.AUDIENCE_BUILDER
                ? false
                : !canEditSQLVisualizer) ||
            isCreatingAudience ||
            isPublishingAudience;

        return (
            <Group className="gap-2">
                {canEditSQLVisualizer && (
                    <Button
                        variant={ButtonVariant.OUTLINED_ACCENTED}
                        onClick={toggleGenerationStrategy}
                    >
                        {generationStrategy ===
                        QueryGenerationStrategy.AUDIENCE_BUILDER
                            ? t('audience.builder.visual.switch')
                            : t('audience.builder.sql.switch')}
                    </Button>
                )}
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={handleSave}
                    disabled={disableButtons}
                >
                    Save draft
                </Button>
                <Button
                    onClick={handleVerifyUsersModal}
                    disabled={disableButtons}
                    leftIcon={<CheckCircle color="white" />}
                >
                    Publish
                </Button>
            </Group>
        );
    }, [
        isEditMode,
        canEditSQLVisualizer,
        isCreatingAudience,
        isPublishingAudience,
        toggleGenerationStrategy,
        generationStrategy,
        t,
        handleSave,
        handleVerifyUsersModal,
        canEditAudience,
        handleDuplicateAudience,
    ]);
    if (isEditMode && !canEditAudience) {
        history.push('/');
    }
    return (
        <>
            <UnsavedChangesConfirmModal
                opened={isSaveWarningModalOpen}
                close={saveWarningModalHandlers.close}
                primaryActionButtonClick={() => {
                    history.block(() => {});
                    if (blockedNavigationLocation)
                        history.push(blockedNavigationLocation);
                }}
                secondaryActionButtonClick={() => {
                    saveWarningModalHandlers.close();
                }}
            />
            <BuilderContainer
                isEditable={canEditAudience}
                title={name}
                onTitleChange={handleNameChange}
                titlePlaceholder={t('audience_builder.name_placeholder')}
                description={description}
                descriptionPlaceholder={t(
                    'audience_builder.description_placeholder',
                )}
                onDescriptionChange={handleAudienceDescription}
                withContentPadding={false}
                rightSection={renderBuilderContainerRightSection}
            >
                <Flex
                    className="p-3.5 border-y border-shade-6 rounded-none bg-shade-2"
                    justify="space-between"
                    align="flex-center"
                    direction="row"
                >
                    <Flex justify="center" align="center">
                        <Box className="pr-4">{getUserCount}</Box>
                        <Button
                            loading={isCountDataLoading || isViewLoading}
                            leftIcon={<Table />}
                            variant="default"
                            onClick={() => handlePreview([])}
                        >
                            {t('audience_builder.preview_data')}
                        </Button>
                    </Flex>

                    {audienceData && !isNewMode && (
                        <AudienceSchedule
                            audienceData={audienceData}
                            scheduleModalOpen={scheduleModalOpen}
                        />
                    )}
                </Flex>

                <Box
                    className={
                        generationStrategy ===
                        QueryGenerationStrategy.AUDIENCE_BUILDER
                            ? 'p-3.5'
                            : 'ps-0.5 pb-0.5'
                    }
                >
                    {generationStrategy ===
                    QueryGenerationStrategy.AUDIENCE_BUILDER ? (
                        <VisualBuilder isEditMode={isEditMode} />
                    ) : (
                        <SQLEditor isEditMode={isEditMode} />
                    )}
                </Box>
            </BuilderContainer>
            {view && (
                <Overlay
                    className="cursor-not-allowed"
                    zIndex={30}
                    color="#000"
                    opacity={0.12}
                />
            )}

            {countData && (
                <AudiencePreviewModal
                    getAudiencePayload={getAudiencePayload}
                    totalResultCount={countData.count}
                    opened={previewOpened}
                    close={previewClose}
                    data={
                        (previewData && previewData.rows) ||
                        (viewData && viewData.rows) ||
                        []
                    }
                    fields={
                        (previewData && previewData.fields) ||
                        (viewData && viewData.fields) ||
                        {}
                    }
                    initialColumns={previewConfig}
                    generationStrategy={audiencePayload.generationStrategy}
                    isEditMode={previewData ? true : false}
                    onColumnChange={(columns: string[]) => {
                        if (isValidQuery) {
                            setAudiencePreviewConfigData(columns);
                        }
                    }}
                    handlePreview={handlePreview}
                    isValidQuery={isValidQuery}
                    showPropertySelect={true}
                    footerRightSection={
                        !countData.count && (
                            <Button
                                variant={ButtonVariant.OUTLINED}
                                onClick={() => previewClose()}
                            >
                                {t('password_modal.secondary_btn_text')}
                            </Button>
                        )
                    }
                    bottomSection={null}
                />
            )}

            {scheduleModal && audienceUuid && (
                <SchedulersModal
                    audienceData={audienceData}
                    isOpen={scheduleModal}
                    onClose={scheduleModalClose}
                />
            )}
            <UserSelectionWithPreview
                getAudiencePayload={getAudiencePayload}
                open={openUserSelection}
                opened={userSelectionOpened}
                close={closeUserSelection}
                totalResultCount={countData?.count ?? 0}
                previewData={previewData?.rows || []}
                fields={
                    (previewData && previewData.fields) ||
                    (viewData && viewData.fields) ||
                    {}
                }
                audienceData={audienceData}
                initialColumns={previewConfig}
                handlePreview={handlePreview}
            />

            <PageSpinner
                isVisible={isPreviewDataLoading}
                withOverlay
                loaderColor={PageLoaderColor.GRAY}
            />

            {isEditMode && (
                <div className="z-50 flex items-end justify-center h-full ">
                    <AIWidget
                        context={
                            generationStrategy ===
                            QueryGenerationStrategy.AUDIENCE_BUILDER
                                ? MessageContext.VISUAL_AUDIENCE
                                : MessageContext.SQL_AUDIENCE
                        }
                        onMessageReceive={(
                            message: AISQLMessage | AIVisualMessage,
                        ) => {
                            handleAIWidgetResponse(message);
                        }}
                        onIsLoading={(value) =>
                            value ? viewOpen() : viewClose()
                        }
                    />
                </div>
            )}
        </>
    );
};

export default AudienceContainer;
