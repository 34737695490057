import {
    type CommunicationChannel,
    type CreateTemplateRequest,
    type Integration,
    type ProviderTemplateDetails,
} from '@lightdash/common';
import React, { useCallback, useMemo, useReducer, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

interface TemplateBuilderContext {
    state: TemplateBuilderState;
    actions: {
        setOpenLibraryModal: (open: boolean) => void;
        setOpenImportModal: (open: boolean) => void;
        setOpenCreateTemplateModal: (open: boolean) => void;
        setTemplatePayload: (values: Partial<CreateTemplateRequest>) => void;
        setTemplateId: (templateId: string) => void;
        handleTemplateSelect: (value: ProviderTemplateDetails) => void;
        setSelectedIntegration: (value: Integration | null) => void;
        setSelectedTemplate: (value: ProviderTemplateDetails | null) => void;
    };
}

export interface TemplateBuilderState {
    openLibraryModal: boolean;
    openImportModal: boolean;
    openCreateTemplateModal: boolean;
    templatePayload: CreateTemplateRequest;
    channel: CommunicationChannel;
    templateId: string;
    initialPayload: CreateTemplateRequest;
    selectedIntegration: Integration | null;
    selectedTemplate: ProviderTemplateDetails | null;
    version: number | undefined;
}

export enum ActionType {
    SET_OPEN_LIBRARY_MODAL,
    SET_OPEN_IMPORT_MODAL,
    SET_OPEN_EMAIL_BUILDER,
    SET_OPEN_CREATE_TEMPLATE_BUILDER,
    SET_TEMPLATE_PAYLOAD,
    HANDLE_TEMPLATE_SELECT,
    SET_SELECTED_INTEGRATION,
    SET_SELECTED_TEMPLATE,
}

type Action =
    | { type: ActionType.SET_OPEN_LIBRARY_MODAL; payload: boolean }
    | { type: ActionType.SET_OPEN_IMPORT_MODAL; payload: boolean }
    | { type: ActionType.SET_OPEN_CREATE_TEMPLATE_BUILDER; payload: boolean }
    | {
          type: ActionType.SET_TEMPLATE_PAYLOAD;
          payload: Partial<CreateTemplateRequest>;
      }
    | { type: ActionType.SET_SELECTED_INTEGRATION; payload: Integration | null }
    | {
          type: ActionType.SET_SELECTED_TEMPLATE;
          payload: ProviderTemplateDetails | null;
      };

const Context = createContext<TemplateBuilderContext | undefined>(undefined);

function reducer(
    state: TemplateBuilderState,
    action: Action,
): TemplateBuilderState {
    switch (action.type) {
        case ActionType.SET_OPEN_LIBRARY_MODAL:
            return { ...state, openLibraryModal: action.payload };
        case ActionType.SET_OPEN_IMPORT_MODAL:
            return { ...state, openImportModal: action.payload };
        case ActionType.SET_OPEN_CREATE_TEMPLATE_BUILDER:
            return { ...state, openCreateTemplateModal: action.payload };
        case ActionType.SET_TEMPLATE_PAYLOAD:
            return {
                ...state,
                templatePayload: {
                    ...state.templatePayload,
                    ...action.payload,
                },
            };
        case ActionType.SET_SELECTED_INTEGRATION: {
            return {
                ...state,
                selectedIntegration: action.payload,
            };
        }
        case ActionType.SET_SELECTED_TEMPLATE: {
            return {
                ...state,
                selectedTemplate: action.payload,
            };
        }
        default:
            throw new Error('Unhandled action type');
    }
}

export const TemplateBuilderProvider: React.FC<
    React.PropsWithChildren<{
        initialState: TemplateBuilderState;
        channel: CommunicationChannel;
        templateId: string;
        handleTemplateSelectCallback: (value: ProviderTemplateDetails) => void;
    }>
> = ({
    initialState,
    children,
    channel,
    templateId,
    handleTemplateSelectCallback,
}) => {
    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const [templateUuid, setTemplateUuid] = useState<string>(templateId);

    const setOpenLibraryModal = useCallback((open: boolean) => {
        dispatch({
            type: ActionType.SET_OPEN_LIBRARY_MODAL,
            payload: open,
        });
    }, []);

    const setOpenImportModal = useCallback((open: boolean) => {
        dispatch({ type: ActionType.SET_OPEN_IMPORT_MODAL, payload: open });
    }, []);

    const setOpenCreateTemplateModal = useCallback((open: boolean) => {
        dispatch({
            type: ActionType.SET_OPEN_CREATE_TEMPLATE_BUILDER,
            payload: open,
        });
    }, []);

    const setTemplatePayload = useCallback(
        (values: Partial<CreateTemplateRequest>) => {
            dispatch({
                type: ActionType.SET_TEMPLATE_PAYLOAD,
                payload: values,
            });
        },
        [],
    );

    const setTemplateId = useCallback((value: string) => {
        setTemplateUuid(value);
    }, []);

    const setSelectedIntegration = useCallback(
        (payload: Integration | null) => {
            dispatch({
                type: ActionType.SET_SELECTED_INTEGRATION,
                payload,
            });
        },
        [],
    );

    const setSelectedTemplate = useCallback(
        (payload: ProviderTemplateDetails | null) => {
            dispatch({
                type: ActionType.SET_SELECTED_TEMPLATE,
                payload,
            });
        },
        [],
    );

    const state = useMemo(
        () => ({
            ...reducerState,
            channel,
            templateId: templateUuid,
            initialPayload: initialState.templatePayload,
        }),
        [reducerState, channel, templateUuid, initialState],
    );

    const actions = useMemo(
        () => ({
            setOpenLibraryModal,
            setOpenImportModal,
            setOpenCreateTemplateModal,
            setTemplatePayload,
            setTemplateId,
            handleTemplateSelect: handleTemplateSelectCallback,
            setSelectedIntegration,
            setSelectedTemplate,
        }),
        [
            setOpenLibraryModal,
            setOpenImportModal,
            setOpenCreateTemplateModal,
            setTemplatePayload,
            setTemplateId,
            handleTemplateSelectCallback,
            setSelectedIntegration,
            setSelectedTemplate,
        ],
    );

    const value = useMemo(
        () => ({
            state,
            actions,
        }),
        [actions, state],
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useTemplateBuilderContext<Selected>(
    selector: (value: TemplateBuilderContext) => Selected,
): Selected {
    return useContextSelector(Context, (context) => {
        if (context === undefined) {
            throw new Error(
                'useContext must be used within Template Builder Provider',
            );
        }
        return selector(context);
    });
}
