import TextInput from '@components/common/Inputs/TextInput';
import { useLocale } from '@hooks/useLocale';
import { Text } from '@mantine/core';
import { isInvalidCronExpression } from '@utils/fieldValidators';
import React, { useState, type FC } from 'react';

const CustomInputs: FC<{
    name: string;
    disabled?: boolean;
    cronExpression: string;
    // These two error types are from the two form handlers.
    // We should be removing one of them.
    error?: string;
    errors?: {
        [x: string]: any;
    };
    onChange: (value: string) => void;
}> = ({ name, disabled, cronExpression, error, errors, onChange }) => {
    const { t } = useLocale();
    const cronError = error ?? errors?.[name]?.message;
    const [cronValidationError, setCronValidationError] = useState<
        string | undefined
    >(undefined);
    return (
        <>
            <Text className="text-gray-500 block">
                {t('cron_input_custom_input_label')}
            </Text>
            <TextInput
                withAsterisk
                value={cronExpression}
                placeholder="0 9 * * 1"
                disabled={disabled}
                onChange={(e) => {
                    setCronValidationError(
                        isInvalidCronExpression('Cron expression')(
                            e.target.value,
                        ),
                    );
                    return onChange(e.target.value);
                }}
                inputWrapperOrder={['label', 'input', 'description', 'error']}
                error={cronError || cronValidationError}
            />
        </>
    );
};
export default CustomInputs;
