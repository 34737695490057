import { getLayoutedElements } from '@components/Journeys/Builder/ReactFlow/Layout/dagre';
import {
    reactFlowEdgeTypes,
    reactFlowNodeTypes,
} from '@components/Journeys/Builder/ReactFlow/utils';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import React, { useCallback } from 'react';
import ReactFlow, {
    Background,
    ConnectionLineType,
    Controls,
    type Node,
    type NodeMouseHandler,
} from 'reactflow';
import {
    ControlPanel,
    JourneyNodeEnum,
    type JourneyNodeBlockData,
    type JourneyNodeData,
    type JourneyNodeTriggerData,
} from '../types';
import { getControlPanelType } from '../utils';

const ReactFlowCanvas: React.FC<{}> = () => {
    const { nodes, controlPanel, edges } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const {
        openControlPanel,
        closeControlPanel,
        removePlaceholderNodes,
        setNodesUnselected,
    } = useJourneyBuilderContext((context) => context.actions);

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        nodes,
        edges,
    );

    const onPaneClick = () => {
        //INFO: This makes sure that the trigger placeholder is not removed when the user clicks on the canvas
        const hasOnlyTriggerPlaceholder =
            nodes.length === 1 &&
            nodes[0].data.type === JourneyNodeEnum.PLACEHOLDER &&
            nodes[0].data.placeHolderType === JourneyNodeEnum.TRIGGER;
        if (hasOnlyTriggerPlaceholder) return;

        const hasPlaceholder = nodes.some(
            (node) => node.data.type === JourneyNodeEnum.PLACEHOLDER,
        );
        const { isOpen } = controlPanel;
        if (isOpen) {
            closeControlPanel();
        }
        if (hasPlaceholder) {
            removePlaceholderNodes(); //INFO: This makes sure that the placeholder is removed when the user clicks on the canvas
        } else {
            setNodesUnselected(nodes); //INFO: This makes sure that the nodes are unselected when the user clicks on the canvas
        }
    };

    const onNodeClick: NodeMouseHandler = useCallback(
        (_event, node) => {
            const nodeData = node as Node<JourneyNodeData>;
            const controlPanelType = getControlPanelType(nodeData.data);
            if (nodeData.data.type !== JourneyNodeEnum.PLACEHOLDER) {
                removePlaceholderNodes();
            }

            switch (controlPanelType) {
                case ControlPanel.BLOCK_CONFIG:
                    openControlPanel({
                        type: controlPanelType,
                        nodeId: (nodeData.data as JourneyNodeBlockData).nodeId,
                    });
                    break;
                case ControlPanel.TRIGGER_CONFIG:
                    openControlPanel({
                        type: controlPanelType,
                        triggerId: (nodeData.data as JourneyNodeTriggerData)
                            .nodeId,
                    });
                    break;
                case ControlPanel.TRIGGERS:
                    openControlPanel({
                        type: controlPanelType,
                    });
                    break;
                default:
                    console.error(
                        'Unknown control panel type:',
                        controlPanelType,
                    );
            }
        },
        [openControlPanel, removePlaceholderNodes],
    );

    return (
        <ReactFlow
            nodes={layoutedNodes}
            edges={layoutedEdges}
            connectionLineType={ConnectionLineType.Straight}
            nodeTypes={reactFlowNodeTypes}
            edgeTypes={reactFlowEdgeTypes}
            panOnScroll={true}
            zoomOnScroll={false}
            panOnDrag={false}
            nodeOrigin={[0.5, 0.5]}
            style={{ cursor: 'default !important' }}
            onPaneClick={onPaneClick}
            onNodeClick={onNodeClick}
            nodesConnectable={false}
            zoomOnDoubleClick={false}
        >
            <Controls position="bottom-left" />
            <Background color="rgb(var(--color-dot))" gap={8} lineWidth={2} />
        </ReactFlow>
    );
};

export default React.memo(ReactFlowCanvas);
