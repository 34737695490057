import { subject } from '@casl/ability';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { useAbilityContext } from '@components/common/Authorization';
import FieldIcon from '@components/common/IconPack/FieldIcon';
import TableIcon from '@components/common/IconPack/TableIcon';
import Modal from '@components/common/modal/Modal';
import { useLocale } from '@hooks/useLocale';
import { RelationTableType } from '@lightdash/common';
import {
    Box,
    Button,
    Card,
    Flex,
    Group,
    Loader,
    Text,
    Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretRight, Trash, TrashSimple } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonVariant, GlobalStyles } from '../../mantineTheme';

interface ProfileCardProps {
    property: [string, unknown];
    handleDelete: (fieldToBeDelete: string) => void;
    primaryProperty: string;
    isLoading: boolean;
    onSuccess: boolean;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
    property,
    handleDelete,
    primaryProperty,
    isLoading,

    onSuccess,
}) => {
    const { t } = useLocale();
    const { activeRelation } = useRelationContext();
    const [openedDelete, { open: openDeleteModal, close: closeDeleteModal }] =
        useDisclosure(false);

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });
    const { user } = useApp();
    const ability = useAbilityContext();
    const projectUuid = useParams<{ projectUuid: string }>();
    const canManageProfiles = ability.can(
        'manage',
        subject('Profile', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const field = fieldsWithSuggestions[property[0]];
    const tableLabel =
        field && field.table && activeRelation?.tables[field?.table]?.label;

    useEffect(() => {
        if (onSuccess) {
            closeDeleteModal();
        }
    }, [onSuccess, closeDeleteModal]);

    if (!field) return null;
    return (
        <>
            <Card className="p-3.5 border-b border-shade-6 rounded-none flex flex-row justify-between hover:cursor-default group/item">
                <Tooltip
                    withinPortal
                    offset={-15}
                    label={
                        <Flex direction="column" gap={2}>
                            <Flex align={'center'} gap={2}>
                                <TableIcon
                                    type={
                                        field.tableType ??
                                        RelationTableType.RELATED
                                    }
                                    strokeWidth={2.5}
                                />
                                <span
                                    className={`text-sm pl-1.5 font-medium`}
                                    style={{
                                        color: GlobalStyles?.tableStyles?.[
                                            field?.tableType ??
                                                RelationTableType.RELATED
                                        ].textColor,
                                    }}
                                >
                                    {tableLabel}
                                </span>
                                <CaretRight />
                                <FieldIcon
                                    type={field.type}
                                    strokeWidth={2.5}
                                />
                                <Text className="!m-0 p-1 rounded text-sm text-gray-800 font-medium !whitespace-nowrap max-w-[180px] truncate">
                                    {field.label}
                                </Text>
                            </Flex>
                            <Text className="text-gray-600">
                                {String(property[1])}
                            </Text>
                        </Flex>
                    }
                    position="bottom-start"
                    maw={350}
                >
                    <Box>
                        <Text className="text-gray-800 border-b-2 border-dashed border-shade-4 w-fit hover:border-gray-400">
                            {field?.label}
                        </Text>
                        <Text className="text-gray-600">
                            {String(property[1])}
                        </Text>
                    </Box>
                </Tooltip>
                {canManageProfiles && (
                    <Flex align="center">
                        {property[0] !== primaryProperty && (
                            <Button
                                className="p-2 group/edit invisible group-hover/item:invisible" //Temparary disabling delete of the profiles
                                onClick={openDeleteModal}
                                variant={ButtonVariant.OUTLINED}
                            >
                                <TrashSimple />
                            </Button>
                        )}
                    </Flex>
                )}
            </Card>
            <Modal
                opened={openedDelete}
                onClose={closeDeleteModal}
                title={t('profiles_view.delete_modal_title')}
                footerRightSection={
                    <Group spacing="xs">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={closeDeleteModal}
                        >
                            {t('profiles_view.delete_modal_cancel_button')}
                        </Button>

                        <Button
                            form="add_user_to_project"
                            type="submit"
                            variant={ButtonVariant.FILLED_DESTRUCTIVE}
                            leftIcon={
                                isLoading ? (
                                    <Loader color="white" size={14} />
                                ) : (
                                    <Trash color={'white'} />
                                )
                            }
                            onClick={async () => {
                                handleDelete(property[0]);
                                closeDeleteModal();
                            }}
                            disabled={isLoading}
                        >
                            <Box data-autofocus>
                                {isLoading
                                    ? t(
                                          'Profiles_view.delete_modal_confirm_button_loading',
                                      )
                                    : t(
                                          'Profiles_view.delete_modal_confirm_button',
                                      )}
                            </Box>
                        </Button>
                    </Group>
                }
            >
                {t('profiles_view.delete_modal_description_first')}{' '}
                {t('profiles_view.delete_modal_description_second')}
            </Modal>
        </>
    );
};
export default ProfileCard;
