import { useLocale } from '@hooks/useLocale';
import { Group, Stack, Text } from '@mantine/core';
import { Article, ChatTeardropDots } from '@phosphor-icons/react';
import React from 'react';

interface SMSTypeSelectionProps {
    isFlash: boolean;
    onChange: (isFlash: boolean) => void;
}

const SMSTypeSelection: React.FC<SMSTypeSelectionProps> = ({
    isFlash,
    onChange,
}) => {
    const { t } = useLocale();

    return (
        <Stack className="gap-1">
            <Text className="text-sm font-medium text-gray-800">
                {t('sms_template_preview.sms_type_title')}
            </Text>
            <Group noWrap>
                <Stack
                    className={`border-base p-2.5 gap-1.5 cursor-pointer box-border font-medium hover:bg-shade-2 ${
                        !isFlash
                            ? 'border-blu-800 shadow-[0_0_4px_0px_rgba(1,149,255,0.20)]'
                            : 'border-shade-6'
                    }`}
                    onClick={() => onChange(false)}
                >
                    <Group className="gap-1">
                        <ChatTeardropDots
                            color={
                                !isFlash
                                    ? 'rgb(var(--color-blu-800))'
                                    : 'rgb(var(--color-gray-600))'
                            }
                        />
                        <Text className="text-sm text-gray-800">
                            {t('sms_template_preview.normal_sms_tile')}
                        </Text>
                    </Group>

                    <Text className="text-xs text-gray-600">
                        {t(
                            'sms_template_preview.normal_sms_content_description',
                        )}
                    </Text>
                </Stack>
                <Stack
                    className={`border-base p-2.5  gap-1.5 cursor-pointer box-border font-medium hover:bg-shade-2 ${
                        isFlash
                            ? 'border-blu-800 shadow-[0_0_4px_0px_rgba(1,149,255,0.20)]'
                            : 'border-shade-6'
                    }`}
                    onClick={() => onChange(true)}
                >
                    <Group className="gap-1">
                        <Article
                            color={
                                isFlash
                                    ? 'rgb(var(--color-blu-800))'
                                    : 'rgb(var(--color-gray-600))'
                            }
                        />
                        <Text className="text-sm font-medium text-gray-800">
                            {t('sms_template_preview.flash_sms_tile')}
                        </Text>
                    </Group>

                    <Text className="text-xs text-gray-600">
                        {t(
                            'sms_template_preview.flash_sms_content_description',
                        )}
                    </Text>
                </Stack>
            </Group>
        </Stack>
    );
};

export default SMSTypeSelection;
