import {
    ControlPanel,
    JourneyBuilderMode,
    JourneyEdgeEnum,
    JourneyNodeEnum,
    type ControlPanelState,
    type JourneyNodeData,
    type JourneyNodeUpdatePayload,
} from '@components/Journeys/Builder/types';
import {
    getActionSeedData,
    getEdgeId,
    hasEntryLogicError,
    hasNodeError,
    hasTriggerNodeError,
} from '@components/Journeys/Builder/utils';
import {
    useActivateJourney,
    useCreateJourney,
    useUpdateJourney,
} from '@hooks/useJourney';
import {
    BranchConcurrencyTypes,
    BranchConditionalTypes,
    PeriodType,
    ReservedEventColumns,
    type BaseTrigger,
    type Branch,
    type Journey,
    type JourneyAction,
    type JourneyBlocksList,
    type JourneyCreatePayload,
    type JourneyEntryLogic,
    type JourneyEventMapperSchema,
    type JourneyNode,
    type JourneyPublishConfig,
    type JourneyStatus,
    type JourneyTriggerConfig,
} from '@lightdash/common';
import { useQueryClient } from '@tanstack/react-query';
import { generateShortUUID } from '@utils/helpers';
import { t as translate } from 'i18next';
import React, { useCallback, useMemo, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { type Edge, type Node } from 'reactflow';
import { QueryKeys } from 'types/UseQuery';
import { createContext, useContextSelector } from 'use-context-selector';

interface JourneyBuilderContext {
    state: JourneyBuilderState;
    actions: {
        addNode: (blockId: string, reactFlowNodeId: string) => void;
        addPlaceholderNode: () => void;
        openControlPanel: ({ ...args }: ControlPanelState) => void;
        closeControlPanel: () => void;
        addTriggerNode: (
            payload: Pick<BaseTrigger, 'eventName' | 'eventSource'>,
        ) => void;
        updateNodeActionConfig: (
            nodeId: string,
            updatedAction: JourneyAction,
        ) => void;
        removePlaceholderNodes: () => void;
        addPlaceholderNodeBetween: (edgeId: string) => void;
        updateJourneyPayload: (payload: Partial<Journey>) => void;
        setExitTriggers: (payload: JourneyTriggerConfig['exit']) => void;
        setNodes: (nodes: Node<JourneyNodeData>[]) => void;
        setNodesUnselected: (nodes: Node<JourneyNodeData>[]) => void;
        setGoals: (goals: JourneyTriggerConfig['conversion']) => void;
        setEntryLogic: (payload: Partial<JourneyEntryLogic>) => void;
        mutateAsyncJourney: (
            redirectOnSuccess: boolean,
        ) => Promise<Journey | undefined>; //Info: This is a wrapper for the createJourney and UpdateJourney hooks
        mutateActivateJourney: (payload: JourneyPublishConfig) => Promise<void>;
        updateTriggerNode: (payload: Partial<BaseTrigger>) => void;
        updateNodeConfig: (payload: JourneyNodeUpdatePayload) => void;
        canSave: () => boolean;
        canLaunch: () => boolean;
    };
}

interface JourneyBuilderBaseState {
    nodes: Node<JourneyNodeData>[];
    edges: Edge[];
    journeyPayload: JourneyCreatePayload;
    blocksList: JourneyBlocksList;
    journeyStatus: JourneyStatus;
}

interface JourneyBuilderOpenState extends JourneyBuilderBaseState {
    controlPanel: ControlPanelState & { isOpen: true };
}

interface JourneyBuilderClosedState extends JourneyBuilderBaseState {
    controlPanel: { isOpen: false };
}

export type JourneyReducerState =
    | JourneyBuilderOpenState
    | JourneyBuilderClosedState;

export type JourneyBuilderState = JourneyReducerState & {
    isLoading: boolean;
    initialJourneyPayload: JourneyCreatePayload;
    uuid: string | undefined;
    journeyEvents: JourneyEventMapperSchema[] | undefined;
    isEditable: boolean;
};

export enum ActionType {
    ADD_NODE,
    ADD_PLACEHOLDER_NODE,
    OPEN_CONTROL_PANEL,
    CLOSE_CONTROL_PANEL,
    ADD_TRIGGER_NODE,
    UPDATE_NODE_ACTION_CONFIG,
    REMOVE_PLACEHOLDER_NODES,
    ADD_PLACEHOLDER_NODE_BETWEEN,
    UPDATE_JOURNEY_PAYLOAD,
    SET_EXIT_TRIGGERS,
    SET_NODES,
    SET_NODES_UNSELECTED,
    SET_GOALS,
    SET_ENTRY_LOGIC,
    UPDATE_TRIGGER_NODE,
    UPDATE_NODE_CONFIG,
}

type Action =
    | {
          type: ActionType.ADD_NODE;
          payload: { blockId: string; reactFlowNodeId: string }; //Info: this is the id of the placeholder node or the id of the react flow node where the new block node is being added
      }
    | { type: ActionType.OPEN_CONTROL_PANEL; payload: ControlPanelState }
    | { type: ActionType.CLOSE_CONTROL_PANEL }
    | {
          type: ActionType.ADD_TRIGGER_NODE;
          payload: Pick<BaseTrigger, 'eventName' | 'eventSource'>;
      }
    | { type: ActionType.ADD_PLACEHOLDER_NODE; payload: undefined }
    | {
          type: ActionType.UPDATE_NODE_ACTION_CONFIG;
          payload: { nodeId: string; updatedAction: JourneyAction };
      }
    | { type: ActionType.REMOVE_PLACEHOLDER_NODES }
    | {
          type: ActionType.ADD_PLACEHOLDER_NODE_BETWEEN;
          payload: { edgeId: string };
      }
    | {
          type: ActionType.UPDATE_JOURNEY_PAYLOAD;
          payload: Partial<Journey>;
      }
    | {
          type: ActionType.SET_EXIT_TRIGGERS;
          payload: JourneyTriggerConfig['exit'];
      }
    | {
          type: ActionType.SET_NODES;
          payload: Node<JourneyNodeData>[];
      }
    | {
          type: ActionType.SET_NODES_UNSELECTED;
          payload: Node<JourneyNodeData>[];
      }
    | {
          type: ActionType.SET_GOALS;
          payload: JourneyTriggerConfig['conversion'];
      }
    | {
          type: ActionType.SET_ENTRY_LOGIC;
          payload: Partial<JourneyEntryLogic>;
      }
    | {
          type: ActionType.UPDATE_TRIGGER_NODE;
          payload: Partial<BaseTrigger>;
      }
    | {
          type: ActionType.UPDATE_NODE_CONFIG;
          payload: JourneyNodeUpdatePayload;
      };

const Context = createContext<JourneyBuilderContext | undefined>(undefined);

function reducer(
    state: JourneyReducerState,
    action: Action,
): JourneyReducerState {
    switch (action.type) {
        case ActionType.ADD_NODE: {
            const { blockId, reactFlowNodeId } = action.payload;
            if (!blockId || !reactFlowNodeId) return state;

            // Find the node with the given reactFlowNodeId
            const targetNode = state.nodes.find(
                (node) => node.id === reactFlowNodeId,
            );
            if (!targetNode) return state;

            const { nodes, edges } = state;

            // Filter out the target node and set selected to false for each node
            const filteredNodes = nodes
                .filter((node) => node.id !== reactFlowNodeId)
                .map((node) => ({ ...node, selected: false }));

            const blockData = state.blocksList.find(
                (block) => block.id === blockId,
            );
            if (!blockData) return state;
            const newNodeId = generateShortUUID();
            const blockActions = blockData?.actions ?? [];
            const newNode: Node<JourneyNodeData> = {
                id: newNodeId,
                position: targetNode.position,
                type: JourneyNodeEnum.BLOCK,
                data: {
                    type: JourneyNodeEnum.BLOCK,
                    nodeId: newNodeId,
                    blockId: blockData?.id,
                },
                selected: true,
            };

            const actionPayload: JourneyNode = {
                id: newNodeId,
                title: blockData?.title ?? '',
                description: blockData?.description ?? '',
                actions: getActionSeedData(blockActions),
                metadata: {
                    blockId: blockData?.id,
                },
                branchConfig: {
                    type: BranchConditionalTypes.IFIF,
                    children: {
                        type: BranchConcurrencyTypes.SEQUENTIAL,
                        branches: [],
                    },
                },
            };

            // Remove the node with the same id as reactFlowNodeId and add the new node
            const updatedConfigNodes = [
                ...(state.journeyPayload.config?.nodes ?? []).filter(
                    (node) => node.id !== reactFlowNodeId,
                ),
                actionPayload,
            ];

            // Find the parent node of the target node
            const parentEdge = edges.find(
                (edge) => edge.target === reactFlowNodeId,
            );
            const parentNodeId = parentEdge ? parentEdge.source : null;

            // Update parent node's branch config to include only the new node as a destination
            const updatedNodes = updatedConfigNodes.map((node) => {
                if (node.id === parentNodeId) {
                    const newBranch: Branch = { destination: newNodeId };
                    const updatedBranchConfig = {
                        type: BranchConditionalTypes.IFIF,
                        children: {
                            type: BranchConcurrencyTypes.SEQUENTIAL,
                            branches: [newBranch],
                        },
                    };
                    return {
                        ...node,
                        branchConfig: updatedBranchConfig,
                    };
                }
                return node;
            });

            // Replace the target node with the new node in the edges
            const updatedEdges = edges.map((edge) => {
                if (edge.source === reactFlowNodeId) {
                    return { ...edge, source: newNodeId };
                }
                if (edge.target === reactFlowNodeId) {
                    return { ...edge, target: newNodeId };
                }
                return edge;
            });
            return {
                ...state,
                nodes: [...filteredNodes, newNode],
                edges: updatedEdges,
                controlPanel: {
                    isOpen: true,
                    type: ControlPanel.BLOCK_CONFIG,
                    nodeId: newNodeId,
                },
                journeyPayload: {
                    ...state.journeyPayload,
                    config: {
                        ...state.journeyPayload.config,
                        nodes: updatedNodes,
                    },
                },
            };
        }

        case ActionType.OPEN_CONTROL_PANEL: {
            return {
                ...state,
                controlPanel: {
                    isOpen: true,
                    ...action.payload,
                },
            };
        }
        case ActionType.CLOSE_CONTROL_PANEL: {
            const { nodes } = state;
            const unselectedNodes = nodes.map((node) => ({
                ...node,
                selected: false,
            }));
            return {
                ...state,
                nodes: unselectedNodes,
                controlPanel: {
                    isOpen: false,
                },
            };
        }
        case ActionType.ADD_TRIGGER_NODE: {
            const nodes = state.nodes;

            //INFO: To ensure only one trigger node is present in the Journey. Remove this statement to allow multiple trigger nodes
            const nodesWithoutTrigger = nodes.filter(
                (node) =>
                    !(
                        node.data.type === JourneyNodeEnum.PLACEHOLDER &&
                        node.data.placeHolderType === JourneyNodeEnum.TRIGGER
                    ),
            );

            const newNodeId = generateShortUUID();
            const triggerNode: Node<JourneyNodeData> = {
                id: newNodeId,
                position: { x: 0, y: 0 },
                type: JourneyNodeEnum.TRIGGER,
                data: {
                    nodeId: newNodeId,
                    type: JourneyNodeEnum.TRIGGER,
                    blockId: JourneyNodeEnum.TRIGGER,
                },
            };

            const triggerPayload: JourneyTriggerConfig['entry'] = [
                {
                    eventName: action.payload.eventName,
                    eventSource: action.payload.eventSource,
                    id: newNodeId,
                    metadata: {
                        id: newNodeId,
                        title: translate(
                            'journey_builder.trigger_node_block_title',
                        ),
                    },
                },
            ];

            return {
                ...state,
                nodes: [...nodesWithoutTrigger, triggerNode],
                journeyPayload: {
                    ...state.journeyPayload,
                    triggers: {
                        entry: triggerPayload,
                    },
                },
                controlPanel: {
                    isOpen: true,
                    type: ControlPanel.TRIGGER_CONFIG,
                    triggerId: newNodeId,
                },
            };
        }

        case ActionType.ADD_PLACEHOLDER_NODE: {
            const placeholderNodeId = generateShortUUID();
            const lastNodeId =
                state.nodes.length > 0
                    ? state.nodes[state.nodes.length - 1].id
                    : '';
            if (!lastNodeId) return state;

            const currentNodes = state.nodes.map((node) => ({
                ...node,
                selected: false,
            }));

            const placeholderNode: Node<JourneyNodeData> = {
                id: placeholderNodeId,
                position: { x: 0, y: 0 },
                type: JourneyNodeEnum.PLACEHOLDER,
                data: {
                    type: JourneyNodeEnum.PLACEHOLDER,
                    placeHolderType: JourneyNodeEnum.BLOCK,
                },
            };

            const newEdge: Edge = {
                id: getEdgeId(lastNodeId, placeholderNodeId),
                source: lastNodeId,
                target: placeholderNodeId,
                type: JourneyEdgeEnum.BLOCK,
            };

            return {
                ...state,
                nodes: [...currentNodes, placeholderNode],
                edges: lastNodeId ? [...state.edges, newEdge] : state.edges,
                controlPanel: {
                    isOpen: true,
                    type: ControlPanel.BLOCKS_LIST,
                    reactFlowNodeId: placeholderNodeId,
                },
            };
        }

        case ActionType.UPDATE_NODE_ACTION_CONFIG: {
            const { nodeId, updatedAction } = action.payload;
            const nodes: Node<JourneyNodeData>[] = state.nodes.map((node) => {
                if (node.id === nodeId) {
                    const journeyActions =
                        state.journeyPayload.config?.nodes.find(
                            (n) => n.id === nodeId,
                        )?.actions ?? [];
                    const updatedActions = journeyActions.map((a) =>
                        a.type === updatedAction.type ? updatedAction : a,
                    );

                    return {
                        ...node,
                        data: {
                            ...node.data,
                            actions: updatedActions,
                        },
                    };
                }
                return node;
            });

            const journeyNodes: JourneyNode[] =
                state.journeyPayload.config?.nodes.map((node) =>
                    node.id === nodeId
                        ? {
                              ...node,
                              actions: node.actions.map((a) =>
                                  a.type === updatedAction.type
                                      ? updatedAction
                                      : a,
                              ),
                          }
                        : node,
                ) ?? [];

            return {
                ...state,
                nodes,
                journeyPayload: {
                    ...state.journeyPayload,
                    config: {
                        ...state.journeyPayload.config,
                        nodes: journeyNodes,
                    },
                },
            };
        }

        case ActionType.REMOVE_PLACEHOLDER_NODES: {
            const isPlaceholderNode = (node: Node<JourneyNodeData>) =>
                node.data.type === JourneyNodeEnum.PLACEHOLDER;

            const hasPlaceholderNodes = state.nodes.some(isPlaceholderNode);

            if (!hasPlaceholderNodes) {
                return state;
            }

            const filteredNodes = state.nodes.filter(
                (node) => !isPlaceholderNode(node),
            );

            const placeholderNodeIds = state.nodes
                .filter(isPlaceholderNode)
                .map((node) => node.id);

            const filteredEdges = state.edges.filter(
                (edge) =>
                    !placeholderNodeIds.includes(edge.source) &&
                    !placeholderNodeIds.includes(edge.target),
            );

            return {
                ...state,
                nodes: filteredNodes,
                edges: filteredEdges,
            };
        }

        case ActionType.ADD_PLACEHOLDER_NODE_BETWEEN: {
            const { edgeId } = action.payload;

            const edge = state.edges.find((e) => e.id === edgeId);
            if (!edge) return state;

            const sourceNode = state.nodes.find((n) => n.id === edge.source);
            const targetNode = state.nodes.find((n) => n.id === edge.target);
            if (!sourceNode || !targetNode) return state;

            const placeholderNodeId = generateShortUUID();

            const placeholderNode: Node<JourneyNodeData> = {
                id: placeholderNodeId,
                position: {
                    x: (sourceNode.position.x + targetNode.position.x) / 2,
                    y: (sourceNode.position.y + targetNode.position.y) / 2,
                },
                type: JourneyNodeEnum.PLACEHOLDER,
                data: {
                    type: JourneyNodeEnum.PLACEHOLDER,
                    placeHolderType: JourneyNodeEnum.BLOCK,
                },
            };

            const newEdge1: Edge = {
                id: `e${edge.source}-${placeholderNodeId}`,
                source: edge.source,
                target: placeholderNodeId,
                type: JourneyEdgeEnum.BLOCK,
            };

            const newEdge2: Edge = {
                id: `e${placeholderNodeId}-${edge.target}`,
                source: placeholderNodeId,
                target: edge.target,
                type: JourneyEdgeEnum.BLOCK,
            };

            return {
                ...state,
                nodes: [...state.nodes, placeholderNode],
                edges: [
                    ...state.edges.filter((e) => e.id !== edgeId),
                    newEdge1,
                    newEdge2,
                ],
            };
        }

        case ActionType.UPDATE_JOURNEY_PAYLOAD: {
            const { payload } = action;
            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,
                    ...payload,
                    name:
                        payload.name && payload.name.length > 0
                            ? payload.name
                            : translate(
                                  'journey_builder.header_title_placeholder',
                              ),
                },
            };
        }

        case ActionType.SET_EXIT_TRIGGERS: {
            const { payload } = action;
            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,
                    triggers: {
                        ...state.journeyPayload.triggers!,
                        exit: payload,
                    },
                },
            };
        }

        case ActionType.SET_GOALS: {
            const { payload } = action;
            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,
                    triggers: {
                        ...state.journeyPayload.triggers!,
                        conversion: payload,
                    },
                },
            };
        }

        case ActionType.SET_NODES: {
            const { payload } = action;
            return {
                ...state,
                nodes: payload,
            };
        }

        case ActionType.SET_NODES_UNSELECTED: {
            const { payload } = action;
            return {
                ...state,
                nodes: payload.map((node) => ({ ...node, selected: false })),
            };
        }

        case ActionType.SET_ENTRY_LOGIC: {
            const { payload } = action;
            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,
                    entryLogic: {
                        ...(state.journeyPayload.entryLogic ?? {
                            cooldown: 0,
                            contextId: ReservedEventColumns.USER_ID,
                            contextTotal: -1,
                            killExisting: true,
                            contextConcurrency: -1,
                            uiConfig: {
                                cooldownType: PeriodType.HOUR,
                            },
                        }), //This defaulting can be removed once the CreateJourney type is updated
                        ...payload,
                    },
                },
            };
        }

        case ActionType.UPDATE_TRIGGER_NODE: {
            const { payload } = action;

            if (!payload) return state;

            // Find the trigger node in the state
            const triggerNode = state.journeyPayload.triggers!.entry[0];
            if (!triggerNode) return state;

            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,
                    triggers: {
                        ...state.journeyPayload.triggers,
                        entry: [
                            {
                                ...triggerNode,
                                ...payload,
                            },
                        ],
                    },
                },
            };
        }

        case ActionType.UPDATE_NODE_CONFIG: {
            const { payload } = action;
            const { nodeId, nodePayload } = payload;
            return {
                ...state,
                journeyPayload: {
                    ...state.journeyPayload,
                    config: {
                        ...state.journeyPayload.config,
                        nodes: state.journeyPayload.config!.nodes.map((node) =>
                            node.id === nodeId
                                ? { ...node, ...nodePayload }
                                : node,
                        ),
                    },
                },
            };
        }

        default:
            return state;
    }
}

export const JourneyBuilderProvider: React.FC<
    React.PropsWithChildren<{
        initialState: JourneyReducerState;
        isEditable: boolean;
        uuid: string | undefined;
        journeyEvents: JourneyEventMapperSchema[] | undefined;
        journeyStatus: JourneyStatus;
    }>
> = ({ initialState, children, isEditable, uuid, journeyEvents }) => {
    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();
    const queryClient = useQueryClient();
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();

    const {
        mutateAsync: mutateAsyncCreateJourney,
        isLoading: isCreatingJourney,
    } = useCreateJourney();

    const {
        mutateAsync: mutateAsyncUpdateJourney,
        isLoading: isUpdatingJourney,
    } = useUpdateJourney(uuid ?? '');

    const { mutateAsync: activateJourney } = useActivateJourney();

    const addNode = useCallback((blockId: string, reactFlowNodeId: string) => {
        dispatch({
            type: ActionType.ADD_NODE,
            payload: { blockId, reactFlowNodeId },
        });
    }, []);

    const openControlPanel = useCallback((props: ControlPanelState) => {
        dispatch({ type: ActionType.OPEN_CONTROL_PANEL, payload: props });
    }, []);

    const closeControlPanel = useCallback(() => {
        dispatch({ type: ActionType.CLOSE_CONTROL_PANEL });
    }, []);

    const addTriggerNode = useCallback(
        (payload: Pick<BaseTrigger, 'eventName' | 'eventSource'>) => {
            dispatch({ type: ActionType.ADD_TRIGGER_NODE, payload: payload });
        },
        [],
    );

    const addPlaceholderNode = useCallback(() => {
        dispatch({ type: ActionType.ADD_PLACEHOLDER_NODE, payload: undefined });
    }, []);

    const updateNodeActionConfig = useCallback(
        (nodeId: string, updatedAction: JourneyAction) => {
            dispatch({
                type: ActionType.UPDATE_NODE_ACTION_CONFIG,
                payload: { nodeId, updatedAction },
            });
        },
        [],
    );

    const removePlaceholderNodes = useCallback(() => {
        dispatch({ type: ActionType.REMOVE_PLACEHOLDER_NODES });
    }, []);

    const addPlaceholderNodeBetween = useCallback((edgeId: string) => {
        dispatch({
            type: ActionType.ADD_PLACEHOLDER_NODE_BETWEEN,
            payload: { edgeId },
        });
    }, []);

    const updateJourneyPayload = useCallback((payload: Partial<Journey>) => {
        dispatch({ type: ActionType.UPDATE_JOURNEY_PAYLOAD, payload });
    }, []);

    const setExitTriggers = useCallback(
        (payload: JourneyTriggerConfig['exit']) => {
            dispatch({ type: ActionType.SET_EXIT_TRIGGERS, payload: payload });
        },
        [],
    );
    const setNodes = useCallback((nodes: Node<JourneyNodeData>[]) => {
        dispatch({ type: ActionType.SET_NODES, payload: nodes });
    }, []);

    const setNodesUnselected = useCallback((nodes: Node<JourneyNodeData>[]) => {
        dispatch({ type: ActionType.SET_NODES_UNSELECTED, payload: nodes });
    }, []);

    const setGoals = useCallback(
        (payload: JourneyTriggerConfig['conversion']) => {
            dispatch({ type: ActionType.SET_GOALS, payload: payload });
        },
        [],
    );
    const setEntryLogic = useCallback((payload: Partial<JourneyEntryLogic>) => {
        dispatch({ type: ActionType.SET_ENTRY_LOGIC, payload });
    }, []);

    const callCreateJourney = useCallback(
        async (redirectOnSuccess: boolean = true) => {
            //Info: Do not allow journey creation if journey uuid is already present
            if (uuid) return;

            const response = await mutateAsyncCreateJourney(
                reducerState.journeyPayload,
            );
            const journeyUuid = response.id;
            if (redirectOnSuccess)
                history.push(
                    `/projects/${projectUuid}/journeys/${journeyUuid}/${JourneyBuilderMode.EDIT}`,
                );
            return response;
        },
        [
            uuid,
            mutateAsyncCreateJourney,
            reducerState.journeyPayload,
            history,
            projectUuid,
        ],
    );

    const callUpdateJourney = useCallback(async () => {
        if (!uuid) return;
        const data = await mutateAsyncUpdateJourney(
            reducerState.journeyPayload,
        );
        await queryClient.invalidateQueries({
            queryKey: [QueryKeys.GET_JOURNEY_BY_ID, uuid],
        });
        return data;
    }, [
        uuid,
        mutateAsyncUpdateJourney,
        reducerState.journeyPayload,
        queryClient,
    ]);

    const mutateAsyncJourney = useCallback(
        async (redirectOnSuccess: boolean) => {
            //Info: If journey uuid is present, update the journey, otherwise create a new journey
            if (uuid) {
                const res = await callUpdateJourney();
                return res;
            }

            const res = await callCreateJourney(redirectOnSuccess);
            return res;
        },
        [callCreateJourney, callUpdateJourney, uuid],
    );

    const mutateActivateJourney = useCallback(
        async (payload: JourneyPublishConfig) => {
            const data = await mutateAsyncJourney(false);

            if (data) {
                await activateJourney({
                    data: payload,
                    uuid: data && data.id,
                });
                history.push(`/projects/${projectUuid}/journeys`);
            }
        },
        [activateJourney, history, mutateAsyncJourney, projectUuid],
    );

    const canSave = useCallback((): boolean => {
        if (!reducerState.journeyPayload.name) {
            return false;
        }
        const triggerBlock = reducerState.journeyPayload.triggers?.entry[0];
        if (!triggerBlock || hasTriggerNodeError(triggerBlock)) {
            return false;
        }
        return true;
    }, [
        reducerState.journeyPayload.name,
        reducerState.journeyPayload.triggers?.entry,
    ]);

    const canLaunch = useCallback(() => {
        if (!canSave()) return false;
        if (
            reducerState.journeyPayload.entryLogic &&
            hasEntryLogicError(reducerState.journeyPayload.entryLogic)
        ) {
            return false;
        }
        if (
            reducerState.journeyPayload.config &&
            reducerState.journeyPayload.config.nodes.some((node) =>
                hasNodeError(node.actions),
            )
        ) {
            return false;
        }
        return true;
    }, [
        canSave,
        reducerState.journeyPayload.config,
        reducerState.journeyPayload.entryLogic,
    ]);

    const updateTriggerNode = useCallback((payload: Partial<BaseTrigger>) => {
        dispatch({ type: ActionType.UPDATE_TRIGGER_NODE, payload });
    }, []);

    const updateNodeConfig = useCallback(
        (payload: JourneyNodeUpdatePayload) => {
            dispatch({
                type: ActionType.UPDATE_NODE_CONFIG,
                payload: payload,
            });
        },
        [],
    );

    const state: JourneyBuilderState = useMemo(
        () => ({
            ...reducerState,
            isLoading: isCreatingJourney || isUpdatingJourney,
            uuid,
            initialJourneyPayload: initialState.journeyPayload,
            isEditable,
            journeyEvents,
        }),
        [
            reducerState,
            isCreatingJourney,
            isUpdatingJourney,
            uuid,
            initialState.journeyPayload,
            isEditable,
            journeyEvents,
        ],
    );

    const actions = useMemo(
        () => ({
            addNode,
            openControlPanel,
            closeControlPanel,
            addTriggerNode,
            addPlaceholderNode,
            updateNodeActionConfig,
            removePlaceholderNodes,
            addPlaceholderNodeBetween,
            updateJourneyPayload,
            setExitTriggers,
            setNodes,
            setNodesUnselected,
            setGoals,
            setEntryLogic,
            mutateAsyncJourney,
            updateTriggerNode,
            mutateActivateJourney,
            updateNodeConfig,
            canSave,
            canLaunch,
        }),
        [
            addNode,
            openControlPanel,
            closeControlPanel,
            addTriggerNode,
            addPlaceholderNode,
            updateNodeActionConfig,
            removePlaceholderNodes,
            addPlaceholderNodeBetween,
            updateJourneyPayload,
            setExitTriggers,
            setNodes,
            setNodesUnselected,
            setGoals,
            setEntryLogic,
            mutateAsyncJourney,
            updateTriggerNode,
            mutateActivateJourney,
            updateNodeConfig,
            canSave,
            canLaunch,
        ],
    );

    const value = useMemo(
        () => ({
            state,
            actions,
        }),
        [actions, state],
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useJourneyBuilderContext<Selected>(
    selector: (value: JourneyBuilderContext) => Selected,
): Selected {
    return useContextSelector(Context, (context) => {
        if (context === undefined) {
            throw new Error(
                'useContext must be used within Journey Builder Provider',
            );
        }
        return selector(context);
    });
}
