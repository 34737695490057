import { useLocale } from '@hooks/useLocale';
import useTimestamp from '@hooks/useTimestamp';
import { JourneyStatus } from '@lightdash/common';
import { Box } from '@mantine/core';
import { ClockCountdown } from '@phosphor-icons/react';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import { useMemo, useState } from 'react';
import SettingsTitle from '../SettingsTitle';
import StatusContent from './StatusContent';

const Scheduling: React.FC = () => {
    const { t } = useLocale();
    const { getTimestamp } = useTimestamp();
    const { journeyStatus } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const minimumDateTime = useMemo(() => {
        const date = new Date();
        date.setMinutes(date.getMinutes() + 15);
        return new Date(getTimestamp(date.getTime()));
    }, [getTimestamp]);

    const [endDateTime] = useState<Date>(minimumDateTime);

    const getSchedulingContent = useMemo(() => {
        switch (journeyStatus) {
            case JourneyStatus.DRAFT:
                return (
                    <StatusContent
                        isEndCheck={true}
                        isStartCheck={true}
                        startText={t(
                            'journey_settings_scheduling.activate_this_journey',
                        )}
                        endText={t(
                            'journey_settings_scheduling.set_end_date_and_time',
                        )}
                        isEndReadOnly={undefined}
                        isStartReadOnly={undefined}
                    />
                );
            case JourneyStatus.SCHEDULED:
                return (
                    <StatusContent
                        isEndCheck={true}
                        isStartCheck={false}
                        startText={t(
                            'journey_settings_scheduling.scheduled_to_activate',
                        )}
                        endText={t(
                            'journey_settings_scheduling.set_end_date_and_time',
                        )}
                        isEndReadOnly={undefined}
                        isStartReadOnly={undefined}
                    />
                );

            case JourneyStatus.ACTIVE:
                const endDate = endDateTime;
                const currentDate = new Date();
                //this is for journey sunset status
                if (endDate < currentDate) {
                    return (
                        <StatusContent
                            isEndCheck={false}
                            isStartCheck={false}
                            startText={t(
                                'journey_settings_scheduling.activated_on',
                            )}
                            endText={t('journey_settings_scheduling.sunset_on')}
                            isStartReadOnly={true}
                            isEndReadOnly={true}
                        />
                    );
                }
                return (
                    <StatusContent
                        isEndCheck={true}
                        isStartCheck={false}
                        startText={t(
                            'journey_settings_scheduling.activated_on',
                        )}
                        endText={t(
                            'journey_settings_scheduling.set_end_date_and_time',
                        )}
                        isStartReadOnly={true}
                        isEndReadOnly={undefined}
                    />
                );

            default:
                // this is for cancelled journey status
                return (
                    <StatusContent
                        isEndCheck={false}
                        isStartCheck={false}
                        startText={t(
                            'journey_settings_scheduling.activated_on',
                        )}
                        endText={t('journey_settings_scheduling.stopped_on')}
                        isStartReadOnly={true}
                        isEndReadOnly={true}
                    />
                );
        }
    }, [endDateTime, journeyStatus, t]);

    return (
        <Box>
            <SettingsTitle
                icon={<ClockCountdown size={14} weight="duotone" />}
                title={t('journey_scheduling.title')}
                subtitle={t('journey_scheduling.subtitle')}
            />
            {getSchedulingContent}
        </Box>
    );
};

export default Scheduling;
