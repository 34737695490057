import { Box, Flex, Text, Title } from '@mantine/core';
import React from 'react';

interface SettingsTitleProps {
    title: string;
    subtitle: string;
    icon: React.ReactNode | undefined;
}

const SettingsTitle: React.FC<SettingsTitleProps> = ({
    title,
    subtitle,
    icon,
}) => {
    return (
        <Flex direction="column" className="border-b-[1px] p-3">
            <Flex align="center" className="mb-1">
                {icon && <Box className="mr-1.5">{icon}</Box>}
                <Title className="text-sm font-semibold text-gray-800">
                    {title}
                </Title>
            </Flex>
            <Text className="text-xs font-normal text-gray-500">
                {subtitle}
            </Text>
        </Flex>
    );
};

export default SettingsTitle;
