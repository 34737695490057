import { RelationTableType } from '@lightdash/common';
import { Clock, SquaresFour, User } from '@phosphor-icons/react';
import { useMemo } from 'react';
import { Handle, Position } from 'reactflow';
import { GlobalStyles } from '../../../mantineTheme';

const Icons = {
    PRIMARY: (
        <User
            size={14}
            weight="duotone"
            color={
                GlobalStyles?.tableStyles?.[RelationTableType?.PRIMARY]
                    ?.textColor
            }
        />
    ),
    EVENT: (
        <Clock
            size={14}
            weight="duotone"
            color={
                GlobalStyles?.tableStyles?.[RelationTableType?.EVENT]?.textColor
            }
        />
    ),
    RELATED: (
        <SquaresFour
            size={14}
            weight="duotone"
            color={
                GlobalStyles?.tableStyles?.[RelationTableType?.RELATED]
                    ?.textColor
            }
        />
    ),
};

interface CustomNodeType {
    data: {
        label: string;
        tableType: 'PRIMARY' | 'EVENT' | 'RELATED';
        onClick?: (node: any) => void;
    };
    allowAdd?: boolean;
    selected?: boolean;
}

const bgColor = {
    PRIMARY: 'blu-800',
    RELATED: 'green',
    EVENT: 'mustard-800',
};

const CustomNode = ({ data, ...rest }: CustomNodeType) => {
    const borderClass = useMemo(() => {
        if (data.tableType === 'EVENT') {
            return 'hover:border-mustard-800/40';
        }
        if (data.tableType === 'PRIMARY') {
            return 'hover:border-blu-800/40';
        }
        if (data.tableType === 'RELATED') {
            return 'hover:border-green/40';
        }
    }, [data.tableType]);

    const nodeClass = useMemo(
        () =>
            `bg-white border border-solid border-black/0.04 flex justify-between items-center gap-3 self-stretch w-[18.75rem] rounded-xl shadow-[inset_0px_-1px_4px_0px_rgba(0,0,0,0.06)] p-2 cursor-pointer ${borderClass}`,
        [borderClass],
    );

    return (
        <div className="relative">
            <Handle
                type="target"
                position={Position.Left}
                className="!bg-gray-600 -z-10 !-left-1"
            />

            <div
                className={nodeClass}
                onClick={() => data.onClick?.(rest)}
                style={{
                    borderColor: rest.selected
                        ? `rgb(var(--color-${bgColor?.[data?.tableType]}))`
                        : '',
                }}
            >
                <div className="flex items-center justify-between">
                    <div
                        className="flex p-1 justify-center items-center gap-10 rounded-md border border-solid border-black/0.04"
                        style={{
                            backgroundColor: `rgb(var(--color-${
                                bgColor?.[data?.tableType]
                            })/0.2)`,
                        }}
                    >
                        {Icons?.[data?.tableType]}
                    </div>
                    <span className="text-gray-800 text-sm pl-2.5 font-medium max-w-[11.25rem] truncate">
                        {data?.label}
                    </span>
                </div>
            </div>

            <Handle
                type="source"
                position={Position.Right}
                className="!bg-gray-600 -z-10 !-right-1"
            />
        </div>
    );
};

export default CustomNode;
