import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import TextInput from '@components/common/Inputs/TextInput';
import Select from '@components/common/Select';
import {
    useAddChannelProvider,
    useGetChannelProviderConfig,
    useGetIntegratedProviderConfig,
    useTestChannelIntegration,
    useUpdateChannels,
} from '@hooks/useChannels';
import { useLocale } from '@hooks/useLocale';
import {
    type ChannelConfig,
    type ChannelIntegrationDetails,
    type Integration,
} from '@lightdash/common';
import {
    ActionIcon,
    Box,
    Button,
    Flex,
    Loader as LoaderIcon,
} from '@mantine/core';
import { Copy } from '@phosphor-icons/react';
import {
    CHANNEL_SETUP_STATES,
    useChannelContext,
} from '@providers/ChannelProvider';
import { useQueryClient } from '@tanstack/react-query';
import { CUSTOMNAME } from '@utils/constants';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../mantineTheme';
import Loader from './Loader';

interface ChannelConfigProps {
    providerId: string | undefined;
    title: string | undefined;
    description: string | undefined;
    handleExit: () => void;
    isSetupDone: boolean | undefined;
    integrationId: string | undefined;
}

const ProviderConfig = ({
    providerId,
    handleExit,
    integrationId,
}: ChannelConfigProps) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
        getValues,
    } = useForm();

    const queryClient = useQueryClient();
    const { actions, state } = useChannelContext((context) => context);
    const { selectedProvider, channelSetupState } = state;
    const { handleShowSetUpModal, updateChannelSetupStateAction } = actions;
    const {
        data: channelProviderConfig,
        isInitialLoading: channelProviderConfigLoading,
    } = useGetChannelProviderConfig({
        providerId: providerId ?? '',
        channel: selectedProvider?.channelName ?? '',
        enabled: channelSetupState === CHANNEL_SETUP_STATES.ADD_CHANNEL_DETAILS,
    });

    const addChannelProvider = useAddChannelProvider();
    const {
        mutate: addChannelMutate,
        isLoading: isChannelProviderAdding,
        isSuccess: isChannelProviderAdded,
    } = addChannelProvider;

    const updateChannelProvider = useUpdateChannels(
        providerId ?? '',
        integrationId ?? '',
        // selectedProvider?.channelName ?? ''
    );
    const {
        mutate,
        isSuccess: isChannelProviderUpdated,
        isLoading: isChannelProviderUpdating,
    } = updateChannelProvider;

    const {
        mutate: testChannelIntegration,
        isSuccess: isIntegrationTestSuccess,
        isLoading: isTestingIntegration,
    } = useTestChannelIntegration();
    const ability = useAbilityContext();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const canOnlyViewChannel =
        ability.can('view', subject('Channel', { projectUuid })) &&
        ability.cannot('update', subject('Channel', { projectUuid }));

    const onSubmit = async (data: {
        [K in ChannelConfig[][number]['configKey']]: string;
    }) => {
        if (!providerId || isChannelProviderUpdating || isChannelProviderAdding)
            return;
        const customName = data.customName.replaceAll(' ', '_');
        const integrationPayload = { ...data };
        delete integrationPayload.customName;
        testChannelIntegration({
            providerId,
            data: {
                customName,
                metadata: integrationPayload,
            },
        });
    };

    const updateOrCreateProviderIntegration = useCallback(() => {
        if (!providerId || isChannelProviderUpdating || isChannelProviderAdding)
            return;
        const data = getValues();
        const customName = data.customName.replaceAll(' ', '_');
        if (integrationId) {
            const payload = {
                config: data,
                customName: customName,
                providerId: providerId,
                integrationId: integrationId,
            };
            mutate(payload);
        } else {
            const payload = {
                metadata: data,
                customName: customName,
                providerId: providerId,
            };
            addChannelMutate(payload);
        }
        void queryClient.refetchQueries([
            QueryKeys.INTEGRATED_CHANNELS,
            selectedProvider?.channelName,
        ]);
    }, [
        addChannelMutate,
        getValues,
        integrationId,
        isChannelProviderAdding,
        isChannelProviderUpdating,
        mutate,
        providerId,
        queryClient,
        selectedProvider,
    ]);

    useEffect(() => {
        if (isIntegrationTestSuccess && !isTestingIntegration) {
            updateOrCreateProviderIntegration();
        }
    }, [
        isIntegrationTestSuccess,
        updateOrCreateProviderIntegration,
        isTestingIntegration,
    ]);

    const {
        data: integratedConfig,
        isInitialLoading: integratedConfigloading,
    } = useGetIntegratedProviderConfig(providerId, integrationId);

    const { t } = useLocale();

    const integrationObject = React.useMemo(():
        | ChannelIntegrationDetails
        | Integration
        | undefined => {
        if (integrationId) {
            return integratedConfig;
        }
        return channelProviderConfig;
    }, [integrationId, integratedConfig, channelProviderConfig]);

    useEffect(() => {
        setValue(CUSTOMNAME, integrationObject?.customName ?? '');
        integrationObject?.config?.forEach((fields: ChannelConfig) => {
            setValue(
                `${fields?.configKey}`,
                `${(fields?.configValue || fields?.defaultValue) ?? ''}`,
            );
        });
    }, [setValue, integrationId, integrationObject]);

    useEffect(() => {
        // INFO - This is to close the modal when update API is successful
        if (isChannelProviderUpdated || isChannelProviderAdded) {
            handleExit();
        }
    }, [isChannelProviderUpdated, isChannelProviderAdded, handleExit]);

    const handleCancel = () => {
        if (integratedConfigloading || channelProviderConfigLoading) return;
        handleShowSetUpModal(false);
        setTimeout(
            () =>
                updateChannelSetupStateAction(
                    CHANNEL_SETUP_STATES.SELECT_PROVIDER,
                ),
            200,
        );
    };

    const submitButtonLabel = useMemo(() => {
        if (isTestingIntegration) {
            return t('workspace_settings.channel.saving');
        }
        if (isChannelProviderUpdating || isChannelProviderAdding) {
            return t('workspace_settings.channel.testing');
        }
        return t('workspace_settings.channel.test_and_save');
    }, [
        isChannelProviderAdding,
        isChannelProviderUpdating,
        isTestingIntegration,
        t,
    ]);

    if (integratedConfigloading || channelProviderConfigLoading) {
        return <Loader length={4} />;
    }

    return (
        <Box className="w-full">
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                    label={t('channel_settings.set_up_channel_custom_name')}
                    placeholder={''}
                    className="w-full"
                    {...register(CUSTOMNAME, {
                        required: false,
                    })}
                    disabled={canOnlyViewChannel || isChannelProviderUpdating}
                />

                <Box key={integrationObject?.integrationId}>
                    {(
                        (integrationObject as ChannelIntegrationDetails)
                            ?.config || []
                    ).map((providerConfigObject: ChannelConfig) => {
                        if (!providerConfigObject?.show) {
                            return;
                        }
                        return !!providerConfigObject?.options &&
                            providerConfigObject.options.length > 0 ? (
                            <>
                                <br />
                                <Select
                                    label={providerConfigObject?.fieldName}
                                    placeholder={
                                        providerConfigObject?.fieldName
                                    }
                                    data={providerConfigObject?.options}
                                    className="w-full h-10"
                                    {...register(
                                        providerConfigObject?.configKey,
                                        {
                                            required:
                                                providerConfigObject?.required,
                                        },
                                    )}
                                    error={
                                        errors?.[
                                            providerConfigObject?.configKey
                                        ]
                                            ? 'This is required'
                                            : undefined
                                    }
                                    onChange={(value: any) =>
                                        setValue(
                                            providerConfigObject?.configKey,
                                            value,
                                        )
                                    }
                                    value={watch(
                                        providerConfigObject?.configKey,
                                    )}
                                    disabled={
                                        canOnlyViewChannel ||
                                        (integrationId &&
                                            !providerConfigObject?.override) ||
                                        isChannelProviderUpdating
                                    }
                                />
                                <br />
                            </>
                        ) : (
                            <Box key={providerConfigObject?.configKey}>
                                <br />
                                <TextInput
                                    type="text"
                                    placeholder={
                                        providerConfigObject?.fieldName
                                    }
                                    label={providerConfigObject?.fieldName}
                                    error={
                                        errors?.[
                                            providerConfigObject?.configKey
                                        ]
                                            ? 'This is required'
                                            : undefined
                                    }
                                    className="w-full h-10"
                                    {...register(
                                        providerConfigObject?.configKey,
                                        {
                                            required:
                                                providerConfigObject?.required,
                                        },
                                    )}
                                    disabled={
                                        canOnlyViewChannel ||
                                        isChannelProviderUpdating ||
                                        providerConfigObject?.configKey ===
                                            'callback_url'
                                    }
                                    rightSection={
                                        providerConfigObject?.configKey ===
                                            'callback_url' && (
                                            <ActionIcon
                                                onClick={() => {
                                                    void window.navigator.clipboard.writeText(
                                                        providerConfigObject?.defaultValue ??
                                                            '',
                                                    );
                                                }}
                                            >
                                                <Copy color="rgb(var(--color-gray-800))" />
                                            </ActionIcon>
                                        )
                                    }
                                    styles={() => ({
                                        wrapper: {
                                            '&:has(input:disabled) .mantine-Input-rightSection':
                                                {
                                                    display: 'flex',
                                                },
                                        },
                                    })}
                                    errorClass="mt-5"
                                />
                                <br />
                            </Box>
                        );
                    })}
                </Box>
                {!canOnlyViewChannel && (
                    <Flex className="mt-4" justify="flex-end" gap="md">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={handleCancel}
                        >
                            {t(
                                'channel_settings_add_channel_modal.secondary_button',
                            )}
                        </Button>
                        <Button
                            type="submit"
                            leftIcon={
                                (isChannelProviderUpdating ||
                                    isChannelProviderAdding) && (
                                    <LoaderIcon size="14" color="white" />
                                )
                            }
                        >
                            {submitButtonLabel}
                        </Button>
                    </Flex>
                )}
            </form>
        </Box>
    );
};

export default ProviderConfig;
