/*
This PasswordInput component should be wrapped in Controller if it is used
reat-hook-form. For the reference see packages/sortfe/src/components/ProjectConnection/WarehouseForms/SnowflakeForm.tsx
*/
import {
    PasswordInput as MantinePasswordInput,
    type PasswordInputProps as MantinePasswordInputProps,
} from '@mantine/core';
import React from 'react';
import { Eye, EyeOff } from 'react-feather';
const PasswordInput: React.FC<MantinePasswordInputProps> = ({ ...props }) => {
    return (
        <MantinePasswordInput
            visibilityToggleIcon={({ reveal }) =>
                reveal ? (
                    <Eye
                        size={13}
                        strokeWidth={2.5}
                        color={'rgb(var(--color-gray-600))'}
                        className="selected__item--icon-selected"
                    />
                ) : (
                    <EyeOff
                        size={13}
                        strokeWidth={2.5}
                        color={'rgb(var(--color-gray-600))'}
                        className="selected__item--icon-selected"
                    />
                )
            }
            {...props}
        />
    );
};
export default React.memo(PasswordInput);
