import { useLocale } from '@hooks/useLocale';
import { Box, Group, Stack, Text } from '@mantine/core';
import { PlayCircle } from '@phosphor-icons/react';
import React from 'react';
import EventsList from './EventsList';

const TriggerPanel: React.FC<{}> = () => {
    const { t } = useLocale();

    return (
        <Stack className="relative h-full gap-0">
            <Stack className="w-full gap-1 p-3 border-b border-b-gray-200">
                <Group className="gap-1.5">
                    <PlayCircle color={'rgb(var(--color-green))'} />
                    <Text className="text-sm font-medium text-gray-800">
                        {t('journey_builder.trigger_block_title')}
                    </Text>
                </Group>
                <Text className="text-sm font-medium text-gray-600">
                    {t('journey_builder.trigger_block_description')}
                </Text>
            </Stack>

            <Box className="flex-grow px-1.5 py-2 overflow-hidden">
                <EventsList />
            </Box>
        </Stack>
    );
};

export default React.memo(TriggerPanel);
