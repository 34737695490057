import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import DataTable from '@components/Table';
import { filterSuperAdmin } from '@components/Workspace/WorkspaceMembers';
import { useLocale } from '@hooks/useLocale';
import { useOrganizationUsers } from '@hooks/useOrganizationUsers';
import { useSortedUsers } from '@hooks/useSortedUsers';
import {
    ShowDataType,
    type OrganizationMemberProfile,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import { useApp } from '@providers/AppProvider';
import React from 'react';
import { useOrganizationMembersColumns } from './useOrganizationMembersTableUtils';

interface OrganizationMembersTableProps {}

const OrganizationMembersTable: React.FC<
    OrganizationMembersTableProps
> = ({}) => {
    const { data: organizationUsers, isLoading: isLoadingUsers } =
        useOrganizationUsers();

    const organizationMembersColumns = useOrganizationMembersColumns();
    const { t } = useLocale();
    const { user } = useApp();
    const { sortedUsers } =
        useSortedUsers<OrganizationMemberProfile>(organizationUsers);
    return (
        <Box>
            {isLoadingUsers ? (
                <SuboptimalState />
            ) : (
                organizationUsers &&
                organizationMembersColumns && (
                    <DataTable<OrganizationMemberProfile>
                        tableData={filterSuperAdmin<OrganizationMemberProfile>(
                            sortedUsers || organizationUsers,
                            user?.data?.userUuid || '',
                        )}
                        options={[
                            {
                                format: ShowDataType.LIST,
                                formatData: organizationMembersColumns,
                            },
                        ]}
                        isManualPagination={false}
                        searchPlaceholder={t(
                            'workspace_members.table_search_placeholder',
                        )}
                        paginationText={t('workspace_members.pagination_text')}
                        isEditable={false}
                    />
                )
            )}
        </Box>
    );
};

export default React.memo(OrganizationMembersTable);
