import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import { type ActionEventField } from '@components/Journeys/Builder/JourneyFilters/types';
import {
    JourneyNodeEnum,
    type JourneyNodeData,
} from '@components/Journeys/Builder/types';
import { getBlockIcon } from '@components/Journeys/Builder/utils';
import {
    getItemId as getFieldId,
    isDimension,
    journeyBlocks,
    JourneyGroup,
    JourneyTriggerType,
    RelationTableType,
    type FilterRule,
    type JourneyDataSchema,
    type JourneyNode,
} from '@lightdash/common';
import { type Edge, type Node } from 'reactflow';

/**
 * Get the number of nodes between the current nodeId and originNodeID.
 *
 * @param {string} nodeId - The ID of the current node.
 * @param {Node<JourneyNodeData>[]} nodes - The list of nodes.
 * @param {string} originNodeID - The ID of the origin node. If "entry", it is considered as the first node in the tree.
 * @param {Edge[]} edges - The list of edges connecting the nodes.
 * @returns {number} - The number of nodes between nodeId and originNodeID.
 */
export const getNodeLocationToFilter = (
    nodeId: string,
    nodes: Node<JourneyNodeData>[],
    originNodeID: string,
    edges: Edge[],
): number => {
    const visited: Set<string> = new Set();
    let nodeCount = 0;

    const traverse = (currentNodeId: string): boolean => {
        if (currentNodeId === nodeId) {
            return true;
        }

        visited.add(currentNodeId);

        const outgoingEdges = edges.filter(
            (edge) => edge.source === currentNodeId,
        );

        for (const edge of outgoingEdges) {
            const targetNodeId = edge.target;
            if (!visited.has(targetNodeId)) {
                const targetNode = nodes.find(
                    (node) => node.id === targetNodeId,
                );
                if (targetNode && traverse(targetNodeId)) {
                    nodeCount++; // Count each node in the path
                    return true;
                }
            }
        }

        return false;
    };

    const startNodeId = originNodeID === 'entry' ? nodes[0]?.id : originNodeID;
    if (startNodeId) {
        traverse(startNodeId);
    }

    return nodeCount;
};

export const getOriginNodeMetadata = (
    originNodeID: string,
    journeyNodes: JourneyNode[],
    nodeLocationToFilter: number,
): {
    eventSourceIcon: React.ReactNode;
    eventSourceLabel: string;
} => {
    if (originNodeID === JourneyTriggerType.ENTRY) {
        return {
            eventSourceIcon: getBlockIcon({
                type: JourneyNodeEnum.TRIGGER,
                actions: [],
                group: JourneyGroup.TRIGGER,
            }),
            eventSourceLabel: `Trigger (${nodeLocationToFilter} before)`,
        };
    }
    const originNode = journeyNodes.find((node) => node.id === originNodeID);
    if (!originNode) {
        return {
            eventSourceIcon: null,
            eventSourceLabel: '',
        };
    }
    const originNodeAction = originNode.actions[0].type;
    const blockId = originNode.metadata?.blockId;
    const block = journeyBlocks.find((b) => b.id === blockId);
    const originNodeActionIcon = getBlockIcon({
        type: JourneyNodeEnum.BLOCK,
        actions: [originNodeAction],
        group: JourneyGroup.ACTION,
    });
    return {
        eventSourceIcon: originNodeActionIcon,
        eventSourceLabel: `${block?.title} (${nodeLocationToFilter} before)`,
    };
};

/**
 * Utility function to get the active event field.
 * @param transientEventFilterTable - The transient event filter table.
 * @param journeyDataSchema - The journey data schema.
 * @param filterRules - The filter rules.
 * @param allFields - All fields with suggestions.
 * @param nodes - The nodes in the journey.
 * @param nodeId - The ID of the current node.
 * @param edges - The edges in the journey.
 * @param journeyNodes - The nodes configuration in the journey payload.
 * @returns The active event field or undefined.
 */
export const getActiveEventField = (
    transientEventFilterTable: string | undefined,
    journeyDataSchema: JourneyDataSchema | undefined,
    filterRules: FilterRule[] | undefined,
    allFields: FieldWithSuggestions[],
    nodes: Node[],
    nodeId: string,
    edges: Edge[],
    journeyNodes: JourneyNode[],
): ActionEventField | undefined => {
    const getOriginNodeMetadataForField = (
        originNode: string,
        nodeLocationToFilter: number,
    ) => {
        return getOriginNodeMetadata(
            originNode,
            journeyNodes,
            nodeLocationToFilter,
        );
    };

    const getNodeLocationToFilterForField = (originNode: string) => {
        return getNodeLocationToFilter(nodeId, nodes, originNode, edges);
    };

    if (transientEventFilterTable && journeyDataSchema) {
        const transientTable =
            journeyDataSchema.tables[transientEventFilterTable];
        if (!transientTable) return;

        const originNode = transientTable.nodeId;
        if (!originNode) return;

        const nodeLocationToFilter =
            getNodeLocationToFilterForField(originNode);
        const originNodeMetadata = getOriginNodeMetadataForField(
            originNode,
            nodeLocationToFilter,
        );

        return {
            eventName: transientTable.name,
            eventLabel: transientTable.label ?? transientTable.name,
            eventSourceIcon: originNodeMetadata.eventSourceIcon,
            eventSourceLabel: originNodeMetadata.eventSourceLabel,
            table: transientEventFilterTable,
        };
    }

    if (!filterRules || !filterRules.length) return;

    const firstFilterRule = filterRules[0];
    const fieldTarget = firstFilterRule.target;
    const fieldId = fieldTarget.fieldId;
    const selectedField = allFields.find(
        (field) => getFieldId(field) === fieldId,
    );

    if (!selectedField || !isDimension(selectedField)) return;

    const selectedDimensionType = selectedField.tableType;
    if (selectedDimensionType !== RelationTableType.EVENT) return;

    const selectedTable = journeyDataSchema?.tables[selectedField.table];
    const originNode = selectedTable?.nodeId;
    if (!originNode) return;

    const nodeLocationToFilter = getNodeLocationToFilterForField(originNode);
    const originNodeMetadata = getOriginNodeMetadataForField(
        originNode,
        nodeLocationToFilter,
    );

    return {
        eventName: selectedField.name,
        eventLabel: selectedField.tableLabel ?? selectedField.name,
        eventSourceIcon: originNodeMetadata.eventSourceIcon,
        eventSourceLabel: originNodeMetadata.eventSourceLabel,
        table: selectedField.table,
    };
};
