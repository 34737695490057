import {
    RelationTableType,
    type CompiledRelation,
    type FilterableField,
    type FilterRule,
} from '@lightdash/common';
import { t as translate } from 'i18next';

/**
 * Checks if a given filterable field is from table of type audience.
 *
 * @param field - The filterable field to check.
 * @param relation - The compiled relation containing the tables to inspect.
 * @returns True if the field's table matches an audience table; otherwise, false.
 */
export const isFieldAudience = (
    field: FilterableField,
    relation: CompiledRelation,
) => {
    const tables = relation.tables;
    for (const key of Object.keys(tables)) {
        const table = tables[key];
        if (
            field.table === table.name &&
            table.type === RelationTableType.AUDIENCE
        ) {
            return true;
        }
    }

    return false;
};

/**
 * Retrieves the primary field ID of the base table from the compiled relation.
 *
 * @param relation - The compiled relation containing the tables.
 * @returns The primary field ID of the base table in the format "baseTableId_primaryKey".
 * @throws Error if the base table does not exist or if the primary key is undefined.
 */
export const getBaseTablePrimaryFieldId = (relation: CompiledRelation) => {
    const baseTableId: string = relation.baseTable;

    // Check if the base table exists in the relation
    const baseTable = relation.tables[baseTableId];
    if (!baseTable) {
        throw new Error(
            translate('filter_rule.no_base_table_error', {
                baseTableId,
            }),
        );
    }

    // Check if the primary key is defined
    if (baseTable.primaryKey === undefined) {
        throw new Error(
            translate('filter_rule.no_primary_key_error', {
                baseTableId,
            }),
        );
    }

    return `${baseTableId}_${baseTable.primaryKey}`;
};

/**
 * Checks if a given filter rule is an audience filter rule.
 *
 * @param filterRule - The filter rule to check, which includes the target field ID and values.
 * @param relation - The compiled relation containing the tables to inspect.
 * @returns True if the filter rule's field ID matches the base table's primary field ID and the value starts with "srt_audience_"; otherwise, false.
 */

export const isFilterRuleAudience = (
    filterRule: FilterRule,
    relation: CompiledRelation,
) => {
    const filterRuleValue = filterRule.values?.[0];
    const filterRuleFieldId = filterRule.target.fieldId;
    const baseTablePrimaryFieldId =
        relation && getBaseTablePrimaryFieldId(relation);

    return (
        baseTablePrimaryFieldId === filterRuleFieldId &&
        typeof filterRuleValue === 'string' &&
        filterRuleValue.startsWith('srt_audience_')
    );
};
