import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import { type Campaign } from '@lightdash/common';
import { type TemplateDetails } from '@providers/CampaignProvider';
import React from 'react';
import TemplateSelectionModal from './JourneyTemplateSelectionModal';
import TemplateSetupModal from './JourneyTemplateSetupModal';

interface JourneyContentSetupManagerModalProps {
    opened: boolean;
    closeContentSetupManager: () => void;
    templateDetails: Campaign['templateDetails'] | undefined;
    communicationDetails: Campaign['communicationDetails'] | undefined;
    setJourneyContentPayload: (
        templateDetails: Partial<TemplateDetails> | null,
        communicationDetails: Campaign['communicationDetails'] | undefined,
    ) => void;
    fields: FieldsWithSuggestions;
}

const JourneyContentSetupManagerModal: React.FC<
    JourneyContentSetupManagerModalProps
> = ({
    opened,
    closeContentSetupManager,
    templateDetails,
    setJourneyContentPayload,
    communicationDetails,
    fields,
}) => {
    return templateDetails?.id ? (
        <TemplateSetupModal
            opened={opened}
            closeTemplateSetup={closeContentSetupManager}
            templateDetails={templateDetails}
            setJourneyContentPayload={setJourneyContentPayload}
            fields={fields}
        />
    ) : (
        <TemplateSelectionModal
            opened={opened}
            closeTemplateSelection={closeContentSetupManager}
            communicationDetails={communicationDetails}
            setJourneyContentPayload={setJourneyContentPayload}
        />
    );
};

export default React.memo(JourneyContentSetupManagerModal);
