import { useLocale } from '@hooks/useLocale';
import useLogoutMutation from '@hooks/user/useUserLogoutMutation';
import { Box, Text } from '@mantine/core';
import { useApp } from '@providers/AppProvider';
import { type FC } from 'react';

const OnBoardingFooter: FC<React.PropsWithChildren> = () => {
    const { user } = useApp();
    const { t } = useLocale();
    const { mutate: logout } = useLogoutMutation({
        onSuccess: () => {
            window.location.href = '/login';
        },
    });
    return (
        <Box
            className="flex gap-6 pb-5 "
            style={{ color: 'rgba(189, 189, 189, 1)' }}
        >
            <Text>{t('organization_creation.footer_name')}</Text>
            {' ·'}
            <Text>{t('organization_creation.footer_privacy_policy')}</Text>
            {'·'}
            <Text
                onClick={() => {
                    logout();
                }}
                className="cursor-pointer"
            >
                {t('organization_creation.footer_sign_out')}
            </Text>
            <Text>{`(${user.data?.email})`}</Text>
        </Box>
    );
};
export default OnBoardingFooter;
