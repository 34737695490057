import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import SettingsTitle from '@components/common/Settings/SettingsTitle';
import WorkspaceSettingsForm from '@components/Workspace/WorkspaceSettingsForm';
import { useLocale } from '@hooks/useLocale';
import { Box, Flex } from '@mantine/core';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

const Overview: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const ability = useAbilityContext();
    const canAccessProjectSetting = ability.can(
        'view',
        subject('ProjectSettings', {
            projectUuid,
        }),
    );
    if (!canAccessProjectSetting) {
        return <Redirect to="/no-access" />;
    }
    return (
        <Box>
            <Flex className="items-center justify-between w-full">
                <SettingsTitle title={t('workspace_settings.overview_title')} />
            </Flex>

            <Box className="my-6">
                <WorkspaceSettingsForm />
            </Box>
        </Box>
    );
};

export default React.memo(Overview);
