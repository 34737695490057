import { subject } from '@casl/ability';
import {
    getButtonHighlightClassName,
    modifyFieldsWithSelectionStatus,
} from '@components/Audience/Filters/FieldListItem/utils';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { useAbilityContext } from '@components/common/Authorization';
import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import { useLocale } from '@hooks/useLocale';
import {
    useCreateProfile,
    useGetProfileConfig,
    useGetProfiles,
    useUpdateProfile,
} from '@hooks/useProfile';
import { getItemId, JoinType } from '@lightdash/common';
import { Box, Button } from '@mantine/core';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import { Spiral, SquareSplitHorizontal } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonVariant } from '../../../mantineTheme';
import ProfileTable from '../Table/ProfileTable';
import {
    formatProfileData,
    toFieldsWithSuggestions,
    updateProfilePayload,
} from '../utils';
import ProfileInitialCreateState from './ProfileInitialCreateState';
import ProfileManagerPropertySelect from './ProfileManagerPropertySelect';
import ProfileTitle from './ProfileTitle';

const ProfileBuilderContainer: React.FC<{}> = ({}) => {
    const { t } = useLocale();

    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { activeRelationUuid, getTableRelation, activeRelation } =
        useRelationContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchString, setSearchString] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const [opened, { open, close }] = useDisclosure(false);
    const { data: config, isInitialLoading } = useGetProfileConfig(projectUuid);
    const { user } = useApp();
    const ability = useAbilityContext();

    const canManageProfiles = ability.can(
        'manage',
        subject('Profile', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const {
        mutate: createProfile,
        isLoading: createLoading,
        isSuccess: creationSuccess,
    } = useCreateProfile(projectUuid);
    const {
        mutate: updateProfile,
        isLoading: updateLoading,
        isSuccess: updationSuccess,
    } = useUpdateProfile(projectUuid);
    const { data: profiles } = useGetProfiles({
        projectUuid,
        perPage: 10,
        currentPage,
        data:
            searchString.length > 0
                ? {
                      [config?.primaryKey as string]: {
                          type: 'like',
                          value: searchString,
                      },
                  }
                : {},
    });
    const pages = {
        total: 0,
        lastPage: 0,
        currentPage: 0,
        perPage: 0,
        from: 0,
        to: 0,
        ...profiles?.paginate,
    };
    const isProfileCreated = config && Object.values(config).length > 0;
    const filteredRelation = getTableRelation([
        JoinType.one_one,
        JoinType.many_one,
    ]);
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });
    const fields = toFieldsWithSuggestions(
        filteredRelation,
        fieldsWithSuggestions,
    );
    const filterFields = useMemo(() => {
        const keysOfFields = Object.keys(fields);
        const filteredFields = config?.metricQuery?.dimensions
            .filter((field: string) =>
                keysOfFields.some((present_field) => present_field === field),
            )
            .filter((fieldId) => fieldId);
        return filteredFields;
    }, [fields, config]);
    if (isInitialLoading) {
        return <SuboptimalState />;
    }

    const handleSearchInput = (value: string) => {
        setSearchString(value);
        setCurrentPage(1);
    };

    return (
        <ManagerBuilderContainer
            withContentPadding={false}
            title={
                isProfileCreated ? (
                    <ProfileTitle profile={config} count={pages.total} />
                ) : (
                    t('profiles')
                )
            }
        >
            <Box>
                {isProfileCreated ? (
                    <ProfileTable
                        profiles={profiles?.data}
                        rightSection={
                            canManageProfiles && (
                                <ProfileManagerPropertySelect
                                    fields={modifyFieldsWithSelectionStatus({
                                        fields: Object.values(fields),
                                        selectedFieldIds: filterFields ?? [],
                                        shouldDisableChecked: true,
                                    })}
                                    onSubmit={(items) => {
                                        updateProfile(
                                            updateProfilePayload({
                                                payload: config,
                                                newColumns: items.map((item) =>
                                                    getItemId(item),
                                                ),
                                            }),
                                        );
                                    }}
                                    isLoading={updateLoading}
                                    isSuccess={updationSuccess}
                                    opened={opened}
                                    close={close}
                                    open={open}
                                    targetButton={
                                        <Button
                                            variant={ButtonVariant.OUTLINED}
                                            className={getButtonHighlightClassName(
                                                opened,
                                            )}
                                            leftIcon={
                                                <SquareSplitHorizontal
                                                    weight={'duotone'}
                                                    color={
                                                        opened
                                                            ? 'rgb(var(--color-blu-800))'
                                                            : 'rgb(var(--color-gray-800))'
                                                    }
                                                />
                                            }
                                        >
                                            {t(
                                                'profiles_manager.edit_columns_button',
                                            )}
                                        </Button>
                                    }
                                />
                            )
                        }
                        onPageChange={async (newPage) => {
                            setCurrentPage(newPage);
                        }}
                        onSearchChange={handleSearchInput}
                        pagination={pages}
                        fields={config.metricQuery?.dimensions}
                        id={config.primaryKey}
                        searchValue={searchString}
                    />
                ) : (
                    <Box className=" bg-gray-100/60">
                        <ProfileInitialCreateState
                            title={t(
                                'profiles_manager.create_initial_state_title',
                            )}
                            description={
                                canManageProfiles
                                    ? t(
                                          'profiles_manager.create_initial_state_description',
                                      )
                                    : t(
                                          'profiles_manager.cannot_create_profiles_text',
                                      )
                            }
                            buttonComponent={
                                canManageProfiles && (
                                    <ProfileManagerPropertySelect
                                        fields={modifyFieldsWithSelectionStatus(
                                            {
                                                fields: Object.values(fields),
                                                selectedFieldIds: [],
                                                shouldDisableChecked: true,
                                            },
                                        )}
                                        onSubmit={(items) => {
                                            createProfile(
                                                formatProfileData({
                                                    projectUuid,
                                                    relationUuid:
                                                        activeRelationUuid,
                                                    newColumns: items.map(
                                                        (item) =>
                                                            getItemId(item),
                                                    ),
                                                }),
                                            );
                                        }}
                                        isLoading={createLoading}
                                        isSuccess={creationSuccess}
                                        opened={opened}
                                        close={close}
                                        open={open}
                                        targetButton={
                                            <Button
                                                variant={ButtonVariant.FILLED}
                                                leftIcon={
                                                    <Spiral color={'white'} />
                                                }
                                            >
                                                {t(
                                                    'audience_preview.modal_choose_properties',
                                                )}
                                            </Button>
                                        }
                                    />
                                )
                            }
                        />
                    </Box>
                )}
            </Box>
        </ManagerBuilderContainer>
    );
};
export default ProfileBuilderContainer;
