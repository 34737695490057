import AccountSettings from '@pages/AccountSettings';
import AudienceBuilder from '@pages/AudienceBuilder';
import AudiencesManager from '@pages/AudiencesManager';
import BlobStorage from '@pages/BlobStorage';
import CampaignManager from '@pages/CampaignManager';
import Channel from '@pages/Channel';
import CreateOrganization from '@pages/CreateOrganization';
import CreateProject from '@pages/CreateProject';
import CreateWorkspace from '@pages/CreateWorkspace';
import ProfilesManager from '@pages/ProfilesManager';
import TemplateManager from '@pages/TemplateManager';
// import CreateWorkspace from '@pages/CreateWorkspace';
import Invite from '@pages/Invite';
import JoinOrganization from '@pages/JoinOrganization';
import Login from '@pages/Login';
import OrganizationSettings from '@pages/OrganizationSettings';
import PasswordRecovery from '@pages/PasswordRecovery';
import PasswordReset from '@pages/PasswordReset';
import Projects from '@pages/Projects';
import ProjectSettings from '@pages/ProjectSettings';
import Register from '@pages/Register';
import Relations from '@pages/Relations';
import VerifyEmail from '@pages/VerifyEmail';
import { type FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// import ForbiddenPanel from './components/ForbiddenPanel';
import ProjectRoute from '@components/Router/ProjectRoute';
import JobDetailsDrawer from './components/JobDetailsDrawer';
import NavBar from './components/NavBar';

import Catalog from './pages/Catalog';
import ChartHistory from './pages/ChartHistory';
// import CreateProjectSettings from './pages/CreateProjectSettings';
import CampaignAnalyticsDashboard from './pages/CampaignAnalyticsDashboard';
import Dashboard from './pages/Dashboard';
import Explorer from './pages/Explorer';
// import Home from './pages/Home';
import MetricFlowPage from './pages/MetricFlow';
// import MinimalDashboard from './pages/MinimalDashboard';
// import MinimalSavedExplorer from './pages/MinimalSavedExplorer';
import SavedDashboards from './pages/SavedDashboards';
import SavedExplorer from './pages/SavedExplorer';
import SavedQueries from './pages/SavedQueries';
// import Settings from './pages/Settings';
// import ShareRedirect from './pages/ShareRedirect';
import SemanticViewerPage from './pages/SemanticViewer';
import Space from './pages/Space';
import Spaces from './pages/Spaces';
import SqlRunner from './pages/SqlRunner';
// import UserActivity from './pages/UserActivity';
// import VerifyEmailPage from './pages/VerifyEmail';
import { JourneyBuilderMode } from '@components/Journeys/Builder/types';
import AppRoute from '@components/Router/AppRouter';
import PrivateRoute from '@components/Router/PrivateRoute';
import CampaignBuilder from '@pages/CampaignBuilder';
import CreateSchema from '@pages/CreateSchema';
import CustomMetricManager from '@pages/CustomMetricManager';
import EventsBuilder from '@pages/EventsBuillder';
import EventsManager from '@pages/EventsManager';
import JourneyBuilder from '@pages/JourneyBuilder';
import JourneysManager from '@pages/JourneysManager';
import ProfilesBuilder from '@pages/ProfilesBuilder';
import SubscriptionPreferences from '@pages/SubscriptionPreferences';
import UnsubscribeGroup from '@pages/UnsubscribeGroup';
import { t } from 'i18next';
import SqlRunnerNew from './pages/SqlRunnerNew';
import ViewSqlChart from './pages/ViewSqlChart';
import { TrackPage } from './providers/TrackingProvider';
import { PageName } from './types/Events';

const Routes: FC = () => {
    return (
        <Switch>
            <Route path="/register">
                <Register />
            </Route>

            <Route path="/login">
                <Login isSuperAdminLogin={false} />
            </Route>
            <Route path="/super-admin-login">
                <Login isSuperAdminLogin={true} />
            </Route>

            <Route path="/recover-password">
                <PasswordRecovery />
            </Route>

            <Route path="/reset-password/:code">
                <PasswordReset />
            </Route>

            <Route path="/invite/:inviteCode">
                <Invite />
            </Route>

            <Route path="/verify-email">
                <VerifyEmail />
            </Route>

            <Route path="/join-organization">
                <JoinOrganization />
            </Route>
            <Route path="/unsubscribe">
                <UnsubscribeGroup />
            </Route>
            <Route path="/subscription-preferences">
                <SubscriptionPreferences />
            </Route>

            <PrivateRoute path="/">
                <JobDetailsDrawer />
                <Switch>
                    <Route path="/create-organization">
                        <CreateOrganization />
                    </Route>
                    <Route path="/create-workspace">
                        <CreateWorkspace />
                    </Route>

                    <Route path="/account/:tab?">
                        <AccountSettings />
                    </Route>
                    <Route path="/organization/:tab?">
                        <OrganizationSettings />
                    </Route>

                    <AppRoute path="/">
                        <Switch>
                            <ProjectRoute path="/projects/:projectUuid">
                                <Switch>
                                    <Route path="/projects/:projectUuid/warehouse">
                                        <NavBar
                                            showMenuItems={false}
                                            showRelation={false}
                                            showCustomMetric={false}
                                            showEventsManager={false}
                                        />
                                        <CreateProject />
                                    </Route>
                                    <Route path="/projects/:projectUuid/blob">
                                        <NavBar
                                            showMenuItems={false}
                                            showRelation={false}
                                            showCustomMetric={false}
                                            showEventsManager={false}
                                        />
                                        <BlobStorage />
                                    </Route>
                                    <Route path="/projects/:projectUuid/channels">
                                        <NavBar
                                            showMenuItems={false}
                                            showRelation={false}
                                            showCustomMetric={false}
                                            showEventsManager={false}
                                        />
                                        <Channel
                                            allowChannelSetup={true}
                                            handleProviderSelect={undefined}
                                            isOnboarding={true}
                                            title={t('channels')}
                                        />
                                    </Route>
                                    <Route path="/projects/:projectUuid/saved/:savedQueryUuid/history">
                                        <NavBar />
                                        <TrackPage
                                            name={PageName.CHART_HISTORY}
                                        >
                                            <ChartHistory />
                                        </TrackPage>
                                    </Route>
                                    <Route path="/projects/:projectUuid/saved/:savedQueryUuid/:mode?">
                                        <NavBar />
                                        <TrackPage
                                            name={PageName.SAVED_QUERY_EXPLORER}
                                        >
                                            <SavedExplorer />
                                        </TrackPage>
                                    </Route>

                                    <Route path="/projects/:projectUuid/saved">
                                        <NavBar />
                                        <TrackPage
                                            name={PageName.SAVED_QUERIES}
                                        >
                                            <SavedQueries />
                                        </TrackPage>
                                    </Route>

                                    <Route path="/projects/:projectUuid/relations/create">
                                        <NavBar showMenuItems={false} />
                                        <CreateSchema />
                                    </Route>

                                    <Route path="/projects/:projectUuid/relations">
                                        <NavBar />
                                        <Relations />
                                    </Route>

                                    <Route path="/projects/:projectUuid/audiences/create">
                                        <NavBar />
                                        <AudienceBuilder />
                                    </Route>
                                    <Route path="/projects/:projectUuid/audiences/:audienceUuid/:mode?">
                                        <NavBar />
                                        <AudienceBuilder />
                                    </Route>
                                    <Route
                                        path="/projects/:projectUuid/dashboards/:dashboardUuid/:mode?/tabs/:tabUuid?"
                                        render={(props) => (
                                            <>
                                                <NavBar />
                                                <TrackPage
                                                    name={PageName.DASHBOARD}
                                                >
                                                    <Dashboard
                                                        key={
                                                            props.match.params
                                                                .tabUuid
                                                        }
                                                    />
                                                </TrackPage>
                                            </>
                                        )}
                                    />

                                    <Route
                                        path="/projects/:projectUuid/dashboards/:dashboardUuid/:mode?"
                                        render={(props) => (
                                            <>
                                                <NavBar />
                                                <TrackPage
                                                    name={PageName.DASHBOARD}
                                                >
                                                    <Dashboard
                                                        key={
                                                            props.match.params
                                                                .dashboardUuid
                                                        }
                                                    />
                                                </TrackPage>
                                            </>
                                        )}
                                    />
                                    <Route path="/projects/:projectUuid/audiences">
                                        <NavBar />
                                        <AudiencesManager />
                                    </Route>
                                    <Route path="/projects/:projectUuid/templates">
                                        <NavBar />
                                        <TemplateManager />
                                    </Route>
                                    <Route path="/projects/:projectUuid/profiles/:profileId">
                                        <NavBar />
                                        <ProfilesBuilder />
                                    </Route>
                                    <Route path="/projects/:projectUuid/profiles">
                                        <NavBar />
                                        <ProfilesManager />
                                    </Route>
                                    <Route path="/projects/:projectUuid/dashboards">
                                        <NavBar />
                                        <TrackPage
                                            name={PageName.SAVED_DASHBOARDS}
                                        >
                                            <SavedDashboards />
                                        </TrackPage>
                                    </Route>

                                    <Route path="/projects/:projectUuid/sqlRunner">
                                        <NavBar />
                                        <TrackPage name={PageName.SQL_RUNNER}>
                                            <SqlRunner />
                                        </TrackPage>
                                    </Route>

                                    <Route
                                        exact
                                        path="/projects/:projectUuid/sql-runner/:slug"
                                    >
                                        <NavBar />

                                        <ViewSqlChart />
                                    </Route>

                                    <Route
                                        exact
                                        path="/projects/:projectUuid/sql-runner/:slug/edit"
                                    >
                                        <NavBar />

                                        <SqlRunnerNew />
                                    </Route>

                                    <Route
                                        exact
                                        path="/projects/:projectUuid/sql-runner"
                                    >
                                        <NavBar />

                                        <SqlRunnerNew />
                                    </Route>

                                    <Route path="/projects/:projectUuid/dbtsemanticlayer">
                                        <NavBar />
                                        <TrackPage name={PageName.METRIC_FLOW}>
                                            <MetricFlowPage />
                                        </TrackPage>
                                    </Route>

                                    <Route
                                        exact
                                        path="/projects/:projectUuid/semantic-viewer"
                                    >
                                        <NavBar />

                                        <TrackPage
                                            name={PageName.SEMANTIC_VIEWER}
                                        >
                                            <SemanticViewerPage />
                                        </TrackPage>
                                    </Route>

                                    <Route path="/projects/:projectUuid/tables/:tableId">
                                        <NavBar />
                                        <TrackPage name={PageName.EXPLORER}>
                                            <Explorer />
                                        </TrackPage>
                                    </Route>

                                    <Route path="/projects/:projectUuid/tables">
                                        <NavBar />
                                        <TrackPage
                                            name={PageName.EXPLORE_TABLES}
                                        >
                                            <Explorer />
                                        </TrackPage>
                                    </Route>

                                    <Route path="/projects/:projectUuid/spaces/:spaceUuid">
                                        <NavBar />
                                        <TrackPage name={PageName.SPACE}>
                                            <Space />
                                        </TrackPage>
                                    </Route>

                                    <Route path="/projects/:projectUuid/spaces">
                                        <NavBar />
                                        <TrackPage name={PageName.SPACES}>
                                            <Spaces />
                                        </TrackPage>
                                    </Route>

                                    <Route path="/projects/:projectUuid/campaigns/create">
                                        <TrackPage
                                            name={PageName.CREATE_CAMPAIGN}
                                        >
                                            <CampaignBuilder />
                                        </TrackPage>
                                    </Route>
                                    <Route path="/projects/:projectUuid/events/create">
                                        <TrackPage
                                            name={PageName.CREATE_EVENTS}
                                        >
                                            <EventsBuilder />
                                        </TrackPage>
                                    </Route>
                                    <Route
                                        exact
                                        path="/projects/:projectUuid/campaigns/:campaignUuid/:mode?"
                                    >
                                        <CampaignBuilder />
                                    </Route>
                                    <Route
                                        exact
                                        path="/projects/:projectUuid/events/:eventsUuid/:source/:mode?"
                                    >
                                        <EventsBuilder />
                                    </Route>
                                    <Route
                                        path="/projects/:projectUuid/campaigns/dashboards/:dashboardUuid/:mode?"
                                        render={(props) => (
                                            <>
                                                <NavBar />
                                                <TrackPage
                                                    name={PageName.DASHBOARD}
                                                >
                                                    <CampaignAnalyticsDashboard
                                                        key={
                                                            props.match.params
                                                                .dashboardUuid
                                                        }
                                                    />
                                                </TrackPage>
                                            </>
                                        )}
                                    />

                                    <Route
                                        exact
                                        path="/projects/:projectUuid/campaigns"
                                    >
                                        <NavBar />
                                        <CampaignManager />
                                    </Route>
                                    <Route
                                        exact
                                        path="/projects/:projectUuid/custom-traits"
                                    >
                                        <NavBar />
                                        <CustomMetricManager />
                                    </Route>
                                    <Route
                                        exact
                                        path="/projects/:projectUuid/events"
                                    >
                                        <NavBar />
                                        <EventsManager />
                                    </Route>
                                    <Route path="/projects/:projectUuid/settings/:tab?">
                                        <NavBar />
                                        <ProjectSettings />
                                    </Route>

                                    <Route
                                        path="/projects/:projectUuid/catalog"
                                        exact
                                    >
                                        <NavBar />
                                        <TrackPage name={PageName.CATALOG}>
                                            <Catalog />
                                        </TrackPage>
                                    </Route>
                                    <Route
                                        path={`/projects/:projectUuid/journeys/${JourneyBuilderMode.CREATE}`}
                                    >
                                        <JourneyBuilder />
                                    </Route>
                                    <Route
                                        path={`/projects/:projectUuid/journeys/:journeyUuid/:mode?`}
                                    >
                                        <JourneyBuilder />
                                    </Route>
                                    <Route path="/projects/:projectUuid/journeys">
                                        <NavBar />
                                        <JourneysManager />
                                    </Route>

                                    <Redirect to="/projects" />
                                </Switch>
                            </ProjectRoute>

                            <Route path="/projects/:projectUuid?" exact>
                                <Projects />
                            </Route>

                            <Redirect to="/projects" />
                        </Switch>
                    </AppRoute>
                </Switch>
            </PrivateRoute>
        </Switch>
    );
};

export default Routes;
