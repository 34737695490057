import UnsavedChangesConfirmModal from '@components/common/modal/UnsavedChangesConfirmModal';
import { useLocale } from '@hooks/useLocale';
import { Button, Group, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonVariant } from '../../../../mantineTheme';
import { type BuilderHeaderProps } from '../type';
import BuilderProgress from './BuilderProgress';

const BuilderHeader = <T,>({
    title,
    exitLink,
    currentStep,
    isValidStep,
    setCurrentStep,
    builderSteps,
    showModal,
}: BuilderHeaderProps<T>) => {
    const { t } = useLocale();
    const [opened, { open, close }] = useDisclosure();
    const history = useHistory();
    return (
        <>
            <Group position="apart" className="w-full">
                <Title className="text-base font-medium text-gray-800">
                    {title}
                </Title>
                <BuilderProgress<T>
                    currentStep={currentStep}
                    isValidStep={isValidStep}
                    setCurrentStep={setCurrentStep}
                    builderSteps={builderSteps}
                />
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={() => {
                        if (showModal) {
                            open();
                        } else {
                            history.push(exitLink);
                        }
                    }}
                >
                    {t('common.exit')}
                </Button>
            </Group>
            <UnsavedChangesConfirmModal
                opened={opened}
                close={close}
                primaryActionButtonClick={() => {
                    history.push(exitLink);
                }}
                secondaryActionButtonClick={() => {
                    close();
                }}
            />
        </>
    );
};

export default BuilderHeader;
