import DataTable from '@components/Table';
import { useLocale } from '@hooks/useLocale';
import {
    useTableEventConfig,
    useUpdateTableDetail,
} from '@hooks/useSchemaBuilder';
import { ShowDataType, type CachedColumnValues } from '@lightdash/common';
import { Box, Stack, Text } from '@mantine/core';
import { ArrowsCounterClockwise } from '@phosphor-icons/react';
import { useRelationContext } from '@providers/RelationProvider';
import { useSchemaContext } from '@providers/SchemaProvider';
import React from 'react';
import { FontSizes } from '../../../../../mantineTheme';

const TableEvents: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { activeRelationUuid } = useRelationContext();
    const { schemaPayload } = useSchemaContext((context) => context.state);

    const { data } = useTableEventConfig({
        relationUuid: activeRelationUuid,
        tableId: schemaPayload?.name,
        dimId: schemaPayload?.eventNameColumn,
    });
    const { mutate } = useUpdateTableDetail(activeRelationUuid);

    if (!data?.cachedColumnValues?.length)
        return (
            <Stack
                className="h-[calc(100vh-20rem)]"
                align="center"
                justify="center"
            >
                <Text
                    align="center"
                    size={FontSizes.xs}
                    color={'rgb(var(--color-gray-600))'}
                >
                    {t('fetching-events')}
                </Text>
            </Stack>
        );

    return (
        <DataTable<CachedColumnValues>
            tableData={data?.cachedColumnValues ?? []}
            searchPlaceholder="Search events"
            leftSection={
                <ArrowsCounterClockwise
                    weight="regular"
                    className="cursor-pointer absolute right-10"
                    onClick={() =>
                        mutate({
                            tableName: schemaPayload.name,
                            payload: {
                                type: schemaPayload.type,
                                dimensions: {
                                    [schemaPayload.eventNameColumn]: {
                                        cached: true,
                                    },
                                },
                            },
                        })
                    }
                />
            }
            options={[
                {
                    format: ShowDataType.LIST,
                    formatData: [
                        {
                            accessorKey: 'name',
                            header: 'eventname',
                            cell: ({ row }) => (
                                <Box className="">{row?.original?.name}</Box>
                            ),
                        },
                        {
                            accessorKey: 'lavel',
                            header: 'LABEL',
                            cell: ({ row }) => (
                                <Box className="">{row?.original?.label}</Box>
                            ),
                        },
                    ],
                },
            ]}
            isManualPagination={false}
            isEditable={false}
        />
    );
};

export default TableEvents;
