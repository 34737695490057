import { Box } from '@mantine/core';
// import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import React from 'react';
import {
    BaseEdge,
    EdgeLabelRenderer,
    getBezierPath,
    type EdgeProps,
} from 'reactflow';
// import AddNodeButton from '../AddNodeButton';

const BlockEdge: React.FC<EdgeProps> = ({
    // id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}) => {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    // const { addPlaceholderNodeBetween } = useJourneyBuilderContext(
    //     (context) => context.actions,
    // );

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <Box
                    style={{
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                    }}
                    className="absolute pointer-events-auto"
                >
                    <Box className="bg-white">
                        {/* <AddNodeButton
                            onAddNodeClick={() => {
                                addPlaceholderNodeBetween(id);
                            }}
                        /> */}
                    </Box>
                </Box>
            </EdgeLabelRenderer>
        </>
    );
};

export default BlockEdge;
