import { LightdashMode, type HealthState } from '@lightdash/common';
import { useEffect, useState } from 'react';

const useClarity = (healthState: HealthState | undefined) => {
    const [isClarityLoaded, setIsClarityLoaded] = useState(false);

    useEffect(() => {
        if (
            isClarityLoaded ||
            !healthState ||
            !healthState.clarity.key ||
            healthState.mode !== LightdashMode.CLOUD_BETA
        )
            return;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${healthState.clarity.key}");
    `;
        document.head.appendChild(script);
        setIsClarityLoaded(true);

        return () => {
            document.head.removeChild(script);
        };
    }, [isClarityLoaded, healthState]);

    return isClarityLoaded;
};

export default useClarity;
