import DataTable from '@components/Table';
import { useLocale } from '@hooks/useLocale';
import { ShowDataType, type DimensionType } from '@lightdash/common';
import { Box } from '@mantine/core';
import React from 'react';
import { useAudiencePreviewColumns } from './useAudiencePreviewTableUtils';

import LoadingState from '@components/common/LoadingState';
import { AUDIENCE_PREVIEW_LIMIT } from '@utils/constants';

interface AudiencePreviewTableProps {
    data: Record<string, unknown>[];
    fields: Record<string, { type: DimensionType }>;
    totalResultCount: number;
    isFetching: boolean;
    generationStrategy: string;
    cellHeight?: string;
    customClass: string;
}

const AudiencePreviewTable: React.FC<AudiencePreviewTableProps> = ({
    data,
    fields,
    totalResultCount,
    isFetching,
    generationStrategy,
    cellHeight = ' h-14 ',
    customClass,
}) => {
    const { columns: audiencePreviewColumns } = useAudiencePreviewColumns(
        fields,
        generationStrategy,
    );
    const { t } = useLocale();

    if (isFetching) {
        return <LoadingState title="" />;
    }
    return (
        <Box>
            {audiencePreviewColumns && (
                <DataTable<Record<string, unknown>>
                    tableData={data}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: audiencePreviewColumns,
                        },
                    ]}
                    isManualPagination={false}
                    paginationText={t('audience_manager.pagination_text')}
                    withBorder={true}
                    customClass={`overflow-y-scroll ${customClass}`}
                    showSearch={false}
                    totalResultCount={totalResultCount}
                    cellHeight={cellHeight}
                    isEditable={false}
                    previewLimit={AUDIENCE_PREVIEW_LIMIT}
                />
            )}
        </Box>
    );
};

export default React.memo(AudiencePreviewTable);
