import { Box, Button } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import { useAudienceContext } from '@providers/AudienceProvider';
import isEmpty from 'lodash/isEmpty';
import { memo, useMemo, type FC } from 'react';
import { ButtonVariant } from '../../mantineTheme';
import FilterGroupOperatorCondition from './Filters/FiltersCard/FilterGroupOperatorCondition';
import FiltersCard from './Filters/FiltersCard/FiltersCard';

interface VisualBuilderProps {
    isEditMode: boolean;
}

const VisualBuilder: FC<VisualBuilderProps> = memo(({ isEditMode }) => {
    const addNewGroup = useAudienceContext(
        (context) => context.actions.addNewGroup,
    );

    const metricQueryGroups = useAudienceContext(
        (context) => context.state.unsavedChartVersion,
    );

    const setGlobalFilterCondition = useAudienceContext(
        (context) => context.actions.setGlobalFilterCondition,
    );

    const globalFilterCondition = useAudienceContext(
        (context) => context.state.globalFilterCondition,
    );

    const renderAddGroupButton = useMemo(() => {
        const currentLastGroupFilters =
            metricQueryGroups[metricQueryGroups.length - 1]?.metricQuery
                ?.filters;

        if (
            isEmpty(currentLastGroupFilters) ||
            isEmpty(currentLastGroupFilters.dimensions) ||
            !isEditMode
        )
            return null;
        return (
            <Button
                variant={ButtonVariant.OUTLINED}
                leftIcon={<PlusCircle />}
                onClick={addNewGroup}
                className="w-fit"
            >
                Group
            </Button>
        );
    }, [addNewGroup, metricQueryGroups, isEditMode]);

    return (
        <Box className="flex flex-col gap-3.5">
            <Box>
                {metricQueryGroups.map((group, index) => (
                    <Box key={index} className="flex flex-col w-full">
                        <FiltersCard index={index} />
                        {metricQueryGroups.length - 1 !== index && (
                            <FilterGroupOperatorCondition
                                toggleFilterCondition={setGlobalFilterCondition}
                                initialFilterCondition={globalFilterCondition}
                                isEditMode={isEditMode}
                            />
                        )}
                    </Box>
                ))}
            </Box>

            {renderAddGroupButton}
        </Box>
    );
});

export default VisualBuilder;
