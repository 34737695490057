import { EventLabelDataTypeOptions } from '@components/EventsManager/utils';
import { Flex, Menu, Text } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import React, { useState } from 'react';

type RecursiveMenuProps = {
    handleClick: (type: string) => void;
    level: number;
    nestedMenuOpened: boolean;
    setNestedMenuOpened:
        | React.Dispatch<React.SetStateAction<boolean>>
        | undefined;
};

const RecursiveMenu: React.FC<RecursiveMenuProps> = ({
    handleClick,
    level,
    nestedMenuOpened,
    setNestedMenuOpened,
}) => {
    const [opened, setOpened] = useState<boolean>(false);
    return (
        <Menu.Dropdown className="max-h-[14rem] overflow-auto rounded-xl">
            <Flex direction="column" gap={10}>
                {Object.values(EventLabelDataTypeOptions).map((option) => {
                    const isArray = option.type === 'array';
                    // this condition is to stop the recursive dropdowns on array datattype.
                    if (isArray) return null;
                    return (
                        <Menu.Item
                            key={option.type}
                            onClick={() => {
                                if (!isArray) handleClick(option.type);
                            }}
                            icon={option.icon}
                            className="group/item"
                        >
                            {' '}
                            {isArray ? (
                                <Menu
                                    trigger="hover"
                                    position="right-start"
                                    width={172}
                                    shadow="md"
                                    opened={opened || nestedMenuOpened}
                                    onOpen={() =>
                                        setNestedMenuOpened &&
                                        setNestedMenuOpened(true)
                                    }
                                    onClose={() =>
                                        !opened &&
                                        setNestedMenuOpened &&
                                        setNestedMenuOpened(false)
                                    }
                                    withinPortal
                                >
                                    <Menu.Target>
                                        <Flex
                                            justify={'space-between'}
                                            align={'center'}
                                        >
                                            <Text className="text-gray-800 font-medium leading-4 text-sm">
                                                {option.label}
                                            </Text>
                                            {isArray && (
                                                <CaretRight
                                                    weight="bold"
                                                    className="group/edit invisible group-hover/item:visible"
                                                />
                                            )}
                                        </Flex>
                                    </Menu.Target>
                                    <RecursiveMenu
                                        handleClick={(type: string) => {
                                            handleClick(
                                                `${option.type} of ${type}`,
                                            );
                                        }}
                                        level={level + 1}
                                        nestedMenuOpened={opened}
                                        setNestedMenuOpened={setOpened}
                                    />
                                </Menu>
                            ) : (
                                <Text className="text-gray-800 font-medium leading-4 text-sm">
                                    {option.label}
                                </Text>
                            )}
                        </Menu.Item>
                    );
                })}
            </Flex>
        </Menu.Dropdown>
    );
};

export default RecursiveMenu;
