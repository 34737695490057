import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import SyncSchedule from '@components/ProjectSettings//SyncSchedulePanel';
import BlobSettings from '@components/ProjectSettings/BlobPanel';
import Channels from '@components/ProjectSettings/ChannelPanel';
import DataConnection from '@components/ProjectSettings/DataConnectionPanel';
import WorkspaceMembers from '@components/ProjectSettings/MembersPanel';
import Overview from '@components/ProjectSettings/OverviewPanel';
import {
    Broadcast,
    ClockCountdown,
    Database,
    Drop,
    PaperPlaneTilt,
    Subtract,
    Users,
} from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { t as translate } from 'i18next';
import SubscriptionGroupPanel from '../SubscriptionGroupPanel';

import { ProjectSettings } from '@lightdash/common';
import DeliveryControls from '../DeliveryControls';
import EventSourcePanel from '../EventSourcePanel';

export interface SettingListItemType {
    label: string;
    redirectTo: string;
    routeMatch: string;
    children: React.ReactNode;
    leftSection?: React.ReactNode;
    disable: boolean;
}

const baseSettingsReditectPath = (projectUuid: string) =>
    `/projects/${projectUuid}/settings`;
const baseSettingsRoutePath = '/projects/:projectUuid/settings';

export const ProjectSettingsMenuGroups = (
    projectUuid: string,
): SettingListItemType[][] => {
    const baseRedirectPath = baseSettingsReditectPath(projectUuid);
    const ability = useAbilityContext();
    const { user } = useApp();
    const cannotViewOverview = ability.cannot(
        'view',
        subject(ProjectSettings.overview, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const cannotViewDataConnection = ability.cannot(
        'view',
        subject(ProjectSettings.dataConnection, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const cannotViewBlobStorage = ability.cannot(
        'view',
        subject(ProjectSettings.blobStorage, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const cannotViewSyncSchedule = ability.cannot(
        'view',
        subject(ProjectSettings.syncSchedules, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const cannotViewSubscription = ability.cannot(
        'view',
        subject(ProjectSettings.subscriptionGroups, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const cannotViewEventSources = ability.cannot(
        'view',
        subject(ProjectSettings.eventSources, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const cannotViewChannel = ability.cannot(
        'view',
        subject(ProjectSettings.channels, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const cannotViewDeliveryControls = ability.cannot(
        'view',
        subject(ProjectSettings.deliveryControls, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const cannotViewMembers = ability.cannot(
        'view',
        subject(ProjectSettings.members, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    return [
        [
            {
                label: translate('project_settings.overview_label'),
                redirectTo: `${baseRedirectPath}/overview`,
                routeMatch: `${baseSettingsRoutePath}/overview`,
                children: <Overview />,
                disable: cannotViewOverview,
            },
        ],
        [
            {
                label: translate('project_settings.data_connection_label'),
                redirectTo: `${baseRedirectPath}/data-connection`,
                routeMatch: `${baseSettingsRoutePath}/data-connection`,
                children: <DataConnection />,
                leftSection: <Database />,
                disable: cannotViewDataConnection,
            },
            {
                label: translate('project_settings.blob_storage_label'),
                redirectTo: `${baseRedirectPath}/blob`,
                routeMatch: `${baseSettingsRoutePath}/blob`,
                children: <BlobSettings />,
                leftSection: <Drop />,
                disable: cannotViewBlobStorage,
            },
            {
                label: translate('project_settings.sync_schedule_label'),
                redirectTo: `${baseRedirectPath}/sync-schedule`,
                routeMatch: `${baseSettingsRoutePath}/sync-schedule`,
                children: <SyncSchedule />,
                leftSection: <ClockCountdown />,
                disable: cannotViewSyncSchedule,
            },
            {
                label: translate('project_settings.channels_label'),
                redirectTo: `${baseRedirectPath}/channels`,
                routeMatch: `${baseSettingsRoutePath}/channels`,
                children: <Channels />,
                leftSection: <Broadcast />,
                disable: cannotViewChannel,
            },
            {
                label: translate('subscription_group'),
                redirectTo: `${baseRedirectPath}/subscription`,
                routeMatch: `${baseSettingsRoutePath}/subscription`,
                children: <SubscriptionGroupPanel />,
                leftSection: <Subtract size={13} />,
                disable: cannotViewSubscription,
            },
            {
                label: translate('project_settings.delivery_controls_title'),
                redirectTo: `${baseRedirectPath}/delivery-controls`,
                routeMatch: `${baseSettingsRoutePath}/delivery-controls`,
                children: <DeliveryControls />,
                leftSection: <PaperPlaneTilt />,
                disable: cannotViewDeliveryControls,
            },
            {
                label: translate('event_sources'),
                redirectTo: `${baseRedirectPath}/event-sources`,
                routeMatch: `${baseSettingsRoutePath}/event-sources`,
                children: <EventSourcePanel />,
                leftSection: <Broadcast />,
                disable: cannotViewEventSources,
            },
        ],
        [
            {
                label: 'Members',
                redirectTo: `${baseRedirectPath}/members`,
                routeMatch: `${baseSettingsRoutePath}/members`,
                children: <WorkspaceMembers />,
                leftSection: <Users />,
                disable: cannotViewMembers,
            },
        ],
    ];
};
