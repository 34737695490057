import FilterRuleForm from '@components/Audience/Filters/FilterRuleForm';
import {
    useFiltersContext,
    type FieldWithSuggestions,
} from '@components/Audience/Filters/FiltersProvider';
import { useGetFieldsBetweenTables } from '@hooks/useGetRelatedTables';
import {
    addFilterRule,
    FilterGroupOperator,
    getFilterGroupItemsPropertyName,
    getItemsFromFilterGroup,
    isAndFilterGroup,
    isField,
    isFilterableField,
    type AndFilterGroup,
    type ConditionalOperator,
    type FieldTarget,
    type FilterGroup,
    type FilterRule,
    type Filters,
    type MetricFilterRule,
} from '@lightdash/common';
import { Box, Button, Stack } from '@mantine/core';
import { useCustomMetricContext } from '@providers/CustomMetricProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { useCallback, useRef } from 'react';
import { ButtonVariant } from '../../mantineTheme';
import CustomMetricFilterPropertySelect from './CustomMetricFilterPropertySelect';

const CustomMetricFilter = ({ isLoading }: { isLoading: boolean }) => {
    const { selectedDimension, selectedTable, viewMode } =
        useCustomMetricContext((context) => context.reducerState);

    const { addFilttersToCustomMetric } = useCustomMetricContext(
        (context) => context.actions,
    );
    const filters = useCustomMetricContext(
        (context) => context.reducerState.filters,
    );
    const { fieldsMap } = useFiltersContext();
    const items = getItemsFromFilterGroup(filters?.dimensions);
    const setFilters = useCustomMetricContext(
        (context) => context.actions.setFilters,
    );
    const filterSelectRef = useRef<HTMLDivElement | null>(null);
    const { activeRelation } = useRelationContext();
    const fieldsBetweenSourceDestination = useGetFieldsBetweenTables(
        selectedTable?.name ?? '',
        selectedDimension?.table ?? '',
        activeRelation,
        fieldsMap,
    );

    const addFieldRule = useCallback(
        (field: FieldWithSuggestions) => {
            if (isField(field) && isFilterableField(field)) {
                const filtersCheck = filters ?? {};
                const updatedFilters = addFilterRule({
                    filters: filtersCheck,
                    field,
                    isCustomMetricFilter: true,
                });
                setFilters(updatedFilters);
                addFilttersToCustomMetric(
                    (updatedFilters.dimensions as AndFilterGroup)
                        ?.and as MetricFilterRule[],
                );
            }
        },
        [filters, setFilters, addFilttersToCustomMetric],
    );
    const onChangeItem = useCallback(
        (index: number, item: FilterRule | FilterGroup) => {
            const updatedFilters = {
                ...filters,

                dimensions: {
                    ...(filters?.dimensions as Filters),
                    [getFilterGroupItemsPropertyName(filters?.dimensions)]: [
                        ...items.slice(0, index),
                        item,
                        ...items.slice(index + 1),
                    ],
                } as FilterGroup,
            };
            setFilters(updatedFilters);
            addFilttersToCustomMetric(
                (updatedFilters.dimensions as AndFilterGroup)
                    ?.and as MetricFilterRule[],
            );
        },
        [filters, items, setFilters, addFilttersToCustomMetric],
    );
    const onDeleteItem = useCallback(
        (index: number) => {
            if (items.length <= 1) {
                setFilters({
                    ...filters,
                    dimensions: undefined,
                });
                addFilttersToCustomMetric(undefined);
            } else {
                const updatedFilters = {
                    ...filters,
                    dimensions: {
                        ...filters?.dimensions,
                        [getFilterGroupItemsPropertyName(filters?.dimensions)]:
                            [
                                ...items.slice(0, index),
                                ...items.slice(index + 1),
                            ],
                    } as FilterGroup,
                };
                setFilters(updatedFilters);
                addFilttersToCustomMetric(
                    (updatedFilters.dimensions as AndFilterGroup)
                        ?.and as MetricFilterRule[],
                );
            }
        },
        [filters, setFilters, items, addFilttersToCustomMetric],
    );
    const scrollToView = useCallback(() => {
        setTimeout(() => {
            if (filterSelectRef.current)
                filterSelectRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
        }, 200);
    }, []);
    const onChangeOperator = useCallback(
        (value: FilterGroupOperator) => {
            setFilters({
                ...filters,
                dimensions: {
                    id: filters?.dimensions?.id,
                    [value]: items,
                } as FilterGroup,
            });
        },
        [filters, items, setFilters],
    );
    return (
        <Box className="p-3.5 border-base shadow-card">
            <Stack pos="relative" spacing="sm" mb="xxs" px={0}>
                <Stack spacing="xs" style={{ flexGrow: 1 }} px={0}>
                    {items.map((item, index) => (
                        <Box
                            key={item.id}
                            className={`flex flex-row items-baseline w-full gap-2 justify-start ${
                                index > 0 ? 'ml-8' : ''
                            }`}
                        >
                            {fieldsBetweenSourceDestination &&
                                filters?.dimensions && (
                                    <>
                                        <Box
                                            className={` text-sm text-gray-600 text-end`}
                                        >
                                            {Boolean(index) && (
                                                <Button
                                                    onClick={() =>
                                                        onChangeOperator(
                                                            filters?.dimensions &&
                                                                isAndFilterGroup(
                                                                    filters?.dimensions,
                                                                )
                                                                ? FilterGroupOperator.or
                                                                : FilterGroupOperator.and,
                                                        )
                                                    }
                                                    // For know I disabled the changing of the operator as there is no support from backend
                                                    disabled={true}
                                                    variant={
                                                        ButtonVariant.UNSTYLED
                                                    }
                                                    className="text-sm font-normal"
                                                >
                                                    {filters?.dimensions &&
                                                    isAndFilterGroup(
                                                        filters?.dimensions,
                                                    )
                                                        ? FilterGroupOperator.and
                                                        : FilterGroupOperator.or}
                                                </Button>
                                            )}
                                        </Box>
                                        <FilterRuleForm
                                            filterRule={
                                                item as FilterRule<
                                                    ConditionalOperator,
                                                    FieldTarget,
                                                    any,
                                                    any
                                                >
                                            }
                                            fields={Object.values(
                                                fieldsBetweenSourceDestination,
                                            )}
                                            isEditMode={
                                                !viewMode
                                                    ? !isLoading
                                                    : !viewMode
                                            }
                                            onChange={(value) =>
                                                onChangeItem(index, value)
                                            }
                                            onDelete={() => onDeleteItem(index)}
                                            filters={filters ?? {}}
                                            setFilters={setFilters}
                                            groupIndex={0}
                                            customMetricFilter={true}
                                        />
                                    </>
                                )}
                        </Box>
                    ))}
                </Stack>
            </Stack>
            <CustomMetricFilterPropertySelect
                fields={Object.values(fieldsBetweenSourceDestination)}
                onSubmit={(field) => {
                    addFieldRule(field);
                    scrollToView();
                }}
                isLoading={isLoading}
            />
        </Box>
    );
};
export default CustomMetricFilter;
