import { subject } from '@casl/ability';
import { useFieldsWithEvents } from '@components/Audience/Filters/FiltersCard/useFieldsWithEvents';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider';
import { useAbilityContext } from '@components/common/Authorization';
import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';
import CustomMetricManagerContent from '@components/CustomMetric/CustomMetricManagerContent';
import Page from '@components/Page/Page';
import PageSpinner from '@components/PageSpinner';
import { useGetCustomMetrics } from '@hooks/useCustomMetric';
import { useCanCreateCustomMetric } from '@hooks/useGetRelatedTables';
import { useLocale } from '@hooks/useLocale';
import { Button, Flex, Text } from '@mantine/core';
import { Nut, PlusCircle } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { useProjectContext } from '@providers/ProjectProvider';
import { useRelationContext } from '@providers/RelationProvider';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonVariant, PageLoaderColor } from '../mantineTheme';

const CustomMetricBuilderContainer: React.FC<{}> = () => {
    const { t } = useLocale();
    const { data: customMetrics, isInitialLoading } = useGetCustomMetrics();
    const [openBaseTable, setOpenBaseTable] = useState<boolean>(false);
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const ability = useAbilityContext();
    const { user } = useApp();
    const canCreateCustomMetricAbility = ability.can(
        'create',
        subject('CustomMetric', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const { activeRelation } = useRelationContext();

    const canCreateCustomMetric = useCanCreateCustomMetric(activeRelation);
    if (isInitialLoading) {
        return (
            <PageSpinner
                isVisible={isInitialLoading}
                withOverlay
                loaderColor={PageLoaderColor.GRAY}
            />
        );
    }
    return (
        <ManagerBuilderContainer
            withContentPadding={false}
            title={
                <Flex justify="start" className="gap-1.5">
                    <Flex align="center">
                        <Nut color={'rgb(var(--color-purple))'} size={20} />
                    </Flex>
                    <Text>{t('custom_metric')}</Text>
                </Flex>
            }
            rightSection={
                canCreateCustomMetricAbility &&
                canCreateCustomMetric &&
                customMetrics?.length ? (
                    <Button
                        className="m-3.5"
                        variant={ButtonVariant.PRIMARY}
                        leftIcon={<PlusCircle color="white" />}
                        onClick={() => {
                            setOpenBaseTable(true);
                        }}
                    >
                        <Text className="inline text-sm font-semibold">
                            {t('custom_metric.create_custom_metric')}
                        </Text>
                    </Button>
                ) : null
            }
        >
            <CustomMetricManagerContent
                openBaseTable={openBaseTable}
                setOpenBaseTable={setOpenBaseTable}
            />
        </ManagerBuilderContainer>
    );
};
const CustomMetricManager: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { activeRelation, activeRelationUuid } = useRelationContext();
    const { projectData } = useProjectContext();

    const { data: eventsData, eventsTableNames } = useFieldsWithEvents({
        relationData: activeRelation,
        activeRelationUuid,
        projectUuid,
    });
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });

    return (
        <FiltersProvider
            projectUuid={projectUuid}
            fieldsMap={fieldsWithSuggestions}
            startOfWeek={
                projectData?.warehouseConnection?.startOfWeek ?? undefined
            }
            eventsMap={eventsData}
            eventTables={eventsTableNames}
        >
            <Page
                title={t('custom_metric')}
                withFullHeight
                withNavbar
                withFixedContent
                withPaddedContent
            >
                <CustomMetricBuilderContainer />
            </Page>
        </FiltersProvider>
    );
};
export default React.memo(CustomMetricManager);
