import { subject } from '@casl/ability';
import CampaignTable from '@components/Campaigns/Table/CampaignTable';
import { useAbilityContext } from '@components/common/Authorization';
import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';
import LinkButton from '@components/common/LinkButton';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import Page from '@components/Page/Page';
import { useCampaigns } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { CampaignStatus } from '@lightdash/common';
import { Text } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { PlusCircle } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonVariant } from '../mantineTheme';

const CampaignBuilderContainer: React.FC<{}> = () => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [searchString, setSearchString] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [pollingActive, setPollingActive] = useState<boolean>(true);
    const { data: campaigns, isInitialLoading } = useCampaigns({
        perPage: 10,
        currentPage,
        query: `name=${searchString}`,
        polling: pollingActive,
    });
    const { user } = useApp();
    const ability = useAbilityContext();
    const canCreateCampaigns = ability.can(
        'manage',
        subject('Campaign', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const handleSearchInput = (value: string) => {
        setSearchString(value);
        setCurrentPage(1);
    };

    useEffect(() => {
        if (campaigns?.data) {
            const allowedPollingStatus = [
                CampaignStatus.IN_PROGRESS,
                CampaignStatus.SCHEDULED,
            ];
            const res = campaigns.data.some(
                (campaign) =>
                    campaign?.status &&
                    allowedPollingStatus.includes(campaign?.status),
            );
            setPollingActive(res);
        }
    }, [campaigns]);

    if (isInitialLoading) {
        return <SuboptimalState loading />;
    }
    return (
        <ManagerBuilderContainer
            withContentPadding={false}
            title={t('campaign.title')}
            rightSection={
                canCreateCampaigns &&
                campaigns?.data?.length && (
                    <LinkButton
                        href={`/projects/${projectUuid}/campaigns/create`}
                        variant={ButtonVariant.PRIMARY}
                        leftIcon={<PlusCircle color="white" />}
                    >
                        <Text className="text-sm font-semibold">
                            {t('campaign.create.title')}
                        </Text>
                    </LinkButton>
                )
            }
        >
            {!Boolean(campaigns?.data?.length) && searchString === '' ? (
                canCreateCampaigns && (
                    <LinkButton
                        className="m-3.5"
                        href={`/projects/${projectUuid}/campaigns/create`}
                        variant={ButtonVariant.PRIMARY}
                        leftIcon={<PlusCircle color="white" />}
                    >
                        <Text className="text-sm font-semibold">
                            {t('campaign_manager.create_your_first_campaign')}
                        </Text>
                    </LinkButton>
                )
            ) : (
                <CampaignTable
                    campaigns={campaigns}
                    onPageChange={async (newPage) => {
                        setCurrentPage(newPage);
                    }}
                    onSearchChange={handleSearchInput}
                    searchValue={searchString}
                />
            )}
        </ManagerBuilderContainer>
    );
};

const CampaignManager = () => {
    const { t } = useLocale();
    return (
        <>
            <Page
                title={t('campaign.title')}
                withFullHeight
                withPaddedContent
                withNavbar
                withFixedContent
            >
                <CampaignBuilderContainer />
            </Page>
        </>
    );
};
export default CampaignManager;
