import PropertySelect from '@components/common/Select/PropertySelect';
import CreateCustomMetric from '@components/CustomMetric/CreateCustomMetric';
import { useFilterFields } from '@hooks/useFilterFields';
import { useLocale } from '@hooks/useLocale';
import {
    addFilterRule,
    DimensionType,
    isField,
    isFilterableField,
    RelationTableType,
    type CompiledRelationTable,
    type Filters,
} from '@lightdash/common';
import { Button } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import { useRelationContext } from '@providers/RelationProvider';
import { useCallback, useState } from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import FieldListItem from './FieldListItem';
import { convertFieldsIntoPropertySelectListType } from './FieldListItem/utils';
import {
    useFiltersContext,
    type FieldWithSuggestions,
} from './FiltersProvider';
import { getBaseTablePrimaryFieldId } from './utils';

interface FilterFormPropertySelectProps {
    targetButton?: React.ReactNode;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    index: number;
}
const FilterFormPropertySelect: React.FC<FilterFormPropertySelectProps> = ({
    targetButton,
    filters,
    setFilters,
    index,
}) => {
    const { filteredDimensions, metrics, events } = useFilterFields();
    const { showFilter, setShowFilter } = useFiltersContext();
    const { t } = useLocale();
    const { activeRelation } = useRelationContext();
    const addFieldRule = useCallback(
        (field: FieldWithSuggestions) => {
            //Info: When the field is an event, we use field reference which is the event name
            //Info: When the field is a dimension, the value is undefined. This is value is entered by the user.
            let value =
                field.tableType === RelationTableType.EVENT &&
                field.type === DimensionType.EVENT
                    ? field.fieldReference
                    : undefined;

            //Info: If the tableType is Audience, the value becomes the materialised table name, and the fieldId becomes the base table name + primary key of base table
            if (field.tableType === RelationTableType.AUDIENCE) {
                value = field.table;
                field.name = activeRelation
                    ? getBaseTablePrimaryFieldId(activeRelation)
                    : field.name;
            }
            if (isField(field) && isFilterableField(field)) {
                const newFilterRule = addFilterRule({
                    filters,
                    field,
                    ...(Boolean(value) && { value }),
                });
                setFilters(newFilterRule, false, index);
            }
        },
        [filters, setFilters, index, activeRelation],
    );
    const [openCustomMetric, setOpenCustomMetric] = useState<
        CompiledRelationTable | undefined
    >(undefined);
    return (
        <>
            <PropertySelect<FieldWithSuggestions>
                items={convertFieldsIntoPropertySelectListType([
                    ...filteredDimensions,
                    ...events,
                    ...metrics,
                ])}
                showGroup={true}
                headerRightSection={
                    <Button
                        leftIcon={<PlusCircle />}
                        variant={ButtonVariant.OUTLINED}
                        className="my-2.5 me-2.5"
                        onClick={() => {
                            setOpenCustomMetric(
                                activeRelation?.tables[
                                    activeRelation.baseTable
                                ],
                            );
                            setShowFilter(false);
                        }}
                    >
                        {t('profiles_view.add_custom_metric_button')}
                    </Button>
                }
                onSubmit={(items: FieldWithSuggestions[]) => {
                    if (!items[0]) return;
                    setShowFilter(false);

                    addFieldRule(items[0]);
                }}
                itemTemplate={({ item }) => {
                    return (
                        <FieldListItem
                            item={item}
                            checked={item.isChecked ?? false}
                            showCheckbox={false}
                            disabled={item.isDisabled ?? false}
                        />
                    );
                }}
                opened={showFilter}
                close={() => setShowFilter(false)}
                open={() => setShowFilter(true)}
                targetButton={targetButton}
                showSearch={true}
                searchKeys={['label', 'tableLabel']}
                searchPlaceholder={t('audience_filters.search_filter_label')}
                allowMultipleSelection={false}
            />
            <CreateCustomMetric
                tableName={openCustomMetric}
                onModalClose={() => {
                    setOpenCustomMetric(undefined);
                }}
                openBaseTable={undefined}
                isViewMode={undefined}
                isDuplicate={undefined}
                customMetricData={undefined}
                customMetricId={undefined}
                disableTableChange={false}
            />
        </>
    );
};
export default FilterFormPropertySelect;
