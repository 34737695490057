import { Box, Card } from '@mantine/core';
import React from 'react';

interface SkeletonLoaderProps {
    height?: number;
    width?: number;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ height, width }) => {
    return (
        <Card
            style={{
                height: height ? `${height}px` : '80px',
                width: width ? `${width}px` : '100%',
            }}
            className={`animate-pulse bg-shade-6 rounded-lg`}
        >
            <Box></Box>
        </Card>
    );
};

export default React.memo(SkeletonLoader);
