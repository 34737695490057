import DataTable from '@components/Table';
import { useLocale } from '@hooks/useLocale';
import { ShowDataType, type CustomAttribute } from '@lightdash/common';
import { useDebouncedState } from '@mantine/hooks';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import Fuse from 'fuse.js';
import React, { useMemo, useState } from 'react';
import CreateCustomMetric from '../CreateCustomMetric';
import { useCustomMetricCoulumns } from './useCustomMetricTableUtils';
interface CustomMetricTableProps {
    customMetrics: CustomAttribute[];
}
const CustomMetricTable: React.FC<CustomMetricTableProps> = ({
    customMetrics,
}) => {
    const [searchString, setSearchString] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );

    const filterItems = useMemo(() => {
        let customMetricsData = customMetrics;
        if (searchString !== '') {
            const fuse = new Fuse(customMetricsData, {
                keys: ['name'],
                threshold: 0.3,
            });
            customMetricsData = fuse
                .search(searchString ?? '')
                .map((res) => res.item);
        }
        return customMetricsData;
    }, [customMetrics, searchString]);
    const customMetricColumns = useCustomMetricCoulumns();
    const [openViewMode, setOpenViewMode] = useState<boolean>(false);
    const [customMetricId, setCustomMetricId] = useState<string>('');
    const { t } = useLocale();
    return (
        <div>
            {customMetrics && customMetricColumns && (
                <DataTable<CustomAttribute>
                    tableData={filterItems}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: customMetricColumns,
                        },
                    ]}
                    searchPlaceholder={t(
                        'custom_metric_manager.table_search_placeholder',
                    )}
                    paginationText={t('custom_metric_manager.pagination_text')}
                    isManualPagination={false}
                    isEditable={false}
                    onSearchChange={setSearchString}
                    handleRowClick={(row) => {
                        setOpenViewMode(true);
                        setCustomMetricId(row.original.uuid);
                    }}
                />
            )}
            {customMetricId && (
                <CreateCustomMetric
                    isViewMode={openViewMode}
                    customMetricId={customMetricId}
                    customMetricData={filterItems.find(
                        (customMetric) => customMetric.uuid === customMetricId,
                    )}
                    openBaseTable={undefined}
                    tableName={undefined}
                    isDuplicate={undefined}
                    onModalClose={() => {
                        setOpenViewMode(false);
                    }}
                    disableTableChange={undefined}
                />
            )}
        </div>
    );
};
export default React.memo(CustomMetricTable);
