import Modal from '@components/common/modal/Modal';
import ImportFromProvider from '@components/Templates/ImportFromProvider';
import { useLocale } from '@hooks/useLocale';
import { useMigrateTemplate } from '@hooks/useTemplate';
import { Button, Loader as LoaderIcon } from '@mantine/core';
import { ArrowDownRight } from '@phosphor-icons/react';
import { useTemplateBuilderContext } from '@providers/TemplateBuilderProvider';
import React, { useCallback, useEffect, useMemo } from 'react';

const ImportTemplateModal: React.FC = () => {
    const { t } = useLocale();
    const { state, actions } = useTemplateBuilderContext((context) => context);
    const { openImportModal, selectedIntegration, selectedTemplate } = state;
    const {
        setOpenImportModal,
        handleTemplateSelect,
        setSelectedIntegration,
        setSelectedTemplate,
    } = actions;

    const {
        mutate: importTemplate,
        isSuccess: importTemplateSuccess,
        data: importTemplateData,
        isLoading: importTemplateLoading,
    } = useMigrateTemplate();

    const handleClose = useCallback(() => {
        setOpenImportModal(false);
        setSelectedIntegration(null);
        setSelectedTemplate(null);
    }, [setOpenImportModal, setSelectedIntegration, setSelectedTemplate]);

    const handleImport = useCallback(() => {
        const payload = {
            providerId: selectedIntegration?.providerId as string,
            integrationId: selectedIntegration?.integrationId as string,
            templateIds: [selectedTemplate?.id] as string[],
            addToLibrary: true,
        };
        importTemplate(payload);
    }, [importTemplate, selectedIntegration, selectedTemplate]);

    useEffect(() => {
        if (importTemplateSuccess) {
            handleClose();
        }
    }, [importTemplateSuccess, handleClose]);

    useEffect(() => {
        if (importTemplateData) {
            handleTemplateSelect({
                id: importTemplateData?.[0]?.id,
                name: importTemplateData?.[0]?.name,
                description: importTemplateData?.[0]?.description,
                updatedAt: importTemplateData?.[0]?.updatedAt,
                ...importTemplateData[0],
            });
        }
    }, [handleTemplateSelect, importTemplateData]);

    const renderModalFooterRightSection = useMemo(() => {
        if (selectedIntegration) {
            return (
                <Button
                    onClick={handleImport}
                    leftIcon={
                        importTemplateLoading && (
                            <LoaderIcon size="14" color="white" />
                        )
                    }
                    rightIcon={
                        <ArrowDownRight
                            size={14}
                            color="white"
                            weight="regular"
                        />
                    }
                    disabled={selectedTemplate === null}
                >
                    {t('template_manager.use_this_template_button')}
                </Button>
            );
        }
        return null;
    }, [
        handleImport,
        importTemplateLoading,
        selectedIntegration,
        t,
        selectedTemplate,
    ]);

    return (
        <Modal
            opened={openImportModal}
            onClose={handleClose}
            title={t('campaigns.import_template.import_from_integration', {
                integration: selectedIntegration?.providerName ?? 'integration',
            })}
            size="lg"
            footerRightSection={renderModalFooterRightSection}
        >
            <ImportFromProvider />
        </Modal>
    );
};

export default React.memo(ImportTemplateModal);
