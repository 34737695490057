import TextInput from '@components/common/Inputs/TextInput';
import Modal from '@components/common/modal/Modal';
import {
    useGetSubscriptionCategoryById,
    useUpdateSubscriptionCategory,
} from '@hooks/subscriptionGroups/useSubscriptionGroups';
import { useLocale } from '@hooks/useLocale';
import { Box, Flex, Group, Text, Textarea } from '@mantine/core';
import { FolderOpen } from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { QueryKeys } from 'types/UseQuery';
import { getStyles } from '../../utils';
import ModalFooter from './ModalFooter';

interface EditCategoryModalProps {
    editCategory: boolean;
    onClose: () => void;
    categoryId: string;
}
const EditCategoryModal: React.FC<EditCategoryModalProps> = ({
    editCategory,
    onClose,
    categoryId,
}) => {
    const { t } = useLocale();
    const { data: categoryData } = useGetSubscriptionCategoryById({
        categoryId: categoryId,
    });
    const { mutateAsync: updateCategory } = useUpdateSubscriptionCategory();
    const queryClient = useQueryClient();
    const [categoryName, setCategoryName] = useState<string>('');
    const [categoryDescription, setCategoryDescription] = useState<string>('');
    const [isCategoryEmpty, setIsCategoryEmpty] = useState<boolean>(false);
    useEffect(() => {
        if (categoryData) {
            setCategoryName(categoryData.name);
            setCategoryDescription(categoryData.description);
        }
    }, [categoryData]);

    const handleSaveClick = useCallback(async () => {
        if (categoryData && categoryData.id) {
            if (!categoryName.trim().length) {
                setIsCategoryEmpty(true);
                return;
            }
            await updateCategory({
                categoryId: categoryData.id,
                payload: {
                    name: categoryName.trim(),
                    description: categoryDescription.trim(),
                },
            });
            await queryClient.invalidateQueries([
                QueryKeys.GET_SUBSCRIPTION_GROUPS,
            ]);
        }
        onClose();
    }, [
        categoryData,
        updateCategory,
        queryClient,
        categoryName,
        categoryDescription,
        onClose,
    ]);

    const ModalTitle = useMemo(
        () => (
            <Group className="flex flex-col items-start gap-0">
                <Text className="text-gray-800 text-base font-medium mb-1">
                    {t('node.edit_category')}
                </Text>
                <Box className="flex items-center space-x-1">
                    <FolderOpen
                        weight="duotone"
                        size={13}
                        color={'rgb(var(--color-gray-600))'}
                    />
                    <Text className="text-gray-800 text-sm font-medium">
                        {categoryData?.name}
                    </Text>
                </Box>
            </Group>
        ),
        [categoryData, t],
    );

    return (
        <Modal
            opened={editCategory}
            onClose={onClose}
            size="xl"
            title={ModalTitle}
            styles={getStyles()}
            footerRightSection={
                <ModalFooter
                    primaryText={t('password_modal.secondary_btn_text')}
                    secondaryText={t('edit_category_modal.save_category')}
                    primaryButtonClick={onClose}
                    secondaryButtonClick={handleSaveClick}
                />
            }
        >
            {categoryData && (
                <Box>
                    <Box className="w-2/3 mx-1.5 mb-3.5">
                        <Text className="  mb-1.5 text-gray-800 text-sm font-medium">
                            {t('edit_category_modal.category_name')}
                        </Text>
                        <TextInput
                            required
                            error={
                                isCategoryEmpty &&
                                t('subscription_group.invalid_name')
                            }
                            placeholder={t('edit_category_modal.example')}
                            value={categoryName}
                            onChange={(e) => {
                                setIsCategoryEmpty(false);
                                setCategoryName(e.target.value);
                            }}
                        />
                        <Text className="text-sm font-medium text-gray-500 my-1.5">
                            {t('subscription_group_content.name_definition')}
                        </Text>
                    </Box>
                    <Box>
                        <Flex className="m-1.5 space-x-1">
                            <Text className="text-gray-800 text-sm font-medium">
                                {t(
                                    'custom_metric.dimension_modal_description_label',
                                )}
                            </Text>
                            <Text className="text-gray-600 text-sm font-medium">
                                {t(
                                    'custom_metric.dimension_modal_description_label_optional',
                                )}
                            </Text>
                        </Flex>
                        <Textarea
                            className="mx-1.5"
                            placeholder={t(
                                'edit_category_modal.description_text',
                            )}
                            value={categoryDescription}
                            onChange={(e) =>
                                setCategoryDescription(e.target.value)
                            }
                        />
                    </Box>
                </Box>
            )}
        </Modal>
    );
};

export default EditCategoryModal;
