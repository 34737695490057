import UnsavedChangesModal from '@components/common/modal/UnsavedChangesModal';
import Select from '@components/common/Select';
import TimeInputWithOptions from '@components/common/Time/TimeInputWithOptions';
import { useLocale } from '@hooks/useLocale';
import { PeriodType } from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import { FlagCheckered } from '@phosphor-icons/react';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import SettingsTitle from '../SettingsTitle';
const Goals: React.FC = () => {
    const { t } = useLocale();
    const {
        journeyEvents: eventsData,
        journeyPayload,
        initialJourneyPayload,
    } = useJourneyBuilderContext((context) => context.state);
    const { setGoals, mutateAsyncJourney } = useJourneyBuilderContext(
        (context) => context.actions,
    );
    const { triggers } = journeyPayload;
    const { conversion } = triggers!;
    const { triggers: initialTriggers } = initialJourneyPayload;
    const { conversion: initialConversion } = initialTriggers!;
    const [isDataChanged, setIsDataChanged] = useState(false);
    const transformedData = useMemo(() => {
        return (
            eventsData &&
            eventsData.map((event) => ({
                label: event.label ? event.label : event.eventName,
                value: event.eventName,
                source: event.source,
            }))
        );
    }, [eventsData]);

    const handleDurationChange = useCallback(
        (duration: number, granularity: PeriodType) => {
            setIsDataChanged(true);
            setGoals([
                {
                    id:
                        conversion && conversion[0].id
                            ? conversion[0].id
                            : uuidv4(),
                    eventName:
                        conversion && conversion[0].eventName
                            ? conversion[0].eventName
                            : '',
                    eventSource:
                        conversion && conversion[0].eventSource
                            ? conversion[0].eventSource
                            : '',
                    deadline: duration,
                    uiConfig: {
                        granularity,
                    },
                },
            ]);
        },
        [conversion, setGoals],
    );
    const handleEventChange = useCallback(
        (eventName: string) => {
            setIsDataChanged(true);
            const selectedEvent =
                transformedData &&
                transformedData.find((event) => event.value === eventName);
            const eventSource = selectedEvent ? selectedEvent.source : '';
            setGoals([
                {
                    id:
                        conversion && conversion[0].id
                            ? conversion[0].id
                            : uuidv4(),
                    eventName,
                    eventSource,
                    deadline: undefined,
                },
            ]);
        },
        [transformedData, setGoals, conversion],
    );
    const handleSave = useCallback(async () => {
        setIsDataChanged(false);
        await mutateAsyncJourney(true);
    }, [mutateAsyncJourney]);
    const handleSecondaryClick = useCallback(() => {
        setIsDataChanged(false);

        setGoals(initialConversion);
    }, [initialConversion, setGoals]);
    return (
        <Box>
            <SettingsTitle
                icon={<FlagCheckered size={14} weight="duotone" />}
                title={t('journey_goals.title')}
                subtitle={t('journey_goals.subtitle')}
            />
            <Box className="p-3">
                <Text>{t('journey_goals.conversion_event')}</Text>
                <Box className="mt-1.5">
                    {transformedData && (
                        <Select
                            value={conversion ? conversion[0].eventName : ''}
                            data={transformedData}
                            onChange={(eventName: string) => {
                                handleEventChange(eventName);
                            }}
                            placeholder={t('journey_goals.select_event')}
                        />
                    )}
                </Box>
                <Text className="mt-3">{t('journey_goals.goal_deadline')}</Text>
                <TimeInputWithOptions
                    selectedDuration={
                        conversion ? conversion[0].deadline : undefined
                    }
                    onDurationChange={handleDurationChange}
                    selectedGranularity={
                        conversion &&
                        conversion[0].uiConfig &&
                        conversion[0].uiConfig.granularity
                    }
                    granularityOptions={[
                        PeriodType.DAY,
                        PeriodType.HOUR,
                        PeriodType.WEEK,
                    ]}
                />
                <Text className="text-gray-500 mt-1.5">
                    {t('journey_goals.maximum_time')}
                </Text>
            </Box>
            <UnsavedChangesModal
                opened={isDataChanged}
                secondaryActionButtonClick={() => handleSecondaryClick()}
                disableButtons={false}
                primaryActionButtonClick={() => handleSave()}
            />
        </Box>
    );
};
export default Goals;
