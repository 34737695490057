import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import TemplateBuilderManager from '@components/Templates/Builder/TemplateBuilderManager';
import { useGetProviderMetada } from '@hooks/useChannels';
import { useGetTemplateById } from '@hooks/useTemplate';
import { type ProviderTemplateDetails } from '@lightdash/common';
import { Box, Group, Stack } from '@mantine/core';
import { useCampaignContext } from '@providers/CampaignProvider';
import { useCallback, useEffect } from 'react';
import SetupContent from './SetupContent';
import TemplateContentPreview from './SetupContent/TemplateContentPreview';

const CampaignBuilderContent: React.FC<{}> = () => {
    const { setCampaignContentPayload, setShowFooterButtons } =
        useCampaignContext((context) => context.actions);

    const { campaignPayload } = useCampaignContext((context) => context.state);
    const { templateDetails, communicationDetails } = campaignPayload;

    const { data: providerMetadata, isInitialLoading } = useGetProviderMetada(
        communicationDetails?.providerId,
        communicationDetails?.channel ?? '',
    );

    const {
        data: templateMetadata,
        isInitialLoading: isTemplateMetadataLoading,
    } = useGetTemplateById(templateDetails?.id ?? '');

    const handleTemplateSelect = useCallback(
        (value: ProviderTemplateDetails) => {
            if (!value) return;
            setCampaignContentPayload({
                id: value.id,
            });
        },
        [setCampaignContentPayload],
    );

    useEffect(() => {
        if (templateDetails?.id) {
            setShowFooterButtons({
                next: true,
                back: true,
            });
            return;
        }
        setShowFooterButtons({
            next: false,
            back: true,
        });
    }, [templateDetails, setShowFooterButtons]);

    if (isInitialLoading || isTemplateMetadataLoading)
        return <SuboptimalState loading />;

    return (
        <Group className="items-start gap-3.5 pb-[82px]" noWrap>
            <Stack className="w-full grow">
                <Box className="">
                    {!templateDetails?.id &&
                    providerMetadata &&
                    communicationDetails?.channel ? (
                        <Box className="w-[32rem]">
                            <TemplateBuilderManager
                                integration={providerMetadata}
                                isSingleSelect={true}
                                handleTemplateSelect={handleTemplateSelect}
                                integrationId={communicationDetails?.id}
                                channel={communicationDetails?.channel}
                                onClose={undefined}
                                editMode={false}
                            />
                        </Box>
                    ) : (
                        <>
                            <SetupContent
                                templateMetadata={templateMetadata}
                                onTemplateSetupComplete={undefined}
                                fields={undefined}
                            />
                        </>
                    )}
                </Box>
            </Stack>
            {templateDetails?.id && templateMetadata && (
                <Box className="flex justify-end w-full">
                    <TemplateContentPreview
                        templateMetadata={templateMetadata}
                    />
                </Box>
            )}
        </Group>
    );
};

export default CampaignBuilderContent;
