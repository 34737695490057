import Modal from '@components/common/modal/Modal';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, TextInput } from '@mantine/core';

import { CaretRight } from '@phosphor-icons/react';
import { useTemplateBuilderContext } from '@providers/TemplateBuilderProvider';
import React from 'react';
import { ButtonVariant } from '../../../../mantineTheme';

interface NewTemplateNameModalProps {
    opened: boolean;
    onClose: () => void;
    onSave: () => void;
    isLoading: boolean;
}

const NewTemplateNameModal: React.FC<NewTemplateNameModalProps> = ({
    opened,
    onClose,
    onSave,
    isLoading,
}) => {
    const { templatePayload } = useTemplateBuilderContext(
        (context) => context.state,
    );

    const { setTemplatePayload } = useTemplateBuilderContext(
        (context) => context.actions,
    );

    const { t } = useLocale();

    const handleTemplateSave = () => {
        if (!templatePayload?.name) {
            return;
        }

        onSave();
        onClose();
    };

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            title={'New template'}
            withCloseButton={true}
            withOverlay={true}
            footerLeftSection={
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={onClose}
                    disabled={isLoading}
                >
                    {t('common.cancel')}
                </Button>
            }
            footerRightSection={
                <Button
                    rightIcon={<CaretRight color="white" />}
                    variant={ButtonVariant.PRIMARY}
                    onClick={handleTemplateSave}
                    disabled={isLoading || !templatePayload?.name}
                >
                    {t('common.save')}
                </Button>
            }
        >
            <Box>
                <TextInput
                    value={templatePayload?.name}
                    onChange={(e) => {
                        setTemplatePayload({
                            ...templatePayload,
                            name: e.currentTarget.value,
                        });
                    }}
                    placeholder={t('template_manager.new_template')}
                />
            </Box>
        </Modal>
    );
};

export default React.memo(NewTemplateNameModal);
