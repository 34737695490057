import {
    DimensionType,
    MetricType,
    type CustomDimensionType,
    type TableCalculationType,
} from '@lightdash/common';
import {
    CalendarBlank,
    CircleHalf,
    CursorClick,
    Hash,
    TextAa,
    User,
    type IconProps,
} from '@phosphor-icons/react';
import { type CSSProperties } from 'styled-components';

interface FieldIconProps extends IconProps {
    type:
        | DimensionType
        | MetricType
        | TableCalculationType
        | CustomDimensionType.SQL
        | undefined;
    style?: CSSProperties;
}

const FieldIcon = ({ type, ...rest }: FieldIconProps) => {
    switch (type) {
        case DimensionType.STRING: {
            return (
                <TextAa
                    color={'rgb(var(--color-gray-600)'}
                    weight="duotone"
                    {...rest}
                />
            );
        }
        case DimensionType.NUMBER:
        case MetricType.SUM:
        case MetricType.AVERAGE:
        case MetricType.COUNT:
        case MetricType.COUNT_DISTINCT:
        case MetricType.MAX:
        case MetricType.MEDIAN:
        case MetricType.MIN:
        case MetricType.PERCENTILE: {
            return (
                <Hash
                    color={'rgb(var(--color-gray-600)'}
                    weight="duotone"
                    {...rest}
                />
            );
        }
        case DimensionType.TIMESTAMP: {
            return (
                <CalendarBlank
                    color={'rgb(var(--color-gray-600)'}
                    weight="duotone"
                    {...rest}
                />
            );
        }
        case DimensionType.DATE: {
            return (
                <CalendarBlank
                    color={'rgb(var(--color-gray-600)'}
                    weight="duotone"
                    {...rest}
                />
            );
        }
        case DimensionType.BOOLEAN: {
            return (
                <CircleHalf
                    color={'rgb(var(--color-gray-600)'}
                    weight="duotone"
                    {...rest}
                />
            );
        }
        case DimensionType.EVENT: {
            return (
                <CursorClick
                    color={'rgb(var(--color-gray-600)'}
                    weight="duotone"
                    {...rest}
                />
            );
        }

        default:
            <User
                color={'rgb(var(--color-gray-600)'}
                weight="duotone"
                {...rest}
            />;
    }
    return null;
};

export default FieldIcon;
