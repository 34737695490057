import { filterPropertyTypeMapper } from '@components/Audience/Filters/FilterSelect/useFilterSelectUtils';
import {
    useFiltersContext,
    type FieldWithSuggestions,
} from '@components/Audience/Filters/FiltersProvider';
import Modal from '@components/common/modal/Modal';
import Select from '@components/common/Select';
import { getCustomMetricType } from '@components/Explorer/ExploreTree/TableTree/Tree/TreeSingleNodeActions';
import useNotify from '@hooks/toaster/useNotify';
import { useCreateCustomMetricMutation } from '@hooks/useCustomMetric';
import { useGetRelatedTables } from '@hooks/useGetRelatedTables';
import { useIsEqual } from '@hooks/useIsEqual';
import { useLocale } from '@hooks/useLocale';
import {
    friendlyName,
    type AdditionalMetric,
    type CompiledDimension,
    type FilterGroup,
    type FilterGroupItem,
    type MetricType,
} from '@lightdash/common';
import {
    Box,
    Button,
    Flex,
    Group,
    Loader,
    Text,
    Textarea,
    TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { CaretRight } from '@phosphor-icons/react';
import { useCustomMetricContext } from '@providers/CustomMetricProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { useCallback, useEffect, useState } from 'react';
import { ButtonVariant } from '../../mantineTheme';
import CustomMetricFilter from './CustomMetricFilter';
import CustomMetricPropertySelect from './CustomMetricPropertySelect';
import { useCustomMetricInitialData } from './utils';
export type CustomMetricForm = {
    name: string;
    metric: MetricType;
    description: string | undefined;
};

const CustomMetricManagerModal = () => {
    const { t } = useLocale();
    const [
        confirmLeaveMetric,
        { open: openConfirmLeaveMetric, close: closeConfirmLeaveMetric },
    ] = useDisclosure(false);
    const {
        showMetricManagerModal,
        selectedTable,
        selectedDimension,
        customAttributePayload,
        initialCustomAttributePayload,
        duplicatedMetric,
        viewMode,
        disableBackButton,
    } = useCustomMetricContext((context) => context.reducerState);
    const {
        closeCustomMetricManagerModal,
        openCustomMetricBaseTableModal,
        selectDimension,
        setFilters,
        addSelectedDimensionDetails,
        addMetricTypeDetailsToPayload,
        addNameDetailsToPayload,
        addDescriptionDetailsToPayload,
        toogleIsDuplicated,
        toggleIsViewMode,
        resetTheCustometricContext,
    } = useCustomMetricContext((context) => context.actions);
    const { activeRelation, activeRelationUuid } = useRelationContext();
    const { fieldsMap } = useFiltersContext();
    const { mutateAsync, isLoading, isSuccess } =
        useCreateCustomMetricMutation();
    const { showToastError } = useNotify();
    const { icon, onActiveClasses } =
        filterPropertyTypeMapper.find(
            (item) => item.propertyType === selectedTable?.type,
        ) || {};
    const relatedTables = useGetRelatedTables(
        selectedTable?.name ?? '',
        activeRelation,
        fieldsMap,
    );
    const [activeField, setActiveField] = useState<
        CompiledDimension | FieldWithSuggestions | undefined
    >(undefined);
    const didSelectedDimension = selectedDimension ? true : false;
    const form = useForm<CustomMetricForm>({
        initialValues: {
            name: customAttributePayload.name ?? '',
            description: customAttributePayload.description ?? '',
            metric: '' as MetricType,
        },
    });
    const initialState = useCustomMetricInitialData(
        undefined,
        activeRelationUuid,
    );
    const hasPayloadChanged = useIsEqual(
        initialCustomAttributePayload,
        customAttributePayload,
    );

    useEffect(() => {
        if (customAttributePayload.name && (duplicatedMetric || viewMode)) {
            if (duplicatedMetric) {
                toogleIsDuplicated(false);
                addNameDetailsToPayload(
                    `Copy of ${customAttributePayload.name}`,
                );
            }
            if (viewMode) {
                addNameDetailsToPayload(customAttributePayload.name);
            }
            setActiveField(selectedDimension);
            setFilters({
                dimensions: {
                    and: (customAttributePayload.definition as AdditionalMetric)
                        .filters as FilterGroupItem[],
                } as FilterGroup,
            });
        }
    }, [
        duplicatedMetric,
        customAttributePayload,
        selectedDimension,
        toogleIsDuplicated,
        addNameDetailsToPayload,
        setFilters,
        viewMode,
    ]);

    const handleClose = useCallback(() => {
        closeCustomMetricManagerModal();
        form.reset();
        setActiveField(undefined);
        resetTheCustometricContext(initialState);
        toggleIsViewMode(false);
    }, [
        closeCustomMetricManagerModal,
        toggleIsViewMode,
        initialState,
        resetTheCustometricContext,
        form,
    ]);
    useEffect(() => {
        if (isSuccess) {
            handleClose();
        }
    }, [isSuccess, handleClose]);
    const handleSubmit = async (_data: CustomMetricForm) => {
        if (customAttributePayload.name.trim().length === 0) {
            showToastError({
                title: t('custom_metric_create_name_enter_toast'),
            });
        } else if (selectedDimension && selectedTable) {
            await mutateAsync(customAttributePayload);
        }
    };
    return (
        <>
            <Modal
                opened={showMetricManagerModal}
                title={
                    <Box>
                        <Text>{t('custom_metric_table_modal.title')}</Text>
                        <Text className="inline text-gray-500">
                            {t('custom_metric_table_modal.sub_title')}{' '}
                        </Text>
                        <Flex
                            direction="row"
                            gap="xs"
                            align="center"
                            className={`inline text-sm font-semibold ${
                                !disableBackButton ? 'cursor-pointer' : ''
                            }`}
                            onClick={() => {
                                if (!disableBackButton) {
                                    handleClose();
                                    openCustomMetricBaseTableModal();
                                }
                            }}
                        >
                            {icon && (
                                <Box
                                    component="span"
                                    className="inline-block mr-1"
                                    style={{ verticalAlign: 'middle' }}
                                >
                                    {icon}
                                </Box>
                            )}
                            <Text
                                className={`inline ${onActiveClasses?.label}`}
                            >
                                {selectedTable?.label}
                            </Text>
                        </Flex>
                    </Box>
                }
                onClose={() => {
                    if (viewMode || !hasPayloadChanged) {
                        closeCustomMetricManagerModal();
                        handleClose();
                    } else openConfirmLeaveMetric();
                }}
                footerRightSection={
                    !viewMode && (
                        <Group>
                            <Button
                                variant={ButtonVariant.DEFAULT}
                                onClick={() => openConfirmLeaveMetric()}
                                disabled={isLoading}
                            >
                                {t(
                                    'custom_metric.dimension_modal_cancel_button',
                                )}
                            </Button>
                            <Button
                                form="create_custom_metric_form"
                                variant={ButtonVariant.PRIMARY}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                type="submit"
                                disabled={
                                    isLoading ||
                                    activeField === undefined ||
                                    (customAttributePayload.definition?.type
                                        ?.length ?? 0) < 1
                                }
                                rightIcon={
                                    isLoading ? (
                                        <Loader color="white" size={14} />
                                    ) : (
                                        <CaretRight
                                            color={
                                                activeField
                                                    ? 'white'
                                                    : 'rgb(var(--color-gray-500))'
                                            }
                                        />
                                    )
                                }
                            >
                                {isLoading
                                    ? t('common.saving')
                                    : t(
                                          'custom_metric.dimension_modal_create_button',
                                      )}
                            </Button>
                        </Group>
                    )
                }
                size="xl"
                closeButtonProps={{
                    className: 'absolute top-3 right-3',
                }}
                centered
            >
                <form
                    name="create_custom_metric_form"
                    id="create_custom_metric_form"
                    onSubmit={form.onSubmit((values) => handleSubmit(values))}
                    className="!min-h-[555px]"
                    onKeyDown={(event) => {
                        if (event.key === 'Escape') {
                            event.stopPropagation();
                        }
                    }}
                >
                    <Flex direction="column" gap="lg" className="text-gray-800">
                        <Box>
                            <TextInput
                                data-autofocus
                                label={
                                    <Text className="text-gray-800">
                                        {t(
                                            'custom_metric.dimension_modal_name_label',
                                        )}
                                    </Text>
                                }
                                className="!w-[272px]"
                                {...form.getInputProps('name')}
                                value={customAttributePayload.name}
                                onChange={(event) => {
                                    form.setFieldValue(
                                        'name',
                                        event.currentTarget.value,
                                    );
                                    addNameDetailsToPayload(
                                        event.currentTarget.value,
                                    );
                                }}
                                disabled={viewMode || isLoading}
                            />
                            <Text className="mt-1 text-sm text-gray-500">
                                {t(
                                    'custom_metric.dimension_modal_name_description',
                                )}
                            </Text>
                        </Box>
                        <Textarea
                            label={
                                <Box>
                                    <Text className="inline me-2">
                                        {t(
                                            'custom_metric.dimension_modal_description_label',
                                        )}
                                    </Text>
                                    <Text className="inline text-gray-500">
                                        {t(
                                            'custom_metric.dimension_modal_description_label_optional',
                                        )}
                                    </Text>
                                </Box>
                            }
                            placeholder={t(
                                'custom_metric.dimension_modal_description_description',
                            )}
                            {...form.getInputProps('description')}
                            value={customAttributePayload.description ?? ''}
                            onChange={(event) => {
                                form.setFieldValue(
                                    'description',
                                    event.currentTarget.value,
                                );
                                addDescriptionDetailsToPayload(
                                    event.currentTarget.value,
                                );
                            }}
                            disabled={viewMode || isLoading}
                        />
                        <Box className="!min-w-[265px]">
                            <Text className="mb-1">
                                {t(
                                    'custom_metric.dimension_modal_property_label',
                                )}
                            </Text>
                            <CustomMetricPropertySelect
                                fields={
                                    relatedTables
                                        ? (Object.values(
                                              relatedTables,
                                          ) as FieldWithSuggestions[])
                                        : []
                                }
                                onSubmit={(field) => {
                                    selectDimension(field[0]);
                                    addSelectedDimensionDetails(field[0]);
                                    setActiveField(field[0]);
                                }}
                                isLoading={isLoading}
                                activeField={activeField}
                            />
                        </Box>
                        <Select
                            withinPortal={true}
                            label={
                                <Text
                                    className={
                                        !didSelectedDimension
                                            ? 'text-gray-400'
                                            : ''
                                    }
                                >
                                    {t(
                                        'custom_metric.dimension_modal_function_label',
                                    )}
                                </Text>
                            }
                            placeholder={t(
                                'custom_metric.dimension_modal_function_description',
                            )}
                            data={
                                selectedDimension
                                    ? getCustomMetricType(
                                          (
                                              selectedDimension as CompiledDimension
                                          ).type,
                                      ).map((name) => ({
                                          label: friendlyName(name),
                                          value: name,
                                      }))
                                    : ['']
                            }
                            className="!w-[272px]"
                            disabled={
                                !didSelectedDimension || viewMode || isLoading
                            }
                            required
                            withAsterisk={false}
                            value={
                                (
                                    customAttributePayload.definition as AdditionalMetric
                                )?.type
                            }
                            onChange={(value) => {
                                form.setFieldValue(
                                    'metric',
                                    value as MetricType,
                                );
                                addMetricTypeDetailsToPayload(
                                    value as MetricType,
                                );
                            }}
                        />

                        <Text
                            className={
                                !didSelectedDimension ? 'text-gray-400' : ''
                            }
                        >
                            {t('custom_metric.dimension_modal.filter_label')}
                        </Text>
                        <Box
                            className={
                                !didSelectedDimension ? 'opacity-70' : ''
                            }
                        >
                            <CustomMetricFilter isLoading={isLoading} />
                        </Box>
                    </Flex>
                </form>
            </Modal>
            <Modal
                opened={confirmLeaveMetric}
                onClose={closeConfirmLeaveMetric}
                withOverlay={true}
                title={t('custom_metric_confirm_exit_title')}
                footerRightSection={
                    <Group spacing="xs">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={closeConfirmLeaveMetric}
                        >
                            {t('custom_metric_confirm_exit_no_button')}
                        </Button>

                        <Button
                            rightIcon={
                                <CaretRight weight="regular" color="white" />
                            }
                            onClick={() => {
                                closeConfirmLeaveMetric();
                                handleClose();
                            }}
                        >
                            {t('custom_metric_confirm_exit_button')}
                        </Button>
                    </Group>
                }
                yOffset={0}
            >
                <Text className="pt-3 text-sm text-gray-800">
                    {t('custom_metric_confirm_exit_description')}
                </Text>
            </Modal>
        </>
    );
};
export default CustomMetricManagerModal;
