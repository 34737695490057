import { type JourneyAction } from '@lightdash/common';
import { Stack } from '@mantine/core';
import React from 'react';
import { actionMapper } from './actionMapper';

interface ActionStackProps {
    actions: JourneyAction[];
    onActionChange: (payload: JourneyAction) => void;
    nodeId: string;
}

const ActionStack: React.FC<ActionStackProps> = ({
    actions,
    onActionChange,
    nodeId,
}) => {
    if (!actions.length) return null;
    return (
        <Stack>
            {actions.map((action, index) => {
                const { component: ActionComponent, getProps } =
                    actionMapper[action.type];
                const props = getProps(
                    action as unknown as any, //Typecasting is required here since there are many possibilites of action type here. This is taken care of in the getProps function
                    onActionChange,
                    nodeId,
                ) as unknown as any; //Typecasting is required here since there are many possibilites of action type here. This is taken care of in the getProps function
                return <ActionComponent key={index} {...props} />;
            })}
        </Stack>
    );
};

export default React.memo(ActionStack);
