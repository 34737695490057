import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type ApiResponse,
    type CreateTemplateRequest,
    type GetAllProviderTemplateRequest,
    type GetAllProviderTemplateResponse,
    type ImportTemplateFromIntegrationRequest,
    type Template,
    type TemplateContentDetails,
    type TemplateListWithPagination,
    type UpdateTemplateRequest,
} from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

export const getTemplates = async (
    projectId: string,
    channel: string,
    perPage: number,
    currentPage: number,
) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/templates?channel=${channel}&perPage=${perPage}&currentPage=${currentPage}`,
        method: 'GET',
        body: undefined,
    });

export const useTemplates = (
    channel: string,
    perPage: number,
    currentPage: number,
) => {
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();

    return useQuery<ApiResponse, ApiError, TemplateListWithPagination>({
        queryKey: [QueryKeys.GET_ALL_TEMPLATE, channel, perPage, currentPage],
        queryFn: () =>
            getTemplates(projectUuid || '', channel, perPage, currentPage),
        enabled: !!projectUuid || !channel,
        retry: true,
        onError: (result) => console.log(result),
        keepPreviousData: true,
    });
};

export const importTemplateFromProvider = async (
    projectId: string,
    providerId: string | undefined,
    integrationId: string | undefined,
    body: GetAllProviderTemplateRequest,
) =>
    sortmentApi<GetAllProviderTemplateResponse>({
        url: `/projects/${projectId}/templates/provider-template?providerId=${providerId}&integrationId=${integrationId}`,
        method: 'POST',
        body: JSON.stringify(body),
    });

export const useImportTemplateFromProvider = (
    providerId: string | undefined,
    integrationId: string | undefined,
) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<
        GetAllProviderTemplateResponse,
        ApiError,
        GetAllProviderTemplateRequest
    >(
        (payload: GetAllProviderTemplateRequest) =>
            importTemplateFromProvider(
                projectUuid,
                providerId,
                integrationId,
                payload,
            ),
        {
            mutationKey: [
                QueryKeys.IMPORT_TEMPLATES_PROVIDER,
                providerId,
                integrationId,
            ],
            onError: (error) => {
                showToastError({
                    title: t('template_manager.import_failed'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

export const migrateTemplate = async (
    projectId: string,
    body: ImportTemplateFromIntegrationRequest,
) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/templates/migrate-template`,
        method: 'POST',
        body: JSON.stringify(body),
    });

export const useMigrateTemplate = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();

    return useMutation<
        any,
        ApiError,
        ImportTemplateFromIntegrationRequest,
        Template[]
    >(
        (payload: ImportTemplateFromIntegrationRequest) =>
            migrateTemplate(projectUuid, payload),
        {
            mutationKey: [QueryKeys.MIGRATE_TEMPLATES_PROVIDER],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('template_manager.import_successful'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('template_manager.import_failed'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

export const getTemplate = async (projectId: string, templateUuid: string) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectId}/templates/${templateUuid}?includeContents=true`,
        method: 'GET',
        body: undefined,
    });

export const useGetTemplateById = (templateUuid: string) => {
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();

    return useQuery<ApiResponse, ApiError, Template>({
        queryKey: [QueryKeys.GET_TEMPLATE, templateUuid],
        queryFn: () => getTemplate(projectUuid ?? '', templateUuid),
        enabled: !!projectUuid && !!templateUuid,
    });
};

export const createTemplate = async (
    projectId: string,
    payload: CreateTemplateRequest,
) =>
    sortmentApi<Template>({
        url: `/projects/${projectId}/templates`,
        method: 'POST',
        body: JSON.stringify(payload),
    });

export const useCreateTemplate = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    // const { t } = useLocale();

    const response = useMutation<Template, ApiError, CreateTemplateRequest>(
        (payload: CreateTemplateRequest) =>
            createTemplate(projectUuid, payload),
        {
            mutationKey: [QueryKeys.CREATE_TEMPLATE],
            onSuccess: async () => {
                showToastSuccess({
                    title: 'Template created successfully',
                });
            },
            onError: (error) => {
                showToastError({
                    title: 'Failed to create template',
                    subtitle: error.error.message,
                });
            },
        },
    );
    return response;
};

export const updateTemplate = async (
    projectId: string,
    templateId: string,
    payload: TemplateContentDetails,
    version: number | undefined,
) =>
    sortmentApi<Template>({
        url: `/projects/${projectId}/templates/${templateId}/version/${version}/content`,
        method: 'PUT',
        body: JSON.stringify(payload),
    });

export const useUpdateTemplate = (
    templateId: string,
    version: number | undefined,
) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const query = useQueryClient();
    // const { t } = useLocale();

    const response = useMutation<Template, ApiError, TemplateContentDetails>(
        (payload: TemplateContentDetails) =>
            updateTemplate(projectUuid, templateId, payload, version),
        {
            mutationKey: [QueryKeys.UPDATE_TEMPLATE, templateId, projectUuid],
            onSuccess: async () => {
                showToastSuccess({
                    title: 'Template updated successfully',
                });
                await query.refetchQueries([QueryKeys.GET_ALL_TEMPLATE]);
            },
            onError: (error) => {
                showToastError({
                    title: 'Failed to update template',
                    subtitle: error.error.message,
                });
            },
        },
    );
    return response;
};

export const updateTemplateName = async (
    projectId: string,
    templateId: string,
    payload: UpdateTemplateRequest,
) =>
    sortmentApi<Template>({
        url: `/projects/${projectId}/templates/${templateId}`,
        method: 'PUT',
        body: JSON.stringify(payload),
    });

export const useUpdateTemplateName = (templateId: string) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const query = useQueryClient();
    // const { t } = useLocale();

    const response = useMutation<Template, ApiError, UpdateTemplateRequest>(
        (payload: UpdateTemplateRequest) =>
            updateTemplateName(projectUuid, templateId, payload),
        {
            mutationKey: [QueryKeys.UPDATE_TEMPLATE, templateId, projectUuid],
            onSuccess: async () => {
                showToastSuccess({
                    title: 'Template updated successfully',
                });
                await query.refetchQueries([QueryKeys.GET_ALL_TEMPLATE]);
            },
            onError: (error) => {
                showToastError({
                    title: 'Failed to update template',
                    subtitle: error.error.message,
                });
            },
        },
    );
    return response;
};
