import { useLocale } from '@hooks/useLocale';
import { WarehouseTypes } from '@lightdash/common';
import { FileInput, Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const BigQueryStorageForm: React.FC<{
    disabled: boolean;
}> = ({ disabled }) => {
    const { register, setValue } = useFormContext();

    const [temporaryFile, setTemporaryFile] = useState<File>();
    const { t } = useLocale();
    useEffect(() => {
        setValue('warehouseConfig.warehouseType', WarehouseTypes.BIGQUERY);
    }, [setValue]);
    return (
        <Stack>
            <Controller
                name="blobStorageConfig.keyfileContents"
                render={({ field }) => (
                    <FileInput
                        {...field}
                        label={t('gcp_blob_storage.label')}
                        description={t('gcp_blob_storage.description')}
                        // FIXME: until mantine 7.4: https://github.com/mantinedev/mantine/issues/5401#issuecomment-1874906064
                        // @ts-ignore
                        placeholder={t('gcp_blob_storage.placeholder')}
                        {...register('blobStorageConfig.keyfileContents')}
                        required
                        accept="application/json"
                        value={temporaryFile}
                        onChange={(file) => {
                            if (file) {
                                const fileReader = new FileReader();
                                fileReader.onload = function (event) {
                                    const contents = event.target?.result;
                                    if (typeof contents === 'string') {
                                        setTemporaryFile(file);
                                        field.onChange(JSON.parse(contents));
                                    } else {
                                        field.onChange(null);
                                    }
                                };
                                fileReader.readAsText(file);
                            }
                            field.onChange(null);
                        }}
                        disabled={disabled}
                    />
                )}
            />
        </Stack>
    );
};

export default BigQueryStorageForm;
