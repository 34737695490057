import { sortmentApi } from '@api/index';
import { type ApiError, type ApiRelationsResults } from '@lightdash/common';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';

const getRelations = async (projectId: string) =>
    sortmentApi<ApiRelationsResults>({
        url: `/projects/${projectId}/relations`,
        method: 'GET',
        body: undefined,
    });

export const useRelations = ({
    projectId,
    isEnabled = true,
}: {
    projectId: string | undefined;
    isEnabled: boolean;
}) => {
    const relations = useQuery<ApiRelationsResults, ApiError>({
        queryKey: [QueryKeys.RELATIONS, projectId, isEnabled],
        queryFn: () => getRelations(projectId || ''),
        enabled: isEnabled && !!projectId,
    });
    return relations;
};
