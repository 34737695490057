import { JourneyNodeEnum } from '@components/Journeys/Builder/types';
import {
    JourneyStatus,
    PeriodType,
    ReservedEventColumns,
    type Journey,
    type JourneyBlocksList,
} from '@lightdash/common';
import { type JourneyReducerState } from '@providers/JourneyBuilderProvider';
import { useMemo } from 'react';
import { ControlPanel, JourneyBuilderMode } from './types';
import { useJourneyNodesAndEdges } from './useJourneyNodesAndEdges';

interface InitialDataProps {
    mode: JourneyBuilderMode;
    blocksList: JourneyBlocksList;
    projectUuid: string;
    journeyDataById: Journey | undefined;
}

const useJourneyInitialData = ({
    mode,
    blocksList,
    projectUuid,
    journeyDataById,
}: InitialDataProps): JourneyReducerState => {
    const { nodes, edges } = useJourneyNodesAndEdges(journeyDataById);
    return useMemo(() => {
        let builderState: JourneyReducerState = {
            nodes,
            edges,
            controlPanel: {
                isOpen: false,
            },
            journeyPayload: {
                startDate:
                    mode === JourneyBuilderMode.CREATE && journeyDataById
                        ? undefined
                        : journeyDataById?.startDate ?? undefined,
                endDate:
                    mode === JourneyBuilderMode.CREATE && journeyDataById
                        ? undefined
                        : journeyDataById?.endDate ?? undefined,
                entryLogic: journeyDataById?.entryLogic ?? {
                    cooldown: -1,
                    contextId: ReservedEventColumns.USER_ID,
                    contextTotal: -1,
                    killExisting: true,
                    contextConcurrency: -1,
                    uiConfig: {
                        cooldownType: PeriodType.DAY,
                    },
                },
                name:
                    mode === JourneyBuilderMode.CREATE && journeyDataById
                        ? `copy of ${journeyDataById.name}`
                        : journeyDataById?.name ?? 'Untitled Journey',
                projectId: projectUuid,
                displayName: journeyDataById?.displayName ?? '',
                config: journeyDataById?.config ?? {
                    nodes: [],
                },
                triggers: journeyDataById?.triggers ?? {
                    entry: [],
                    exit: [],
                    conversion: undefined,
                    signals: undefined,
                },
            },
            journeyStatus: journeyDataById?.status ?? JourneyStatus.DRAFT,
            blocksList: blocksList,
        };

        if (
            (mode === JourneyBuilderMode.CREATE &&
                (!journeyDataById ||
                    journeyDataById.triggers.entry.length === 0)) ||
            (mode === JourneyBuilderMode.EDIT &&
                journeyDataById &&
                journeyDataById.triggers.entry.length === 0)
        ) {
            builderState = {
                ...builderState,
                nodes: [
                    {
                        id: '1',
                        position: { x: 0, y: 0 },
                        type: JourneyNodeEnum.PLACEHOLDER,
                        data: {
                            type: JourneyNodeEnum.PLACEHOLDER,
                            placeHolderType: JourneyNodeEnum.TRIGGER,
                        },
                    }, // The first node is the placeholder node to add a trigger block
                ],
                controlPanel: {
                    isOpen: true,
                    type: ControlPanel.TRIGGERS,
                },
            };
        }

        return builderState;
    }, [nodes, edges, journeyDataById, projectUuid, blocksList, mode]);
};

export default useJourneyInitialData;
