import SelectedCampaignAudience from '@components/Campaigns/Builder/Steps/CampaignBuilderAudience/SelectedCampaignAudience';
import BuilderContainer from '@components/common/BuilderContainer';
import GenericOption from '@components/common/Card/GenericOption';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { useTriggerCampaign, useUpdateCampaign } from '@hooks/useCampaigns';
import {
    useGetIntegratedProviderConfig,
    useGetProviderConfig,
} from '@hooks/useChannels';
import { useAudiences } from '@hooks/useGetAudience';
import { useLocale } from '@hooks/useLocale';
import { useGetTemplateById } from '@hooks/useTemplate';
import useTimestamp from '@hooks/useTimestamp';
import {
    AudienceRunStatus,
    AudienceStatus,
    CampaignScheduleType,
    CampaignStatus as CampaignStatusEnum,
    type AudienceType,
    type Campaign,
    type CampaignUpdateRequest,
} from '@lightdash/common';
import { Box, Button, Group, Stack, Text } from '@mantine/core';
import {
    CaretRight,
    CheckCircle,
    ClockCountdown,
    Copy,
    NewspaperClipping,
} from '@phosphor-icons/react';
import { useCampaignContext } from '@providers/CampaignProvider';
import { useProjectContext } from '@providers/ProjectProvider';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';
import { toCampaignAnalyticsTimeFormat } from '../Analytics/utils';
import IntegrationOption from '../Builder/IntegrationOption';
import { CampaignBuilderStep } from '../Builder/types';
import { allowCampaignEdits } from '../utils';
import {
    getCampaignAnalyticsDashboardUrl,
    isCampaignExecutionDone,
} from './../campaignUtils';

interface CampaignPreviewContainerProps {
    campaignData: Campaign;
}

const CampaignPreviewContainer: React.FC<CampaignPreviewContainerProps> = ({
    campaignData,
}) => {
    const { t } = useLocale();
    const history = useHistory();
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();
    const queryClient = useQueryClient();
    const { projectData } = useProjectContext();
    const { mutateAsync: mutateTriggerCampaign } = useTriggerCampaign();
    const { getTimestamp } = useTimestamp();

    const { setCurrentStep } = useCampaignContext((context) => context.actions);

    const { campaignPayload } = useCampaignContext((context) => context.state);
    const { communicationDetails } = campaignPayload;

    const isCampaignEditable = useMemo(
        () =>
            allowCampaignEdits({
                executionTime: campaignData.schedule.executionTime,
                status: campaignData.status,
            }),
        [campaignData],
    );

    const {
        data: integrationConfig,
        isInitialLoading: isIntegrationConfigLoading,
    } = useGetIntegratedProviderConfig(
        communicationDetails.providerId,
        communicationDetails.id,
    );
    const { data: providerConfig, isInitialLoading: isProviderConfigLoading } =
        useGetProviderConfig(communicationDetails.providerId);

    const { data: templateData, isInitialLoading: isTemplateDataLoading } =
        useGetTemplateById(campaignData.templateDetails.id);

    const { mutateAsync: updateCampaign } = useUpdateCampaign(
        campaignData?.id ?? '',
        false,
    );

    const {
        data: audiences,
        isLoading,
        isInitialLoading,
    } = useAudiences({
        query: `status=${AudienceStatus.ACTIVE}&lastRunStatus=${AudienceRunStatus.SUCCESS}`,
        perPage: 9999,
        currentPage: 1,
        polling: false,
    });

    const handleDuplicateCampaign = useCallback(() => {
        if (!campaignData) return;

        history.push(
            `/projects/${projectUuid}/campaigns/create?templateId=${campaignData.id}`,
        );
    }, [campaignData, history, projectUuid]);

    const handleViewReport = useCallback(() => {
        if (!campaignData || !projectData) return;

        const dashboardUrl = getCampaignAnalyticsDashboardUrl(
            projectData,
            campaignData.id,
        );

        history.push(dashboardUrl);
    }, [campaignData, history, projectData]);

    const handleTriggerCampaign = useCallback(async () => {
        if (!campaignData.id) return;
        await mutateTriggerCampaign({ campaignId: campaignData.id ?? '' });
        await queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
    }, [mutateTriggerCampaign, queryClient, campaignData]);

    const renderBuilderContainerRightSection = useMemo(() => {
        return (
            <Group>
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={handleDuplicateCampaign}
                    leftIcon={<Copy />}
                >
                    Duplicate
                </Button>
                {campaignData.status === CampaignStatusEnum.DRAFT && (
                    <Button
                        variant={ButtonVariant.PRIMARY}
                        onClick={() => handleTriggerCampaign()}
                        rightIcon={
                            <CaretRight
                                color="white"
                                weight="regular"
                                size={16}
                            />
                        }
                    >
                        Send message now
                    </Button>
                )}
                {campaignData.status === CampaignStatusEnum.COMPLETED && (
                    <Button
                        variant={ButtonVariant.PRIMARY}
                        onClick={handleViewReport}
                        rightIcon={
                            <CaretRight
                                color="white"
                                weight="regular"
                                size={16}
                            />
                        }
                    >
                        View report
                    </Button>
                )}
            </Group>
        );
    }, [
        handleDuplicateCampaign,
        campaignData.status,
        handleTriggerCampaign,
        handleViewReport,
    ]);

    const runTypeText = useMemo(() => {
        switch (campaignData.schedule.type) {
            case CampaignScheduleType.MANUAL:
                return campaignData.status === CampaignStatusEnum.COMPLETED
                    ? t('campaign.view.manual_trigger_done')
                    : t('campaign.view.manual_trigger_title');
            case CampaignScheduleType.DELAYED: {
                let time = campaignData.schedule.executionTime;
                if (!time) return;

                const timestamp = getTimestamp(time.toString());
                return t('campaign.view.is_scheduled_trigger_title', {
                    time: toCampaignAnalyticsTimeFormat(timestamp),
                    verb: isCampaignExecutionDone(time) ? 'was' : 'is',
                });
            }

            default:
                return '';
        }
    }, [campaignData, getTimestamp, t]);

    const renderStatusBanner = useMemo(() => {
        if (campaignData.status === CampaignStatusEnum.COMPLETED) {
            return (
                <Box className="flex gap-1.5 px-3 py-2 border rounded-lg bg-blu-800/2 border-blu-800/40 items-center">
                    <CheckCircle color="rgb(var(--color-blu-800))" />
                    <Text className="text-sm font-medium text-blu-800">
                        {t('campaign.view.campaign_execution_successfull', {
                            time: toCampaignAnalyticsTimeFormat(
                                getTimestamp(
                                    campaignData.updatedAt?.toString() ?? '',
                                ),
                            ),
                        })}
                    </Text>
                </Box>
            );
        }

        if (
            campaignData.status === CampaignStatusEnum.DRAFT &&
            campaignData.schedule.type === CampaignScheduleType.DELAYED
        ) {
            return (
                <Box className="flex gap-1.5 px-3 py-2 border rounded-lg bg-mustard-800/2 border-mustard-800/40 items-center">
                    <ClockCountdown color="rgb(var(--color-mustard-800))" />
                    <Text className="text-sm font-medium text-mustard-800">
                        {t('campaign.view.campaign_execution_scheduled', {
                            time: toCampaignAnalyticsTimeFormat(
                                getTimestamp(
                                    campaignData.schedule.executionTime?.toString() ??
                                        '',
                                ),
                            ),
                        })}
                    </Text>
                </Box>
            );
        }

        return null;
    }, [
        campaignData.schedule.executionTime,
        campaignData.schedule.type,
        campaignData.status,
        campaignData.updatedAt,
        getTimestamp,
        t,
    ]);

    const handleCampaignNameChange = useCallback(
        async (name: string) => {
            if (!campaignData.id) return;

            const payload: CampaignUpdateRequest = {
                name: name,
                audienceType: campaignData?.audienceType as AudienceType,
            };

            await updateCampaign(payload);
        },
        [campaignData.id, campaignData?.audienceType, updateCampaign],
    );

    const handleCampaignDescriptionChange = useCallback(
        async (description: string) => {
            if (!campaignData.id) return;

            const payload: CampaignUpdateRequest = {
                description: description,
                audienceType: campaignData?.audienceType as AudienceType,
            };

            await updateCampaign(payload);
        },
        [campaignData.id, campaignData?.audienceType, updateCampaign],
    );

    const handleOptionClick = useCallback(
        (campaignStep: CampaignBuilderStep) => {
            const mode =
                campaignData.status === CampaignStatusEnum.DRAFT ||
                campaignData.status === CampaignStatusEnum.SCHEDULED
                    ? 'edit'
                    : campaignData.status === CampaignStatusEnum.COMPLETED
                    ? 'view'
                    : null;
            if (!mode) return;

            setCurrentStep(campaignStep);
            history.push(
                `/projects/${projectUuid}/campaigns/${campaignData.id}/${mode}`,
            );
        },
        [
            campaignData.id,
            campaignData.status,
            history,
            projectUuid,
            setCurrentStep,
        ],
    );

    return (
        <BuilderContainer
            isEditable={isCampaignEditable}
            title={campaignData.name}
            onTitleChange={(value) => handleCampaignNameChange(value)}
            titlePlaceholder={t('audience_builder.name_placeholder')}
            description={campaignData.description}
            descriptionPlaceholder={t(
                'audience_builder.description_placeholder',
            )}
            onDescriptionChange={(value) =>
                handleCampaignDescriptionChange(value)
            }
            withContentPadding={true}
            rightSection={renderBuilderContainerRightSection}
        >
            <Stack>
                {renderStatusBanner}
                <Box
                    className="w-full"
                    onClick={() =>
                        isCampaignEditable &&
                        handleOptionClick(CampaignBuilderStep.AUDIENCE)
                    }
                >
                    <SelectedCampaignAudience
                        audiences={audiences?.data}
                        isLoading={isLoading || isInitialLoading}
                        withHeader={false}
                    />
                </Box>

                {isIntegrationConfigLoading || isProviderConfigLoading ? (
                    <SkeletonLoader height={70} />
                ) : integrationConfig && providerConfig ? (
                    <>
                        <IntegrationOption
                            title={integrationConfig?.customName}
                            providerName={providerConfig.providerName}
                            providerLogoUrl={providerConfig.logoUrl}
                            integrationId={
                                integrationConfig.integrationId ?? ''
                            }
                            isMutable={false}
                            customClass="!w-full"
                            channel={communicationDetails.channel}
                            onClick={() =>
                                isCampaignEditable &&
                                handleOptionClick(CampaignBuilderStep.CHANNEL)
                            }
                        />
                    </>
                ) : null}

                {isTemplateDataLoading ? (
                    <>
                        <SkeletonLoader height={70} />
                    </>
                ) : (
                    templateData && (
                        <GenericOption
                            title={
                                <Text className="text-sm font-medium text-gray-800">
                                    {templateData.name}
                                </Text>
                            }
                            subtitle={
                                <Group className="gap-1.5 text-xs text-gray-600">
                                    <NewspaperClipping />
                                    <Text>Template</Text>
                                </Group>
                            }
                            rightSection={<CaretRight weight="regular" />}
                            customClass={'!w-full'}
                            onClick={() =>
                                isCampaignEditable &&
                                handleOptionClick(CampaignBuilderStep.CONTENT)
                            }
                        />
                    )
                )}

                {runTypeText && (
                    <GenericOption
                        title={
                            <Group className="gap-1.5">
                                <ClockCountdown />
                                <Text className="text-sm font-medium text-gray-800">
                                    {runTypeText}
                                </Text>
                            </Group>
                        }
                        rightSection={<CaretRight weight="regular" />}
                        customClass={'!w-full'}
                        onClick={() =>
                            isCampaignEditable &&
                            handleOptionClick(CampaignBuilderStep.SCHEDULE)
                        }
                    />
                )}
            </Stack>
        </BuilderContainer>
    );
};

export default React.memo(CampaignPreviewContainer);
