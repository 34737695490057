import {
    Anchor,
    Breadcrumbs,
    Tooltip,
    type BreadcrumbsProps,
    type MantineSize,
    type TooltipProps,
} from '@mantine/core';
import { type FC } from 'react';
import { Link } from 'react-router-dom';

type BreadCrumbItem = {
    title: React.ReactNode;
    to?: string;
    onClick?: () => void;
    active?: boolean;
    tooltipProps?: Omit<TooltipProps, 'children'>;
};

export interface PageBreadcrumbsProps
    extends Omit<BreadcrumbsProps, 'children'> {
    size?: MantineSize;
    items: BreadCrumbItem[];
}

const PageBreadcrumbs: FC<PageBreadcrumbsProps> = ({
    items,
    size = 'sm',
    ...rest
}) => {
    const textSizeClass = `text-${size}`;

    return (
        <Breadcrumbs
            {...rest}
            styles={(theme) => ({
                root: {
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '0rem',
                },
                separator: {
                    display: 'inline-block',
                    marginLeft: '3px',
                    marginRight: '3px',
                    color: 'rgb(var(--color-gray-400))',
                    fontWeight: 700,
                    fontSize: theme.fontSizes.sm,
                },
            })}
        >
            {items.map((item, index) => {
                const anchor = item.to ? (
                    <>
                        <Anchor
                            key={item.tooltipProps ? undefined : index}
                            component={Link}
                            to={item.to}
                            className={`${textSizeClass} text-blu-800 font-medium`}
                        >
                            {item.title}
                        </Anchor>
                    </>
                ) : (
                    <Anchor
                        key={item.tooltipProps ? undefined : index}
                        className={`${textSizeClass} text-gray-600 cursor-text hover:no-underline`}
                    >
                        {item.title}
                    </Anchor>
                );

                return item.tooltipProps ? (
                    <Tooltip key={index} {...item.tooltipProps}>
                        {anchor}
                    </Tooltip>
                ) : (
                    anchor
                );
            })}
        </Breadcrumbs>
    );
};

export default PageBreadcrumbs;
