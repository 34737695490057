import { useFiltersContext } from '@components/Audience/Filters/FiltersProvider';
import { useFilterFields } from '@hooks/useFilterFields';
import { useLocale } from '@hooks/useLocale';
import {
    getFilterRulesByFieldType,
    getTotalFilterRules,
    type Field,
    type Filters,
} from '@lightdash/common';
import { Box, Button, Group, Menu, Text } from '@mantine/core';
import { Plus } from '@phosphor-icons/react';
import { useAudienceContext } from '@providers/AudienceProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { sortRelationFields } from '@utils/relation';
import { useCallback, useMemo, type FC } from 'react';
import { MoreHorizontal, X } from 'react-feather';
import { ButtonVariant } from '../../../mantineTheme';
import { categorizeDimensionsAndEvents } from '../utils';
import FilterFormPropertySelect from './FilterFormPropertySelect';
import FilterGroupForm from './FilterGroupForm';

type Props = {
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    isEditMode: boolean;
    index: number;
};

const FiltersForm: FC<Props> = ({ filters, setFilters, isEditMode, index }) => {
    const { activeRelation } = useRelationContext();

    const { fieldsMap, eventsMap, setFilterableFields, setShowFilter } =
        useFiltersContext();
    const { t } = useLocale();
    const filterGroups = useAudienceContext(
        (context) => context.state.unsavedChartVersion,
    );
    const filterGroup = filterGroups[index];

    const { addUnsavedAudienceFilter, removeUnsavedAudienceFilter } =
        useAudienceContext((context) => context.actions);
    const { fields, dimensions, metrics } = useFilterFields();
    const totalFilterRules = getTotalFilterRules(filters);
    const { valid: validFilterRulesPerType } = getFilterRulesByFieldType(
        fields as Field[],
        totalFilterRules,
    );

    const duplicateFilterGroup = useCallback(() => {
        addUnsavedAudienceFilter(filterGroup, index);
    }, [filterGroup, addUnsavedAudienceFilter, index]);

    const removeFilterGroup = useCallback(() => {
        removeUnsavedAudienceFilter(index);
    }, [removeUnsavedAudienceFilter, index]);

    const groupFilter = useMemo(
        () =>
            categorizeDimensionsAndEvents(
                filters.dimensions,
                fieldsMap,
                eventsMap,
            ),
        [filters.dimensions, fieldsMap, eventsMap],
    );
    return (
        <Box className="p-3.5 border-base shadow-card">
            <Group className="flex justify-between">
                <Text className="text-sm text-gray-600 uppercase">
                    {t('audiences.all_users')}
                </Text>
                {isEditMode && (
                    <Group className="gap-1">
                        <Menu
                            position="bottom-end"
                            shadow="md"
                            closeOnItemClick
                        >
                            <Menu.Target>
                                <Button
                                    variant={ButtonVariant.UNSTYLED}
                                    className="rounded-lg w-9 h-9 hover:bg-shade-6"
                                >
                                    <MoreHorizontal
                                        size={13}
                                        color={'rgb(var(--color-gray-600))'}
                                    />
                                </Button>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Item onClick={duplicateFilterGroup}>
                                    Duplicate group
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>

                        {filterGroups.length > 1 && (
                            <Button
                                variant={ButtonVariant.UNSTYLED}
                                className="rounded-lg w-9 h-9 hover:bg-shade-6"
                                onClick={removeFilterGroup}
                            >
                                <X
                                    size={13}
                                    color={'rgb(var(--color-gray-600))'}
                                />
                            </Button>
                        )}
                    </Group>
                )}
            </Group>

            {totalFilterRules.length >= 1 && (
                <>
                    <div style={{ position: 'relative' }}>
                        {filters.dimensions &&
                            groupFilter &&
                            validFilterRulesPerType.dimensions.length >= 1 && (
                                <FilterGroupForm
                                    allowConvertToGroup
                                    hideLine
                                    hideButtons
                                    conditionLabel="dimension"
                                    filterGroup={groupFilter}
                                    fields={sortRelationFields([
                                        ...dimensions,
                                        ...eventsMap,
                                    ])}
                                    isEditMode={isEditMode}
                                    onChange={(value) =>
                                        setFilters(
                                            {
                                                ...filters,
                                                dimensions: value,
                                            },
                                            false,
                                            index,
                                        )
                                    }
                                    onDelete={() => {
                                        if (filterGroups.length <= 1)
                                            setFilters(
                                                {
                                                    ...filters,
                                                    dimensions: undefined,
                                                },
                                                true,
                                                index,
                                            );
                                        else removeFilterGroup();
                                    }}
                                    filters={filters}
                                    setFilters={setFilters}
                                    groupIndex={index}
                                    relation={activeRelation}
                                />
                            )}
                        {filters.metrics &&
                            validFilterRulesPerType.metrics.length >= 1 && (
                                <FilterGroupForm
                                    allowConvertToGroup
                                    hideLine
                                    hideButtons
                                    conditionLabel="metric"
                                    filterGroup={filters.metrics}
                                    fields={metrics}
                                    isEditMode={isEditMode}
                                    onChange={(value) =>
                                        setFilters(
                                            {
                                                ...filters,
                                                metrics: value,
                                            },
                                            false,
                                            index,
                                        )
                                    }
                                    onDelete={() => {
                                        if (filterGroups.length <= 1)
                                            setFilters(
                                                {
                                                    ...filters,
                                                    metrics: undefined,
                                                },
                                                true,
                                                index,
                                            );
                                        else removeFilterGroup();
                                    }}
                                    filters={filters}
                                    setFilters={setFilters}
                                    groupIndex={index}
                                    relation={activeRelation}
                                />
                            )}
                    </div>
                </>
            )}
            {isEditMode && (
                <Box
                    className={`px-0 py-3  border-none ${
                        totalFilterRules.length >= 1 ? 'ml-20' : ''
                    }`}
                >
                    <FilterFormPropertySelect
                        filters={filters}
                        setFilters={setFilters}
                        index={index}
                        targetButton={
                            <Button
                                variant={ButtonVariant.SUBTLE}
                                leftIcon={<Plus weight={'bold'} size={13} />}
                                onClick={() => {
                                    setShowFilter(true);
                                    setFilterableFields({
                                        showDimensions: true,
                                        showMetrics: true,
                                        showEvents: true,
                                        showEventProperties: false,
                                    });
                                }}
                            >
                                {t(
                                    'custom_metric.dimension_modal.filter_label',
                                )}
                            </Button>
                        }
                    />
                </Box>
            )}
        </Box>
    );
};

export default FiltersForm;
