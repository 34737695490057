import {
    Stepper as MantineStepper,
    type StepperProps as MantineStepperProps,
} from '@mantine/core';
import React from 'react';

interface StepperProps extends Omit<MantineStepperProps, 'children'> {
    active: number;
    steps: { key: string; label: string; isVisited: boolean }[];
}

const Stepper: React.FC<StepperProps> = ({ active, steps, ...props }) => {
    return (
        <MantineStepper active={active} iconSize={18} {...props}>
            {steps.map((step, index) => (
                <MantineStepper.Step
                    key={step.key}
                    label={
                        <span
                            className={`${
                                active >= index || step.isVisited
                                    ? 'text-blu-800'
                                    : 'text-gray-600'
                            } ${
                                active === index
                                    ? 'font-semibold'
                                    : 'font-normal'
                            }`}
                        >
                            {step.label}
                        </span>
                    }
                    completedIcon={
                        <span className="text-blu-800 bg-blu-100 w-[inherit] h-[inherit] rounded-[inherit] text-center">
                            {index + 1}
                        </span>
                    }
                    icon={
                        <span
                            className={`${
                                step.isVisited
                                    ? 'bg-blu-100 text-blu-800'
                                    : 'bg-shade-6'
                            } w-[inherit] h-[inherit] rounded-[inherit] text-center`}
                        >
                            {index + 1}
                        </span>
                    }
                    progressIcon={
                        <span
                            className={
                                'w-[inherit] h-[inherit] rounded-[inherit] text-center bg-blu-800 text-white'
                            }
                        >
                            {index + 1}
                        </span>
                    }
                />
            ))}
        </MantineStepper>
    );
};

export default React.memo(Stepper);
