import styled from 'styled-components';

export const TypingIndicatorStyled = styled.div`
    .cs-typing-indicator__dot {
        background-color: rgb(var(--color-blu-800)) !important;
    }

    .cs-typing-indicator__text {
        font-size: 13px;
        color: rgb(var(--color-blu-800)) !important;
    }
`;

export const AiWidgetWrapper = styled.div`
    .cs-message-input__content-editor-wrapper {
        background-color: white !important;
    }

    .cs-message-input {
        border: none !important;
        display: flex;
        align-items: center;
        padding: 9px !important;
    }

    .cs-message-input__content-editor-wrapper {
        border: 1px solid rgb(var(--color-black) / 0.06) !important;
        padding: 9px;
        border-radius: 8px;
        margin: 0px !important;
    }

    .cs-message-input__content-editor {
        font-weight: 400;
        color: rgb(var(--color-gray-800)) !important ;
        background-color: white !important;
    }

    .cs-button--send {
        margin-left: 9px !important;
        background-color: rgb(var(--color-blu-800)) !important;
        padding: 7px 13px !important;
    }

    .cs-button--send svg {
        color: rgb(var(--color-white)) !important;
        width: 13px !important;
        height: 13px !important;
    }

    .cs-message__content {
        background-color: white !important;
        padding: 6px;
        border-radius: 9px;
        margin-bottom: 10px;
        border: 1px solid;
        border-color: rgb(var(--color-black) / 0.06) !important;
    }

    .cs-message__html-content {
        color: rgb(var(--color-gray-800)) !important;
        font-weight: 500;
        font-size: 13px;
    }

    .cs-typing-indicator__dot {
        background-color: rgb(var(--color-blu-800)) !important;
    }

    .cs-typing-indicator__text {
        font-size: 13px;
        color: rgb(var(--color-blu-800)) !important;
    }

    .cs-message-list__scroll-wrapper {
        padding: 13px;
    }
`;
