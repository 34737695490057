import { useLocale } from '@hooks/useLocale';
import useRelation from '@hooks/useRelation';
import { useRelationAceEditorCompleter } from '@hooks/useRelationAceEditorCompleter';
import {
    type CompiledDimension,
    type CompiledRelation,
    type CompiledRelationTable,
} from '@lightdash/common';
import {
    Accordion,
    ActionIcon,
    Box,
    Button,
    Flex,
    Text,
    TextInput,
} from '@mantine/core';
import { useAudienceContext } from '@providers/AudienceProvider';
import { useRelationContext } from '@providers/RelationProvider';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-mysql';
import 'ace-builds/src-noconflict/theme-xcode';
import Fuse from 'fuse.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import AceEditor from 'react-ace';
import {
    ChevronRight,
    RefreshCw,
    RotateCcw,
    RotateCw,
    Search,
} from 'react-feather';
import { useDebounce } from 'react-use';
import { ButtonVariant } from '../../mantineTheme';
type TableSearchType = {
    tableName: boolean;
    dimensionName: boolean;
};

type MatcherType = {
    [K: string]: TableSearchType;
};

interface SQLEditorProps {
    isEditMode?: boolean;
}
const SQLEditor = ({ isEditMode = true }: SQLEditorProps) => {
    const { sqlQuery } = useAudienceContext(
        (context) => context.state.audiencePayload,
    );
    const { setSqlQuery } = useAudienceContext((context) => context.actions);
    const { activeRelationUuid } = useRelationContext();
    const {
        data: activeRelation,
        refetch,
        isLoading,
        isRefetching,
    } = useRelation(activeRelationUuid);
    const [search, setSearch] = useState<string>('');
    const [searchDebounce, setSearchDebounce] = useState<string>('');
    const editorRef = useRef<InstanceType<typeof AceEditor> | null>(null);
    const [disableRedo, setDisableRedo] = useState<boolean>(false);
    const [disableUndo, setDisableUndo] = useState<boolean>(false);
    const [openAccordion, setOpenAccordion] = useState<string[]>([]);

    useDebounce(
        () => {
            setSearchDebounce(search);
        },
        300,
        [search],
    );
    const handleEditorAction = useCallback(() => {
        const editor = editorRef.current?.editor;
        if (!editor) return;

        const canUndo = editor.getSession().getUndoManager().canUndo();
        const canRedo = editor.getSession().getUndoManager().canRedo();
        setDisableRedo(!canRedo);
        setDisableUndo(!canUndo);
    }, []);

    const handleUndo = useCallback(() => {
        if (editorRef.current) {
            editorRef.current.editor.undo();
            handleEditorAction();
        }
    }, [handleEditorAction]);

    const handleRedo = useCallback(() => {
        if (editorRef.current) {
            editorRef.current.editor.redo();
            handleEditorAction();
        }
    }, [handleEditorAction]);

    const handleSQLChange = useCallback(
        (data: string) => {
            setSqlQuery(data ?? '');
            if (editorRef.current) handleEditorAction();
        },
        [handleEditorAction, setSqlQuery],
    );

    const getHighlightedText = useCallback(
        (text: string) => {
            const parts = text.split(new RegExp(`(${searchDebounce})`, 'gi'));
            return (
                <span>
                    {parts.map((part, i) => (
                        <span
                            key={i}
                            className={
                                part.toLowerCase() ===
                                searchDebounce.toLowerCase()
                                    ? 'bg-blu-800/15'
                                    : ''
                            }
                        >
                            {part}
                        </span>
                    ))}
                </span>
            );
        },
        [searchDebounce],
    );
    const searchFilter = useCallback(() => {
        const matcher: MatcherType = {};
        const accordionOpener: string[] = [];
        if (searchDebounce !== '' && activeRelation?.tables) {
            new Fuse(Object.values(activeRelation?.tables), {
                keys: ['name'],
                ignoreLocation: true,
                threshold: 0.3,
            })
                .search(searchDebounce)
                .forEach((res) => {
                    matcher[res.item.name] = {
                        tableName: true,
                        dimensionName: false,
                    };
                    accordionOpener.push(res.item.name);
                });
            Object.values(activeRelation?.tables).forEach(
                (pass: CompiledRelationTable) => {
                    new Fuse(Object.values(pass.dimensions), {
                        keys: ['name'],
                        ignoreLocation: true,
                        threshold: 0.3,
                    })
                        .search(searchDebounce)
                        .forEach(() => {
                            if (matcher[pass.name]) {
                                matcher[pass.name] = {
                                    tableName: true,
                                    dimensionName: true,
                                };
                            } else {
                                matcher[pass.name] = {
                                    tableName: false,
                                    dimensionName: true,
                                };
                                accordionOpener.push(pass.name);
                            }
                        });
                },
            );
        }
        return { matcher, accordionOpener };
    }, [searchDebounce, activeRelation]);

    useEffect(() => {
        const { accordionOpener } = searchFilter();
        setOpenAccordion(accordionOpener);
    }, [activeRelation, searchDebounce, searchFilter]);
    const handleSearchClear = useCallback(() => {
        setSearch('');
    }, []);
    const panelRefs = useRef<Array<HTMLDivElement | null>>([]);

    const doClick = useCallback(
        (index: number) => {
            const panelRef = panelRefs.current[index];

            if (panelRef) {
                setTimeout(() => {
                    panelRef.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }, 200);
            }
        },
        [panelRefs],
    );

    const items = (relationData: CompiledRelation) => {
        const { matcher } = searchFilter();

        return relationData?.tables
            ? Object.values(relationData.tables)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item: CompiledRelationTable, index) => {
                      const isSearchEmpty = searchDebounce === '';
                      const isTableNameMatch = matcher[item.name]?.tableName;
                      const isDimensionNameMatch =
                          matcher[item.name]?.dimensionName;

                      if (
                          isSearchEmpty ||
                          isTableNameMatch ||
                          isDimensionNameMatch
                      ) {
                          return (
                              <Accordion.Item
                                  key={item.name}
                                  value={item.name}
                                  ref={(ref) =>
                                      (panelRefs.current[index] = ref)
                                  }
                              >
                                  <Accordion.Control
                                      onClick={() => {
                                          doClick(index);
                                      }}
                                  >
                                      <Text // Call your custom click function
                                          className="text-sm lowercase truncate w-[180px] text-gray-800"
                                      >
                                          {isSearchEmpty
                                              ? item.name
                                              : getHighlightedText(item.name)}
                                      </Text>
                                  </Accordion.Control>
                                  <Accordion.Panel>
                                      {Object.values(item.dimensions)
                                          .sort((a, b) =>
                                              a.name.localeCompare(b.name),
                                          )
                                          .filter(
                                              (obj: CompiledDimension) =>
                                                  isSearchEmpty ||
                                                  (matcher[item.name]
                                                      ?.dimensionName &&
                                                      !matcher[item.name]
                                                          ?.tableName &&
                                                      obj.name
                                                          .trim()
                                                          .toLowerCase()
                                                          .includes(
                                                              searchDebounce
                                                                  .trim()
                                                                  .toLowerCase(),
                                                          )) ||
                                                  matcher[item.name].tableName,
                                          )
                                          .map((obj: CompiledDimension) => (
                                              <Box
                                                  key={obj.name}
                                                  className="flex justify-between my-1.5"
                                              >
                                                  <Text className="text-sm lowercase text-gray-800 truncate w-[130px]">
                                                      {getHighlightedText(
                                                          obj.name,
                                                      )}
                                                  </Text>
                                                  <Box className="px-1.5 bg-shade-4 text-gray-600 rounded text-xs font-normal break-normal">
                                                      {obj.type}
                                                  </Box>
                                              </Box>
                                          ))}
                                  </Accordion.Panel>
                              </Accordion.Item>
                          );
                      }
                      return null;
                  })
            : null;
    };

    const { t } = useLocale();
    const { setAceEditor } = useRelationAceEditorCompleter();

    return (
        <>
            <Flex direction={'column'} className={`border-inherit rounded-md `}>
                <Flex direction="row" className="flex ace-editor">
                    <Flex direction="column" className="w-[80%]">
                        <Flex className="p-2  border-b border-shade-6 border-solid justify-between ">
                            <Flex className="text-sm  text-gray-500 pt-1">
                                {t('audiences_sql.writing_sql')}
                            </Flex>
                            <Flex>
                                <ActionIcon
                                    variant={ButtonVariant.OUTLINED}
                                    aria-label="Settings"
                                    onClick={handleUndo}
                                    className="mx-1  border border-shade-6 rounded-3xl min-w-9"
                                    disabled={disableUndo}
                                >
                                    <RotateCcw
                                        size={13}
                                        strokeWidth={2.5}
                                        color={'rgb(var(--color-gray-600))'}
                                    />
                                </ActionIcon>
                                <ActionIcon
                                    aria-label="Settings"
                                    onClick={handleRedo}
                                    className="mx-1 border border-shade-6 rounded-3xl min-w-9"
                                    disabled={disableRedo}
                                >
                                    <RotateCw
                                        size={13}
                                        strokeWidth={2.5}
                                        color={'rgb(var(--color-gray-600))'}
                                    />
                                </ActionIcon>
                            </Flex>
                        </Flex>
                        <AceEditor
                            ref={editorRef}
                            width="100%"
                            height="100%"
                            placeholder={t('audience.builder.sql.placeholder')}
                            mode="sql"
                            theme="xcode"
                            name="Audience-builder-sql-editor"
                            fontSize={14}
                            showPrintMargin={false}
                            showGutter={true}
                            highlightActiveLine={true}
                            value={sqlQuery}
                            onLoad={setAceEditor}
                            onChange={handleSQLChange}
                            readOnly={!isEditMode}
                            wrapEnabled={true}
                            setOptions={{
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                                tabSize: 2,
                                indentedSoftWrap: true,
                                wrap: true,
                            }}
                        />
                    </Flex>
                    <Flex
                        direction="column"
                        className="border-s border-shade-6 border-solid w-[240px] h-[488px]"
                    >
                        <Flex
                            c="dimmed"
                            className="p-2 border-b border-shade-6 border-solid "
                            justify="space-between"
                        >
                            <Text>{t('audiences_sql.all_tables')}</Text>
                            <ActionIcon
                                onClick={() => {
                                    refetch()
                                        .then(() => {}) // TODO: Fix this, add a temp code to make lint work
                                        .catch(() => {});
                                }}
                            >
                                <RefreshCw
                                    size={13}
                                    strokeWidth={2.5}
                                    color={'rgb(var(--color-gray-600))'}
                                    className={
                                        isRefetching ? 'animate-spin' : ''
                                    }
                                />
                            </ActionIcon>
                        </Flex>
                        <div className="sticky top-0  w-full border-b border-shade-6 border-solid text-gray-800">
                            <TextInput
                                icon={
                                    <Search
                                        size={13}
                                        color={'rgb(var(--color-gray-500))'}
                                    />
                                }
                                sx={{
                                    input: {
                                        border: '0px',
                                        borderRadius: '0px',
                                    },
                                }}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                                placeholder={t(
                                    'create_campaign.channel_search',
                                )}
                                className="text-gray-800"
                            />
                        </div>
                        {searchDebounce && searchDebounce.length > 0 && (
                            <Box className="border-b border-shade-6 border-solid px-2 py-1.5 text-xs text-gray-600">
                                <Flex justify="space-between">
                                    <Text>
                                        {`${t(
                                            'audiences_sql.showing_results_text',
                                        )} '${searchDebounce}'`}
                                    </Text>
                                    <Button
                                        variant={ButtonVariant.UNSTYLED}
                                        className="!text-xs !text-blu-800"
                                        onClick={handleSearchClear}
                                    >
                                        {t(
                                            'audiences_sql.clear_for_all_tables_search',
                                        )}
                                    </Button>
                                </Flex>
                            </Box>
                        )}

                        <Accordion
                            chevronPosition="left"
                            multiple
                            value={openAccordion}
                            onChange={setOpenAccordion}
                            chevron={
                                <ChevronRight
                                    size={13}
                                    strokeWidth={2.5}
                                    color={'rgb(var(--color-gray-600))'}
                                />
                            }
                            styles={{
                                chevron: {
                                    '&[data-rotate]': {
                                        transform: 'rotate(90deg)',
                                    },
                                    margin: '0px !important',
                                },
                                label: {
                                    padding: '6px 0px',
                                    backgroundColor: 'white',
                                },
                                item: {
                                    backgroundColor: 'white',
                                },
                                panel: {
                                    backgroundColor: 'white',
                                },
                                control: {
                                    backgroundColor: 'white',
                                },
                            }}
                            variant="filled"
                            className="overflow-scroll"
                        >
                            {!(isLoading || isRefetching) &&
                                activeRelation &&
                                items(activeRelation)}
                        </Accordion>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};
export default SQLEditor;
