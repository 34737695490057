import { useActiveProjectUuid } from '@hooks/useActiveProject';
import { useLocale } from '@hooks/useLocale';
import { useProject } from '@hooks/useProject';
import { Timezones } from '@lightdash/common';
import { Group, Input, NumberInput } from '@mantine/core';
import moment from 'moment';
import React, { type FC } from 'react';
import {
    getMonthlyCronExpression,
    parseCronExpression,
} from './cronInputUtils';
import TimePicker from './TimePicker';

const MonthlyInputs: FC<{
    disabled?: boolean;
    cronExpression: string;
    onChange: (value: string) => void;
}> = ({ disabled, cronExpression, onChange }) => {
    const { minutes, hours, day } = parseCronExpression(cronExpression);

    const { activeProjectUuid } = useActiveProjectUuid();
    const { data: projectData } = useProject(activeProjectUuid);
    const projectTimezone = projectData?.timezone ?? Timezones.UTC;

    const offset = moment().tz(projectTimezone).format('Z');

    const { t } = useLocale();

    const onDayChange = (newDay: number) => {
        if (newDay >= 1 && newDay <= 31) {
            onChange(getMonthlyCronExpression(minutes, hours, newDay));
        }
    };

    const onTimeChange = (newTime: { minutes: number; hours: number }) => {
        onChange(getMonthlyCronExpression(newTime.minutes, newTime.hours, day));
    };

    return (
        <Group spacing="sm">
            <Input.Label className="text-gray-600 text-sm font-normal">
                {t('audience_scheduler.on_day_input')}
            </Input.Label>
            <NumberInput
                styles={() => ({
                    input: {
                        width: '6rem !important',
                        padding: '0 0.9rem',
                    },
                })}
                value={day}
                onChange={onDayChange}
                disabled={disabled}
                w="6xl"
                min={1}
                max={31}
            />
            <Input.Label className="text-gray-600 text-sm font-normal">
                {t('audience_scheduler.at')}
            </Input.Label>

            <TimePicker
                disabled={disabled}
                cronExpression={cronExpression}
                onChange={onTimeChange}
            />
            <Input.Label className="text-gray-600 text-sm font-normal">
                {`(GMT${offset})`}
            </Input.Label>
        </Group>
    );
};
export default MonthlyInputs;
