import { useLocale } from '@hooks/useLocale';
import React from 'react';
import {
    BaseEdge,
    EdgeLabelRenderer,
    getBezierPath,
    type EdgeProps,
} from 'reactflow';

const CustomEdge = ({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    label,
}: EdgeProps) => {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const { t } = useLocale();

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                    }}
                    className="text-xs bg-white p-1 text-gray-600 rounded-xl border border-solid border-black/0.04 w-54 absolute pointer-events-auto font-medium px-2 py-1"
                >
                    {t(`custom_edge.${label}`)}
                </div>
            </EdgeLabelRenderer>
        </>
    );
};

export default CustomEdge;
