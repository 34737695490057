import FiltersForm from '@components/Audience/Filters';
import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider';
import { useAudienceContext } from '@providers/AudienceProvider';
import { useProjectContext } from '@providers/ProjectProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { memo, type FC } from 'react';
import { useParams } from 'react-router-dom';
import { useFieldsWithEvents } from './useFieldsWithEvents';
import { useFieldsWithSuggestions } from './useFieldsWithSuggestions';

interface FiltersCardProps {
    index: number;
}

const FiltersCard: FC<FiltersCardProps> = memo(({ index }) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { activeRelation, activeRelationUuid } = useRelationContext();
    const { projectData } = useProjectContext();

    const isEditMode = useAudienceContext(
        (context) => context.state.isEditMode,
    );

    const filters = useAudienceContext(
        (context) =>
            context.state.unsavedChartVersion[index]?.metricQuery.filters,
    );
    const additionalMetrics = useAudienceContext(
        (context) =>
            context.state.unsavedChartVersion[index].metricQuery
                .additionalMetrics,
    );
    const customDimensions = useAudienceContext(
        (context) =>
            context.state.unsavedChartVersion[index].metricQuery
                .customDimensions,
    );
    const tableCalculations = useAudienceContext(
        (context) =>
            context.state.unsavedChartVersion[index].metricQuery
                .tableCalculations,
    );
    const queryResults = useAudienceContext(
        (context) => context.queryResults.data,
    );
    const setFilters = useAudienceContext(
        (context) => context.actions.setFilters,
    );

    const { data: eventsData, eventsTableNames } = useFieldsWithEvents({
        relationData: activeRelation,
        activeRelationUuid,
        projectUuid,
    });
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults,
        additionalMetrics,
        tableCalculations: tableCalculations,
        customDimensions: customDimensions,
    });
    return (
        <FiltersProvider
            projectUuid={projectUuid}
            fieldsMap={fieldsWithSuggestions}
            startOfWeek={
                projectData?.warehouseConnection?.startOfWeek ?? undefined
            }
            eventsMap={eventsData}
            eventTables={eventsTableNames}
        >
            <FiltersForm
                isEditMode={isEditMode}
                filters={filters}
                setFilters={setFilters}
                index={index}
            />
        </FiltersProvider>
    );
});

export default FiltersCard;
