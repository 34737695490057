import { type DimensionType } from '@lightdash/common';

export enum EventBuilderStep {
    PAYLOAD = 'payload',
    PROPERTIES = 'properties',
    KEYS = 'keys',
    REVIEW = 'review',
}
export interface EventBuilderStepMap {
    key: EventBuilderStep;
    label: string;
    component: React.ComponentType;
    pageHeader: string;
}
export interface EvenTableRequiredColumnsProps {
    value: string;
    label: string;
    description: string;
    type: string;
    placeholder: string;
}
export enum EventManagerType {
    VIEW = 'view',
    CLONE = 'clone',
}

export type PropertiesType = {
    columnName: string;
    key: string;
    label: string;
    required: boolean;
    nestedLevel: number;
    reservedKey: boolean;
    type: string;
    selectedProperty: boolean;
};
export type SourceKeysProps = {
    sourcekey: string;
    keyType: string;
    label: string;
};
export type EventLabelDataTypeOptionsProps = {
    type: DimensionType | string;
    icon: React.ReactNode;
    label: string;
};
