import Profile from '@components/AccountSettings/ProfilePanel';
import { t as translate } from 'i18next';
import { User } from 'react-feather';

export interface SettingListItemType {
    label: string;
    redirectTo: string;
    routeMatch: string;
    children: React.ReactNode;
    leftSection?: React.ReactNode;
}

const baseSettingsReditectPath = `/account`;

export const settingsListGroups = (): SettingListItemType[][] => {
    return [
        [
            {
                label: translate('account_settings.menu_item_profile'),
                redirectTo: `${baseSettingsReditectPath}/profile`,
                routeMatch: `${baseSettingsReditectPath}/profile`,
                children: <Profile />,
                leftSection: <User size={13} />,
            },
        ],
    ];
};
