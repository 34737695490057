import { useLocale } from '@hooks/useLocale';
import { Box, Group, Text, Tooltip } from '@mantine/core';
import { NewspaperClipping } from '@phosphor-icons/react';
import React from 'react';
import FlashSMSPreview from './FlashSMSPreview';
import NormalSMSPreview from './NormalSMSPreview';

interface SMSPreviewBaseProps {
    content: string;
    isFlash: boolean;
}

interface SMSPreviewWithModifyProps extends SMSPreviewBaseProps {
    allowModify: true;
    onModify: () => void;
    templateName: string;
}

interface SMSPreviewWithoutModifyProps extends SMSPreviewBaseProps {
    allowModify?: false;
}

type SMSPreviewProps = SMSPreviewWithModifyProps | SMSPreviewWithoutModifyProps;

const SMSPreview: React.FC<SMSPreviewProps> = (props) => {
    const { t } = useLocale();

    return (
        <Box className="w-[19rem] border-base rounded-xl">
            <Group
                className="p-3 font-medium text-gray-800 border-b"
                position="apart"
                noWrap
            >
                <Text>{t('sms_template_preview.preview_container_title')}</Text>
                {props.allowModify && (
                    <Group spacing={6}>
                        <NewspaperClipping />
                        <Tooltip label={props.templateName}>
                            <Text className="text-sm text-gray-600 truncate max-w-32">
                                {props.templateName}
                            </Text>
                        </Tooltip>

                        <Text
                            className="font-medium cursor-pointer text-blu-800"
                            onClick={props.onModify}
                        >
                            Modify
                        </Text>
                    </Group>
                )}
            </Group>
            <Box className="min-h-[31rem] max-h-[41rem] rounded-b-[inherit]">
                {props.isFlash ? (
                    <FlashSMSPreview content={props.content} />
                ) : (
                    <NormalSMSPreview content={props.content} />
                )}
            </Box>
        </Box>
    );
};

export default React.memo(SMSPreview);
