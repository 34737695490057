import SettingsTitle from '@components/common/Settings/SettingsTitle';
import InviteOrganizationMemberModal from '@components/OrganizationSettings/InviteOrganizationMemberModal';
import OrganizationMembersTable from '@components/OrganizationSettings/MembersPanel/OrganizationMembersTable';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PlusCircle } from '@phosphor-icons/react';
import React from 'react';

const OrganizationMembers: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const [opened, { toggle, close }] = useDisclosure(false);

    return (
        <>
            <Box>
                <Flex className="items-center justify-between w-full pt-3">
                    <SettingsTitle
                        title={t('organization_settings.members_title')}
                    />
                    <Button
                        leftIcon={<PlusCircle color="white" />}
                        onClick={toggle}
                    >
                        {t('organization_settings.invite_member_btn')}
                    </Button>
                </Flex>

                <Box className="my-6">
                    <OrganizationMembersTable />
                </Box>
            </Box>

            <InviteOrganizationMemberModal
                onClose={() => close()}
                opened={opened}
            />
        </>
    );
};

export default React.memo(OrganizationMembers);
