import FieldIcon from '@components/common/IconPack/FieldIcon';
import PropertySelect from '@components/common/Select/PropertySelect';
import { useLocale } from '@hooks/useLocale';
import { type JourneyDataSchema } from '@lightdash/common';
import { Box, Flex, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretRight } from '@phosphor-icons/react';
import React from 'react';
import {
    useJourneyProperties,
    type JourneyProperty,
} from './useJourneyProperties';

interface JourneyPropertySelectorFieldItemProps {
    item: JourneyProperty;
    rightHoverSection?: React.ReactNode;
}

export const JourneyPropertySelectorFieldItem: React.FC<
    JourneyPropertySelectorFieldItemProps
> = ({ item, rightHoverSection }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                '&:hover .icon': {
                    opacity: 1,
                },
            }}
            className="w-full p-2.5 rounded cursor-pointer hover:bg-gray-50"
        >
            <Group className="gap-1">
                <FieldIcon type={item?.type} strokeWidth={2.5} />
                <Box className={`max-w-[120px] truncate`}>
                    {item?.label && (
                        <Text className="text-sm font-medium text-gray-800">
                            {item?.label}
                        </Text>
                    )}
                </Box>
            </Group>

            <Box
                className="icon"
                sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    opacity: 0,
                    transition: 'opacity 0.1s ease-in-out',
                }}
            >
                <Flex align="center" gap={4}>
                    {rightHoverSection}
                    <CaretRight weight="bold" />
                </Flex>
            </Box>
        </Box>
    );
};

interface JourneyPropertySelectorProps {
    targetElement: React.ReactNode;
    journeyDataSchema: JourneyDataSchema;
    onSubmit: (item: JourneyProperty) => void;
}

const JourneyPropertySelector: React.FC<JourneyPropertySelectorProps> = ({
    targetElement,
    onSubmit,
    journeyDataSchema,
}) => {
    const [opened, { open, close }] = useDisclosure();
    const { t } = useLocale();
    const journeyProperties = useJourneyProperties(journeyDataSchema);

    return (
        <>
            <PropertySelect<JourneyProperty>
                items={journeyProperties}
                showGroup={true}
                headerRightSection={null}
                onSubmit={(field: JourneyProperty[]) => {
                    if (!field[0]) return;
                    onSubmit(field[0]);
                    close();
                }}
                itemTemplate={({ item }) => {
                    return <JourneyPropertySelectorFieldItem item={item} />;
                }}
                opened={opened}
                close={close}
                open={open}
                withinPortal={true}
                targetButton={targetElement}
                showSearch={true}
                searchKeys={['label']}
                searchPlaceholder={t('audience_filters.search_filter_label')}
                allowMultipleSelection={false}
                showAllItemsGroup={false}
            />
        </>
    );
};

export default JourneyPropertySelector;
