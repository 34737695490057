import { OverflownText } from '@components/Audience/Filters/FieldListItem';
import FieldIcon from '@components/common/IconPack/FieldIcon';
import FilterSelectWrapper from '@components/Journeys/Builder/JourneyFilters/FilterSelectWrapper';
import { DimensionType } from '@lightdash/common';
import { Box, Group } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import React from 'react';
import { type ActionEventField } from './types';

/**
 * Props for the JourneyActiveEventField component
 */
interface JourneyActiveFieldProps {
    activeEventField: ActionEventField | undefined;
    isEditMode: boolean;
}

const JourneyActiveEventField: React.FC<JourneyActiveFieldProps> = ({
    activeEventField,
    isEditMode,
}) => {
    if (!activeEventField) return null;

    return (
        <FilterSelectWrapper isEditMode={isEditMode}>
            <Group className="gap-1">
                <Box className="px-2 !p-0">
                    <div className="flex items-center">
                        <div className="flex items-center justify-center gap-10 rounded-md ">
                            {activeEventField.eventSourceIcon}
                        </div>
                        <span
                            className={`text-sm pl-1.5 font-medium max-w-[120px] truncate`}
                        >
                            {activeEventField.eventSourceLabel}
                        </span>
                    </div>
                </Box>
                <CaretRight />

                <FieldIcon type={DimensionType.EVENT} strokeWidth={2.5} />
                <Box className={`max-w-[120px] truncate`}>
                    {activeEventField.eventLabel && (
                        <OverflownText
                            text={activeEventField.eventLabel}
                            className="!m-0 py-2 rounded text-sm text-gray-800 font-medium"
                        />
                    )}
                </Box>
            </Group>
        </FilterSelectWrapper>
    );
};

export default JourneyActiveEventField;
