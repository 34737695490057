import InputErrorText from '@components/common/InputErrorText';
import {
    Stack,
    Text,
    TextInput as MantineTextInput,
    type TextInputProps as MantineInputProps,
} from '@mantine/core';
import React from 'react';

interface TextInputProps extends MantineInputProps {
    error?: React.ReactNode;
    description?: React.ReactNode;
    errorClass?: string;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
    ({ error, description, errorClass, ...rest }, ref) => {
        return (
            <Stack className="gap-1.5">
                <MantineTextInput {...rest} ref={ref} />
                {error && (
                    <InputErrorText className={errorClass} value={error} />
                )}
                {description && (
                    <Text className="text-sm font-medium text-gray-500">
                        {description}
                    </Text>
                )}
            </Stack>
        );
    },
);

export default TextInput;
