import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type BaseProject,
    type CreateProjectRequest,
} from '@lightdash/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

const createProjectQuery = async (data: CreateProjectRequest) =>
    sortmentApi<BaseProject>({
        url: `/projects`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useProjectCreationMutation = () => {
    const { t } = useLocale();
    const { showToastSuccess, showToastError } = useNotify();
    const queryClient = useQueryClient();
    return useMutation<BaseProject, ApiError, CreateProjectRequest>(
        createProjectQuery,
        {
            mutationKey: [QueryKeys.PROJECT_CREATE],
            onSuccess: async () => {
                showToastSuccess({
                    title: t(`project_creation.success_toast_message`),
                });
                await queryClient.invalidateQueries([QueryKeys.ORGANIZATION]);
            },
            onError: (error) => {
                showToastError({
                    title: t(`project_creation.failure_toast_message`),
                    subtitle: error.error.message,
                });
            },
        },
    );
};
