import { useLocale } from '@hooks/useLocale';
import { Button, Group, Text } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import React from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import Modal from './Modal';

interface UnsavedChangesConfirmModalProps {
    opened: boolean;
    close: () => void;
    primaryActionButtonClick: () => void;
    secondaryActionButtonClick: () => void;
}

const UnsavedChangesConfirmModal: React.FC<UnsavedChangesConfirmModalProps> = ({
    opened,
    close,
    primaryActionButtonClick,
    secondaryActionButtonClick,
}) => {
    const { t } = useLocale();
    return (
        <Modal
            opened={opened}
            onClose={close}
            title={t('audience_creation_exit_confirmation_modal_title')}
            withCloseButton={false}
            closeOnClickOutside={false}
            footerRightSection={
                <Group spacing="xs">
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        onClick={secondaryActionButtonClick}
                    >
                        {t('audience_create_confirm_exit_no_button')}
                    </Button>

                    <Button
                        rightIcon={<CaretRight color="white" />}
                        onClick={() => {
                            primaryActionButtonClick();
                            close();
                        }}
                    >
                        {t('audience_create_confirm_exit_button')}
                    </Button>
                </Group>
            }
        >
            <Text className="pt-3">
                {t('audience_creation_exit_confirmation_modal_description')}
            </Text>
        </Modal>
    );
};
export default React.memo(UnsavedChangesConfirmModal);
