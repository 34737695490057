import {
    type CommunicationChannel,
    type Name,
    type Primitive,
    type TestEmailDestination,
} from './communications';

export enum TemplateSource {
    SORTMENT = 'SORTMENT',
    SENDGRID = 'SENDGRID',
    FACEBOOK = 'FACEBOOK',
}

export type Template = {
    id: string;
    organizationId: string;
    projectId: string;
    name: string;
    description: string;
    clonedFromId: string | null;
    createdBy: string;
    createdAt: Date;
    updatedBy: string;
    updatedAt: Date;
    contents: TemplateContent[];
    channel: CommunicationChannel;
    imported: boolean;
    source: TemplateSource;
    consideredInLibrary: boolean;
    metadata: {
        [key: string]: string;
    };
    tags: string[] | undefined;
};

export type TemplateContent = {
    templateId: string;
    version: number;
    versionName: string | null;
    content: TemplateContentDetails;
    variables: TemplateVariableDetails;
    parsedVariables: ParsedVariable[] | null;
    previewUrl: string | null;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
};

export type ParsedVariable = {
    key: string;
    type: string;
    value: string;
};

export type TemplateContentDetails =
    | EmailTemplateContentDetails
    | SmsTemplateContentDetails
    | WhatsAppTemplateContentDetails;

export type EmailTemplateContentDetails = {
    subject: string;
    html: string;
};

export type TemplateTagsUpdateRequest = {
    tags: string[];
};

export type SmsTemplateContentDetails = {
    body: string;
    dltTemplateId?: string;
    unicode?: boolean;
    flash?: boolean;
};

export type WhatsAppTemplateContentDetails = {
    header: string | undefined;
    body: string;
    footer: string | undefined;
    language?: string;
};

export type ReducedTemplate = Pick<
    Template,
    'id' | 'name' | 'description' | 'createdAt' | 'updatedAt' | 'channel'
> & {
    content: TemplateContentDetails;
    createdBy: Name;
    updatedBy: Name;
    previewUrl?: string;
};

export type Pagination = {
    total?: number;
    lastPage?: number;
    currentPage: number;
    perPage: number;
    from: number;
    to: number;
};

export type TemplateList = {
    data: ReducedTemplate[] | null;
};

export type TemplateListWithPagination = TemplateList & {
    pagination: Pagination;
};

export type CreateTemplateRequest = Required<
    Pick<Template, 'name' | 'description' | 'channel' | 'consideredInLibrary'> &
        Pick<TemplateContent, 'content' | 'previewUrl'>
>;

export type UpdateTemplateRequest = Partial<
    Pick<Template, 'name' | 'description'> & Pick<TemplateContent, 'content'>
>;

export type Paginate = {
    perPage: number;
    currentPage: number;
};

export type SearchTemplateFromIntegrationRequest = {
    providerId: string;
    integrationId: string;
    templateIds: string[];
};

export type ImportTemplateFromIntegrationRequest =
    SearchTemplateFromIntegrationRequest & {
        addToLibrary: boolean;
    };

export type Sorting = {
    field: string;
    direction: 'asc' | 'desc';
};

export type TemplateVariableDetails = {
    headerVariables: string[] | undefined;
    bodyVariables: string[] | undefined;
    footerVariables: string[] | undefined;
};

export type ProviderTemplateDetails = {
    id: string;
    name: string;
    description: string | undefined;
    updatedAt?: string;
};

export type SimpleTemplateDetails = Pick<Template, 'name'>;

export type TestEmailRequest = {
    to: TestEmailDestination;
    channel: CommunicationChannel;
    templateContent: TemplateContentDetails;
    templateDetails: SimpleTemplateDetails;
    mappings: {
        [key: string]: Primitive;
    };
};

export type GetAllProviderTemplateRequest = {
    templateIds: string[];
};

export type GetAllProviderTemplateResponse = {
    templates: ProviderTemplateDetails[];
};

export type FileUploadResponse = {
    url: string;
};

export enum TemplateVariables {
    UNSUBSCRIBE = 'sortment_unsub_url',
}
