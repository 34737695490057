import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import TimezoneSelect from '@components/common/Select/TimezoneSelect';
import { useIsEqual } from '@hooks/useIsEqual';
import { useLocale } from '@hooks/useLocale';
import { useUpdateSettingsMutation } from '@hooks/useProject';
import { ProjectSettings, type UpdateProjectSettings } from '@lightdash/common';
import { Stack, TextInput } from '@mantine/core';
import { useApp } from '@providers/AppProvider';
import { useProjectContext } from '@providers/ProjectProvider';
import React, { useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import UnsavedChangesModal from '../common/modal/UnsavedChangesModal';

const WorkspaceSettingsForm: React.FC<{}> = ({}) => {
    const { projectData } = useProjectContext();
    const { projectUuid: projectId } = useParams<{ projectUuid: string }>();
    const updateMutation = useUpdateSettingsMutation(projectId);
    const { isLoading: isSaving, mutateAsync } = updateMutation;
    const { user } = useApp();
    const { t } = useLocale();
    const ability = useAbilityContext();
    const canEditProjectNameAndTimezone = ability.can(
        'update',
        subject(ProjectSettings.overview, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid: projectId,
        }),
    );
    const defaultValues: UpdateProjectSettings = useMemo(() => {
        return {
            name: projectData?.name ?? '',
            timezone: projectData?.timezone ?? null,
        };
    }, [projectData]);

    const form = useForm<UpdateProjectSettings>({
        defaultValues,
    });

    const { control, handleSubmit, register, reset } = form;
    const watchFields = useWatch<UpdateProjectSettings>({ control });
    const hasFormChanged = useIsEqual<UpdateProjectSettings>(
        defaultValues,
        watchFields,
    );

    const submitForm = async (formData: UpdateProjectSettings) => {
        const { name, timezone } = formData;
        await mutateAsync({ name, timezone });
    };

    return (
        <>
            <form
                name="update_workspace_name"
                id="update_workspace_name"
                onSubmit={(_value) => {
                    void handleSubmit(submitForm)();
                }}
            >
                <Stack>
                    <TextInput
                        id="workspace-name-input"
                        placeholder={t(
                            'workspace_settings_form.workspace_name_placeholder',
                        )}
                        label={t(
                            'workspace_settings_form.workspace_name_label',
                        )}
                        type="text"
                        required
                        disabled={isSaving || !canEditProjectNameAndTimezone}
                        data-cy="workspace-name-input"
                        {...register('name')}
                        className="w-72"
                    />
                    <Controller
                        name="timezone"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TimezoneSelect
                                id="workspace-timezone-select"
                                label={t(
                                    'workspace_settings_form.timezone_label',
                                )}
                                placeholder={t(
                                    'workspace_settings_form.timezone_placeholder',
                                )}
                                data={[]}
                                value={value || ''}
                                onChange={(zone) => onChange(zone)}
                                searchable
                                disabled={
                                    isSaving || !canEditProjectNameAndTimezone
                                }
                                maxDropdownHeight={300}
                                className="!w-72"
                                styles={() => ({
                                    dropdown: {
                                        width: '350px !important',
                                    },
                                })}
                            />
                        )}
                    />
                </Stack>
            </form>

            <UnsavedChangesModal
                opened={hasFormChanged}
                secondaryActionButtonClick={() => reset()}
                disableButtons={isSaving}
                form="update_workspace_name"
                type="submit"
            />
        </>
    );
};

export default WorkspaceSettingsForm;
