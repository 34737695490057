import { JourneyNodeEnum } from '@components/Journeys/Builder/types';
import { ActionType, JourneyGroup, type JourneyNode } from '@lightdash/common';
import { Group, Text } from '@mantine/core';
import { CursorClick } from '@phosphor-icons/react';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import React from 'react';
import {
    extractNodeValue,
    getBlockIcon,
    hasEntryLogicError,
    hasNodeError,
    hasTriggerNodeError,
} from '../../utils';

interface NodeData {
    journeyNodeData: JourneyNode | null;
    blockGroup: JourneyGroup | null;
    isLeafNode: boolean;
    blockType: JourneyNodeEnum | null;
    icon: React.ReactNode;
    nodeValue: React.ReactNode | null;
    hasError: boolean;
    subTitle: string | null;
}

/**
 * Custom hook to get node data for a given nodeId.
 *
 * @param {string} nodeId - The ID of the node to retrieve data for.
 * @returns {NodeData} - The data associated with the node.
 */
export const useNodeData = (nodeId: string): NodeData => {
    const { edges, journeyPayload, blocksList, nodes, journeyEvents } =
        useJourneyBuilderContext((context) => context.state);

    const node = nodes.find((n) => n.id === nodeId);
    const blockType = node?.data.type as JourneyNodeEnum | null;

    let journeyNodeData: JourneyNode | null = null;
    let blockGroup: JourneyGroup | null = null;
    let nodeValue: React.ReactNode | null = null;
    let hasError: boolean = false;
    let subTitle: string | null = null;

    if (blockType === JourneyNodeEnum.TRIGGER) {
        const triggerBlock = journeyPayload.triggers?.entry[0];
        const event = journeyEvents?.find(
            (e) => e.eventName === triggerBlock?.eventName,
        );
        if (triggerBlock) {
            journeyNodeData = {
                id: triggerBlock.metadata?.id ?? '',
                title: triggerBlock.metadata?.title ?? 'Trigger',
                description: triggerBlock.metadata?.description ?? '',
                metadata: {
                    blockId: triggerBlock.metadata?.metadata?.blockId ?? '',
                },
                actions: [],
            };
            blockGroup = JourneyGroup.TRIGGER;
            hasError =
                hasTriggerNodeError(triggerBlock) ||
                hasEntryLogicError(journeyPayload.entryLogic!);
            nodeValue = (
                <Group className="gap-1">
                    <CursorClick size={14} />
                    <Text>{event?.label ?? triggerBlock.eventName}</Text>
                </Group>
            );
        }
    } else if (journeyPayload.config) {
        const { nodes: journeyNodes } = journeyPayload.config;
        journeyNodeData = journeyNodes.find((n) => n.id === nodeId) ?? null;
        if (journeyNodeData) {
            const block = blocksList.find(
                (b) => b.id === journeyNodeData?.metadata?.blockId,
            );
            blockGroup = block?.group ?? null;
            nodeValue = extractNodeValue(journeyNodeData.actions);
        }
        hasError =
            (journeyNodeData && hasNodeError(journeyNodeData.actions)) ?? false;
    }

    const isLeafNode = !edges.some((edge) => edge.source === nodeId);

    const icon = getBlockIcon({
        type: blockType ?? JourneyNodeEnum.BLOCK,
        actions: journeyNodeData?.actions.map((action) => action.type) ?? [],
        group: blockGroup ?? JourneyGroup.DELAY,
    });
    if (journeyNodeData?.actions[0]?.type === ActionType.API) {
        subTitle =
            journeyNodeData?.actions[0].config.apiConfig.httpConfig.method;
    }
    return {
        journeyNodeData,
        blockGroup,
        isLeafNode,
        blockType,
        icon,
        nodeValue,
        hasError,
        subTitle,
    };
};
