import { Box, Input } from '@mantine/core';
import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Edit2 } from 'react-feather';
import { buttonInputStyles } from './ButtonInput.styles';
import { type ButtonInputProps } from './ButtonInput.types';

const ButtonInput: React.FC<ButtonInputProps> = ({
    placeholder,
    leftSection,
    rightSection: initialRightSection,
    defaultValue,
    isDisabled = false,
    isRequired,
    fontSize,
    fontWeight,
    placeholderColor = 'rgb(var(--color-gray-400))',
    textColor = 'rgb(var(--color-gray-800))',
    onBlur,
    onChange,
    autoFocus = false,
    ...rest
}) => {
    const [value, setValue] = useState<string | number | undefined>(
        defaultValue,
    );

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const ref = useRef<HTMLInputElement>(null);

    useLayoutEffect(() => {
        if (!ref.current) return;
        const inputValue = `${defaultValue}`;
        const size = inputValue ? inputValue.length : placeholder.length;
        ref.current.setAttribute('size', size.toString());
    }, [placeholder, defaultValue]);

    const iconClass = useMemo(() => {
        return `buttonInput__icon${
            isHovered ? ' buttonInput__icon--input-hovered' : ''
        }${isFocused ? ' buttonInput__icon--input-focused' : ''}`;
    }, [isHovered, isFocused]);

    const styles = useMemo(
        () =>
            buttonInputStyles({
                fontSize,
                fontWeight,
                placeholderColor,
                textColor,
            }),
        [fontSize, fontWeight, placeholderColor, textColor],
    );

    const rightSection = useMemo(
        () =>
            initialRightSection !== null ? (
                <Edit2 size={13} strokeWidth={2.5} className={iconClass} />
            ) : null,
        [initialRightSection, iconClass],
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value);
            if (onChange) {
                onChange(event.target.value);
            }
        },
        [onChange],
    );

    const handleBlur = useCallback(() => {
        setIsFocused(false);
        if (onBlur) {
            onBlur(value?.toString() ?? '');
        }
    }, [onBlur, value]);

    const handleFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    return (
        <Box
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            component="div"
        >
            <Input
                onFocus={handleFocus}
                styles={styles}
                icon={leftSection}
                rightSection={rightSection}
                disabled={isDisabled}
                placeholder={placeholder}
                required={isRequired}
                value={value}
                ref={ref}
                onChange={handleChange}
                onBlur={handleBlur}
                rightSectionProps={{
                    style: {
                        cursor: 'pointer',
                        pointerEvents: 'none',
                    },
                }}
                autoFocus={autoFocus}
                sx={{
                    input: {
                        '&:focus-within': {
                            background: 'rgb(var(--color-blu-800)/0.04)',
                        },
                    },
                }}
                {...rest}
            />
        </Box>
    );
};

export default React.memo(ButtonInput);
