import { sortmentApi } from '@api/index';
import useQueryError from '@hooks/useQueryError';
import {
    type ApiError,
    type ApiJobStartedResults,
    type MostPopularAndRecentlyUpdated,
    type Project,
    type UpdateProject,
    type UpdateProjectSettings,
} from '@lightdash/common';
import { useActiveJob } from '@providers/ActiveJobProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

// const createProject = async (data: CreateProject) =>
//     sortmentApi<ApiJobStartedResults>({
//         url: `/org/projects/precompiled`,
//         method: 'POST',
//         body: JSON.stringify(data),
//     });

const updateProject = async (id: string, data: UpdateProject) =>
    sortmentApi<ApiJobStartedResults>({
        url: `/projects/${id}`,
        method: 'PATCH',
        body: JSON.stringify(data),
    });

const updateProjectSettings = async (id: string, data: UpdateProjectSettings) =>
    sortmentApi<ApiJobStartedResults>({
        url: `/projects/${id}/settings`,
        method: 'PATCH',
        body: JSON.stringify(data),
    });

const getProject = async (id: string) =>
    sortmentApi<Project>({
        url: `/projects/${id}`,
        method: 'GET',
        body: undefined,
    });

export const useProject = (id: string | undefined) => {
    const setErrorResponse = useQueryError();
    return useQuery<Project, ApiError>({
        queryKey: [QueryKeys.PROJECT, id],
        queryFn: () => getProject(id || ''),
        enabled: id !== undefined,
        retry: false,
        onError: (result) => setErrorResponse(result),
    });
};

export const useUpdateSettingsMutation = (id: string) => {
    const queryClient = useQueryClient();
    const { showToastError, showToastSuccess } = useNotify();
    const { t } = useLocale();
    return useMutation<ApiJobStartedResults, ApiError, UpdateProjectSettings>(
        (data) => updateProjectSettings(id, data),
        {
            mutationKey: [QueryKeys.PROJECT_UPDATE, id],
            onSuccess: async () => {
                await queryClient.invalidateQueries(['projects']);
                await queryClient.invalidateQueries(['project', id]);
                showToastSuccess({
                    title: t('workspace_settings_form.update_success_msg'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('workspace_settings_form.update_error_msg'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

export const useUpdateMutation = (id: string) => {
    const queryClient = useQueryClient();
    const { setActiveJobId } = useActiveJob();
    const { showToastApiError } = useNotify();
    return useMutation<ApiJobStartedResults, ApiError, UpdateProject>(
        (data) => updateProject(id, data),
        {
            mutationKey: ['project_update', id],
            onSuccess: async (data) => {
                setActiveJobId(data.jobUuid);

                await queryClient.invalidateQueries(['projects']);
                await queryClient.invalidateQueries(['tables']);
                await queryClient.invalidateQueries(['queryResults']);
                await queryClient.invalidateQueries(['status']);
            },
            onError: ({ error }) => {
                showToastApiError({
                    title: `Failed to update project`,
                    apiError: error,
                });
            },
        },
    );
};

// export const useCreateMutation = () => {
//     const { setActiveJobId } = useActiveJob();
//     const { showToastError } =useNotify();
//     return useMutation<ApiJobStartedResults, ApiError, CreateProject>(
//         (data) => createProject(data),
//         {
//             mutationKey: [QueryKeys.PROJECT_CREATE],
//             retry: 3,
//             onSuccess: (data) => {
//                 setActiveJobId(data.jobUuid);
//             },
//             onError: (error) => {
//                 showToastError({
//                     title: `Failed to create project`,
//                     subtitle: error.error.message,
//                 });
//             },
//         },
//     );
// };

const getMostPopularAndRecentlyUpdated = async (projectUuid: string) =>
    sortmentApi<MostPopularAndRecentlyUpdated>({
        url: `/projects/${projectUuid}/most-popular-and-recently-updated`,
        method: 'GET',
        body: undefined,
    });

export const useMostPopularAndRecentlyUpdated = (projectUuid: string) =>
    useQuery<MostPopularAndRecentlyUpdated, ApiError>({
        queryKey: ['most-popular-and-recently-updated', projectUuid],
        queryFn: () => getMostPopularAndRecentlyUpdated(projectUuid || ''),
    });
