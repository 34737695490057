import StepTitle from '@components/Campaigns/Builder/Steps/StepTitle';
import { CampaignBuilderStep } from '@components/Campaigns/Builder/types';
import { CampaignBuilderSteps } from '@components/Campaigns/Builder/utils';
import { useAudiences } from '@hooks/useGetAudience';
import {
    AudienceRunStatus,
    AudienceStatus,
    AudienceType,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import { useCampaignContext } from '@providers/CampaignProvider';
import React, { useCallback, useMemo } from 'react';
import SelectCampaignAudience from './SelectCampaignAudience';
import SelectedCampaignAudience from './SelectedCampaignAudience';

const CampaignBuilderAudience: React.FC<{}> = ({}) => {
    const { campaignPayload } = useCampaignContext((context) => context.state);

    const {
        data,
        refetch: refetchAudiences,
        isLoading,
        isFetching,
        isInitialLoading,
    } = useAudiences({
        query: `status=${AudienceStatus.ACTIVE}&lastRunStatus=${AudienceRunStatus.SUCCESS}`,
        perPage: 9999,
        currentPage: 1,
        polling: false,
    });

    const title = CampaignBuilderSteps.find(
        (step) => step.key === CampaignBuilderStep.AUDIENCE,
    )?.pageHeader;

    const handleRefresh = useCallback(() => {
        void refetchAudiences();
    }, [refetchAudiences]);

    const renderAudienceContent = useMemo(() => {
        if (
            campaignPayload.audienceType === AudienceType.WAREHOUSE &&
            campaignPayload.audienceId
        ) {
            return (
                <SelectedCampaignAudience
                    audiences={data?.data}
                    isLoading={isLoading || isInitialLoading}
                />
            );
        }
        if (
            campaignPayload.audienceType === AudienceType.CSV &&
            campaignPayload.csvUploadDetails?.fileName
        ) {
            return (
                <SelectedCampaignAudience
                    audiences={data?.data}
                    isLoading={isLoading || isInitialLoading}
                />
            );
        }
        return (
            <SelectCampaignAudience
                audiences={data?.data}
                isLoading={isLoading}
                isFetching={isFetching}
                onRefresh={handleRefresh}
            />
        );
    }, [
        campaignPayload,
        data,
        handleRefresh,
        isFetching,
        isInitialLoading,
        isLoading,
    ]);

    return (
        <Box>
            <StepTitle title={title || ''} />
            <Box className="mt-6 max-w-[800px] w-[600px]">
                {renderAudienceContent}
            </Box>
        </Box>
    );
};

export default CampaignBuilderAudience;
