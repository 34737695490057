import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import {
    CampaignContentStep,
    type TemplateSetupCompleteArgs,
} from '@components/Campaigns/Builder/types';
import Stepper from '@components/common/Stepper';
import { type Template } from '@lightdash/common';
import { Box, Divider, Stack } from '@mantine/core';
import { useCampaignContext } from '@providers/CampaignProvider';
import { isEqual } from 'lodash';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { getChannelBasedContentSteps } from './../../../utils';

interface SetupContentProps {
    templateMetadata: Template | undefined;
    onTemplateSetupComplete:
        | ((args: TemplateSetupCompleteArgs) => void)
        | undefined;
    fields: FieldsWithSuggestions | undefined;
}

const SetupContent: React.FC<SetupContentProps> = ({
    templateMetadata,
    onTemplateSetupComplete,
    fields,
}) => {
    const oldTemplateMetadata = useRef<Template | null>(null);
    const { state, actions } = useCampaignContext((context) => context);
    const { setPreviousStepCallback, setCurrentStepCallback } = actions;
    const { isValidStep, campaignPayload } = state;

    const campaignContentSteps = useMemo(() => {
        if (!templateMetadata?.channel) return [];
        return getChannelBasedContentSteps(
            templateMetadata?.channel,
            templateMetadata.contents[0].parsedVariables,
        );
    }, [templateMetadata]);

    const [activeContentStep, setActiveContentStep] =
        useState<CampaignContentStep>(campaignContentSteps[0].key);

    const setActiveContentStepFn = useCallback(
        (step: CampaignContentStep) => {
            const currentStepIndex = campaignContentSteps.findIndex(
                (indexStep) => indexStep.key === step,
            );
            const isLastStep =
                currentStepIndex === campaignContentSteps.length - 1;
            setCurrentStepCallback({
                callback:
                    isLastStep && onTemplateSetupComplete
                        ? () =>
                              onTemplateSetupComplete({
                                  template: campaignPayload.templateDetails,
                                  communicationDetails:
                                      campaignPayload.communicationDetails,
                              })
                        : () => {},
                skipExecutionAfterCallback: false,
            });
            setPreviousStepCallback({
                callback: () => {},
                skipExecutionAfterCallback: false,
            });
            setActiveContentStep(step);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [campaignPayload],
    );

    const currentStepIndex = useMemo(
        () =>
            campaignContentSteps.findIndex(
                (step) => step.key === activeContentStep,
            ),
        [activeContentStep, campaignContentSteps],
    );

    // INFO - This is to handle the scenario where user modifies the template at any step of the content
    // and adds any new links or variables
    useEffect(() => {
        if (oldTemplateMetadata.current === null && templateMetadata) {
            oldTemplateMetadata.current = templateMetadata;
        } else if (templateMetadata && oldTemplateMetadata.current) {
            if (
                !isEqual(
                    oldTemplateMetadata.current.contents[0],
                    templateMetadata.contents[0],
                )
            ) {
                setActiveContentStep(CampaignContentStep.SETUP);
                oldTemplateMetadata.current = templateMetadata;
            }
        }
    }, [templateMetadata]);

    useEffect(() => {
        if (currentStepIndex === -1) {
            setActiveContentStep(CampaignContentStep.SETUP);
        }
    }, [currentStepIndex]);

    const CurrentComponent =
        campaignContentSteps[currentStepIndex === -1 ? 0 : currentStepIndex]
            .component;

    const handleStepClick = useCallback(
        (index: number) => {
            const isValid = isValidStep(campaignContentSteps[index].key);
            if (isValid) {
                setActiveContentStep(campaignContentSteps[index].key);
            }
        },
        [campaignContentSteps, isValidStep],
    );

    if (!templateMetadata) return null;

    return (
        <Stack>
            <Stepper
                active={currentStepIndex}
                onStepClick={handleStepClick}
                steps={campaignContentSteps.map((step) => ({
                    key: step.key,
                    label: step.label,
                    isVisited: isValidStep(step.key),
                }))}
            />

            <Divider className="border-shade-6" />

            <Box>
                <CurrentComponent
                    setActiveContentStep={setActiveContentStepFn}
                    activeContentStep={activeContentStep}
                    templateMetadata={templateMetadata}
                    fields={fields}
                />
            </Box>
        </Stack>
    );
};

export default React.memo(SetupContent);
