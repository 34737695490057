import Select from '@components/common/Select';
import { useLocale } from '@hooks/useLocale';
import { useUpdateUserMutation } from '@hooks/useOrganizationUsers';
import {
    OrganizationMemberRole,
    OrganizationMemberRoleDescriptions,
    OrganizationMemberRoleLabels,
    type OrganizationMemberProfile,
} from '@lightdash/common';
import { Box, Button, Text } from '@mantine/core';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Check, ChevronRight } from 'react-feather';
import { ButtonVariant } from '../../mantineTheme';
import Modal from '../common/modal/Modal';
const allowedRoles: OrganizationMemberRole[] = [
    OrganizationMemberRole.ADMIN,
    OrganizationMemberRole.MANAGER,
    OrganizationMemberRole.MEMBER,
];
interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    label: string;
    description: string;
}
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, description, ...others }: ItemProps, ref) => (
        <Box ref={ref} {...others}>
            <Box className="flex justify-between">
                <Text className="text-sm font-medium text-gray-800">
                    {label}
                </Text>

                <Check
                    size={13}
                    strokeWidth={2.5}
                    color={'rgb(var(--color-gray-600))'}
                    className="selected__item--icon-selected"
                />
            </Box>
            <Text className="text-sm text-gray-600 break-normal">
                {description}
            </Text>
        </Box>
    ),
);
interface ChangeMemberRoleProps {
    onClose: () => void;
    opened: boolean;
    data: OrganizationMemberProfile;
}
export const ChangeMemberRole: React.FC<ChangeMemberRoleProps> = ({
    onClose,
    opened,
    data,
}) => {
    const {
        mutate: updateUser,
        isLoading,
        isSuccess,
    } = useUpdateUserMutation(data.userUuid);
    const { t } = useLocale();
    const [selectedRole, setSelectedRole] = useState<OrganizationMemberRole>(
        data.role,
    );
    const handleUserUpdateClick = useCallback(() => {
        updateUser({ role: selectedRole });
    }, [updateUser, selectedRole]);
    useEffect(() => {
        if (isSuccess && !isLoading) {
            onClose();
        }
    }, [isSuccess, isLoading, onClose]);
    return (
        <Modal
            opened={opened}
            onClose={onClose}
            keepMounted={false}
            title={t('organization_settings.change_role_title')}
            size="lg"
            footerRightSection={
                <Box className="flex gap-2">
                    <Button variant={ButtonVariant.OUTLINED} onClick={onClose}>
                        {t('organization_settings.admin_change_role_cancel')}
                    </Button>

                    <Button
                        form="add_user_to_project"
                        type="submit"
                        onClick={handleUserUpdateClick}
                        rightIcon={<ChevronRight size={13} strokeWidth={2.5} />}
                        disabled={isLoading}
                    >
                        {t('organization_settings.admin_change_role_confirm')}
                    </Button>
                </Box>
            }
        >
            <Select
                label={
                    <Box className="py-0 text-gray-500 whitespace-nowrap">
                        <Box className="inline text-gray-300">
                            {t(
                                'organization_settings.change_role_select_label',
                            )}
                        </Box>{' '}
                        {data.firstName} {`(${data.email})`}
                    </Box>
                }
                itemComponent={SelectItem}
                data={Object.values(OrganizationMemberRole)
                    .filter((role) => allowedRoles.indexOf(role) > -1)
                    .map((orgMemberRole: OrganizationMemberRole) => ({
                        value: orgMemberRole,
                        label: OrganizationMemberRoleLabels[orgMemberRole],
                        description:
                            OrganizationMemberRoleDescriptions[orgMemberRole],
                    }))}
                required
                dropdownPosition="bottom"
                maxDropdownHeight={400}
                withinPortal
                className="!w-2/5"
                withAsterisk={false}
                value={selectedRole}
                onChange={(value) => {
                    setSelectedRole(value as OrganizationMemberRole);
                }}
                disabled={isLoading}
            />
        </Modal>
    );
};
