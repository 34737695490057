import { useLocale } from '@hooks/useLocale';
import { Flex, Text, Textarea } from '@mantine/core';
import React, { useCallback, useState } from 'react';
import {
    parseStringWithVariablesToObject,
    stringifyObjectWithVariables,
} from '../utils';

interface RequestBodyProps {
    payloadMapper: string;
    onPayloadMapperChange: (value: string) => void;
}

const RequestBody: React.FC<RequestBodyProps> = ({
    payloadMapper,
    onPayloadMapperChange,
}) => {
    const { t } = useLocale();
    const [jsonValue, setValue] = useState<string>(
        JSON.stringify(parseStringWithVariablesToObject(payloadMapper)),
    );

    const handleJsonChange = useCallback(
        (value: string) => {
            try {
                setValue(value);
                const parsedObj = JSON.parse(value);
                const parsedObject = stringifyObjectWithVariables(parsedObj);
                onPayloadMapperChange(parsedObject);
            } catch (error) {}
        },
        [onPayloadMapperChange],
    );

    return (
        <Flex direction="column" gap="md">
            <Text>{t('journey_builder.call_api_request_body_label')}</Text>
            <Textarea
                value={jsonValue}
                onChange={(e) => handleJsonChange(e.target.value)}
            />
        </Flex>
    );
};

export default React.memo(RequestBody);
